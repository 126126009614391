import { Homescreen } from "@kanpla/types";
import Root from "../blocks/Root";
import WrapperBlock from "../blocks/WrapperBlock";

export const serializer = (
  singleBlock: Homescreen.ContentInner,
  key: string
) => {
  if (!singleBlock) return null;

  if (singleBlock.type === "div") return <Root singleBlock={singleBlock} />;

  return <WrapperBlock singleBlock={singleBlock} blockId={key} />;
};
