import { callInternalApi } from "@kanpla/system";
import { FoodWasteInsights } from "@kanpla/ui";
import { useQuery } from "@tanstack/react-query";
import {
  LoadPastWeekInsightsProps,
  LoadPastWeekInsightsResponse,
} from "libs/services/src/lib/foodwaste/loadPastWeekInsights";
import React from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

const FoodWasteInsightsBlock = () => {
  const { schoolId, partnerId } = useContainer(AppContext);

  const { data, status } = useQuery({
    queryKey: ["loadTrackingInsights", { schoolId, partnerId }],
    queryFn: () =>
      callInternalApi<LoadPastWeekInsightsProps, LoadPastWeekInsightsResponse>(
        "load/trackingInsights",
        { schoolId, partnerId: partnerId || "" }
      ),
    cacheTime: 1000 * 60 * 120, // Cache for 2 hours (no need to reload already cached data)
    staleTime: 1000 * 60 * 120, // Reload after 2 hours
    enabled: !!schoolId && !!partnerId,
  });

  if (status === "loading") return <FoodWasteInsights state="loading" />;
  if (status === "error") return <FoodWasteInsights state="error" />;

  return <FoodWasteInsights state="success" data={data.foodWasteInsights} />;
};

export default FoodWasteInsightsBlock;
