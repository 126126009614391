import { calculateOrderTotalByModule, useT } from "@kanpla/system";
import { OrderLines } from "@kanpla/types";
import { usePriceFormatter } from "@kanpla/ui";
import React from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";

interface Props {
  items: OrderLines;
}

const ReceiptFooter = (props: Props) => {
  const { items } = props;

  const t = useT();

  const { module } = useContainer(OrderingContext);
  const orderTotalPrice = calculateOrderTotalByModule(items, module);
  const orderTotalPriceFormatted = usePriceFormatter(orderTotalPrice);

  return (
    <div className="w-full px-10 py-4 pb-6 bg-gradient-to-b from-background-secondary to-background-primary border-t border-divider-main flex justify-between items-center">
      <span className="text-text-primary text-lg">{t("Total")}: </span>
      <span className="title-main text-text-primary">
        {orderTotalPriceFormatted}
      </span>
    </div>
  );
};

export default ReceiptFooter;
