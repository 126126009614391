import { useT } from "../transifex.config";

interface Props {
  isBulk?: boolean;
  t: typeof useT;
}

export const getEditStandardOrderStrings = ({ isBulk = false, t }: Props) => {
  const title = isBulk ? t("Set default order") : t("Change standard choice");

  const subtitle = isBulk
    ? t("Edit your company's default order for the different days")
    : t(
        "Register your standard lunch choice, and it will be automatically processed without you having to remember the weekly order. You can always edit your standard choice."
      );

  return { title, subtitle };
};
