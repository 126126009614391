import {
  getAvailabilityProductsInfo,
  getDaysInRange,
  tx,
} from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { orderBy } from "lodash";
import moment from "moment";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import ProductsAvailabilityContent from "./ProductsAvailabilityContent";

interface Props {
  isStandard?: boolean;
  classNames?: string;
}

const ProductsAvailability = ({
  isStandard = false,
  classNames = "",
}: Props) => {
  moment.updateLocale(tx.getCurrentLocale() || "da", {
    week: { dow: 1, doy: 4 },
  });

  const { week, items } = useContainer(OrderingContext);

  const getFilteredItems = () => {
    const startSecondsWeek = week?.[0]?.seconds;
    const endSecondsWeek = week?.[week?.length - 1]?.seconds;

    const startDateWeek = startSecondsWeek
      ? moment.unix(startSecondsWeek)
      : null;
    const endDateWeek = endSecondsWeek ? moment.unix(endSecondsWeek) : null;

    const range = getDaysInRange(
      startDateWeek,
      endDateWeek,
      "unix"
    ) as number[];

    const isWeekTruthy = startDateWeek && endDateWeek;

    const filteredByTime = (items as CombinedOfferItem[]).filter((item) => {
      const { fromSeconds, toSeconds } = item;

      if (fromSeconds && toSeconds && isWeekTruthy) {
        const isInRange =
          range.includes(fromSeconds) || range.includes(toSeconds);

        return isInRange;
      }

      return false;
    });

    return isStandard ? items : filteredByTime;
  };
  const filteredItems = getFilteredItems();

  const sortedItems = orderBy(
    filteredItems,
    ["productId", "fromSeconds"],
    ["asc"]
  );

  const uniqItems = Object.values(
    Object.fromEntries(
      new Set(
        (sortedItems as CombinedOfferItem[]).map((item) => [
          item.fromSeconds && item.toSeconds && item.productId,
          item,
        ])
      )
    )
  );

  const { areSomePeriods } = getAvailabilityProductsInfo({
    products: items as CombinedOfferItem[],
  });

  if (!areSomePeriods) return null;

  if (!sortedItems?.length) return null;

  return (
    <div className={classNames}>
      <ProductsAvailabilityContent
        isStandard={isStandard}
        sortedItems={uniqItems as CombinedOfferItem[]}
      />
    </div>
  );
};

export default ProductsAvailability;
