import { calculateAmountOfOrderItems } from "@kanpla/system";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { fullOrderAtom } from "../../../data/atomWeeklyOrders";

type TotalItemProps = {
  dayIndex: number;
  productId: string;
  selectedDayIndex: number;
};

export const VariantsTotalItem = ({
  dayIndex,
  productId,
  selectedDayIndex,
}: TotalItemProps) => {
  const finalOrder = useAtomValue(fullOrderAtom);

  const productOrders =
    finalOrder[dayIndex]?.filter((i) => i.productId === productId) || [];

  const total = calculateAmountOfOrderItems(productOrders);

  return (
    <div
      className={classNames(
        "text-center text-xl font-medium w-16 text-text-disabled flex-1",
        dayIndex !== selectedDayIndex && "hidden md:block"
      )}
    >
      {total}
    </div>
  );
};
