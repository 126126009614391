import { AdyenCheckoutModal, OrdersModal } from "@kanpla/ordering";
import { useWindowSize } from "@kanpla/system";
import { TimeNavigation } from "@kanpla/types";
import { DashboardSkeleton } from "@kanpla/ui";
import { atom, useAtom } from "jotai";
import Head from "next/head";
import { useContainer } from "unstated-next";
import AnonymousTabs from "./anonymous/AnonymousTabs";
import { AppContext } from "./contextProvider";
import FirstChild from "./FirstChild";
import FooterCanteen from "./FooterCanteen";
import AddClassInfo from "./modals/AddClassInfo";
import AddReferenceInfo from "./modals/AddReferenceInfo";
import AnonymousModal from "./modals/Anonymous/AnonymousModal";
import EmptyNamePopup from "./modals/EmptyNamePopup";
import NegativeBalancePopup from "./modals/NegativeBalancePopup";
import SubscriptionPopup from "./modals/SubscriptionPopup";
import Navbar from "./Navbar";
import Tabs from "./Tabs";

interface Props {
  viewName: string;
  children: React.ReactNode;
  timeNavigation?: TimeNavigation;
  withTabs?: boolean;
  disableIfBasket?: boolean;
  noPaddingOnTop?: boolean;
  noPadding?: boolean;
  /** Dynamic tabs show dynamically links to modules
   * @version 3.0
   */
  newDynamicTabs?: boolean;
}

/** Used only for triggering opening when clicking on the receipt */
export const accountHistoryOpenAtom = atom<boolean>(false);

const Wrapper = (props: Props) => {
  const {
    children,
    school,
    appLoading,
    innerAppLoading,
    modules,
    module,
    supplier,
    moduleLoading,
    auth,
    mobile,
  } = useContainer(AppContext);

  const [historyOpen, setHistoryOpen] = useAtom(accountHistoryOpenAtom);

  /** Check how many available modules are, add or remove padding to the wrapper accordingly  */
  const activeModules =
    modules?.filter(
      (module) =>
        module?.scope?.generatedSchoolIds?.includes(school?.id || "") &&
        !module?.displayOptions?.hideMobileTab
    ) || [];

  const { width: screenWidth } = useWindowSize();

  const wrapperMarginBottom =
    screenWidth && screenWidth < 768 && activeModules.length > 1
      ? module?.flow === "registering"
        ? "168px"
        : "80px"
      : module?.flow === "registering"
      ? "88px"
      : "0px";

  return (
    <div
      id="dashboard"
      className="w-full flex flex-col justify-between relative min-h-full"
    >
      <Head>
        {module && (module.displayName || module.name) && (
          <title>
            {module.displayName || module.name} |{" "}
            {supplier?.name ? `${supplier.name} | powered by Kanpla` : "Kanpla"}
          </title>
        )}
      </Head>

      <Navbar />

      <div
        className="min-h-full flex-1 flex flex-col"
        style={{
          marginBottom: wrapperMarginBottom,
        }}
      >
        <div className="relative flex flex-col h-full min-h-full flex-1 container mx-auto md:px-2 lg:px-4">
          {/* If no user created, create it! */}
          {!appLoading &&
            !innerAppLoading &&
            !auth?.loading &&
            children != null &&
            !children.length &&
            auth.user && <FirstChild />}

          {(children === null || children?.length > 0 || !auth.loading) && (
            <>
              {(appLoading || innerAppLoading) && moduleLoading && (
                <DashboardSkeleton />
              )}
              <div
                className={`transition ${
                  (appLoading || innerAppLoading) && moduleLoading
                    ? "absolute opacity-0 inset-x-0"
                    : "relative"
                }`}
              >
                {props.children}
              </div>
              <FooterCanteen />
            </>
          )}
        </div>
      </div>

      {/** Mobile bottom navigation bar */}
      {mobile && (auth.user ? <Tabs /> : <AnonymousTabs />)}

      <AddClassInfo />
      <AddReferenceInfo />

      <EmptyNamePopup />

      <AdyenCheckoutModal />

      <NegativeBalancePopup />

      {/* Temporary Milk Popup */}
      <SubscriptionPopup />

      <OrdersModal open={historyOpen} setOpen={setHistoryOpen} />

      {!auth.user && <AnonymousModal />}
    </div>
  );
};

export default Wrapper;
