import { faForward } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { Button, Space } from "@kanpla/ui";
import { Portal } from "@mui/material";
import { useState } from "react";
import { useLocalstorageState } from "rooks";

const SkipQueueBanner = () => {
  const t = useT();
  const [skipQueueBannerHidden, setSkipQueueBannerHidden] =
    useLocalstorageState("skip-queue-banner-hidden", false);

  const [hideForNow, setHideForNow] = useState(false);

  if (skipQueueBannerHidden || hideForNow) return null;

  return (
    <Portal>
      <div
        className="fixed inset-0 bg-background-secondary p-10 flex flex-col items-center justify-center overflow-y-auto text-text-primary"
        style={{ zIndex: 99999999 }}
      >
        <FontAwesomeIcon icon={faForward} className="mb-8 text-5xl" />
        <h2 className="title-main !font-black uppercase leading-tight text-center">
          {t("Skip the queue!")}
        </h2>
        <p className="text-center mt-2">
          {t("Just take the food and show the receipt on the way out! ✅")}
        </p>

        <Space direction="vertical" align="center" className="w-full">
          <Button onClick={() => setHideForNow(true)} dataCy="btn-show-receipt">
            {t("Show receipt")}
          </Button>
          <div className="my-4 flex justify-center">
            <Button
              type="primary"
              className="text-center mx-auto"
              onClick={() => setSkipQueueBannerHidden(true)}
              dataCy="btn-do-not-show-skip-queue"
            >
              {t("Don't show this again")}
            </Button>
          </div>
        </Space>
      </div>
    </Portal>
  );
};

export default SkipQueueBanner;
