import { faReceipt } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  T,
  calculateAmountOfOrderItems,
  calculateOrderTotalByOrder,
  useT,
  useWindowSize,
} from "@kanpla/system";
import { Order, OrderPersonal } from "@kanpla/types";
import { PriceFormatter } from "@kanpla/ui";
import classNames from "classnames";
import { useSetAtom } from "jotai";
import { OrderWithChildName } from "libs/services/src/lib/ordering/orderHistory";
import { isEmpty, sum } from "lodash";
import moment from "moment";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import OrderOptions from "./OrderOptions";
import { receiptOrderAtom } from "./Receipt";

interface Props {
  order: Order;
  viewEmployeeOrders?: boolean;
  meetingView?: boolean;
  reloadOrders?: () => void;
  /** Display data grouped for each child   */
  grouped?: boolean;
  /** Orders of the grouped child */
  orders?: Order[];
  /** Name of the grouped child */
  groupedChildName?: string;
}

const OrderCard = (props: Props) => {
  const {
    order,
    viewEmployeeOrders,
    meetingView,
    reloadOrders,
    grouped = false,
    orders = [],
    groupedChildName = null,
  } = props;

  const t = useT();

  const { width: screenWidth } = useWindowSize();
  const mobile = isMobile || (screenWidth || Infinity) < 768;

  const [openSheet, setOpenSheet] = useState<boolean>(false);

  const setReceipt = useSetAtom(receiptOrderAtom);

  const dateMoment = moment.unix(order.dateSeconds);
  const formatDay = dateMoment.format("L");
  const time = order?.info?.timeInput;
  const orderName =
    order?.info?.name ||
    t("Order for {value}", { value: dateMoment.format("D/M") });

  const orderTotalPrice = calculateOrderTotalByOrder(order.orderLines, order);

  const childName =
    (order as OrderWithChildName)?.childDisplayName ||
    (order as OrderPersonal)?.displayName;
  const childInitials = (order as OrderWithChildName)?.childInitials;

  if (mobile || (!meetingView && !viewEmployeeOrders))
    return (
      <>
        <div
          className="flex w-full justify-between items-center mb-3 hover:opacity-70 md:hover:opacity-100 cursor-pointer hover:outline outline-offset-4 outline-secondary-light outline-1 rounded-sm transition-all ease-in-out group"
          onClick={() => {
            if (viewEmployeeOrders || meetingView) {
              setOpenSheet(true);
            } else {
              setReceipt({ order, from: "history" });
            }
          }}
        >
          <div className="flex items-center group-hover:opacity-75">
            <div className="w-8 h-8 flex items-center justify-center rounded-sm bg-background-secondary p-1">
              <FontAwesomeIcon icon={faReceipt} size="lg" />
            </div>
            <div className="flex flex-col ml-2">
              <h1>
                {(order as OrderPersonal)?.displayName || "Admin"}{" "}
                {order.groupName ? `(${order.groupName})` : ""}
              </h1>
              <span className="text-sm text-text-secondary">
                {dateMoment.format("L")}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-end group-hover:opacity-75">
            <h1>
              <PriceFormatter price={orderTotalPrice} />
            </h1>
            <span className="text-sm text-text-secondary">
              {order.paymentMethod === "billing"
                ? t("Paid via invoice")
                : t("Paid from balance")}
            </span>
          </div>
        </div>
        {(viewEmployeeOrders || meetingView) && (
          <OrderOptions
            order={order}
            setOpenSheet={setOpenSheet}
            openSheet={openSheet}
            meetingView={meetingView}
            reloadOrders={reloadOrders}
          />
        )}
      </>
    );

  if (grouped) {
    const orderTotalPrice = sum(
      orders.map((o) => calculateOrderTotalByOrder(o.orderLines, o))
    );

    const orderTotalAmount = sum(
      orders.map((o) => calculateAmountOfOrderItems(o.orderLines))
    );

    return (
      <div className="w-full mt-4 grid grid-cols-4 gap-3 items-center bg-background-secondary rounded-md p-4 relative">
        <div
          className="col-span-2 flex items-center text-text-primary"
          title={orderName}
        >
          {groupedChildName}
        </div>
        <div>{orderTotalAmount}</div>
        <div>
          <PriceFormatter price={orderTotalPrice} />
        </div>
      </div>
    );
  }

  const isCancelled = isEmpty(order.orderLines);

  return (
    <div
      onClick={() => setReceipt({ order, from: "history" })}
      className={classNames(
        "w-full mt-4 grid grid-cols-6 gap-3 items-center bg-background-secondary rounded-md p-4 relative",
        "cursor-pointer transition-all ease-in-out hover:shadow shadow-none shadow-black duration-400 text-text-primary",
        isCancelled && "bg-opacity-50"
      )}
    >
      <div>{formatDay}</div>
      <div className="col-span-2 flex items-center" title={orderName}>
        {viewEmployeeOrders && (
          <div className="rounded-full border border-secondary-dark bg-secondary-main text-center font-medium p-2 w-8 h-8 flex items-center justify-center">
            {childInitials}
          </div>
        )}
        <div className="truncate ml-2">
          {[
            meetingView
              ? [orderName, childName].filter((n) => n)
              : orderName || childName,
            time ? moment.utc(time * 1000 || 0).format("HH:mm") : [],
          ]
            .flat()
            .join(", ")}
        </div>
      </div>
      <div>{calculateAmountOfOrderItems(order.orderLines)}</div>
      <div>
        {isCancelled ? (
          <p className="text-text-disabled">
            <T _str="Cancelled" />
          </p>
        ) : (
          <PriceFormatter price={orderTotalPrice} />
        )}
      </div>
      <div className="place-self-end" onClick={(e) => e.stopPropagation()}>
        <OrderOptions
          order={order}
          meetingView={meetingView}
          openSheet={openSheet}
          setOpenSheet={setOpenSheet}
          reloadOrders={reloadOrders}
        />
      </div>
    </div>
  );
};

export default OrderCard;
