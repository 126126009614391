import {
  calculateAmountOfOrderItems,
  getUnitPrice,
  validateRequiredOrder,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  Module,
  OrderLine,
  OrderLines,
} from "@kanpla/types";
import { sum } from "lodash";

type Props = {
  module: Pick<Module, "plugins">;
  orderLines: OrderLines;
  offerItems: CombinedOfferItem[];
  optionChoices?: OrderLine["options"];
};

export const adjustRequiredProduct = ({
  module,
  offerItems,
  orderLines,
  optionChoices,
}: Props) => {
  // 1. Get target product, and skip if not available
  const requiredProductData = module?.plugins?.requiredProduct;
  const productId = requiredProductData?.productId;
  const product = offerItems?.find((i) => i.productId === productId);
  if (!productId || !product) return orderLines;

  // 2. Let's see the situation with the current order
  const orderWithoutRequiredProduct = orderLines.filter(
    (orderLine) => orderLine.productId !== productId
  );
  const requiredProductAmount = sum(
    orderLines
      .filter((orderLine) => orderLine.productId === productId)
      .map((orderLine) => orderLine.amount)
  );
  const orderIsEmpty =
    calculateAmountOfOrderItems(orderWithoutRequiredProduct) === 0;

  // True by default
  let matchesRequiredOrder = true;

  // If the required product has an order that has to be fulfilled
  const requiredOrder = requiredProductData?.requiredOrder;
  if (requiredOrder?.layers?.length) {
    matchesRequiredOrder = validateRequiredOrder(orderLines, requiredOrder);
  }

  // 3. If already correct, return
  const correctlyIncludes =
    !orderIsEmpty &&
    requiredProductAmount === 1 &&
    typeof optionChoices === "undefined" &&
    matchesRequiredOrder;
  const correctlyExcludes = orderIsEmpty && requiredProductAmount === 0;
  if (correctlyIncludes || correctlyExcludes) return orderLines;

  // 4. Otherwise, modify the order to include requiredProduct if the order is not empty
  const withRequiredProduct: OrderLines = orderWithoutRequiredProduct;

  if (matchesRequiredOrder)
    withRequiredProduct.push({
      amount: 1,
      productId: product.id,
      name: product.name,
      unitPrice: getUnitPrice(product) || 0,
      productLineId: product.productLineId,
      options: optionChoices || {},
      vatRate:
        offerItems?.find((o) => o.productId === product.id)?.vatRate ?? 0.25,
    });

  const newOrder = orderIsEmpty
    ? orderWithoutRequiredProduct
    : withRequiredProduct;

  return newOrder;
};
