import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { DayIndex } from "@kanpla/types";
import { Tooltip } from "@kanpla/ui";
import classnames from "classnames";
import { useAtomValue } from "jotai";
import moment from "moment";
import { isStandardOrderingAtom } from "../Navigation";
import { useHolidays } from "../holidays/useHolidays";
import { useCheckIsBelow } from "../minimumOrder/useCheckIsBelow";

interface Props {
  dayIndex: DayIndex;
  dateSeconds: number;
}

export const WeekDateDisplayItem = ({ dayIndex, dateSeconds }: Props) => {
  const t = useT();
  const isStandardOrdering = useAtomValue(isStandardOrderingAtom);

  const { daysHolidays } = useHolidays();

  const { isValid } = useCheckIsBelow({
    dayIndex,
  });
  const isHoliday = !isStandardOrdering && daysHolidays[dayIndex];
  const timestampDate = moment.unix(dateSeconds);

  const standardFormat = `[${t("every")}] ddd`;
  const standardViewFormat = timestampDate?.format(standardFormat);
  const defaultFormat = timestampDate?.format("ddd D.M.");

  return (
    <div
      className={classnames(
        "flex-1 text-sm font-medium text-center hidden md:flex items-center justify-center",
        !isValid && "text-danger-main",
        isHoliday && "flex flex-wrap justify-center"
      )}
    >
      <span className="inline-block w-10 text-center">
        {isStandardOrdering ? standardViewFormat : defaultFormat}
      </span>
      {isHoliday ? (
        <Tooltip
          content={t(
            "Ordering not possible, contact administrator for more information."
          )}
          trigger="click"
        >
          <FontAwesomeIcon
            icon={faWarning}
            className="ml-2 text-warning-main cursor-pointer"
          />
        </Tooltip>
      ) : null}
    </div>
  );
};
