import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@kanpla/ui";
import { useT } from "@transifex/react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

const LockedBlock = () => {
  const { mobile } = useContainer(AppContext);

  const t = useT();

  return (
    <Tooltip
      content={t("You don't have access to this module")}
      trigger={mobile ? "click" : "hover"}
    >
      <div className="absolute top-0 left-0 w-full h-full rounded-lg flex justify-end px-4 py-3 cursor-not-allowed">
        <div className="absolute bg-black bg-opacity-20 filter backdrop-blur-[2px] w-full h-full top-0 left-0 rounded-lg" />
        <FontAwesomeIcon
          icon={faLock}
          className="text-white text-4xl absolute"
        />
      </div>
    </Tooltip>
  );
};

export default LockedBlock;
