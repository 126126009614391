import React from "react";

import { useT } from "@kanpla/system";

import Spinner from "../../loading/Spinner";

import { average, currentTime, day } from "./mocks";

import type { CrowdTrackerProps as SuccessProps } from "./CrowdTracker";
import { CrowdTracker as SuccessView } from "./CrowdTracker";
import { Wrapper } from "./Layout";

type CrowdTrackerProps =
  | ({
      state: "success";
    } & SuccessProps)
  | ({ state: "loading" | "error" | "preview" } & Partial<SuccessProps>);

export const CrowdTracker = (props: CrowdTrackerProps) => {
  const t = useT();
  if (props.state === "success") {
    return <SuccessView {...props} />;
  }

  if (props.state === "preview") {
    return (
      <SuccessView
        average={average}
        day={day}
        currentTime={currentTime}
        isPreview
      />
    );
  }

  if (props.state === "loading") {
    return (
      <Wrapper>
        <Spinner className="text-info-light" size="large" />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="px-2 py-1 rounded-md bg-background-primary w-auto mx-auto">
        <span>{t("coming soon")}</span>
      </div>

      <span className="text-4xl my-3 font-bold text-center">
        {t("Crowd Tracker")}
      </span>

      <span className="text-center mx-4">
        {t("Check how busy the canteen is and find the perfect time for lunch")}
      </span>
    </Wrapper>
  );
};
