import { useT } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { message } from "@kanpla/ui";
import moment from "moment";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import ChildForm from "./childForm";

const AddChild = ({ callback = () => null }) => {
  const t = useT();

  const { isChildSalesplace, createChild, triggerReload } =
    useContainer(AppContext);

  const submit = async (data: Child) => {
    try {
      const child: Child = {
        ...data,
        createdAt: moment().toDate(),
      };

      // Create child
      const childDocument = await createChild({
        name: child.name,
        schoolId: child.schoolId,
        selectors: child.selectors,
        userId: child.userId,
        codes: [],
        isChildSalesplace,
        scope: child.scope,
      });

      if (childDocument) if (typeof callback === "function") callback();

      /**
       * The created child will be selected automatically if it's the first child.
       * The triggerReload will be ran after 1 second
       * - to let all the background handlers be completed
       * - to let the user see the new added user in the list
       **/
      await triggerReload(1000);

      message.success(t("Additional user created"));
    } catch (e) {
      console.error(e);
      message.error(t("User could not be created"));
    }
  };

  return <ChildForm submit={submit} />;
};

export default AddChild;
