import { scroll2 } from "@kanpla/system";
import React, { useEffect } from "react";
import { useContainer } from "unstated-next";
import { Module } from "./Module";
import { OrderingContext } from "./context";
import { useDeadlineJump } from "./lib/hooks/useDeadlineJump";
import { useKanplaGo } from "./lib/hooks/useKanplaGo";
import { PurchaseAnimation } from "./mealplan2/basket/elements/PurchaseAnimation";
import NewReceipt from "./mealplan2/kanplaGo/receipt/index";
import { useKanplaGoListener } from "./mealplan2/kanplaGo/useKanplaGoListener";

interface Props {
  Overview?: () => React.ReactElement | null;
}

export const Switch = ({ Overview }: Props) => {
  const { innerAppLoading, fromAdmin } = useContainer(OrderingContext);

  useDeadlineJump();
  useKanplaGoListener();
  useKanplaGo();

  /**
   * Issue: On switching into pages if the previous page was scrolled,
   * then on a mobile device the current page is being opened already scrolled.
   */
  useEffect(() => {
    if (innerAppLoading || fromAdmin) return;
    scroll2({ top: 0 });
  }, [innerAppLoading]);

  return (
    <>
      <Module Overview={Overview} />
      <NewReceipt />
      <PurchaseAnimation />
    </>
  );
};
