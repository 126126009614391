import { DayIndex, OrderLines } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useFinalOrders } from "../data/useFinalOrders";
import { isBelowMinimum } from "./isBelowMinimum";

interface Props {
  dayIndex: DayIndex;
}

export const useCheckIsBelow = ({ dayIndex }: Props) => {
  const { school, fromAdmin } = useContainer(OrderingContext);
  const finalOrders = useFinalOrders();

  const orderLines: OrderLines = finalOrders[dayIndex];
  const { isValid, totalAmount } = isBelowMinimum({
    orderLines,
    school,
  });

  return { isValid: fromAdmin ? true : isValid, totalAmount };
};
