import { Timestamp } from "@kanpla/system";
import { DayIndex, Week, _FrontendModule } from "@kanpla/types";
import { getWeekArray } from "../time/getWeekArray";
import { getWeekSeconds } from "../time/getWeekSeconds";

interface goToNextAvailableDayProps {
  week: Week;
  defaultDateSeconds: number;
  setDate: ({ week, dayIndex }: { week?: Week; dayIndex?: DayIndex }) => void;
  module?: _FrontendModule;
}

export const goToNextAvailableDay = ({
  week,
  defaultDateSeconds,
  setDate,
  module,
}: goToNextAvailableDayProps) => {
  const sevenDayWeek = module?.plugins?.sevenDayWeek?.active;

  // Set week
  const mondaySeconds = getWeekSeconds(defaultDateSeconds);
  const differentWeek = week[0].seconds !== mondaySeconds;
  if (differentWeek) {
    const targetWeek = getWeekArray(mondaySeconds, Timestamp);
    setDate({ week: targetWeek });
  }

  // Set dayIndex
  let daysFromMonday = ((defaultDateSeconds - mondaySeconds) /
    86400) as DayIndex;
  if (daysFromMonday > 4 && !sevenDayWeek) daysFromMonday = 4;
  if (daysFromMonday < 0) daysFromMonday = 0;
  setDate({ dayIndex: daysFromMonday });
};
