import { useT } from "@kanpla/system";
import {
  Language,
  ProductAllIcons,
  Supplier,
  _FrontendSupplier,
} from "@kanpla/types";
import { default as classNames, default as classnames } from "classnames";
import { sortBy } from "lodash";
import React from "react";
import { isMobile } from "react-device-detect";
import { ProductSettingsHeader } from "../product/ProductSettingsHeader";
import { DisplayAttribute, DisplayDescriptionProps } from "./DisplayAttribute";
import { getAttributes } from "./getAttributes";

interface Props {
  attributes: ProductAllIcons;
  hideHeader?: boolean;
  supplier?: Supplier | _FrontendSupplier | null;
  className?: string;
  size?: DisplayDescriptionProps["size"];
  mode: DisplayDescriptionProps["mode"];
  disableSvg?: boolean;
  colorClassName?: string;
  noBgHeader?: boolean;
  /** Should we keep neutral colors for print? */
  neutral?: boolean;
  noPadding?: boolean;
  fontSizePx?: number;
  language?: Language;
  onlyIcon?: boolean;
  onlyName?: boolean;
}

export const DisplayAttributes = ({
  attributes,
  hideHeader = true,
  supplier,
  className = "",
  size,
  mode,
  disableSvg,
  colorClassName = "",
  noBgHeader = false,
  neutral,
  noPadding = false,
  fontSizePx,
  language,
  onlyIcon: onlyIconFromProps,
  onlyName,
}: Props) => {
  const t = useT();

  const customAttributesData = getAttributes({
    supplier,
  });

  const activeAttributes: string[] = Object.entries(attributes || {})
    .filter(([key, isActive]) => {
      // Skip if isn't available
      if (!isActive) return false;

      const [parentKey] = key.split(":");

      // Skip if no data
      const attributeData = customAttributesData.find(
        (a) => a.key === parentKey
      );

      if (!attributeData) return false;

      // Skip if hidden
      const attributeHidden = attributeData.frontendDisplayMode === "hidden";
      if (attributeHidden) return false;

      // Otherwise, determine based on mode
      const isMatchingMode =
        mode === "all" || attributeData.frontendDisplayMode === mode;
      return isMatchingMode;
    })
    .map(([key]) => key);

  const noActiveAttributes = activeAttributes.length === 0;
  if (noActiveAttributes) return null;

  const sortedAttributes = sortBy(activeAttributes, (name) => name);
  const numberOfAttributes = activeAttributes.length;

  const tagWrapperStyle = classnames(
    "absolute top-0 right-0 flex overflow-hidden md:rounded-tr rounded-bl-md max-w-full",
    className
  );

  const descriptionWrapperStyle = classnames(
    "flex",
    !noPadding && (hideHeader ? "py-2" : "px-2"),
    "flex-wrap -mx-1",
    className
  );

  const shouldCollapse = numberOfAttributes > (isMobile ? 1 : 3);
  const isTagMode = mode === "tag";

  return (
    <div className={isTagMode ? tagWrapperStyle : descriptionWrapperStyle}>
      {!hideHeader && !isTagMode && (
        <div className="print:hidden">
          <ProductSettingsHeader
            title={t("Allergens")}
            help={t(
              "The allergens are indicative only. Please contact the kitchen regarding allergen issues."
            )}
            noBg={noBgHeader}
          />
        </div>
      )}
      <div
        className={classNames(
          !isTagMode &&
            !neutral &&
            "bg-background-secondary print:bg-transparent ",
          !isTagMode && "rounded-md print:p-0 flex-wrap",
          !isTagMode && !noPadding && "p-0.5 px-1 mt-1",
          "flex print:inline-flex",
          className
        )}
      >
        {sortedAttributes.map((attributeKey) => (
          <DisplayAttribute
            key={attributeKey}
            allergenName={attributeKey}
            supplier={supplier}
            size={size}
            onlyIcon={(isTagMode && shouldCollapse) || onlyIconFromProps}
            onlyName={onlyName}
            mode={mode}
            disableSvg={disableSvg}
            colorClassName={neutral ? "text-black" : colorClassName}
            fontSizePx={fontSizePx}
            noPadding={noPadding}
            language={language}
          />
        ))}
      </div>
    </div>
  );
};
