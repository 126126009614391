import { DayIndex } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useFinalOrders } from "../data/useFinalOrders";
import { isBelowMinimum } from "./isBelowMinimum";

export const useIsDisabledForMinimumOrder = () => {
  const { school, week, fromAdmin } = useContainer(OrderingContext);
  const finalOrders = useFinalOrders();

  // Allow admins to make their own changes and ignore minimums
  if (fromAdmin) return false;

  const someDaysAreBelow = () => {
    const someAreBelow = week.some((_, index) => {
      const dayIndex = index as DayIndex;
      const { isValid } = isBelowMinimum({
        school,
        orderLines: finalOrders[dayIndex],
      });

      return !isValid;
    });

    return someAreBelow;
  };

  const disabled = someDaysAreBelow();

  return disabled;
};
