import { useT } from "@kanpla/system";
import { sortBy } from "lodash";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import NewSalesplace from "../../modals/NewSalesplace";
import SalesplaceKidRow from "./SalesplaceKidRow";
import UserInfo from "./UserInfo";

const SalesplaceKid = () => {
  const { children } = useContainer(AppContext);
  const t = useT();

  return (
    <div className="flex flex-col w-full">
      <h2 className="text-sm uppercase font-semibold text-text-secondary mb-2">
        {t("Active user")}
      </h2>

      <UserInfo />

      <h2 className="text-sm uppercase font-semibold text-text-secondary mt-6 mb-2">
        {t("Locations")}
      </h2>
      <div className="flex flex-col gap-y-3 w-full">
        {sortBy(children, "school_name").map((child) => (
          <SalesplaceKidRow
            key={child.id}
            child={child}
            schoolId={child.schoolId}
          />
        ))}
      </div>

      <div className="mt-2">
        <NewSalesplace />
      </div>
    </div>
  );
};

export default SalesplaceKid;
