import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";

import {
  calculateAmountOfOrderItems,
  calculateOrderTotal,
  calculateProductPrice,
  getAllowancesForProduct,
} from "@kanpla/system";
import type { CombinedOfferItem } from "@kanpla/types";

import { OrderingContext, initialAllowanceUsagesAtom } from "../../context";
import { basketAtom } from "../basket/useBasket";

export const useSingleAllowance = () => {
  const initialAllowanceUsages = useAtomValue(initialAllowanceUsagesAtom);

  const { module } = useContainer(OrderingContext);

  const basket = useAtomValue(basketAtom);

  const shouldDisableProduct = (product: CombinedOfferItem) => {
    const allowancesForProduct = getAllowancesForProduct({
      productId: product.id,
      allowanceUsages: initialAllowanceUsages.filter((a) => !a?.discountId),
    });

    const basketTotalPrice = calculateOrderTotal(basket, true);
    const basketTotalAmount = calculateAmountOfOrderItems(basket);

    const productPrice = calculateProductPrice({ product, module });

    const nextTotalPrice = basketTotalPrice + productPrice;
    const nextTotalAmount = basketTotalAmount + 1;

    const willGoOver = allowancesForProduct.some((allowance) => {
      const { numberOfProducts, unitPrice } = allowance;

      if (numberOfProducts) {
        return nextTotalAmount > numberOfProducts.amount;
      }

      if (unitPrice) {
        return nextTotalPrice > unitPrice.amount;
      }

      return false;
    });

    return willGoOver;
  };

  return {
    shouldDisableProduct,
  };
};
