import { DayIndex } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { pickBy } from "lodash";
import { globalVariantChoicesAtom } from "../ordering/globalVariant/globalVariantChoicesAtom";
import { useSetGlobalVariantToOrder } from "../ordering/globalVariant/setGlobalVariantToOrder";
import { fullOrderAtom } from "./atomWeeklyOrders";
import { orderChangesAtom } from "./changesAtom";

export const useFinalOrders = () => {
  const finalOrder = useAtomValue(fullOrderAtom);
  const orderChanges = useAtomValue(orderChangesAtom);
  const globalVariantChoices = useAtomValue(globalVariantChoicesAtom);

  // 2. Apply global variants
  const withGlobalVariants = useSetGlobalVariantToOrder({
    orders: finalOrder,
  });

  // 3. Filter only changed days
  const allChangedDayIndexes = orderChanges.map((ch) => ch.dayIndex);
  const filteredOrders = pickBy(
    withGlobalVariants,
    (_, dayIndex) =>
      allChangedDayIndexes.includes(parseInt(dayIndex) as DayIndex) ||
      typeof globalVariantChoices?.[parseInt(dayIndex) as DayIndex] === "string"
  );

  return filteredOrders;
};
