import { useT, weekToDisplayString } from "@kanpla/system";
import { useAtom } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import { isStandardOrderingAtom } from "./Navigation";

export const useTitle = () => {
  const t = useT();
  const { week } = useContainer(OrderingContext);
  const [isStandardOrdering] = useAtom(isStandardOrderingAtom);

  if (isStandardOrdering) {
    return t("Standard order");
  } else {
    return weekToDisplayString(week, t);
  }
};
