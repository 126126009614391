import { faRedo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  calculateSubscriptionPrice,
  getDeliveryDates,
  T,
  useT,
} from "@kanpla/system";
import { SubscriptionPeriod, _FrontendModule } from "@kanpla/types";
import {
  Checkbox,
  Image,
  ProductSettingsHeader,
  usePriceFormatter,
} from "@kanpla/ui";
import moment, { Moment } from "moment";
import { useContainer } from "unstated-next";
import CardPreview from "../../../../../libs/ordering/src/shared/payment/payment/CardPreview";
import { AppContext } from "../../contextProvider";

interface Props {
  isChoosingName: boolean;
  selectedPeriod: SubscriptionPeriod;
  productId: string;
  startDate: Moment;
  loading: boolean;
  existingCardSelected: boolean;
  setExistingCardSelected: (newState: boolean) => void;
  acceptedTerms: boolean;
  setAcceptedTerms: (acceptedTerms: boolean) => void;
  module?: _FrontendModule;
}

const OrderSummary = (props: Props) => {
  const {
    selectedPeriod,
    productId,
    startDate,
    loading,
    existingCardSelected,
    setExistingCardSelected,
    isChoosingName,
    setAcceptedTerms,
    module,
    acceptedTerms,
  } = props;

  const t = useT();
  const { child, card } = useContainer(AppContext);

  const selectedProduct = selectedPeriod?.products?.find(
    (p) => p.id === productId
  );

  const { hidePrices } = module?.config || {};

  // if (!selectedPeriod || !selectedProduct) return null;

  const deliveryDates = getDeliveryDates(startDate, selectedPeriod);
  const numberOfDates = deliveryDates.length;

  // multiply all prices by 100
  const fullPrice =
    calculateSubscriptionPrice(selectedProduct, numberOfDates, "price") * 100;
  const fullSubsidies =
    calculateSubscriptionPrice(selectedProduct, numberOfDates, "subsidies") *
    100;
  const resultingPrice =
    calculateSubscriptionPrice(selectedProduct, numberOfDates) * 100;
  const resultingPriceFormatted = usePriceFormatter(resultingPrice);

  return (
    <div className="flex flex-col justify-center ">
      <div className="px-2">
        <ProductSettingsHeader
          title={t("{value} Order overview", {
            value: isChoosingName ? "3." : "2.",
          })}
        />
      </div>

      <div className="text-center mb-6 pt-6 text-text-primary">
        <p>
          <T
            _str="To {childName} at {schoolName}"
            childName={
              <b>
                {child.name.trim()}
                {child.groupName && `, ${child.groupName}`}
              </b>
            }
            schoolName={child.school_name}
          />
        </p>

        <Image
          src={selectedProduct.photo || ""}
          className="h-40 w-full object-contain mt-6"
          alt=""
        />
        <h4 className="heading-main mt-2">{selectedProduct.name}</h4>
        {hidePrices ? null : (
          <p className="text-xs font-bold opacity-80">
            {resultingPriceFormatted}{" "}
            {t("per {value} days", { value: numberOfDates })}
          </p>
        )}
      </div>

      <span className="form-label font-medium  mb-2">{t("Time overview")}</span>

      <InfoLine
        name={t("Delivered from")}
        value={startDate.format("D/M YYYY")}
      />
      <InfoLine name={t("Number of delivery days")} value={numberOfDates} />
      <InfoLine
        name={t("Next invoicing")}
        value={moment.unix(selectedPeriod.to.seconds).format("D/M YYYY")}
      />

      <span className="py-1 text-primary-light text-xs">
        <FontAwesomeIcon icon={faRedo} className="mr-1" />
        {t("Subscription renewed automatically")}
      </span>

      <span className="form-label font-medium mt-4 mb-2">
        {t("Price overview")}
      </span>

      <InfoLine
        name={t("Price {value}", {
          value: fullSubsidies > 0 ? t("(without subsidy)") : "",
        })}
        value={fullPrice}
        config={{ type: "money" }}
      />
      {fullSubsidies > 0 && (
        <InfoLine
          name={t("EU Grants")}
          value={fullSubsidies}
          config={{ type: "money" }}
        />
      )}
      <p className="flex justify-between py-2">
        <span className="heading-main">{t("To be paid")}</span>
        <span className="heading-main">{resultingPriceFormatted}</span>
      </p>

      <hr className="mb-4" />

      {card && (
        <div className="block my-4">
          <span className="form-label">{t("Payment method")}</span>
          <div className="mt-2">
            <div className="">
              <label className="flex items-center bg-background-secondary rounded py-2 px-1">
                <div className="mx-3">
                  <input
                    type="radio"
                    className="form-radio"
                    name="radio"
                    disabled={loading}
                    checked={existingCardSelected}
                    onChange={(e) => setExistingCardSelected(!!e.target.value)}
                  />
                </div>
                <CardPreview card={card} />
              </label>
            </div>
            <div className="">
              <label className="flex items-center px-4 py-3 bg-background-secondary rounded mt-2">
                <input
                  type="radio"
                  className="form-radio"
                  name="radio"
                  disabled={loading}
                  checked={!existingCardSelected}
                  onChange={(e) => setExistingCardSelected(!e.target.value)}
                />
                <span className="ml-2 text-sm text-text-primary">
                  {t("Add card")}
                </span>
              </label>
            </div>
          </div>
        </div>
      )}

      <Checkbox
        className="mr-2 mb-2"
        checked={acceptedTerms}
        onChange={(e) => setAcceptedTerms(e.target.checked)}
      >
        <T
          _str="I accept {terms}"
          terms={
            <a
              href="/kanpla_abonnementsvilkar.pdf"
              className="underline"
              target="_blank"
            >
              <T _str="the subscription terms" />
            </a>
          }
        />
      </Checkbox>

      {loading && (
        <p className="text-center text-sm text-text-secondary pb-4">
          {t("Payment could be slow ⌛")}
        </p>
      )}
    </div>
  );
};

export default OrderSummary;

const InfoLine = ({ name, value, config = { type: "" } }) => {
  const { type } = config;

  const priceFormatted = usePriceFormatter(value);
  const targetValue = type === "money" ? priceFormatted : value;

  return (
    <p className="flex justify-between pt-1 border-b pb-1 text-xs text-text-secondary">
      <span>{name}</span>
      <span>{targetValue}</span>
    </p>
  );
};
