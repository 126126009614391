import { stringifyOptions } from "@kanpla/system";
import { cloneDeep, pick, setWith } from "lodash";
import { WeeklyOrders } from "./atomWeeklyOrders";
import { Change, Changes } from "./changesAtom";

interface Props {
  loadedOrders: WeeklyOrders;
  changes: Changes;
}

export const stringifyChangeInADay = (
  line: Pick<Change, "productId" | "options">
) => `${line.productId}${stringifyOptions(line.options)}`;

export const applyChangesToOrders = ({ loadedOrders, changes }: Props) => {
  // 0. Deep clone to avoid mutability issues
  const loadedOrdersClone = cloneDeep(loadedOrders);

  // 1. Map through the changes
  const finalOrders = changes.reduce((acc, change) => {
    // 1.1 Filter only lines for the day
    const { dayIndex } = change;
    const otherLines = acc[dayIndex]?.filter(
      (orderLine) =>
        stringifyChangeInADay(orderLine) !== stringifyChangeInADay(change)
    );

    // 1.2 Add the new line
    const newOrderLines = [
      ...(otherLines || []),
      pick(change, ["amount", "productId", "options"]),
    ];

    // 1.3 Put it all together and return
    setWith(acc, dayIndex, newOrderLines, Object);
    return acc;
  }, loadedOrdersClone);

  return finalOrders;
};
