import { DayIndex } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useGetSoftDeadlineAmount } from "./useGetSoftDeadlineAmount";

export const useIsDisabledForSoftDeadline = () => {
  const { week } = useContainer(OrderingContext);
  const getSoftDeadlineAmount = useGetSoftDeadlineAmount();

  const someDaysAreBelow = () => {
    const someAreBelow = week.some((_, index) => {
      const dayIndex = index as DayIndex;
      const { availableChanges } = getSoftDeadlineAmount(dayIndex);
      const isValid = availableChanges >= 0;

      return !isValid;
    });

    return someAreBelow;
  };

  const disabled = someDaysAreBelow();

  return disabled;
};
