import { DrawerOrModal } from "@kanpla/ui";
import { useAtom, useAtomValue } from "jotai";
import { selectedPaymentMethodAtom } from "../../../mealplan2/basket/elements/selectedPaymentMethodAtom";
import AdyenPaymentComponent, {
  adyenPaymentCheckoutConfigAtom,
} from "./AdyenPaymentComponent";

export const AdyenCheckoutModal = () => {
  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);

  const [adyenCheckoutConfig, setAdyenCheckoutConfig] = useAtom(
    adyenPaymentCheckoutConfigAtom
  );

  if (selectedPaymentMethod !== "card") return null;
  if (!adyenCheckoutConfig) return null;

  return (
    <DrawerOrModal
      setOpen={() => setAdyenCheckoutConfig(null)}
      open={!!adyenCheckoutConfig}
      zMax
    >
      <AdyenPaymentComponent type="card" />
    </DrawerOrModal>
  );
};
