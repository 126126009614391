import { DayIndex } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { isStandardOrderingAtom } from "../../../Navigation";
import { loadedStandardOrdersAtom } from "../../../data/atomStandardOrders";
import { Change } from "../../../data/changesAtom";
import { valueFromOrders } from "./helpers/valueFromOrders";

interface Props {
  productId: Change["productId"];
  dayIndex: DayIndex;
  options: Change["options"];
  /** If there are variants and this is the default choice, only show total */
  showTotal?: boolean;
}

export const usePlaceholder = ({ productId, dayIndex, options }: Props) => {
  const isStandardOrdering = useAtomValue(isStandardOrderingAtom);

  const weekStandardOrders = useAtomValue(loadedStandardOrdersAtom);
  const standardOrderValue = valueFromOrders({
    productId,
    dayIndex,
    options,
    orders: weekStandardOrders,
  });

  if (isStandardOrdering) return 0;
  return standardOrderValue || 0;
};
