import { CombinedOfferItem } from "@kanpla/types";
import { groupBy } from "lodash";

interface Props {
  items: CombinedOfferItem[];
}

export const getItemCategories = ({ items }: Props) => {
  if (!Array.isArray(items))
    return { itemsWithCategory: [], allItemsWithCategory: [] };

  const filteredItems = items.filter(
    (item) => item.availableFor?.admin ?? true
  );

  const groupedItems = groupBy(filteredItems, "productId");

  // Get the dates if any of the applicable lines are available for each day
  const uniqItems = Object.values(groupedItems).map((items) => {
    const dates = Object.entries(items[0].dates || {}).reduce(
      (acc, [date, data]) => {
        const dateSeconds = Number(date);

        acc[dateSeconds] = data;

        const someAvailable = items.some(
          (item) => item.dates?.[dateSeconds].available
        );

        acc[dateSeconds].available = someAvailable;

        return acc;
      },
      {} as CombinedOfferItem["dates"]
    );

    return { ...(items[0] || {}), dates };
  });

  return {
    itemsWithCategory: uniqItems,
    allItemsWithCategory: filteredItems,
  };
};
