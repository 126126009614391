import { useT } from "@kanpla/system";
import { useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";

export const amountShownTakeFirstBannerAtom = atomWithStorage(
  "amount-shown-take-first-banner",
  0
);

export const TakeFirstBanner = () => {
  const t = useT();
  const amountShownTakeFirstBanner = useAtomValue(
    amountShownTakeFirstBannerAtom
  );
  const { hasKanplaGo, module } = useContainer(OrderingContext);

  const shouldShow =
    amountShownTakeFirstBanner < 4 &&
    hasKanplaGo &&
    !module?.plugins?.kanplaGo?.hidePickProducts;

  if (!shouldShow) return null;

  return (
    <div className="bg-yellow-50 text-yellow-900 border border-yellow-600 rounded-lg p-2 px-3 flex items-center mb-3">
      <div className="w-full flex justify-center">
        <p className="font-semibold">
          {t("Remember to grab the products before paying!")}
        </p>
      </div>
    </div>
  );
};
