import { deadlineProvider, isDatePastDeadline, useT } from "@kanpla/system";
import { SubscriptionPeriod } from "@kanpla/types";
import { DatePanelPicker, ProductSettingsHeader } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { deadlineInfoAtom } from "libs/ordering/src/lib/hooks/useDeadlineInfo";
import moment, { Moment } from "moment";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useContainer } from "unstated-next";
import { SubscriptionContext } from "..";
import { AppContext } from "../../contextProvider";

interface Props {
  periods: Array<SubscriptionPeriod>;
  productId: string;
  startDate: Moment;
  setStartDate: Dispatch<SetStateAction<Moment>>;
  isChoosingName: boolean;
  setSelectedPeriod: Dispatch<SetStateAction<SubscriptionPeriod>>;
}

const ChooseDate = (props: Props) => {
  const {
    periods,
    productId,
    isChoosingName,
    startDate,
    setStartDate,
    setSelectedPeriod,
  } = props;

  const valueProps = startDate.isValid()
    ? {
        value: startDate,
      }
    : {};

  const t = useT();
  // Util to change the localization of moment.js

  const { school } = useContainer(AppContext);
  const { module } = useContext(SubscriptionContext);
  const { timezone } = useAtomValue(deadlineInfoAtom);

  const allAvailablePeriods = periods.filter((period) =>
    period.products.map((p) => p.id).includes(productId)
  );

  const { deadline } = deadlineProvider({
    module,
    school,
    timezone,
  });

  const signupSeconds = useMemo(
    () =>
      allAvailablePeriods
        .map((period) => {
          return period.signupDates.filter((s) => {
            return !isDatePastDeadline({
              deadline,
              date: s,
              timezone,
            });
          });
        })
        .flat()
        .map((period) => period.seconds)
        .sort((a, b) => a - b),
    [allAvailablePeriods, deadline]
  );

  const updateStartDate = useCallback(
    (seconds: number) => {
      const time = moment.unix(seconds);
      setStartDate(time);
      const targetPeriod = allAvailablePeriods.find((p) =>
        p.signupDates.some((s) => s.seconds === seconds)
      );
      setSelectedPeriod(targetPeriod);
    },
    [allAvailablePeriods, setSelectedPeriod, setStartDate]
  );

  // Choose first available date by default
  useEffect(() => {
    const firstAvailableDateSeconds = signupSeconds[0];

    // Skip if there's no available date
    if (!firstAvailableDateSeconds) return;

    // Skip if the selected date is possible to choose
    if (signupSeconds.includes(startDate.unix())) return;

    updateStartDate(firstAvailableDateSeconds);
  }, [startDate, signupSeconds]);

  const validate = useCallback(
    (dateTime: Moment) => {
      const currSeconds = dateTime.unix();
      const isSignup = signupSeconds.includes(currSeconds);
      if (isSignup) return currSeconds;
      return false;
    },
    [signupSeconds]
  );

  return (
    <div className="flex flex-col justify-center">
      <div className="px-2">
        <ProductSettingsHeader
          title={t("{value} Start up date", {
            value: isChoosingName ? "2." : "1.",
          })}
        />
      </div>

      <div className="mx-auto my-8">
        <DatePanelPicker
          onChange={(dateTime: Moment) => {
            // Validate time
            const seconds = validate(dateTime);
            if (!seconds) return;
            updateStartDate(seconds);
          }}
          disabledDate={(dateTime) => {
            const valid = validate(dateTime);
            return !valid;
          }}
          {...valueProps}
        />
      </div>
    </div>
  );
};

export default ChooseDate;
