import { OrderingContext } from "@kanpla/ordering";
import { T, useT } from "@kanpla/system";
import { useAtomValue } from "jotai";
import { ReactNode } from "react";
import { useContainer } from "unstated-next";
import { deadlineInfoAtom } from "../lib/hooks/useDeadlineInfo";
import { default as JumpLink } from "../shared/JumpLink";

interface Props {
  children: ReactNode;
}

const NoMealplanWrapper = ({ children }: Props) => {
  const t = useT();
  const {
    noMealplan,
    hasOrdered,
    module: { hasMultipleOrdersPerDay },
    hasKanplaGo,
  } = useContainer(OrderingContext);
  const { defaultDateSeconds } = useAtomValue(deadlineInfoAtom);

  if (noMealplan)
    return (
      <section className="max-w-screen-xl mx-auto text-center flex flex-col items-center">
        <div
          style={{ minHeight: "70vh" }}
          className="flex items-center flex-col justify-center -mt-8"
        >
          <h2 className="font-semibold text-2xl md:text-3xl mb-2 text-text-secondary title-main">
            <T _str="The kitchen has no menu yet." />
            <br />
            <T _str="Come back later." />
          </h2>
          {!hasKanplaGo && (
            <p className="text-text-secondary">
              {defaultDateSeconds ? (
                <>
                  <T _str="If you're hungry, you can order for " />
                  <JumpLink />!
                </>
              ) : (
                <span>{t("There are no future mealplans yet.")}</span>
              )}
            </p>
          )}
        </div>
      </section>
    );

  const allowMultipleOrdersPerDay = hasMultipleOrdersPerDay ?? true;
  if (hasOrdered && !allowMultipleOrdersPerDay)
    return (
      <div className="md:min-h-[70vh]">
        <p className="text-text-disabled mt-4 text-center md:text-left">
          {t(
            "You can't add any more tickets for today. Come back tomorrow to find them again"
          )}{" "}
          😊
        </p>
      </div>
    );

  return <>{children}</>;
};

export default NoMealplanWrapper;
