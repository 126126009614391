import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { DayIndex } from "@kanpla/types";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { loadingWeeklyDocumentsAtom } from "../data/useLoadWeeklyOrders";
import { firstColumnStyles } from "./Week";
import { WeekTotalsItem } from "./WeekTotalsItem";

export const WeekTotals = () => {
  const t = useT();
  const { week } = useContainer(OrderingContext);
  const weeklyDataLoading = useAtomValue(loadingWeeklyDocumentsAtom);

  return (
    <div className="flex flex-wrap items-center bg-background-secondary px-4 py-2 rounded-lg mb-2 justify-between font-medium">
      <div className={`${firstColumnStyles} h300`}>
        {t("Total")}:{" "}
        <FontAwesomeIcon
          icon={faSpinnerThird}
          spin
          className={classNames(
            "ml-2 transition-opacity",
            weeklyDataLoading ? "opacity-100" : "opacity-0"
          )}
        />
      </div>
      {week.map((date, dayIndex) => (
        <WeekTotalsItem
          key={String(date.seconds + dayIndex)}
          dayIndex={dayIndex as DayIndex}
        />
      ))}
    </div>
  );
};
