import { getEditStandardOrderStrings, useT } from "@kanpla/system";
import { DayIndex, StandardOrderLines } from "@kanpla/types";
import { DrawerOrModal, message } from "@kanpla/ui";
import { atom, useAtom } from "jotai";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import {
  useFlexStandardOrders,
  useReloadFlexStandardOrders,
} from "../../lib/flex/useFlexStandardOrders";
import ProductsAvailability from "../ProductsAvailability";
import DayProductItem from "./DayProductItem";

export const openStandardsModalAtom = atom(false);
export const standardOrdersModalDataAtom = atom<StandardOrderLines>({});

const StandardOrderModal = () => {
  const t = useT();
  const standardOrdersReloader = useReloadFlexStandardOrders();
  const [open, setOpen] = useAtom(openStandardsModalAtom);
  const { standardOrderLines, submitStandardOrder } = useFlexStandardOrders();
  const [standardOrderLinesData, setStandardOrderLinesData] = useAtom(
    standardOrdersModalDataAtom
  );

  useEffect(() => {
    if (isEqual(standardOrderLinesData, standardOrderLines)) return;
    setStandardOrderLinesData(standardOrderLines);
  }, [standardOrderLines]);

  const { title, subtitle } = getEditStandardOrderStrings({ t, isBulk: false });
  const { week } = useContainer(OrderingContext);

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={title}
      subtitle={subtitle}
      actions={[
        {
          label: t("Save"),
          type: "primary",
          onClick: async () => {
            message.loading(t("Standards are being saved..."), {
              autoClose: false,
            });

            await submitStandardOrder({
              standardOrderLines: standardOrderLinesData,
            });

            message.dismiss();
            message.success(t("New orders saved"), {
              messageId: "success-new-standard-saved",
            });
            standardOrdersReloader();
            setOpen(false);
          },
        },
      ]}
      className="max-w-xl"
    >
      <ProductsAvailability classNames="pb-3" isStandard />
      <div className="md:max-h-full">
        {week.map((day, dayIndex) => (
          <DayProductItem
            key={day.seconds}
            orderLines={standardOrderLinesData[dayIndex as DayIndex] || []}
            dayIndex={dayIndex}
            dateSeconds={day.seconds}
          />
        ))}
      </div>
    </DrawerOrModal>
  );
};

export default StandardOrderModal;
