import { useT } from "@kanpla/system";
import { _FrontendModule } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import { useState } from "react";
import KanplaGoIntro from "./KanplaGoIntro";

interface Props {
  module: _FrontendModule;
  align?: "left" | "center" | "right";
}

const ModuleDescription = ({ module, align }: Props) => {
  const t = useT();
  const [kanplaGoIntroOpen, setKanplaGoIntroOpen] = useState(false);

  const text = module?.text;

  const isKanplaGo = module?.plugins?.kanplaGo?.active;
  const hideSkipQueue = isKanplaGo && module?.plugins?.kanplaGo?.hideSkipQueue;

  const kanplaGoTitle =
    text?.["kanplaGoHeader/title"] || t("Order in the canteen");
  const kanplaGoSubtitle =
    text?.["kanplaGoHeader/subtitle"] || t("Buy food with just a swipe.");
  const kanplaGoButtonText =
    text?.["kanplaGoHeader/readMoreButton"] || t("Read more");

  const descTitle =
    (isKanplaGo && kanplaGoTitle
      ? kanplaGoTitle
      : text?.["description/title"]) ||
    module?.displayName ||
    module?.name;
  const descText =
    isKanplaGo && kanplaGoSubtitle
      ? kanplaGoSubtitle
      : text?.["description/text"];

  if (!descTitle && !descText) return null;

  return (
    <div
      className={`px-0 mb-6 md:mb-8 text-center ${
        align === "left"
          ? "sm:text-left sm:mx-0 sm:px-0"
          : align === "right"
          ? "sm:text-right sm:mx-0 sm:px-0"
          : ""
      } text-text-secondary mx-auto max-w-md`}
    >
      {descTitle !== "" && (
        <h2 className="title-secondary mx-auto leading-tight text-primary-main">
          {descTitle}
        </h2>
      )}
      <div className="flex flex-col">
        {descText !== "" && (
          <p className="mt-2 text-text-secondary text-sm whitespace-pre-line">
            {descText}
          </p>
        )}
        {isKanplaGo &&
          kanplaGoButtonText &&
          !module?.plugins?.kanplaGo?.hideGuide && (
            <div>
              <Button
                type="primary"
                className="mt-4 w-fit"
                onClick={() => setKanplaGoIntroOpen(true)}
                dataCy="button-open-kanplago-intro"
              >
                {kanplaGoButtonText}
              </Button>
              <KanplaGoIntro
                automaticallyOpen
                open={kanplaGoIntroOpen}
                setOpen={setKanplaGoIntroOpen}
                hideSkipQueue={hideSkipQueue}
                module={module}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default ModuleDescription;
