import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { Alert, AvailabilityProduct } from "@kanpla/ui";
import { Collapse } from "antd";
import { default as classNames, default as classnames } from "classnames";
import { isEmpty } from "lodash";
import { useState } from "react";

interface Props {
  isStandard: boolean;
  sortedItems: CombinedOfferItem[];
}

const ProductsAvailabilityContent = ({ isStandard, sortedItems }: Props) => {
  const t = useT();

  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const { wrapperWarningIconStyles, wrapperWarningIconClassNames } = {
    wrapperWarningIconStyles: {
      borderRadius: isCollapseOpen ? "0.4rem 0 0 0" : "0.4rem 0 0 0.4rem",
    },
    wrapperWarningIconClassNames: classnames({
      "delay-150": !isCollapseOpen,
    }),
  };

  return isStandard ? (
    <Collapse
      onChange={(panels) => {
        setIsCollapseOpen(!isEmpty(panels));
      }}
      expandIconPosition="end"
      className="border-warning-main bg-background-primary availability-collapse"
    >
      <Collapse.Panel
        header={
          <div>
            <div className="flex">
              <div
                className={classNames(
                  "bg-warning-main flex justify-center pt-2 transition-all",
                  wrapperWarningIconClassNames
                )}
                style={{
                  height: "52px",
                  width: "36px",
                  ...wrapperWarningIconStyles,
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  className="text-warning-contrast"
                />
              </div>
              <span className="text-warning-main pl-2 flex items-center font-semibold">
                {t("Availability info")}
              </span>
            </div>
          </div>
        }
        key="availability"
        className="bg-background-primary"
      >
        <div className="text-warning-main">
          <ul>
            {sortedItems.map((product) => (
              <AvailabilityProduct product={product} t={t} />
            ))}
          </ul>
        </div>
      </Collapse.Panel>
    </Collapse>
  ) : (
    <Alert
      message={
        <div>
          <ul>
            {(sortedItems as CombinedOfferItem[]).map((product) => (
              <AvailabilityProduct product={product} t={t} />
            ))}
          </ul>
        </div>
      }
      type="warning"
    />
  );
};

export default ProductsAvailabilityContent;
