import { T } from "@kanpla/system";
import { isInteger } from "lodash";
import React from "react";
import Info from "./Info";

export const Wrapper = React.forwardRef<
  HTMLDivElement,
  JSX.IntrinsicElements["div"]
>((props, ref) => {
  return (
    <div
      className="w-full h-full bg-background-primary border border-secondary-main rounded-md p-4 lg:p-6 flex flex-col justify-center max-w-[500px] min-h-[200px]"
      ref={ref}
      {...props}
    />
  );
});

interface HeaderProps {
  totalWeekAmount: number;
  isPreview?: boolean;
}

export const Header = ({ totalWeekAmount, isPreview = false }: HeaderProps) => {
  return (
    <div className="inline-flex justify-between">
      <div className="flex flex-col">
        {isPreview ? (
          <div className="rounded-md w-[150px] h-4 loader" />
        ) : (
          <h3 className="text-lg text-text-secondary">
            <T _str="Food waste last week:" />
          </h3>
        )}
        {isPreview ? (
          <div className="rounded-md w-[60px] h-8 loader mt-2" />
        ) : (
          <h1 className="text-[32px] text-text-primary font-semibold">
            {isInteger(totalWeekAmount)
              ? `${totalWeekAmount} kg`
              : `${totalWeekAmount.toFixed(1)} kg`}
          </h1>
        )}
      </div>
      {!isPreview && <Info />}
    </div>
  );
};
