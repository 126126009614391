import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import { useFlexStandardOrders } from "../../lib/flex/useFlexStandardOrders";
import { openStandardsModalAtom } from "./StandardOrderModal";

const StandardOrderButton: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const t = useT();

  const setStandardModalOpen = useSetAtom(openStandardsModalAtom);
  const { standardOrderLines } = useFlexStandardOrders();

  const hasStandard = Object.values(standardOrderLines || {}).some(
    (dayStandard) => !isEmpty(dayStandard)
  );

  const text = hasStandard
    ? t("Change standard choice")
    : t("Set standard choice");

  return (
    <div className="pt-6">
      <Button
        dataCy="change-standard-order"
        onClick={() => setStandardModalOpen(true)}
        type="primary"
        className="w-auto"
      >
        <FontAwesomeIcon icon={faList} className="mr-2" />
        {text}
      </Button>
      {children}
    </div>
  );
};

export default StandardOrderButton;
