import { Homescreen } from "@kanpla/types";
import { useContext, useMemo } from "react";
import { HomescreenContext } from "..";
import { serializer } from "../utils";

const Root = ({ singleBlock }: { singleBlock: Homescreen.ContentInner }) => {
  const { content } = useContext(HomescreenContext);

  const editorNodes = useMemo(() => singleBlock.nodes, [singleBlock?.nodes]);

  return (
    <div className="w-full">
      {editorNodes.map((nodeId) => {
        const node = content[nodeId];

        return serializer(node, nodeId);
      })}
    </div>
  );
};

export default Root;
