import { hasAccessToModule, useT } from "@kanpla/system";
import {
  Homescreen as HomescreenType,
  Product,
  _FrontendModule,
} from "@kanpla/types";
import { Button, DotDotDot } from "@kanpla/ui";
import { isEmpty } from "lodash";
import { createContext, useEffect } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { serializer } from "./utils";

interface ExtraContentProps {
  flexProducts: Array<Product>;
}

interface Props {
  module: _FrontendModule;
  contextExtras?: Partial<ExtraContentProps>;
}

interface ContextProps extends Partial<ExtraContentProps> {
  content: HomescreenType.Content;
  module: _FrontendModule;
}

export const HomescreenContext = createContext<Partial<ContextProps>>({});

const Homescreen = ({ module, contextExtras = {} }: Props) => {
  const t = useT();

  const {
    school,
    child,
    modules,
    setTimeNavigation,
    setSchoolId,
    setModuleId,
  } = useContainer(AppContext);

  const { other } = hasAccessToModule({
    module: module,
    child,
    school,
  });

  const startOver = () => {
    if (!modules || !school) return;

    const availableModule = modules.find((m) =>
      module?.scope?.generatedSchoolIds?.includes(school?.id)
    );

    setSchoolId(school?.id);
    setModuleId(availableModule?.id);
  };

  useEffect(() => {
    setTimeNavigation("none");
  }, []);

  if (!other)
    return (
      <section className="max-w-screen-xl mx-auto text-center flex flex-col items-center">
        <div
          style={{ minHeight: "70vh" }}
          className="flex items-center flex-col justify-center -mt-8"
        >
          <span style={{ fontSize: 120 }} role="img" aria-label="">
            ✋🏻
          </span>
          <h2 className="font-semibold text-2xl md:text-3xl mb-2 text-text-secondary title-main">
            {t("You don't have access to this page.")}
          </h2>
          <Button
            type="primary"
            className="mt-8"
            onClick={startOver}
            dataCy="btn-homescreen-start-over"
            shape="solid"
          >
            {t("Start over")}
          </Button>
        </div>
      </section>
    );

  if (!module)
    return (
      <p>
        {t("Loading")} <DotDotDot />
      </p>
    );

  const content = JSON.parse(
    JSON.stringify(module?.content || {}).replace(/&nbsp;/g, "")
  ) as HomescreenType.Content;

  if (isEmpty(content))
    return (
      <section className="max-w-screen-xl mx-auto text-center flex flex-col items-center">
        <div
          style={{ minHeight: "70vh" }}
          className="flex items-center flex-col justify-center -mt-8"
        >
          <span style={{ fontSize: 120 }} role="img" aria-label="">
            📭
          </span>
          <h2 className="font-semibold text-2xl md:text-3xl mb-2 text-text-secondary title-main">
            {t("This website is empty for now")}
          </h2>
        </div>
      </section>
    );

  return (
    <div className="w-full max-w-7xl wrapper mt-4">
      <HomescreenContext.Provider value={{ content, module, ...contextExtras }}>
        {content?.ROOT && serializer(content.ROOT, "0")}
      </HomescreenContext.Provider>
    </div>
  );
};

export default Homescreen;
