import { useT } from "@kanpla/system";
import classNames from "classnames";
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useContainer } from "unstated-next";
import { ExportContext } from "..";
import EmployeeStats from "./EmployeeStats";
import Menu from "./Menu";

const Header = () => {
  const t = useT();

  const { items, viewEmployeeOrders, shouldScroll, meetingView, mobile } =
    useContainer(ExportContext);

  const adminView = viewEmployeeOrders || meetingView;

  const parentRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classNames(
        "w-full bg-background-primary transition-all ease-in-out md:pt-8 md:pb-4 md:px-8 z-10",
        { "border-b border-secondary-light": !shouldScroll },
        { "shadow-md": shouldScroll },
        { "pb-4": !viewEmployeeOrders }
      )}
      ref={parentRef}
      // Arbitrarily setting the values here, otherwise the header is not gonna be smoothly animated
      // It's is based on the height of the `EmployeeStats` component
      style={
        viewEmployeeOrders && !mobile
          ? { height: shouldScroll ? 149 : 212 }
          : {}
      }
    >
      <div
        className={classNames("flex justify-between px-6 md:px-0 w-full", {
          "flex-col": !adminView || mobile,
        })}
      >
        <h1
          className={classNames("title-secondary text-center md:mr-2", {
            "md:text-start w-fit": adminView,
          })}
        >
          {t("Orders")}
        </h1>
        <Menu />
      </div>

      {viewEmployeeOrders &&
        (items.length < 10 || mobile ? (
          <div className="w-auto h-auto ml-6 md:ml-0 mb-4 md:mb-0">
            <EmployeeStats />
          </div>
        ) : (
          <CSSTransition
            nodeRef={nodeRef}
            in={!shouldScroll}
            classNames="animate-slide-down"
            timeout={400}
            unmountOnExit
          >
            <div className="w-auto h-auto ml-6 md:ml-0" ref={nodeRef}>
              <EmployeeStats />
            </div>
          </CSSTransition>
        ))}
    </div>
  );
};

export default Header;
