import {
  faCircleCheck,
  faCircleExclamation,
  faCirclePlay,
  faCircleXmark,
  faRefresh,
} from "@fortawesome/pro-duotone-svg-icons";

export const subscriptionStatus = (t) => {
  return {
    active: {
      text: t("Active subscription"),
      icon: faCircleCheck,
      background: "bg-primary-main hover:bg-primary-dark",
    },
    automatic: {
      text: t("Automatic renewal"),
      icon: faRefresh,
      background: "bg-primary-main hover:bg-primary-dark",
    },
    expiring: {
      text: t("About to expire"),
      icon: faCircleExclamation,
      background: "bg-orange-500 hover:bg-orange-600",
    },
    ended: {
      text: t("Ended subscription"),
      icon: faCircleXmark,
      background: "bg-red-600 hover:bg-red-700",
    },
    new: {
      text: t("New subscription"),
      icon: faCirclePlay,
      background: "bg-blue-600 hover:bg-blue-700",
    },
  };
};
