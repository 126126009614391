import { Homescreen } from "@kanpla/types";
import { CustomSlider } from "@kanpla/ui";
import { useContext } from "react";
import { useContainer } from "unstated-next";
import { HomescreenContext } from "..";
import { AppContext } from "../../contextProvider";
import SectionHeader from "../elements/SectionHeader";
import { BlockProps, serializer } from "../utils";

interface Props extends BlockProps {
  scrollInfo?: {
    rows: 1 | 2;
    direction: "scrollable" | "stacked";
  };
  title?: string;
  subtitle?: string;
  buttonProps?: Homescreen.ContentInner;
}

export const SectionInner = ({
  singleBlock,
  scrollInfo,
  title,
  subtitle,
}: Props) => {
  const { child } = useContainer(AppContext);
  const { content } = useContext(HomescreenContext);

  const allNodeIds = singleBlock?.nodes;

  /** Filter node ids only for the ones available */
  const nodeIds = allNodeIds.filter((nodeId) => {
    const node = content[nodeId];
    const scope = node?.props?.scope;

    const isEverywhere = !scope;
    if (isEverywhere) return true;

    const childSchoolId = child?.schoolId;
    if (!childSchoolId) return false;

    const hasSchoolId = scope?.generatedSchoolIds?.includes(childSchoolId);
    if (!hasSchoolId) return false;

    const scopeSelectorStrings =
      scope?.schools?.[childSchoolId]?.selectorStrings || [];

    // Show banner if the school is available and no selector specifications
    if (!scopeSelectorStrings.length && hasSchoolId) return true;

    // Otherwise show if the child has the right selector
    if (scopeSelectorStrings.includes(child?.groupName)) return true;

    return false;
  });

  if (!nodeIds.length) return null;

  if (scrollInfo?.direction === "scrollable")
    return (
      <main className="rounded-lg -mx-4 md:-mx-4 lg:-mx-8 p-2 px-4 md:px-4 lg:px-8 relative overflow-hidden">
        <CustomSlider
          title={title}
          subtitle={subtitle}
          items={nodeIds}
          rows={scrollInfo?.rows || 1}
          renderer={(nodeId) => {
            const node = content[nodeId];

            return serializer(node, nodeId);
          }}
        />
      </main>
    );

  return (
    <main className="rounded-lg -mx-2 md:-mx-4 lg:-mx-8 p-2 px-2 md:px-4 lg:px-8 relative ">
      <div
        className={`grid grid-cols-1 ${
          nodeIds.length > 1 ? "sm:grid-cols-2" : ""
        } ${
          nodeIds.length > 2
            ? "md:grid-cols-3 md:gap-4"
            : nodeIds.length > 1
            ? "md:grid-cols-2 md:gap-8"
            : ""
        } gap-2 `}
      >
        {nodeIds.map((nodeId) => {
          const node = content[nodeId];

          return serializer(node, nodeId);
        })}
      </div>
    </main>
  );
};

const Section = ({ singleBlock }: Props) => {
  const { content } = useContext(HomescreenContext);

  const buttonArea = content[singleBlock?.linkedNodes?.buttonArea];
  const sectionData = content[singleBlock?.linkedNodes?.sectionData];

  const { direction, rows } = singleBlock.props;

  const buttonProps = content[buttonArea?.nodes?.[0]];

  return (
    <section className="w-full">
      {direction !== "scrollable" && (
        <SectionHeader
          title={singleBlock.props?.title}
          subtitle={singleBlock.props?.subtitle}
        />
      )}
      <SectionInner
        title={singleBlock.props?.title}
        subtitle={singleBlock.props?.subtitle}
        singleBlock={sectionData}
        buttonProps={buttonProps}
        scrollInfo={{ direction, rows }}
      />
    </section>
  );
};

export default Section;
