import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const UserIcon: React.FC<{ onClick?: () => void }> = ({ onClick = null }) => {
  const { child, isChildSalesplace, children } = useContainer(AppContext);

  const oneChild = children.length === 1;
  const initials = child?.name
    ?.split(" ")
    .map((word) => word[0])
    .filter((ch) => /([A-Za-z])/.test(ch))
    .join("")
    .slice(0, 2);

  if (isChildSalesplace || oneChild)
    return (
      <div
        className="w-10 h-10 mr-1 md:mr-0 rounded-full p-2 bg-secondary-main border border-secondary-dark flex items-center justify-center hover:bg-secondary-dark"
        id="action-menuOpen"
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faUser} className="text-secondary-contrast" />
      </div>
    );

  return (
    <div
      className="w-10 h-10 mr-1 md:mr-0 rounded-full p-2 bg-secondary-main border border-secondary-dark flex items-center justify-center hover:bg-secondary-dark transition-all ease-in-out"
      id="action-menuOpen"
      onClick={onClick}
    >
      <h1 className="font-medium text-lg text-center text-secondary-contrast">
        {initials}
      </h1>
    </div>
  );
};

export default UserIcon;
