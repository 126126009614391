import { CombinedOfferItem } from "@kanpla/types";
import { entries, filter, size } from "lodash";

export const offerHasMultipleCategories = (
  offer: Record<string, CombinedOfferItem[]>
) => {
  const offerEntries = entries(offer || {});
  const filteredOfferEntries = filter(
    offerEntries,
    ([_, items]) => size(items) > 0
  );

  return size(offerEntries) > 1 && size(filteredOfferEntries) > 1;
};
