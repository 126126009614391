import { CombinedOfferItem, Product } from "@kanpla/types";
import { t } from "..";

interface Props {
  product?: CombinedOfferItem;
  dateSeconds: number;
  productName: string;
  productId: Product["id"];
}

export const getProductNameForBasket = ({
  product,
  dateSeconds,
  productName,
  productId,
}: Props): string => {
  if (productId.startsWith("ad-hoc-")) {
    return productName || t("Bought in the canteen");
  }

  const currentDay = product?.dates?.[dateSeconds];
  const productNameBase = currentDay?.menu?.name || productName;
  const nameToDisplay =
    productNameBase?.length > 50
      ? `${productNameBase.substring(0, 50)}...`
      : productNameBase;

  return nameToDisplay;
};
