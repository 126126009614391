import { useT } from "@kanpla/system";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { dateInfoAtom } from "../lib/hooks/useDateInfo";
import { deadlineInfoAtom } from "../lib/hooks/useDeadlineInfo";
import UIJumpLink from "./JumpLink";

// Wraps the offer and determines if it should be displayed or not.
// Otherwise, shows a link to the next available date.

const PastDateWrapper: FC = ({ children }) => {
  const { defaultDateSeconds, latestIndividualDeadline } =
    useAtomValue(deadlineInfoAtom);
  const { isPastDeadline, isPastTodayIndividualDeadline } =
    useAtomValue(dateInfoAtom);
  const t = useT();

  if (isPastDeadline && isPastTodayIndividualDeadline(latestIndividualDeadline))
    return (
      <section className="max-w-screen-xl mx-auto text-center flex flex-col items-center relative">
        <div
          style={{ minHeight: "70vh" }}
          className="flex items-center flex-col justify-center -mt-8 relative z-10"
        >
          <span style={{ fontSize: 120 }} role="img">
            ⌛️
          </span>
          <h2 className="font-semibold text-2xl md:text-3xl mb-2 text-text-secondary title-main">
            {t("It's too late to order for this day.")}
          </h2>
          {defaultDateSeconds ? (
            <p>
              {t("However, it is still possible to order to")} <UIJumpLink />
            </p>
          ) : (
            t("There are no future mealplans yet.")
          )}
        </div>
      </section>
    );

  return <div>{children}</div>;
};

export default PastDateWrapper;
