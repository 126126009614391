import { useT } from "@kanpla/system";
import {
  CombinedOfferItem,
  FlexOption,
  OfferProductAvailability,
  OrderLine,
} from "@kanpla/types";
import { DrawerOrModal } from "@kanpla/ui";
import { atom } from "jotai";
import React, { Dispatch, SetStateAction, useState } from "react";
import { ProductOptions } from "./ProductOptions";

export const selectedProductOptions = atom<OrderLine["options"] | null>(null);

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  product: CombinedOfferItem;
  productAvailability: OfferProductAvailability;
  mealOptions?: FlexOption[];
  value: OrderLine["options"];
  onChange: (newOrderOptions: OrderLine["options"]) => void;
}

export const VariantsModal = ({
  product,
  productAvailability,
  mealOptions,
  value,
  open,
  setOpen,
  onChange,
}: Props) => {
  const t = useT();

  const [orderOptions, setOrderOptions] = useState<OrderLine["options"]>(
    value || {}
  );

  const options = mealOptions?.filter(
    (option: FlexOption) =>
      productAvailability?.menu?.options?.includes(option?.id) ||
      product?.options?.includes(option?.id)
  );

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      actions={[
        {
          label: t("Confirm"),
          onClick: () => {
            setOpen(false);
            onChange(orderOptions);
          },
        },
      ]}
      noPadding
      floatingActions
    >
      <div className="px-8">
        <ProductOptions
          product={{
            color: product?.color,
            vatRate: product?.vatRate,
          }}
          options={options}
          value={orderOptions}
          onChange={(newOptionChoices) => setOrderOptions(newOptionChoices)}
        />
      </div>
    </DrawerOrModal>
  );
};
