import { atom } from "jotai";
import { WeeklyOrders } from "./atomWeeklyOrders";
import { allLoadedOrdersAtom, emptyWeeklyOrders } from "./loadedOrdersAtom";

// 1. Load standard orders from an internal API using Jotai's atomsWithQuery

export const loadedStandardOrdersAtom = atom<WeeklyOrders>((get) => {
  const allLoadedOrders = get(allLoadedOrdersAtom);
  return allLoadedOrders["standard"] || emptyWeeklyOrders;
});
