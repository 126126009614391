import { useT } from "@kanpla/system";
import { _FrontendModule } from "@kanpla/types";
import { Form, Input } from "@kanpla/ui";

interface Props {
  module: _FrontendModule;
  basketKey: string;
}

export const MeetingName = ({ module, basketKey }: Props) => {
  const t = useT();

  if (module?.flow !== "meeting") return null;

  const requiredMeetingName = module?.meetingNameRequired ?? true;

  return (
    <Form.Item
      name={`${basketKey}.name`}
      label={t("Meeting's name")}
      required={requiredMeetingName}
      rules={
        requiredMeetingName
          ? {
              required: t("{fieldName} is required", {
                fieldName: t("Meeting name"),
              }),
            }
          : {}
      }
    >
      <Input className="mt-1" placeholder={t("Meeting's name")} />
    </Form.Item>
  );
};
