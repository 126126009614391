import { useBasketPreventClick } from "@kanpla/ordering";
import { isWithCredit, useAppPadding, useT } from "@kanpla/system";
import { NewLogo, Space } from "@kanpla/ui";
import classnames from "classnames";
import { atom, useAtom } from "jotai";
import BalanceDisplay from "libs/ordering/src/shared/navbar/BalanceDisplay";
import { useEffect, useState } from "react";
import { animated } from "react-spring";
import { useContainer } from "unstated-next";
import ReceiptButton from "./ReceiptButton";
import Tabs from "./Tabs";
import AnonymousMenuButton from "./anonymous/AnonymousMenuButton";
import AnonymousTabs from "./anonymous/AnonymousTabs";
import { AppContext } from "./contextProvider";
import NotificationCenter from "./notifications";
import BackButton from "./router/BackButton";
import ChildSettings from "./settings";

const NavbarInner = () => {
  const { mobile, auth, userId, appLoading } = useContainer(AppContext);

  if (mobile) {
    if (auth.loading || appLoading)
      return <div className="loader w-9 h-9 rounded-full mr-3" />;

    if (!auth?.user) return <AnonymousMenuButton />;

    return (
      <Space>
        <ReceiptButton userId={userId} />
        <NotificationCenter />
        <ChildSettings />
      </Space>
    );
  }

  if (auth.loading || appLoading)
    return <div className="loader w-24 h-9 rounded ml-2" />;

  if (auth.user)
    return (
      <Space className="items-center">
        <ReceiptButton userId={userId} />
        <NotificationCenter />
        <ChildSettings />
      </Space>
    );

  return <AnonymousMenuButton />;
};

export const backLinkAtom = atom<{
  fromModuleId?: string;
  toModuleId?: string;
}>({});

const Navbar = () => {
  const t = useT();

  const { appLoading, mobile, auth, module, moduleId, setModuleId, schoolId } =
    useContainer(AppContext);

  const withCredit = isWithCredit(module);

  const confirm = useBasketPreventClick({ disabled: false });

  const { shouldAddPadding } = useAppPadding();

  const [backLink, setBackLink] = useAtom(backLinkAtom);
  const hasBackLinkQuery =
    backLink?.fromModuleId?.length && backLink?.fromModuleId === moduleId;

  const back = async () => {
    await confirm(module);
    setModuleId(backLink?.toModuleId);
  };

  const cleanBackLinkIfModuleChanges = () => {
    const shouldCleanBackLink = moduleId !== backLink?.fromModuleId;
    if (shouldCleanBackLink) setBackLink({});
  };

  useEffect(() => {
    cleanBackLinkIfModuleChanges();
  }, [moduleId]);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => setMounted(true), 300);
  }, []);

  return (
    <animated.div
      id="navbar"
      className={classnames(
        "transition-transform duration-500 transform bg-background-primary lg:border-b z-30 relative",
        { "-translate-y-full": !mounted, "translate-y-0": mounted }
      )}
    >
      {/* IOS statusbar overlay */}
      <div
        className="bg-background-primary bg-opacity-60 backdrop-blur-sm z-max fixed top-0 inset-x-0"
        style={{
          height: `calc(var(--status-bar-height) - ${
            shouldAddPadding ? "2px" : "0px"
          } )`,
          transition: "0.3s ease-in-out",
        }}
      />

      <div
        className="px-2 lg:px-4 flex justify-between items-center bg-background-primary border-divider-main text-text-secondary container"
        style={{
          height: 64,
          marginTop: `calc(var(--status-bar-height) - ${
            shouldAddPadding ? "2px" : "0px"
          } )`,
        }} // increase height to 62 if using new switches on the navbar
      >
        {!appLoading ? (
          hasBackLinkQuery ? (
            <BackButton label={t("Back")} onClick={back} />
          ) : (
            <div className="mx-1">
              <button onClick={() => setModuleId(null)}>
                <NewLogo
                  className="object-left transform scale-90 md:transform-none flex justify-center items-center"
                  style={{
                    maxWidth: 130,
                    maxHeight: 42,
                    height: 42,
                  }}
                  horizontal
                  schoolId={schoolId}
                />
              </button>
            </div>
          )
        ) : (
          <div className="h-8 w-28 rounded loader ml-2 lg:ml-4" />
        )}

        <div className="ml-auto flex items-center">
          {withCredit && mobile && (
            <div className="mr-3">
              <BalanceDisplay />
            </div>
          )}
        </div>
        {!mobile && (auth.user ? <Tabs /> : <AnonymousTabs />)}
        <NavbarInner />
      </div>
    </animated.div>
  );
};

export default Navbar;
