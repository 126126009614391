import { DrawerOrModal } from "@kanpla/ui";
import { Orders } from "./index";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  viewEmployeeOrders?: boolean;
  meetingView?: boolean;
  limitedModuleIds?: string[];
}

export const OrdersModal = ({
  open,
  setOpen,
  viewEmployeeOrders = false,
  meetingView = false,
  limitedModuleIds = [],
}: Props) => {
  const moreOptions = viewEmployeeOrders || meetingView;

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      noPadding
      modalProps={{
        maxWidth: moreOptions ? "max-w-3xl" : "max-w-md",
        className: moreOptions ? "!h-full" : "!h-auto",
        style: { maxHeight: 800 },
      }}
    >
      <div className="w-full h-full flex flex-col">
        <Orders
          viewEmployeeOrders={viewEmployeeOrders}
          meetingView={meetingView}
          limitedModuleIds={limitedModuleIds}
        />
      </div>
    </DrawerOrModal>
  );
};
