import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import moment from "moment";
import React, { useMemo } from "react";

interface Props {
  t?: ReturnType<typeof useT>;
  product: CombinedOfferItem;
}

export const AvailabilityProduct = ({ product, t = () => null }: Props) => {
  const getAvailabilityProductString = () => {
    const { fromSeconds, toSeconds } = product;

    if (!fromSeconds && !toSeconds) return null;

    const values = {
      fromSecondsAndToSeconds:
        "will be available from {fromSeconds} to {toSeconds}",
      fromSeconds: "will be available from {fromSeconds}",
      toSeconds: "will be available until the {toSeconds}",
    };

    const fromSecondsDate = moment.unix(fromSeconds as number).format("ll");
    const toSecondsDate = moment.unix(toSeconds as number).format("ll");

    if (fromSeconds && toSeconds)
      return t(values.fromSecondsAndToSeconds, {
        fromSeconds: fromSecondsDate,
        toSeconds: toSecondsDate,
      });

    if (fromSeconds)
      return t(values.fromSeconds, {
        fromSeconds: fromSecondsDate,
      });

    if (toSeconds)
      return t(values.toSeconds, {
        toSeconds: toSecondsDate,
      });

    return "";
  };

  const availabilityString = useMemo(
    () => getAvailabilityProductString(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product]
  );

  if (availabilityString === null) return null;

  return (
    <div className="flex items-center">
      <FontAwesomeIcon
        icon={faCircle}
        style={{ fontSize: "7px" }}
        className="mr-2"
      />
      <li>
        <p>
          <span className="font-semibold">{product?.name}</span>{" "}
          {availabilityString}
        </p>
      </li>
    </div>
  );
};
