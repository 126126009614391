import { OrderingContext } from "@kanpla/ordering";
import { useAppPadding } from "@kanpla/system";
import { bottomNavigationHeightAtom } from "@kanpla/ui";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { ReactNode, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useContainer } from "unstated-next";
import { basketAtom } from "../../shared/basket/useBasket";

interface Props {
  hideActions?: boolean;
  children: ReactNode;
  actionsClassName?: string;
}

const ActionsWrapper = ({ children, actionsClassName }: Props) => {
  const nodeRef = useRef(null);
  const { mobile } = useContainer(OrderingContext);
  const basket = useAtomValue(basketAtom);

  const shouldShow = !isEmpty(basket);
  const bottomNavigationHeight = useAtomValue(bottomNavigationHeightAtom);

  if (mobile)
    return (
      <CSSTransition
        nodeRef={nodeRef}
        in={shouldShow}
        classNames="animate-slide-up"
        timeout={400}
        unmountOnExit
      >
        <div
          ref={nodeRef}
          className={classNames(
            "h-fit p-2 w-full fixed z-20 bg-background-primary inset-x-0",
            actionsClassName
          )}
          style={{
            boxShadow: "0px -6px 8px 0px rgba(0,0,0,0.06)",
            bottom: bottomNavigationHeight,
          }}
        >
          {children}
        </div>
      </CSSTransition>
    );

  if (!shouldShow) return null;

  return <div className="-mx-3">{children}</div>;
};

export default ActionsWrapper;
