import {
  CombinedOfferItem,
  CustomOrderContent,
  MenuModuleItemProps,
  OrderInfo,
  OrderLine,
  OrderLines,
  Plugins,
  TooltipDisabledButton,
} from "@kanpla/types";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { ProductDetails } from "./ProductDetails";
import { ProductPreview } from "./ProductPreview";

type ExtendedProductProps = {
  /** If the product has more than one price, show "fra" on products */
  pricePrefix?: boolean;
  /** Show extra text in the price label */
  priceSuffix?: string;
  moduleName?: MenuModuleItemProps["moduleName"];
  periodPrice?: number;
};
export type SingleProductProps = CombinedOfferItem & ExtendedProductProps;

export interface ProductProps extends TooltipDisabledButton {
  product: SingleProductProps;
  isBig?: boolean;
  open?: boolean;
  setOpen?: (nextState: boolean) => void;
  backLabel?: string;
  purchaseLabel?: string;
  previewProps?: any;
  onPurchase: (
    product: CombinedOfferItem,
    data: CustomOrderContent
  ) => Promise<void>;
  /** Option to disable amount input */
  hideAmount?: boolean;
  disabled?: boolean;
  /** Whether or not the order is placed from admin */
  fromAdmin?: boolean;
  /** Only show the product preview */
  previewOnly?: boolean;
  /** The product price before the discount (for leftovers) */
  oldPrice?: number;
  /** How many are left to buy (for leftovers)  */
  stock?: number;
  /** Overrides classes of a product block */
  customClassName?: string;
  plugins?: Plugins.Array;
  order: OrderLines;
  orderInfo: OrderInfo;
  maxAmount?: number;
  initialAmount?: number;
  defaultReference?: string;
  initialOptions?: OrderLine["options"];
  hasRequiredProduct?: boolean;
  isChildView?: boolean;
  /** Adds a banner where a countdown to the deadline is displayed */
  showDeadlineCountdown?: boolean;
  /** Used to replace default content  */
  customInputs?: ReactNode;
  /** Hide image from the product detail  */
  noImage?: boolean;
  orderButtonDisabled?: boolean;
  /** To get extra infos for the current menu of the week */
  currentDayTimestamp?: number;
  /** To reset the state of the acceptedTerms property */
  setAcceptedTerms?: Dispatch<SetStateAction<boolean>>;
  hidePriceInLabel?: boolean;
  DisabledContent?: () => JSX.Element;
  maxDisabled?: boolean;
}

export const Product = (props: ProductProps) => {
  const { supplier, module, fromAdmin } = useContainer(OrderingContext);
  const {
    product,
    open,
    isBig,
    setOpen,
    disabled = false,
    previewProps = {},
    previewOnly = false,
    stock = null,
    customClassName,
    currentDayTimestamp,
    setAcceptedTerms,
    maxDisabled,
  } = props;

  const [_open, _setOpen] = useState(false);

  useEffect(() => {
    if (typeof open === `boolean`) _setOpen(open);
  }, [open]);

  /**
   * If the modal will be closed the status of the payments will be resetted.
   */
  useEffect(() => {
    if (!_open && setAcceptedTerms) {
      setAcceptedTerms(_open);
    }
  }, [_open]);

  const updateOpen = (newOpen: boolean) => {
    _setOpen(newOpen);
    if (setOpen) setOpen(newOpen);
  };

  // Admin can only add products if the paymentMethod == 'billing' and if the order has not already been invoiced
  const disabledForAdmin = module.paymentMethod === "credit";

  const wrapperStyles = isBig
    ? "inline-block px-1 py-2 sm:p-2 lg:p-3 w-full sm:w-1/2 lg:w-1/2 xl:w-1/3"
    : "inline-block px-1 py-2 sm:p-2 lg:p-3 w-1/2 md:w-1/3 xl:w-1/4";

  return (
    <>
      <div
        className={
          customClassName ||
          (previewProps?.view !== "table" ? wrapperStyles : undefined)
        }
      >
        <ProductPreview
          product={product}
          isBig={isBig}
          disabled={fromAdmin ? disabledForAdmin : disabled}
          stock={stock}
          onClick={() => {
            updateOpen(true);
          }}
          previewOnly={previewOnly}
          currentDayTimestamp={currentDayTimestamp}
          supplier={supplier}
          module={module}
          {...previewProps}
        />
      </div>
      {!previewOnly && (
        <ProductDetails
          {...props}
          disabled={fromAdmin ? disabledForAdmin : disabled}
          currentDayTimestamp={currentDayTimestamp}
          open={_open}
          setOpen={updateOpen}
          maxDisabled={maxDisabled}
        />
      )}
    </>
  );
};
