import { t } from "@kanpla/system";
import { CustomProduct } from "@kanpla/types";
import { DrawerOrModal, Form, VatRateInput } from "@kanpla/ui";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";

type ChangeVatRateProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setCustomProducts: Dispatch<SetStateAction<CustomProduct[]>>;
  productId: string;
  vatRate: number;
};

type FormType = {
  vatRate: number;
};

export const ChangeVatRate = ({
  open,
  setOpen,
  setCustomProducts,
  productId,
  vatRate,
}: ChangeVatRateProps) => {
  const formMethods = useForm<FormType>({
    defaultValues: { vatRate },
  });

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("Custom VAT rate")}
      stopPropagation={false}
      actions={[
        {
          type: "primary",
          label: t("Save"),
          htmlType: "submit",
          form: "custom-product-vat-rate",
        },
      ]}
    >
      <Form<FormType>
        methods={formMethods}
        id="custom-product-vat-rate"
        onSubmit={(values) => {
          setCustomProducts((prev) => {
            const newCustomProducts = [...prev];
            const index = newCustomProducts.findIndex(
              (product) => product.productId === productId
            );

            newCustomProducts[index] = {
              ...newCustomProducts[index],
              vatRate: values.vatRate ?? vatRate,
            };
            return newCustomProducts;
          });

          setOpen(false);
        }}
      >
        <Form.Item name="vatRate">
          <VatRateInput placeholder={25} />
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};
