import { DayIndex, TrackingEntry } from "@kanpla/types";
import { sum } from "lodash";
import { FoodWasteInsightsData } from "./entriesToGraphData";

export const entriesTotalDayAmount = (
  entries: TrackingEntry["metricEntries"]
) => {
  return sum(entries.map((el) => el.amount));
};

export const entriesTotalWeekAmount = (entries: FoodWasteInsightsData) => {
  return sum(
    Object.keys(entries).map((dayIndex) =>
      entriesTotalDayAmount(entries[parseInt(dayIndex) as DayIndex])
    )
  );
};
