import { useT } from "@kanpla/system";
import { message } from "@kanpla/ui";
import { isEmpty } from "lodash";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { SignupData } from "../NewSalesplace";

interface Props {
  signupData: SignupData | null;
  setOpen: (open: boolean) => void;
  setLoading: (loading: boolean) => void;
}

export const useSubmitChild = ({ signupData, setOpen, setLoading }: Props) => {
  const {
    children,
    isChildSalesplace,
    userId,
    createChild,
    triggerReload,
    setChild,
  } = useContainer(AppContext);

  const t = useT();

  const targetSchool = signupData?.school;
  const selectors = signupData?.selectors;

  const submit = async () => {
    try {
      setLoading(true);

      const areAllSelectorsHidden =
        targetSchool?.selectors?.every((s) =>
          s?.options?.every((o) => o?.hidden)
        ) || false;

      const divisionNotChosen = Boolean(
        targetSchool?.selectors && isEmpty(selectors) && !areAllSelectorsHidden
      );

      if (divisionNotChosen) {
        message.error(t("Choose a division"), {
          messageId: "error-choose-selector",
        });

        throw new Error(t("Choose a division"));
      }

      const newChild = await createChild(
        {
          name: targetSchool?.name || "",
          schoolId: targetSchool?.id,
          selectors: selectors || {},
          userId,
          codes: [],
          isChildSalesplace,
          scope: signupData?.scope,
        },
        false
      );

      await triggerReload(1000);

      if (!children?.length) {
        await setChild(newChild);
      }
      message.success(t("New child is added!"));

      setOpen(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return submit;
};
