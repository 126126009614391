import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { auth, callFunction, T, useRequest, useT } from "@kanpla/system";
import {
  activeCurrencyAtom,
  Alert,
  Button,
  ButtonLine,
  DrawerOrModal,
  Form,
  Input,
  message,
  usePriceFormatter,
} from "@kanpla/ui";
import { RequestPayoutProps } from "apps/frontend/pages/api/internal/submit/requestPayout";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { useAtomValue } from "jotai";
import { useRouter } from "next/router";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

type FormData = {
  password: string;
  email: string;
};

const DeleteAccount = () => {
  const { balance, userId } = useContainer(AppContext);
  const router = useRouter();
  const t = useT();
  const activeCurrency = useAtomValue(activeCurrencyAtom);

  const balanceFormatted = usePriceFormatter(balance);

  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { isSubmitting, request: requestPayoutFn } = useRequest<
    RequestPayoutProps,
    boolean
  >("submit/requestPayout");

  const deleteAccount = async ({ email, password }: FormData) => {
    try {
      setDeleting(true);
      const credential = EmailAuthProvider.credential(email, password);

      if (!auth?.currentUser) return null;

      // Validate password
      await reauthenticateWithCredential(auth.currentUser, credential);

      await callFunction("softDeletes-deleteUser", {
        userId,
        email,
      });

      setDeleting(false);
      message.success(t("Account deleted successfully"));
      router.push("/signup");
    } catch (e) {
      setDeleting(false);
      console.error(e);
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  };

  const requestPayout = async () => {
    try {
      await requestPayoutFn({
        userId,
        payoutAmount: balance / 100,
        currency: activeCurrency,
      });

      message.success(
        t(
          "Your request is registered, it will be processed within 3-5 business days and you will receive more information on your email."
        )
      );
      return null;
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
      console.error(e);
    }
  };

  return (
    <>
      <ButtonLine
        label={t("Delete account")}
        icon={faTrash}
        danger
        onClick={() => setOpen(true)}
        className="border-b-0"
      />
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        title={t("Do you want to delete your account?")}
        subtitle={t(
          "You are about to delete your account, which cannot be taken back, are you sure you want to do that?"
        )}
        actions={[
          {
            onClick: () => null,
            label: t("Delete account"),
            loading: deleting,
            form: "delete-account-form",
            htmlType: "submit",
          },
        ]}
      >
        {balance ? (
          <Alert
            type="warning"
            message={
              <div>
                <T
                  t={t}
                  _str="Remember to request a payout, there is {balanceLeft} left on your balance!"
                  balanceLeft={balanceFormatted}
                />
                <Button
                  size="small"
                  className="mt-2"
                  onClick={requestPayout}
                  loading={isSubmitting}
                >
                  Request a payout
                </Button>
              </div>
            }
          />
        ) : null}

        <h3 className="heading-main mt-6">
          {t("Please enter your email and password")}
        </h3>
        <Form<FormData>
          id="delete-account-form"
          className="mt-6"
          onSubmit={deleteAccount}
        >
          <Form.Item
            name="email"
            rules={{
              required: t("Enter your email address"),
            }}
          >
            <Input.Email placeholder={t("Enter your email address")} required />
          </Form.Item>
          <Form.Item
            name="password"
            rules={{
              required: t("Enter your password"),
            }}
          >
            <Input.Password placeholder={t("Enter your password")} required />
          </Form.Item>
        </Form>
      </DrawerOrModal>
    </>
  );
};

export default DeleteAccount;
