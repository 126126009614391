import { DayIndex } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { globalVariantChoicesAtom } from "./globalVariantChoicesAtom";
import { useGetGlobalVariantFromOrder } from "./useGetGlobalVariantFromOrder";
import { useGlobalVariants } from "./useGlobalVariants";

/**
 * Detect the global variants of the day
 */
export const useGetGlobalVariantForDay = () => {
  const globalVariantChoices = useAtomValue(globalVariantChoicesAtom);
  const { option } = useGlobalVariants();
  const getGlobalVariantFromOrder = useGetGlobalVariantFromOrder();

  const getChoicePerDay = (dayIndex: DayIndex) => {
    // 1. Get choice from atom
    const choiceFromAtom = globalVariantChoices[dayIndex];

    // 2. If there is no choice from atom, get the one from the orders
    const choiceFromOrders = getGlobalVariantFromOrder(dayIndex);

    // 3. Get the first choice as default
    const firstAvailableChoice = option.choices?.[0]?.id;

    // 4. Put it all together
    const finalChoiceId =
      choiceFromAtom || choiceFromOrders || firstAvailableChoice;

    const finalChoice = option.choices?.find((ch) => ch.id === finalChoiceId);

    return finalChoice;
  };

  return getChoicePerDay;
};
