import { getActivePlugins, getTodaySeconds, useT } from "@kanpla/system";
import { _FrontendModule } from "@kanpla/types";
import { Button, Form, TimeSelect } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import moment from "moment";
import { basketEditModeAtom } from "../../../shared/basket/useBasket";

type Props = {
  module: _FrontendModule;
  basketKey: string;
};

export const TimeInput = ({ module, basketKey }: Props) => {
  const t = useT();
  const plugins = module?.plugins;
  const { activePlugins } = getActivePlugins({ module });
  const todaySeconds = getTodaySeconds();
  const setBasketEditMode = useSetAtom(basketEditModeAtom);
  if (!activePlugins["timeInput"]) return null;

  const {
    interval = 30,
    startAt = 27000,
    endAt = 57600,
    fieldName,
    fieldDescription,
    takeaway,
    individualDeliveryTime = true,
  } = plugins?.timeInput || {};

  return (
    <div>
      <Form.Item
        name={`${basketKey}.timeInput`}
        className="mb-4"
        label={fieldName || t("Delivery time")}
        description={fieldDescription}
        required
        rules={{
          required: t("{fieldName} is required", {
            fieldName: t("Delivery time"),
          }),
        }}
      >
        <TimeSelect
          interval={interval}
          endAt={endAt}
          startAt={startAt}
          takeaway={
            moment().isSame(moment.unix(todaySeconds), "day") && takeaway
          }
        />
      </Form.Item>
      {module.flow === "meeting" && individualDeliveryTime && (
        <Button
          onClick={() => setBasketEditMode((mode) => !mode)}
          className="whitespace-normal h-auto mb-4 mt-2 text-sm"
        >
          {t("Edit delivery time for each item")}
        </Button>
      )}
    </div>
  );
};
