import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  T,
  getLastFlexOrderChange,
  shouldHaveStandardOrdering,
  useT,
} from "@kanpla/system";
import { GroupedCombinedOffer } from "@kanpla/types";
import { message } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { CSSProperties, Dispatch, SetStateAction, useEffect } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { dateInfoAtom } from "../../lib/hooks/useDateInfo";
import { deadlineInfoAtom } from "../../lib/hooks/useDeadlineInfo";
import LineWrapper from "../LineWrapper";
import ProductsAvailability from "../ProductsAvailability";
import Days from "../shared/Days";
import HolidaysList from "../standardHolidays/HolidaysList";
import StandardHolidaysButton from "../standardHolidays/StandardHolidaysButton";
import StandardOrderButton from "../standardOrder/StandardOrderButton";

type MenuProps = {
  offerItems: GroupedCombinedOffer;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
const Menu = ({ offerItems, setOpen }: MenuProps) => {
  const t = useT();

  const { date, orderDocument, isBulk, orderLines, module, school } =
    useContainer(OrderingContext);
  const { deadline } = useAtomValue(deadlineInfoAtom);
  const { isPastDeadline } = useAtomValue(dateInfoAtom);

  const softDeadlineMaxAmount =
    (school?.contract?.softDeadlineMaxAmount as number | undefined) || 0;

  const { availableChanges = 0 } =
    getLastFlexOrderChange({
      order: orderDocument,
      date,
      deadline,
      localOrder: orderLines,
      softDeadlineMaxAmount,
    }) || {};

  // Check soft deadlines max order changes
  useEffect(() => {
    if (!isBulk || !isPastDeadline) return;

    if (availableChanges < 0)
      message.error({
        key: "error-exceeded",
        content: t(
          "You have exceeded the maximum of changes after the deadline"
        ),
      });
  }, [availableChanges, isBulk, isPastDeadline]);

  const hasStandardOrdering = shouldHaveStandardOrdering({
    targetModule: module,
    school,
  });

  return (
    <div className="w-full">
      <ProductsAvailability classNames="pb-3" />
      <div className="pb-6 pt-1">
        <div
          style={
            {
              columns: "1",
              breakInside: "avoid",
              columnFill: "balance",
              WebkitColumnFill: "balance",
              MozColumnFill: "balance",
              pageBreakInside: "avoid",
              columnGap: 32,
              MozColumnGap: 32,
              WebkitColumnGap: 32,
            } as CSSProperties
          }
        >
          <Days offerItems={offerItems} />
        </div>

        {isBulk && (
          <LineWrapper>
            <p className="text-text-secondary pt-3 pb-4 items-center flex justify-center relative">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-xl" />
              <T _str="Your order has been confirmed" />
            </p>
          </LineWrapper>
        )}

        <div className="my-10" />

        {hasStandardOrdering && (
          <div className="flex flex-col gap-y-6">
            <div>
              <h3 className="h500">
                <T _str="Standard choice" />
              </h3>
              <p>
                <T _str="Register your standard lunch choice, and it will be automatically processed without you having to remember the weekly order. You can always edit your standard choice." />
              </p>
              <StandardOrderButton />
            </div>

            <div>
              <h3 className="h500">
                <T _str="Holiday periods" />
              </h3>
              <p>
                <T _str="During these periods you will not receive any food." />
              </p>

              <HolidaysList />

              <StandardHolidaysButton setOpen={setOpen} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
