import { OrderLines } from "@kanpla/types";
import { has, pickBy } from "lodash";
import { UniqueOrderLineProps, matchesLine } from "../../flex/hasOrderChanged";

type Props = {
  orderLines: OrderLines | undefined;
  targetLine: UniqueOrderLineProps;
};

/** Find an orderLine inside an order, and update a part of it */
export const getOrderLine = ({ orderLines = [], targetLine }: Props) => {
  if (typeof orderLines?.length !== "number") return null;

  const matchingLine = orderLines.find((line) => {
    // Search only by fields present in targetLine
    const limitedLine = pickBy(line, (_, key) => has(targetLine, key));

    // Skip other lines
    const matchesTarget = matchesLine(limitedLine, targetLine, {
      withVatRate: false,
    });
    return matchesTarget;
  });

  return matchingLine;
};
