import { StampCard as StampCardType, StampCardRule } from "@kanpla/types";
import classNames from "classnames";
import React from "react";
import {
  PreviewStampcard,
  RealStampcard,
  StampCard as StampCardComponent,
} from "./StampCard";

type Props = {
  stampcards: { structure: StampCardRule; data: StampCardType | null }[];
  direction?: "row" | "column";
} & (PreviewStampcard | RealStampcard);

export const StampCardsRow = ({
  stampcards,
  onRewardClick,
  direction,
  isPreview,
}: Props) => {
  return (
    <div
      className={classNames(
        "grid gap-3 w-full",
        direction === "row" && "md:grid-cols-2 xl:grid-cols-3"
      )}
    >
      {stampcards.map((stampcard) => (
        <div className="w-full">
          <StampCardComponent
            key={stampcard.structure.id}
            structure={stampcard.structure}
            data={stampcard.data}
            {...(isPreview ? { isPreview } : { onRewardClick })}
          />
        </div>
      ))}
    </div>
  );
};
