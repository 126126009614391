import React from "react";

import classNames from "classnames";

type TickProps = {
  time: number;
  isPreview: boolean;
};
export const Tick = ({ time, isPreview }: TickProps) => {
  const hours = Math.floor(time);
  const minutes = time % 1;

  let tick;
  if (minutes === 0) {
    tick = `${hours}:00`;
  } else if (minutes === 0.5) {
    tick = `${hours}:30`;
  }

  if (!tick) return <div className="h-4 absolute bottom-0" />;

  return (
    <span
      className={classNames("h-4 text-xs absolute bottom-0", {
        "opacity-50": tick.includes("30"),
        "rounded-md w-full loader": isPreview,
      })}
    >
      {isPreview ? "" : tick}
    </span>
  );
};
