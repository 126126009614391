import { OrderingContext } from "@kanpla/ordering";
import { DayIndex } from "@kanpla/types";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { selectedDayIndexAtom } from "../..";
import { firstColumnStyles } from "../Week";
import { GlobalVariantDay } from "./GlobalVariantDay";
import { useGlobalVariants } from "./useGlobalVariants";

/** Global variants radio-select component. */
export const GlobalVariant = () => {
  const { isActive, option } = useGlobalVariants();
  const { week } = useContainer(OrderingContext);
  const selectedDayIndex = useAtomValue(selectedDayIndexAtom);

  if (!isActive) return null;

  return (
    <div className="border border-divider-main rounded-lg mb-2">
      <div className="h400 pt-4 px-4 pb-1">{option.name}</div>

      <div className="flex flex-wrap mb-2 px-4 justify-between">
        <div className={firstColumnStyles}>
          {option?.choices?.map((choice) => (
            <div key={choice.id} className="py-1">
              {choice.name}
            </div>
          ))}
        </div>
        <div className="flex md:flex-1">
          {week.map((date, dayIndex) => (
            <GlobalVariantDay
              key={date.seconds}
              dateSeconds={date.seconds}
              dayIndex={dayIndex as DayIndex}
              option={option}
              className={classNames(
                dayIndex !== selectedDayIndex
                  ? "hidden md:flex"
                  : "flex items-center justify-end"
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
