import { isEmpty } from "lodash";

import { Firestore } from "@google-cloud/firestore";

import { FirestoreDocument } from "@kanpla/types";
import { db } from "../firebase.config";
import { delayPromise } from "../utils/delayPromise";
import { fetchDocument } from "./fetchDocument";

interface Options {
  db: Firestore;
  includeRef?: boolean;
  /** The max allowed batch size for this call, defaults to 500 */
  batchSize?: number;
}

/**
 * Fetch multiple documents in the same collection by id
 * @typeParam T You can define a generic type here
 * @param collection The name of the collection of the query
 * @param docIds An array of document ids
 * @returns An array of documents data
 */
export const fetchMultipleDocuments = async <T extends FirestoreDocument>(
  collection: string,
  docIds: string[],
  options?: Options
): Promise<Array<T>> => {
  try {
    const filteredIds = docIds.filter((id) => id);
    const batchSize = options?.batchSize || 500;

    if (isEmpty(filteredIds)) return [];

    const dataInPromises = [];
    const totalBatches = Math.ceil(filteredIds.length / batchSize);

    for (let i = 0; i < totalBatches; i++) {
      const start = i * batchSize;
      const end = (i + 1) * batchSize;
      const batchIds = filteredIds.slice(start, end);

      const batchPromise = Promise.allSettled(
        batchIds.map((id) =>
          fetchDocument<T>(
            (options?.db || db).collection(collection).doc(id),
            options?.includeRef,
            {
              allowUnknown: true,
            }
          )
        )
      );

      dataInPromises.push(batchPromise);

      // Add a 2-second cooldown after each batch
      if (i < totalBatches - 1) {
        await delayPromise(1000);
      }
    }

    // If we need to fetch more than 10 documents
    const dataBatches = await Promise.all(dataInPromises);
    // Flatten the array of batches into a single array
    const data = dataBatches
      .flatMap((result) =>
        result.map((promiseResult) =>
          promiseResult.status === "fulfilled" ? promiseResult.value : null
        )
      )
      .filter((result) => result !== null) as T[];

    return data.filter((d) => !!d);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export default fetchMultipleDocuments;
