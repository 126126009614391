import { stringifyOptions } from "@kanpla/system";
import { useAtom } from "jotai";
import { isNil } from "lodash";
import { Change, orderChangesAtom } from "../../../../data/changesAtom";

export const stringifyChangeInAWeek = (
  line: Pick<Change, "productId" | "options" | "dayIndex">
) => `${line.productId}${stringifyOptions(line.options)}${line.dayIndex}`;

export const useOnChange = () => {
  const [changes, setChanges] = useAtom(orderChangesAtom);

  const onChange = (change: Change) => {
    const otherChanges = changes.filter(
      (ch) => stringifyChangeInAWeek(ch) !== stringifyChangeInAWeek(change)
    );
    const isUndefined = isNil(change.amount) || isNaN(change.amount);
    const newChanges = isUndefined ? otherChanges : [...otherChanges, change];
    setChanges(newChanges);
  };

  return onChange;
};
