import {
  IconDefinition,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/pro-regular-svg-icons";
import { escapeHTMLString } from "@kanpla/system";
import classNames from "classnames";
import { chunk } from "lodash";
import {
  ButtonBack,
  ButtonNext,
  CarouselContext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useOnWindowResize } from "rooks";
import { Button } from "..";

interface Props {
  items: Array<any>;
  rows?: 1 | 2;

  renderer: (data: any) => React.ReactChild;
  title?: string;
  subtitle?: string;
  linkButton?: JSX.Element;
}

const slidersAmount = (width: number) => {
  if (width < 640) return 1.2;
  if (width < 1024) return 2.15;
  return 3.1;
};

export const CustomSlider = (props: Props) => {
  const [visibleSlides, setVisibleSlides] = useState(1);

  const { items, rows = 1, renderer, title, subtitle, linkButton } = props;

  useOnWindowResize(({ target }: { target: Window }) => {
    const width = target.innerWidth;
    return setVisibleSlides(slidersAmount(width));
  });

  useEffect(() => {
    if (typeof window === "undefined" || !window) return;
    return setVisibleSlides(slidersAmount(window.innerWidth));
  }, []);

  const totalSlides = Math.ceil(items.length / rows);

  return (
    <div>
      <CarouselProvider
        lockOnWindowScroll
        naturalSlideWidth={100}
        naturalSlideHeight={80}
        totalSlides={totalSlides}
        visibleSlides={visibleSlides}
        isIntrinsicHeight
      >
        <header>
          <div className="flex justify-between items-baseline mt-4 mb-2">
            <div>
              {title && (
                <h2 className="title-secondary">{escapeHTMLString(title)}</h2>
              )}
              {subtitle && (
                <p className="text-text-secondary text-sm">
                  {escapeHTMLString(subtitle)}
                </p>
              )}
            </div>
            {linkButton && <div className="ml-auto md:mr-4">{linkButton}</div>}
            <div className="hidden md:flex float-right mb-3">
              <NavButtonWrapper Wrapper={ButtonBack} icon={faArrowLeft} />
              <NavButtonWrapper Wrapper={ButtonNext} icon={faArrowRight} />
            </div>
          </div>
        </header>
        <style key={`slider-flex-${totalSlides}`}>{`
          .slider-flex.slides-${totalSlides} {
            display: grid!important;
            grid-template-columns: repeat(${totalSlides} , 1fr)
          }

          .slider-flex.slides-${totalSlides} > div {
            width: 100% !important;
          }
        `}</style>
        <Slider
          classNameTray={`flex transition gap-4 slider-flex slides-${totalSlides}`}
          className="w-screen md:w-full -ml-4 md:m-0 p-4 md:p-0 md:pb-4 pt-0"
        >
          {chunk(items, rows).map((itemElements, i) => (
            <Slide
              key={i}
              index={i}
              innerClassName="h-full"
              className="flex-1 w-full"
            >
              {itemElements.map((item, o) => (
                <div
                  className={classNames(
                    "mb-2 h-full",
                    i === 0 && "first-slide"
                  )}
                  key={`${item?.id}-${i}-${o}`}
                  style={{ touchAction: "pan-x" }}
                >
                  {renderer(item)}
                </div>
              ))}
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </div>
  );
};

interface NavButtonWrapperProps {
  Wrapper: React.ComponentType<any>;
  icon: IconDefinition;
}

const NavButtonWrapper = ({ Wrapper, icon }: NavButtonWrapperProps) => {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide
  );
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  const ref = useRef(null);

  useEffect(() => {
    const parentElement = ReactDOM.findDOMNode(ref.current);
    // @ts-ignore
    const newIsDisabled = parentElement?.disabled;
    setIsDisabled(newIsDisabled);
  }, [currentSlide]);

  return (
    <Wrapper ref={ref}>
      <Button shape="plain" icon={icon} disabled={isDisabled} />
    </Wrapper>
  );
};
