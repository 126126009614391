import { useT } from "@kanpla/system";
import { DrawerOrModal, Form, Input, message } from "@kanpla/ui";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

type FormData = {
  password: string;
  newPassword: string;
  newPasswordAgain: string;
};

const ChangePassword = ({ open, setOpen }) => {
  const t = useT();
  const { auth } = useContainer(AppContext);
  const [loading, setLoading] = useState(false);

  const onFinish = async (data: FormData) => {
    setLoading(true);
    const { password, newPassword, newPasswordAgain } = data;

    try {
      if (newPassword !== newPasswordAgain)
        throw new Error(t("The passwords don't match"));

      const credential = EmailAuthProvider.credential(
        auth?.user?.email,
        password
      );

      await reauthenticateWithCredential(auth.user, credential);
      await updatePassword(auth.user, newPassword);

      message.success(t("Your password was changed"));
      setOpen(false);
    } catch (err) {
      if (err?.code === "auth/wrong-password") {
        message.error(t("Your old password is incorrect"));
        return;
      }
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("Change password")}
      subtitle={t("Enter your old and new password")}
      actions={[
        {
          label: t("Change password"),
          className: "primary",
          loading,
          htmlType: "submit",
          form: "change-psw-form",
          onClick: () => null,
          dataCy: "change-password-button",
        },
      ]}
    >
      <Form<FormData>
        id="change-psw-form"
        layout="vertical"
        onSubmit={onFinish}
      >
        <Form.Item
          id="password"
          name="password"
          rules={{ required: t("This field is required") }}
        >
          <Input.Password
            label={t("Old password")}
            autoComplete="off"
            dataCy="old-password-input"
            required
          />
        </Form.Item>

        <Form.Item
          id="newPassword"
          name="newPassword"
          rules={{ required: t("This field is required") }}
        >
          <Input.Password
            label={t("New password")}
            autoComplete="off"
            dataCy="new-password-input"
            required
          />
        </Form.Item>
        <Form.Item
          id="newPasswordAgain"
          name="newPasswordAgain"
          rules={{
            required: t("This field is required"),
            validate: (value, formValues) =>
              (value && formValues["newPassword"] === value) ||
              t("New passwords must be the same"),
          }}
        >
          <Input.Password
            label={t("Confirm password")}
            autoComplete="off"
            dataCy="confirm-new-password-input"
            required
          />
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default ChangePassword;
