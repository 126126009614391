import { OrderLines, Product } from "@kanpla/types";
import { groupBy, isNaN, sortBy } from "lodash";
import moment from "moment";
import { configToDisplayString } from "../orders/configToDisplayString";
interface Props {
  orderLines: OrderLines;
  products?: Array<Product>;
  showDeliveryTime?: boolean;
  groupedByDeliveryTime?: boolean;
}

const getOrderLinesString = (
  orderLines: Props["orderLines"],
  products: Props["products"],
  showDeliveryTime: Props["showDeliveryTime"]
) =>
  sortBy(orderLines, (orderLine) => -orderLine.amount)
    .map((orderLine) => {
      const { amount, productId, name } = orderLine;
      const product = products?.find((p: Product) => p.id === productId);

      const productName = name || product?.name || product?.id || "--";

      const configString = configToDisplayString(orderLine, showDeliveryTime);
      if (amount === 0) return null;

      return `<span class="block whitespace-pre-line"><b>${amount}x</b> ${productName} ${
        configString || ""
      }</span>`;
    })
    .filter((string) => string?.length > 0)
    .join("\n");

export const orderToDisplayString = (props: Props) => {
  const { orderLines, products, showDeliveryTime, groupedByDeliveryTime } =
    props;

  /**
   * if "groupedByDeliveryTime" is true it shows the orders grouped by deliveryTime (and sorted by amount),
   * if not it prints them only sorted by amount
   */
  if (groupedByDeliveryTime) {
    const groupedOrderLines = groupBy(
      orderLines,
      (orderLine) => orderLine.deliveryTime
    );
    return Object.entries(groupedOrderLines || {})
      .map(([deliveryTime, deliveryOrderLines]) => {
        const orderString = getOrderLinesString(
          deliveryOrderLines,
          products,
          false
        );

        if (!deliveryTime || isNaN(parseInt(deliveryTime)))
          return `<div>${orderString}</div>`;

        return `<div><p>🕑 ${moment
          .utc(parseInt(deliveryTime) * 1000)
          .format("LT")}</p><span>${orderString}</span></div>`;
      })
      .join("\n\n");
  }

  return getOrderLinesString(orderLines, products, showDeliveryTime);
};
