import { CombinedOfferItem, ProductAllIcons } from "@kanpla/types";
import { DisplayAttributes, DrawerOrModal } from "@kanpla/ui";
import classNames from "classnames";
import moment from "moment";
import { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";

interface ModalProps {
  item: CombinedOfferItem;
  title: ReactNode;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

/**
 * WeekMenuModal is separated to not let useFetch hook work for
 * all items, but only for opened item.
 */
export const ProductMenuModal: FC<ModalProps> = ({
  item,
  title,
  visible,
  setVisible,
}) => {
  const { week, supplier } = useContainer(OrderingContext);

  const headerArr = week.map((dayTimestamp) => {
    const momentObj = moment(dayTimestamp.toDate());

    return {
      dayName: momentObj.format("ddd"),
      dayNumber: momentObj.format("D/M"),
    };
  });

  return (
    <DrawerOrModal
      title={title}
      open={visible}
      setOpen={setVisible}
      style={{ maxWidth: "1600px" }}
      className="min-w-fit"
      showCloseButton
    >
      <div
        className={classNames(
          "flex md:flex-col flex-row",
          "rounded-t-lg min-w-fit overflow-visible"
        )}
      >
        <div
          className={classNames(
            "grid md:grid-cols-5 md:grid-rows-1 grid-rows-5 grid-cols-1",
            "bg-background-secondary"
          )}
          style={{ backgroundColor: item.color }}
        >
          {headerArr.map(({ dayName, dayNumber }) => (
            <div className="md:whitespace-nowrap md:px-4 px-2 py-2 inline-flex md:flex-row flex-col items-center">
              <span>
                {dayName} {dayNumber}
              </span>
            </div>
          ))}
        </div>
        <div className="flex-1 grid md:grid-cols-5 md:grid-rows-1 grid-rows-5 grid-cols-1">
          {week.map((date) => {
            const dayMenu = item.dates?.[date.seconds]?.menu;

            const attributes: ProductAllIcons = {
              ...dayMenu?.allergens,
              ...dayMenu?.labels,
              ...dayMenu?.pictograms,
            };

            return (
              <div className="px-4 py-2 col border">
                <div className="font-semibold text-base">{dayMenu?.name}</div>
                <div className="text-xs">{dayMenu?.description}</div>
                <DisplayAttributes
                  attributes={attributes}
                  size="small"
                  mode="all"
                  supplier={supplier}
                />
              </div>
            );
          })}
        </div>
      </div>
    </DrawerOrModal>
  );
};
