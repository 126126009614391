import { faBan } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T, t } from "@kanpla/system";
import { OrderPersonal } from "@kanpla/types";
import { Button, DrawerOrModal } from "@kanpla/ui";
import moment from "moment";
import { useState } from "react";
import { useSubmitOrder } from "../../lib/submit/useSubmitOrder";

interface CancelModalProps extends CancelOrderButtonProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
const CancelModal = ({ open, setOpen, orderDocument }: CancelModalProps) => {
  const submit = useSubmitOrder();

  if (!orderDocument) return null;

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      actions={[
        {
          label: t("Go back"),
          type: "secondary",
          onClick: () => setOpen(false),
        },
        {
          label: t("Cancel the order"),
          type: "danger",
          onClick: () =>
            submit({
              orders: [
                { ...orderDocument, info: orderDocument.info, orderLines: [] },
              ],
              noReceipt: true,
            }),
        },
      ]}
    >
      <div className="flex flex-col justify-items-center items-center gap-y-3">
        <p>
          <b>
            <T _str="You are about to cancel the order for" />
          </b>
        </p>
        <div className="bg-background-secondary rounded p-2 px-3">
          <span>
            {moment.unix(orderDocument.dateSeconds).format("dddd DD/MM/YYYY")}
          </span>
        </div>
        <span>
          <T _str="Do you wish to continue?" />
        </span>
      </div>
    </DrawerOrModal>
  );
};

interface CancelOrderButtonProps {
  orderDocument: OrderPersonal;
}
export const CancelOrderButton = ({
  orderDocument,
}: CancelOrderButtonProps) => {
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  if (!orderDocument) return null;

  return (
    <>
      <Button
        onClick={() => setOpenModal(true)}
        shape="soft"
        size="small"
        dataCy="btn-receipt-edit"
      >
        <FontAwesomeIcon icon={faBan} className="mr-2" />
        {t("Cancel order")}
      </Button>
      <CancelModal
        open={isOpenModal}
        setOpen={setOpenModal}
        orderDocument={orderDocument}
      />
    </>
  );
};
