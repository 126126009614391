import { faCheckCircle, faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useContainer } from "unstated-next";

import { T, useDocumentListener, useT } from "@kanpla/system";
import { Order, OrderPersonal, School } from "@kanpla/types";
import {
  Alert,
  Button,
  Divider,
  DrawerOrModal,
  ReceiptWrapper,
  RequestReceipt,
} from "@kanpla/ui";

import { OrderingContext, showDiscountPriceAtom } from "../../context";
import { BasketListTextItem } from "../../lib/BasketList/BasketListTextItem";
import { deadlineInfoAtom } from "../../lib/hooks/useDeadlineInfo";
import {
  meetingCurrentViewAtom,
  meetingEditingOrderAtom,
} from "../../mealplan2/meeting";
import DiscountBreakdown from "../../shared/receipt/DiscountBreakdown";
import { PriceBreakdown } from "../../shared/receipt/PriceBreakdown";

import PaidFromAlert from "./PaidFromAlert";

interface Props {
  /** Optional callback, used in meeting after successful purchase */
  onClose?: () => void;
}

type ReceiptOrder = {
  order: Order;
  /** Differentiate between `meeting` receipt or `history` receipt type */
  from?: "history" | "meeting";
};

/** Pass in order to show a receipt, or pass null to hide it */
export const receiptOrderAtom = atom<null | ReceiptOrder>(null);

export const Receipt = (props: Props) => {
  const { onClose } = props;
  const [receipt, setReceipt] = useAtom(receiptOrderAtom);

  const receiptOrder = receipt?.order as OrderPersonal;

  const t = useT();

  const close = () => {
    setReceipt(null);
    if (onClose) onClose();
  };

  const closeString = t("Close");

  const isShowing = typeof receiptOrder?.dateSeconds === `number`;

  return (
    <DrawerOrModal
      open={isShowing}
      setOpen={(o) => (o ? null : close())}
      title={receipt?.from === "history" ? t("Receipt") : null}
      actions={[
        {
          onClick: () => close(),
          dataCy: "btn-meetin-close-receipt",
          label: closeString,
        },
      ]}
      stopPropagation={false}
    >
      {isShowing ? (
        <OrderContent
          receiptOrder={receiptOrder}
          fromHistory={receipt?.from === "history"}
          fromMeeting={receipt?.from === "meeting"}
          closeHandler={close}
        />
      ) : null}
    </DrawerOrModal>
  );
};

const OrderContent = ({
  receiptOrder,
  fromHistory,
  fromMeeting,
  closeHandler,
}: {
  receiptOrder: OrderPersonal;
  fromHistory: boolean;
  fromMeeting: boolean;
  closeHandler?: () => void;
}) => {
  const {
    orderLines,
    dateSeconds,
    createdAtSeconds,
    info: orderInfo,
    groupName,
    displayName,
    schoolId,
    paymentMethod,
    discounts,
  } = receiptOrder;
  const t = useT();

  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const { setDate, module, user } = useContainer(OrderingContext);
  const setCurrentView = useSetAtom(meetingCurrentViewAtom);
  const setEditingOrder = useSetAtom(meetingEditingOrderAtom);

  const [school] = useDocumentListener<School>({
    collection: "schools",
    id: schoolId,
  });

  const { isDateSecondsPastDeadline } = useAtomValue(deadlineInfoAtom);
  const showDiscountPrice = useAtomValue(showDiscountPriceAtom);

  const pickUpDate = moment.unix(dateSeconds || 0).format("dddd LL");
  const orderingDate = moment.unix(createdAtSeconds || 0).format("LLLL");

  const isRefundedOrDeleted =
    receiptOrder?.deleted || !isEmpty(receiptOrder?.refundIds);
  const tooLateToChange = isDateSecondsPastDeadline(receiptOrder?.dateSeconds);

  return (
    <div className="bg-background-primary">
      <div className="relative">
        {isRefundedOrDeleted && (
          <Alert
            message={t("This order has been canceled or refunded")}
            type="danger"
            className="mb-4"
          />
        )}
        <div className="flex items-center flex-col">
          {!fromHistory && (
            <div className="flex w-full items-center justify-center">
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="2x"
                className="text-primary-main"
              />
              <h1
                className="text-2xl ml-2 font-semibold text-primary-main"
                data-cy="order-confirmation"
              >
                {t("Your order is confirmed")}
              </h1>
            </div>
          )}
          <div className="md:mt-10 grid gap-4 grid-cols-2 grid-rows-2 w-full md:px-4">
            <div className="flex flex-col">
              <h3 className="text-lg text-text-secondary">
                {t("Pickup date")}:
              </h3>
              <p>{pickUpDate}</p>
            </div>
            <div className="flex flex-col">
              <h3 className="text-lg text-text-secondary">{t("User name")}:</h3>
              <p>
                {displayName} {groupName ? `(${groupName})` : ""}
              </p>
            </div>
            {Boolean(createdAtSeconds) && (
              <div className="flex flex-col">
                <h3 className="text-lg text-text-secondary">
                  {t("Ordering date")}:
                </h3>
                <p>{orderingDate}</p>
              </div>
            )}
            <div className="flex flex-col">
              <h3 className="text-lg text-text-secondary">
                {t("Salesplace")}:
              </h3>
              <p>{school?.name}</p>
            </div>
          </div>
          <div className="mt-4 w-full md:px-4">
            <div className="h-1 border-t border-secondary-main my-4" />
            <h2 className="text-lg text-text-secondary mb-2">
              {t("Your order")}:
            </h2>
            {!orderLines.length && <T _str="This order is empty" />}
            {orderLines
              .filter((line) => line.amount)
              .map((orderLine) => (
                <BasketListTextItem
                  item={orderLine}
                  dateSeconds={dateSeconds}
                />
              ))}
            <div className="h-1 border-t border-secondary-main my-4" />

            <div className="mt-2 flex flex-col w-full gap-y-2">
              <DiscountBreakdown orderDiscounts={discounts || []} />
              {!!discounts?.length && showDiscountPrice && <Divider />}
              <PriceBreakdown
                orderLines={orderLines}
                highlightTotal
                showVatInfo
                hidePaidAmount={fromHistory || fromMeeting}
              />

              <PaidFromAlert hideBalance paymentMethod={paymentMethod} />
            </div>

            <div className="mt-6 flex gap-x-3">
              <Button
                type="primary"
                shape="soft"
                onClick={() => setShowMoreInfo((prev) => !prev)}
              >
                {showMoreInfo ? t("Hide info") : t("More info")}
              </Button>
              {fromMeeting && (
                <Button
                  type="primary"
                  shape="soft"
                  icon={faPen}
                  disabled={isRefundedOrDeleted || tooLateToChange}
                  onClick={() => {
                    const newMoment = moment.unix(receiptOrder?.dateSeconds);
                    setDate({ momentDate: newMoment });

                    setEditingOrder(receiptOrder as OrderPersonal);
                    setCurrentView("ordering");

                    closeHandler?.();
                  }}
                >
                  {t("Edit")}
                </Button>
              )}
            </div>

            {/* INFO */}
            {showMoreInfo && (
              <div className="mt-6">
                <h2 className="text-xl font-semibold text-text-primary">
                  {t("Order information")}
                </h2>
                <p className="flex mb-2">
                  <span className=" font-semibold">{t("Order ID")}: </span>
                  {receiptOrder.id}
                </p>
                <div className="my-2">
                  <RequestReceipt
                    id={receiptOrder.id}
                    type="order"
                    emails={user?.email ? [user.email] : []}
                  />
                </div>
                <ReceiptWrapper.InfoDisplay
                  orderInfo={orderInfo}
                  module={module}
                />
                <div className="mt-4">
                  <ReceiptWrapper.KitchenInfo school={school} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
