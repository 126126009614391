import { faChevronDown } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateProductPrice, T } from "@kanpla/system";
import { CombinedOfferItem, MenuItem, Supplier } from "@kanpla/types";
import { Space } from "@kanpla/ui";
import classNames from "classnames";
import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import { useContainer } from "unstated-next";
import { AllergensWarning, DisplayAttributes, PriceFormatter } from "..";
import { OrderingContext } from "../../../ordering/src/context";

interface Props {
  product: CombinedOfferItem;
  hidePrices: boolean;
  dateSeconds: number;
  supplier?: Supplier;
  fromStandard?: boolean;
}

const Inner = ({
  menu,
  supplier,
  open: openInfo,
  onClick,
}: {
  menu: MenuItem | undefined;
  supplier: Supplier | undefined;
  open: boolean;
  onClick: (event: React.MouseEvent<HTMLParagraphElement>) => void;
}) => {
  const { mobile } = useContainer(OrderingContext);

  const hasMenu =
    menu &&
    (menu?.description !== "" ||
      menu?.allergens ||
      menu?.labels ||
      menu?.pictograms);

  if (!hasMenu) return null;

  return (
    <div className="w-full">
      <p
        className="text-sm text-text-secondary opacity-75 mt-1"
        onClick={onClick}
      >
        {mobile ? (
          <p
            className={classNames(
              "whitespace-pre-line",
              !openInfo && "line-clamp-2 md:line-clamp-none"
            )}
          >
            {menu?.description}
          </p>
        ) : (
          <>
            <T
              _str="{show} menu description"
              show={openInfo ? <T _str="Hide" /> : <T _str="Show" />}
            />
            <FontAwesomeIcon
              icon={faChevronDown}
              className={classNames({
                "text-xs transform transition-transform ml-2": true,
                "rotate-180": openInfo,
              })}
            />
          </>
        )}
      </p>

      {/** @ts-ignore */}
      <AnimateHeight
        duration={500}
        height={openInfo ? "auto" : 0}
        animateOpacity
        onClick={onClick}
      >
        <Space
          className="text-sm mt-2 text-text-secondary"
          direction="vertical"
        >
          {!mobile && (
            <p className="whitespace-pre-line">{menu?.description}</p>
          )}
          <DisplayAttributes
            attributes={{
              ...menu?.labels,
              ...menu?.allergens,
              ...menu?.pictograms,
            }}
            supplier={supplier}
            mode="all"
            className="!mx-0"
          />
        </Space>
      </AnimateHeight>
    </div>
  );
};

export const ProductItemInfo = ({
  product,
  hidePrices,
  dateSeconds,
  supplier,
  fromStandard = false,
}: Props) => {
  const { module, child, mobile } = useContainer(OrderingContext);

  const price = calculateProductPrice({ product, module });

  const [open, setOpen] = useState(false);

  const sharedClassNamesAndStyles = {
    wrapperStyles: {
      width: "calc(100% - 32px - 32px)",
    },
    wrapperClassNames: "flex flex-col",
  };

  const productNameStyle: React.CSSProperties = {
    width: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };

  const menuDescription = product?.dates[dateSeconds]?.menu;

  const infoOnClick = (event: React.MouseEvent<HTMLParagraphElement>) => {
    event.stopPropagation();
    setOpen((open) => !open);
  };

  return (
    <div
      style={sharedClassNamesAndStyles.wrapperStyles}
      className={classNames(
        sharedClassNamesAndStyles.wrapperClassNames,
        "pb-4"
      )}
      onClick={(e) => mobile && infoOnClick(e)}
    >
      <div
        className={classNames(
          !hidePrices && "flex flex-col items-center",
          "relative"
        )}
      >
        {!fromStandard && menuDescription?.name && (
          <p
            style={productNameStyle}
            className="h100 text-sm md:text-xs mb:1 md:mb-0 uppercase md:normal-case text-text-disabled flex items-center"
          >
            {product?.name}
            {!!(product?.color && mobile) && (
              <div
                style={{ backgroundColor: product.color }}
                className="rounded-full h-4 w-4 ml-2"
              />
            )}
          </p>
        )}
        <p style={productNameStyle} className="font-medium text-text-primary">
          {menuDescription?.name || product?.name}
        </p>
        {!fromStandard && (
          <Inner
            menu={menuDescription}
            supplier={supplier}
            onClick={(e) => infoOnClick(e)}
            open={open}
          />
        )}
        {!!(mobile && !open) && (
          <div
            style={{ height: "30px" }}
            className="bg-gradient-to-t from-background-primary absolute bottom-0 w-full"
          />
        )}
      </div>
      <div className="my-2.5">
        {!fromStandard && <PriceFormatter price={price} />}
        {!fromStandard && (
          <AllergensWarning
            product={product}
            menu={menuDescription}
            child={child}
            className="text-xs my-2"
          />
        )}
      </div>
    </div>
  );
};

export default ProductItemInfo;
