import { faAt, faKeySkeleton, faUser } from "@fortawesome/pro-solid-svg-icons";
import { useT } from "@kanpla/system";
import { ButtonLine, DrawerOrModal } from "@kanpla/ui";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import ChangeEmail from "../../modals/changeEmail";
import ChangePassword from "../../modals/changePassword";
import DeleteAccount from "./DeleteAccount";
import RequestPayout from "./RequestPayout";

const Settings = () => {
  const [open, setOpen] = useState(false);
  const { user, auth } = useContainer(AppContext);
  const [emailOpen, setEmailOpen] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);

  const t = useT();
  const providerData = auth?.user?.providerData?.[0];

  return (
    <>
      <ButtonLine
        onClick={() => setOpen(true)}
        label={t("My account")}
        icon={faUser}
        dataCy="user-settings-btn"
      />
      <DrawerOrModal setOpen={setOpen} open={open} title={t("My account")}>
        <ButtonLine
          label={t("Change email ({value})", {
            value: user?.email,
          })}
          onClick={() => setEmailOpen(true)}
          disabled={providerData?.providerId === "microsoft.com"}
          dataCy="change-email"
          icon={faAt}
        />
        <ButtonLine
          label={t("Change password")}
          onClick={() => setPasswordOpen(true)}
          dataCy="change-password"
          icon={faKeySkeleton}
        />
        <RequestPayout />
        <DeleteAccount />
      </DrawerOrModal>
      {/* Modals */}
      <ChangeEmail open={emailOpen} setOpen={setEmailOpen} />
      <ChangePassword open={passwordOpen} setOpen={setPasswordOpen} />
    </>
  );
};

export default Settings;
