import {
  getActivePlugins,
  isDefaultReferenceValid,
  useT,
} from "@kanpla/system";
import { _FrontendModule } from "@kanpla/types";
import { Form, InvoiceReference } from "@kanpla/ui";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";
import { useInvoiceReference } from "../../hooks/useInvoiceReference";

type Props = {
  module: _FrontendModule;
  basketKey: string;
};

export const Reference = ({ module, basketKey }: Props) => {
  const { defaultReference, schoolId, child, groupName } =
    useContainer(OrderingContext);
  const t = useT();
  const { required, title, minCharacters, description } = useInvoiceReference();
  const { activePlugins } = getActivePlugins({ module });

  // [`reference${extraKey}`]:
  //   activePlugins?.["invoiceReference"] &&
  //   (initialValues?.reference || defaultReference),

  const plugins = module?.plugins;

  const shouldHideReference =
    plugins?.invoiceReference?.hideInBasket &&
    isDefaultReferenceValid({ child, module });

  const isSchoolInInvoiceReferenceScope =
    plugins?.invoiceReference?.scope?.generatedSchoolIds?.includes(schoolId) ??
    false;

  const isSelectorInlcudedInInvoiceReference =
    isSchoolInInvoiceReferenceScope &&
    (isEmpty(
      plugins?.invoiceReference?.scope?.schools?.[schoolId]?.selectorStrings
    ) ||
      plugins?.invoiceReference?.scope?.schools?.[
        schoolId
      ].selectorStrings?.includes(groupName));

  const isInvoiceReferenceActive =
    activePlugins["invoiceReference"] &&
    isSchoolInInvoiceReferenceScope &&
    isSelectorInlcudedInInvoiceReference;

  if (!isInvoiceReferenceActive) return null;

  return (
    <div
      className={classNames({
        "absolute h-0 w-0 opacity-0 pointer-events-none hidden":
          shouldHideReference,
      })}
    >
      <Form.Item
        name={`${basketKey}.reference`}
        label={title || t("Reference")}
        description={description}
        required={required}
        rules={{
          required:
            required &&
            t("{fieldName} is required", {
              fieldName: title,
            }),
          ...(typeof minCharacters === "number"
            ? {
                min: {
                  value: minCharacters,
                  message: t("write at least {value} characters", {
                    value: minCharacters,
                  }),
                },
              }
            : {}),
        }}
      >
        <InvoiceReference
          module={module}
          defaultReference={defaultReference}
          hideHeader
        />
      </Form.Item>
    </div>
  );
};
