import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAppleWhole,
  faCartShopping,
  faCroissant,
  faHouse,
  faPlateUtensils,
  faSquare,
  IconDefinition,
  IconName,
  IconPrefix,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useT } from "@transifex/react";
import { isArray } from "lodash";
import React from "react";
import { isMobile } from "react-device-detect";
import { fontAwesomeCollection } from "../../icons/fontAwesome";

export interface ModuleIcon {
  name: string;
  icon: IconDefinition;
  key: string;
}

interface GetTabIconOptions {
  iconKey: string;
  size?: SizeProp;
  color?: string;
}

export const getTabIcon = (options: GetTabIconOptions): JSX.Element | null => {
  const {
    size = isMobile ? "lg" : "1x",
    iconKey,
    color = "currentColor",
  } = options;

  const findIcon = tabIcons.find((icon) => icon.key === iconKey);

  if (!findIcon) return null;

  return <FontAwesomeIcon icon={findIcon.icon} size={size} color={color} />;
};

export const tabIcons: ModuleIcon[] = [
  {
    name: "Home",
    icon: faHouse,
    key: "home",
  },
  {
    name: "Lunch",
    icon: faPlateUtensils,
    key: "lunch",
  },
  {
    name: "Breakfast",
    icon: faCroissant,
    key: "breakfast",
  },
  {
    name: "Snacks",
    icon: faAppleWhole,
    key: "snacks",
  },
  {
    name: "Takeaway",
    icon: faCartShopping,
    key: "takeaway",
  },
];

interface Props {
  name: string;
  iconKey: string | [IconPrefix, IconName];
  truncateText?: boolean;
  dataCy?: string;
}

export const ModuleTab = (props: Props) => {
  const { name, iconKey, truncateText = false, dataCy = "" } = props;

  const t = useT();

  const iconDefinition = !isArray(iconKey)
    ? (tabIcons.find((icon) => icon.key === iconKey)?.icon as IconDefinition)
    : (fontAwesomeCollection.fad[iconKey?.[1]] as IconDefinition);

  const icon = (
    <FontAwesomeIcon icon={iconDefinition} size={isMobile ? "lg" : "1x"} />
  );

  return (
    <div className="flex gap-x-6 flex-col md:flex-row">
      <div className="mb-6">
        <p className="pb-2">{t("Desktop preview")}</p>
        <div
          className="flex items-center flex-nowrap justify-center px-6 py-2 w-fit rounded transition-colors bg-background-secondary"
          data-cy={dataCy}
        >
          {icon}
          <h1
            className={`ml-2 text-lg font-semibold text-text-secondary whitespace-nowrap truncate`}
            style={truncateText ? { maxWidth: 150 } : {}}
          >
            {name}
          </h1>
        </div>
      </div>
      <div>
        <p className="pb-2">{t("Mobile preview")}</p>
        <BottomNavigation value={0} showLabels className="max-w-xs border-2">
          <BottomNavigationAction
            label={
              <div>
                <span className="text-primary-main">{icon}</span>
                <span className="w-full break-words line-clamp-1 whitespace-normal text-ellipsis !text-xs font-medium text-primary-main">
                  {name}
                </span>
              </div>
            }
            classes={{
              root: "!p-0",
            }}
          />
          {[0, 1, 2].map(() => (
            <BottomNavigationAction
              label={
                <div>
                  <FontAwesomeIcon
                    icon={faSquare}
                    size="lg"
                    className="text-gray-300"
                  />
                  <div className="flex items-center h-5 w-10">
                    <div className="h-1.5 bg-gray-300 rounded-md w-full" />
                  </div>
                </div>
              }
            />
          ))}
        </BottomNavigation>
      </div>
    </div>
  );
};
