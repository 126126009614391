import { faCircleCheck } from "@fortawesome/pro-duotone-svg-icons";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OpenReceiptButton } from "@kanpla/ordering";
import { t, T } from "@kanpla/system";
import {
  _FrontendModule,
  OrderInfo,
  OrderPersonal,
  School,
  SubscriptionOrder,
} from "@kanpla/types";
import classNames from "classnames";
import { compact, isEmpty } from "lodash";
import moment from "moment";
import React, { ReactNode, useCallback } from "react";
import { TextInputDisplay, TimeInputDisplay } from "..";

interface Props {
  children: ReactNode; // React children (element)
  mobile?: boolean;
  bottomActions?: Array<ReactNode | JSX.Element>;
  wrapperChildren?: ReactNode; // React children (element)
  center?: boolean;
  className?: string;
  withPadding?: boolean;
  svgColor?: string;
  fullWidth?: boolean;
  ordersAmount?: number;
  orderDocument?: OrderPersonal;
  orderNumber?: number;
  receiptConfig?: {
    title?: string | ReactNode;
    status?: {
      text?: string;
      icon?: IconDefinition;
      background?: string;
    };
    subtitle?: string | ReactNode;
  };
  /** If you need to trigger initial open of the receipt */
  initialOpen?: boolean;
  subscription?: SubscriptionOrder;
}

export const ReceiptWrapper = (props: Props) => {
  const {
    children,
    center = false,
    className,
    fullWidth,
    receiptConfig,
    subscription,
    orderDocument,
  } = props;

  const { title: receiptTitle = "", status: receiptStatus = {} } =
    receiptConfig || {};

  const { text: receiptSubtitle = "", icon = faCircleCheck } = receiptStatus;

  const formatDate = useCallback(
    (seconds: number) => moment.unix(seconds).format("DD/MM") || "-",
    []
  );

  return (
    <div className="-mx-2 md:-mx-6">
      <div
        className={classNames(
          center ? "md:mx-auto" : "md:pl-8 lg:pl-12 xl:pl-32 md:pt-0",
          fullWidth
            ? "w-full sm:w-3/4 sm:float-right md:w-full md:float-none"
            : "w-full md:w-1/2 lg:w-3/7 xl:w-1/2",
          className,
          "pb-3 relative rounded-lg overflow-hidden max-w-lg"
        )}
        style={{ zIndex: 11 }}
      >
        <div className="rounded-lg py-3 px-6 flex flex-col justify-between items-center text-primary border-x border-y drop-shadow-md bg-background-primary">
          <div className="flex justify-between items-center w-full">
            <div className="flex flex-col justify-items-end">
              {/* Subtitle */}
              <div className="flex flex-row items-center justify-between w-full text-secondary-contrast">
                <div className="flex flex-row items-center gap-x-2 opacity-80">
                  <FontAwesomeIcon icon={icon} />
                  <span className="uppercase text-xs">
                    {receiptSubtitle || receiptConfig?.subtitle}
                  </span>
                </div>

                {subscription && (
                  <p className="text-xs">
                    {subscription?.from?.seconds > moment().unix()
                      ? t("Will start on {date}", {
                          date: formatDate(subscription?.from?.seconds),
                        })
                      : t("Expires on {date}", {
                          date: formatDate(subscription?.to?.seconds),
                        })}
                  </p>
                )}
              </div>

              {/* Title */}
              <div className="flex flex-row items-center justify-between w-full">
                <h3 className="h500">{receiptTitle}</h3>
              </div>
            </div>
            <div>
              {!!orderDocument && (
                <OpenReceiptButton orderDocument={orderDocument} />
              )}
            </div>
          </div>
          {/* Content */}
          {children}
        </div>
      </div>
    </div>
  );
};

interface InfoDisplayProps {
  orderInfo?: OrderInfo;
  module: _FrontendModule;
}

ReceiptWrapper.InfoDisplay = ({ orderInfo, module }: InfoDisplayProps) => {
  if (!orderInfo || isEmpty(orderInfo)) return null;

  return (
    <div className="text-text-primary flex flex-col gap-1">
      {orderInfo.name && (
        <p>
          <span className="font-semibold">
            <T _str="Name" />:
          </span>{" "}
          {orderInfo.name}
        </p>
      )}
      {module.plugins?.timeInput?.active && (
        <TimeInputDisplay timeInput={orderInfo?.timeInput} />
      )}
      {!!orderInfo?.cohost?.length && (
        <p>
          <span className="font-semibold">{t("Cohosts:")}</span>{" "}
          {orderInfo.cohost?.join(", ")}
        </p>
      )}
      {module.plugins?.invoiceReference?.active &&
        orderInfo.reference &&
        orderInfo.reference !== "" && (
          <p>
            <span className="font-semibold">{t("Reference:")}</span>{" "}
            {orderInfo.reference}
          </p>
        )}
      <TextInputDisplay
        textInput={orderInfo.textInput}
        plugin={module.plugins?.textInput}
      />
    </div>
  );
};

ReceiptWrapper.KitchenInfo = ({ school }: { school: School }) => {
  const displayAddress = compact([
    school?.customer?.address,
    school?.customer?.zip,
    school?.customer?.city,
  ]).join(", ");

  const cvr = school?.customer?.cvr;
  const tel = school?.customer?.tel;

  return (
    <div className="flex flex-col gap-y-2">
      <h2 className="text-xl font-semibold text-text-primary mt-2">
        <T _str="Kitchen information" />
      </h2>
      <p>{school?.name}</p>
      <p>{displayAddress}</p>
      {tel && <p>{tel}</p>}
      {cvr && (
        <span className="inline-flex">
          <h3 className="text-text-secondary">CVR:</h3>
          <p>{cvr}</p>
        </span>
      )}
    </div>
  );
};

interface BottomActionsProps {
  bottomActions: Array<ReactNode | JSX.Element>;
}

ReceiptWrapper.BottomActions = ({ bottomActions }: BottomActionsProps) => {
  return (
    <div className="border-x-2 border-y-2 rounded-bl-lg rounded-br-lg overflow-hidden">
      <div className="flex flex-row flex-wrap justify-evenly pt-2 pb-5">
        {bottomActions.map((action) => {
          return action;
        })}
      </div>
    </div>
  );
};
