import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { OrderingContext } from "@kanpla/ordering";
import { goToNextAvailableDay, T } from "@kanpla/system";
import { Button, createHapticFeedback } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { dateInfoAtom } from "../../lib/hooks/useDateInfo";
import { deadlineInfoAtom } from "../../lib/hooks/useDeadlineInfo";

const NavigateToNextAvailableDay = () => {
  const { module, week, dateSeconds, setDate } = useContainer(OrderingContext);
  const { defaultDateSeconds } = useAtomValue(deadlineInfoAtom);
  const { isPastDeadline } = useAtomValue(dateInfoAtom);

  if (module?.type !== "mealplan") return null;

  if (!isPastDeadline) return null;

  return (
    <Button
      type="primary"
      shape="soft"
      dataCy="btn-navigate-to-next-available-day"
      className="flex items-center gap-2 w-full md:w-auto justify-center"
      icon={faChevronRight}
      onClick={() => {
        goToNextAvailableDay({
          week,
          secondsInView: dateSeconds,
          setDate,
          defaultDateSeconds,
          module,
        });

        createHapticFeedback({
          type: "impact",
          impactFeedback: "light",
        });
      }}
    >
      <T _str="Order for the next available day" />
    </Button>
  );
};

export default NavigateToNextAvailableDay;
