import { Divider } from "antd";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";

import { t, T } from "@kanpla/system";
import { OrderLines } from "@kanpla/types";

import { OrderingContext, showDiscountPriceAtom } from "../../context";
import { basketEditModeAtom, basketPaidAmountAtom } from "../basket/useBasket";
import { useOrderPriceBreakdown } from "../basket/useOrderPriceBreakdown";

type Props = {
  orderLines: OrderLines;
  highlightTotal?: boolean;
  showVatInfo?: boolean;
  hidePaidAmount?: boolean;
};

export const PriceBreakdown = ({
  orderLines,
  highlightTotal = false,
  showVatInfo,
  hidePaidAmount = false,
}: Props) => {
  const { module, activePlugins } = useContainer(OrderingContext);

  const paidAmount = useAtomValue(basketPaidAmountAtom);
  const showDiscountPrice = useAtomValue(showDiscountPriceAtom);

  const basketEditMode = useAtomValue(basketEditModeAtom);

  const {
    totalDiscountFormatted,
    totalExcludingPriceFormatted,
    totalPriceFormatted,
    totalVatAmountFormatted,
    vatRatesDisplay,
    paidAmountFormatted,
    totalDiscountAmount,
    resultedPriceFormatted,
  } = useOrderPriceBreakdown({ orderLines });

  if (!module) return null;

  return (
    <div>
      {showVatInfo && (
        <>
          <div className="flex justify-between items-center body-secondary mb-1">
            <p className="text-text-secondary">
              <T _str="Total excl. VAT" />
            </p>
            <p>{totalExcludingPriceFormatted}</p>
          </div>
          {vatRatesDisplay && (
            <div className="flex justify-between items-center body-secondary mb-1">
              <p className="text-text-secondary">
                <T _str="VAT" /> ({vatRatesDisplay})
              </p>
              <p>{totalVatAmountFormatted}</p>
            </div>
          )}
        </>
      )}

      {showDiscountPrice && totalDiscountAmount > 0 && (
        <div className="flex justify-between items-center body-main mb-1">
          <p className="text-text-secondary">{t("Discount")}</p>
          <p>- {totalDiscountFormatted}</p>
        </div>
      )}

      <div className="flex justify-between items-center body-main mb-2">
        {vatRatesDisplay && (
          <h2 className="text-text-secondary">
            <T
              _str="Total, incl. VAT ({vatRatesDisplay})"
              vatRatesDisplay={vatRatesDisplay}
            />
          </h2>
        )}
        <p className={classNames({ "text-primary-main": highlightTotal })}>
          {totalPriceFormatted}
        </p>
      </div>
      {module.paymentMethod === "credit" &&
        !activePlugins["kanplaGo"] &&
        !activePlugins["payPerOrder"] &&
        !hidePaidAmount &&
        basketEditMode &&
        paidAmount > 0 && (
          <>
            <div className="flex justify-between items-center body-main mb-2">
              <p className="text-text-primary">
                <T _str="Already paid amount" />
              </p>
              <p className={classNames({ "text-primary-main": true })}>
                - {paidAmountFormatted}
              </p>
            </div>
            <Divider className="my-2" />
            <div className="flex justify-between items-center body-main mb-2">
              {
                <p className="text-text-primary">
                  <T _str="Left to pay" />
                </p>
              }
              <p>{resultedPriceFormatted}</p>
            </div>
          </>
        )}
    </div>
  );
};
