import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isLastItem } from "@kanpla/system";
import classnames from "classnames";
import { useAtomValue } from "jotai";
import moment from "moment";
import { periodsAtom } from "./StandardHolidays";

const HolidaysList = () => {
  const periods = useAtomValue(periodsAtom);

  if (!periods.some((period) => period?.fromSeconds && period?.toSeconds)) {
    return null;
  }

  return (
    <div className="mt-4 mb-1">
      <ul>
        {periods.map((period, index) => {
          const startDate = moment.unix(period.fromSeconds || 0).format("LL");
          const endDate = moment.unix(period.toSeconds || 0).format("LL");

          if (!period.fromSeconds || !period.toSeconds) return null;

          const isLast = isLastItem({ array: periods, index });

          return (
            <div
              key={index}
              className={classnames("flex items-center text-warning-main", {
                "mb-1": !isLast,
                "": isLast,
              })}
            >
              <FontAwesomeIcon
                icon={faCircle}
                style={{ fontSize: "7px" }}
                className="mr-2"
              />
              <li className="font-semibold">
                {startDate} - {endDate}
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default HolidaysList;
