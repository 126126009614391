import {
  IconDefinition,
  IconName,
  IconPrefix,
} from "@fortawesome/fontawesome-common-types";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { isArray } from "lodash";
import React from "react";
import { fontAwesomeCollection } from "../../icons/fontAwesome";
import { getTabIcon } from "./ModuleTab";

interface TabIconProps {
  iconKey: string | [IconPrefix, IconName];
  size?: FontAwesomeIconProps["size"];
}

export const TabIcon: React.FC<TabIconProps> = (props) => {
  const { iconKey, size = "1x" } = props;

  return isArray(iconKey) ? (
    <FontAwesomeIcon
      icon={fontAwesomeCollection.fad[iconKey?.[1]] as IconDefinition}
      size={size}
    />
  ) : (
    <div>{getTabIcon({ iconKey, size })}</div>
  );
};
