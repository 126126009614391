import { db, fetchDocument } from "@kanpla/system";
import { User } from "@kanpla/types";
import { Button, Form, Input, Space, message } from "@kanpla/ui";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

interface FormData {
  userId: string;
}

const SuperadminSwitch = () => {
  const { userId, setOverrideUser, isSuperadmin, setIsOverridingUser } =
    useContainer(AppContext);

  const [loading, setLoading] = useState(false);

  const findUser = async (uid: string) => {
    try {
      setLoading(true);
      if (!uid) throw new Error("No user ID");
      if (!isSuperadmin) throw new Error("You are not a superadmin!");
      if (uid === userId) return;

      const user = await fetchDocument<User>(db.collection("users").doc(uid));
      if (isEmpty(user)) throw new Error("User doesn't exist");

      setOverrideUser(user || ({} as User));
      setIsOverridingUser(true);
    } catch (e) {
      console.error(e);
      message.error(e?.message, { messageId: "error-find-user" });
    } finally {
      setLoading(false);
    }
  };

  if (!isSuperadmin) return null;

  return (
    <div className="text-text-primary mr-1 p-4 print:hidden bg-background-secondary border-2 border-primary-main flex flex-col justify-between">
      <h2 className="text-sm text-primary-dark mb-2">Superadmin</h2>
      <Form<FormData>
        onSubmit={({ userId: tableUserId }) => findUser(tableUserId)}
        onReset={() => findUser(userId)}
        defaultValues={{
          userId: "",
        }}
      >
        <Form.Item
          name="userId"
          rules={{
            required: "This field is required!",
          }}
        >
          <Input placeholder="Add user ID" disabled={loading} />
        </Form.Item>
        <Space>
          <Button
            htmlType="submit"
            loading={loading}
            type="primary"
            shape="solid"
            dataCy="btn-superadmin-switch"
          >
            Mask
          </Button>
          <Button htmlType="reset" shape="solid" dataCy="btn-superadmin-clear">
            Clear
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default SuperadminSwitch;
