import { useT } from "@kanpla/system";
import { StampCardsRow } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { stampcardModalAtom } from "./StampcardModal";
import { useStampCards } from "./useStampCards";

export const StampCards = () => {
  const t = useT();

  const setStampcardModal = useSetAtom(stampcardModalAtom);
  const { stampcards } = useStampCards();

  if (stampcards.length === 0) return null;

  return (
    <div>
      <h2 className="text-sm uppercase font-semibold text-text-secondary mb-2">
        {stampcards.length === 1 ? t("Stampcard") : t("Stampcards")}
      </h2>
      <StampCardsRow
        direction="column"
        stampcards={stampcards}
        onRewardClick={(s) => setStampcardModal({ isOpen: true, stampcard: s })}
      />
    </div>
  );
};
