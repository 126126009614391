import {
  faFileMagnifyingGlass,
  faPrint,
} from "@fortawesome/pro-solid-svg-icons";
import { useT } from "@kanpla/system";
import { ProductBank, _FrontendModule } from "@kanpla/types";
import Item from "./Item";

interface Props {
  flexModulesToPrint: _FrontendModule[];
}

const MultipleModulePrint = ({ flexModulesToPrint }: Props) => {
  const t = useT();

  return (
    <>
      <Item
        buttonLineIcon={faPrint}
        buttonLineLabel={t("Print this week's menu")}
        buttonLineClasses="w-full"
        flexModulesToPrint={flexModulesToPrint}
      />
      <Item
        buttonLineIcon={faFileMagnifyingGlass}
        buttonLineLabel={t("View this week's menu")}
        buttonLineClasses="w-full"
        flexModulesToPrint={flexModulesToPrint}
        isDigital
      />
    </>
  );
};

export default MultipleModulePrint;
