import { SessionResponse } from "@kanpla/services";
import {
  calculateAmountOfOrderItems,
  callInternalApi,
  getErrorMessage,
  useT,
} from "@kanpla/system";
import { AdyenCheckoutCardConfiguration, OrderPersonal } from "@kanpla/types";
import { ActionType, message } from "@kanpla/ui";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { clone, isEmpty, set } from "lodash";
import moment from "moment";
import { useState } from "react";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import { useContainer } from "unstated-next";
import { OrderingContext, initialAllowanceUsagesAtom } from "../../context";
import { useSubmitOrder } from "../../lib/submit/useSubmitOrder";
import { allowanceUsageAtom } from "../../shared/allowance/useAllowance";
import {
  basketAtom,
  basketContainerAtom,
  basketContainerTotalPriceAtom,
  basketResetAtom,
  handleBasketOpeningAtom,
  hasEnoughMoneyToPayBasketAtom,
} from "../../shared/basket/useBasket";
import { useOrderPriceBreakdown } from "../../shared/basket/useOrderPriceBreakdown";
import { adyenPaymentCheckoutConfigAtom } from "../../shared/payment/adyen/AdyenPaymentComponent";
import useBasketPayment from "../../shared/payment/useBasketPayment";
import {
  checkoutItemsAtom,
  kanplaGoReceiptOpenAtom,
  receiptTimeAtom,
} from "../kanplaGo/useKanplaGoListener";
import { meetingCurrentViewAtom } from "../meeting";
import { amountShownTakeFirstBannerAtom } from "./TakeFirstBanner";
import { rememberCardAtom } from "./elements/Payment";
import { confirmationModalOpenAtom } from "./elements/PurchaseAnimation";
import { selectedPaymentMethodAtom } from "./elements/selectedPaymentMethodAtom";
import useBasketActions from "./lib/useBasketActions";

/** Purchase logic for basket. Contains:
 * - Logic to show allowed action buttons
 * - On purchase, and finish order logic
 * - Plugins and module config logic
 * - Kanpla Go UI receipt
 */

export type BasketInfoForm = UseFormReturn<FieldValues, unknown>;

const useBasketPurchaseLogic = () => {
  const t = useT();

  const {
    module,
    hasKanplaGo,
    schoolId,
    card,
    balance,
    fromAdmin,
    paymentGatewayId,
    dateSeconds,
  } = useContainer(OrderingContext);
  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);

  const basket = useAtomValue(basketAtom);
  const basketContainer = useAtomValue(basketContainerAtom);

  const setAllowanceUsage = useSetAtom(allowanceUsageAtom);
  const initialAllowanceUsages = useAtomValue(initialAllowanceUsagesAtom);

  // BASKET
  const basketInfoForm = useForm();
  const basketContainerTotalPrice = useAtomValue(basketContainerTotalPriceAtom);
  const rememberCard = useAtomValue(rememberCardAtom);
  const [amountShownTakeFirstBanner, setAmountShownTakeFirstBanner] = useAtom(
    amountShownTakeFirstBannerAtom
  );
  const hasEnoughMoneyToPayBasket = useAtomValue(hasEnoughMoneyToPayBasketAtom);
  const handleBasketOpening = useSetAtom(handleBasketOpeningAtom);
  const basketReset = useSetAtom(basketResetAtom);

  // MEETING
  const setCurrentView = useSetAtom(meetingCurrentViewAtom);

  // KANPLA GO
  const setReceiptOpen = useSetAtom(kanplaGoReceiptOpenAtom);
  const setReceiptTime = useSetAtom(receiptTimeAtom);
  const setCheckoutItems = useSetAtom(checkoutItemsAtom);

  // POPUPS
  const setConfirmationModalOpen = useSetAtom(confirmationModalOpenAtom);

  // ADYEN
  const setAdyenCheckoutConfig = useSetAtom(adyenPaymentCheckoutConfigAtom);

  const [loading, setLoading] = useState(false);

  // To be refactored!
  const { loadChargeSession, callbackUrl } = useBasketPayment({
    setLoading,
    hasKanplaGo,
  });

  const payWithSavedCard = !!card && selectedPaymentMethod === "card";

  // Handle discounts (Allowance)
  const { totalPrice } = useOrderPriceBreakdown({
    orderLines: basket,
  });

  const canOrderForFree =
    module.paymentMethod === "credit" &&
    !isEmpty(basketContainer) &&
    totalPrice <= 0;

  // Open receipt ticket
  const openConfirmation = () => {
    if (hasKanplaGo) {
      setReceiptOpen(true);
      setReceiptTime(moment().unix());
      setCheckoutItems(basket);
    } else {
      setConfirmationModalOpen(true);
      setTimeout(() => setConfirmationModalOpen(false), 3000);
    }
  };

  const submitOrder = useSubmitOrder();

  // submit order
  const finishOrder = async (basketContainer: OrderPersonal[]) => {
    try {
      await submitOrder({ orders: basketContainer });

      // Open receipt ticket
      openConfirmation();
      handleBasketOpening(false);

      if (module.flow === "meeting") {
        setCurrentView("overview");
      }

      // Reset basket container
      basketReset();
    } catch (err) {
      const error = getErrorMessage(err);
      const errorMessage = error.replace("Error: ", "");
      if (errorMessage === "user.missing-selector") {
        const errorTitle = t(`User is missing selectors!`);
        message.error(errorTitle);
      } else {
        message.error(errorMessage);
      }
      console.error(err);

      // Reset state-stored allowance if some error
      setAllowanceUsage(initialAllowanceUsages);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = async (
    data: FieldValues = basketInfoForm.getValues()
  ) => {
    const updatedBasketContainer = clone(basketContainer);

    Object.entries(data).forEach(([basketId, { info }]) => {
      if (!info) return;
      set(updatedBasketContainer, `${basketId}.info`, info);
    });

    return updatedBasketContainer;
  };

  // submit order
  const onPurchase = async (data: FieldValues = basketInfoForm.getValues()) => {
    try {
      const updatedBasketContainer = await validateForm(data);

      const validBasketContainer = Object.values(updatedBasketContainer).filter(
        (o) => {
          const hasDate = typeof o.dateSeconds === `number`;
          const isValid = calculateAmountOfOrderItems(o.orderLines) > 0 || o.id;
          return hasDate && isValid;
        }
      );

      setLoading(true);

      // Return billing order right away
      if (module.paymentMethod === "billing") {
        await finishOrder(validBasketContainer);
        basketReset();
        return;
      }

      setAmountShownTakeFirstBanner(amountShownTakeFirstBanner + 1);

      const shouldChargeCreditCard =
        !hasEnoughMoneyToPayBasket && !payWithSavedCard && !canOrderForFree;

      if (shouldChargeCreditCard && !fromAdmin) {
        const missingMoney = basketContainerTotalPrice - balance;
        const calculatePrice =
          missingMoney < 0 ? basketContainerTotalPrice : missingMoney;

        const { sessionId, provider, sessionData }: SessionResponse =
          await callInternalApi("payment/paymentOnce", {
            unitPrice: calculatePrice,
            recurring: selectedPaymentMethod === "card" ? rememberCard : false,
            paymentMethod: selectedPaymentMethod,
            isWindowSession: true,
            mode: "order",
            callbackUrl,
            orders: validBasketContainer,
            schoolId,
            paymentGatewayId,
            orderDateSeconds: dateSeconds,
          });

        const payload =
          provider === "adyen"
            ? { provider, sessionId, sessionData }
            : { provider, sessionId };

        const session = await loadChargeSession(payload);

        if (session.provider === "adyen") {
          setAdyenCheckoutConfig({
            options: {
              ...(session.config as AdyenCheckoutCardConfiguration),
            },
            onSuccess: basketReset,
            rememberCard,
          });

          setLoading(false);
          return; // handle basket reset in adyen component
        }
      } else {
        await finishOrder(validBasketContainer);
      }
      basketReset();
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errors = e?.errorFields?.map((f) => f?.errors);
      message.error(errors?.join(", ") || getErrorMessage(e));
      console.error(e);
      setLoading(false);
    }
  };

  const { actions, className, onSubmit, noPadding } = useBasketActions({
    onPurchase,
    loading,
  });

  return {
    actions: [actions, className, noPadding] as [ActionType[], string, boolean],
    basketInfoForm,
    onSubmit,
  };
};

export default useBasketPurchaseLogic;
