const KidLoader = () => {
  return (
    <div className="border-b py-1.5">
      <div className="flex justify-between items-center py-4 flex-wrap gap-3">
        {/* Icon */}
        <div className="border border-divider-main p-0.5 bg-background-secondary rounded-full w-12 h-12 flex-shrink-0 flex items-center justify-center loader " />

        <div className="mr-auto">
          <div className="text-text-primary heading-main loader rounded h-6 mb-1 w-24" />
          <div className="text-text-primary heading-main loader rounded h-3 mb-1 w-16" />
        </div>

        {/* Actions */}
        <div className="w-28 h-10 rounded loader" />
      </div>
    </div>
  );
};

export default KidLoader;
