import { Order, OrderPersonal, _FrontendModule } from "@kanpla/types";
import { useAtom, useAtomValue } from "jotai";
import { pickBy } from "lodash";
import { generate } from "short-uuid";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { dateInfoAtom } from "../../lib/hooks/useDateInfo";
import { addRequiredProduct } from "./useAddRequiredProduct";
import {
  BasketContainer,
  basketContainerAtom,
  isTargetOrder,
} from "./useBasket";

// Update method
export const useSetBasket = () => {
  const {
    schoolId,
    childId,
    userId,
    modules,
    moduleId: moduleIdInView,
    activePlugins,
    offer,
    fromAdmin,
  } = useContainer(OrderingContext);
  const { dateSeconds: dateSecondsInView } = useAtomValue(dateInfoAtom);
  const [basketContainer, setBasketContainer] = useAtom(basketContainerAtom);

  return (order: {
    orderLines: Order["orderLines"];
    moduleId?: Order["moduleId"];
    dateSeconds?: Order["dateSeconds"];
    info?: Order["info"];
    id?: Order["id"];
  }) => {
    // 1. Get info from submitted props
    const moduleId = order.moduleId || moduleIdInView;
    const module = modules?.find((m) => m.id === moduleId) as _FrontendModule;
    const paymentMethod = module?.paymentMethod;
    const { orderLines, info, id } = order;
    const dateSeconds = order.dateSeconds || dateSecondsInView;

    // 2. Keep other orders (only in the frontend)
    const otherOrders = !fromAdmin
      ? pickBy(basketContainer, (targetOrder) => {
          const isCurrentOrder = isTargetOrder(
            targetOrder,
            moduleId,
            dateSeconds,
            schoolId,
            childId
          );
          return !isCurrentOrder;
        })
      : {};

    // 3. Update target order
    const [basketId, targetOrder] = Object.entries(basketContainer || {}).find(
      ([_, o]) => isTargetOrder(o, moduleId, dateSeconds, schoolId, childId)
    ) || [null, null];

    let newOrderLines = orderLines;
    if (activePlugins?.["requiredProduct"]) {
      //3.1 Remove required product if there aren't any order lines
      const requiredProductId = module?.plugins?.requiredProduct?.productId;
      if (
        orderLines?.length === 1 &&
        orderLines[0].productId === requiredProductId
      ) {
        newOrderLines = [];
      } else if (orderLines?.length > 0) {
        //3.2 Add required product if isn't already in the order lines
        const checkedOrderLines = addRequiredProduct({
          orderLines,
          module,
          offer,
        });
        newOrderLines = [...checkedOrderLines];
      }
    }

    const newTargetOrder = {
      ...(id ? { id } : {}),
      ...(targetOrder || {}),
      orderLines: newOrderLines,
      moduleId,
      dateSeconds,
      schoolId,
      info,
      paymentMethod,
      childId,
      userId,
    } as OrderPersonal;

    // 4. Put it all together and submit
    const newBasketId = basketId || generate();
    const newBasketContainer: BasketContainer = {
      ...otherOrders,
      [newBasketId]: newTargetOrder,
    };
    setBasketContainer(newBasketContainer);
  };
};
