import { faLock, faWheatSlash } from "@fortawesome/pro-solid-svg-icons";
import {
  getAvailableAccessCodes,
  getErrorMessage,
  removeUndefinedKeys,
  useT,
} from "@kanpla/system";
import {
  Child,
  Module,
  School,
  _FrontendModule,
  _FrontendSupplier,
} from "@kanpla/types";
import { ButtonLine, message } from "@kanpla/ui";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import EditAccessCodes from "./EditAccessCodes";
import EditAllergens from "./EditAllergens";

interface Props {
  child: Child;
  supplier: _FrontendSupplier | null;
  allModules: _FrontendModule[];
  school: School;
}

export const EditKidButtons = ({
  child,
  supplier,
  allModules,
  school,
}: Props) => {
  const t = useT();

  const { updateCurrentChild, triggerReload } = useContainer(AppContext);

  const submit = async (data: Partial<Child>) => {
    try {
      removeUndefinedKeys(data);
      await updateCurrentChild(data);
      triggerReload();
      message.success(t("Information about your user is saved"));
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      message.error(errorMessage);
    }
  };

  const [openAccessCodes, setOpenAccessCodes] = useState(false);
  const [openAllergens, setOpenAllergens] = useState(false);

  const allAvailableCodes = getAvailableAccessCodes(
    allModules as Module[],
    school
  )
    .filter((value) => typeof value === "string")
    .map((value) => String(value));

  const hasAccessCodes = allAvailableCodes.length > 0;

  const shouldShowAllergens = supplier?.screens?.shouldHideAllergens !== true;

  return (
    <div className="mt-2">
      {hasAccessCodes && (
        <>
          <ButtonLine
            label={t("Access codes")}
            onClick={() => setOpenAccessCodes(true)}
            dataCy="access-codes-btn"
            icon={faLock}
          />
          <EditAccessCodes
            open={openAccessCodes}
            setOpen={setOpenAccessCodes}
            child={child}
            allAvailableCodes={allAvailableCodes}
            submit={submit}
          />
        </>
      )}
      {shouldShowAllergens && (
        <>
          <ButtonLine
            label={t("Allergens")}
            onClick={() => setOpenAllergens(true)}
            dataCy="allergens-btn"
            icon={faWheatSlash}
          />
          <EditAllergens
            open={openAllergens}
            setOpen={setOpenAllergens}
            child={child}
            supplier={supplier}
          />
        </>
      )}
    </div>
  );
};
