import { OrderingContext } from "@kanpla/ordering";
import { DrawerOrModal } from "@kanpla/ui";
import { useAtom, useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import SkipQueueBanner from "../SkipQueueBanner";
import {
  checkoutItemsAtom,
  kanplaGoReceiptOpenAtom,
} from "../useKanplaGoListener";
import ReceiptFooter from "./Footer";
import ReceiptHeader from "./Header";
import ReceiptItems from "./ReceiptItem";

const Receipt = () => {
  const { child, school, hasPayPerOrder, module, hasKanplaGo } =
    useContainer(OrderingContext);
  const hideSkipQueue = module?.plugins?.kanplaGo?.hideSkipQueue ?? false;
  const checkoutItems = useAtomValue(checkoutItemsAtom);
  const [receiptOpen, setReceiptOpen] = useAtom(kanplaGoReceiptOpenAtom);
  const disableCountdown = module?.plugins?.kanplaGo?.hideCountdown ?? false;

  const shouldDisplayBecauseKanplaGo =
    hasKanplaGo && !hasPayPerOrder && module?.flow !== "meeting";
  const shouldDisplayBecauseRegistration = module?.flow === "registering";

  if (!shouldDisplayBecauseKanplaGo && !shouldDisplayBecauseRegistration)
    return null;

  return (
    <>
      {receiptOpen && !hideSkipQueue && !hasPayPerOrder && <SkipQueueBanner />}

      <DrawerOrModal
        open={receiptOpen}
        setOpen={() => setReceiptOpen(false)}
        showCloseButton={true}
        noPadding={true}
        drawerProps={{
          drawerClassName: "overflow-hidden bg-background-primary",
        }}
      >
        <div
          className="w-full h-full flex flex-col md:h-auto relative z-10"
          style={{
            paddingBottom: `env(safe-area-inset-bottom)`,
          }}
        >
          <ReceiptHeader
            onBack={() => setReceiptOpen(false)}
            childName={child?.displayName || child?.name || "-"}
            schoolName={school?.name || ""}
            disableCountdown={disableCountdown}
          />
          <div
            className="z-10"
            style={{
              height: "5px",
              marginTop: "-10px",
            }}
          />
          <div className="z-0 max-h-96 w-full p-8 flex flex-col items-center overflow-auto">
            <ReceiptItems items={checkoutItems} />
          </div>
          <ReceiptFooter items={checkoutItems} />
        </div>
      </DrawerOrModal>
    </>
  );
};

export default Receipt;
