import { useT } from "@kanpla/system";
import AddChild from "./forms/addChild";

const FirstChild = () => {
  const t = useT();

  return (
    <div className="text-center py-16 text-text-primary">
      <h2 className="text-2xl mb-1 title-main">{t("Let's get started!")}</h2>
      <p className="text-text-primary">{t("Add your first user:")}</p>
      <div className="py-4 max-w-xs m-auto">
        <AddChild />
      </div>
    </div>
  );
};

export default FirstChild;
