import { dayIndexesArr } from "@kanpla/system";
import { DayIndex, OrderLines } from "@kanpla/types";
import { atom } from "jotai";
import { isStandardOrderingAtom } from "../Navigation";
import { stringifyChangeInADay } from "./applyChangesToOrders";
import { loadedStandardOrdersAtom } from "./atomStandardOrders";
import { displayOrdersAtom } from "./loadedOrdersAtom";

export type WeeklyOrders = {
  [dayIndex in DayIndex]: OrderLines;
};

// 4. Full order
export const fullOrderAtom = atom((get) => {
  const isStandardOrdering = get(isStandardOrderingAtom);
  const finalStandardOrder = get(displayOrdersAtom);

  const getFinalWeeklyOrder = () => {
    // 4.1 Use standard-orders as default value for order days
    const displayWeeklyOrders = get(displayOrdersAtom);
    const loadedStandardOrders = get(loadedStandardOrdersAtom);

    const mergedOrders = Object.fromEntries(
      dayIndexesArr.map((dayIndex) => {
        const weeklyOrderLines = displayWeeklyOrders[dayIndex];
        const standardOrderLinesForDay = loadedStandardOrders[dayIndex] || [];

        const notOverriddenOrderLines = standardOrderLinesForDay.filter(
          (standardOrderLine) => {
            const isOverridden = weeklyOrderLines?.some(
              (weeklyOrderLine) =>
                stringifyChangeInADay(weeklyOrderLine) ===
                stringifyChangeInADay(standardOrderLine)
            );
            return !isOverridden;
          }
        );

        return [
          dayIndex,
          [...(notOverriddenOrderLines || []), ...(weeklyOrderLines || [])],
        ];
      })
    );

    return mergedOrders;
  };

  const fullOrder = isStandardOrdering
    ? finalStandardOrder
    : getFinalWeeklyOrder();
  return fullOrder;
});
