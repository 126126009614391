import { Receipt, Switch } from "@kanpla/ordering";
import { checks, deconstructSlugs } from "@kanpla/system";
import { Module } from "@kanpla/types";
import Wrapper from "apps/frontend/components/Wrapper";
import Authentication from "apps/frontend/components/anonymous/Authentication";
import { AppContext } from "apps/frontend/components/contextProvider";
import AdminOverview from "apps/frontend/components/flexBulk/AdminOverview";
import Homescreen from "apps/frontend/components/homescreen";
import SignupCarouselWrapper from "apps/frontend/components/introduction/SignupCarouselWrapper";
import ChooseAllergensModal from "apps/frontend/components/modals/ChooseAllergensModal";
import PermissionsModal from "apps/frontend/components/permissions";
import { StampcardModal } from "apps/frontend/components/stampcards/StampcardModal";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useContainer } from "unstated-next";
import FeedbackProvider from "../../components/feedback/Provider";
import MenuPreview from "../../components/menuPreview";
import PopupProvider from "../../components/popups/Provider";
import Subscription from "../../components/subscription";

const OrderingPage = () => {
  const {
    child,
    user,
    schoolId,
    moduleId,
    module,
    timeNavigation,
    isBulk,
    auth,
    setSchoolId,
    setModuleId,
    setIsBulk,
    moduleLoading,
  } = useContainer(AppContext);

  const switchModules = (module: Module) => {
    if (module.type === "subscription") return <Subscription />;
    if (module.type === "homescreen") return <Homescreen module={module} />;
    if (module.type === "flex" && module.flow === "menuPreview")
      return <MenuPreview />;

    return <Switch Overview={AdminOverview} />;
  };

  const router = useRouter();

  const slugs = router.query?.slugs as unknown as Array<string>;
  const rawSlug = deconstructSlugs({
    slugs,
  });

  useEffect(() => {
    if (!router.isReady) return;
    if (moduleLoading) return;
    setTimeout(() => {
      if (rawSlug.schoolId) setSchoolId(rawSlug.schoolId);
      if (rawSlug.moduleId) setModuleId(rawSlug.moduleId);
      if (rawSlug.isBulk) setIsBulk(rawSlug.isBulk);
    }, 1000);
  }, [
    router.isReady,
    rawSlug.schoolId,
    rawSlug.moduleId,
    rawSlug.isBulk,
    moduleLoading,
  ]);

  const renderModules = (module: Module) => {
    const { isFlexAndMenuPreview, isHomescreen, isPaymentMethodCredit } =
      checks({ module });

    if (auth.user) {
      return switchModules(module);
    }

    if (!auth.user) {
      if (module?.public) {
        return switchModules(module);
      }
      if (module?.public === undefined) {
        if (isFlexAndMenuPreview || isHomescreen || isPaymentMethodCredit) {
          return switchModules(module);
        }
      }
      if (
        module?.public === false ||
        module?.paymentMethod === "billing" ||
        isBulk
      ) {
        return <Authentication wrap />;
      }
    }
  };

  const showCarousel =
    isMobile || isTablet
      ? !user?.hasSeenSignupCarousel?.mobile
      : isDesktop
      ? !user?.hasSeenSignupCarousel?.desktop &&
        !user?.hasSeenSignupCarousel?.mobile
      : false;

  return (
    <>
      <Wrapper
        viewName={module?.displayName || module?.name || "Kanpla"}
        timeNavigation={timeNavigation}
        withTabs
        newDynamicTabs
        noPadding={module?.flow === "meeting" || module?.flow === "menuPreview"}
      >
        {module && moduleId && <div>{renderModules(module)}</div>}
        <PopupProvider />
        <FeedbackProvider />
        {auth?.user && showCarousel && schoolId && child?.id && (
          <SignupCarouselWrapper
            schoolId={schoolId}
            childId={child?.id}
            pushToUrl="/app"
          />
        )}
        <PermissionsModal />
        <StampcardModal />
        <ChooseAllergensModal />
      </Wrapper>
      {schoolId && (
        <link
          type="text/css"
          href={`/api/stylesheet?schoolId=${schoolId}`}
          key="stylesheet"
          rel="stylesheet"
        />
      )}
      <Receipt />
    </>
  );
};

export default OrderingPage;
