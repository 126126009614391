import { entriesTotalDayAmount, metricToColorClass } from "@kanpla/system";
import { MetricEntry } from "@kanpla/types";
import React, { useState } from "react";
import { useDebounce, useDidMount } from "rooks";

interface BarProps {
  data: MetricEntry[];
  absoluteWeekMax: number;
}

const Bar = ({ data, absoluteWeekMax }: BarProps) => {
  const totalAmount = entriesTotalDayAmount(data);

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const setIsMountedDebounced = useDebounce(setIsMounted, 200);

  useDidMount(() => {
    setIsMountedDebounced(true);
  });

  // 150 is the arbitrary height of the bar in px
  const fullHeight = (totalAmount * 150) / absoluteWeekMax;

  return (
    <div className="flex flex-col gap-y-[3px] min-w-[30px] w-full">
      {data.map(({ amount, metricKey }) => {
        const height = (amount / totalAmount) * fullHeight;

        return (
          <div
            key={metricKey}
            style={{
              height,
              transform: isMounted ? "scaleY(1)" : "scaleY(0)",
            }}
            className={`w-full animate-bar-chart bg-${metricToColorClass(
              metricKey
            )}-main rounded-sm`}
          />
        );
      })}
    </div>
  );
};

export default Bar;
