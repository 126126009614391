import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { T } from "@kanpla/system";
import { Button, Modal } from "@kanpla/ui";
import React, { useState } from "react";

const Info = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        icon={faCircleInfo}
        size="small"
        className="w-10 h-10"
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        setOpen={setOpen}
        title={<T _str="Food waste" />}
        subtitle={<T _str="How the insights work" />}
      >
        <p>
          <T _str="The data is manually collected from different sources of the kitchen and is divided in the following categories: " />
          <ul className="text-sm mt-4 list-disc">
            <li>
              <b>
                <T _str="Plate" />
              </b>
              :{" "}
              <T _str="What's left from the plates people eat from (Unfinished meals, bones, etc.)" />
            </li>
            <li>
              <b>
                <T _str="Buffet" />
              </b>
              :{" "}
              <T _str="What's left on the tray the food is served from (Leftovers)" />
            </li>
            <li>
              <b>
                <T _str="Meeting" />
              </b>
              :{" "}
              <T _str="What's left after a meeting catering (e.g. if you order croissants and cake for meeting)" />
            </li>
            <li>
              <b>
                <T _str="Production" />
              </b>
              : <T _str="What's wasted in the kitchen" />
            </li>
          </ul>
        </p>
      </Modal>
    </>
  );
};

export default Info;
