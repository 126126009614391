import { getErrorMessage, useT } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { Button, DrawerOrModal, Form, Input, message } from "@kanpla/ui";
import { every, uniq } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";

interface EditAccessCodesProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  child: Child;
  allAvailableCodes: string[];
  submit: (data: Partial<Child>) => Promise<void>;
}

interface FormData {
  newCodes: string[];
}

const EditAccessCodes = ({
  open,
  setOpen,
  child,
  allAvailableCodes,
  submit,
}: EditAccessCodesProps) => {
  const t = useT();
  const [loading, setLoading] = useState(false);

  const validate = async (data: FormData) => {
    try {
      setLoading(true);

      // Access code
      const { newCodes } = data;

      const allCodes = uniq(newCodes);

      // Submit
      await submit({
        codes: allCodes,
      });
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      console.error(errorMessage);
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerOrModal open={open} setOpen={setOpen} title={t("User codes")}>
      <Form<FormData>
        layout="vertical"
        onSubmit={validate}
        defaultValues={{ newCodes: child?.codes || [] }}
      >
        <Form.Item
          name="newCodes"
          label={t("Special passwords")}
          rules={{
            required: t("Enter at least one code"),
            validate: (newCodes) =>
              every(newCodes, (code) => allAvailableCodes.includes(code)) ||
              t("One or more codes are not valid"),
          }}
        >
          <Input.Tags placeholder={t("Add codes...")} />
        </Form.Item>
        <p className="text-xs -mt-1 text-text-secondary opacity-70">
          {t("Grant access to features reserved to selected users")}
        </p>

        <Button
          type="primary"
          dataCy="btn-childForm-submit"
          shape="solid"
          loading={loading}
          loadingText={t("Please wait...")}
          htmlType="submit"
          size="large"
          className="mt-1"
        >
          {t("Save changes")}
        </Button>
      </Form>
    </DrawerOrModal>
  );
};

export default EditAccessCodes;
