import {
  faArrowsRotate,
  faBagShopping,
  faMoneyCheck,
  faMoneySimpleFromBracket,
  faWarning,
} from "@fortawesome/pro-duotone-svg-icons";
import { HistoryItem } from "apps/frontend/pages/api/internal/load/paymentHistory";

export const getIcon = (iconName: HistoryItem["icon"]) => {
  switch (iconName) {
    case "subscription":
      return faArrowsRotate;
    case "payout":
      return faMoneySimpleFromBracket;
    case "payment-valid":
      return faMoneyCheck;
    case "payment-error":
      return faWarning;
    case "order":
      return faBagShopping;
    default:
      return null;
  }
};
