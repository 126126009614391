import { useInvoiceReference } from "@kanpla/ordering";
import { isDefaultReferenceValid, useT } from "@kanpla/system";
import { DrawerOrModal, Form, InvoiceReference, message } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { isMenuOpenAtom } from "../settings";

type FormData = {
  invoiceReference: string;
};

const AddReferenceInfo = () => {
  const t = useT();
  const {
    child,
    activePlugins,
    module,
    triggerReload,
    updateCurrentChild,
    previousModuleId,
    setModuleId,
  } = useContainer(AppContext);

  const { title, minCharacters, defaultRequired } = useInvoiceReference();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const setIsMenuOpen = useSetAtom(isMenuOpenAtom);

  useEffect(() => {
    if (!child || !module) return;

    const referenceActive = activePlugins?.invoiceReference === true;
    const invalidDefaultReference = !isDefaultReferenceValid({ child, module });

    const shouldOpen =
      referenceActive && defaultRequired && invalidDefaultReference;
    if (shouldOpen) {
      setOpen(true);
      setIsMenuOpen(false);
    }
  }, [module?.id, child?.id, defaultRequired]);

  if (!child) return null;

  const submit = async (data: FormData) => {
    try {
      setLoading(true);
      await updateCurrentChild({
        defaultReference: data.invoiceReference,
      });
      message.success(t("Information about your user is saved"));

      setOpen(false);
      triggerReload();
    } catch (e) {
      console.error(e);
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={() => {
        message.info(t("Please add information about your user"));
      }}
      title={title}
      actions={[
        {
          label: t("Cancel"),
          onClick: () => {
            setModuleId(previousModuleId);
            setOpen(false);
          },
          type: "secondary",
        },
        {
          label: !loading ? t("Confirm") : t("Please wait..."),
          loading: loading,
          onClick: () => null,
          htmlType: "submit",
          form: "invoice-ref-form",
        },
      ]}
    >
      <Form<FormData>
        id="invoice-ref-form"
        className="my-8 m-auto mb-0"
        onSubmit={submit}
      >
        <Form.Item
          name="invoiceReference"
          rules={{
            required:
              defaultRequired &&
              t("{fieldName} is required", {
                fieldName: title,
              }),
            minLength:
              minCharacters &&
              t("Write at least {value} characters", {
                value: minCharacters,
              }),
          }}
          controlled
        >
          <InvoiceReference module={module} noExtraMargin />
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default AddReferenceInfo;
