import { sortProducts, t } from "@kanpla/system";
import { OrderPersonal } from "@kanpla/types";
import { ReceiptWrapper } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { BasketListTextItem } from "../../lib/BasketList/BasketListTextItem";
import { dateInfoAtom } from "../../lib/hooks/useDateInfo";
import { PriceBreakdown } from "../../shared/receipt/PriceBreakdown";
import { CancelOrderButton } from "./CancelOrderButton";
import { EditButton } from "./EditButton";
import { OpenTicketButton } from "./OpenTicketButton";

interface OrderAdjustmentCompProps extends OrderItemProps {
  type?: "hidden" | "pastDeadline";
}

const OrderAdjustmentComp = ({
  orderDocument,
  type,
}: OrderAdjustmentCompProps) => {
  if (type === "hidden") return null;

  if (type === "pastDeadline")
    return (
      <p className="text-sm text-text-disabled -my-2">
        {t("It's too late to change your order")}
      </p>
    );

  return (
    <>
      <EditButton orderDocument={orderDocument} />
      <CancelOrderButton orderDocument={orderDocument} />
    </>
  );
};

interface OrderItemProps {
  orderDocument: OrderPersonal;
}

const OrderItem = ({ orderDocument }: OrderItemProps) => {
  const { module, fromAdmin, orders, hasKanplaGo } =
    useContainer(OrderingContext);
  const { orderLines, info, dateSeconds, discounts } = orderDocument;
  const { isPastDeadline } = useAtomValue(dateInfoAtom);

  const sortedOrderLines = sortProducts({
    items: orderLines.filter((o) => o.amount),
    productLines: module.productLines,
  });

  const pastDeadlineCheck = isPastDeadline && !fromAdmin;
  const noOrderCheck = !orders.length;
  const kanplaGoEditCheck = hasKanplaGo || module.flow === "registering";

  const orderAdjustmentType =
    noOrderCheck || kanplaGoEditCheck
      ? "hidden"
      : pastDeadlineCheck
      ? "pastDeadline"
      : undefined;

  return (
    <div className="py-2 transition-opacity w-full">
      {/* Basket List */}
      {sortedOrderLines.map((orderLine) => (
        <BasketListTextItem
          item={orderLine}
          dateSeconds={dateSeconds}
          showImage
        />
      ))}
      {/* Infos and Footer */}
      <div className="flex justify-between items-baseline mb-2">
        <ReceiptWrapper.InfoDisplay orderInfo={info} module={module} />
      </div>
      <div className="m-0 border-t border-divider-main" />
      <div className="pt-4 pb-3">
        <PriceBreakdown hidePaidAmount orderLines={orderLines} />
      </div>
      <div className="flex gap-2 flex-wrap">
        <OpenTicketButton orderDocument={orderDocument} module={module} />
        <OrderAdjustmentComp
          orderDocument={orderDocument}
          type={orderAdjustmentType}
        />
      </div>
    </div>
  );
};

export default OrderItem;
