import classNames from "classnames";

type TotalEmptyItemProps = {
  dayIndex: number;
  selectedDayIndex: number;
};

export const VariantsTotalEmpty = ({
  dayIndex,
  selectedDayIndex,
}: TotalEmptyItemProps) => {
  return (
    <div
      className={classNames(
        "text-center text-xl font-medium w-16 text-text-disabled flex-1",
        dayIndex !== selectedDayIndex && "hidden md:block"
      )}
    >
      0
    </div>
  );
};
