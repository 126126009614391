import { DayIndex } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { flexBulkFocusAtom } from "../../Navigation";
import { allLoadedOrdersAtomWithGlobalVariantOptions } from "../../data/loadedOrdersAtom";
import { useGlobalVariants } from "./useGlobalVariants";

export const useGetGlobalVariantFromOrder = () => {
  const { option: globalVariantOption } = useGlobalVariants();
  const loadedOrders = useAtomValue(
    allLoadedOrdersAtomWithGlobalVariantOptions
  );
  const flexBulkFocus = useAtomValue(flexBulkFocusAtom);
  const globalVariantChoices =
    globalVariantOption?.choices?.map((choice) => choice.id) || [];
  const getGlobalVariantFromOrder = (dayIndex: DayIndex) => {
    const orderLines = loadedOrders?.[flexBulkFocus]?.[dayIndex] || [];

    const allChoices = orderLines
      .map((orderLine) => Object.keys(orderLine.options || {}))
      .flat();
    const firstGlobalChoiceId = allChoices.find((choiceId) => {
      const valid = globalVariantChoices?.includes(choiceId);
      if (valid) return true;
    });
    return firstGlobalChoiceId;
  };

  return getGlobalVariantFromOrder;
};
