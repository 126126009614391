import { faFileExcel } from "@fortawesome/pro-regular-svg-icons";
import {
  downloadBlob,
  urlBase64ToUint8Array,
  useRequest,
  useT,
} from "@kanpla/system";
import { ButtonLine, message } from "@kanpla/ui";
import { ExportTransactionHistoryProps } from "apps/frontend/pages/api/internal/user/export-transaction-history";
import { last } from "lodash";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

const ExportTransactions = () => {
  const { userId } = useContainer(AppContext);
  const t = useT();

  const { request: exportData, isSubmitting } = useRequest<
    ExportTransactionHistoryProps,
    string
  >("user/export-transaction-history", null, { returnRaw: true });

  const downloadData = async () => {
    try {
      const data = await exportData({ userId });

      const fileName = last(
        data.headers?.["content-disposition"]?.split("filename=")
      );

      const arrayData = urlBase64ToUint8Array(data.data);

      downloadBlob(new Blob([arrayData]), fileName as string);
    } catch (e) {
      message.error(e?.message);
    }
  };

  return (
    <ButtonLine
      label={
        isSubmitting
          ? t("Downloading")
          : t("Export complete transaction history")
      }
      onClick={() => downloadData()}
      dataCy="history-payments"
      icon={faFileExcel}
      disabled={isSubmitting}
    />
  );
};

export default ExportTransactions;
