import { faImageSlash } from "@fortawesome/pro-duotone-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderingContext } from "@kanpla/ordering";
import {
  calculateProductPrice,
  createOrderLine,
  getOrderLine,
  stringifyOrderLine,
  updateOrderLine,
} from "@kanpla/system";
import { OrderLine, OrderPersonal } from "@kanpla/types";
import { Button, Image, InputAmount, usePriceFormatter } from "@kanpla/ui";
import classNames from "classnames";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { isEmpty, sortBy } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { useProcessAllergens } from "../../lib/allergens/waitAllergensWarning";
import { dateInfoAtom } from "../../lib/hooks/useDateInfo";
import { useNextAvailableDate } from "../../lib/hooks/useNextAvailableDate";
import DeadlineInfo from "../../mealplan2/DeadlineInfo";
import {
  basketAtom,
  basketContainerAtom,
  openBasketAtom,
} from "../basket/useBasket";
import { useSetBasket } from "../basket/useSetBasket";
import { useMinimumAmount } from "../minimumAmount/useMinimumAmount";
import { SingleProductProps } from "./Product";

interface ProductPreviewRowProps {
  product: SingleProductProps;
  onClick: () => void | (() => Promise<void>);
  disabled?: boolean;
  className?: string;
  children: ReactNode;
}

export const currentBasketItemAtom = atom<OrderPersonal | null>((get) => {
  const validBaskets = get(basketContainerAtom);

  const [firstOrder] = sortBy(
    Object.values(validBaskets),
    (order) => order.dateSeconds
  );

  return firstOrder || null;
});

export const ProductPreviewRow = ({
  product,
  onClick,
  children,
  disabled: disabledFromProps,
}: ProductPreviewRowProps) => {
  const { isPastTodayIndividualDeadline } = useAtomValue(dateInfoAtom);

  const setBasket = useSetBasket();
  const basket = useAtomValue(basketAtom);
  const basketValue = useAtomValue(currentBasketItemAtom);
  const setOpenBasket = useSetAtom(openBasketAtom);

  const { module, fromAdmin, dateSeconds, overviewEditingOrder, supplier } =
    useContainer(OrderingContext);

  const [showAmount, setShowAmount] = useState(false);

  const productPrice = calculateProductPrice({
    product,
    module,
  });

  const priceFormatted = usePriceFormatter(productPrice);

  const nextAvailableDate = useNextAvailableDate({ product });

  const isPastIndividualDeadline = isPastTodayIndividualDeadline(
    product?.individualDeadline
  );

  const isBeforeFlexibleStartDate =
    typeof product?.individualOnset === "number" &&
    !isPastTodayIndividualDeadline(product?.individualOnset);

  const hasRestrictedAvailability =
    !fromAdmin && (isPastIndividualDeadline || isBeforeFlexibleStartDate);

  const disabled = disabledFromProps || hasRestrictedAvailability;

  // backend disabled editing
  const disabledEditingFromBackend =
    fromAdmin &&
    !isEmpty(overviewEditingOrder) &&
    module.paymentMethod === "credit";

  const {
    photo: productPhoto,
    id: productId,
    name: productName,
    options: productOptions,
    color: productColor,
  } = product || {};

  const photo = productPhoto || supplier?.custom?.images?.defaultProductImage;

  // Don't show if it's the required product
  const requiredProductPlugin = module?.plugins?.requiredProduct;
  const requiredProductId =
    requiredProductPlugin?.active && requiredProductPlugin?.productId;
  const isRequiredProduct = requiredProductId === productId;

  const newProductItem = createOrderLine({
    amount: 1,
    product,
    options: {},
  });

  const basketItem = getOrderLine({
    orderLines: basket,
    targetLine: newProductItem,
  });

  const hasVariants = !isEmpty(productOptions || []);
  const productInBasket = basketItem?.productId === productId;

  useEffect(() => {
    if (!productInBasket) setShowAmount(false);
  }, [productInBasket]);

  const { waitAllergensWarning } = useProcessAllergens({
    product,
    dateSeconds,
  });

  const minimumAmount = useMinimumAmount(
    basket,
    stringifyOrderLine(basketItem || {}),
    product
  );

  const addProduct = async () => {
    const forward = await waitAllergensWarning();

    if (!forward) return;

    if (hasVariants) {
      onClick();
      return;
    }

    const newBasket = updateOrderLine({
      orderLines: basket,
      targetLine: newProductItem,
      newData: { amount: minimumAmount || 1 },
    }) as OrderLine[];

    setBasket({ orderLines: newBasket, info: basketValue?.info });

    setShowAmount(true);
    setOpenBasket(true);
  };

  const updateAmount = async (amount: number) => {
    const basketAmount =
      basket.find(
        (p) =>
          newProductItem.productLineId === p.productLineId ||
          newProductItem.productId === p.productId
      )?.amount || 0;
    const removing = amount < basketAmount;

    if (!removing) {
      const forward = await waitAllergensWarning();

      if (!forward) return;
    }

    const newBasket = updateOrderLine({
      orderLines: basket,
      targetLine: newProductItem,
      newData: { amount },
      removeOrderLine: amount === 0,
    }) as OrderLine[];

    setBasket({ orderLines: newBasket, info: basketValue?.info });

    if (amount === 0) setShowAmount(false);
  };

  if (isRequiredProduct) return null;

  return (
    <div className="w-full relative">
      <div
        className={classNames(
          "w-full flex flex-wrap lg:flex-nowrap md:justify-between border-t py-3",
          disabled && "opacity-60 pointer-events-none cursor-not-allowed"
        )}
      >
        <div
          onClick={onClick}
          className="border cursor-pointer border-transparent hover:border-divider-main py-2 rounded-lg hover:shadow transition w-full md:w-auto flex flex-row items-center justify-start md:flex-1 mr-6 md:px-2"
        >
          {photo ? (
            <div
              className={classNames(
                "w-14 md:w-20 h-14 rounded-md flex-shrink-0 overflow-hidden relative",
                disabled && "grayscale cursor-not-allowed"
              )}
            >
              <Image
                size={{ w: 80 }}
                src={photo}
                alt={productName}
                className="object-cover object-center w-14 md:w-20 h-14"
              />
            </div>
          ) : (
            <div
              className={classNames(
                "w-14 md:w-20 h-14 object-cover object-center rounded-md flex-shrink-0 bg-primary-main text-primary-contrast flex items-center justify-center",
                disabled && "grayscale cursor-not-allowed"
              )}
              style={{ backgroundColor: productColor }}
            >
              <FontAwesomeIcon icon={faImageSlash} />
            </div>
          )}
          <div className="flex flex-col justify-center ml-3 md:ml-6 md:block md:w-fit">
            <h1 className="text-md text-text-primary">{productName}</h1>
            <h1 className="heading-main md:heading-uppercase whitespace-nowrap block md:hidden">
              {priceFormatted}
            </h1>
          </div>
        </div>
        <div className="flex items-center justify-end w-full lg:w-fit gap-x-3">
          <h1 className="heading-main md:heading-uppercase whitespace-nowrap hidden md:block">
            {priceFormatted}
          </h1>
          {showAmount || productInBasket ? (
            <InputAmount
              amount={basketItem?.amount || 0}
              design="soft"
              setAmount={(amount) => updateAmount(amount)}
              disabled={disabled || disabledEditingFromBackend}
              minAmount={minimumAmount || 0}
            />
          ) : (
            <Button
              type="primary"
              shape="soft"
              icon={faPlus}
              onClick={addProduct}
              disabled={disabled || disabledEditingFromBackend}
            />
          )}
        </div>
        {children}
      </div>
      <DeadlineInfo
        individualDeadline={product.individualDeadline}
        individualOnset={product?.individualOnset}
        showChildrenAnyway
        hideNextDay={false}
        nextAvailableDate={nextAvailableDate}
        displayRight={false}
        displayMessage
        className="absolute top-2 -left-1"
      />
    </div>
  );
};
