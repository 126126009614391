import { T } from "@kanpla/system";
import { DayIndex } from "@kanpla/types";
import { Alert } from "@kanpla/ui";
import moment from "moment";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useGetSoftDeadlineAmount } from "./useGetSoftDeadlineAmount";

import { useSoftDeadlineMaxAmount } from "./useSoftDeadlineMaxAmount";

export const SoftDeadlineAlert = () => {
  const { week } = useContainer(OrderingContext);
  const getSoftDeadlineAmount = useGetSoftDeadlineAmount();
  const softDeadlineMaxAmount = useSoftDeadlineMaxAmount();

  const dateWithSoftDeadline = week
    .map((date, index) => {
      const dayIndex = index as DayIndex;
      const { availableChanges } = getSoftDeadlineAmount(dayIndex);

      return { date, availableChanges };
    })
    .find(({ availableChanges }) => availableChanges !== Infinity);

  if (!dateWithSoftDeadline) return null;

  return (
    <Alert
      type={dateWithSoftDeadline.availableChanges > 0 ? "info" : "danger"}
      message={
        <T
          softDeadlineMaxAmount={softDeadlineMaxAmount}
          date={moment.unix(dateWithSoftDeadline.date.seconds).format("dddd")}
          availableChanges={dateWithSoftDeadline.availableChanges}
          _str="You can only make {softDeadlineMaxAmount} last minute changes for {date}. You have {availableChanges} left"
        />
      }
      className="my-6 text-sm"
    />
  );
};
