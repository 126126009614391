import { OrderLines, _FrontendModule } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { findKey } from "lodash";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import {
  basketContainerAtom,
  isTargetOrder,
} from "../../shared/basket/useBasket";
import { CoHostsInput } from "./orderInfo/CoHosts";
import { MeetingName } from "./orderInfo/MeetingName";
import { Reference } from "./orderInfo/Reference";
import { RequiredProduct } from "./orderInfo/RequiredProduct";
import { TextInput } from "./orderInfo/TextInput";
import { TimeInput } from "./orderInfo/TimeInput";

interface Props {
  module: _FrontendModule;
  dateSeconds: number;
  basket: OrderLines;
  setBasket: (orderLines: OrderLines) => void;
}

export const OrderInfoItem = ({
  module,
  dateSeconds,
  basket,
  setBasket,
}: Props) => {
  const { moduleId, schoolId, childId } = useContainer(OrderingContext);
  const basketContainer = useAtomValue(basketContainerAtom);

  const basketId = findKey(basketContainer, (o) =>
    isTargetOrder(o, moduleId, dateSeconds, schoolId, childId)
  );

  /** Basket key should always exist */
  if (!basketId) return null;
  const basketKey = `${basketId}.info`;

  return (
    <div className="pt-3">
      <MeetingName module={module} basketKey={basketKey} />
      <TimeInput module={module} basketKey={basketKey} />
      <CoHostsInput module={module} basketKey={basketKey} />
      <Reference module={module} basketKey={basketKey} />
      <TextInput module={module} basketKey={basketKey} orderLines={basket} />
      <RequiredProduct module={module} basket={basket} setBasket={setBasket} />
    </div>
  );
};
