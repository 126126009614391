import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import { Dispatch, SetStateAction } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import Content from "./Content";
import Header from "./Header";

interface Props {
  setShowReceipt: Dispatch<SetStateAction<boolean>>;
}

const RegisteringModal = ({ setShowReceipt }: Props) => {
  const { hasOrdered } = useContainer(OrderingContext);

  const t = useT();

  return (
    <div className="max-w-md mx-auto rounded-lg mt-8">
      {hasOrdered && (
        <div className="ml-3">
          <Button
            // size="small"
            className="mb-2 w-fit"
            onClick={() => setShowReceipt(true)}
            dataCy="btn-registering-back"
            icon={faChevronLeft}
            shape="plain"
          >
            {t("My orders")}
          </Button>
        </div>
      )}
      <Header />
      <Content />
    </div>
  );
};

export default RegisteringModal;
