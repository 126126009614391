import { useT } from "@kanpla/system";
import { SubscriptionProduct, _FrontendModule } from "@kanpla/types";
import { usePriceFormatter } from "@kanpla/ui";
import { useState } from "react";

interface Props {
  subsequentProducts: SubscriptionProduct[];
  nextStart: string | number;
  module: _FrontendModule;
}

const ChooseProduct = ({ subsequentProducts = [], nextStart }: Props) => {
  const t = useT();

  const [selected, setSelected] = useState({});

  const set = (value: SubscriptionProduct) => {
    setSelected(value);
  };

  return (
    <div>
      {nextStart ? (
        <p className="text-text-secondary text-sm text-center -mt-1 mb-2">
          {t("Your change will take effect on {value}", { value: nextStart })}
        </p>
      ) : null}
      {subsequentProducts.length === 0 && (
        <div className="text-center text-danger-main mt-10 mb-4">
          {t(
            "No products to choose. Please wait until the kitchen confirms the products for the next period."
          )}
        </div>
      )}
      {subsequentProducts.map((product) => (
        <Product
          product={product}
          selected={selected}
          set={set}
          key={product.name}
        />
      ))}
    </div>
  );
};

const Product = ({ product, selected, set }) => {
  const formattedPrice = usePriceFormatter(product.price - product.subsidies);

  return (
    <label
      className={`block py-2 px-3 my-1 rounded cursor-pointer ${
        selected["id"] === product.id && "bg-info-light"
      }`}
    >
      <input
        type="radio"
        className="form-radio"
        name={product.name}
        value={product.id}
        checked={selected["id"] === product.id}
        onChange={() => set(product)}
      />
      <span className="ml-2">
        {product.name}

        <span className="ml-3 text-text-secondary">{formattedPrice}</span>
      </span>
    </label>
  );
};

export default ChooseProduct;
