import { FoodWasteInsightsData, useT } from "@kanpla/system";
import { every, isEmpty } from "lodash";
import React from "react";
import Spinner from "../../loading/Spinner";
import { FoodWasteInsights as SuccessChart } from "./FoodWasteInsights";
import { Wrapper } from "./Layout";
import { trackingInsightsMockData } from "./mock";

type FoodWasteInsightsProps =
  | ({
      state: "success";
    } & { data: FoodWasteInsightsData })
  | {
      state: "loading" | "error" | "preview";
    };

export const FoodWasteInsights = (props: FoodWasteInsightsProps) => {
  const t = useT();

  const isDataEmpty = props.state === "success" && every(props.data, isEmpty);

  if (props.state === "success" && !isDataEmpty)
    return <SuccessChart {...props} />;

  if (props.state === "preview")
    return <SuccessChart data={trackingInsightsMockData} isPreview />;

  if (props.state === "loading")
    return (
      <Wrapper>
        <Spinner size="large" className="text-text-primary" />
      </Wrapper>
    );

  return (
    <Wrapper>
      <div className="px-2 py-1 rounded-md bg-info-main w-auto mx-auto">
        <span className="text-info-contrast">{t("coming soon")}</span>
      </div>

      <span className="text-4xl my-3 font-bold text-center">
        {t("Food waste")}
      </span>

      <span className="text-center mx-4">
        {t("Check how much food is wasted in your canteen")}
      </span>
    </Wrapper>
  );
};
