import { isDatePastDeadline } from "@kanpla/system";
import { DayIndex } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useSoftDeadlineMaxAmount } from "../../flexBulk/softDeadline/useSoftDeadlineMaxAmount";
import { deadlineInfoAtom } from "../../lib/hooks/useDeadlineInfo";
import { isStandardOrderingAtom } from "../Navigation";
import { useHolidays } from "../holidays/useHolidays";

export const useGetDisabledByDeadline = () => {
  const { week, fromAdmin, offer } = useContainer(OrderingContext);
  const isStandardOrdering = useAtomValue(isStandardOrderingAtom);

  const { isDateSecondsPastDeadline, timezone } =
    useAtomValue(deadlineInfoAtom);
  const { daysHolidays } = useHolidays();
  const softDeadlineMaxAmount = useSoftDeadlineMaxAmount();

  const {
    deadlineInfo: {
      deadlineSoft,
      deadlineExcludingWeekends,
      deadlineWeekRelative,
    },
  } = offer;

  const getDisabledByDeadline = (dayIndex: DayIndex) => {
    const date = week[+dayIndex];

    const overDeadline = isDateSecondsPastDeadline(date?.seconds);

    // Disabled
    const pastSoftDate = isDatePastDeadline({
      date: week[dayIndex],
      deadline: deadlineSoft,
      deadlineExcludingWeekends,
      deadlineWeekRelative,
      timezone,
    });

    const inSoftMode = Boolean(
      !pastSoftDate &&
        overDeadline &&
        softDeadlineMaxAmount &&
        +softDeadlineMaxAmount > 0
    );

    const deadlineDisabled =
      overDeadline && !fromAdmin && !inSoftMode && !isStandardOrdering;

    const isHoliday = daysHolidays[dayIndex];

    const disabled = deadlineDisabled || isHoliday;

    return { disabled, inSoftMode };
  };

  return getDisabledByDeadline;
};
