import { useT } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { Dictionary, groupBy } from "lodash";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";

/** Returns dictionary of sorted and grouped categories. */
export const useCategories = (
  itemsFromProps?: CombinedOfferItem[]
): Dictionary<CombinedOfferItem[]> => {
  const {
    items: itemsFromContext,
    module,
    dateSeconds,
    schoolId,
  } = useContainer(OrderingContext);

  const t = useT();

  const items = itemsFromProps || itemsFromContext;

  const category = module?.text?.["noCategory/text"] || t("Dish of the day");

  const mapped = items
    ?.filter((pl) => {
      if (!pl.dates[dateSeconds]?.available) return false;

      if ((pl.fromSeconds || 0) > dateSeconds) return false;

      if ((pl.toSeconds || Infinity) < dateSeconds) return false;

      if (!pl?.scope) return true;

      if (!pl.scope?.generatedSchoolIds?.includes(schoolId)) return false;

      return true;
    })
    .map((item) => {
      const shopProducts = items.filter(
        (pl) =>
          item.productId === pl.productId &&
          dateSeconds >= (pl.fromSeconds || 0) &&
          dateSeconds <= (pl.toSeconds || Infinity) &&
          pl.scope?.generatedSchoolIds?.includes(schoolId)
      );

      const shopProduct = shopProducts?.[0];
      return {
        ...(shopProduct || {}),
        ...item,
        category: item.category ? item.category : category,
        isBig: item.category ? false : true,
      };
    });

  return groupBy(mapped, "category");
};
