import { useT } from "@kanpla/system";
import {
  CombinedOfferItem,
  FlexOption,
  MenuItemVariants,
  OrderLine,
} from "@kanpla/types";
import { ProductSettingsHeader } from "@kanpla/ui";
import classnames from "classnames";
import { pickBy, sum } from "lodash";
import { useEffect } from "react";
import Choice from "./Choice";

interface Props {
  option: FlexOption;
  color?: string;
  setError: (erroredModuleId: string, add: boolean) => void;
  orderChoices: OrderLine["options"];
  variantNames?: MenuItemVariants;
  onChange: (newValue: OrderLine["options"]) => void;
  isFirst?: boolean;
  vatRate: CombinedOfferItem["vatRate"];
}

const Option = ({
  option,
  color,
  onChange,
  orderChoices,
  variantNames,
  isFirst,
  vatRate,
}: Props) => {
  const t = useT();
  const { minimum, maximum } = option;
  const required = minimum && minimum > 0;
  const choiceIds = option.choices?.map((ch) => String(ch.id)) || [];

  const choicesWithinThisOption = pickBy(orderChoices, (value, key) =>
    choiceIds?.includes(`${key}`)
  );
  const otherChoices = pickBy(
    orderChoices,
    (_, key) => !choiceIds?.includes(`${key}`)
  );
  const amountChosen = sum(
    Object.values(choicesWithinThisOption).map((ch) => ch.amount)
  );

  const getAmountText = () => {
    const textData = [];
    if (required) textData.push(t("required"));
    if (minimum && minimum > 1)
      textData.push(t("at least {value}", { value: minimum }));
    if (maximum) textData.push(t("select up to {value}", { value: maximum }));
    const amountText = textData.join(", ");
    return amountText;
  };

  const setChoice = (
    name: string,
    id: string,
    amount: number,
    price: number,
    removeOtherChoices?: boolean
  ) => {
    const restOfChoices = pickBy(orderChoices, (v, k) => {
      const isCurrentChoice = `${k}` === `${id}`;
      const hasAmount = v.amount > 0;

      const isSameOptionGroup = choiceIds?.includes(k);

      return removeOtherChoices
        ? !isSameOptionGroup && hasAmount
        : !isCurrentChoice && hasAmount;
    });

    const newValue = {
      ...restOfChoices,
      [id]: {
        name,
        amount,
        unitExtraPrice: price,
      },
    };

    onChange(newValue);
  };

  const setMinimumChoices = () => {
    if (required) {
      const hasSelectedEnough = amountChosen >= minimum;
      if (hasSelectedEnough) return;

      const firstChoice = option?.choices?.[0];

      if (!firstChoice) return;
      const newMinimumChoice: OrderLine["options"] = {
        ...otherChoices,
        [firstChoice?.id]: {
          name: firstChoice.name,
          unitExtraPrice: firstChoice.unitPrice || 0,
          amount: minimum,
        },
      };

      onChange(newMinimumChoice);
    }
  };

  const orderChoicesTrigger = JSON.stringify(otherChoices);
  useEffect(() => {
    setMinimumChoices();
  }, [required, minimum, orderChoicesTrigger]);

  const wrapperClassNames = classnames({
    "w-full text-left": true,
    "my-4": !isFirst,
    "": isFirst,
  });

  return (
    <div className={wrapperClassNames}>
      <ProductSettingsHeader title={option?.name} subtitle={getAmountText()} />
      <ul>
        {option.choices?.map((choice) => (
          <Choice
            key={choice.id}
            id={choice.id}
            title={choice.name}
            subtitle={variantNames?.[option.id]?.[choice.id]}
            price={choice.unitPrice as number}
            color={color as string}
            max={choice.max}
            amount={orderChoices?.[choice?.id]?.amount}
            leftPerOption={option.maximum && option.maximum - amountChosen}
            disableMinus={minimum ? minimum >= amountChosen : false}
            option={option}
            setChoice={setChoice}
            vatRate={vatRate}
          />
        ))}
      </ul>
    </div>
  );
};

export default Option;
