import { OrderingContext } from "@kanpla/ordering";
import { useQueryDeliveryNotes } from "@kanpla/system";
import { useMemo } from "react";
import { useContainer } from "unstated-next";

export const useLoadDeliveryNotes = () => {
  const { moduleId, schoolId, week, module } = useContainer(OrderingContext);

  const dateSecondsArray = useMemo(
    () => week.map((day) => day.seconds),
    [week]
  );

  return useQueryDeliveryNotes({
    moduleId,
    schoolId,
    dateSecondsArray,
    enabled: !!module?.plugins?.customDeliveryNote?.active,
  });
};
