import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Space } from "antd";
import classnames from "classnames";
import { useAtomValue } from "jotai";
import { Dispatch, SetStateAction } from "react";
import { useContainer } from "unstated-next";

import { calculateProductPrice, getUnitPrice, useT } from "@kanpla/system";
import { CustomOrderContent, MenuItem } from "@kanpla/types";
import {
  Alert,
  AllergensWarning,
  DisplayAttributes,
  Image,
  ProductSettingsHeader,
  hidePricesAtom,
  usePriceFormatter,
} from "@kanpla/ui";

import { OrderingContext } from "../../context";
import { useAllowance } from "../allowance/useAllowance";
import { useAllowanceProduct } from "../allowance/useAllowanceProduct";
import { ProductOptions } from "../productOptions/ProductOptions";

import { useGetAllowanceMessage } from "../allowance/useGetAllowanceMessage";
import { ProductProps } from "./Product";
import DeadlineTimer from "./partials/DeadlineTimer";

interface Props
  extends Pick<
    ProductProps,
    | "product"
    | "isChildView"
    | "stock"
    | "showDeadlineCountdown"
    | "plugins"
    | "order"
    | "orderInfo"
    | "maxAmount"
    | "defaultReference"
    | "initialAmount"
    | "initialOptions"
    | "hasRequiredProduct"
    | "customInputs"
    | "noImage"
    | "DisabledContent"
    | "open"
  > {
  setData?: Dispatch<SetStateAction<CustomOrderContent>>;
  data?: CustomOrderContent;
  currentDay?: {
    available: true;
    stock?: number;
    menu?: MenuItem;
  };
  currentDayTimestamp?: number;
  hideVariants?: boolean;
  noWrapperMargin?: boolean;
  disabled?: boolean;
  displayAttributesClasses?: string;
}

export const ProductDetailsContent = (props: Props) => {
  const {
    product,
    isChildView = false,
    showDeadlineCountdown = false,
    customInputs,
    noImage = false,
    currentDayTimestamp,
    noWrapperMargin = false,
    disabled = false,
    DisabledContent,
    open,
  } = props;
  const { supplier, child, dayIndex, week, module } =
    useContainer(OrderingContext);
  const hidePrices = useAtomValue(hidePricesAtom);

  const t = useT();

  const hasDiscount = product?.originalPrice;
  // For leftovers products with no discount (already applied to price)
  const discountIsSameAsPrice =
    product?.originalPrice === getUnitPrice(product);
  const showDiscountUI = !discountIsSameAsPrice && hasDiscount;

  // For display more information about the product in the current day of a menu
  const currentDay = product?.dates?.[currentDayTimestamp || 0];

  const productPrice = calculateProductPrice({
    product,
    module,
    options: {},
  });

  const hideImage = noImage || !product?.photo;

  const wrapperClassNames = classnames({
    "w-full h-full kanpla-bg-primary rounded-t-lg": true,
    "mb-6": !noWrapperMargin,
    "": noWrapperMargin,
  });

  const imageClassNames = classnames(
    "h-full w-full object-cover object-center md:rounded-t-lg inset-x absolute",
    disabled && "grayscale filter cursor-not-allowed opacity-60"
  );

  const discountAmountFormatted = usePriceFormatter(hasDiscount);

  const productPriceFormatted = usePriceFormatter(productPrice);

  const { allowanceUsages } = useAllowance();
  const { messages } = useGetAllowanceMessage({ allowanceUsages });

  const { isAllowanceOverForProduct } = useAllowanceProduct({
    open: open || false,
    product,
  });

  return (
    <div className={wrapperClassNames}>
      {!hideImage && (
        <div
          className={`w-full relative bg-gradient-to-bl md:rounded-t-lg from-gray-300 to-background-secondary user-select-none pointer-events-none  ${
            isChildView ? "h-52" : "h-72"
          }`}
        >
          <Image
            src={product.photo}
            size={{ w: 400 }}
            alt={currentDay?.menu?.name || product.name}
            className={imageClassNames}
          />
          {disabled && (
            <div
              className="flex flex-row items-center text-xs w-fit text-background-primary bg-danger-main font-medium px-2 py-1 rounded-r-lg bg-opacity-80 absolute -bottom-3"
              style={{
                backdropFilter: "blur(3px)",
                WebkitBackdropFilter: "blur(3px)",
              }}
            >
              <FontAwesomeIcon icon={faClock} className="mr-2" />
              {t("It's too late to order")}
            </div>
          )}
          <DisplayAttributes
            mode="tag"
            attributes={currentDay?.menu?.labels || product.labels}
            className={isChildView ? "bottom-0 top-auto" : ""}
            supplier={supplier}
          />
        </div>
      )}
      {showDeadlineCountdown && (
        <DeadlineTimer deadline={product?.individualDeadline || null} />
      )}
      <div
        className={`px-4 pt-6 md:p-6 kanpla-bg-primary ${
          hideImage ? "md:rounded-t-lg" : ""
        }`}
      >
        {messages.map((message) => (
          <Alert type="info" message={message} className="w-full mb-4" />
        ))}
        <div className="px-2 pb-2">
          <div
            className={
              !currentDay?.menu?.description && !product.description
                ? "mb-4"
                : ""
            }
          >
            <h3
              className="title-secondary"
              title={currentDay?.menu?.name || product.name}
            >
              {currentDay?.menu?.name || product.name}
            </h3>
            {hidePrices ? null : (
              <div className="flex items-center mb-4 -mt-1">
                <p
                  className={`${
                    showDiscountUI
                      ? "text-text-disabled line-through text-sm"
                      : "text-primary-main"
                  } text-md`}
                >
                  {showDiscountUI ? (
                    discountAmountFormatted
                  ) : (
                    <>
                      {product.pricePrefix}
                      {productPriceFormatted}
                      {product.priceSuffix || ""}
                    </>
                  )}
                </p>
                {showDiscountUI && (
                  <p className={`text-primary-main ml-2 font-semibold text-md`}>
                    {productPriceFormatted}
                    {product.priceSuffix || ""}
                  </p>
                )}
              </div>
            )}
          </div>

          {(currentDay?.menu?.description || product.description) && (
            <p className="mb-4 whitespace-pre-wrap text-text-secondary">
              {currentDay?.menu?.description || product.description}
            </p>
          )}

          <AllergensWarning
            product={product}
            dayIndex={dayIndex}
            week={week}
            child={child}
            className="my-2"
          />

          {isAllowanceOverForProduct && (
            <div className="w-full my-4">
              <Alert
                type="danger"
                message={t("You are over the remaining allowance")}
              />
            </div>
          )}

          {customInputs ? (
            customInputs
          ) : (
            <div>
              <DefaultContent
                {...props}
                currentDay={currentDay}
                displayAttributesClasses="!mx-0"
              />
            </div>
          )}
          {disabled && typeof DisabledContent !== "undefined" ? (
            <div className="flex justify-center">
              <DisabledContent />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const DefaultContent = (props: Props) => {
  const {
    product,
    setData,
    initialOptions = {},
    data,
    currentDay,
    hideVariants,
    displayAttributesClasses = "",
  } = props;

  const { mealOptions, supplier } = useContainer(OrderingContext);

  const t = useT();

  const options = Object.values(
    currentDay?.menu?.options || product?.options || []
  )
    ?.reduce((acc, id) => {
      const newOption = mealOptions?.find((opt) => opt.id === id);
      return [...acc, newOption];
    }, [])
    .filter((o) => o);

  const updateValues = (newData: Partial<CustomOrderContent>) => {
    setData((data) => ({ ...data, ...newData }));
  };

  const optionChoices = data?.["optionChoices"] || initialOptions;

  const ingredients = (product?.ingredients || [])
    ?.map((i) => i?.name)
    ?.filter((i) => i);

  return (
    <>
      {!hideVariants && (
        <div className="px-2">
          <ProductOptions
            product={{
              name: currentDay?.menu?.name || product.name,
              id: product.productId,
              color: product.color,
              price: getUnitPrice(product),
              vatRate: product.vatRate,
            }}
            options={options}
            value={optionChoices}
            onChange={(newValue) => updateValues({ optionChoices: newValue })}
          />
        </div>
      )}

      <Space direction="vertical" size="large" className="w-full">
        <DisplayAttributes
          attributes={{
            ...currentDay?.menu?.allergens,
            ...product.allergens,
            ...currentDay?.menu?.pictograms,
            ...product.pictograms,
            ...currentDay?.menu?.labels,
            ...product.labels,
          }}
          supplier={supplier}
          className={displayAttributesClasses}
          mode="description"
        />
        {ingredients.length > 0 && (
          <div className="px-2 block">
            <ProductSettingsHeader title={t("Ingredients")} />
            <div className="mt-2 text-sm text-text-secondary">
              {ingredients.join(", ")}
            </div>
          </div>
        )}
      </Space>
    </>
  );
};
