import { DayIndex } from "@kanpla/types";
import classnames from "classnames";
import { useAtomValue } from "jotai";
import { selectedDayIndexAtom } from "..";
import { fullOrderAtom } from "../data/atomWeeklyOrders";
import { displayOrdersAtom } from "../data/loadedOrdersAtom";
import { useCheckIsBelow } from "../minimumOrder/useCheckIsBelow";
import { valueFromOrders } from "./product/input/helpers/valueFromOrders";

interface Props {
  dayIndex: DayIndex;
}

export const WeekTotalsItem = ({ dayIndex }: Props) => {
  const selectedDayIndex = useAtomValue(selectedDayIndexAtom);
  const finalOrder = useAtomValue(fullOrderAtom);
  const weekOrders = useAtomValue(displayOrdersAtom);

  const totalAmount = finalOrder[dayIndex]?.reduce(
    (acc, order) =>
      acc +
      (valueFromOrders({
        productId: order.productId,
        dayIndex,
        options: order.options,
        orders: weekOrders,
      }) ?? 0),
    0
  );

  const { isValid } = useCheckIsBelow({ dayIndex });

  return (
    <div
      className={classnames(
        !isValid && "text-danger-main",
        dayIndex !== selectedDayIndex && "hidden md:block",
        "md:flex-1 text-center md:w-fit w-16"
      )}
    >
      {totalAmount}
    </div>
  );
};
