import {
  CombinedTimeout,
  getInterceptionThreshold,
  scroll2Elem,
  useIsUserScrolling,
  useWindowSize,
} from "@kanpla/system";
import classnames, { Argument } from "classnames";
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntersectionObserverRef } from "rooks";

interface Props {
  scrollRef?: MutableRefObject<HTMLDivElement>;
  topOffsetPx?: number;
  className?: Argument;
  onIntercept?: () => void;
  onChange?: (isIntercepting?: boolean) => void;
  scrollIntoView?: CallableFunction | unknown;
}

const TOP_OFFSET = 100;

let scrollDisabled = false;

export const AutoScrollContainer: FC<Props> = ({
  children,
  onIntercept,
  onChange,
  topOffsetPx = TOP_OFFSET,
  scrollRef: givenScrollRef,
  className = "",
  scrollIntoView,
}) => {
  const isScrolling = useIsUserScrolling();
  const defaultScrollRef = useRef<HTMLDivElement>();
  const { height: screenHeight } = useWindowSize();

  const timer = new CombinedTimeout({
    timeout: 1000,
    callback: () => {
      scrollDisabled = false;
    },
  });

  const scrollRef = givenScrollRef || defaultScrollRef;

  const [intercepting, setIntercepting] = useState(false);

  const shouldScroll: boolean = useMemo(() => {
    const rawValue =
      typeof scrollIntoView === "function" ? scrollIntoView() : scrollIntoView;
    return Boolean(rawValue);
  }, [scrollIntoView]);

  /** Select category in horizontal menu when the category is in viewport. */
  const interceptCallback = useCallback(
    ([entry]) => {
      if (shouldScroll || scrollDisabled) return;
      setIntercepting(entry.isIntersecting);
    },
    [shouldScroll]
  );

  const [interceptionRef] = useIntersectionObserverRef(interceptCallback, {
    threshold: getInterceptionThreshold(500),
    rootMargin: `-${topOffsetPx || 0}px 0px -${
      (screenHeight || 0) - (topOffsetPx || 0) - 100
    }px`,
  });

  /** Handle intercepting. */
  useEffect(() => {
    if (shouldScroll || scrollDisabled) return;

    intercepting && onIntercept?.();
    onChange?.(intercepting);
  }, [intercepting, onIntercept, onChange, isScrolling, shouldScroll]);

  /** Handle AutoScroll */
  useEffect(() => {
    if (!shouldScroll || intercepting || !scrollRef?.current) return;

    scrollDisabled = true;
    timer.setTimer();

    scroll2Elem(scrollRef.current, { offsetY: topOffsetPx - 1 });
  }, [shouldScroll, scrollRef, topOffsetPx, intercepting]);

  return (
    <div ref={interceptionRef} className={classnames(className)}>
      <div ref={scrollRef} />
      {children}
    </div>
  );
};
