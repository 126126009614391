import { T, t } from "@kanpla/system";
import classNames from "classnames";
import React, { useState } from "react";

type ExpandableTextProps = {
  content?: string;
  rows?: number;
};

export const ExpandableText = ({
  content = "",
  rows = 1,
}: ExpandableTextProps) => {
  const [ellipsis, setEllipsis] = useState(true);

  const shouldAddEllipsis = content.length >= 100;

  if (shouldAddEllipsis)
    return (
      <p className="text-text-secondary text-xs">
        <span className={classNames(ellipsis || "flex flex-col")}>
          <span className={classNames(ellipsis && `line-clamp-${rows}`)}>
            {content}
          </span>
          {ellipsis ? (
            <span
              onClick={() => setEllipsis(false)}
              className="text-primary-main hover:text-primary-dark cursor-pointer"
            >
              <T _str="Read more" />
            </span>
          ) : (
            <span
              onClick={() => setEllipsis(true)}
              className="text-primary-main hover:text-primary-dark cursor-pointer"
            >
              <T _str="Read less" />
            </span>
          )}
        </span>
      </p>
    );

  return <p className="text-text-secondary text-xs">{content}</p>;
};
