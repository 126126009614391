import { faReceipt } from "@fortawesome/pro-duotone-svg-icons";
import { useT } from "@kanpla/system";
import { Badge, Button, Tooltip } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import useFutureOrders from "../lib/useFutureOrders";
import { accountHistoryOpenAtom } from "./Wrapper";

interface Props {
  userId: string;
}

const InnerButton = () => {
  const t = useT();
  const setHistoryOpen = useSetAtom(accountHistoryOpenAtom);

  return (
    <Tooltip content={t("Show receipts")} side="left">
      <Button
        size="small"
        shape="plain"
        onClick={() => setHistoryOpen(true)}
        icon={faReceipt}
        className="!text-2xl"
        dataCy="btn-open-history-receipt"
      />
    </Tooltip>
  );
};

const ReceiptButton = ({ userId }: Props) => {
  const { hasFutureOrders, ordersForToday } = useFutureOrders(userId);

  if (!userId) return null;

  if (!hasFutureOrders) return null;

  if (!ordersForToday)
    return (
      <Badge count={1} dot offset={[-10, 4]} size="small" useRedColor>
        <InnerButton />
      </Badge>
    );

  return (
    <Badge
      count={ordersForToday}
      overflowCount={9}
      offset={[-8, 3]}
      useRedColor
      size="small"
    >
      <InnerButton />
    </Badge>
  );
};

export default ReceiptButton;
