import { getWeekDays, tx } from "@kanpla/system";

const FlexLoader = () => {
  const days = getWeekDays();

  return (
    <div className="pb-6 pt-1 md:mx-auto mx-2">
      {days.map((day) => {
        return (
          <div key={day} className="mb-8">
            <h3 className="h500 mb-2">{day}</h3>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-3">
              <div className="loader h-24 rounded-lg" />
              <div className="loader h-24 rounded-lg" />
              <div className="loader h-24 rounded-lg" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FlexLoader;
