import { getAllowanceLeftMessage, tx } from "@kanpla/system";
import { AllowanceUsage, CombinedOfferItem } from "@kanpla/types";
import { activeCurrencyAtom } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useVatRate } from "../useVatRate";

interface UseGetAllowanceMessageArgs {
  allowanceUsages: AllowanceUsage[];
  product?: CombinedOfferItem;
  filterOutWithProduct?: boolean;
}

export const useGetAllowanceMessage = ({
  allowanceUsages,
  product,
  filterOutWithProduct = false,
}: UseGetAllowanceMessageArgs) => {
  const { module } = useContainer(OrderingContext);
  const currency = useAtomValue(activeCurrencyAtom);
  const vatRate = useVatRate();

  return getAllowanceLeftMessage({
    locale: tx.getCurrentLocale(),
    currency,
    product,
    allowanceUsages,
    vatRate,
    showExclusiveVat: module.paymentMethod === "billing",
    filterOutWithProduct,
  });
};
