import { CombinedOfferItem } from "@kanpla/types";

interface GetGroupProps {
  items: CombinedOfferItem[];
  entity: "dates" | "category";
}

export const getGroup = ({ items, entity }: GetGroupProps): string[] => {
  const values = items?.reduce((acc, item) => {
    switch (entity) {
      case "dates": {
        return [...acc, item?.["seconds"]];
      }
      case "category":
        return [...acc, item?.[entity]];
      default:
        return [...acc, item];
    }
  }, []);

  const uniqueValues = [...new Set(values)];

  return uniqueValues;
};
