import { OrderingContext } from "@kanpla/ordering";
import { callInternalApi, removeUndefinedKeys, useT } from "@kanpla/system";
import { DeliveryNote } from "@kanpla/types";
import { message } from "@kanpla/ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DeliveryNoteSubmitData } from "libs/services/src/lib/_handlers/submit/customDeliveryNote";
import moment from "moment";
import { useContainer } from "unstated-next";

export const useSaveDeliveryNote = () => {
  const t = useT();
  const queryClient = useQueryClient();
  const { schoolId, moduleId, userId, user } = useContainer(OrderingContext);
  const email = user?.email;

  return useMutation({
    mutationFn: (noteData?: DeliveryNote) => {
      if (!noteData) throw new Error("No noteData provided");
      if (!schoolId) throw new Error("No schoolId provided");
      if (!moduleId) throw new Error("No moduleId provided");
      if (!userId) throw new Error("No userId provided");

      const data: DeliveryNoteSubmitData = {
        ...noteData,
        schoolId,
        moduleId,
        userId,
        email,
        changedAt: moment().unix(),
      };

      removeUndefinedKeys(data);

      return callInternalApi<DeliveryNoteSubmitData, DeliveryNote>(
        "submit/customDeliveryNote",
        data
      );
    },
    onError: (err) => {
      console.error(err);
      message.error(t("Could not save delivery note!"));
    },
    onSuccess: async (data: DeliveryNote) => {
      message.success(t("Delivery note is saved!"));
      await queryClient.invalidateQueries({ queryKey: ["loadDeliveryNotes"] });
      return data;
    },
  });
};
