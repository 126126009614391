import { Homescreen } from "@kanpla/types";
import { Spinner, StampCardsRow } from "@kanpla/ui";
import { useContext } from "react";
import { HomescreenContext } from "..";
import { useStampCards } from "../../stampcards/useStampCards";
import SectionHeader from "../elements/SectionHeader";

interface WrapperProps {
  singleBlock: Homescreen.ContentInner;
}
const StampCards = ({ singleBlock }: WrapperProps) => {
  const { content } = useContext(HomescreenContext);

  const { title, subtitle } = singleBlock.props;
  const buttonArea = content[singleBlock?.linkedNodes?.buttonArea];

  const { stampcards, openStampcardModal, loadingStampCards } = useStampCards();

  if (!stampcards.length) return null;

  return (
    <section className="w-full">
      <SectionHeader
        title={title}
        subtitle={subtitle}
        buttonProps={content[buttonArea?.nodes?.[0]]}
      />
      <main className="rounded-lg -mx-2 md:-mx-4 lg:-mx-8 p-2 px-2 md:px-4 lg:px-8 relative overflow-hidden">
        {!loadingStampCards ? (
          <StampCardsRow
            stampcards={stampcards}
            direction="row"
            onRewardClick={(s) => openStampcardModal(s)}
          />
        ) : (
          <Spinner size="medium" />
        )}
      </main>
    </section>
  );
};

export default StampCards;
