import { useContainer } from "unstated-next";

import { CrowdTracker } from "@kanpla/ui";

import { AppContext } from "../../../contextProvider";

import { Module } from "@kanpla/types";
import { useCrowdTrackerAverage, useCrowdTrackerDay } from "./queries";

type CrowdTrackerBlockProps = {
  moduleId?: Module["id"];
};

const CrowdTrackerBlock = ({ moduleId }: CrowdTrackerBlockProps) => {
  const { schoolId, partner } = useContainer(AppContext);

  const { data: averageData, status } = useCrowdTrackerAverage({
    moduleId,
    schoolId,
    timezone: partner?.timezone,
  });
  const { data: dayData } = useCrowdTrackerDay({
    moduleId,
    schoolId,
    timezone: partner?.timezone,
  });

  if (status === "error") {
    return <CrowdTracker state="error" />;
  }

  if (!moduleId || !schoolId || status !== "success") {
    return <CrowdTracker state="loading" />;
  }

  return (
    <CrowdTracker
      state={status}
      average={averageData.data}
      day={dayData?.data}
      currentTime={dayData?.currentTime}
    />
  );
};

export default CrowdTrackerBlock;
