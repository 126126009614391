import { Supplier } from "@kanpla/types";
import { DisplayAttributes } from "@kanpla/ui";
import { SingleProductProps } from "./Product";

interface Props {
  product: SingleProductProps;
  onClick: () => void;
  dateSeconds: number;
  supplier?: Supplier;
}

export const FlexModule = ({
  product,
  onClick,
  dateSeconds,
  supplier,
}: Props) => {
  if (!product) return null;

  const menuItem = product.dates?.[dateSeconds]?.menu;

  const productName: string = menuItem?.name || product.name;

  const attributes = {
    ...(menuItem?.allergens || {}),
    ...(product?.allergens || {}),
    ...(product?.labels || {}),
    ...(menuItem?.labels || {}),
  };

  return (
    <div
      onClick={onClick}
      className={`bg-background-primary rounded-lg flex flex-col justify-between relative cursor-pointer border border-divider-main`}
    >
      <div className="px-5 py-6">
        {menuItem?.name && (
          <p className="text-xs text-text-secondary ">{product.name}</p>
        )}
        <h3 className="text-text-primary text-xl mb-2 font-semibold">
          {productName}
        </h3>
        <p className="text-text-secondary text-sm whitespace-pre-wrap">
          {menuItem?.description || product.description || ""}
        </p>
        <DisplayAttributes
          attributes={attributes}
          supplier={supplier}
          mode="all"
        />
      </div>
    </div>
  );
};
