import { Order, OrderLines } from "@kanpla/types";
import { calculateOrderVatBreakdown } from "./calculateOrderVatBreakdown";

/** calculateOrderTotalByOrder displays price inclusive/exclusive VAT based on the order payment method. */

export const calculateOrderTotalByOrder = (
  orderLines: OrderLines | undefined = [],
  order: Pick<Order, "paymentMethod">
) => {
  const { withoutVat, withVat } = calculateOrderVatBreakdown(orderLines);
  const showExclusiveVat = order.paymentMethod === "billing";
  const result = showExclusiveVat ? withoutVat : withVat;

  return result;
};
