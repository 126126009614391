import { getLastFlexOrderChange } from "@kanpla/system";
import { DayIndex, Timestamp } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { deadlineInfoAtom } from "../../lib/hooks/useDeadlineInfo";
import { useFinalOrders } from "../data/useFinalOrders";
import { currentWeekDocumentsAtom } from "./currentWeekDocumentsAtom";
import { useGetDisabledByDeadline } from "./useDisabledByDeadline";
import { useSoftDeadlineMaxAmount } from "./useSoftDeadlineMaxAmount";

export const useGetSoftDeadlineAmount = () => {
  const softDeadlineMaxAmount = useSoftDeadlineMaxAmount();
  const { week } = useContainer(OrderingContext);
  const { deadline } = useAtomValue(deadlineInfoAtom);
  const finalOrders = useFinalOrders();
  const currentWeekDocuments = useAtomValue(currentWeekDocumentsAtom);

  const getDisabledBySoftDeadline = useGetDisabledByDeadline();

  const getSoftDeadlineAmount = (dayIndex: DayIndex) => {
    const { inSoftMode } = getDisabledBySoftDeadline(dayIndex);
    if (!inSoftMode)
      return { min: 0, max: Infinity, availableChanges: Infinity };

    const res = getLastFlexOrderChange({
      order: currentWeekDocuments[dayIndex],
      date: week[dayIndex] as Timestamp,
      deadline,
      softDeadlineMaxAmount,
      localOrder: finalOrders[dayIndex],
    });
    const availableChanges = res?.availableChanges || 0;

    return { availableChanges };
  };

  return getSoftDeadlineAmount;
};
