import React from "react";

const PhoneWireframe = () => {
  return (
    <svg
      width="175"
      height="105"
      viewBox="0 0 175 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_50_1538"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="172"
        height="105"
      >
        <rect width="172" height="105" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_50_1538)">
        <path
          d="M173.941 72.9346H172.024V27.5486C172.024 20.5818 168.822 13.9004 163.123 8.97412C157.424 4.04788 149.694 1.28034 141.634 1.28033H30.3899C26.3991 1.28033 22.4473 1.95977 18.7602 3.27986C15.0732 4.59995 11.723 6.53486 8.90103 8.97408C6.07907 11.4133 3.84056 14.3091 2.31331 17.4961C0.786073 20.6831 6.76263e-06 24.0989 0 27.5485V276.54C-6.82887e-06 283.507 3.20177 290.188 8.90097 295.114C14.6002 300.041 22.33 302.808 30.3898 302.808H141.634C149.694 302.808 157.424 300.041 163.123 295.114C168.822 290.188 172.024 283.507 172.024 276.54V105.241H173.941V72.9346Z"
          fill="#0E0E0E"
        />
        <path
          d="M142.29 7.95375H127.827C128.492 9.37014 128.746 10.9069 128.567 12.4286C128.387 13.9502 127.779 15.4102 126.797 16.6798C125.815 17.9494 124.488 18.9897 122.934 19.709C121.379 20.4284 119.645 20.8046 117.884 20.8048H54.4081C52.647 20.8047 50.9129 20.4284 49.3587 19.709C47.8045 18.9897 46.4778 17.9494 45.4955 16.6798C44.5132 15.4102 43.9054 13.9502 43.7257 12.4285C43.546 10.9069 43.7999 9.37012 44.465 7.95374H30.9564C27.988 7.95373 25.0486 8.46158 22.3061 9.44827C19.5636 10.435 17.0717 11.8812 14.9727 13.7044C12.8737 15.5276 11.2087 17.692 10.0727 20.0741C8.93673 22.4562 8.35205 25.0093 8.35205 27.5877V276.432C8.35205 279.01 8.93672 281.563 10.0727 283.945C11.2087 286.327 12.8737 288.492 14.9727 290.315C17.0717 292.138 19.5636 293.584 22.3061 294.571C25.0485 295.558 27.9879 296.066 30.9563 296.066H142.29C148.285 296.066 154.035 293.997 158.274 290.315C162.513 286.633 164.895 281.639 164.895 276.432V27.5877C164.895 25.0094 164.31 22.4562 163.174 20.0741C162.038 17.692 160.373 15.5276 158.274 13.7044C156.175 11.8812 153.683 10.435 150.941 9.44829C148.198 8.46159 145.259 7.95375 142.29 7.95375Z"
          fill="white"
        />
      </g>
      <rect
        x="16"
        y="61"
        width="40"
        height="25"
        rx="3"
        fill="#DBE0EA"
        fill-opacity="0.26"
      />
      <rect
        x="64"
        y="61"
        width="41"
        height="25"
        rx="3"
        fill="#DBE0EA"
        fill-opacity="0.26"
      />
      <rect
        x="113"
        y="60"
        width="40"
        height="25"
        rx="3"
        fill="#DBE0EA"
        fill-opacity="0.26"
      />
      <rect
        x="16"
        y="33"
        width="48"
        height="10.1795"
        rx="5.08977"
        fill="#DBE0EA"
      />
      <rect x="134" y="33" width="24" height="10" rx="5" fill="#DBE0EA" />
      <path
        d="M172 72H174C174.552 72 175 72.4477 175 73V105H172V72Z"
        fill="#0E0E0E"
      />
      <path
        d="M17 100.308C17 98.6509 18.3431 97.3077 20 97.3077H54C55.6569 97.3077 57 98.6509 57 100.308V105H17V100.308Z"
        fill="#DBE0EA"
        fill-opacity="0.26"
      />
      <path
        d="M65 100.308C65 98.6509 66.3431 97.3077 68 97.3077H103C104.657 97.3077 106 98.6509 106 100.308V105H65V100.308Z"
        fill="#DBE0EA"
        fill-opacity="0.26"
      />
      <path
        d="M114 100C114 98.3431 115.343 97 117 97H151C152.657 97 154 98.3431 154 100V104.692H114V100Z"
        fill="#DBE0EA"
        fill-opacity="0.26"
      />
    </svg>
  );
};

export default PhoneWireframe;
