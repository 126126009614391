import { useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { authModalStateAtom } from "../modals/Anonymous/AnonymousModal";

const AnonymousMenuButton = () => {
  const t = useT();
  const { module } = useContainer(AppContext);
  const setAuthModalState = useSetAtom(authModalStateAtom);

  const fromAPrivateModule = module?.public === false;

  return (
    <>
      <Button
        onClick={() => {
          setAuthModalState("login");
        }}
        type="primary"
        shape="solid"
        className="mr-2 w-auto"
        dataCy="login-btn"
        disabled={fromAPrivateModule}
      >
        {t("Login")}
      </Button>

      <Button
        shape="solid"
        onClick={() => {
          setAuthModalState("signup");
        }}
        disabled={fromAPrivateModule}
        dataCy="signup-btn"
        className="w-auto"
      >
        {t("Sign up")}
      </Button>
    </>
  );
};

export default AnonymousMenuButton;
