import {
  CombinedOfferItem,
  GroupedCombinedOffer,
  Week,
  _FrontendModule,
} from "@kanpla/types";
import { t } from "@transifex/native";
import { getGroup } from "./getGroup";

interface Props {
  items: CombinedOfferItem[];
  week: Week;
  module: _FrontendModule;
}

/**
 * This method group the offers by available day and by category.
 * Object structure:
 * {
 *   [dateSeconds]: {
 *     [category]: [offer, offer, offer],
 *     [category]: [offer, offer],
 *   },
 *   ...
 * }
 */
export const groupOfferItems = ({
  items,
  week,
  module,
}: Props): GroupedCombinedOffer => {
  const datesSeconds = getGroup({
    items: week as any,
    entity: "dates",
  });

  const categoryName =
    module?.text?.["noCategory/text"] || t("Dish of the day");

  const categories = getGroup({ items, entity: "category" }).map(
    (category) => category || categoryName
  );
  const groupedItems = datesSeconds.reduce((acc, dateSeconds) => {
    const reducedCategories = categories.reduce(
      (acc, category) => ({ ...acc, [category]: [] }),
      {}
    );

    return {
      ...acc,
      [dateSeconds]: reducedCategories,
    };
  }, {});

  Object.entries(groupedItems).forEach(([key, value]) => {
    const availableItems = items.filter(
      (item) =>
        item.dates[key]?.available &&
        item.dates[key]?.menu?.name?.toLowerCase() !== "x"
    );

    if (availableItems.length) {
      Object.keys(value).forEach((category) => {
        const filteredAvailableItems = availableItems.filter((item) => {
          const itemCategory = item.category || categoryName;
          return itemCategory === category;
        });

        value[category] = filteredAvailableItems;
      });
    }
  });

  return groupedItems;
};
