import { faTriangleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import {
  ActionType,
  Button,
  Checkbox,
  useConfirmation,
  usePriceFormatter,
} from "@kanpla/ui";
import { useAtom, useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { FieldValues, useForm } from "react-hook-form";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";
import {
  basketAtom,
  basketContainerAtom,
  basketContainerTotalPriceAtom,
  hasEnoughMoneyToPayBasketAtom,
} from "../../../shared/basket/useBasket";
import { useOrderPriceBreakdown } from "../../../shared/basket/useOrderPriceBreakdown";
import ChoosePaymentMethod from "../../../shared/payment/ChoosePaymentMethod";
import AdyenApplePay from "../../../shared/payment/adyen/AdyenApplePay";
import AdyenMobilePay from "../../../shared/payment/adyen/AdyenMobilePay";
import { rememberCardAtom } from "../elements/Payment";
import { selectedPaymentMethodAtom } from "../elements/selectedPaymentMethodAtom";

interface Props {
  onPurchase: (data: FieldValues) => Promise<void>;
  loading: boolean;
}

const useBasketActions = (props: Props) => {
  const { onPurchase, loading } = props;

  const {
    balance,
    isSuperadmin,
    isOverridingUser,
    child,
    userId,
    activePlugins,
    module,
    onAuthClick,
    fromAdmin,
    card,
    paymentGatewayProvider,
  } = useContainer(OrderingContext);

  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);
  const basket = useAtomValue(basketAtom);
  const basketContainer = useAtomValue(basketContainerAtom);
  const basketContainerTotalPrice = useAtomValue(basketContainerTotalPriceAtom);
  const hasEnoughMoneyToPayBasket = useAtomValue(hasEnoughMoneyToPayBasketAtom);
  const [rememberCard, setRememberCard] = useAtom(rememberCardAtom);

  const { handleSubmit } = useForm();

  const { totalPrice, totalDiscountAmount } = useOrderPriceBreakdown({
    orderLines: basket,
  });

  const canOrderForFree =
    module.paymentMethod === "credit" &&
    !isEmpty(basketContainer) &&
    totalPrice <= 0;

  const canProcessPayment =
    activePlugins["kanplaGo"] || activePlugins["payPerOrder"];
  const onlyCredit = module.paymentMethod === "credit" && !canProcessPayment;

  const confirm = useConfirmation();

  const t = useT();

  const difference = basketContainerTotalPrice - (balance || 0);

  const canPayWithSomeBalance =
    (balance || 0) > 0 && !hasEnoughMoneyToPayBasket;

  const amountToPay = hasEnoughMoneyToPayBasket
    ? basketContainerTotalPrice
    : difference;

  const amountToPayFormatted = usePriceFormatter(
    amountToPay - totalDiscountAmount
  );
  const balanceFormatted = usePriceFormatter(balance || 0);

  const handleOnPurchase = async (data: FieldValues) => {
    if (isSuperadmin && isOverridingUser) {
      await confirm({
        title: "You are about to order on behalf of the user!",
        closableOutside: false,
        children: (
          <>
            <p className="text-center">
              Are you sure you want to place this order on behalf of this child?
            </p>
            <p className="text-sm text-center text-text-disabled mt-2">
              {child?.displayName || child?.name}
              <br />
              UID: {userId}
            </p>
          </>
        ),
        extraTopContent: (
          <div className="w-full flex mb-2 justify-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-orange-500"
              size="2x"
            />
          </div>
        ),
      });
    }

    await onPurchase(data);
  };

  // check if there is a new order in the basket (without ID)
  const containsNewOrders = Object.values(basketContainer).some((o) => !o.id);

  const disableSwipeIfEmptyOnRegistering =
    module?.flow === "registering" && isEmpty(basket);

  const disabledPayment =
    !fromAdmin && onlyCredit && !hasEnoughMoneyToPayBasket && !canOrderForFree;

  const disabledNoMethodSelected =
    !selectedPaymentMethod &&
    module.paymentMethod === "credit" &&
    !hasEnoughMoneyToPayBasket;

  const disabled =
    disabledPayment ||
    disabledNoMethodSelected ||
    loading ||
    disableSwipeIfEmptyOnRegistering;

  const registeringFlow =
    module?.flow === "registering" && module.paymentMethod === "billing";
  const creditFlow = hasEnoughMoneyToPayBasket
    ? t("Pay {value} from balance", { value: amountToPayFormatted })
    : t("Pay Now");

  const labelPurchase = registeringFlow ? t("Register Now") : creditFlow;

  let labelButton: string;
  switch (true) {
    case module.paymentMethod === "billing":
    case module.paymentMethod === "credit" && fromAdmin:
    case !containsNewOrders:
      labelButton = t("Approve");
      break;
    case canProcessPayment && !hasEnoughMoneyToPayBasket:
      labelButton = t("Pay {price} and check out", {
        price: amountToPayFormatted,
      });
      break;
    default:
      labelButton = t("Check out");
  }

  const showPayButton =
    module.paymentMethod !== "billing" &&
    canProcessPayment &&
    containsNewOrders;

  const authenticatedActions: ActionType[] = [
    showPayButton ? (
      <div className="flex flex-col gap-y-4 p-4 bg-background-primary border-t border-divider-main shadow-[0px_-14px_36px_-21px_rgba(0,0,0,0.2)]">
        <div className="w-full h-fit flex justify-between items-center gap-x-4">
          {!hasEnoughMoneyToPayBasket && !canOrderForFree && (
            <ChoosePaymentMethod className="min-w-fit" />
          )}
          <div className="flex items-end w-full flex-col gap-y-2">
            {canPayWithSomeBalance && !canOrderForFree && (
              <span className="text-text-secondary text-sm flex">
                {t("From balance")}:
                <p className="!text-text-primary ml-2 font-medium">
                  {balanceFormatted}
                </p>
              </span>
            )}
            <span className="text-text-secondary flex">
              {t("Total")}:
              <p className="!text-text-primary ml-2 font-medium">
                {amountToPayFormatted}
              </p>
            </span>
          </div>
        </div>
        {!hasEnoughMoneyToPayBasket &&
          selectedPaymentMethod === "card" &&
          !card &&
          !canOrderForFree && (
            <Checkbox
              onChange={(e) => setRememberCard(e.target.checked)}
              checked={rememberCard}
              className="text-text-primary"
            >
              {t("Save the card for later payments")}
            </Checkbox>
          )}
        {/* TODO: This will be refactored better with the GooglePay PR */}
        {selectedPaymentMethod === "applepay" &&
        paymentGatewayProvider === "adyen" ? (
          <AdyenApplePay />
        ) : selectedPaymentMethod === "mobilepay" &&
          paymentGatewayProvider === "adyen" ? (
          <AdyenMobilePay />
        ) : (
          <Button
            type="primary"
            className="w-full"
            disabled={disabled}
            loading={loading}
            htmlType="submit"
            form="basket-form"
            onClick={() => {
              handleSubmit(handleOnPurchase);
            }}
          >
            {labelPurchase}
          </Button>
        )}
      </div>
    ) : (
      {
        label: labelButton,
        type: "primary",
        onClick: () => {
          handleSubmit(handleOnPurchase);
        },
        htmlType: "submit",
        form: "basket-form",
        loading,
        disabled,
      }
    ),
  ];

  const unauthenticatedActions: ActionType[] = [
    {
      label: t("Login"),
      onClick: () => onAuthClick("login"),
      type: "primary",
      dataCy: "login-anonymous",
    },
    {
      label: t("Sign up"),
      onClick: () => onAuthClick?.("signup"),
      type: "secondary",
      dataCy: "signup-anonymous",
    },
  ];

  const customActions = userId && showPayButton;

  return {
    noPadding: customActions ? true : false,
    className: customActions
      ? "!bg-background-primary !overflow-visible !p-0"
      : "",
    actions: userId ? authenticatedActions : unauthenticatedActions,
    onSubmit: userId ? handleOnPurchase : () => null,
  };
};

export default useBasketActions;
