import { Plugins } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../../context";
import { basketAtom } from "../../../../shared/basket/useBasket";
import Basket from "../../../basket/index";
import { EmptyBasket } from "../../MobileBasket";
interface Props {
  hideButton?: boolean;
  /** This triggers opening of the modal, when the state changes to edit, used e.g. in meeting */
  openModalWithEdit?: boolean;
}

const BasketStep = ({ hideButton = false }: Props) => {
  const { module, mobile } = useContainer(OrderingContext);
  const basket = useAtomValue(basketAtom);

  // Ignore required product
  const plugins = module?.plugins || ({} as Plugins.Array);
  const requiredProductId = plugins?.requiredProduct?.productId;

  const showEmptyBasket =
    !hideButton &&
    isEmpty(basket.filter((pl) => requiredProductId !== pl.productId));

  if (mobile) return null;

  return showEmptyBasket ? <EmptyBasket /> : <Basket />;
};

export default BasketStep;
