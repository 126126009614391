import { Space } from "@kanpla/ui";
import { useContext } from "react";
import { HomescreenContext } from "..";
import { BlockProps, serializer } from "../utils";

const Editor = ({ singleBlock }: BlockProps) => {
  const { content } = useContext(HomescreenContext);

  const childNodes = singleBlock?.nodes;

  return (
    <div className="w-full">
      <Space direction="vertical" size="large" className="w-full">
        {childNodes.map((nodeId) => {
          const node = content[nodeId];

          return serializer(node, nodeId);
        })}
      </Space>
    </div>
  );
};

export default Editor;
