import { OrderingContext } from "@kanpla/ordering";
import { mapValues, pickBy } from "lodash";
import { useContainer } from "unstated-next";
import { WeeklyOrders } from "../../data/atomWeeklyOrders";
import { useIsSpecialOption } from "./useIsSpecialOption";

export const useFilterOutSpecialVariants = () => {
  const { mealOptions } = useContainer(OrderingContext);
  const isSpecialOption = useIsSpecialOption();

  // 2. Prepare hidden options
  const specialOptions = mealOptions?.filter((o) => isSpecialOption(o));
  const choiceIdsToFilterOut = specialOptions.flatMap(
    (o) => o.choices?.map((ch) => ch.id) || []
  );

  // 4. Return function to filter out special variants
  const filterOutSpecialVariants = (orders: WeeklyOrders) =>
    mapValues(orders, (order) =>
      order.map((orderLine) => ({
        ...orderLine,
        options: pickBy(
          orderLine.options,
          (_, choiceId) => !choiceIdsToFilterOut.includes(choiceId)
        ),
      }))
    );

  return filterOutSpecialVariants;
};
