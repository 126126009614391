import {
  getActivePlugins,
  isSchoolSelectorInScope,
  useT,
  validateRequiredOrder,
} from "@kanpla/system";
import { OrderLines, Plugins, User, _FrontendModule } from "@kanpla/types";
import { Form, Input, Select } from "@kanpla/ui";
import { uniq } from "lodash";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";

interface Props {
  basketKey: string;
  module: _FrontendModule;
  /** Current basket, to show/hide text fields based on the order */
  orderLines: OrderLines;
}

interface TextInputInnerProps {
  field: Plugins.TextInputField;
  value?: string | Array<string>;
  onChange?: (newValue: string | Array<string>) => void;
  defaultValues: User["textInputValues"];
}

const TextInputInner = ({
  field,
  value,
  onChange = () => null,
  defaultValues = {},
}: TextInputInnerProps) => {
  const t = useT();
  const defaultSelect = defaultValues[field?.key];

  const isTextInput = field?.type === "text" || !field?.type;
  const isSelectInput =
    field?.type === "select" && field?.type && !field?.isMultiSelect;
  const isMultiSelectInput =
    field?.type === "select" && field?.type && field?.isMultiSelect;

  useEffect(() => {
    if (isTextInput || !defaultSelect) return;
    if (defaultSelect === "undefined") return;

    onChange(defaultSelect);
  }, []);

  const options = field.options?.map((option) => ({
    value: option,
    label: option,
  }));

  if (isSelectInput)
    return (
      <Select
        className="my-0.5"
        placeholder={t("Select an option")}
        value={
          typeof value === "string" ? value : value?.[0] ? value : defaultSelect
        }
        onChange={onChange}
        options={options}
        required={field?.required}
        defaultValue={defaultSelect}
      />
    );

  if (isMultiSelectInput) {
    const defaultMultiValue = [...(defaultValues[field?.key] || [])].map(
      (value) => ({
        value,
        label: value,
      })
    );

    return (
      <Select
        className="my-0.5"
        isMulti
        placeholder={t("Select one or more options")}
        value={[value]?.flat(2)}
        hideSelectedOptions={false}
        onChange={(_value) => {
          const data = uniq(_value.flat()) as Array<string>;
          onChange(data);
        }}
        options={options}
        required={field?.required}
        defaultValue={defaultMultiValue}
      />
    );
  }

  if ((field?.type === "text" || !field?.type) && field?.isTextArea)
    return (
      <Input.TextArea
        required={field?.required}
        value={value as string}
        onChange={(e) => onChange(e.target.value)}
        placeholder={field.placeholder || field?.title || ""}
      />
    );

  return (
    <Input
      required={field?.required}
      value={value as string}
      onChange={(e) => onChange(e.target.value)}
      placeholder={field.placeholder || field?.title || ""}
    />
  );
};

export const TextInput = ({ basketKey, module, orderLines }: Props) => {
  const t = useT();
  const { schoolId, groupName, user } = useContainer(OrderingContext);

  const settings = module?.plugins?.textInput;
  const { activePlugins } = getActivePlugins({ module });

  // Filter out fields disabled for this school
  const availableFields =
    settings?.fields?.filter((f) => {
      // Fallback for the old text input plugin
      if (f.scope) {
        const isAvailable = isSchoolSelectorInScope({
          scope: f.scope,
          schoolId,
          groupName,
        });
        const fulfillsRequiredOrder = validateRequiredOrder(
          orderLines,
          f.requiredOrder
        );
        return isAvailable && fulfillsRequiredOrder;
      }
      return !(f.disabledSchoolIds || []).includes(schoolId);
    }) || [];

  if (availableFields.length === 0) return null;
  if (!activePlugins["textInput"]) return null;

  return (
    <>
      {availableFields.map((field) => {
        return (
          <Form.Item
            key={field.key}
            name={`${basketKey}.textInput.${field.key}`}
            rules={
              field?.required
                ? {
                    required: t("{fieldName} is required", {
                      fieldName: field?.title,
                    }),
                  }
                : {}
            }
            label={field.title}
            description={field.subtitle}
            required={field?.required}
          >
            <TextInputInner
              field={field}
              defaultValues={user?.textInputValues}
            />
          </Form.Item>
        );
      })}
    </>
  );
};
