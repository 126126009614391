import { OrderingContext } from "@kanpla/ordering";
import { T, useT } from "@kanpla/system";
import { DayIndex, DeliveryNote } from "@kanpla/types";
import { DrawerOrModal, Form, TextAreaWithOptions } from "@kanpla/ui";
import TextArea, { TextAreaRef } from "antd/lib/input/TextArea";
import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSaveDeliveryNote } from "./useSaveDeliveryNote";

type Props = {
  dateSeconds: number;
  deliveryNote?: DeliveryNote;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const DeliveryNoteModal = ({
  open,
  setOpen,
  deliveryNote = {} as DeliveryNote,
  dateSeconds,
}: Props) => {
  const t = useT();
  const { mutateAsync } = useSaveDeliveryNote();
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState(deliveryNote?.note || "");

  const date: string = useMemo(() => {
    if (!dateSeconds) return "";

    return moment.unix(dateSeconds).format("dddd D.M.");
  }, [dateSeconds]);

  useEffect(() => setNote(deliveryNote?.note || ""), [deliveryNote?.note]);

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      actions={[
        {
          label: t("Cancel"),
          onClick: () => setOpen(false),
          size: "medium",
          type: "secondary",
        },
        {
          label: t("Save"),
          size: "medium",
          loading,
          onClick: async () => {
            setLoading(true);
            try {
              await mutateAsync({
                ...deliveryNote,
                dateSeconds,
                note,
              });
              setOpen(false);
            } catch (error) {
              console.log(error);
            } finally {
              setLoading(false);
            }
          },
        },
      ]}
    >
      <h1 className="text-2xl font-bold mb-4">
        <T _str="Delivery note for {date}" date={date} />
      </h1>
      <TextArea
        value={note}
        placeholder={t(
          "Add your custom delivery note, be as descriptive as possible"
        )}
        style={{ height: "300px" }}
        size="large"
        onChange={(e) => setNote(e?.target?.value || "")}
      />
    </DrawerOrModal>
  );
};
