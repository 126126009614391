import { Homescreen } from "@kanpla/types";
import { isEmpty } from "lodash";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

interface Props {
  block: Homescreen.ContentInner | null;
}

export const useTargetBlock = ({ block }: Props): Props => {
  const { school } = useContainer(AppContext);

  const isContainer =
    block?.type === "EditorBlock" ||
    block?.type === "SectionComponent" ||
    block?.type === "SectionInner";

  if (isContainer) return { block };

  /** Map block variations */
  const variations = Object.entries(block?.props?.variations || {}).map(
    ([variationId, variation]) => ({
      type: block?.type,
      /** Needed inside the ProductsComponent node */
      linkedNodes: block?.linkedNodes,
      props: {
        variationId,
        ...variation,
      },
    })
  ) as unknown as Homescreen.ContentInner[];
  const allBlocks = [...variations, block];

  /** All created blocks are not scoped in any sales place */
  const allBlocksHaveEmptyScope = allBlocks.every(
    (block) =>
      !isEmpty(block?.props?.scope) &&
      isEmpty(block?.props?.scope?.generatedSchoolIds)
  );

  if (allBlocksHaveEmptyScope) return { block: null };

  /** Variation available in defined sales places */
  const variation = allBlocks.find((v) =>
    v?.props?.scope?.generatedSchoolIds?.includes(school?.id)
  );

  if (variation) return { block: variation };

  /** Variation available everywhere */
  const everywhereVariation = variations.find((v) => !v?.props?.scope);
  if (everywhereVariation) return { block: everywhereVariation };

  const scope = block?.props?.scope;
  const hasSchoolId = scope?.generatedSchoolIds?.includes(school?.id);
  const isEverywhere = !scope;

  /** Only the parent block it's available */
  if (hasSchoolId || isEverywhere) return { block };

  return { block: null };
};
