import { CloseOutlined } from "@ant-design/icons";
import { useT } from "@kanpla/system";
import { PopupConstructor } from "@kanpla/types";
import { useEffect, useState } from "react";

interface Props {
  popup: PopupConstructor.Banner;
  closePopup: () => void;
}

const Banner = ({ popup, closePopup }: Props) => {
  const t = useT();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => setOpen(true), 500);
  }, []);

  const text = popup?.blocks?.[0]?.text;
  const button = popup?.blocks?.[1];

  return (
    <div
      className={`p-4 px-6 rounded-lg bg-primary-dark z-max left-1/2 transform -translate-x-1/2 md:max-w-2xl shadow-xl text-primary-contrast w-full flex items-center justify-end transition-all duration-500 fixed ${
        popup.placement === "top"
          ? open
            ? "top-16"
            : "-top-0 opacity-0 pointer-events-none scale-90"
          : open
          ? "bottom-2"
          : "-bottom-4 opacity-0 pointer-events-none scale-90"
      }`}
    >
      <p className="mr-auto">{text}</p>
      {button && button.label && button.link && (
        <a href={button.link} target="_blank" rel="noreferrer">
          <button className="border border-divider-main rounded p-1 px-2 flex-shrink-0 text-sm text-primary-contrast hover:text-primary-contrast hover:bg-primary-contrast hover:bg-opacity-10 no-underline transition-colors">
            {button.label}
          </button>
        </a>
      )}
      <button
        aria-label={t("Close the banner")}
        className="border border-transparent hover:border-divider-main transition-all ml-2 rounded flex items-center justify-center p-2"
        onClick={() => {
          setOpen(false);
          setTimeout(closePopup, 500);
        }}
      >
        <CloseOutlined className="text-primary-contrast text-sm flex-shrink-0" />
      </button>
    </div>
  );
};

export default Banner;
