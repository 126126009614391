import { faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import {
  Timestamp,
  dateToMomentUtc,
  filterModulesForChild,
  getDaysInRange,
  getMidnightSeconds,
  useT,
} from "@kanpla/system";
import {
  HolidayPeriod,
  LoadOfferReturn,
  Timestamp as Tsp,
} from "@kanpla/types";
import {
  Alert,
  Button,
  Calendar,
  DrawerOrModal,
  Form,
  Select,
} from "@kanpla/ui";
import classnames from "classnames";
import { useAtom, useAtomValue } from "jotai";
import moment, { Moment } from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { DEFAULT_PERIOD, DEFAULT_PERIODS } from "..";
import { OrderingContext } from "../../context";
import { holidayModuleIdAtom } from "../../flexBulk/FlexBulkHolidaysModal";
import { useAvailableModules } from "../../orders/useAvailableModules";
import { deadlineInfoAtom } from "../hooks/useDeadlineInfo";

interface Props {
  onConfirm: (periods: HolidayPeriod[]) => Promise<void> | void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open?: boolean;
  periods?: HolidayPeriod[];
  holidayDates: LoadOfferReturn["holidayDates"];
  isAdmin?: boolean;
  fromUserSettings?: boolean;
  holidaysLoading?: boolean;
  savingLoading?: boolean;
}

const seconds2Timestamp = (
  seconds?: number | null
): [Moment | null, Tsp | undefined] => {
  if (!seconds) return [null, undefined];

  const momentDate = moment.unix(seconds);
  const tsp = new Timestamp(momentDate.unix(), 0);

  return [momentDate, tsp];
};

export const HolidaysModal = ({
  onConfirm,
  setOpen,
  open = false,
  periods: periodsValue = DEFAULT_PERIODS,
  holidayDates,
  isAdmin,
  fromUserSettings = false,
  holidaysLoading = false,
  savingLoading = false,
}: Props) => {
  const t = useT();

  const { school, child, module } = useContainer(OrderingContext);

  const [holidayModuleId, setHolidayModuleId] = useAtom(holidayModuleIdAtom);

  const modules = useAvailableModules({ viewEmployeeOrders: isAdmin || false });
  const flexModules =
    filterModulesForChild({ modules, school, child })?.filter(
      (m) => m.type === "flex"
    ) || [];

  const { isDateSecondsPastDeadline } = useAtomValue(deadlineInfoAtom);

  const [periods, setPeriods] = useState<HolidayPeriod[]>(periodsValue);

  useEffect(() => {
    setPeriods(periodsValue);
  }, [periodsValue]);

  useEffect(() => {
    if (open || !fromUserSettings) return;

    setHolidayModuleId(null);
  }, [open]);

  useEffect(() => {
    if (!open) return;
    if (!fromUserSettings) return;
    if (holidayModuleId) return;

    setHolidayModuleId(flexModules?.[0]?.id);
  }, [open, holidayModuleId]);

  const [overlappingPeriods, setOverlappingPeriods] = useState<HolidayPeriod[]>(
    []
  );

  const getActiveHolidays = () => {
    const holidayEntries = Object.entries(holidayDates || {}) || [];
    const holidays = holidayEntries.reduce((acc, [seconds, holiday]) => {
      if (!holiday) return acc;
      return [...acc, seconds];
    }, [] as string[]);

    return holidays;
  };
  const activeHolidays = getActiveHolidays();

  const addPeriod = () => {
    setPeriods((prevState) => [...prevState, DEFAULT_PERIOD]);
  };

  const deletePeriod = (index: number) => {
    setPeriods((prevPeriods) => {
      const item = prevPeriods?.[index];

      // Delete when holiday-document already exists
      if (item?.id) {
        return periods.map((p, ind) =>
          ind === index ? { ...p, deleted: true } : p
        );
      }

      // Delete when holiday-period is added new
      return periods.filter((_, indexPeriod) => indexPeriod !== index);
    });
  };

  const changeModules = (value: boolean, index: number) => {
    setPeriods((prevState) => {
      const newState = prevState.map((period, indexPeriod) => {
        // Just return other periods
        if (indexPeriod !== index) return period;

        return { ...period, isAllModules: value, moduleIds: [module?.id] };
      });

      return newState;
    });
  };

  const choosePeriod = (event: [Moment, Moment], index: number) => {
    const [startDate = null, endDate = null] = event || [];

    const newFromSeconds = startDate
      ? getMidnightSeconds(startDate.unix())
      : null;
    let newToSeconds = endDate ? getMidnightSeconds(endDate.unix()) : null;

    if (newFromSeconds && newToSeconds && newFromSeconds > newToSeconds)
      newToSeconds = newFromSeconds;

    setPeriods((prevState) => {
      const newState = prevState.map((period, indexPeriod) => {
        if (indexPeriod === index)
          return {
            ...period,
            fromSeconds: newFromSeconds,
            toSeconds: newToSeconds,
          };
        return period;
      });

      const overlappedPeriods = newState.filter((period) => {
        const { fromSeconds: currentFromSeconds, toSeconds: currentToSeconds } =
          period;

        // Check if all from-to's exists
        if (
          !currentFromSeconds ||
          !currentToSeconds ||
          !newFromSeconds ||
          !newToSeconds
        )
          return false;

        return (
          newFromSeconds < currentFromSeconds &&
          newToSeconds > currentToSeconds &&
          newToSeconds > newFromSeconds
        );
      });

      const newPeriodIsOverlapping = Boolean(overlappedPeriods.length);

      if (newPeriodIsOverlapping)
        setOverlappingPeriods((prevState) => [
          ...prevState,
          { fromSeconds: newFromSeconds, toSeconds: newToSeconds },
        ]);

      return newState;
    });
  };

  const todayIsPast = () => {
    return isDateSecondsPastDeadline(moment().unix());
  };

  const usedDays = () => {
    const range = periods.reduce((acc: number[], period) => {
      const { fromSeconds, toSeconds } = period;
      if (!fromSeconds || !toSeconds) return acc;

      const momentStartDate = moment.unix(fromSeconds);
      const momentEndDate = moment.unix(toSeconds);

      const currentRange: number[] = getDaysInRange(
        momentStartDate,
        momentEndDate,
        "unix"
      ) as number[];

      return [...acc, ...currentRange];
    }, []);

    return [...new Set(range)] as number[];
  };

  const disabledDate = (
    current: moment.Moment,
    startDate?: moment.Moment | null,
    endDate?: moment.Moment | null
  ) => {
    const currentRange = getDaysInRange(startDate, endDate, "unix") as number[];

    const targetRange = usedDays().filter(
      (seconds) => !currentRange.includes(seconds)
    );

    const isDayAlreadyPresent = targetRange.includes(
      getMidnightSeconds(current.unix())
    );

    const isKitchenClosed = activeHolidays.includes(
      getMidnightSeconds(current.unix()).toString()
    );

    const isDisabled =
      (current &&
        (todayIsPast()
          ? current < moment().endOf("day")
          : current < moment().startOf("day"))) ||
      isDateSecondsPastDeadline(current?.unix()) ||
      isDayAlreadyPresent ||
      isKitchenClosed;

    return isDisabled;
  };

  const getPeriodsData = () => {
    const overlappingPeriodsSet = new Set(
      overlappingPeriods.map((op) => `${op.fromSeconds}-${op.toSeconds}`)
    );

    const targetPeriods = periods.map((period) => {
      const { fromSeconds, toSeconds } = period;

      const [startDate, startTsp] = seconds2Timestamp(fromSeconds);
      const [endDate, endTsp] = seconds2Timestamp(toSeconds);

      const isStartDateInPastValue = isDateSecondsPastDeadline(
        startTsp?.seconds || 0
      );
      const isEndDateInPastValue = isDateSecondsPastDeadline(
        endTsp?.seconds || 0
      );
      const isStartDateInPast = startDate ? isStartDateInPastValue : false;
      const isEndDateInPast = endDate ? isEndDateInPastValue : false;

      const isOverlapping = overlappingPeriodsSet.has(
        `${fromSeconds}-${toSeconds}`
      );

      return {
        ...period,
        startDate,
        endDate,
        isInPast: isStartDateInPast || isEndDateInPast,
        isStartDateInPast,
        isEndDateInPast,
        isOverlapping,
      };
    });

    return targetPeriods;
  };
  const periodsData = getPeriodsData();

  const moduleName =
    module?.adminDisplayName || `${module?.displayName || module.name} - Admin`;

  return (
    <DrawerOrModal
      actions={[
        {
          label: t("Confirm"),
          htmlType: "submit",
          form: "holidays-form",
          loading: savingLoading,
        },
      ]}
      open={open}
      setOpen={setOpen}
      title={t("Holiday periods")}
      subtitle={t(
        `By creating a "Holiday Period," your company won't receive lunch orders during this time. Existing orders within this period will be permanently deleted.`
      )}
      stopPropagation={false}
    >
      <div className="mb-16 mt-12">
        {isAdmin && fromUserSettings && (
          <Select
            label={t("Choose module")}
            placeholder={t("Select module to add holidays to")}
            options={flexModules.map((m) => ({
              label: m?.displayName || m.name,
              value: m.id,
            }))}
            isLoading={holidaysLoading}
            value={holidayModuleId}
            className="mb-2"
            onChange={(newValue: string) => setHolidayModuleId(newValue)}
          />
        )}
        <div className="mb-5">
          <Form id="holidays-form" onSubmit={() => onConfirm(periods)}>
            {periodsData.map(
              (
                {
                  fromSeconds,
                  toSeconds,
                  startDate,
                  endDate,
                  isInPast,
                  isOverlapping,
                  deleted,
                  id,
                  isAllModules,
                },
                index
              ) => (
                <div
                  key={`${fromSeconds}-${toSeconds}-${index}-${id}`}
                  className={classnames(
                    deleted && "hidden",
                    "flex items-center justify-between gap-2.5"
                  )}
                >
                  <Form.Item
                    name={`period-${fromSeconds}`}
                    extra={
                      isOverlapping && (
                        <div className="ant-form-item-explain-warning mt-2 mb-3">
                          {t("*This period is overlapping other holidays")}
                        </div>
                      )
                    }
                  >
                    <div className="flex items-center gap-2.5">
                      <Calendar.Input
                        mode="range"
                        popoverClassName="z-max"
                        className="w-full -mt-3 md:-mt-1"
                        value={[startDate, endDate]}
                        onChange={(newRange) =>
                          choosePeriod(newRange as [Moment, Moment], index)
                        }
                        disabled={(date) =>
                          disabledDate(
                            dateToMomentUtc(date),
                            startDate,
                            endDate
                          )
                        }
                      />
                      {!fromUserSettings && isAdmin && (
                        <Select
                          value={isAllModules}
                          options={[
                            { label: t("All modules"), value: true },
                            {
                              label: t("Only {name}", {
                                name: moduleName,
                              }),
                              value: false,
                            },
                          ]}
                          onChange={(value: boolean) =>
                            changeModules(value, index)
                          }
                          className="w-[150px]"
                          dropdownWidth={!isInPast ? 200 : 150}
                        />
                      )}
                    </div>
                  </Form.Item>

                  {!isInPast && (
                    <Button
                      icon={faTrash}
                      type="danger"
                      onClick={() => deletePeriod(index)}
                      size="small"
                    />
                  )}
                </div>
              )
            )}
          </Form>
        </div>
        <Button className="w-full" onClick={addPeriod} icon={faPlus}>
          {t("New holiday period")}
        </Button>
      </div>
      <Alert
        message={t(
          "Creating a holiday period is irreversible. Deleted orders cannot be recovered"
        )}
        type="warning"
      />
    </DrawerOrModal>
  );
};
