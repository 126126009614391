import { getFlexStandard, useSubmit } from "@kanpla/system";
import { SubmitFlexIndividualStandardProps } from "@kanpla/types";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";

const atomReloader = atom(0);

/** Returns function which will trigger the reload. */
export const useReloadFlexStandardOrders = () => {
  const setReload = useSetAtom(atomReloader);
  return () => setReload((v) => v + 1);
};

export const useFlexStandardOrders = () => {
  const { moduleId, childId, schoolId, userId } = useContainer(OrderingContext);
  const _reloader = useAtomValue(atomReloader);

  const { standardOrderLines, holidays, setIndividualStandard } =
    getFlexStandard({
      moduleId,
      childId,
      schoolId,
      _reloader,
    });

  const { submit } = useSubmit<
    SubmitFlexIndividualStandardProps,
    Pick<SubmitFlexIndividualStandardProps, "standardOrderLines">
  >({
    setData: setIndividualStandard,
    path: "submit/flexIndividualStandard",
    requestConstructor: ({ standardOrderLines }) => ({
      standardOrderLines,
      schoolId,
      moduleId,
      userId,
      childId,
      fromAdmin: false,
    }),
    responseDestructor: ({ standardOrderLines }) => standardOrderLines,
  });

  return {
    standardOrderLines,
    submitStandardOrder: submit,
    holidays,
  };
};
