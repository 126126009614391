import { goToNextAvailableDay } from "@kanpla/system";
import { useAtomValue } from "jotai";
import { NumberParam, useQueryParam } from "next-query-params";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import { isOrderingFromBackendAtom, OrderingContext } from "../../context";
import { deadlineInfoAtom } from "./useDeadlineInfo";

export const useDeadlineJump = (): void => {
  const { week, setDate } = useContainer(OrderingContext);
  const { defaultDateSeconds } = useAtomValue(deadlineInfoAtom);
  const isOrderingFromBackend = useAtomValue(isOrderingFromBackendAtom);

  const router = useRouter();
  const [paramDateSeconds] = useQueryParam("date", NumberParam);

  // Jump forward to first available day
  useEffect(() => {
    if (isOrderingFromBackend) return;
    if (!router.isReady) return;
    if (!defaultDateSeconds) return;
    if (paramDateSeconds) return;

    goToNextAvailableDay({
      week,
      defaultDateSeconds,
      setDate,
    });
  }, [defaultDateSeconds, router?.isReady]);
};
