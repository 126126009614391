import { CombinedOffer, CombinedOfferItem } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { ReactNode, useEffect, useState } from "react";
import { createContainer, useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { translatedOfferAtom } from "./TranslateMenu";
import { showMenuTranslatedAtom } from "./useTranslations";

const ContextState = () => {
  const { module, setTimeNavigation, week, offer } = useContainer(AppContext);

  const showMenuTranslated = useAtomValue(showMenuTranslatedAtom);

  // Offers
  const allDateSeconds = week.map((d) => d.seconds);
  const { items, holidayDates, deadlineInfo } = offer || {};

  const [activeOffer, setActiveOffer] = useState<CombinedOffer>(items);
  const translatedOffer = useAtomValue(translatedOfferAtom);

  useEffect(() => {
    if (showMenuTranslated) {
      setActiveOffer(translatedOffer || items);
    } else {
      setActiveOffer(items);
    }
  }, [showMenuTranslated]);

  useEffect(() => {
    setTimeNavigation("calendarWeekly");
  }, []);

  const { defaultDate } = deadlineInfo || {};

  const hasHolidaysAllWeek = !allDateSeconds.some(
    (dateSeconds) => !holidayDates?.[dateSeconds]
  );
  const activeHoliday = hasHolidaysAllWeek
    ? holidayDates?.[allDateSeconds[0]]
    : null;

  const line =
    module?.productLines?.map((line) => [line.productId, line]) || [];

  const highlightedProductIds = line
    .filter(
      ([productId]) =>
        module?.productOptions?.[productId as string]?.highlighted
    )
    .map(([productId]) => productId);

  // Show highlighted products, or default to the first one
  const highlightedItems: Array<CombinedOfferItem> =
    highlightedProductIds.length
      ? (activeOffer as Array<CombinedOfferItem>).filter((p) =>
          highlightedProductIds.includes(p.productId)
        )
      : (activeOffer as Array<CombinedOfferItem>).filter((p, i) => i < 1);

  return { items: activeOffer, highlightedItems, defaultDate, activeHoliday };
};

export const MenuPreviewContext = createContainer(ContextState);

export const MenuPreviewContainer = ({ children }: { children: ReactNode }) => {
  return <MenuPreviewContext.Provider>{children}</MenuPreviewContext.Provider>;
};
