import { T, useT } from "@kanpla/system";
import { Button, Modal } from "@kanpla/ui";
import { useState } from "react";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const SubscriptionPopup = () => {
  const t = useT();
  const { school, modules, setModuleId } = useContainer(AppContext);
  const [open, setOpen] = useState(true);
  const [shown, setShown] = useLocalstorageState("skolemilk_june_2020");

  const dismiss = () => {
    setOpen(false);
    setShown(true);
  };

  if (!school || !school.subscriptions || shown) return null;

  return (
    <Modal open={open} setOpen={dismiss} title={t("Order your school milk")}>
      <p className="text-center mb-2">
        <T _str="Kanpla has made it easy" />
        <br />
        <T _str="to order milk at your school" />
      </p>
      <img src="/images/skolemælk.jpg" alt="" />{" "}
      <div className="flex justify-center">
        <Button
          type="primary"
          onClick={() => {
            setModuleId(modules.find((m) => m.type === "subscription")?.id);
            dismiss();
          }}
          shape="solid"
          dataCy="btn-subscription-create"
          className="w-full mt-2.5"
        >
          {t("Create your subscription")}
        </Button>
      </div>
    </Modal>
  );
};

export default SubscriptionPopup;
