import { OrderOptions } from "@kanpla/types";
import { sortBy } from "lodash";

export const getConfigNameAndId = (options: {
  [choiceId: string]: OrderOptions;
}) => {
  const sorted = sortBy(Object.entries(options || {}), (e) => e[0]).filter(
    ([_, option]) => option.amount
  );
  const name = sorted
    .map(
      ([_, option]) =>
        `${option.amount > 1 ? `${option.amount}x ` : ""}${option.name}`
    )
    .join(", ");

  const id = sorted
    .map(
      ([key, option]) =>
        `${option.amount > 1 ? `${option.amount}x` : ""}${key || option.id}`
    )
    .join(",");

  return { name, id };
};

// It returns only the id of the main option
export const getConfigNameAndIdWithoutAmount = (options: {
  [choiceId: string]: OrderOptions;
}) => {
  const sorted = sortBy(Object.entries(options || {}), (e) => e[0]).filter(
    ([_, option]) => option.amount
  );
  const name = sorted
    .map(
      ([_, option]) =>
        `${
          option.amount > 1 && Object.keys(options).length > 1
            ? `${option.amount}x `
            : ""
        }${option.name}`
    )
    .join(", ");

  const id = sorted.map(([key, option]) => `${key || option.id}`)?.[0];

  return { name, id };
};
