import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@kanpla/ui";

interface Props {
  /** Label to display */
  label?: string;
  /** Back method */
  onClick: () => void;
}

const BackButton = ({ label = "Forside", onClick }: Props) => {
  return (
    <Button
      onClick={onClick}
      dataCy="btn-back-button"
      icon={faChevronLeft}
      shape="plain"
    >
      {label}
    </Button>
  );
};

export default BackButton;
