import {
  faFileExclamation,
  faFileSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T, getDateRangeFormat, useT } from "@kanpla/system";
import { Spinner, Switch } from "@kanpla/ui";
import classNames from "classnames";
import { atom, useAtom } from "jotai";
import { groupBy, isArray } from "lodash";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { ExportContext } from ".";
import WrapContent from "./WrapContent";
import OrderCard from "./order/OrderCard";

export const showIndividualUsersAtom = atom<boolean>(false);

const Content = () => {
  const {
    loading,
    items,
    viewEmployeeOrders,
    meetingView,
    reloadHistory,
    mobile,
    timeRange,
    error,
  } = useContainer(ExportContext);

  const [showIndividualUsers, setShowIndividualUsers] = useAtom(
    showIndividualUsersAtom
  );
  const [showText, setShowText] = useState<boolean>(false);

  const adminView = viewEmployeeOrders || meetingView;

  const groupedItems =
    !viewEmployeeOrders || meetingView || showIndividualUsers
      ? items
      : groupBy(items, "childName");

  const t = useT();

  useEffect(() => {
    if (!loading) return;

    const timer = setTimeout(() => {
      setShowText(true);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [loading]);

  if (error?.status === 413)
    return (
      <WrapContent>
        <FontAwesomeIcon icon={faFileExclamation} size="6x" />
        <div className="mt-3">
          {t(
            "This result is too big. Please select smaller time range or less modules."
          )}
        </div>
      </WrapContent>
    );

  if (loading)
    return (
      <WrapContent>
        <Spinner useCurrentColor />
        {showText ? (
          <h1 className="text-md font-semibold mt-4">
            <T _str="Hold on! We're fetching a lot of data, it might take up to 1 minute..." />
          </h1>
        ) : null}
      </WrapContent>
    );

  if (!items.length)
    return (
      <WrapContent>
        <FontAwesomeIcon icon={faFileSlash} size="6x" />
        <div className="mt-3">{t("No orders match your selection")}</div>
      </WrapContent>
    );

  return (
    <>
      {!mobile && adminView && (
        <div className="mb-6 flex gap-x-6">
          <div className="flex flex-col">
            <h2 className="text-sm font-medium text-text-secondary">
              <T _str="Date" />
            </h2>
            <span className="font-semibold">
              {getDateRangeFormat({
                startDate: timeRange?.fromSeconds || 0,
                endDate: timeRange?.toSeconds || 0,
              })}
            </span>
          </div>
          {!meetingView && (
            <div className="flex flex-col">
              <h2 className="text-sm font-medium text-text-secondary">
                <T _str="Show individual users" />
              </h2>
              <Switch
                checked={showIndividualUsers}
                onCheckedChange={(checked) => setShowIndividualUsers(checked)}
              />
            </div>
          )}
        </div>
      )}
      {adminView && (
        <div
          className={classNames(
            "md:grid gap-3 uppercase text-text-secondary px-4 text-sm hidden",
            showIndividualUsers || meetingView ? "grid-cols-6" : "grid-cols-4"
          )}
        >
          {(showIndividualUsers || meetingView) && <div>{t("Date")}</div>}
          <div className="col-span-2">{t("Name")}</div>
          <div>{t("Products")}</div>
          <div>{t("Price")}</div>
        </div>
      )}
      <div className="w-full flex flex-col items-center">
        {isArray(groupedItems)
          ? groupedItems.map((item) => (
              <OrderCard
                order={item}
                key={item.id}
                meetingView={meetingView}
                reloadOrders={reloadHistory}
                viewEmployeeOrders={viewEmployeeOrders}
              />
            ))
          : Object.entries(groupedItems).map(([childName, orders]) => (
              <OrderCard
                order={orders[0]}
                key={orders[0].id}
                meetingView={meetingView}
                reloadOrders={reloadHistory}
                viewEmployeeOrders={viewEmployeeOrders}
                grouped
                groupedChildName={childName}
                orders={orders}
              />
            ))}
      </div>
    </>
  );
};

export default Content;
