import { calculateVatRate } from "@kanpla/system";
import { useContainer } from "unstated-next";
import { OrderingContext } from "..";

/**
 * Get the VAT rate for the current module and school
 * @returns The VAT rate for the current module and school
 */
export const useVatRate = () => {
  const { module, school } = useContainer(OrderingContext);
  return calculateVatRate({ module, school, productLine: null });
};
