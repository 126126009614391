import { atom, useAtom, useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { useContainer } from "unstated-next";

import { calculateProductPrice, getAllowancesForProduct } from "@kanpla/system";
import type {
  AllowanceUsage,
  CombinedOfferItem,
  CustomOrderContent,
} from "@kanpla/types";

import { OrderingContext } from "../../context";
import { allowanceUsageAtom } from "./useAllowance";

export const allowanceProductLocalUsageAtom = atom<AllowanceUsage[]>([]);

interface AllowanceProductArgs {
  product: CombinedOfferItem;
  open: boolean;
}

// Allowance localized to the current product (resets once the product is closed)
export const useAllowanceProduct = ({
  open,
  product,
}: AllowanceProductArgs) => {
  const { module } = useContainer(OrderingContext);
  const [allowanceProductLocalUsage, setAllowanceProductLocalUsage] = useAtom(
    allowanceProductLocalUsageAtom
  );

  const allowanceUsages = useAtomValue(allowanceUsageAtom);

  // Initialize local allowance usage
  useEffect(() => {
    setAllowanceProductLocalUsage(() =>
      open
        ? getAllowancesForProduct({ allowanceUsages, productId: product.id })
        : []
    );
  }, [open]);

  const update = (
    newAmount: number,
    options: CustomOrderContent["optionChoices"]
  ) => {
    if (isEmpty(allowanceUsages)) return;

    const individualPrice = calculateProductPrice({ product, options, module });
    const totalPrice = individualPrice * newAmount;

    const productAllowances = getAllowancesForProduct({
      productId: product.id,
      allowanceUsages,
    });

    if (isEmpty(productAllowances)) return;

    setAllowanceProductLocalUsage(() => {
      return productAllowances.map((allowance) => {
        const { numberOfProducts, unitPrice } = allowance;

        if (numberOfProducts) {
          const diff = numberOfProducts.amount - newAmount;
          return {
            ...allowance,
            isAllowanceOver: diff < 0,
            numberOfProducts: {
              ...numberOfProducts,
              amount: diff < 0 ? 0 : diff,
            },
          };
        }

        if (unitPrice) {
          const diff = unitPrice.amount - totalPrice;

          return {
            ...allowance,
            isAllowanceOver: diff < 0,
            unitPrice: {
              ...unitPrice,
              amount: diff < 0 ? 0 : diff,
            },
          };
        }

        return allowance;
      });
    });
  };

  const isAllowanceOverForProduct = useMemo(
    () =>
      allowanceProductLocalUsage.some(
        (allowance) => !allowance?.discountId && allowance.isAllowanceOver
      ),
    [allowanceProductLocalUsage]
  );

  return { update, isAllowanceOverForProduct };
};
