import { useT } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { Divider, SelectorsInput } from "@kanpla/ui";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import SelectSchool from "../../forms/SelectSchool";
import { SignupData } from "../NewSalesplace";
import { useGetCompanySchools } from "../newSalesplace/useGetCompanySchools";

interface Props {
  signupData: SignupData | null;
  setSignupData: (signupData: SignupData) => void;
}

export const ChooseSchool = ({ signupData, setSignupData }: Props) => {
  const { supplier } = useContainer(AppContext);

  const t = useT();

  const { schools, domains } = useGetCompanySchools();
  const [selectors, setSelectors] = useState<Child["selectors"]>({});

  const schoolDomains = domains?.filter(
    (d) => d.schoolId === signupData?.school?.id
  );

  const additionalSelectors = signupData?.school?.selectors?.map((s) => ({
    ...s,
    options: s.options.map((o) => {
      const selectorVisible = schoolDomains?.some(
        (d) => d.selectors?.[s.name] === o.name
      );

      return {
        ...o,
        hidden: selectorVisible ? false : o.hidden,
      };
    }),
  }));

  if (isEmpty(schools)) return null;

  return (
    <>
      <label htmlFor="school-input">
        <label className="form-label text-text-secondary mb-1">
          {t("Add salesplace available from your email domain")}
        </label>
        <div className="text-left mt-2 mb-6">
          <SelectSchool
            school={signupData?.school || null}
            schools={schools}
            allowedSchools={schools.map((s) => s.id)}
            setSchool={(newSchool) =>
              setSignupData({ school: newSchool, schoolId: newSchool?.id })
            }
            company={!isEmpty(supplier?.logo)}
          />
        </div>
      </label>

      <SelectorsInput
        selectors={selectors}
        setSelectors={(newSelectors) => {
          setSignupData({ ...(signupData || {}), selectors: newSelectors });
          setSelectors(newSelectors);
        }}
        school={signupData?.school}
        useExtraSelectors
        extraSelectors={additionalSelectors}
        isControlled={false}
      />

      <div className="relative my-2 pb-3">
        <Divider />
        <div className="absolute -top-3 left-1/2 -ml-6 text-sm p-1 px-4 bg-background-primary text-text-secondary">
          {t("or")}
        </div>
      </div>
    </>
  );
};
