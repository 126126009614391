import { faChevronRight } from "@fortawesome/pro-duotone-svg-icons";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import { Breadcrumb } from "antd";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";
import { isStandardOrderingAtom } from "../../Navigation";
import { ProductMenuModal } from "./ProductMenuModal";

interface Props {
  isSingleCategory?: boolean;
  item: CombinedOfferItem;
}

export const ProductMenuButton = ({ item }: Props) => {
  const [visible, setVisible] = useState(false);
  const { module } = useContainer(OrderingContext);
  const isStandardOrdering = useAtomValue(isStandardOrderingAtom);
  const t = useT();

  const clickHandler = () => setVisible(true);

  if (isStandardOrdering || module?.shouldHideMenu) return null;

  return (
    <>
      <div className="-mx-3">
        <Button
          size="small"
          type="secondary"
          shape="plain"
          onClick={clickHandler}
          icon={faCircleInfo}
        >
          {t("Menu this week")}
        </Button>
      </div>
      {visible && (
        <ProductMenuModal
          item={item}
          visible={visible}
          setVisible={setVisible}
          title={
            <Breadcrumb
              separator={<FontAwesomeIcon icon={faChevronRight} />}
              className="text-text-primary"
            >
              <Breadcrumb.Item className="font-bold">
                {t("Weekly menu")}
              </Breadcrumb.Item>
              <Breadcrumb.Item className="font-light">
                {item?.name?.toUpperCase()}
              </Breadcrumb.Item>
            </Breadcrumb>
          }
        />
      )}
    </>
  );
};
