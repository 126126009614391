import { useT } from "@kanpla/system";
import { OrderInfo } from "@kanpla/types";
import moment from "moment";
import React from "react";

interface Props {
  timeInput: OrderInfo["timeInput"];
  justTime?: boolean;
}

export const TimeInputDisplay = (props: Props) => {
  const { timeInput, justTime = false } = props;
  const t = useT();
  // Util to change the localization of moment.js

  const formattedString = moment.utc(timeInput * 1000).format("LT");

  if (justTime) {
    if (!timeInput) return <p></p>;
    return <p>{formattedString}</p>;
  }

  if (!timeInput) return <div>{t("No time")}</div>;

  return (
    <div>
      <span className="font-semibold">{t("Time:")}</span> {formattedString}
    </div>
  );
};
