import { OrderingContext, ProductOptions } from "@kanpla/ordering";
import {
  adjustRequiredProduct,
  getActivePlugins,
  getOrderLine,
  stringifyOptions,
  stringifyOrder,
} from "@kanpla/system";
import {
  FlexOption,
  OrderLine,
  OrderLines,
  OrderOptions,
  _FrontendModule,
} from "@kanpla/types";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";

interface Props {
  basket: OrderLines;
  setBasket: (newValue: OrderLines) => void;
  module: _FrontendModule;
}

const RequiredProductInner = ({ setBasket, module, basket }: Props) => {
  const { offers, offer: offerFromContext } = useContainer(OrderingContext);
  const [optionChoices, setOptionChoices] = useState<OrderLine["options"]>({});
  const productId = module?.plugins?.requiredProduct?.productId;
  const offer = offerFromContext || offers?.[module?.id];
  const offerItems = offer?.items;
  const mealOptions = offer?.mealOptions || [];
  const product = offerItems?.find((i) => i.productId === productId);

  const targetLine = getOrderLine({
    orderLines: basket,
    targetLine: { productId },
  });
  const defaultOptionChoices = targetLine?.options || {};

  const initialOptions: {
    [choiceId: string]: OrderOptions;
  } = product?.options?.reduce((acc, optionId) => {
    const singleOption = mealOptions.find((o) => o.id === optionId);

    if (!singleOption?.minimum && singleOption?.choices?.length) return acc;

    const choice = singleOption?.choices?.[0];
    const choiceId = String(choice?.id);
    if (!choiceId) return acc;

    acc[choiceId] = {
      name: choice?.name,
      id: choiceId,
      amount: 1,
      unitExtraPrice: choice?.unitPrice,
    };

    return acc;
  }, {});

  const optionsEmpty = isEmpty(optionChoices);

  useEffect(() => {
    if (!optionsEmpty) return;

    const choices = isEmpty(defaultOptionChoices)
      ? initialOptions
      : defaultOptionChoices;

    setOptionChoices(choices);
  }, [optionsEmpty]);

  const orderWithoutRequiredProduct = basket.filter(
    (o) => o.productId !== productId
  );
  // submit data to the order
  const submitProduct = () => {
    const newOrder = adjustRequiredProduct({
      orderLines: basket,
      module,
      offerItems,
      optionChoices,
    });
    setBasket(newOrder);
  };

  const requiredProductOptionsTrigger = stringifyOptions(optionChoices);
  const orderTrigger = stringifyOrder(orderWithoutRequiredProduct);
  useEffect(() => {
    submitProduct();
  }, [requiredProductOptionsTrigger, orderTrigger]);
  useEffect(() => {
    submitProduct();
  }, []);

  if (!product) return null;

  const options = mealOptions?.filter((option: FlexOption) =>
    product.options?.includes(option.id)
  );

  return (
    <div>
      <div className="m-2">
        <ProductOptions
          product={{
            color: product.color,
            vatRate: product.vatRate,
          }}
          options={options}
          value={optionChoices}
          onChange={(newValue) => setOptionChoices(newValue)}
        />
      </div>
    </div>
  );
};

export const RequiredProduct = (props: Props) => {
  const { activePlugins } = getActivePlugins({ module: props.module });

  if (!activePlugins?.requiredProduct) return null;

  return <RequiredProductInner {...props} />;
};
