import { Homescreen } from "@kanpla/types";
import { useTargetBlock } from "../utils";
import Banner from "./Banner";
import Button from "./Button";
import CrowdTrackerBlock from "./CrowdTracker/CrowdTracker";
import Editor from "./Editor";
import FlexMenu from "./FlexMenu";
import FoodWasteInsights from "./FoodWasteInsights";
import Image from "./Image";
import Products from "./Products";
import SingleProductBlock from "./Products/SingleProductBlock";
import Section, { SectionInner } from "./Section";
import StampCards from "./StampCards";
import Text from "./Text";

interface Props {
  singleBlock: Homescreen.ContentInner;
  blockId: string;
}

const WrapperBlock = ({ singleBlock, blockId }: Props) => {
  const { block } = useTargetBlock({ block: singleBlock });

  const blockProps = { singleBlock: block, key: blockId };

  switch (block?.type?.["resolvedName"] || block?.type) {
    case "EditorBlock":
      return <Editor {...blockProps} />;
    case "SectionComponent":
      return <Section {...blockProps} />;
    case "SectionInner":
      return <SectionInner {...blockProps} />;
    case "ButtonComponent":
      return <Button {...blockProps} />;
    case "FlexMenuComponent":
      return <FlexMenu {...blockProps} />;
    case "ImageComponent":
      return <Image {...blockProps} />;
    case "ProductsComponent":
      return <Products {...blockProps} />;
    case "BannerComponent":
      return <Banner {...blockProps} index={blockId} />;
    case "TextComponent":
      return <Text {...blockProps} />;
    case "ProductComponent":
      return <SingleProductBlock {...blockProps} />;
    case "FoodWasteInsights":
      return <FoodWasteInsights {...blockProps} />;
    case "CrowdTrackerComponent":
      return <CrowdTrackerBlock moduleId={singleBlock.props.moduleId} />;
    case "StampCardsComponent":
      return <StampCards {...blockProps} />;
    default: {
      return null;
    }
  }
};

export default WrapperBlock;
