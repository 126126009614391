import { groupOfferItems, useT } from "@kanpla/system";
import { _FrontendModule } from "@kanpla/types";
import {
  AllergensWarningModal,
  ModuleLoadingWrapper,
  message,
} from "@kanpla/ui";
import { atom, useAtomValue } from "jotai";
import { values } from "lodash";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import { useDeadlineJump } from "../lib/hooks/useDeadlineJump";
import CanteenClosed from "../shared/CanteenClosed";
import ModuleDescription from "../shared/info/ModuleDescription";
import NavbarSecondary from "../shared/navbar/NavbarSecondary";
import FlexLoader from "./FlexLoader";
import Menu from "./flexView/Menu";
import ProductsListModal from "./shared/ProductsListModal";
import StandardHolidays from "./standardHolidays/StandardHolidays";
import StandardOrderModal, {
  openStandardsModalAtom,
} from "./standardOrder/StandardOrderModal";

export const savingOrderAtom = atom<boolean>(false);

export const Flex = () => {
  const {
    innerAppLoading,
    module,
    holidayDates,
    activeHoliday,
    offer,
    week,
    setTimeNavigation,
  } = useContainer(OrderingContext);

  const t = useT();
  const [open, setOpen] = useState(false);

  const standardModalOpen = useAtomValue(openStandardsModalAtom);

  const groupedOfferItems = groupOfferItems({
    items: offer.items,
    week,
    module: module as _FrontendModule,
  });

  const saving = useAtomValue(savingOrderAtom);

  useDeadlineJump();

  useEffect(() => {
    setTimeNavigation("todaySwitch");
  }, []);

  useEffect(() => {
    if (!saving) {
      return message.dismiss();
    }

    message.loading(t("Your order is being saved"));
  }, [saving]);

  // Removed holiday check for _proPluginModuleSwitch, don't really know what to do here since it wasn't coming from the type @francesco
  // activeHoliday?.some((h) => (h as Holiday)?._proPluginModuleSwitch);
  const hasActiveModuleSwitchHoliday = values(holidayDates)?.every((h) => h);

  return (
    <>
      <NavbarSecondary timeNavigation="weekly" />
      <div className="py-3 md:pb-12 wrapper select-none overflow-hidden">
        <ModuleDescription align="left" module={module} />
        <ModuleLoadingWrapper loading={innerAppLoading}>
          {hasActiveModuleSwitchHoliday ? (
            CanteenClosed({
              disableJumpLink: true,
              holidayDesc: activeHoliday?.design,
            })
          ) : (
            <>
              {innerAppLoading ? (
                <FlexLoader />
              ) : (
                <Menu offerItems={groupedOfferItems} setOpen={setOpen} />
              )}
              <StandardOrderModal />
              <StandardHolidays open={open} setOpen={setOpen} />
              <ProductsListModal
                offerItems={groupedOfferItems}
                isStandard={standardModalOpen}
              />
              <AllergensWarningModal />
            </>
          )}
        </ModuleLoadingWrapper>
      </div>
    </>
  );
};

export default Flex;
