import {
  T,
  getDayIndexFromSeconds,
  getProductName,
  getTodaySeconds,
  useT,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  Holiday,
  HolidaysRecurring,
  OrderLine,
  OrderLines,
  StandardOrderLine,
} from "@kanpla/types";
import { Button } from "@kanpla/ui";
import classNames from "classnames";
import { useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useFlexStandardOrders } from "../../lib/flex/useFlexStandardOrders";
import { productsListDateSecondsAtom } from "./ProductsListModal";

interface MobileProductPreviewProps {
  orderLines: OrderLines | null;
  offer: Record<string, CombinedOfferItem[]>;
  dayString: string;
  dateSeconds: number;
  activeHoliday?: Holiday | HolidaysRecurring | null;
  isStandardHoliday?: boolean;
  isPastDeadline?: boolean;
}

const MobileProductPreview = (props: MobileProductPreviewProps) => {
  const {
    dateSeconds,
    dayString,
    orderLines,
    activeHoliday,
    isPastDeadline,
    isStandardHoliday,
  } = props;

  const { items } = useContainer(OrderingContext);
  const { standardOrderLines } = useFlexStandardOrders();
  const t = useT();

  const dayIndex = getDayIndexFromSeconds(dateSeconds);
  const todaySeconds = getTodaySeconds();
  const isToday = todaySeconds === dateSeconds;
  const disabled = Boolean(
    isPastDeadline || isStandardHoliday || activeHoliday
  );
  const isPastToday = dateSeconds < todaySeconds;

  // orderLines is `null` if the user (or server, for standards) has not ordered yet, empty `[]` if has chosen no lunch
  const orderLinesIsNotCreated = orderLines === null;

  // Find the orderLine for current day
  const orderLine: OrderLine | StandardOrderLine =
    orderLines?.[0] ||
    (orderLinesIsNotCreated
      ? standardOrderLines[dayIndex]?.[0]
      : ({} as StandardOrderLine)) ||
    ({} as OrderLine);

  const product = items.find(
    (i) =>
      i.productLineId === orderLine.productLineId ||
      i.productId === orderLine.productId
  );

  const productInfos = getProductName({
    product,
    date: dateSeconds.toString(),
    noLunch: t("No lunch"),
  });

  const showProductInfo = Boolean(
    productInfos?.menu?.name || productInfos?.menu?.description
  );

  const setProductListDateSeconds = useSetAtom(productsListDateSecondsAtom);

  const getContent = () => {
    const holiday = activeHoliday?.design;

    if (isStandardHoliday) {
      return (
        <p className="text-lg">
          <T _str="Holiday" />
        </p>
      );
    }

    if (activeHoliday) {
      return (
        <>
          <p className="text-lg">
            {holiday?.title ? t(holiday?.title) : t("Holiday")}
          </p>
          <p className=" text-text-secondary text-sm">
            {holiday?.text
              ? t(holiday?.text)
              : t("The canteen is closed this day")}
          </p>
        </>
      );
    }

    return (
      <>
        <p>{productInfos?.name || t("No Lunch")}</p>
        {showProductInfo && (
          <p className="text-sm text-text-secondary">
            {productInfos?.menu?.name || productInfos?.menu?.description}
          </p>
        )}
      </>
    );
  };

  return (
    <div
      className={classNames(
        "flex flex-col p-4 pl-5 border-b",
        isToday && "bg-background-secondary",
        isPastToday && "opacity-30",
        disabled && "pointer-events-none"
      )}
      onClick={() => {
        if (disabled) return;
        setProductListDateSeconds(dateSeconds);
      }}
    >
      <div className="flex gap-x-4 justify-between rounded-lg transition-all">
        <div>
          <p className="text-text-primary capitalize">{dayString}</p>
          {getContent()}
        </div>
        <div
          className={classNames(
            "flex min-h-full",
            isToday ? "items-start" : "items-center"
          )}
        >
          {isToday && (
            <div
              className={classNames(
                "bg-primary-backdrop text-primary-dark",
                "h-6 px-2 text-xs rounded leading-4",
                "flex items-center justify-center"
              )}
            >
              <T _str="Today" />
            </div>
          )}
          {/* The Order button should be present if the day is not holiday and the day date is in future */}
          {!disabled && !isToday && (
            <Button size="medium" shape="soft">
              <T _str={product ? "Change" : "Order"} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileProductPreview;
