import {
  db,
  fetchCollection,
  fetchDocument,
  fetchMultipleDocuments,
} from "@kanpla/system";
import { Homescreen, Product, ProductBank } from "@kanpla/types";
import { CustomSlider } from "@kanpla/ui";
import { cloneDeep, shuffle, uniq } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { HomescreenContext } from "../..";
import SectionHeader from "../../elements/SectionHeader";
import Button from "../Button";
import SingleProduct from "./SingleProduct";

interface WrapperProps {
  children: React.ReactChild | Array<React.ReactChild>;
  title?: string;
  subtitle?: string;
  buttonProps?: Homescreen.ContentInner;
}

const ProductsSectionWrapper = ({
  children,
  title,
  subtitle,
  buttonProps,
}: WrapperProps) => {
  return (
    <section className="w-full">
      <SectionHeader
        buttonProps={buttonProps}
        title={title}
        subtitle={subtitle}
      />
      <main className="rounded-lg -mx-2 md:-mx-4 lg:-mx-8 p-2 px-2 md:px-4 lg:px-8 relative overflow-hidden">
        {children}
      </main>
    </section>
  );
};

interface Props {
  singleBlock: Homescreen.ContentInner;
}

const Products = ({ singleBlock }: Props) => {
  const { module, content } = useContext(HomescreenContext);

  const [allProducts, setAllProducts] = useState<Array<Product>>([]);

  const updateProducts = async () => {
    const prods = await getProducts();
    setAllProducts(prods);
  };

  useEffect(() => {
    if (!singleBlock || !singleBlock.props) return;
    updateProducts();
  }, [module?.id]);

  if (!singleBlock || !singleBlock.props) return null;

  const {
    category,
    direction,
    productIds = [],
    rows,
    showAmount,
    showType,
    title,
    subtitle,
    moduleId,
    onClick = "nothing",
    targetDay,
    hideAfter,
  } = singleBlock.props;

  const buttonArea = content?.[singleBlock?.linkedNodes?.buttonArea || ""];

  const clickInfo = {
    moduleId,
    onClick,
    targetDay,
  };

  const getProducts = async () => {
    switch (showType) {
      case "first": {
        const products = await fetchCollection<Product>(
          db.collection("products").limit(showAmount as number)
        );
        return products.filter((p) => !p.deleted);
      }
      case "random": {
        const productBank = await fetchMultipleDocuments<ProductBank>(
          "productBanks",
          module?.sourceProductBankIds || [],
          { db }
        );

        const productIds =
          uniq(module?.productLines?.map((pl) => pl.productId)) || [];

        if (productIds.length > 0) {
          const correctProductIdArray = shuffle(cloneDeep(productIds)).slice(
            0,
            showAmount
          );
          const productPromise = correctProductIdArray.map((productId) =>
            fetchDocument<Product>(db.collection("products").doc(productId))
          );
          const products = await Promise.all(productPromise);
          const filteredProducts = getExistingProducts(products);
          return filteredProducts;
        }

        const allProducts = await fetchCollection<Product>(
          db
            .collection("products")
            .where("productBankId", "==", productBank[0].id)
        );

        const products = shuffle(allProducts).slice(0, showAmount);

        return products.filter((p) => !p.deleted);
      }
      case "choose": {
        const productPromise = productIds.map((productId: string) =>
          fetchDocument<Product>(db.collection("products").doc(productId))
        );
        const products = await Promise.all(productPromise);
        const filteredProducts = getExistingProducts(products);
        return filteredProducts;
      }
      case "category": {
        const products = await fetchCollection<Product>(
          db.collection("products").where("category", "==", category)
        );

        return products.filter((p) => !p.deleted).slice(0, showAmount);
      }
      default: {
        const products = await fetchCollection<Product>(
          db.collection("products").limit(showAmount as number)
        );
        return products.filter((p) => !p.deleted);
      }
    }
  };

  const getExistingProducts = (products: (Product | null)[]) =>
    products.filter((p) => !p?.deleted).filter((p): p is Product => Boolean(p));

  if (moment().isSameOrAfter(moment.unix(hideAfter as number), "day"))
    return null;

  if (direction === "scrollable") {
    const buttonProps = content?.[buttonArea?.nodes?.[0] || ""];
    return (
      <ProductsSectionWrapper>
        <CustomSlider
          title={title}
          subtitle={subtitle}
          linkButton={buttonProps && <Button singleBlock={buttonProps} />}
          items={allProducts.filter((p) => p)}
          rows={rows}
          renderer={(data) => <SingleProduct module={module} product={data} />}
        />
      </ProductsSectionWrapper>
    );
  }

  return (
    <ProductsSectionWrapper
      title={title}
      subtitle={subtitle}
      buttonProps={content?.[buttonArea?.nodes?.[0] || ""]}
    >
      <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-start">
        {allProducts
          .filter((p) => p)
          .map((p) => (
            <SingleProduct
              clickInfo={clickInfo}
              key={p.id}
              product={p}
              module={module}
            />
          ))}
      </ul>
    </ProductsSectionWrapper>
  );
};

export default Products;
