import { useT } from "@kanpla/system";
import { Button, DrawerOrModal, Image } from "@kanpla/ui";
import classNames from "classnames";
import { useState } from "react";
import { useBoundingclientrectRef } from "rooks";
import { getProportionalFontSize, getProportionalHeightPadding } from "./utils";

interface Props {
  title: string;
  subTitle: string;
  imageUrl: string;
  defaultExpanded?: boolean;
}

const BannerFive = (props: Props) => {
  const { title, imageUrl, subTitle, defaultExpanded } = props;
  const [bannerRef, boundingClientRect] = useBoundingclientrectRef();
  const [descriptionRef, descriptionRect] = useBoundingclientrectRef();
  const [openModal, setOpenModal] = useState(false);
  const t = useT();

  if (!title && !imageUrl) return null;

  const width = boundingClientRect?.width || 0;
  const descHeight = descriptionRect?.height || 0;
  const expandDescription = !defaultExpanded && descHeight > 90;

  return (
    <div
      ref={bannerRef}
      className={`w-full h-full rounded-lg flex flex-col overflow-hidden relative shadow-lg bg-background-secondary`}
      style={{ minHeight: 192 }}
    >
      <div
        className={`overflow-hidden relative ${getProportionalHeightPadding(
          width * 1.2,
          "height",
          true
        )}`}
      >
        <Image
          src={imageUrl}
          alt={title}
          className="relative h-full w-full object-center object-cover"
          size={{ w: width }}
        />
      </div>
      <div className="flex flex-col p-4">
        {title && (
          <h3
            className={`title-secondary text-left leading-tight ${getProportionalFontSize(
              width,
              "text-lg"
            )}`}
          >
            {title}
          </h3>
        )}
        {subTitle && (
          <p
            className={classNames(
              "text-text-secondary text-left text-sm overflow-hidden leading-tight mt-1",
              getProportionalFontSize(width),
              !defaultExpanded && "max-h-24"
            )}
          >
            <span
              className="block whitespace-pre-wrap"
              ref={descriptionRef}
              dangerouslySetInnerHTML={{ __html: subTitle }}
            />
          </p>
        )}
        {expandDescription && (
          <>
            <Button
              shape="plain"
              dataCy="btn-homescreen-banner-five-open"
              className="px-0 text-left inline-block"
              onClick={() => setOpenModal(true)}
            >
              <span className="hover:opacity-60 transition-opacity text-primary-dark">
                {t("Read more")}
              </span>
            </Button>
            <DrawerOrModal open={openModal} setOpen={setOpenModal} noPadding>
              <BannerFive {...props} defaultExpanded />
            </DrawerOrModal>
          </>
        )}
      </div>
    </div>
  );
};

export default BannerFive;
