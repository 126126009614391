import { PlusOutlined } from "@ant-design/icons";
import { antdFilterOptionSearch, useT } from "@kanpla/system";
import { Button, Divider, Input, Select } from "antd";
import React, { useEffect, useState } from "react";

interface Props {
  defaultOptions?: Array<string>;
  value: string;
  onChange: (newValue: string) => void;
  style?: React.CSSProperties;
  placeholder?: string;
}

/** @deprecated, use `<SelectCreatable/>` instead */
export const SelectCreatableLegacy = (props: Props) => {
  const { defaultOptions = [], placeholder = "" } = props;
  const [options, setOptions] = useState([]);
  const [newOption, setNewOption] = useState("");
  const t = useT();

  const validateOptions = (options: string[]) => {
    const valid = options.filter((o) => typeof o === "string");
    const unique = [...new Set(valid)];
    const sorted = unique.sort((a, b) => a.localeCompare(b));
    return sorted;
  };

  useEffect(() => {
    setOptions((prev) => validateOptions([...prev, ...defaultOptions]));
  }, [defaultOptions.join()]);

  const addNewOption = () => {
    if (newOption && !options.includes(newOption)) {
      setOptions((prev) => validateOptions([...prev, newOption]));
      props.onChange(newOption);
      setNewOption("");
      // Hack to overwrite onChange from input when hitting "enter"
      setTimeout(() => props.onChange(newOption), 100);
    }
  };

  return (
    <Select
      {...props}
      onChange={(newValue) => {
        props.onChange(newValue);
      }}
      placeholder={placeholder}
      showSearch
      filterOption={antdFilterOptionSearch}
      style={{ width: 200, ...(props.style || {}) }}
      onInputKeyDown={() => {}}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider className="mt-0 mx-0 mb-1" />
          <div className="p-1 flex">
            <Input
              placeholder={t("Write a new category here")}
              style={{ flex: "auto" }}
              className="placeholder:italic placeholder:text-text-disabled"
              value={newOption}
              onChange={(e) => setNewOption(e.target.value)}
              bordered={false}
              onKeyDown={(e) => {
                //it triggers by pressing the enter key
                if (e.keyCode === 13 || e.key === "Enter") {
                  return addNewOption();
                }
              }}
            />
            {newOption.length > 0 && (
              <Button onClick={addNewOption} type="link">
                <PlusOutlined style={{ color: "rgb(22 163 74)" }} />
              </Button>
            )}
          </div>
        </div>
      )}
    >
      {options
        .filter((o) => o !== "")
        .map((category, index) => (
          <Select.Option key={category + index.toString()} value={category}>
            {category}
          </Select.Option>
        ))}
    </Select>
  );
};
