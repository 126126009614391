import { callInternalApi } from "@kanpla/system";
import { useQuery } from "@tanstack/react-query";
import { TranslatorUsageReturns } from "apps/frontend/pages/api/internal/translate/usage";
import { useAtomValue } from "jotai";
import { NextApiRequest } from "next";
import { translationsAvailableAtom } from "../contextProvider";

export const useGetTranslatorUsage = () => {
  const translationsAvailable = useAtomValue(translationsAvailableAtom);

  return useQuery({
    queryKey: ["translatorUsage"],
    queryFn: () =>
      callInternalApi<Partial<NextApiRequest>, TranslatorUsageReturns>(
        "translate/usage",
        {}
      ),
    enabled: translationsAvailable,
    initialData: { isAvailable: false },
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    retry: 1,
    refetchInterval: 1000 * 60 * 60, // 1 hour
  });
};
