import { Product as ProductTemplate } from "@kanpla/ordering";
import { getUnitPrice, useT } from "@kanpla/system";
import {
  CombinedOfferItem,
  CustomOrderContent,
  OrderInfo,
  OrderLines,
  Plugins,
  Product,
  _FrontendModule,
} from "@kanpla/types";
import { AppContext } from "apps/frontend/components/contextProvider";
import { StringParam, useQueryParam } from "next-query-params";
import { useContainer } from "unstated-next";

interface WrapperProps extends Pick<Props, "clickInfo" | "product"> {
  children: JSX.Element;
}

const ProductWrapper = ({ children, clickInfo, product }: WrapperProps) => {
  const { setModuleId, setDate } = useContainer(AppContext);
  const [_, setOpenProductId] = useQueryParam("productId", StringParam);

  if (clickInfo?.onClick === "nothing") return children;

  const hasModuleId = clickInfo?.moduleId?.includes("/m/");

  if (!hasModuleId) return children;

  const linkClassName = "cursor-pointer link-color-normal text-left";

  const moduleId = clickInfo?.moduleId?.replace("/app", "").replace("/m/", "");

  if (clickInfo?.onClick === "module" && moduleId) {
    return (
      <button
        className={linkClassName}
        onClick={() => {
          setModuleId(moduleId);
        }}
      >
        {children}
      </button>
    );
  }

  if (clickInfo?.onClick === "product_module" && moduleId && product?.id) {
    return (
      <button
        className={linkClassName}
        onClick={() => {
          setModuleId(moduleId);
          setOpenProductId(product?.id);
        }}
      >
        {children}
      </button>
    );
  }

  if (
    clickInfo?.onClick === "product_module_day" &&
    clickInfo?.targetDay &&
    moduleId &&
    product?.id
  )
    return (
      <button
        className={linkClassName}
        onClick={() => {
          setModuleId(moduleId);
          setOpenProductId(product?.id);
          setDate({ dateSeconds: clickInfo.targetDay });
        }}
      >
        {children}
      </button>
    );

  return children;
};

interface Props {
  product: Product;
  module?: _FrontendModule;
  clickInfo?: {
    moduleId?: string;
    targetDay?: number;
    onClick: "nothing" | "module" | "product_module" | "product_module_day";
  };
}

const SingleProduct = ({ product, module, clickInfo }: Props) => {
  const t = useT();

  const { defaultReference, school, child, getOffer } =
    useContainer(AppContext);

  const { items } = getOffer(clickInfo?.moduleId?.replace("/app/m/", "") || "");
  const item = items.find((i) => i.id === product.id);

  const targetProduct = (item || product) as CombinedOfferItem;

  if (!targetProduct) return null;

  // Add base price for (before) taxes
  (targetProduct as CombinedOfferItem).basePrice = getUnitPrice(targetProduct);

  const onPurchase = async (_: CombinedOfferItem, __: CustomOrderContent) => {
    return;
  };

  const plugins = module?.plugins || ({} as Plugins.Array);

  const orderLines: OrderLines = [];
  const orderInfo = {} as OrderInfo;

  /** Signup offer plugin */
  const isSignupOfferProduct =
    plugins?.signupOffer?.active &&
    plugins?.signupOffer?.productId === product.id;

  const signupOfferExpired =
    isSignupOfferProduct && child?.takenSignupOffer === true;

  if (signupOfferExpired) return null;

  return (
    <ProductWrapper clickInfo={clickInfo} product={product}>
      <ProductTemplate
        product={targetProduct}
        previewOnly
        onPurchase={onPurchase}
        purchaseLabel={t("Add to basket")}
        fromAdmin
        previewProps={{
          children: <></>,
        }}
        customClassName="w-full"
        plugins={plugins}
        hasRequiredProduct={false}
        order={orderLines}
        orderInfo={orderInfo}
        defaultReference={defaultReference}
      />
    </ProductWrapper>
  );
};

export default SingleProduct;
