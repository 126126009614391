import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CombinedOfferItem } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import classNames from "classnames";
import { useState } from "react";
import { firstColumnStyles } from "../Week";
import { ProductMenuButton } from "./ProductMenuButton";
import { WeekInputs } from "./input/WeekInputs";
import { useAvailableOptions } from "./input/helpers/useAvailableOptions";
import { Variants } from "./variants/Variants";
import { VariantsTotal } from "./variants/VariantsTotal";

interface Props {
  item: CombinedOfferItem;
  lastItemClassnames?: string;
}

export const Product = ({ item, lastItemClassnames = "" }: Props) => {
  const { productId } = item;
  const availableOptions = useAvailableOptions({ productId });
  const hasOptions = availableOptions?.length > 0;

  const [expanded, setExpanded] = useState(false);

  return (
    <div
      data-product-id={productId}
      className={classNames(
        "border-divider-main border-b border-r overflow-hidden",
        lastItemClassnames
      )}
    >
      <div
        className="flex py-4 border-l-4 px-4 items-center justify-between overflow-hidden"
        style={{ borderColor: item.color }}
      >
        <div className="flex flex-col w-fit md:w-5/12 flex-0">
          <div
            className={classNames(
              "min-w-fit h400 flex items-center",
              firstColumnStyles
            )}
          >
            <p>{item.name}</p>
            {hasOptions && (
              <Button
                onClick={() => setExpanded(!expanded)}
                className="ml-3 h-8"
                shape="soft"
                size="small"
              >
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={classNames(
                    "text-xs transition-transform",
                    expanded ? "rotate-90" : "rotate-0"
                  )}
                />
              </Button>
            )}
          </div>
          <ProductMenuButton item={item} />
        </div>
        {hasOptions ? (
          <VariantsTotal setExpanded={setExpanded} productId={productId} />
        ) : (
          <WeekInputs
            productId={productId}
            options={{}}
            showTotal={hasOptions}
            item={item}
          />
        )}
      </div>
      {hasOptions && (
        <Variants expanded={expanded} productId={productId} item={item} />
      )}
    </div>
  );
};
