import { db, T, useT } from "@kanpla/system";
import { Button, Form, Input, message, Modal } from "@kanpla/ui";
import moment from "moment";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";

type FormData = {
  suggestion: string;
};

const ProductSuggestion = () => {
  const t = useT();

  const { schoolId, userId } = useContainer(OrderingContext);

  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const submit = async (data: FormData) => {
    try {
      setSending(true);

      const { suggestion } = data;

      await db.collection("suggestions").doc().set({
        userId,
        message: suggestion,
        schoolId: schoolId,
        dateCreated: moment().toDate(),
      });

      setSending(false);
      message.success(t("Your suggestion was sent"));
      setOpen(false);
    } catch (err) {
      setSending(false);
      message.error(t("Failed to send your suggestion"));
    }
  };

  return (
    <div className="pt-32 text-center">
      <h2 className="font-semibold text-xl md:text-2xl lg:text-3xl mb-2 text-text-secondary">
        {t("Do you have an idea for a dish?")}
      </h2>
      <p className="text-text-secondary">
        <T
          _str="You're welcome to {suggestProduct}"
          suggestProduct={
            <a onClick={() => setOpen(true)}>
              <T _str="suggest a product" />
            </a>
          }
        />
      </p>

      <Modal
        title={t("Suggest a dish / product")}
        subtitle={t("Your proposal will be sent to the kitchen")}
        open={open}
        setOpen={setOpen}
      >
        <Form<FormData> onSubmit={submit}>
          <Form.Item
            name="suggestion"
            rules={{
              required: t("Please enter your suggestion"),
            }}
            controlled
          >
            <Input.TextArea
              autoFocus
              className="block w-full"
              placeholder={`${t("Suggest something")}...`}
              rows={3}
            />
          </Form.Item>
          <Button
            loading={sending}
            htmlType="submit"
            dataCy="btn-mealplan-product-suggestion"
            className="mt-1"
          >
            Send
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default ProductSuggestion;
