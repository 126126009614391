import { getCssVariable, useT } from "@kanpla/system";
import { SubscriptionOrder } from "@kanpla/types";
import { ReceiptWrapper } from "@kanpla/ui";
import moment from "moment";
import { useCallback, useContext, useMemo } from "react";
import { useContainer } from "unstated-next";
import { SubscriptionContext } from "../.";
import { AppContext } from "../../contextProvider";
import Item from "./Item";
import { subscriptionStatus } from "./subscriptionStatus";

const Index = () => {
  const t = useT();

  const { subscriptions = [], periods } = useContext(SubscriptionContext);
  const { module, mobile } = useContainer(AppContext);

  const allPeriods = Object.values(periods || {})?.flat();

  const customColor =
    getCssVariable("--palette-background-primary") || "#ffffff";

  const subscriptionStatusInfo = useMemo(() => subscriptionStatus(t), [t]);

  const getStatus = useCallback(
    (subscription: SubscriptionOrder) => {
      const isExpiring = moment
        .unix(subscription?.to?.seconds)
        .isBefore(moment().add(4, "days"), "D");
      const isEnded = subscription?.to?.seconds < moment().unix();
      const isNew = subscription?.from?.seconds > moment().unix();
      if (isNew) {
        return subscriptionStatusInfo?.new;
      } else if (subscription?.autoRenew) {
        return subscriptionStatusInfo?.automatic;
      } else if (isExpiring) {
        return subscriptionStatusInfo?.expiring;
      } else if (isEnded) {
        return subscriptionStatusInfo?.ended;
      } else {
        return subscriptionStatusInfo?.active;
      }
    },
    [
      subscriptionStatusInfo?.active,
      subscriptionStatusInfo?.automatic,
      subscriptionStatusInfo?.ended,
      subscriptionStatusInfo?.expiring,
      subscriptionStatusInfo?.new,
    ]
  );

  const setReceiptConfig = (subscription: SubscriptionOrder) => {
    const period = allPeriods.find(
      (period) => period?.id === subscription?.periodId
    );

    const status = getStatus(subscription);

    return {
      title: (
        <p>
          {subscription.name}{" "}
          {period ? (
            <span className="opacity-70 font-normal">
              &mdash; {period?.name}
            </span>
          ) : null}
        </p>
      ),
      status,
    };
  };

  const now = moment().unix();
  const filterOutSubscription = (s: SubscriptionOrder) => s.to.seconds > now;

  const activeSubscriptions = subscriptions.filter(filterOutSubscription);

  return (
    <div className="flex flex-col pb-6 md:p-0 md:float-right">
      {activeSubscriptions.map((subscription) => (
        <ReceiptWrapper
          withPadding={true}
          svgColor={customColor}
          mobile={mobile}
          receiptConfig={setReceiptConfig(subscription)}
          fullWidth={true}
          subscription={subscription}
        >
          <Item subscription={subscription} module={module} />
        </ReceiptWrapper>
      ))}
      {subscriptions?.length > activeSubscriptions?.length && (
        <p className="text-sm text-text-secondary float-right mx-auto max-w-sm">
          {t(
            "To see old subscriptions, go to your account history in the settings."
          )}
        </p>
      )}
    </div>
  );
};

export default Index;
