import { CombinedOfferItem, Order } from "@kanpla/types";

interface Props {
  orders: Order[];
  product: CombinedOfferItem;
}

export const checkChildLimit = ({ orders = [], product }: Props) => {
  const { limitPerChild = Infinity } = product || {};

  if (orders) {
    const amount = orders.reduce((acc, order) => {
      return (acc += (order?.orderLines || []).reduce((acc, order) => {
        if (order.productId === product.id) {
          return (acc += order.amount);
        }
        return acc;
      }, 0));
    }, 0);

    return (limitPerChild ?? Infinity) - amount;
  }

  return limitPerChild;
};
