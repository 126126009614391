import { OrderingContext, useBasketPreventClick } from "@kanpla/ordering";
import { isWithCredit, useT, useWindowSize } from "@kanpla/system";
import { CombinedOfferItem, DayIndex, Module } from "@kanpla/types";
import { HorizontalSlider, TodaySwitch, WeekNavbarSwitch } from "@kanpla/ui";
import classNames from "classnames";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { Dictionary, capitalize, isEmpty } from "lodash";
import {
  Dispatch,
  FC,
  LegacyRef,
  default as React,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntersectionObserverRef } from "rooks";
import { useContainer } from "unstated-next";
import { dateInfoAtom } from "../../lib/hooks/useDateInfo";
import { basketContainerAtom, openBasketAtom } from "../basket/useBasket";
import BalanceDisplay from "./BalanceDisplay";
import BasketDisplay from "./BasketDisplay";

export const inViewCategoryIndexAtom = atom<number | null>(null);
export const clickedCategoryIndexAtom = atom<number | null>(null);
export const deadlineInfoHeightAtom = atom(0);

export interface NavbarSecondaryProps {
  timeNavigation?: "none" | "daily" | "weekly";
  extraContent?: React.ReactNode;
  categories?: Dictionary<CombinedOfferItem[]>;
  /** The dayRowOnPhone view will appear independently from module type. */
  enableDayRowOnPhone?: boolean;
  dayRowVisibleOnTablet?: boolean;
  /** Passes up the dayIndex value for usages in the library components. */
  onDayChange?: Dispatch<SetStateAction<DayIndex>>;
}

const NavbarSecondary: FC<NavbarSecondaryProps> = ({
  timeNavigation = "daily",
  extraContent = null,
  categories = {},
  enableDayRowOnPhone = false,
  dayRowVisibleOnTablet = false,
  onDayChange = () => void 0,
}) => {
  const t = useT();

  const openBasket = useAtomValue(openBasketAtom);

  const {
    week,
    dayIndex,
    setDate,
    mobile,
    module,
    userId,
    fromAdmin,
    innerAppLoading,
  } = useContainer(OrderingContext);

  const basketContainer = useAtomValue(basketContainerAtom);
  const setOpenBasket = useSetAtom(openBasketAtom);
  const { deadlineFormatted, isPastDeadline } = useAtomValue(dateInfoAtom);
  const inViewCategoryIndex = useAtomValue(inViewCategoryIndexAtom);

  const setClickedCategoryIndex = useSetAtom(clickedCategoryIndexAtom);
  const setDeadlineInfoHeight = useSetAtom(deadlineInfoHeightAtom);

  const deadlineInfoRef = useRef<HTMLDivElement | null>(null);

  const confirm =
    useBasketPreventClick({ disabled: true }) || (async () => true);

  const requiredProductId =
    module?.plugins?.requiredProduct?.active &&
    module?.plugins?.requiredProduct?.productId;

  const categoryItems = Object.entries(categories)
    .filter(
      ([_, items]) =>
        items?.filter((item) => requiredProductId !== item?.productId)?.length
    )
    .map(
      ([name]) =>
        capitalize(name) ||
        module?.text?.["noCategory/text"] ||
        t("Dish of the day")
    );

  const itemsTotalCount: number = Object.values(categories).flat().length;

  const { isMobileSize } = useWindowSize();

  const withCredit = isWithCredit(module);

  const [isScrolled, setIsScrolled] = useState(false);

  const callback = (entries: any) =>
    setIsScrolled(entries?.[0]?.intersectionRatio < 1);

  const [myRef] = useIntersectionObserverRef(callback, {
    threshold: [0, 0.9, 1],
    rootMargin: "50px 0px 0px 0px",
  });

  useEffect(() => {
    if (!mobile) return;

    setDeadlineInfoHeight(deadlineInfoRef.current?.clientHeight || 0);
  }, [deadlineInfoRef, mobile]);

  const DeadlineInfo = () =>
    deadlineFormatted ? (
      <div
        ref={deadlineInfoRef as LegacyRef<HTMLDivElement> | undefined}
        className={classNames(isPastDeadline && "text-danger-main")}
      >
        {t("Order until {value}", { value: deadlineFormatted })}
      </div>
    ) : null;

  const hasExtraContent =
    !isEmpty(extraContent) ||
    (withCredit && !mobile && userId) ||
    (!isEmpty(basketContainer) && !mobile);

  useEffect(() => {
    onDayChange(dayIndex);
  }, [dayIndex, onDayChange]);

  const ExtraContent = () => {
    if (module.flow === "menuPreview") return null;

    return (
      <div className="flex items-center">
        {withCredit && !mobile && userId && !fromAdmin && (
          <div className="hidden lg:block flex-shrink-0 whitespace-nowrap">
            {t("balance:")} <BalanceDisplay />
          </div>
        )}

        {!isEmpty(basketContainer) && !mobile && (
          <div className="ml-auto mt-3 md:mt-0 lg:ml-4">
            <BasketDisplay
              className={`${!openBasket ? "animate-pop" : ""}`}
              onClick={() => {
                setOpenBasket(true);
              }}
            />
          </div>
        )}

        {extraContent}
      </div>
    );
  };

  const showCategoriesSlider =
    categoryItems.length > 1 &&
    (isMobileSize
      ? categoryItems.length >= 4 || itemsTotalCount > 20
      : categoryItems.length >= 6 || itemsTotalCount > 25);

  const timeNavigationContainerClasses = classNames(
    "flex flex-col items-center justify-start w-full lg:w-initial gap-2.5",
    dayRowVisibleOnTablet ? "lg:items-start" : "md:items-start"
  );

  const daySwitchContainerClasses = classNames(
    "flex flex-col items-center w-full",
    dayRowVisibleOnTablet ? "lg:flex-row" : "md:flex-row"
  );

  if (!hasExtraContent && timeNavigation === "none" && !showCategoriesSlider)
    return null;

  const showFirstRow = hasExtraContent || timeNavigation !== "none";

  return (
    <div
      className="sticky z-20 bg-background-primary pt-16 -mt-16"
      style={{ top: "calc(var(--status-bar-height, 0px) - 4rem)" }}
      ref={myRef}
    >
      {showFirstRow && (
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-baseline py-3 wrapper w-full">
          {timeNavigation !== "none" && (
            <div className={timeNavigationContainerClasses}>
              <div className={daySwitchContainerClasses}>
                {timeNavigation === "daily" ? (
                  <TodaySwitch
                    week={week}
                    dayIndex={dayIndex}
                    setDate={setDate}
                    confirm={confirm}
                    module={module as Module}
                  />
                ) : (
                  <WeekNavbarSwitch
                    week={week}
                    dayIndex={dayIndex}
                    setDate={setDate}
                    dayRowOnPhone={
                      enableDayRowOnPhone ||
                      module.flow === "menuPreview" ||
                      module.type === "mealplan"
                    }
                    dayRowVisibleOnTablet={dayRowVisibleOnTablet}
                  />
                )}
                {module?.flow !== "menuPreview" && (
                  <div
                    className={classNames(
                      "transition-all flex items-center",
                      mobile && "mt-5",
                      mobile && isScrolled ? "opacity-0 !h-0" : "h-8",
                      !mobile && dayRowVisibleOnTablet
                        ? "md:mt-5 lg:mt-0 lg:ml-4"
                        : "md:ml-4"
                    )}
                  >
                    {innerAppLoading ? (
                      <div className="h-2 w-20 rounded loader" />
                    ) : (
                      <DeadlineInfo />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="hidden lg:block ml-auto">
            <ExtraContent />
          </div>
        </div>
      )}

      {showCategoriesSlider && (
        <HorizontalSlider
          items={categoryItems}
          selectedIndex={inViewCategoryIndex ?? 0}
          onClick={setClickedCategoryIndex}
          classNames={classNames(
            "pb-4",
            showFirstRow ? "pt-1 mt-1" : "pt-2 mt-2"
          )}
        />
      )}

      <div
        className={`h-px w-screen absolute left-0 right-0 bg-divider-main bottom-0 transition-opacity ${
          isScrolled ? "" : "opacity-0"
        }`}
        style={{
          left: "-50vw",
          marginLeft: "50%",
        }}
      />
      <div className="wrapper lg:hidden text-center">
        <ExtraContent />
      </div>
    </div>
  );
};

export default NavbarSecondary;
