import {
  entriesTotalWeekAmount,
  metricToColorClass,
  metricToDisplayString,
} from "@kanpla/system";
import classNames from "classnames";
import { FoodWasteInsightsData } from "libs/system/src/foodwaste/entriesToGraphData";
import { isEmpty, values } from "lodash";
import React from "react";
import Chart from "./Chart";
import { Header, Wrapper } from "./Layout";

interface FoodWasteInsightsProps {
  data: FoodWasteInsightsData;
  isPreview?: boolean;
}

export const FoodWasteInsights = ({
  data,
  isPreview = false,
}: FoodWasteInsightsProps) => {
  const totalWeekAmount = entriesTotalWeekAmount(data || {});

  const metrics = values(data)
    .find((metrics) => metrics && !isEmpty(metrics))
    ?.map((entry) => entry.metricKey);

  return (
    <Wrapper>
      <Header totalWeekAmount={totalWeekAmount} isPreview={isPreview} />
      {!isPreview && (
        <div className="flex flex-row flex-wrap w-full gap-x-3 md:gap-x-4 mt-1">
          {(metrics || []).map((metric) => (
            <div key={metric} className="flex items-center gap-x-1">
              <div
                className={classNames(
                  "w-2 h-2 md:w-3 md:h-3 rounded-full",
                  `bg-${metricToColorClass(metric)}-main`
                )}
              />
              <h3
                className={`text-${metricToColorClass(
                  metric
                )}-main whitespace-nowrap text-ellipsis text-sm md:text-md`}
              >
                {metricToDisplayString(metric)}
              </h3>
            </div>
          ))}
        </div>
      )}
      <div
        className={classNames(
          "flex w-full justify-center",
          isPreview ? "mt-1" : "mt-6 lg:mt-8"
        )}
      >
        <Chart weekData={data} />
      </div>
    </Wrapper>
  );
};
