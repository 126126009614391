import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { PriceExcludingVat, VatRate } from "@kanpla/types";
import { message } from "@kanpla/ui";
import { InputNumber } from "antd";
import { round } from "lodash";
import React, { useEffect, useState } from "react";

interface Props {
  value?: PriceExcludingVat;
  vatRate: VatRate;
  onChange?: (newPrice: PriceExcludingVat | undefined) => void;
  className?: string;
  /** Should show prices inc. VAT by default? */
  defaultShowingVat?: boolean;
  prefix?: React.ReactNode;
}

export const PriceInputWithVat = ({
  value,
  vatRate,
  onChange,
  className = "",
  defaultShowingVat = false,
  prefix = null,
}: Props) => {
  /** Convert from øre to kroner (or from cents fo EUR) */
  const displayValue = (value || 0) / 100;
  const onChangeDisplayValue = (newPrice: number | undefined) => {
    if (!onChange) return;
    const unitPrice = typeof newPrice === `number` ? newPrice * 100 : undefined;
    onChange(unitPrice);
  };

  const t = useT();
  const [exVatPrice, setExVatPrice] = useState(displayValue);
  const [incVatPrice, setIncVatPrice] = useState(displayValue * (1 + vatRate));
  const [showingVat, setShowingVat] = useState(defaultShowingVat);

  useEffect(() => {
    setExVatPrice(round(displayValue, 2));
    setIncVatPrice(round(displayValue * (1 + vatRate), 2));
  }, [displayValue, vatRate]);

  const handleExVatPriceChange = (value: number | null) => {
    if (value === null) {
      onChangeDisplayValue(undefined);
      return;
    }

    const exVatPrice = value;
    const incVatPrice = exVatPrice * (1 + vatRate);
    setExVatPrice(round(exVatPrice, 2));
    setIncVatPrice(round(incVatPrice, 2));
    onChangeDisplayValue(exVatPrice);
  };

  const handleIncVatPriceChange = (value: number | null) => {
    if (value === null) {
      onChangeDisplayValue(undefined);
      return;
    }

    const incVatPrice = value;
    const exVatPrice = incVatPrice / (1 + vatRate);
    setExVatPrice(round(exVatPrice, 2));
    setIncVatPrice(round(incVatPrice, 2));
    onChangeDisplayValue(exVatPrice);
  };

  const label = showingVat
    ? t("Inc. {vatRate}% VAT", { vatRate: vatRate * 100 })
    : t("Excl. VAT");
  const AddonAfter = (
    <div
      className="cursor-pointer"
      onClick={() => {
        setShowingVat(!showingVat);
      }}
    >
      {label} <FontAwesomeIcon icon={faAngleDown} />
    </div>
  );

  if (showingVat)
    return (
      <InputNumber
        min={0}
        value={typeof value === `number` ? incVatPrice : null}
        onChange={handleIncVatPriceChange}
        placeholder={t("Enter price incl. VAT")}
        addonAfter={AddonAfter}
        onKeyDown={(key) =>
          key.code === "Comma" &&
          message.warning(
            t("Use period (.) instead of comma for decimal numbers!")
          )
        }
        prefix={prefix}
      />
    );

  return (
    <InputNumber
      min={0}
      value={typeof value === `number` ? exVatPrice : null}
      onKeyDown={(key) =>
        key.code === "Comma" &&
        message.warning(
          t("Use period (.) instead of comma for decimal numbers!")
        )
      }
      onChange={handleExVatPriceChange}
      placeholder={t("Enter price excl. VAT")}
      addonAfter={AddonAfter}
      className={className}
      prefix={prefix}
    />
  );
};
