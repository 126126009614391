import { OrderLine, OrderLines } from "@kanpla/types";
import { isEqual, omit } from "lodash";
import { UniqueOrderLineProps, matchesLine } from "../../flex/hasOrderChanged";

type Props = {
  orderLines: OrderLines | undefined;
  targetLine: UniqueOrderLineProps;
  newData: Partial<OrderLine>;
  withVatRate?: boolean;
  removeOrderLine?: boolean;
};

/** Find an orderLine inside an order, and update a part of it */
export const updateOrderLine = ({
  orderLines = [],
  targetLine,
  newData,
  withVatRate = true,
  removeOrderLine = false,
}: Props) => {
  const matchingOptions = { withVatRate };

  if (typeof orderLines?.length !== "number") return [];

  if (!targetLine) return orderLines;

  const matchedLine = orderLines.find((line) =>
    matchesLine(line, targetLine, matchingOptions)
  );
  const otherLines = orderLines.filter((line) => {
    const matchesTarget = matchesLine(line, targetLine, matchingOptions);
    return !matchesTarget;
  });

  const newLine = {
    ...targetLine,
    ...(matchedLine || {}),
    ...newData,
  } as OrderLine;

  const stringifiedMatchedLine = JSON.stringify(matchedLine);
  const stringifiedNewLine = JSON.stringify(newLine);

  /** Remove the orderLine if newAmount is 0 */
  const isBeingRemoved =
    removeOrderLine && matchedLine && matchedLine?.amount >= 1;
  if (isBeingRemoved) return otherLines;

  /** Multiple variant is being edited (targeting multiple variants) */
  const isBeingEdited =
    stringifiedMatchedLine !== stringifiedNewLine &&
    otherLines.every((ol) => JSON.stringify(ol) !== stringifiedNewLine);
  /** Multiple variant that is being ordered (targeting multiple variants) */
  const isMultipleVariant = matchedLine && !otherLines.length;
  /** First line that is being ordered (targeting multiple variants) */
  const isFirstLine = !matchedLine && !otherLines.length;
  /** Lines added after the first line (targeting multiple variants) */
  const isNewLine = Boolean(!matchedLine && otherLines.length);
  /** If the line that is being ordered is already present (targeting multiple variants) */
  const isSameLine = stringifiedMatchedLine === stringifiedNewLine;
  const shouldIncludeNewLine = newLine?.amount > 0;

  const newLines = [...otherLines, newLine];

  const mergedLines = newLines.reduce((acc, ol, _, self) => {
    const refinedOl = omit(ol, ["amount", "unitPrice", "vatRate"]);

    const selfOl = self.find((selfOl) =>
      isEqual(omit(selfOl, ["amount", "unitPrice", "vatRate"]), refinedOl)
    );

    if (!selfOl) return acc;

    const mergedOl = {
      ...ol,
      ...selfOl,
    };

    return [...acc, mergedOl];
  }, [] as OrderLines);
  const uniqLines = mergedLines.filter(
    (ol, i, self) => i === self.findIndex((selfOl) => isEqual(selfOl, ol))
  );

  if (isBeingEdited) return uniqLines;
  if (shouldIncludeNewLine || isMultipleVariant || isNewLine || isSameLine)
    return newLines;
  if (isFirstLine) return [newLine];
  return otherLines;
};
