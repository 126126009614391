import { callInternalApi, KanplaError, useT } from "@kanpla/system";
import { message, Spinner } from "@kanpla/ui";
import {
  GetSchoolOptions,
  GetSchoolResponse,
} from "apps/frontend/pages/api/internal/signup/school";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { SignupData } from "../NewSalesplace";

interface Props {
  signupData: SignupData | null;
  setSignupData: (signupData: SignupData) => void;
}

export const ChooseWithCanteenId = ({ signupData, setSignupData }: Props) => {
  const {
    supplier,
    partnerId: appContextPartnerId,
    children,
  } = useContainer(AppContext);

  const t = useT();

  const [loading, setLoading] = useState<boolean>(false);

  const [code, setCode] = useState<string>("");

  const submitCanteenCode = async () => {
    try {
      setLoading(true);

      if (!code)
        throw new KanplaError("kanpla/fill-canteen-id", "Fill canteen ID out!");

      const partnerId = supplier?.partnerId || appContextPartnerId || null;

      const {
        schoolId: targetEntityId,
        scope,
        school: targetSchool,
      } = await callInternalApi<GetSchoolOptions, GetSchoolResponse>(
        "signup/school",
        {
          partnerId: partnerId || undefined,
          code,
        }
      );
      if (children.find((c) => c.schoolId === targetEntityId))
        throw new Error(t("You already have a location with this Canteen ID"));

      if (targetEntityId) {
        setSignupData({
          schoolId: targetEntityId,
          scope,
          school: targetSchool,
        });
      } else {
        throw new KanplaError("kanpla/wrong-canteen-id", "Wrong canteen ID");
      }
    } catch (e: any) {
      console.error(e);
      message.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code.length < 4) return;
    submitCanteenCode();
  }, [code]);

  return (
    <>
      <label className="form-label text-text-secondary mb-1">
        {t("Add a salesplace using a CanteenID")}
      </label>
      <div className="flex justify-center mb-4">
        {loading && isEmpty(signupData?.school?.selectors) ? (
          <div className="mt-4">
            <Spinner size="medium" useCurrentColor />
          </div>
        ) : (
          <ReactCodeInput
            type="text"
            className="react-code-input-wrapper"
            value={code}
            onChange={(newValue) => {
              setCode(newValue);
            }}
            fields={4}
            name="canteenIdCode"
            inputMode="latin"
          />
        )}
      </div>
    </>
  );
};
