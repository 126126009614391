import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { faCreditCard, faMobile } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isApplePaySupported, t, useT } from "@kanpla/system";
import { PaymentProvider } from "@kanpla/types";
import classNames from "classnames";
import { useAtom } from "jotai";
import { values } from "lodash";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { selectedPaymentMethodAtom } from "../../mealplan2/basket/elements/selectedPaymentMethodAtom";
import { SelectPayment } from "./SelectPayment";

type PaymentMethodOption = {
  label: string;
  value: PaymentProvider;
  icon: IconProp;
};

const paymentMethodsOptions: Record<
  "card" | "applepay" | "mobilepay",
  PaymentMethodOption
> = {
  card: {
    label: t("Card"),
    value: "card",
    icon: faCreditCard,
  },
  applepay: {
    label: "Apple Pay",
    value: "applepay",
    icon: faApple,
  },
  mobilepay: {
    label: "Mobile Pay",
    value: "mobilepay",
    icon: faMobile,
  },
};

const ChoosePaymentMethod = ({ className }: { className?: string }) => {
  const [open, setOpen] = useState(false);

  const [paymentMethod, setPaymentMethod] = useAtom(selectedPaymentMethodAtom);

  const { allowMobilePay, card } = useContainer(OrderingContext);
  const allowApplePay = isApplePaySupported();

  const t = useT();

  const options = values(paymentMethodsOptions).filter(({ value }) => {
    if (!allowMobilePay && value === "mobilepay") return false;
    if (!allowApplePay && value === "applepay") return false;
    return true;
  });

  const selectedOption = options.find(
    (option) => option.value === paymentMethod
  );

  return (
    <>
      <button
        className={classNames(
          "w-[200px] px-3 py-2 border justify-between border-divider-main shadow-sm rounded-md flex items-center cursor-pointer hover:opacity-50 md:hover:opacity-100 md:hover:border-primary-main transition-all ease-in-out",
          className
        )}
        onClick={() => setOpen(true)}
      >
        <div className="flex gap-x-3 items-center">
          {selectedOption?.icon && (
            <FontAwesomeIcon icon={selectedOption.icon} />
          )}
          {selectedOption?.value === "card" && card ? (
            <div className="flex flex-col items-start">
              <b className="capitalize text-sm">{card.metadata.brand}</b>
              <span className="text-xs text-text-secondary -mt-0.5">
                {t("ends with {value}", { value: card.metadata.last4 })}
              </span>
            </div>
          ) : (
            <span>{selectedOption?.label}</span>
          )}
        </div>
        <FontAwesomeIcon icon={faChevronDown} className="text-secondary-main" />
      </button>
      <SelectPayment
        open={open}
        setOpen={setOpen}
        selectedMethod={paymentMethod}
        showCardButton
        onSelect={(method) => setPaymentMethod(method)}
      />
    </>
  );
};

export default ChoosePaymentMethod;
