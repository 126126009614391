import { LoadFrontendReturn } from "@kanpla/services";
import { calculateAmountOfOrderItems } from "@kanpla/system";
import { OrderLines } from "@kanpla/types";

interface Props {
  school: LoadFrontendReturn["school"];
  orderLines: OrderLines;
}

/**
 * This method check if the order amount is below to a certain minimum (Flex Bulk Minimum).
 * However, if the order amount is 0 the order will go through.
 */
export const isBelowMinimum = ({ school, orderLines }: Props) => {
  const { flexBulkMinimum } = school?.contract || {};

  const totalAmount = calculateAmountOfOrderItems(orderLines);
  const isValid =
    typeof flexBulkMinimum !== `number` ||
    totalAmount === 0 ||
    totalAmount >= flexBulkMinimum;

  return { totalAmount, isValid };
};
