import { callInternalApi, useFetch } from "@kanpla/system";
import { SurveyForm } from "@kanpla/types";
import {
  FetchLastSurveyProps,
  FetchLastSurveyReturn,
} from "apps/frontend/pages/api/internal/feedback/fetchLastSurvey";
import { FetchSurveyByIdProps } from "apps/frontend/pages/api/internal/feedback/fetchSurveyById";
import { useSetAtom } from "jotai";
import moment from "moment";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { Modal, SurveysClosed, feedbackModalAtom } from "./Modal";
import { useFeedback } from "./useFeedback";

const Provider = () => {
  const router = useRouter();

  const { schoolId } = useContainer(AppContext);
  const { surveysResponded } = useFeedback();

  const [surveysClosed, setSurveysClosed] = useLocalstorageState<SurveysClosed>(
    "surveys-closed",
    []
  );

  const [surveyFromPushNotification, setSurveyFromPushNotification] =
    useState<SurveyForm | null>(null);

  const setFeedbackModal = useSetAtom(feedbackModalAtom);

  const { data } = useFetch<FetchLastSurveyProps, FetchLastSurveyReturn>(
    "feedback/fetchLastSurvey",
    {
      schoolId: schoolId || "",
      surveysClosed,
    },
    {
      refreshInterval: 1000 * 60 * 5,
      revalidateOnFocus: false,
    }
  );
  const lastSurveyAvailable = data?.lastSurvey || null;

  const surveyToShow = surveyFromPushNotification || lastSurveyAvailable;

  useEffect(() => {
    const { surveyId } = router.query;

    if (surveyId) {
      callInternalApi<FetchSurveyByIdProps, SurveyForm>(
        "feedback/fetchSurveyById",
        { surveyId: typeof surveyId === "object" ? surveyId[0] : surveyId }
      ).then((surveyForm: SurveyForm) => {
        setSurveyFromPushNotification(surveyForm);
      });
    }
  }, [router.asPath]);

  if (surveyToShow) {
    const startOfToday = moment.utc().startOf("day").unix();
    const checkClosedSurvey = surveysClosed.some(
      (s) => s.id === surveyToShow.id && startOfToday < s.closedAtSeconds
    );

    const checkRespondedSurvey = surveysResponded.some(
      (s) => s.surveyId === surveyToShow.id && startOfToday < s.dateSeconds
    );

    if (checkClosedSurvey || checkRespondedSurvey) return null;

    setFeedbackModal({ isOpen: true, surveyForm: surveyToShow });
  }

  return <Modal setSurveysClosed={setSurveysClosed} />;
};

export default Provider;
