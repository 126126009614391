import { IconDefinition, IconName } from "@fortawesome/fontawesome-svg-core";
import {
  faAppleWhole,
  faAvocado,
  faBacon,
  faBars,
  faBowlSpoon,
  faBullseyePointer,
  faBurger,
  faCakeSlice,
  faCandy,
  faCarrot,
  faCartShopping,
  faCircleEllipsis,
  faCookie,
  faCorn,
  faCroissant,
  faCupTogo,
  faFork,
  faForkKnife,
  faHome,
  faHouse,
  faKiwiFruit,
  faMeat,
  faMugTeaSaucer,
  faOliveBranch,
  faPlateUtensils,
  faPopcorn,
  faPotFood,
  faTurkey,
  faUtensils,
  faWheat,
} from "@fortawesome/pro-solid-svg-icons";

export type FontAwesomeCollection = Partial<{
  [key in IconName]: IconDefinition;
}>;

interface FontAwesomeCollections {
  fad: FontAwesomeCollection;
}

export const fontAwesomeCollection: FontAwesomeCollections = {
  fad: {
    avocado: faAvocado,
    bacon: faBacon,
    burger: faBurger,
    croissant: faCroissant,
    "kiwi-fruit": faKiwiFruit,
    carrot: faCarrot,
    popcorn: faPopcorn,
    "cake-slice": faCakeSlice,
    cookie: faCookie,
    corn: faCorn,
    candy: faCandy,
    meat: faMeat,
    "mug-tea-saucer": faMugTeaSaucer,
    turkey: faTurkey,
    wheat: faWheat,
    "olive-branch": faOliveBranch,
    "apple-whole": faAppleWhole,
    house: faHouse,
    "cart-shopping": faCartShopping,
    fork: faFork,
    utensils: faUtensils,
    "fork-knife": faForkKnife,
    "bowl-spoon": faBowlSpoon,
    "cup-togo": faCupTogo,
    "plate-utensils": faPlateUtensils,
    home: faHome,
    "pot-food": faPotFood,
    "bullseye-pointer": faBullseyePointer,
    bars: faBars,
    "circle-ellipsis": faCircleEllipsis,
  },
};
