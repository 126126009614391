import { db, fetchCollection, fetchMultipleDocuments } from "@kanpla/system";
import { School, SignupDomain } from "@kanpla/types";
import { isEmpty, uniq } from "lodash";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

export const useGetCompanySchools = () => {
  const {
    partnerId: appContextPartnerId,
    children,
    user,
  } = useContainer(AppContext);

  const [schools, setSchools] = useState<School[]>([]);
  const [domains, setDomains] = useState<Array<SignupDomain>>([]);

  /** Fallback if the school from code doesn't exist */

  const userDomain = user?.email?.split("@")[1];
  const getCompanySchools = async () => {
    try {
      // 1. Get signup domains of the partner
      const signupDomains = await fetchCollection<SignupDomain>(
        db
          .collection("signupDomains")
          .where("partnerId", "==", appContextPartnerId)
          .where("domain", "==", userDomain)
      );

      setDomains(signupDomains);

      // 2. Find same domains (will be displayed in the school selection)
      if (!signupDomains?.length) {
        setSchools([]);
        return;
      }

      const salesplacesAlreadyAdded =
        children?.map((child) => child.schoolId) || [];

      const schoolsIds = uniq(
        signupDomains
          .filter(
            (dom) =>
              dom.domain === userDomain &&
              !salesplacesAlreadyAdded.includes(dom.schoolId)
          )
          .map((domain) => domain.schoolId)
      );

      if (isEmpty(schoolsIds)) {
        setSchools([]);
        return;
      }

      const getSchools = await fetchMultipleDocuments<School>(
        "schools",
        schoolsIds
      );

      setSchools(getSchools);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCompanySchools();
  }, [children.length, appContextPartnerId, userDomain]);

  return { schools, domains };
};
