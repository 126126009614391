import { faShoppingBasket } from "@fortawesome/pro-duotone-svg-icons";
import { useLocalStorage, useT } from "@kanpla/system";
import { Badge, Button, Tooltip } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { basketContainerTotalItemsAtom } from "../basket/useBasket";

interface Props {
  shouldHide?: boolean;
  onClick: () => void;
  className: string;
}

interface ButtonProps {
  amountProducts: number;
  onClick: () => void;
}

const BasketButton = ({ amountProducts, onClick }: ButtonProps) => {
  const t = useT();

  return (
    <Badge count={amountProducts}>
      <Button
        type="primary"
        onClick={onClick}
        dataCy="basket-btn"
        icon={faShoppingBasket}
        className="w-fit"
      >
        {t("Basket")}
      </Button>
    </Badge>
  );
};

const BasketDisplay = (props: Props) => {
  const { shouldHide = false, onClick } = props;
  const t = useT();
  const amountOfItems = useAtomValue(basketContainerTotalItemsAtom);

  const [hasSeenButtonBefore, setHasSeenButtonBefore] = useLocalStorage(
    "seen-basket-button-2",
    false
  );

  if (amountOfItems === 0 || shouldHide) return null;

  if (!hasSeenButtonBefore || hasSeenButtonBefore === "undefined")
    return (
      <Tooltip
        defaultOpen
        content={t(
          "You can find your order here. Open the basket to complete your purchase."
        )}
        side="bottom"
      >
        <BasketButton
          amountProducts={amountOfItems}
          onClick={() => {
            setHasSeenButtonBefore(true);
            onClick();
          }}
        />
      </Tooltip>
    );

  return <BasketButton amountProducts={amountOfItems} onClick={onClick} />;
};

export default BasketDisplay;
