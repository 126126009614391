import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import React from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import { useDeadlineFormatter } from "../lib/hooks/useDeadlineFormatter";
import { deadlineInfoAtom } from "../lib/hooks/useDeadlineInfo";

interface DeadlineProps {
  individualDeadline: number;
  individualOnset?: number | null;
  isBeforeFlexibleStartDate?: boolean;
  className?: string;
  message?: string;
  onClick?: () => void;
}

const Deadline = ({
  individualDeadline,
  individualOnset = null,
  isBeforeFlexibleStartDate = false,
  className,
  message = "",
  onClick = () => null,
}: DeadlineProps) => {
  const t = useT();
  const { dateSeconds } = useContainer(OrderingContext);
  const { deadline, deadlineWeekRelative, deadlineExcludingWeekends } =
    useAtomValue(deadlineInfoAtom);

  const isExtended = deadline < individualDeadline;

  const deadlineDisplay = useDeadlineFormatter({
    dateSeconds,
    deadline: individualDeadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  const onsetDisplay = useDeadlineFormatter({
    dateSeconds,
    deadline: individualOnset || 0,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  const hasMessage = Boolean(message);
  const hasIndividualOnsetDate = Boolean(
    individualOnset && isBeforeFlexibleStartDate
  );
  const hasIndividualDeadline = Boolean(individualDeadline);

  if (!hasMessage && !hasIndividualOnsetDate && !hasIndividualDeadline)
    return null;

  return (
    <div
      className={classNames(
        "text-xs text-background-primary pb-1 bg-primary-light font-medium px-2 pt-1 rounded-lg bg-opacity-60",
        className
      )}
      style={{
        backdropFilter: "blur(3px)",
        WebkitBackdropFilter: "blur(3px)",
      }}
      onClick={onClick}
    >
      <div className="flex flex-row md:items-center">
        <FontAwesomeIcon icon={faClock} className="mr-2 mt-1 md:mt-0" />
        <div>
          {!hasMessage
            ? hasIndividualOnsetDate
              ? t("Order from {onsetDisplay}", {
                  onsetDisplay,
                })
              : hasIndividualDeadline
              ? t("Order {word} {deadlineDisplay}", {
                  word: isExtended ? t("until") : t("before"),
                  deadlineDisplay,
                })
              : null
            : message}
        </div>
      </div>
    </div>
  );
};

export default Deadline;
