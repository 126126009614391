import { CombinedOfferItem } from "@kanpla/types";
import { Print as PrintWrapper } from "@kanpla/ui";
import { useMemo } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

interface Props {
  children: JSX.Element;
  moduleId: string;
  isDigital?: boolean;
}

const SinglePrint = ({ children, moduleId, isDigital }: Props) => {
  const { week, getOffer, supplier } = useContainer(AppContext);

  const [menus] = useMemo(() => {
    const offer = getOffer(moduleId)?.items as Array<CombinedOfferItem>;

    return [offer];
  }, [getOffer, moduleId]);

  return (
    <PrintWrapper
      menus={menus}
      week={week}
      isDigital={isDigital}
      supplier={supplier}
    >
      {children}
    </PrintWrapper>
  );
};

export default SinglePrint;
