import {
  LoadChildrenProps,
  LoadChildrenReturn,
  SubmitChildProps,
} from "@kanpla/services";
import { hasAccessToModule, useFetch, useSubmit } from "@kanpla/system";
import { Child, Module, School } from "@kanpla/types";
import { atom, useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";

export const activateChildDataReadAtom = atom<boolean>(false);

export const useChildren = () => {
  const { moduleId, schoolId } = useContainer(OrderingContext);
  const active = useAtomValue(activateChildDataReadAtom);

  const { data, setData, loading } = useFetch<
    LoadChildrenProps,
    LoadChildrenReturn
  >(
    active ? "load/children" : null,
    {
      schoolId,
      moduleId,
    },
    {
      revalidateOnFocus: false,
      refreshInterval: 5 * 60 * 1000,
    }
  );

  const children = data?.children || [];

  const { submit } = useSubmit<SubmitChildProps, Child, typeof setData>({
    path: "submit/child",
    setData,
    requestConstructor: (newChild) => ({
      child: newChild,
      id: newChild.id,
      fromFrontend: true,
    }),
    responseDestructor: (newChild) => ({
      children: (
        children?.map((child) =>
          child.id === newChild.id ? newChild : child
        ) || []
      ).filter((c) => !c.deleted),
    }),
  });

  type HasAccessToIndividualFlex = {
    childId: Child["id"] | null;
    module: Module;
    school: School;
  };

  const hasAccessToIndividualFlex = ({
    childId,
    module,
    school,
  }: HasAccessToIndividualFlex) => {
    if (!childId) return false;

    const child = children.find((c) => c.id === childId);

    if (!child) return false;

    const { individual } = hasAccessToModule({
      child,
      module,
      school,
    });
    return individual;
  };

  return {
    children,
    submitChild: submit,
    hasAccessToIndividualFlex,
    loading,
  };
};
