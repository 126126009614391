import { constructNewUrl, Timestamp, useT } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { DrawerOrModal, Form, Input, message } from "@kanpla/ui";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

type FormData = {
  childName: string;
};

interface Props {
  child: Child;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const DeleteKid = ({ child, open, setOpen }: Props) => {
  const t = useT();

  const {
    setChild,
    userId,
    children,
    modules = [],
    updateCurrentChild,
    triggerReload,
  } = useContainer(AppContext);

  const router = useRouter();

  const handleSubmit = async () => {
    const otherChildren = children?.filter((c) => c.id !== child.id);
    const newChild = otherChildren?.[0];

    const deleteLoading = message.loading(t("Deleting user"));

    try {
      await setChild(newChild || null);

      await updateCurrentChild({
        kanplaId: null,
        cardHEX: null,
        deleted: true,
        deletedAt: Timestamp.now().seconds,
        deletedBy: userId,
      });

      await triggerReload(1000);
      message.success(t("{value} was deleted", { value: child.name.trim() }));
      setOpen(false);

      if (otherChildren.length === 0) {
        router.push("/app");

        return;
      }

      const newModuleId = modules?.find((m) =>
        m?.scope?.generatedSchoolIds?.includes(newChild.schoolId)
      )?.id;
      if (!newModuleId) return;
      const url = constructNewUrl(newChild.schoolId, newModuleId);
      router.push(url);
    } catch (error) {
      console.error(error);
      message.error(error.message);
    } finally {
      toast.done(deleteLoading);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("Delete {value}", {
        value: `${child.name}`,
      })}
      actions={[
        {
          label: `${t("Confirm")} ${t("deletion")}`,
          onClick: () => null,
          htmlType: "submit",
          form: "delete-kid-form",
          type: "danger",
        },
      ]}
    >
      <Form<FormData>
        id="delete-kid-form"
        onSubmit={handleSubmit}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="childName"
          className="mb-0"
          rules={{
            required: t("Please enter the {value}", {
              value: "child's name",
            }),
            validate: (value: string) =>
              value.trim() === child.name.trim() ||
              t("The name must be the equal to {value}", {
                value: child.name.trim(),
              }),
          }}
        >
          <Input
            label={t(`Write "${child.name.trim()}" in order to confirm`)}
            placeholder={child.name}
            id="name-input"
            autoFocus
          />
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default DeleteKid;
