import { faStore } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getChildGroupName } from "@kanpla/system";
import { Child } from "@kanpla/types";
import classNames from "classnames";
import dynamic from "next/dynamic";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import ChildActions from "../ChildActions";

const SalesplaceEdit = dynamic(() => import("./SalesplaceEdit"), {
  ssr: false,
});

interface Props {
  child: Child;
  schoolId: string;
}

const SalesplaceKidRow = ({ child, schoolId }: Props) => {
  const { schoolId: activeSchoolId, school } = useContainer(AppContext);

  const active = schoolId === activeSchoolId;

  return (
    <div
      className={classNames(
        "p-4 rounded-md flex items-center justify-between",
        active
          ? "bg-primary-main"
          : "bg-background-primary border border-secondary-light"
      )}
    >
      <div className="flex gap-x-3 items-center">
        {active && (
          <FontAwesomeIcon
            icon={faStore}
            size="lg"
            className={classNames(
              active ? "text-primary-contrast" : "text-text-primary"
            )}
          />
        )}
        <div className="flex flex-col">
          <h3
            title={child.school_name}
            className={classNames(
              "font-medium truncate w-48 md:w-56",
              active ? "text-primary-contrast" : "text-text-primary"
            )}
          >
            {child.school_name}
          </h3>
          <h4
            className={classNames(
              "text-xs",
              active ? "text-primary-contrast" : "text-text-primary"
            )}
          >
            {getChildGroupName(child?.selectors || {})}
          </h4>
        </div>
      </div>
      <ChildActions
        child={child}
        childActive={active}
        EditPopup={({ open, setOpen, child }) => (
          <SalesplaceEdit
            open={open}
            setOpen={setOpen}
            child={child}
            school={school}
          />
        )}
      />
    </div>
  );
};

export default SalesplaceKidRow;
