import { callInternalApi, useLocalStorage } from "@kanpla/system";
import { Popup } from "@kanpla/types";
import { useQuery } from "@tanstack/react-query";
import { FetchPopupProps } from "apps/frontend/pages/api/internal/popups/fetchPopup";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import Banner from "./Banner";
import Interaction from "./Interaction";
import Modal from "./Modal";

const Provider = () => {
  const { child, school } = useContainer(AppContext);

  const [initiated, setInitiated] = useState(false);
  const [savedPopup, setSavedPopup] = useLocalStorage<Popup | null>(
    "saved-popup",
    null
  );

  useEffect(() => {
    setTimeout(() => setInitiated(true), 5000);

    return () => setInitiated(false);
  }, [child?.id]);

  const childId = child?.id;
  const schoolId = child?.schoolId;

  const { data } = useQuery({
    queryKey: ["popups/fetchPopup", schoolId, childId],
    queryFn: async () => {
      if (!child || !isEmpty(savedPopup) || !initiated) return null;
      if (!childId || !schoolId) return null;

      const result = await callInternalApi<FetchPopupProps, Popup>(
        "popups/fetchPopup",
        {
          childId,
          schoolId,
        }
      );
      return result || null;
    },
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
  });

  useEffect(() => {
    setSavedPopup(data || null);
  }, [data?.id]);

  if (!child || !school) return null;

  if (!savedPopup || isEmpty(savedPopup)) return null;

  if (savedPopup.type === "banner")
    return <Banner popup={savedPopup} closePopup={() => setSavedPopup(null)} />;

  if (savedPopup.type === "modal")
    return <Modal popup={savedPopup} closePopup={() => setSavedPopup(null)} />;

  if (savedPopup.type === "interaction")
    return (
      <Interaction popup={savedPopup} closePopup={() => setSavedPopup(null)} />
    );

  return null;
};

export default Provider;
