/* eslint-disable react-hooks/rules-of-hooks */
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { isOfferHidden, useT } from "@kanpla/system";
import { CombinedOfferItem, DayIndex } from "@kanpla/types";
import {
  AllergensWarning,
  Button,
  DisplayAttributes,
  DrawerOrModal,
} from "@kanpla/ui";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

interface Props {
  product: CombinedOfferItem;
  dayIndex: DayIndex;
  detailed?: boolean;
}

const Product = ({ product, dayIndex, detailed = false }: Props) => {
  const t = useT();

  const { supplier, week, module, child } = useContainer(AppContext);
  const hideProductNames = module?.config?.hideProductNames;

  const [allergensModalOpen, setAllergensModalOpen] = useState(false);

  const dateSeconds = week[dayIndex]?.seconds || 0;
  const menuItem = product.dates?.[+dateSeconds]?.menu;

  const { isAlwaysVisible, isHidden } = isOfferHidden(
    product,
    dateSeconds,
    module
  );
  /** The item with content `X` should always be hidden */
  const isHiddenWithX = menuItem?.name === "X" || menuItem?.name === "x";

  /**
   * Even with productLine.isAlwaysVisible it should be
   * hidden if the menuItem content is `X`
   */
  const shouldHide =
    isHiddenWithX ||
    (!module?.productOptions?.[product?.id]?.default && !menuItem);

  const dishName = menuItem?.name || "";
  const dishDescription = menuItem?.description || "";
  const productItemName = product.name || "";

  const productName =
    isAlwaysVisible && !isHidden
      ? productItemName || dishName
      : productItemName;

  const { tags, allergens } = useMemo(() => {
    return {
      tags: {
        ...(product.pictograms || {}),
        ...(menuItem?.pictograms || {}),
        ...((product as any)?.labels || {}),
        ...(menuItem?.labels || {}),
      },
      allergens: {
        ...((product as any)?.allergens || {}),
        ...(menuItem?.allergens || {}),
      },
    };
  }, [menuItem?.allergens, product, menuItem?.labels, menuItem?.pictograms]);

  if (isHidden || shouldHide) return null;

  return (
    <div className="py-6 inline-block w-full border-divider-main relative last:pb-12">
      {detailed && (
        <>
          {!hideProductNames && (
            <p
              className={classNames(
                productName && dishName && "text-text-secondary",
                !dishName && "text-text-primary",
                "font-bold mb-2"
              )}
            >
              {productName.trim().toUpperCase()}
            </p>
          )}

          {dishName && (
            <p className={classNames("font-semibold")}>{dishName}</p>
          )}
        </>
      )}
      <p className="whitespace-pre-line first-letter:uppercase mt-3">
        {detailed ? dishDescription : productName || dishDescription}
      </p>

      <AllergensWarning
        product={product}
        dayIndex={dayIndex}
        child={child}
        week={week}
        className="my-3"
      />

      {detailed && (
        <>
          <div
            className={classNames(
              "flex mt-3 items-baseline",
              !Object.values(tags).includes(true)
                ? "justify-end"
                : "justify-between"
            )}
          >
            <DisplayAttributes
              attributes={tags}
              supplier={supplier}
              mode="all"
            />
            {Object.values(allergens || {}).includes(true) && (
              <Button
                shape="plain"
                className="px-0 text-text-secondary w-auto"
                onClick={() => setAllergensModalOpen(true)}
                dataCy="btn-menu-allergens"
                icon={faQuestionCircle}
              >
                <span className="mr-2 underline">{t("Allergens")}</span>
              </Button>
            )}
          </div>
          <DrawerOrModal
            title={t("Allergens")}
            open={allergensModalOpen}
            setOpen={setAllergensModalOpen}
          >
            <DisplayAttributes
              attributes={allergens}
              mode="description"
              supplier={supplier}
              className="bg-transparent"
            />
          </DrawerOrModal>
        </>
      )}
    </div>
  );
};

export default Product;
