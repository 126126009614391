import { getConfigNameAndId, stringifyOptions } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { firstColumnStyles } from "../../Week";
import { WeekInputs } from "../input/WeekInputs";
import VariantsWrapper from "./VariantsWrapper";
import { useAllConfigs } from "./useAllConfigs";

type Props = {
  productId: string;
  expanded: boolean;
  item: CombinedOfferItem;
};

export const Variants = ({ productId, expanded, item }: Props) => {
  const configsToShow = useAllConfigs({ productId });

  return (
    <VariantsWrapper expanded={expanded}>
      {configsToShow.map((config) => {
        const name = Object.values(config)
          .map((options) => getConfigNameAndId({ options })?.name)
          .join(", ");

        return (
          <div
            className="flex flex-wrap border-t py-2 px-4 items-center justify-between"
            key={stringifyOptions(config)}
          >
            <div className={firstColumnStyles}>{name}</div>
            <WeekInputs productId={productId} options={config} item={item} />
          </div>
        );
      })}
    </VariantsWrapper>
  );
};
