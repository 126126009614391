import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { T, t } from "@kanpla/system";
import { DrawerOrModal, Input, Spinner } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { RowItem } from "./RowItem";
import { activateChildDataReadAtom, useChildren } from "./useChildren";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const Modal = ({ open, setOpen }: Props) => {
  const { children, loading } = useChildren();

  const [query, setQuery] = useState("");

  const setActive = useSetAtom(activateChildDataReadAtom);

  useEffect(() => {
    if (open) setActive(open);
  }, [open]);

  const childrenFiltered = children.filter((child) =>
    (child?.name || "").toLowerCase().includes(query.toLowerCase())
  );

  if (!open) return null;

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("Users overview")}
      maxWidth="max-w-2xl"
    >
      <div className="text-center">
        <div className="md:w-1/2 ml-auto mb-2">
          <Input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={t("Search for a user")}
            icon={faSearch}
            iconRight={true}
          />
        </div>

        {!loading && !childrenFiltered.length && <T _str="No users found" />}

        <table className="table w-full whitespace-normal table-fixed">
          <thead>
            <tr>
              <th key="userName" className="text-left">
                <T _str="Name" />
              </th>
              <th key="order">
                <T _str="Order" />
              </th>
              <th key="codes">
                <T _str="Codes" />
              </th>
              <th key="delete">
                <T _str="Delete" />
              </th>
            </tr>
          </thead>

          {!loading && childrenFiltered && (
            <tbody>
              {sortBy(childrenFiltered, (c) => c.displayName).map((child) => (
                <RowItem child={child} key={child.id} />
              ))}
            </tbody>
          )}
          <tfoot>
            <tr>
              <td colSpan={4} className="text-right p-2 text-text-disabled">
                <T _str="Total users" />: {childrenFiltered.length}
              </td>
            </tr>
          </tfoot>
        </table>

        {loading && (
          <div className="">
            <Spinner />
          </div>
        )}
      </div>
    </DrawerOrModal>
  );
};
