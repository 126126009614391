import { LoadFlexBulkStandardProps } from "@kanpla/services";
import { useFetch } from "@kanpla/system";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { groupNameAtom, moduleIdAtom, schoolIdAtom } from "../../context";
import { WeeklyOrders } from "./atomWeeklyOrders";
import { emptyWeeklyOrders, useSetLoadedOrders } from "./loadedOrdersAtom";

export const useLoadStandardOrders = () => {
  const moduleId = useAtomValue(moduleIdAtom);
  const groupName = useAtomValue(groupNameAtom);
  const schoolId = useAtomValue(schoolIdAtom);

  const setLoadedOrders = useSetLoadedOrders();

  useEffect(() => {
    setLoadedOrders(emptyWeeklyOrders, "standard");
  }, [moduleId, groupName, schoolId]);

  const { data, setData } = useFetch<LoadFlexBulkStandardProps, WeeklyOrders>(
    "load/getFlexBulkStandardOrders",
    { moduleId, groupName, schoolId },
    {
      fallbackData: emptyWeeklyOrders,
    }
  );

  useEffect(() => {
    if (!data) return;
    setLoadedOrders(data, "standard");
  }, [data]);

  return { setData };
};
