import { OrderingContext } from "@kanpla/ordering";
import { useT } from "@transifex/react";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useContainer } from "unstated-next";

export const useInvoiceReference = () => {
  const { module } = useContainer(OrderingContext);
  const t = useT();

  const methods = useMemo(() => {
    if (!module) return {};

    const invoiceReference = module?.plugins?.invoiceReference;

    if (!invoiceReference || isEmpty(invoiceReference)) return {};

    return {
      required: invoiceReference?.required ?? true,
      defaultRequired: invoiceReference?.defaultRequired || false,
      minCharacters: invoiceReference?.minLength || 0,
      description:
        invoiceReference?.description ||
        t(
          "The reference is automatically added to your order. You can always update your default reference or manually change it in the order."
        ),
      title: invoiceReference?.title || t("Reference to company acquisition"),
      availableReferences: invoiceReference?.availableReferences,
    };
  }, [module, t]);

  return { ...methods };
};
