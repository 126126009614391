/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Module,
  School,
  Timestamp as TimestampType,
  Timezone,
} from "@kanpla/types";
import { Timestamp } from "../firebase.config";
import { getDefaultDateSeconds } from "./getDefaultDateSeconds";
import { getModuleDeadline } from "./getModuleDeadline";

interface Props {
  school: School;
  module: Module;
  timezone: Timezone;
}

export interface DeadlineProviderReturn {
  deadline: number;
  deadlineSoft: number;
  deadlineWeekRelative: boolean;
  deadlineExcludingWeekends: boolean;
  /** @deprecated */
  defaultDate?: TimestampType;
  /** @deprecated */
  defaultSoftDate?: TimestampType;
  defaultDateSeconds: number;
  defaultSoftDateSeconds: number;
}

export const deadlineProvider = ({
  school,
  module,
  timezone,
}: Props): DeadlineProviderReturn => {
  // 1. Load deadline data
  const { deadline, deadlineWeekRelative, deadlineSoft } = getModuleDeadline({
    school,
    module,
  });
  const deadlineExcludingWeekends =
    module?.plugins?.deadlineExcludingWeekends?.active;

  const getDefaultDate = ({
    deadline,
  }: {
    deadline: number;
    isWeekRelative?: boolean;
  }) => {
    const defaultDateSeconds = getDefaultDateSeconds({
      deadline,
      deadlineWeekRelative,
      deadlineExcludingWeekends,
      includesWeekend: !!module?.plugins?.sevenDayWeek?.active,
      timezone,
    });

    const defaultDate = new Timestamp(defaultDateSeconds, 0);
    return defaultDate;
  };

  // 2. Default dates + softDate
  const defaultDate = getDefaultDate({
    deadline,
  });
  const defaultSoftDate = getDefaultDate({
    deadline: deadlineSoft,
  });

  return {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends: deadlineExcludingWeekends || false,
    deadlineSoft,
    defaultDate,
    defaultSoftDate,
    defaultDateSeconds: defaultDate.seconds,
    defaultSoftDateSeconds: defaultSoftDate?.seconds,
  };
};
