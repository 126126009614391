import { Module, Product } from "@kanpla/types";
import { isEmpty, sortBy, uniq } from "lodash";

interface WithId {
  id: string;
}

interface WithProductId {
  productId: string;
}

interface Props<T> {
  /** Products you want to sort */
  items?: Array<T>;
  /** Array of product lines used for sorting */
  productLines?: Module["productLines"];
  /**
   * If an array of categories is available, we add them to the sorting
   * We multiply category placement by 1000, to enforce sorting
   */
  categories?: Array<string>;
  /**
   * List of productIds, saved at the productBank document
   * @deprecated use `productLines` instead
   */
  list?: Array<string>;
}

// type SortableProductList = WithId | WithProductId;

export function sortProducts<Type = Product>(props: Props<Type>): Array<Type> {
  const { items = [], productLines = [], categories = [], list = [] } = props;

  if (isEmpty(categories)) {
    const sorted = sortBy(items, (item) => {
      const currentList = uniq(productLines.map((pl) => pl.productId));
      return (currentList || list).indexOf(
        (item as unknown as WithProductId)?.productId ||
          (item as unknown as WithId)?.id
      );
    });

    return sorted;
  }

  const currentList = uniq(productLines.map((pl) => pl.productId));
  const sorted = sortBy(items, (item) => {
    const productIndex = (currentList || list).indexOf(
      (item as unknown as WithProductId)?.productId ||
        (item as unknown as WithId)?.id
    );

    const categoryIndex = categories.indexOf((item as Product)?.category || "");

    return categoryIndex * 1000 + productIndex;
  });

  return sorted;
}
