import { hasAccessToModule } from "@kanpla/system";
import { isEmpty } from "lodash";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";

interface Props {
  /** Limit the orders displayed to only one module (e.g. in meeting) */
  limitedModuleIds?: string[];
  viewEmployeeOrders: boolean;
}

export const useAvailableModules = ({
  limitedModuleIds = [],
  viewEmployeeOrders,
}: Props) => {
  const { modules, child, school } = useContainer(OrderingContext);

  if (!modules) return [];

  // 1. Get ordering modules
  const allModuleIds = modules?.filter((module) => {
    return module?.type !== "homescreen" && module?.flow !== "menuPreview";
  });

  // 2. Filter my "limitedModuleIds" if provided
  const availableModules =
    limitedModuleIds && !isEmpty(limitedModuleIds)
      ? allModuleIds?.filter((m) => limitedModuleIds.includes(m.id))
      : allModuleIds;

  // 3. For employee orders edge-case, return all flex modules if has admin access, or nothing
  if (viewEmployeeOrders) {
    const hasAdminAccessToAtLeastOneModule = modules?.some((module) => {
      if (!child || !school) return false;
      const { bulk } = hasAccessToModule({
        child,
        school,
        module,
        ignoreActive: true,
      });

      return bulk;
    });
    if (!hasAdminAccessToAtLeastOneModule) return [];

    return availableModules;
  }

  return availableModules;
};
