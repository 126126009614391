import { faLanguage } from "@fortawesome/pro-solid-svg-icons";
import { useT } from "@kanpla/system";
import { ButtonLine } from "@kanpla/ui";
import { useState } from "react";
import ChangeLanguage from "../../modals/ChangeLanguage";

const Language = () => {
  const t = useT();

  const [languageOpen, setLanguageOpen] = useState(false);

  return (
    <>
      <ButtonLine
        label={t("Language preference")}
        onClick={() => setLanguageOpen(true)}
        icon={faLanguage}
        dataCy="language-preferences-btn"
      />
      <ChangeLanguage open={languageOpen} setOpen={setLanguageOpen} />
    </>
  );
};

export default Language;
