import { Metric } from "@kanpla/types";
import { t } from "../transifex.config";
import { MetricMap } from "./displayMetricsString";

export const metricToDisplayString = (metric: Metric) => {
  const mappedMetrics: MetricMap = {
    plate: t("Plates"),
    buffet: t("Buffets"),
    meeting: t("Meetings"),
    production: t("Kitchen waste"),
  };

  return mappedMetrics[metric];
};
