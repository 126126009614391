export const TriggerPagePrint = (title?: string) => {
  const oldDocumentTitle = document.title;
  if (title) {
    document.title = title;
    window.addEventListener("beforeprint", (event) => {
      document.title = title;
    });
  }

  // Print in app
  try {
    // @ts-ignore
    window.webkit?.messageHandlers?.iosListener?.postMessage("print");
  } catch (err) {
    console.error(err);
  }

  // Do Firefox-related activities
  if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
    window.print();
    return;
  }

  // Print in browser
  try {
    // Print for Safari browser
    document.execCommand("print", false, undefined);
  } catch {
    window.print();
  }

  document.title = oldDocumentTitle;
  window.addEventListener("afterprint", (event) => {
    document.title = oldDocumentTitle;
  });
};
