import { db, fetchCollection } from "@kanpla/system";
import { Order } from "@kanpla/types";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

const useFutureOrders = (userId: string) => {
  const [hasFutureOrders, setFutureOrders] = useState(false);
  const [ordersForToday, setOrdersForToday] = useState<number>(null);

  const today = moment().startOf("day");

  const getLatestOrders = useCallback(
    async (uid: string) => {
      const orders = await fetchCollection<Order>(
        db
          .collection("orders")
          .where("userId", "==", uid)
          .where("dateSeconds", ">", today.unix())
          .limit(10)
      ).catch((e) => {
        console.log("FIRESTORE ERRROR (gLO)", e);
        return [];
      });

      setFutureOrders(orders.length > 0);

      const todayOrders = orders.filter((o) =>
        moment.unix(o.dateSeconds).isSame(moment(), "D")
      );
      setOrdersForToday(todayOrders.length);
    },
    [today]
  );

  useEffect(() => {
    if (!userId) return setFutureOrders(false);
    getLatestOrders(userId);
  }, [userId]);

  return {
    hasFutureOrders,
    ordersForToday,
  };
};

export default useFutureOrders;
