import React from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";
import Basket from "../../basket";

export const MobileBasket = () => {
  const { mobile } = useContainer(OrderingContext);

  if (!mobile) return null;

  return <Basket />;
};
