import { callFunction } from "@kanpla/system";
import { PopupConstructor } from "@kanpla/types";
import { ModalWrapper, SignupCarousel } from "@kanpla/ui";
import classNames from "classnames";
import { isEmpty } from "lodash";
import router from "next/router";
import React, { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

interface Props {
  childId: string;
  schoolId: string;
  pushToUrl: string;
  /** Push to url even if the carousel is not being rendered */
  forcePushToUrl?: boolean;
}

const SignupCarouselWrapper = (props: Props) => {
  const { pushToUrl, childId, schoolId, forcePushToUrl = false } = props;
  const { supplier, mobile } = useContainer(AppContext);

  /** Signup carousel logic */
  const [signupScreens, setSignupScreens] = useState<
    PopupConstructor.Fullscreen[]
  >([]);

  const loadSignupScreens = async (schoolId: string) => {
    try {
      const screens = (await callFunction("popups-fetchPopupScreens", {
        schoolId,
      })) as PopupConstructor.Fullscreen[];

      setSignupScreens(screens);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!schoolId) return;
    loadSignupScreens(schoolId);
  }, [schoolId]);

  const [shouldShowCarousel, setShouldShowCarousel] =
    useLocalstorageState<boolean>("should-show-carousel", true);
  const [open, setOpen] = useState(shouldShowCarousel);
  const [loading, setLoading] = useState(false);

  const isDesktopView = !mobile;

  const handleOnLastCustomScreen = async () => {
    try {
      setLoading(true);

      await callFunction("popups-registerPopupEvents", {
        childId,
        schoolId,
        popupIds: signupScreens.map((s) => s.id),
        registeredFrom: isDesktop ? "desktop" : "mobile",
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (!shouldShowCarousel) return null;

  if (loading) return null;

  if (isEmpty(signupScreens) && !loading) {
    forcePushToUrl && router.push(pushToUrl);
    return null;
  }

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      closableOutside={false}
      fullHeight={!isDesktopView}
      zMax={!isDesktopView}
      containerClassName="md:py-16"
      innerClassName={classNames({
        "md:rounded-lg md:shadow-xl relative w-full md:max-w-2xl md:h-full h-auto mx-auto overflow-hidden":
          isDesktop,
      })}
    >
      <SignupCarousel
        screens={signupScreens}
        supplier={supplier}
        onFinish={() => {
          setShouldShowCarousel(false);
          setOpen(false);
          router.push(pushToUrl);
        }}
        onLastCustomScreen={handleOnLastCustomScreen}
        lastButtonLoading={loading}
        hideSystemScreens
        desktopView={isDesktopView}
      />
    </ModalWrapper>
  );
};

export default SignupCarouselWrapper;
