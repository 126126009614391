/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  deadlineDisplay,
  delayPromise,
  displayDayString,
  getDayIndexFromSeconds,
  useT,
  weekToDisplayString,
} from "@kanpla/system";
import {
  DayIndex,
  GroupedCombinedOffer,
  OrderLines,
  StandardOrderLines,
} from "@kanpla/types";
import { DrawerOrModal } from "@kanpla/ui";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useFlexOrders } from "../../lib/flex/useFlexOrders";
import { useFlexSubmitOrder } from "../../lib/flex/useFlexSubmitOrder";
import { deadlineInfoAtom } from "../../lib/hooks/useDeadlineInfo";
import { confirmationModalOpenAtom } from "../../mealplan2/basket/elements/PurchaseAnimation";
import { standardOrdersModalDataAtom } from "../standardOrder/StandardOrderModal";
import ProductsList from "./ProductsList";

interface Props {
  offerItems: GroupedCombinedOffer;
  isStandard?: boolean;
}

export const productsListDateSecondsAtom = atom<number | null>(null);

const ProductsListModal = ({ offerItems, isStandard = false }: Props) => {
  const t = useT();
  const { week, mobile } = useContainer(OrderingContext);
  const { submit: submitOrder } = useFlexSubmitOrder();

  const { deadline, deadlineExcludingWeekends, deadlineWeekRelative } =
    useAtomValue(deadlineInfoAtom);

  const showSuccessPopup = useSetAtom(confirmationModalOpenAtom);

  const [standardOrderLines, setStandardOrdersModalData] = useAtom(
    standardOrdersModalDataAtom
  );

  const { weekOrders } = useFlexOrders();

  const [dateSeconds, setDateSeconds] = useAtom(productsListDateSecondsAtom);
  const [open, setOpen] = useState(false);

  const dayIndex = getDayIndexFromSeconds(dateSeconds || 0);

  const deadlineFormatted = deadlineDisplay({
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
    t,
  });

  useEffect(() => {
    if (!dateSeconds) {
      open && setOpen(false);
      return;
    }
    setOpen(true);
  }, [dateSeconds]);

  useEffect(() => {
    if (dateSeconds && !open) setDateSeconds(null);
  }, [open]);

  const modalTitle =
    mobile && dateSeconds ? (
      <div>
        <p>
          {displayDayString({
            dateSeconds: dateSeconds,
            todayString: t("Today"),
            format: "dddd",
          })}
        </p>
        <p className="text-base text-text-disabled mb-2">
          {weekToDisplayString(week)}
        </p>
      </div>
    ) : (
      weekToDisplayString(week)
    );

  /** The `weekOrders` is an object with 5 properties(5days), where the keys are `dateSeconds`. */
  const dayOrderLines: OrderLines = dateSeconds
    ? weekOrders?.[dateSeconds] || []
    : [];

  const orderLinesWithStandards =
    (isStandard ? standardOrderLines?.[dayIndex] : dayOrderLines) || [];

  return (
    <DrawerOrModal
      title={isStandard ? t("Standard choice") : modalTitle}
      subtitle={
        !isStandard && t("Order until {value}", { value: deadlineFormatted })
      }
      open={open}
      setOpen={setOpen}
      actions={[
        {
          label: t("Done"),
          onClick: () => {
            setDateSeconds(null);
          },
          type: "primary",
        },
      ]}
      floatingActions
    >
      {dateSeconds && (
        <ProductsList
          disabled={false}
          isStandard={isStandard}
          dateSeconds={dateSeconds}
          offer={offerItems?.[dateSeconds]}
          orderLines={orderLinesWithStandards}
          onProductSelected={async (newOrder) => {
            if (isStandard) {
              const standardEntries = week.map((_, index) => [
                index,
                standardOrderLines?.[index as DayIndex] || [],
              ]);

              standardEntries.push([
                dayIndex,
                isArray(newOrder) ? newOrder : [newOrder],
              ]);

              const newStandardOrders = Object.fromEntries(
                standardEntries
              ) as StandardOrderLines;

              setStandardOrdersModalData(newStandardOrders);
            } else {
              await submitOrder(
                isArray(newOrder) ? newOrder : [newOrder],
                {},
                dateSeconds.toString()
              );

              setDateSeconds(null);
              showSuccessPopup(true);
              await delayPromise(1000);
              showSuccessPopup(false);
            }
          }}
        />
      )}
    </DrawerOrModal>
  );
};

export default ProductsListModal;
