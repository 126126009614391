import { CombinedOfferItem } from "@kanpla/types";
import { isEmpty } from "lodash";
import moment from "moment";

interface Props {
  product?: CombinedOfferItem;
  dateSeconds?: string;
}

/**
 * Check if a product in a specific day of the week is available, based only on the days -
 * fromSeconds and toSeconds are not needed.
 */
export const getAvailabilityProductStandard = ({
  product,
  dateSeconds,
}: Props) => {
  const { fromSeconds, toSeconds, days } = product || {};

  const currentDayIndex = moment.unix(Number(dateSeconds)).weekday();

  const hasAvailabilitySettings = Boolean(
    fromSeconds || toSeconds || !isEmpty(days)
  );

  const isAvailableInTheCurrentDay = hasAvailabilitySettings
    ? !days?.[currentDayIndex]?.disabled
    : true;

  return {
    isAvailable: isAvailableInTheCurrentDay,
  };
};
