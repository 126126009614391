/* eslint-disable react-hooks/rules-of-hooks */
import { isOfferHidden } from "@kanpla/system";
import { CombinedOfferItem, DayIndex } from "@kanpla/types";
import { useMemo } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import ProductComponent from "./Product";

interface Props {
  categoryName: string;
  items: CombinedOfferItem[];
  overrideDayIndex?: DayIndex;
  /** This is a link to a possible icon for a category */
  menuIcon?: string;
}

/**
 * Draws a single category with its own product lines.
 */
const DayDetailCategory = ({
  items: categoryMenus,
  categoryName,
  overrideDayIndex,
  menuIcon,
}: Props) => {
  const { dayIndex: appDayIndex, week, module } = useContainer(AppContext);
  const dayIndex: DayIndex = overrideDayIndex ?? appDayIndex;

  const items = categoryMenus.filter(
    (item, index, self) => index === self.findIndex((t) => t.id === item.id)
  );

  const isEmpty = useMemo(() => {
    return items.every(
      (offer) => isOfferHidden(offer, week[+dayIndex]?.seconds, module).isHidden
    );
  }, [items, week, dayIndex, module]);

  /** Otherwise the Category title will be shown even if the items are hidden. */
  if (isEmpty) return null;

  return (
    <>
      <div className="text-base font-semibold before:h-px before:bg-gray-400 before:flex-1 after:flex-1 after:h-px after:bg-gray-400 flex items-center pt-6 pb-8 px-2 after:ml-10 before:mr-10 capitalize">
        {menuIcon && (
          <img src={menuIcon} alt={categoryName} className="h-8 mr-2" />
        )}
        <h2>{categoryName}</h2>
      </div>
      <div>
        {items.map((product) => {
          return (
            <ProductComponent
              product={product}
              key={product?.id}
              dayIndex={overrideDayIndex ?? dayIndex}
              detailed
            />
          );
        })}
      </div>
    </>
  );
};

export default DayDetailCategory;
