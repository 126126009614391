import { constructNewUrl } from "@kanpla/system";
import { Button as ButtonUI } from "@kanpla/ui";
import Link from "next/link";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { BlockProps } from "../utils";

const Button = ({ singleBlock }: BlockProps) => {
  const { modules, schoolId } = useContainer(AppContext);

  if (!singleBlock || !singleBlock.props) return <span></span>;

  const { title, url } = singleBlock.props;

  if (!title || !url) return <span></span>;

  const moduleUrlID = url?.split("/m/")?.[1]?.split("/")?.[0];

  if (!schoolId) return null;
  const redirectToUrl = constructNewUrl(schoolId, moduleUrlID);

  const moduleIsIncluded = modules?.map((m) => m.id).includes(moduleUrlID);

  return (
    <ButtonUI
      size="small"
      shape="soft"
      type="primary"
      className="my-2 xxs:my-0 px-0 whitespace-pre-line"
      style={{ maxWidth: "15ch" }}
    >
      {moduleIsIncluded ? (
        <Link href={redirectToUrl}>{title}</Link>
      ) : (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      )}
    </ButtonUI>
  );
};

export default Button;
