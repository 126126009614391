import { getMidnightSeconds, useFetch } from "@kanpla/system";
import {
  LoadOrderHistoryArgs,
  LoadOrderHistoryReturn,
} from "apps/frontend/pages/api/internal/load/orderHistory";
import classNames from "classnames";
import { atom, useAtomValue } from "jotai";
import { OrderWithChildName } from "libs/services/src/lib/ordering/orderHistory";
import { isEmpty, omit } from "lodash";
import moment from "moment";
import { useState } from "react";
import { uuid } from "short-uuid";
import { createContainer, useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import Content from "./Content";
import Header from "./navigation";
import { useAvailableModules } from "./useAvailableModules";

interface Props {
  /** Limit the orders displayed to only one module (e.g. in meeting) */
  limitedModuleIds?: string[];
  /** Special mode for viewing orders of all users in a company (school/selector), instead of viewing orders just for the single user */
  viewEmployeeOrders?: boolean;
  /** If the list is scrolling */
  isScrolling?: boolean;
  /** If the modal is opened from a `meeting` module, it will have more specific actions */
  meetingView?: boolean;
}

export const isModuleBulkAtom = atom<boolean>(false);

const ContextState = (
  {
    limitedModuleIds,
    viewEmployeeOrders = false,
    isScrolling,
    meetingView = false,
  }: Props = {} as Props
) => {
  const {
    userId,
    child,
    schoolId,
    mobile,
    moduleId: currentModuleId,
    partnerId,
    fromAdmin,
  } = useContainer(OrderingContext);

  const availableModules = useAvailableModules({
    limitedModuleIds,
    viewEmployeeOrders,
  });
  const availableModuleIds = availableModules.map((m) => m.id);

  const [moduleIds, setModuleIds] = useState<string[]>([
    availableModuleIds?.[0],
  ]);

  const showBulk = useAtomValue(isModuleBulkAtom);

  const [timeRange, setTimeRange] = useState<{
    fromSeconds: number;
    toSeconds: number;
  } | null>({
    fromSeconds: getMidnightSeconds(moment().unix()),
    toSeconds: getMidnightSeconds(moment().unix() + 90 * 86400),
  });
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [_reloader, setReloader] = useState("");
  const reloadHistory = () => setReloader(uuid());

  const { data, isValidating, error } = useFetch<
    LoadOrderHistoryArgs,
    LoadOrderHistoryReturn
  >(
    "load/orderHistory",
    {
      userId,
      moduleIds: isEmpty(moduleIds) ? [currentModuleId] : moduleIds,
      fromSeconds:
        timeRange?.fromSeconds || getMidnightSeconds(moment().unix()),
      toSeconds:
        timeRange?.toSeconds ||
        moment().utc().startOf("day").add(90, "days").unix(),
      groupName: child?.groupName as string,
      schoolId,
      viewEmployeeOrders,
      meetingView,
      fromAdmin,
      partnerId: partnerId || "",
      _reloader,
      showBulk,
    },
    { refreshInterval: 200000, revalidateOnFocus: false }
  );

  const loading = isValidating && isEmpty(data?.orders);

  const items = ((data?.orders || []) as OrderWithChildName[]).filter((o) =>
    (o?.childName?.toLowerCase() || "").includes(searchQuery.toLowerCase())
  );

  return {
    availableModuleIds,
    moduleIds,
    setModuleIds,
    timeRange,
    setTimeRange,
    loading,
    items,
    mobile,
    searchQuery,
    setSearchQuery,
    viewEmployeeOrders,
    meetingView,
    shouldScroll: isScrolling && items.length > 9,
    reloadHistory,
    error,
    ...omit(data, "orders"),
  };
};

export const ExportContext = createContainer(ContextState);

export const Orders = (props: Props) => {
  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (event.currentTarget.scrollTop > 0) {
      setTimeout(() => setIsScrolling(true), 0);
    } else {
      setTimeout(() => {
        setIsScrolling(false);
      }, 0);
    }
  };

  return (
    <ExportContext.Provider initialState={{ ...props, isScrolling }}>
      <Header />

      <div
        className={classNames(
          "w-full h-full px-6 md:px-8 overflow-scroll pb-6 md:pb-8 relative pt-6"
        )}
        onScroll={handleScroll}
      >
        <Content />
      </div>
    </ExportContext.Provider>
  );
};
