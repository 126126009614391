import { Child, _FrontendModule, _GeneratedSchool } from "@kanpla/types";
import { hasAccessToModule } from "./hasAccessToModule";
import { sortModules } from "./sortModules";

interface FilterModulesForChildProps {
  child: Child | null;
  school: _GeneratedSchool["school"] | undefined;
  modules: Array<_FrontendModule>;
}

export const filterModulesForChild = ({
  modules,
  school,
  child,
}: FilterModulesForChildProps) => {
  const filtered = modules.filter((module) => {
    if (module.deleted) return false;
    if (!school || !child) return false;

    const belongsToSchool = module.scope?.generatedSchoolIds?.includes(
      school?.id
    );

    if (!belongsToSchool) return false;

    const hasAccess = hasAccessToModule({
      child,
      school,
      module,
    });

    if (!child?.id && typeof module.public === "boolean" && !module.public)
      return false;

    return (
      belongsToSchool &&
      ((module.type === "flex" && (hasAccess.bulk || hasAccess.individual)) ||
        (module.type !== "flex" && hasAccess.other))
    );
  });

  const sorted = sortModules(filtered);

  return sorted;
};

interface FilterModuleForAnonymousProps {
  school: _GeneratedSchool["school"];
  modules: Array<_FrontendModule>;
}

export const filterModuleForAnonymous = ({
  modules,
  school,
}: FilterModuleForAnonymousProps) => {
  const filtered = modules.filter((module) => {
    if (module.deleted) return false;

    const belongsToSchool = module.scope?.generatedSchoolIds?.includes(
      school?.id
    );

    if (!belongsToSchool) return false;

    if (typeof module.public === "boolean" && !module.public) return false;

    if (!belongsToSchool) return false;

    return true;
  });

  const sorted = sortModules(filtered);

  return sorted;
};
