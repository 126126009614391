import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { useT } from "@kanpla/system";
import { Button, Input, Select } from "@kanpla/ui";
import { Table } from "antd";
import { cloneDeep, groupBy, set } from "lodash";
import React from "react";
import { ColumnTableProps } from "./ColumnTable.d";
import {
  getCellDesignSettings,
  getHeaderCellDesignSettings,
} from "./ColumnTable.helper";

export const ColumnTable = <PathType extends string | number>({
  columns,
  setColumns,
  options,
  setAllColumns,
  allColumns,
  moduleIds,
  tableClassNames = "",
}: ColumnTableProps<PathType> & { moduleIds?: string[] }) => {
  const t = useT();

  return (
    <div className="w-full">
      <Table
        pagination={false}
        size="small"
        dataSource={(columns || [])?.map((col, index) => ({
          ...col,
          withIndex: `${col.path}-${index}`,
        }))}
        rowKey="withIndex"
        className={`bg-background-secondary ${tableClassNames}`}
        footer={() => (
          <Button
            onClick={() => {
              setColumns(() => {
                const newD = cloneDeep(columns || []);
                newD.push({});
                return newD;
              });
              if (moduleIds) {
                setAllColumns(() => {
                  const newState = cloneDeep(allColumns);

                  moduleIds.forEach((moduleId) => {
                    set(newState, `export-${moduleId}-columns`, [
                      ...(newState[`export-${moduleId}-columns`] || []),
                      {},
                    ]);
                  });

                  return newState;
                });
              }
            }}
          >
            {t("Add a column")}
          </Button>
        )}
        columns={[
          {
            title: t("Name"),
            dataIndex: "title",
            width: "40%",
            render: (title: string, _, index) => (
              <Input
                placeholder={t("Name")}
                value={title}
                onChange={(e) => {
                  setColumns((d) => {
                    const newD = d.slice();

                    newD[index] = {
                      ...newD[index],
                      title: e.target.value,
                    };

                    return newD;
                  });
                  if (moduleIds) {
                    setAllColumns(() => {
                      const newState = cloneDeep(allColumns);

                      moduleIds.forEach((moduleId) => {
                        set(newState, `export-${moduleId}-columns[${index}]`, {
                          ...(newState[`export-${moduleId}-columns`][index] ||
                            {}),
                          title: e.target.value,
                        });
                      });

                      return newState;
                    });
                  }
                }}
                required
              />
            ),
            onHeaderCell: getHeaderCellDesignSettings,
            onCell: getCellDesignSettings,
          },
          {
            title: "Data",
            dataIndex: "path",
            width: "40%",
            render: (p: PathType, _, index) => {
              return (
                <Select
                  value={p}
                  className="w-40"
                  style={{ maxWidth: 350 }}
                  aria-required
                  placeholder={t("Select data")}
                  onChange={(path: PathType) => {
                    setColumns((d) => {
                      const newD = d.slice();

                      newD[index] = {
                        title: options.find((p) => p.path === path)?.title,
                        path,
                      };

                      return newD;
                    });
                    if (moduleIds) {
                      setAllColumns(() => {
                        const newState = cloneDeep(allColumns);

                        moduleIds.forEach((moduleId) => {
                          set(
                            newState,
                            `export-${moduleId}-columns[${index}]`,
                            {
                              ...(newState[`export-${moduleId}-columns`]?.[
                                index
                              ] || {}),
                              title: options.find((p) => p.path === path)
                                ?.title,
                              path,
                            }
                          );
                        });

                        return newState;
                      });
                    }
                  }}
                  options={Object.entries(groupBy(options, "group")).flatMap(
                    ([_, items]) =>
                      items.map((path) => ({
                        label: path.title,
                        value: path.path as string,
                      }))
                  )}
                />
              );
            },
            onHeaderCell: getHeaderCellDesignSettings,
            onCell: getCellDesignSettings,
          },
          {
            title: t("Delete"),
            dataIndex: "title",
            render: (_, __, index) => (
              <Button
                type="danger"
                icon={faTrashAlt}
                onClick={() => {
                  setColumns((d) => {
                    delete d[index];

                    return d.filter((da) => da);
                  });
                  if (moduleIds) {
                    const newState = cloneDeep(allColumns);

                    moduleIds.forEach((moduleId) => {
                      delete newState[`export-${moduleId}-columns`][index];

                      set(
                        newState,
                        `export-${moduleId}-columns`,
                        newState[`export-${moduleId}-columns`].filter(
                          (col) => col
                        )
                      );
                    });

                    setAllColumns(newState);
                  }
                }}
              />
            ),
            onHeaderCell: getHeaderCellDesignSettings,
            onCell: getCellDesignSettings,
          },
        ]}
      />
    </div>
  );
};
