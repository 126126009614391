import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { _FrontendModule } from "@kanpla/types";
import { ButtonLine, Popper, PopperMenu } from "@kanpla/ui";
import React, { useState } from "react";
import SinglePrint from "./SinglePrint";

interface Props {
  buttonLineIcon: IconDefinition;
  buttonLineLabel: string;
  buttonLineClasses?: string;
  flexModulesToPrint: _FrontendModule[];
  isDigital?: boolean;
}

const Item = ({
  buttonLineIcon,
  buttonLineLabel,
  flexModulesToPrint,
  buttonLineClasses = "",
  isDigital = false,
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Popper
      open={open}
      setOpen={setOpen}
      actionElement={
        <ButtonLine
          label={buttonLineLabel}
          icon={buttonLineIcon}
          className={`print:hidden ${buttonLineClasses}`}
          inBetween
        />
      }
      zIndex={60}
      fitActionElement={false}
      containerAutoWidth
      disablePortal
      className="print:hidden mt-1 !p-0"
    >
      <PopperMenu className="print:hidden">
        {flexModulesToPrint?.map((m) => (
          <SinglePrint moduleId={m.id} key={m.id} isDigital={isDigital}>
            <PopperMenu.Item>{m.displayName || m.name}</PopperMenu.Item>
          </SinglePrint>
        ))}
      </PopperMenu>
    </Popper>
  );
};

export default Item;
