import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { atom, useSetAtom } from "jotai";
import { ReactNode } from "react";
import { useIntersectionObserverRef } from "rooks";

interface StepWrapperProps {
  pageTitle: string;
  subtitle?: string;
  children: ReactNode;
  backButtonText?: string;
  hideBackButton?: boolean;
  hideAction?: boolean;
  action?: ReactNode;
  onBack?: () => void;
}

export const isScrolledDescriptionAtom = atom(false);

const StepWrapper = (props: StepWrapperProps) => {
  const t = useT();
  const {
    children,
    pageTitle,
    subtitle,
    onBack,
    backButtonText,
    hideBackButton,
    hideAction,
    action,
  } = props;

  const setIsScrolled = useSetAtom(isScrolledDescriptionAtom);
  const callback = (entries: any) => {
    setIsScrolled(entries?.[0]?.intersectionRatio < 1);
  };
  const [myRef] = useIntersectionObserverRef(callback, {
    threshold: [0, 0.9, 1],
    rootMargin: "50px 0px 0px 0px",
  });

  return (
    <div className="flex flex-col">
      {!hideBackButton && (
        <button
          onClick={onBack}
          className="-mb-6 whitespace-nowrap w-min font-regular text-text-secondary hover:opacity-70 cursor-pointer flex items-center"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          {backButtonText || t("Back")}
        </button>
      )}
      <div
        className="flex w-full justify-between items-center mt-8"
        ref={myRef}
      >
        <div className="flex flex-col">
          <h1 className="title-secondary">{pageTitle}</h1>
          {subtitle && (
            <h3 className="text-sm text-text-secondary mt-2">{subtitle}</h3>
          )}
        </div>
        {!hideAction && action}
      </div>
      <div className="mt-4">{children}</div>
    </div>
  );
};

export default StepWrapper;
