import { CombinedOfferItem, DayIndex } from "@kanpla/types";
import classnames from "classnames";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { selectedDayIndexAtom } from "../../..";
import { OrderingContext } from "../../../../context";
import { isStandardOrderingAtom } from "../../../Navigation";
import { Change } from "../../../data/changesAtom";
import { DailyInputWeeklyOrder } from "./DailyInputWeeklyOrder";

interface Props {
  productId: Change["productId"];
  options: Change["options"];
  showTotal?: boolean;
  item: CombinedOfferItem;
}

export const WeekInputs = ({ productId, options, showTotal, item }: Props) => {
  const selectedDayIndex = useAtomValue(selectedDayIndexAtom);
  const isStandardOrdering = useAtomValue(isStandardOrderingAtom);

  const { week } = useContainer(OrderingContext);

  return (
    <div className="flex md:flex-1">
      {week.map((seconds, dayIndex) => {
        const props = {
          dayIndex: dayIndex as DayIndex,
          productId,
          options,
        };

        const dayIndexDisabled = item.days?.[dayIndex]?.disabled || false;
        const daySecondsDisabled = !item?.dates?.[seconds.seconds]?.available;

        return (
          <div
            key={dayIndex}
            className={classnames(
              "flex-1 text-center block",
              dayIndex !== selectedDayIndex && "hidden md:block"
            )}
          >
            <DailyInputWeeklyOrder
              {...props}
              disabled={
                isStandardOrdering
                  ? false
                  : dayIndexDisabled || daySecondsDisabled
              }
              showTotal={showTotal}
            />
          </div>
        );
      })}
    </div>
  );
};
