import { displayDayString, findHoliday, useT } from "@kanpla/system";
import { GroupedCombinedOffer } from "@kanpla/types";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { isArray } from "lodash";
import React, { useMemo } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { getRanges } from "../../lib/flex/getRanges";
import { useFlexOrders } from "../../lib/flex/useFlexOrders";
import { useFlexSubmitOrder } from "../../lib/flex/useFlexSubmitOrder";
import { deadlineInfoAtom } from "../../lib/hooks/useDeadlineInfo";
import CanteenClosed from "../../shared/CanteenClosed";
import HolidayFeedback from "../standardHolidays/HolidayFeedback";
import { periodsAtom } from "../standardHolidays/StandardHolidays";
import MobileProductPreview from "./MobileProductPreview";
import ProductsList from "./ProductsList";

type DaysProps = {
  offerItems: GroupedCombinedOffer;
};
const Days = ({ offerItems: groupedOfferItems }: DaysProps) => {
  const t = useT();

  const { mobile, week, holidayDates } = useContainer(OrderingContext);

  const { submit } = useFlexSubmitOrder();

  const periods = useAtomValue(periodsAtom);
  const periodsRanges = getRanges(periods);

  const { isDateSecondsPastDeadline } = useAtomValue(deadlineInfoAtom);

  const { weekOrders } = useFlexOrders();

  const weekMenu = useMemo(
    () =>
      week.map((day) => {
        const dateSeconds = day.seconds;
        const dayString = displayDayString({
          dateSeconds,
          format: "dddd",
        });

        const isPastDeadline = isDateSecondsPastDeadline(dateSeconds);
        const { isHoliday: isStandardHoliday } = findHoliday({
          periodsRanges,
          dateSeconds: dateSeconds.toString(),
        });
        const activeHoliday = holidayDates?.[dateSeconds];
        const isKitchenClosed = Boolean(activeHoliday);

        const disabled = isPastDeadline || isStandardHoliday || isKitchenClosed;

        return {
          dateSeconds,
          dayString,
          disabled,
          isStandardHoliday,
          isKitchenClosed,
          orderLines: weekOrders[dateSeconds],
          offer: groupedOfferItems[dateSeconds],
          activeHoliday,
          isPastDeadline,
        };
      }),
    [week, weekOrders, holidayDates, periodsRanges, isDateSecondsPastDeadline]
  );

  /** is week backend holiday */
  const isWeekHoliday = weekMenu.every((day) => day.isKitchenClosed);
  const activeHoliday = isWeekHoliday ? holidayDates?.[week[0].seconds] : null;

  if (activeHoliday) {
    return <CanteenClosed holidayDesc={activeHoliday.design} />;
  }

  return (
    <div className={classNames(mobile && "-mx-4")}>
      {weekMenu.map(
        ({
          dateSeconds,
          orderLines,
          dayString,
          offer,
          isStandardHoliday: isHoliday,
          activeHoliday,
          isKitchenClosed,
          disabled,
          isPastDeadline,
        }) => {
          if (mobile)
            return (
              <MobileProductPreview
                key={dateSeconds}
                offer={offer}
                orderLines={orderLines}
                dateSeconds={dateSeconds}
                dayString={dayString}
                activeHoliday={activeHoliday}
                isPastDeadline={isPastDeadline}
                isStandardHoliday={isHoliday}
              />
            );

          return (
            <div key={dateSeconds} className="mb-8 last-of-type:mb-0">
              <div
                className={classNames("mb-2", {
                  flex: isHoliday || isKitchenClosed,
                  "gap-x-5": isHoliday,
                  "items-baseline gap-x-3": isKitchenClosed,
                })}
              >
                <h3 className="h500">{dayString}</h3>
                <HolidayFeedback
                  dateSeconds={dateSeconds.toString()}
                  isHolidayFromAdmin={isKitchenClosed}
                />
              </div>

              <ProductsList
                isStandard={false}
                offer={offer}
                dateSeconds={dateSeconds}
                orderLines={orderLines}
                disabled={disabled}
                onProductSelected={async (newOrder) => {
                  await submit(
                    isArray(newOrder) ? newOrder : [newOrder],
                    {},
                    dateSeconds.toString()
                  );
                }}
              />
            </div>
          );
        }
      )}
    </div>
  );
};

export default Days;
