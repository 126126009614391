import moment from "moment";

import type { FlexBulkStandard } from "@kanpla/types";

import { getDaysInRange } from "@kanpla/system";

export const getRanges = (periods: FlexBulkStandard["holidays"] = []) => {
  if (!periods.length) return [];

  return periods.flatMap((period) => {
    const { fromSeconds, toSeconds } = period;

    if (!fromSeconds || !toSeconds) return [];

    const startDate = moment.unix(fromSeconds);
    const endDate = moment.unix(toSeconds);

    return { ...period, ranges: getDaysInRange(startDate, endDate, "unix") };
  });
};
