import { useT } from "@kanpla/system";
import { Checkbox, DrawerOrModal, Form, message } from "@kanpla/ui";
import { Dispatch, SetStateAction } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

type FormData = {
  emailOnOrder: boolean;
  emailOnPayment: boolean;
  notificationOnDeadline: boolean;
};

type NotificationsProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const Notifications = ({ open, setOpen }: NotificationsProps) => {
  const t = useT();

  const { user, userId, updateUser } = useContainer(AppContext);

  const handleSubmit = async (data: FormData) => {
    if (!userId) return;

    await updateUser({ notificationPreferences: { ...data } });

    message.success(t("Mail preferences saved"));
    setOpen(false);
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("Mail preferences")}
      actions={[
        {
          label: t("Confirm"),
          onClick: () => null,
          className: "primary",
          htmlType: "submit",
          form: "notification-preferences-form",
        },
      ]}
    >
      <Form<FormData>
        id="notification-preferences-form"
        onSubmit={handleSubmit}
        defaultValues={{
          emailOnOrder: user?.notificationPreferences?.emailOnOrder ?? false,
          emailOnPayment: user?.notificationPreferences?.emailOnPayment ?? true,
          notificationOnDeadline:
            user?.notificationPreferences?.notificationOnDeadline ?? false,
        }}
      >
        <Form.Item
          name="emailOnOrder"
          controlled
          controlledProps={{ valueName: "checked" }}
        >
          <Checkbox>{t("Order confirmation")}</Checkbox>
        </Form.Item>
        <Form.Item
          name="emailOnPayment"
          controlled
          controlledProps={{ valueName: "checked" }}
        >
          <Checkbox>{t("Payment confirmation")}</Checkbox>
        </Form.Item>
        <Form.Item
          name="notificationOnDeadline"
          controlled
          controlledProps={{ valueName: "checked" }}
        >
          <Checkbox>{t("Deadline push-notification")}</Checkbox>
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default Notifications;
