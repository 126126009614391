import { useFetch } from "@kanpla/system";
import { StampCard, StampCardRule } from "@kanpla/types";
import {
  FetchStampcardProps,
  FetchStampcardReturn,
} from "apps/frontend/pages/api/internal/stampcards/fetchStampcards";
import { useSetAtom } from "jotai";
import { orderBy } from "lodash";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { stampcardModalAtom } from "./StampcardModal";

export const useStampCards = () => {
  const { schoolId } = useContainer(AppContext);
  const setStampcardModal = useSetAtom(stampcardModalAtom);

  const {
    data: stampcardResponse,
    setData: setStampcards,
    loading: loadingStampCards,
  } = useFetch<FetchStampcardProps, FetchStampcardReturn>(
    "stampcards/fetchStampcards",
    {
      schoolId: schoolId,
    },
    {
      revalidateOnFocus: false,
      isPaused: () => !schoolId,
    }
  );
  const stampcards = stampcardResponse?.stampcards || [];

  const orderedStampcards = orderBy(
    stampcards,
    "structure.createdAtSeconds",
    "desc"
  );

  const openStampcardModal = (stampcard: {
    structure: StampCardRule;
    data: StampCard;
  }) => setStampcardModal({ isOpen: true, stampcard });

  return {
    stampcards: orderedStampcards,
    setStampcards,
    openStampcardModal,
    loadingStampCards,
  };
};
