import { faEmptySet } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { Empty } from "antd";
import { useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { basketContainerAtom } from "../../shared/basket/useBasket";

const BasketEmpty = () => {
  const basketContainer = useAtomValue(basketContainerAtom);
  const t = useT();

  const isBasketEmpty = isEmpty(basketContainer);
  if (!isBasketEmpty) return null;

  return (
    <div className="my-6 py-4 text-text-secondary rounded-lg bg-background-secondary ">
      <Empty
        description={t("No bookings")}
        image={<FontAwesomeIcon icon={faEmptySet} className="text-sm" />}
        imageStyle={{ fontSize: 10 }}
      />
    </div>
  );
};

export default BasketEmpty;
