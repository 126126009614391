import {
  Module,
  School,
  _FrontendModule,
  _GeneratedSchool,
} from "@kanpla/types";

interface Props {
  /** All the school's modules */
  modules?: Array<Module>;
  /** Or single module */
  targetModule?: Module | _FrontendModule;
  /** The target school */
  school: School | _GeneratedSchool["school"];
}

/**
 * Checks if a module has enabled standard ordering, given module and school
 * @interface props
 * @returns `true` if the module should have access to standard ordering
 */
export const shouldHaveStandardOrdering = (props: Props) => {
  const { targetModule, school, modules } = props;

  let hasStandardOrderDisabledOnModule: boolean = null;

  if (targetModule) {
    const { config } = targetModule;

    hasStandardOrderDisabledOnModule = config?.standardOrderingDisabled;
  } else if (modules?.length) {
    hasStandardOrderDisabledOnModule = Boolean(
      modules.some((m) => m?.config?.standardOrderingDisabled)
    );
  } else return true;

  // Disabled on the school
  const { modules: schoolModules } = school;
  const standardOrderingOnSchool =
    schoolModules?.[targetModule?.id]?.standardOrderingDisabled;

  const standardOrderOnModuleNotSpecified =
    typeof standardOrderingOnSchool === "undefined" ||
    typeof standardOrderingOnSchool === null;

  if (
    hasStandardOrderDisabledOnModule &&
    (standardOrderingOnSchool || standardOrderOnModuleNotSpecified)
  )
    return false;

  if (!hasStandardOrderDisabledOnModule && standardOrderingOnSchool)
    return false;

  return true;
};
