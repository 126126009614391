import { faFileExcel } from "@fortawesome/pro-solid-svg-icons";
import {
  defineOptions,
  exportOrders,
  getErrorMessage,
  tx,
  useT,
} from "@kanpla/system";
import {
  ExportOrderColumns,
  ExportOrderColumnsBase,
  Module,
} from "@kanpla/types";
import {
  ColumnTable,
  ColumnTableList,
  DrawerOrModal,
  Switch,
  message,
} from "@kanpla/ui";
import { cloneDeep, set } from "lodash";
import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { ExportContext } from "..";
import { OrderingContext } from "../../context";

interface AllCols {
  [key: string]: ColumnTableList<string>;
}

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  modalTitle: string;
  setAllColumns: Dispatch<SetStateAction<AllCols>>;
  allColumns: AllCols;
  selectedColumns?: ColumnTableList<string>;
}

const ExportOrdersModal = ({
  open,
  setOpen,
  modalTitle,
  setAllColumns,
  allColumns,
  selectedColumns,
}: Props) => {
  const t = useT();
  moment.updateLocale(tx.getCurrentLocale(), { week: { dow: 1, doy: 4 } });

  const { module, schoolId, modules, child } = useContainer(OrderingContext);
  const { moduleIds, timeRange } = useContainer(ExportContext);

  const [productPerLine, setProductPerLine] = useState(false);
  const [loading, setLoading] = useState(false);

  const options = useMemo(() => {
    const { definedOptions, customOptions } = defineOptions({
      modules: (modules || []) as Module[],
      productPerLine,
      t,
    });

    return [...definedOptions, ...customOptions];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules, productPerLine]);

  const defaultColumns = useMemo(() => {
    const cols = [
      {
        title: t("Date"),
        path: "dateSeconds",
      },
      {
        title: t("Order name"),
        path: "orderInfo_name",
      },
      {
        title: t("Total price"),
        path: "priceTotalIncludingTax",
      },
      {
        title: t("VAT"),
        path: "taxTotal",
      },
    ];

    return cols;
  }, []);

  const [columnSettings, setColumnSettings] =
    useLocalstorageState<ColumnTableList>(
      `export-${module?.id}-columns`,
      defaultColumns
    );

  const propOrState = (
    newState: ColumnTableList<string> | null = null,
    getState = false
  ) => {
    if (getState) return selectedColumns || [];

    const newCols = cloneDeep(allColumns);

    moduleIds.forEach((moduleId) => {
      if (!moduleId) return;
      set(newCols || {}, `export-${moduleId}-columns`, newState);
    });

    setAllColumns(newCols || {});

    return newCols || {};
  };

  useEffect(() => {
    const isOldWay = (
      (propOrState(null, true) as ColumnTableList<string>) || []
    ).some((col) => col?.path?.includes("info//"));

    if (isOldWay) propOrState(defaultColumns);

    if (
      propOrState(null, true) &&
      (propOrState(null, true) as any) !== "undefined" &&
      (propOrState(null, true) as any) !== "null"
    )
      return;

    propOrState(defaultColumns);
  }, []);

  const downloadExcel = useCallback(async (): Promise<void> => {
    const selectedColumnsConfig: ExportOrderColumns = {};

    (propOrState(null, true) as ColumnTableList<string>)
      .filter((item) => item.path?.trim())
      .forEach(({ path, title }) => {
        selectedColumnsConfig[path || ""] = {
          displayName: title?.trim(),
        };
      });

    setLoading(true);

    try {
      await exportOrders({
        fromSeconds:
          timeRange?.fromSeconds ||
          moment().utc().subtract(3, "months").startOf("day").unix(),
        toSeconds:
          timeRange?.toSeconds ||
          moment().utc().add(3, "months").endOf("day").unix(),
        moduleIds: moduleIds.map((m) => m.split("-")[0]),
        schoolIds: [schoolId],
        returnType: "xls",
        columns: selectedColumnsConfig,
        productPerLine,
        groupNames: child?.groupName ? [child.groupName] : [],
        childId: child?.id,
        schoolId,
      });
    } catch (e) {
      console.error(e);
      message.error({
        key: "error-export-orders",
        content: getErrorMessage(e),
      });
    } finally {
      setLoading(false);
    }
  }, [
    timeRange,
    schoolId,
    columnSettings,
    moduleIds,
    child?.id,
    productPerLine,
  ]);

  const onChangeProductPerLine = (checked: boolean) => {
    const orderCol = {
      title: t("Order"),
      path: "order",
    };

    const orderPathIsAlreadyPresent = Boolean(
      checked &&
        (propOrState(null, true) || []).find(
          (col) => col.path === orderCol.path
        )
    );

    const newColumns = checked
      ? [orderCol, ...(propOrState(null, true) as ColumnTableList<string>)]
      : (propOrState(null, true) as ColumnTableList<string>).filter(
          (col) => col.path !== orderCol.path
        );

    if (!orderPathIsAlreadyPresent) propOrState(newColumns);

    setProductPerLine(checked);
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={modalTitle}
      style={{ maxWidth: "36rem" }}
      stopPropagation={false}
      actions={[
        {
          label: t("Export to Excel"),
          icon: faFileExcel,
          type: "primary",
          onClick: async () => await downloadExcel(),
          dataCy: "btn-exports-export",
          loading,
        },
      ]}
    >
      <div className="flex flex-col w-full h-full">
        <h1 className="mb-1 text-lg font-medium">{t("Choose columns")}</h1>
        <ColumnTable
          columns={propOrState(null, true) as ColumnTableList<string>}
          setColumns={setColumnSettings}
          setAllColumns={setAllColumns}
          allColumns={allColumns}
          options={options as ColumnTableList<keyof ExportOrderColumnsBase>}
          moduleIds={moduleIds}
          tableClassNames="bg-white"
        />
        <h1 className="mb-1 mt-4 text-lg font-medium">
          {t("Product per line")}
        </h1>
        <Switch
          checked={productPerLine}
          onCheckedChange={onChangeProductPerLine}
          label={t("Each product of each order will have its own line")}
        />
      </div>
    </DrawerOrModal>
  );
};

export default ExportOrdersModal;
