import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import React from "react";
import { TabIcon } from "./TabIcon";

interface TabProps {
  /** Click action */
  onClick?: () => Promise<void> | void;
  /** The moduleId of the targeted tab */
  moduleId: string;
  /** If the tab is active */
  active?: boolean;
  /** The icon of the Tab */
  iconKey: string | [IconPrefix, IconName];
  /** The label of the Tab */
  label: string;
  /** If it has to be displayed with mobile styles */
  isMobile?: boolean;
  fullWidth?: boolean;
  className?: string;
}

export const Tab = ({
  onClick,
  moduleId,
  active = false,
  iconKey,
  label,
  isMobile = false,
  fullWidth = false,
  className = "",
}: TabProps) => {
  return (
    <button
      onClick={onClick}
      id={`navbar_${moduleId}`}
      className={classNames(
        "focus:no-underline items-center justify-center flex transition-colors py-2 whitespace-nowrap font-medium",
        isMobile
          ? "flex-col flex-1 px-0"
          : "rounded-lg mr-1 group relative overflow-hidden px-4",
        active
          ? "text-primary-main"
          : isMobile
          ? "text-text-secondary"
          : "text-text-primary",
        fullWidth && isMobile ? "w-full" : "",
        className
      )}
    >
      {/* Background */}
      {!isMobile && (
        <div
          className={classNames(
            "z-0",
            active ? "text-primary-main" : "text-background-secondary"
          )}
        >
          <div
            className={classNames(
              active ? "opacity-10" : "group-hover:opacity-100 opacity-0",
              "transition-opacity ease-in-out bg-current absolute inset-0 rounded-lg overflow-hidden"
            )}
          />
        </div>
      )}
      <div
        className={classNames(
          "relative flex items-center justify-items-start",
          isMobile ? "flex-col flex-1 w-full" : "mr-1"
        )}
      >
        <div className={classNames({ "h-5": isMobile })}>
          <TabIcon iconKey={iconKey} size={isMobile ? "lg" : "1x"} />
        </div>
        <span
          className={classNames(
            isMobile
              ? "mt-1.5 w-full break-words line-clamp-1 whitespace-normal text-ellipsis"
              : "ml-2"
          )}
          style={{ fontSize: isMobile ? 12 : 14 }}
        >
          {label}
        </span>
      </div>
    </button>
  );
};
