import { T, useT } from "@kanpla/system";
import { Calendar, Input } from "@kanpla/ui";
import classNames from "classnames";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { useContainer } from "unstated-next";
import { ExportContext } from "..";
import ExportMenu from "./ExportMenu";
import SelectModule from "./SelectModule";

const unixToMomentRange = (
  range: {
    fromSeconds: number;
    toSeconds: number;
  } | null
): [Moment, Moment] | null => {
  if (!range) return null;
  return [moment.unix(range.fromSeconds), moment.unix(range.toSeconds)];
};

const Menu = () => {
  const {
    mobile,
    timeRange,
    setTimeRange,
    setSearchQuery,
    searchQuery,
    viewEmployeeOrders,
    meetingView,
  } = useContainer(ExportContext);

  const [showError, setShowError] = useState<boolean>(false);

  const adminView = viewEmployeeOrders || meetingView;
  const t = useT();

  return (
    <div
      className={classNames(
        "flex flex-row items-center flex-wrap md:flex-nowrap gap-x-3 gap-y-3 md:gap-y-0",
        {
          "mt-2": !adminView || mobile,
        }
      )}
    >
      <SelectModule />

      <div className="flex flex-row gap-x-3 items-center w-fit md:w-full">
        {adminView && (
          <Input.Search
            placeholder={t("Search order...")}
            value={searchQuery}
            onChange={(newValue) => setSearchQuery(newValue)}
            withButton
          />
        )}
        <Calendar.RangePicker
          value={unixToMomentRange(timeRange)}
          onChange={(range) => {
            const diffInMonths = moment(range[1]).diff(
              moment(range[0]),
              "months"
            );

            if (diffInMonths > 3) {
              setShowError(true);
              return;
            }

            setTimeRange({
              fromSeconds: (range[0] as Moment).unix(),
              toSeconds: (range[1] as Moment).unix(),
            });
          }}
          calendarProps={{
            disabled: (date) => {
              const threeMonthsAgo = moment().subtract(3, "months");
              return moment(date).isBefore(threeMonthsAgo);
            },
          }}
          extraContent={
            showError ? (
              <div className="mt-2 text-sm font-semibold text-danger-main">
                <T _str="The selected range cannot exceed 3 months" />
              </div>
            ) : null
          }
        />
        {!meetingView && <ExportMenu />}
      </div>
    </div>
  );
};

export default Menu;
