import React, { useLayoutEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";

import type { CrowdTrackerData } from "@kanpla/types";

import { Header, Wrapper } from "./Layout";
import { Tick } from "./Tick";

export type CrowdTrackerProps = {
  average: CrowdTrackerData;
  day?: CrowdTrackerData;
  currentTime?: number;
  isPreview?: boolean;
};

export const CrowdTracker = ({
  average,
  day = [],
  currentTime = 0,
  isPreview = false,
}: CrowdTrackerProps) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (targetRef.current) {
        setHeight(targetRef.current.offsetHeight);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { liveCount, avgCount, isLive } = useMemo(() => {
    const liveData = day.find(({ time }) => time === currentTime);
    const avgData = average.find(({ time }) => time === currentTime);

    if (!liveData || !avgData) {
      return { liveCount: 0, avgCount: 0, isLive: false };
    }

    return { liveCount: liveData.count, avgCount: avgData.count, isLive: true };
  }, [day, average, currentTime]);

  const scaleY = useMemo(() => {
    const maxValue = Math.max(
      ...average.map(({ count }) => count),
      ...day.map(({ count }) => count)
    );

    const scale = isLive ? 2 : 1.3;

    return height / scale / maxValue;
  }, [height, average, day, isLive]);

  return (
    <Wrapper ref={targetRef}>
      {isLive && (
        <Header
          liveCount={liveCount}
          avgCount={avgCount}
          isPreview={isPreview}
        />
      )}

      <div className="flex flex-1 sm:gap-2 gap-1.5 h-full w-full justify-between">
        {average.map(({ time, count: avgCount }) => {
          let dayCount = 0;
          const dayTime = day.find(({ time: liveTime }) => liveTime === time);
          if (dayTime) {
            dayCount = dayTime.count;
          }

          return (
            <div
              key={time}
              className="flex flex-1 relative flex-col-reverse items-center"
            >
              <Tick time={time} isPreview={isPreview} />

              <div className="mb-6 relative w-full flex flex-col-reverse">
                <div
                  className={classNames(
                    "w-full rounded-md transition-[height,opacity] duration-500 ease-in-out",
                    {
                      "z-10 bg-info-main opacity-80": dayCount >= avgCount,
                      "bg-info-light opacity-50": dayCount < avgCount,
                    }
                  )}
                  style={{ height: avgCount * scaleY }}
                />

                <div
                  className="absolute bottom-0 w-full rounded-md bg-info-dark transition-[height,opacity] delay-200 duration-500 ease-in-out"
                  style={{ height: dayCount * scaleY }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};
