import classnames from "classnames";
import React, {
  ReactNodeArray,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import SliderItem from "./SliderItem";

type HorizontalSliderProps = {
  selectedIndex: number | null;
  items: ReactNodeArray;
  onClick?: (index: number) => void;
  classNames?: string;
};

export const HorizontalSlider = ({
  items,
  onClick,
  selectedIndex = 0,
  classNames,
}: HorizontalSliderProps) => {
  const scrollableElement = useRef<HTMLDivElement>();
  const [highlighted, setHighlighted] = useState(null);

  const handleClick = useCallback(
    (index: number) => {
      setHighlighted(index);
      onClick?.(index);
    },
    [onClick]
  );

  const containerClasses = classnames(
    classNames,
    "w-full h-fit overflow-x-auto px-5 flex flex-nowrap items-center scrollbar-hide"
  );

  const overlayClasses =
    "w-8 from-background-primary absolute inset-y-0 z-40 pointer-events-none mb-px";

  useEffect(() => {
    setHighlighted(selectedIndex);
  }, [selectedIndex]);

  if (!items?.length) return null;

  return (
    <div className="relative">
      <div className={containerClasses} ref={scrollableElement}>
        {items.map((content, index) => (
          <SliderItem
            key={index}
            selected={index === highlighted}
            onClick={() => handleClick(index)}
            scrollableElement={scrollableElement.current}
          >
            {content}
          </SliderItem>
        ))}

        {/* Element with zero-width-space to fix last item's right-padding in mobile. */}
        <div style={{ minWidth: "10px", minHeight: "100%" }}>&#8203;</div>
      </div>
      <div className={classnames(overlayClasses, "left-0 bg-gradient-to-r")} />
      <div className={classnames(overlayClasses, "right-0 bg-gradient-to-l")} />
    </div>
  );
};
