import { useT } from "@kanpla/system";
import { usePriceFormatter } from "@kanpla/ui";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";

import { OrderingContext } from "../../../context";
import {
  basketAtom,
  basketContainerTotalPriceAtom,
  hasEnoughMoneyToPayBasketAtom,
} from "../../../shared/basket/useBasket";
import { useOrderPriceBreakdown } from "../../../shared/basket/useOrderPriceBreakdown";
import { DiscountBreakdown } from "../../../shared/receipt/DiscountBreakdown";
import { PriceBreakdown } from "../../../shared/receipt/PriceBreakdown";

const PriceOverview = () => {
  const t = useT();
  const { activePlugins, balance, module, fromAdmin } =
    useContainer(OrderingContext);
  const basket = useAtomValue(basketAtom);
  const hasEnoughMoneyToPayBasket = useAtomValue(hasEnoughMoneyToPayBasketAtom);
  const basketContainerTotalPrice = useAtomValue(basketContainerTotalPriceAtom);

  const hasPayPerOrder =
    activePlugins["kanplaGo"] || activePlugins["payPerOrder"];
  const hidePayment = module.paymentMethod !== "billing" && hasPayPerOrder;

  const deductedFromCredit = () => {
    const diff = (balance || 0) - basketContainerTotalPrice;
    return diff < 0 ? balance || 0 : basketContainerTotalPrice;
  };

  const { totalPrice, orderDiscounts, totalDiscountAmount } =
    useOrderPriceBreakdown({
      orderLines: basket,
    });

  const amountToPay =
    basketContainerTotalPrice - deductedFromCredit() - totalDiscountAmount;
  const amountToPayFormatted = usePriceFormatter(amountToPay);

  const shouldPay = totalPrice > 0;

  return (
    <div
      className={classNames(
        "flex w-full pt-2 md:pt-4 flex-col",
        hidePayment && "pb-8"
      )}
    >
      <DiscountBreakdown orderDiscounts={orderDiscounts} />
      <h2 className="heading-main w-full text-left">{t("Price overview")}</h2>
      <PriceBreakdown orderLines={basket} showVatInfo />
      {module.paymentMethod === "credit" &&
        !hasEnoughMoneyToPayBasket &&
        !fromAdmin &&
        shouldPay && (
          <div className="flex pt-1 justify-between">
            <h3 className="text-md text-warning-main">
              {hasPayPerOrder ? t("You will be charged") : t("You are missing")}
            </h3>
            <p className="text-warning-main text-md">{amountToPayFormatted}</p>
          </div>
        )}
    </div>
  );
};

export default PriceOverview;
