import {
  faBurgerSoda,
  faFileInvoice,
  faFileInvoiceDollar,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PriceFormatter } from "@kanpla/ui";
import React from "react";
import { useContainer } from "unstated-next";
import { ExportContext } from "..";

const EmployeeStats = () => {
  const { totalAmount, totalPrice, totalProducts } =
    useContainer(ExportContext);

  return (
    <div className="w-full flex justify-between gap-4 mt-4 md:h-full md:overflow-auto h-fit overflow-x-scroll flex-nowrap items-center scrollbar-hide md:pr-0 pr-6">
      <div className="p-4 rounded-md bg-background-secondary flex flex-col w-fit md:w-full">
        <div className="flex items-center md:flex-wrap flex-nowrap">
          <FontAwesomeIcon icon={faFileInvoice} />
          <h3 className="text-sm ml-2 whitespace-nowrap md:whitespace-normal">
            Total orders
          </h3>
        </div>
        <h1 className="font-semibold text-lg md:text-2xl mt-1">
          {totalAmount || "-"}
        </h1>
      </div>
      <div className="p-4 rounded-md bg-background-secondary flex flex-col w-fit md:w-full">
        <div className="flex items-center md:flex-wrap flex-nowrap">
          <FontAwesomeIcon icon={faFileInvoiceDollar} />
          <h3 className="text-sm ml-2 whitespace-nowrap md:whitespace-normal">
            Total price
          </h3>
        </div>
        <h1 className="font-semibold text-lg md:text-2xl mt-1">
          {totalPrice ? <PriceFormatter price={totalPrice} /> : "-"}
        </h1>
      </div>
      <div className="p-4 rounded-md bg-background-secondary flex flex-col w-fit md:w-full">
        <div className="flex items-center md:flex-wrap flex-nowrap">
          <FontAwesomeIcon icon={faBurgerSoda} />
          <h3 className="text-sm ml-2 whitespace-nowrap md:whitespace-normal">
            Total products
          </h3>
        </div>
        <h1 className="font-semibold text-lg md:text-2xl mt-1">
          {totalProducts || "-"}
        </h1>
      </div>
    </div>
  );
};

export default EmployeeStats;
