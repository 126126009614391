import { hasAccessToModule } from "@kanpla/system";
import { _FrontendModule } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

/** Figure out if the user has an admin access,
 * and therefore should be able to see admin functions
 * such as "Employee Orders" export */

export const useHasAdminAccess = () => {
  const { modules = [], child, school } = useContainer(AppContext);

  const hasBulkAccess = (module: _FrontendModule) => {
    // Don't show if that module doesn't have any admin codes
    // A code is required to access export function
    if (!module.config?.bulkCodes?.length) return false;

    if (!child || !school) return false;

    return hasAccessToModule({
      module,
      child,
      school,
      ignoreActive: true,
    }).bulk;
  };

  const hasAdminAccess = modules.some(
    (module) => module.paymentMethod === "billing" && hasBulkAccess(module)
  );

  return hasAdminAccess;
};
