import { T } from "@kanpla/system";
import { Alert } from "@kanpla/ui";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useIsDisabledForMinimumOrder } from "./useIsDisabledForMinimumOrder";

export const MinimumOrderAlert = () => {
  const { school } = useContainer(OrderingContext);
  const isDisabledForMinimumOrder = useIsDisabledForMinimumOrder();

  const { flexBulkMinimum } = school?.contract || {};

  if (!flexBulkMinimum) return null;

  return (
    <Alert
      type={isDisabledForMinimumOrder ? "danger" : "info"}
      message={
        <T
          minimum={<b>{flexBulkMinimum}</b>}
          zero={<b>0</b>}
          _str="The minimum order for each day is {minimum} products, you can either order {zero} or at least {minimum} products."
        />
      }
      className="my-6 text-sm !w-fit"
    />
  );
};
