import { Form } from "@kanpla/ui";
import { FC, ReactNode } from "react";
import { FieldValues } from "react-hook-form";
import { BasketInfoForm } from "../../mealplan2/basket/useBasketPurchaseLogic";

interface Props {
  children: ReactNode;
  onSubmit: (data: FieldValues) => void;
  basketInfoForm: BasketInfoForm;
}

export interface FormType {
  [moduleId: string]: {
    [dateSeconds: number]: {
      [key: string]: string;
    };
  };
}

export const OrderInfoFormWrapper: FC<Props> = ({
  children,
  onSubmit,
  basketInfoForm,
}) => (
  <Form<FormType> methods={basketInfoForm} onSubmit={onSubmit} id="basket-form">
    {children}
  </Form>
);
