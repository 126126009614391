import { getAllergensIntersection, useT } from "@kanpla/system";
import { Child, CombinedOfferItem, Supplier, Week } from "@kanpla/types";
import { Alert, Checkbox, DisplayAttributes, DrawerOrModal } from "@kanpla/ui";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { useLocalstorageState } from "rooks";

export interface AllergensWarningProps {
  product?: CombinedOfferItem;
  dayIndex?: number;
  child?: Child | null;
  week?: Week;
  supplier?: Supplier;
}

export type PromiseInfo = {
  resolve: (value: boolean) => void;
  reject: (reason?: unknown) => void;
} | null;

export const promiseInfoAllergensWarningAtom = atom<PromiseInfo>(null);
export const openAllergensWarningAtom = atom(false);
export const hideAllergensWarningAtom = atom(false);
export const propsAllergensWarningAtom = atom<AllergensWarningProps>({});

export const AllergensWarningModal = () => {
  const props = useAtomValue(propsAllergensWarningAtom);
  const { child, dayIndex, product, week, supplier } = props;

  const t = useT();

  const [_hide, _setHide] = useLocalstorageState(
    "kanpla:hide-allergens-modal",
    false
  );
  const [open, setOpen] = useAtom(openAllergensWarningAtom);
  const promiseInfo = useAtomValue(promiseInfoAllergensWarningAtom);
  const setHide = useSetAtom(hideAllergensWarningAtom);

  useEffect(() => {
    setHide(_hide);
  }, [_hide]);

  const dateSeconds = week?.[dayIndex as number]?.seconds;
  const menuItem = product?.dates?.[dateSeconds as number]?.menu;
  const allergens = getAllergensIntersection({ child, product, menuItem });

  const actions: Parameters<typeof DrawerOrModal>[0]["actions"] = [
    {
      label: t("Confirm"),
      onClick: () => {
        promiseInfo?.resolve(true);
        setOpen(false);
      },
      type: "warning",
    },
  ];

  const dismissModal = (state: boolean) => {
    promiseInfo?.resolve(false);
    setOpen(state);
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={(state) => dismissModal(state)}
      actions={actions}
      title={t("Allergen warning")}
      subtitle={t(
        "Be aware, this dish contains ingredients you are allergic to"
      )}
    >
      <div className="flex justify-center mb-4">
        <DisplayAttributes
          attributes={allergens}
          supplier={supplier}
          mode="all"
          className="!mx-0 !py-0"
        />
      </div>
      <Alert
        type="warning"
        message={t(
          "Always check allergens with relevant kitchen staff before eating"
        )}
      />
      <div className="mt-4">
        <Checkbox
          onChange={(event) => {
            const { checked } = event.target;
            _setHide(checked);
          }}
          checked={_hide}
          dataCy="allergens-warning-modal-hide"
        >
          {t("Don't show it again")}
        </Checkbox>
      </div>
    </DrawerOrModal>
  );
};
