import { DayIndex, OrderLines } from "@kanpla/types";
import { mapValues } from "lodash";
import { WeeklyOrders } from "../../data/atomWeeklyOrders";
import { useGetGlobalVariantForDay } from "./useGetGlobalVariantForDay";
import { useGlobalVariants } from "./useGlobalVariants";

interface Props {
  orders: WeeklyOrders;
}

export const useSetGlobalVariantToOrder = ({ orders }: Props) => {
  const getGlobalVariantForDay = useGetGlobalVariantForDay();
  const { isActive } = useGlobalVariants();

  const setGlobalVariantToADay = (
    orderLines: OrderLines,
    dayIndex: DayIndex
  ) => {
    if (!isActive) return orderLines;
    const choice = getGlobalVariantForDay(dayIndex);

    if (!choice) return orderLines;

    const newOrderLines = orderLines.map((orderLine) => {
      return {
        ...orderLine,
        options: {
          ...orderLine.options,
          [choice.id]: { id: choice?.id, name: choice?.name, amount: 1 },
        },
      };
    }) as OrderLines;

    return newOrderLines;
  };

  const transformOrders = mapValues(orders, (orderLines, dayIndex) =>
    setGlobalVariantToADay(orderLines, parseInt(dayIndex) as DayIndex)
  );

  return transformOrders;
};
