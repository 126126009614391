import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDayIndexFromSeconds } from "@kanpla/system";
import { OrderLines } from "@kanpla/types";
import { useT } from "@transifex/react";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { standardOrdersModalDataAtom } from "../standardOrder/StandardOrderModal";

interface NoLunchButtonProps {
  dateSeconds: number;
  onChange: (newOrder: OrderLines) => void;
  isFlexStandard?: boolean;
}

const NoLunchButton = ({
  dateSeconds,
  onChange,
  isFlexStandard,
}: NoLunchButtonProps) => {
  const t = useT();

  const { orders } = useContainer(OrderingContext);
  const standardOrderLines = useAtomValue(standardOrdersModalDataAtom);
  const dayIndex = getDayIndexFromSeconds(dateSeconds);

  const orderExists = orders.find((order) => order.dateSeconds === dateSeconds);

  const hasStandard = !isEmpty(standardOrderLines?.[dayIndex]);
  const hasNoOrder =
    (!orderExists && !hasStandard) ||
    (typeof orderExists?.orderLines !== "undefined" &&
      isEmpty(orderExists.orderLines));

  // No lunch is selected when an empty orderLine exists
  const isSelected = isFlexStandard ? !hasStandard : hasNoOrder;
  const isStandard = !isSelected && hasStandard;

  return (
    <div
      onClick={() => onChange([])}
      className={classNames(
        "px-5 py-4 mb-3 rounded transition-all bg-background-primary flex items-center justify-between gap-x-8 cursor-pointer border self-start",
        { "border-2 border-primary-main": isSelected && !isStandard },
        { "border-2 border-blue-600": isSelected && isStandard }
      )}
    >
      <div
        style={{
          width: "calc(100% - 32px - 32px)",
        }}
        className="flex gap-x-2"
      >
        <p>{t("No lunch")}</p>
      </div>
      <div
        style={{
          minWidth: "32px",
        }}
        className={classNames(
          "w-8 aspect-square rounded-full self-center flex items-center justify-center",
          {
            "bg-primary-main": isSelected && !isStandard,
            "bg-blue-600": isSelected && isStandard,
            "bg-secondary-main": !isSelected,
          }
        )}
      >
        {isSelected && (
          <FontAwesomeIcon icon={faCheck} color="white" className="text-lg" />
        )}
      </div>
    </div>
  );
};

export default NoLunchButton;
