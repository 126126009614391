import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import {
  faEllipsis,
  faEye,
  faPen,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderingContext } from "@kanpla/ordering";
import {
  SubmitMultipleOrdersProps,
  SubmitMultipleOrdersReturn,
} from "@kanpla/services";
import { callInternalApi, tx, useT } from "@kanpla/system";
import { Order, OrderLines, OrderPersonal } from "@kanpla/types";
import {
  BottomDrawer,
  Modal,
  ModalConfirm,
  PopperMenu,
  Space,
  message,
} from "@kanpla/ui";
import { DatePicker, Dropdown, Menu } from "antd";
import classNames from "classnames";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { IncomingOrder } from "libs/services/src/lib/ordering/sendMultipleOrders";
import moment, { Moment } from "moment";
import { Dispatch, SetStateAction, useState } from "react";
import { useContainer } from "unstated-next";
import { childIdAtom, moduleIdAtom, schoolIdAtom } from "../../context";
import { deadlineInfoAtom } from "../../lib/hooks/useDeadlineInfo";
import {
  meetingCurrentViewAtom,
  meetingEditingOrderAtom,
} from "../../mealplan2/meeting";
import { useSetBasket } from "../../shared/basket/useSetBasket";
import { receiptOrderAtom } from "./Receipt";

interface Props {
  order: Order;
  openSheet: boolean;
  setOpenSheet: Dispatch<SetStateAction<boolean>>;
  meetingView?: boolean;
  reloadOrders?: () => void;
}

const OrderOptions = ({
  order,
  openSheet,
  setOpenSheet,
  meetingView = false,
  reloadOrders,
}: Props) => {
  const t = useT();
  /**
   * Util to change the localization of moment.js
   * dow and doy explanation: https://github.com/moment/momentjs.com/issues/279
   */
  moment.updateLocale(tx.getCurrentLocale(), { week: { dow: 1, doy: 4 } });

  const { setDate, mobile, setChild, children } = useContainer(OrderingContext);
  const setBasket = useSetBasket();
  const setCurrentView = useSetAtom(meetingCurrentViewAtom);
  const setEditingOrder = useSetAtom(meetingEditingOrderAtom);
  const setModuleId = useSetAtom(moduleIdAtom);
  const setSchoolId = useSetAtom(schoolIdAtom);
  const [childId, setChildId] = useAtom(childIdAtom);

  const { isDateSecondsPastDeadline, defaultDateSeconds } =
    useAtomValue(deadlineInfoAtom);

  const [expanded, setExpanded] = useState<boolean>(false);

  const setReceipt = useSetAtom(receiptOrderAtom);
  const [duplicateDate, setDuplicateDate] = useState<Moment>(() => {
    return defaultDateSeconds
      ? moment.unix(defaultDateSeconds).startOf("day")
      : moment().startOf("day");
  });

  const [loading, setLoading] = useState(false);
  const [duplicateOpen, setDuplicateOpen] = useState<boolean>(false);

  const orderName =
    order.info?.name ||
    t("Order for {value}", {
      value: moment.unix(order.dateSeconds).format("D/M Y"),
    });

  const deleteOrder = async () => {
    await callInternalApi<
      SubmitMultipleOrdersProps,
      SubmitMultipleOrdersReturn
    >("ordering/submitMultipleOrders", {
      orders: [{ ...(order as IncomingOrder), orderLines: [] }],
    });

    message.success(t("Order was deleted."));
    reloadOrders?.();
  };

  const duplicateOrder = async () => {
    try {
      setLoading(true);

      const orderInfo = {
        ...(order.info || {}),
        name: t("Duplicate: {value}", { value: orderName }),
      };

      const momentDateSeconds = duplicateDate.utc(true).unix();

      // Validate the order
      const [validatedOrder] = await callInternalApi<
        {
          dateSeconds: number;
          childId: string;
          orderLines: OrderLines;
          moduleId: string;
        },
        Order[]
      >("ordering/validateOrder", {
        dateSeconds: momentDateSeconds,
        childId: (order as OrderPersonal).childId,
        orderLines: order.orderLines,
        moduleId: order.moduleId,
      });

      setDate({ momentDate: duplicateDate.utc(true) });
      setCurrentView("ordering");

      setBasket({
        orderLines: validatedOrder.orderLines,
        info: orderInfo,
        moduleId: validatedOrder.moduleId,
        dateSeconds: momentDateSeconds,
      });
    } catch (err: any) {
      console.error(err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const tooLateToChange = isDateSecondsPastDeadline(order?.dateSeconds);
  const disabledClassName =
    tooLateToChange && "filter grayscale pointer-events-none opacity-50";

  const editingOptions = (
    <Menu onClick={() => setExpanded(false)}>
      <Menu.Item
        key={0}
        className="py-2"
        onClick={() => setReceipt({ order, from: "history" })}
      >
        <Space direction="horizontal" align="center">
          <FontAwesomeIcon icon={faEye} />
          {t("View")}
        </Space>
      </Menu.Item>
      <Menu.Divider />
      {meetingView && (
        <>
          <Menu.Item
            key={1}
            className={`py-2 ${disabledClassName || ""}`}
            disabled={tooLateToChange}
            onClick={() => {
              const meetingOrder = order as OrderPersonal;

              // Adjust logic if I'm ordering for a different child/salesplace
              if (meetingOrder.childId !== childId) {
                // setChildId(meetingOrder.childId);
                // setSchoolId(meetingOrder.schoolId);
                setChildId(meetingOrder.childId);
                const foundChild = children?.find(
                  (c) => c?.id === meetingOrder.childId
                );

                if (!foundChild) {
                  message.error(
                    t("The original user of this order doesn't exist anymore")
                  );
                  return null;
                }

                setChild(foundChild);

                message.warning(
                  t(
                    "You are editing order for a different user. We've redirected you to {schoolName}.",
                    { schoolName: t("there") }
                  )
                );

                setTimeout(() => {
                  setModuleId(meetingOrder.moduleId);
                  setSchoolId(meetingOrder.schoolId);
                  setEditingOrder(meetingOrder);
                  setCurrentView("ordering");
                  setDate({ dateSeconds: meetingOrder.dateSeconds });
                }, 1000);

                return;
              }

              setEditingOrder(meetingOrder);
              setCurrentView("ordering");
              setDate({ dateSeconds: meetingOrder.dateSeconds });
            }}
          >
            <Space direction="horizontal" align="center">
              <FontAwesomeIcon icon={faPen} />
              {t("Edit")}
            </Space>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}

      {meetingView && (
        <>
          <Menu.Item
            key={2}
            className="py-2"
            onClick={() => setDuplicateOpen(true)}
          >
            <Space direction="horizontal" align="center">
              <FontAwesomeIcon icon={faCopy} />
              {t("Duplicate")}
            </Space>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <ModalConfirm
        content={t("Are you sure you want to remove the order?")}
        onConfirm={deleteOrder}
        zIndex={65}
      >
        <Menu.Item
          key={3}
          className={classNames("py-2", disabledClassName)}
          danger
          disabled={tooLateToChange}
        >
          <Space direction="horizontal" align="center">
            <FontAwesomeIcon icon={faTrash} />
            {t("Delete")}
          </Space>
        </Menu.Item>
      </ModalConfirm>
    </Menu>
  );

  if (mobile)
    return (
      <BottomDrawer
        open={openSheet}
        setOpen={setOpenSheet}
        noPadding
        showCloseButton
      >
        <PopperMenu className="text-center">
          <PopperMenu.Item
            key="view-action"
            className="!py-3 border-b border-secondary-light !text-lg"
            onClick={() => setReceipt({ order, from: "history" })}
          >
            {t("View")}
          </PopperMenu.Item>
          {meetingView && (
            <PopperMenu.Item
              key="edit-action"
              className="!py-3 border-b border-secondary-light !text-lg"
              isDisabled={tooLateToChange}
              onClick={() => {
                const newMoment = moment.unix(order?.dateSeconds);
                setDate({ momentDate: newMoment });

                setEditingOrder(order as OrderPersonal);
                setOpenSheet(false);
                setCurrentView("ordering");
              }}
            >
              {t("Edit")}
            </PopperMenu.Item>
          )}
          {meetingView && (
            <PopperMenu.Item
              key="duplicate-action"
              onClick={() => {
                setOpenSheet(false);
                setDuplicateOpen(true);
              }}
              className="!py-3 border-b border-secondary-light !text-lg"
            >
              {t("Duplicate")}
            </PopperMenu.Item>
          )}
          <ModalConfirm
            content={t("Are you sure you want to delete this booking?")}
            zIndex={65}
            onConfirm={async () => {
              await deleteOrder();
              setOpenSheet(false);
            }}
          >
            <PopperMenu.Item
              key="delete-action"
              className="!py-3 border-b border-secondary-light !text-lg"
              danger
              isDisabled={tooLateToChange}
            >
              {t("Delete")}
            </PopperMenu.Item>
          </ModalConfirm>
        </PopperMenu>
      </BottomDrawer>
    );

  return (
    <>
      <Dropdown
        overlay={editingOptions}
        trigger={["click"]}
        onOpenChange={(visible) => setExpanded(visible)}
        getPopupContainer={(trigger: HTMLElement) =>
          trigger.parentNode as HTMLElement
        }
      >
        <div
          className="cursor-pointer w-6 h-6 bg-background-primary rounded-full p-2 border border-secondary-dark hover:opacity-70 transition-opacity ease-in-out flex items-center justify-center relative"
          onClick={() => setExpanded((expanded) => !expanded)}
        >
          <FontAwesomeIcon icon={faEllipsis} />
        </div>
      </Dropdown>

      {duplicateOpen && (
        <Modal
          open={duplicateOpen}
          zMax
          setOpen={setDuplicateOpen}
          title={t("Duplicate order")}
          actions={[
            {
              label: t("Confirm"),
              onClick: duplicateOrder,
              className: "primary",
              loading,
            },
          ]}
        >
          <div className="flex justify-center pt-3">
            <label className="form-label mb-2">{t("New date")}</label>
            <DatePicker
              value={duplicateDate}
              allowClear={false}
              format={(m) => m.format("ll")}
              onChange={(d) =>
                setDuplicateDate(moment(d).utc(true).startOf("day"))
              }
              disabledDate={(d) =>
                d.get("day") === 0 ||
                d.get("day") === 6 ||
                (defaultDateSeconds || moment().add(1, "day").unix()) > d.unix()
              }
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default OrderOptions;
