import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@kanpla/system";
import classNames from "classnames";

interface AllowanceOverInfoProps {
  message?: string;
  type?: "info" | "danger";
}

const AllowanceOverInfo = ({
  message,
  type = "danger",
}: AllowanceOverInfoProps) => (
  <div
    className={classNames(
      "text-xs text-background-primary pb-1 font-medium px-2 pt-1 rounded-lg bg-opacity-70 mt-1 flex items-center gap-x-1",
      type === "info" ? "bg-info-main" : "bg-danger-main"
    )}
  >
    <FontAwesomeIcon icon={faExclamationCircle} />
    <T
      _str={message || "You don't have enough allowance left for this product"}
    />
  </div>
);

export default AllowanceOverInfo;
