import { useT } from "@transifex/react";
import { Input } from "antd";
import React, { useEffect, useState } from "react";

type VatRateInputProps = {
  value?: number;
  onChange?: (value: number | null) => void;
  placeholder?: number;
};

export const VatRateInput = ({
  value,
  onChange = () => null,
  placeholder,
}: VatRateInputProps) => {
  const t = useT();
  const [text, setText] = useState("");
  const handleOnChange = (newText: string) => {
    const decimal = newText.length ? parseFloat(newText) / 100 : null;
    onChange(decimal);
  };

  useEffect(() => {
    if (typeof value !== `number` || isNaN(value)) return;
    const percentage = value * 100;
    const roundedToTwoDecimals = Math.round(percentage * 100) / 100;
    setText(`${roundedToTwoDecimals}`);
  }, [value]);

  return (
    <Input
      type="text"
      value={text}
      onChange={(e) => {
        const newText = e.target.value;
        setText(newText);
        handleOnChange(newText);
      }}
      placeholder={placeholder || t("Enter VAT rate")}
      addonAfter={"%"}
    />
  );
};
