import { faGift } from "@fortawesome/pro-solid-svg-icons";
import { T, useFetch, useSubmit, useT } from "@kanpla/system";
import { Reference } from "@kanpla/types";
import {
  ButtonLine,
  DrawerOrModal,
  ExpandableText,
  Input,
  PrivacyPolicy,
  TermsOfTrade,
  message,
} from "@kanpla/ui";
import { Form } from "antd";
import {
  LoadReferencesProps,
  LoadReferencesResult,
} from "apps/frontend/pages/api/internal/load/references";
import { SubmitReferenceProps } from "apps/frontend/pages/api/internal/submit/reference";
import { useAtomValue } from "jotai";
import { capitalize, isEmpty, omit } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext, paymentGatewayIdAtom } from "../../../contextProvider";

const Refer = () => {
  const { partnerId, userId, schoolId, user, partner, supplier } =
    useContainer(AppContext);
  const paymentGatewayId = useAtomValue(paymentGatewayIdAtom);

  const t = useT();

  const [open, setOpen] = useState(false);

  const [form] = Form.useForm();

  const hasReferencePlugin = partner?.proPlugins?.reference;

  const { buttonLineLabel, modalSubtitle, modalTitle, customTerms } =
    partner?.proPluginsConfigurations?.reference || {};

  const onInputProp = {
    onInput: (e) => (e.target["value"] = capitalize(e.target["value"])),
  };

  const { data, setData } = useFetch<LoadReferencesProps, LoadReferencesResult>(
    "load/references",
    { userId, partnerId },
    { revalidateOnFocus: false, shouldRetryOnError: false }
  );

  const { submit: submitReference } = useSubmit<
    SubmitReferenceProps,
    Reference[],
    typeof setData
  >({
    path: "submit/reference",
    requestConstructor: (references) => ({ data: references, userId }),
    responseDestructor: (references) => {
      return {
        references,
        groupedReferences: data?.groupedReferences,
      };
    },
  });

  const submit = async () => {
    try {
      const values = await form.validateFields();

      const reference = {
        ...omit(values, ["phoneNumber", "prefix"]),
        userEmail: user?.email,
        userId,
        partnerId,
        schoolId,
        dateSeconds: moment().unix(),
        phone: {
          phoneNumber: values?.phoneNumber || "",
          prefix: Number(values?.prefix?.replace("+", "")) || 45,
        },
      } as Reference;

      await submitReference([reference]);

      message.success({
        key: "success-reference",
        content: t("The data have been sent"),
      });
    } catch (error) {
      const isFromForm = error?.errorFields;

      if (isFromForm) return;

      message.error({
        key: "error-reference",
        content: t("Something went wrong"),
      });
    } finally {
      setOpen(false);
      form.resetFields();
    }
  };

  if (!hasReferencePlugin) return null;

  return (
    <>
      <ButtonLine
        label={
          buttonLineLabel ||
          t("Refer {name}", { name: supplier?.name || "Kanpla" })
        }
        onClick={() => setOpen(true)}
        icon={faGift}
        dataCy="refer-btn"
      />
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        actions={[
          {
            type: "default",
            label: t("Cancel"),
            onClick: () => setOpen(false),
          },
          {
            type: "primary",
            label: t("Confirm"),
            onClick: () => submit(),
          },
        ]}
        title={t(modalTitle)}
        subtitle={t(modalSubtitle)}
      >
        <div>
          <Form form={form} layout="vertical">
            {(_, formInstance) => (
              <>
                <div className="flex gap-x-3">
                  <Form.Item
                    rules={[{ required: true, message: t("Enter a name") }]}
                    name="name"
                  >
                    <Input
                      label={t("Name")}
                      placeholder={t("Type a name")}
                      error={!isEmpty(formInstance.getFieldError("name"))}
                      required
                      {...onInputProp}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      { required: true, message: t("Enter a last name") },
                    ]}
                    name="lastName"
                  >
                    <Input
                      label={t("Last name")}
                      placeholder={t("Type a last name")}
                      error={!isEmpty(formInstance.getFieldError("lastName"))}
                      required
                      {...onInputProp}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  rules={[
                    { required: true, message: t("Enter an email") },
                    { type: "email", message: t("Email not valid") },
                  ]}
                  name="email"
                >
                  <Input.Email
                    placeholder={t("example@gmail.com")}
                    error={!isEmpty(formInstance.getFieldError("email"))}
                    required
                  />
                </Form.Item>
                <Form.Item>
                  <div className="flex gap-x-3">
                    <Form.Item name="prefix" className="!mb-0">
                      <Input label={t("Prefix")} defaultValue="+45" />
                    </Form.Item>
                    <Form.Item name="phoneNumber" className="w-full !mb-0">
                      <Input
                        label={t("Phone number")}
                        placeholder={t("Type a phone number")}
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item name="additionalInfo" className="mb-0">
                  <Input
                    label={t("Additional info")}
                    placeholder={t("Type some additional info")}
                    maxLength={300}
                  />
                </Form.Item>
              </>
            )}
          </Form>
          {customTerms && (
            <div className="pt-6">
              <ExpandableText content={customTerms} />
            </div>
          )}
          <div className="pt-3">
            <p className="text-xs text-text-secondary">
              <T
                _str="By clicking “Confirm” and submitting the reference, you accept our {termsOfUse} and {privacyPolicy}"
                termsOfUse={
                  <TermsOfTrade paymentGatewayId={paymentGatewayId} />
                }
                privacyPolicy={
                  <PrivacyPolicy paymentGatewayId={paymentGatewayId} />
                }
              />
            </p>
          </div>
        </div>
      </DrawerOrModal>
    </>
  );
};

export default Refer;
