import { db, Timestamp } from "@kanpla/system";
import { EventTracking, _FrontendModule } from "@kanpla/types";
import { IntroModal } from "@kanpla/ui";
// import * as Sentry from "@sentry/nextjs";
import { OrderingContext } from "@kanpla/ordering";
import { useT } from "@kanpla/system";
import * as Sentry from "@sentry/nextjs";
import { isEmpty } from "lodash";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { useContainer } from "unstated-next";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  hideSkipQueue?: boolean;
  module?: _FrontendModule;
  automaticallyOpen?: boolean;
}

const KanplaGoIntro = ({
  open,
  setOpen,
  module,
  hideSkipQueue = false,
  automaticallyOpen,
}: Props) => {
  const t = useT();
  const { childId, userId, schoolId } = useContainer(OrderingContext);

  const allowAutomaticOpening =
    automaticallyOpen && childId && module?.id && userId;

  const [
    event = [{ title: "To start with" }],
    eventLoading = true,
    eventError,
  ] = useCollectionDataOnce<EventTracking>(
    allowAutomaticOpening
      ? db
          .collection("eventTracking")
          .where("type", "==", "kanplaGo")
          .where("componentId", "==", module.id)
          .where("childId", "==", childId)
          .where("schoolId", "==", schoolId)
          .where("userId", "==", userId)
      : null
  );

  useEffect(() => {
    if (eventError) console.error(eventError);
  }, [eventError]);

  const registerEvent = async () => {
    try {
      await db.collection("eventTracking").add({
        type: "kanplaGo",
        componentId: module.id,
        childId,
        userId,
        schoolId,
        created_at: Timestamp.now().seconds,
      });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    if (!automaticallyOpen) return;

    if (!allowAutomaticOpening) return;

    if (eventLoading) return;

    if (!isEmpty(event)) return;

    setOpen(true);
    registerEvent();
  }, [eventLoading, automaticallyOpen, allowAutomaticOpening]);

  const data = Object.entries(module?.text || {}).reduce(
    (acc, [key, value], i) => {
      const split = key.split("/");
      if (split[0] !== "kanplaGoModal") return acc;
      if (split[1] === "title") return acc;
      if (split[1] === "subtitle") return acc;

      const index = split[1].replace("step", "");
      return { ...acc, [index]: { ...acc[index], [split[2]]: value } };
    },
    {}
  );

  // Create default data
  const defaultData = {
    1: {
      title: "Grab 🙌",
      description: t("Take your food in the canteen."),
    },
    2: {
      title: "Buy 💳",
      description: t(
        "Add your card, place the product in the basket, swipe and pay via the app."
      ),
    },
    3: {
      title: t("Skip the queue 😋"),
      description: t("Save time for you and others."),
    },
    4: {
      title: "Go ✅",
      description: t("Show receipt to the canteen staff."),
    },
  };

  if (hideSkipQueue) delete defaultData[3];
  if (Object.values(data).length === 3) delete defaultData[4];

  const finalData = { ...defaultData, ...(data || {}) };

  return (
    <IntroModal
      title={module?.text?.["kanplaGoModal/title"] || t("That's how it works")}
      subtitle={module?.text?.["kanplaGoModal/subtitle"]}
      data={Object.values(finalData)}
      open={open}
      setOpen={setOpen}
    />
  );
};

export default KanplaGoIntro;
