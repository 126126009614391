import { useT } from "@kanpla/system";
import { Homescreen } from "@kanpla/types";
import { Image as KanplaImage } from "@kanpla/ui";

interface Props {
  singleBlock: Homescreen.ContentInner;
}

const Image = ({ singleBlock }: Props) => {
  const t = useT();

  if (!singleBlock || !singleBlock.props) return null;

  const { imageUrl, altText } = singleBlock.props;

  if (!imageUrl) return null;

  return (
    <figure className="rounded-lg overflow-hidden">
      <KanplaImage
        src={imageUrl}
        alt={altText || t("An image without description")}
        className="w-full"
      />
    </figure>
  );
};

export default Image;
