import { tx } from "@kanpla/system";
import { useAtomValue } from "jotai";
import moment from "moment";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import { deadlineInfoAtom } from "../lib/hooks/useDeadlineInfo";

const UIJumpLink = () => {
  // Util to change the localization of moment.js
  const { setDate } = useContainer(OrderingContext);
  const { defaultDateSeconds } = useAtomValue(deadlineInfoAtom);

  return (
    <button
      className="text-primary-main font-medium border-b-2 -mb-1 border-primary-light"
      onClick={() =>
        defaultDateSeconds && setDate({ dateSeconds: defaultDateSeconds })
      }
    >
      {defaultDateSeconds &&
        moment
          .unix(defaultDateSeconds)
          .locale(tx.getCurrentLocale())
          .format("dddd, D/M")}
    </button>
  );
};

export default UIJumpLink;
