import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T, t } from "@kanpla/system";
import React, { FC } from "react";
import { Button, ButtonProps } from "./Button";

type Props = ButtonProps & {
  label?: string;
  saved?: boolean;
  onClick?: () => void;
};

export const ButtonSave: FC<Props> = (props: Props) => {
  const { label, onClick, saved = true, loading = false, ...rest } = props;

  if (saved)
    return (
      <Button {...rest} disabled>
        <FontAwesomeIcon icon={faSave} className="mr-2" /> {t("Saved")}
      </Button>
    );

  return (
    <Button
      type="primary"
      onClick={onClick}
      loadingText={t("Saving")}
      {...rest}
      data-cy="save-btn"
      loading={loading}
    >
      {!loading && <FontAwesomeIcon icon={faSave} className="mr-1" />}{" "}
      {label ? label : <T _str="Save" />}
    </Button>
  );
};
