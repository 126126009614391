import { useT } from "@kanpla/system";
import { CombinedOfferItem, OrderLine, StandardOrderLine } from "@kanpla/types";
import classNames from "classnames";
import CategoryProductList from "./CategoryProductList";

export interface CategoryProps {
  key: string;
  products: CombinedOfferItem[];
  category?: string;
  orderLines: OrderLine[] | StandardOrderLine[];
  dateSeconds: number;
  disabled?: boolean;
  onChange: (newOrder: OrderLine) => void;
}

const Category = ({
  key,
  products,
  orderLines,
  dateSeconds,
  category,
  onChange,
  disabled = false,
}: CategoryProps) => {
  const t = useT();

  return (
    <div
      key={key}
      className={classNames("md:bg-background-secondary rounded", {
        "pointer-events-none": disabled,
      })}
    >
      <div className="px-3 py-2">
        <h4 className="text-2xl md:text-xl text-text-primary font-medium md:font-normal mb-2.5 md:mb-4 pb-3 md:p-0 border-b md:border-none border-divider-main">
          {category || t("Dish of the day")}
        </h4>
        <CategoryProductList
          products={products}
          orderLines={orderLines}
          dateSeconds={dateSeconds}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Category;
