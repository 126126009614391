import { DayIndex } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { firstColumnStyles } from "./Week";
import { WeekDateDisplayItem } from "./WeekDateDisplayItem";

export const WeekDateDisplay = () => {
  const { week } = useContainer(OrderingContext);

  return (
    <div className="flex flex-wrap mb-2 px-4">
      <div className={`${firstColumnStyles}`}></div>
      {week.map((date, dayIndex) => {
        return (
          <WeekDateDisplayItem
            key={String(date.seconds + dayIndex)}
            dayIndex={dayIndex as DayIndex}
            dateSeconds={date.seconds}
          />
        );
      })}
    </div>
  );
};
