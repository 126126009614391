import { faPen } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { OrderPersonal } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import {
  basketEditModeAtom,
  isOrderEditingAtom,
  openBasketAtom,
} from "../../shared/basket/useBasket";
import { useSetBasket } from "../../shared/basket/useSetBasket";
import { useBasketPreventClick } from "../../shared/navbar/BasketPreventClick";

interface Props {
  orderDocument: OrderPersonal;
}

export const EditButton = ({ orderDocument }: Props) => {
  const { module, hasKanplaGo } = useContainer(OrderingContext);
  const confirm = useBasketPreventClick({});
  const setBasket = useSetBasket();

  const setEditMode = useSetAtom(basketEditModeAtom);
  const setIsOrderEditingAtom = useSetAtom(isOrderEditingAtom);

  const setOpenBasket = useSetAtom(openBasketAtom);

  const t = useT();

  return (
    <Button
      onClick={async () => {
        await confirm?.(module);
        setOpenBasket(true);
        setBasket(orderDocument);
        setEditMode(true);
        setIsOrderEditingAtom(true);
      }}
      disabled={hasKanplaGo}
      shape="soft"
      size="small"
      dataCy="btn-receipt-edit"
    >
      <FontAwesomeIcon icon={faPen} className="mr-2" />
      {t("Edit")}
    </Button>
  );
};
