import { useEffect, useState } from "react";
import { isNative } from ".";

type NativeProperty =
  | "hasAnsweredToNotificationPermissions"
  | "hasAnsweredToTrackingPermissions"
  | "trackingPermissionsGranted";

const getPermissionResponse = (nativeProperty: NativeProperty): boolean => {
  if (typeof window === undefined) return true;

  const valueToCheck = window?.[nativeProperty];
  if (valueToCheck === undefined) return true;

  if (valueToCheck === undefined || JSON.parse(valueToCheck) === false)
    return false;

  return true;
};

/**
 * Handle native permissions from the native wrapper
 * @returns utilities to check or request native permissions (notifications, tracking)
 */
export const usePermissions = () => {
  const [notificationPermissions, setNotificationPermissions] =
    useState<boolean>(false);
  const [trackingPermissions, setTrackingPermissions] =
    useState<boolean>(false);

  const hasAnsweredToNotificationPermissions = getPermissionResponse(
    "hasAnsweredToNotificationPermissions"
  );

  const hasAnsweredToTrackingPermissions = getPermissionResponse(
    "hasAnsweredToTrackingPermissions"
  );

  const hasAllowedNotifications = Boolean(isNative && window?.iOSTokenExpo);

  const hasAllowedTracking = getPermissionResponse(
    "trackingPermissionsGranted"
  );

  const requestNotification = () => {
    window?.ReactNativeWebView.postMessage(
      JSON.stringify({ askForNotifications: true })
    );
  };

  const requestTraking = () => {
    window?.ReactNativeWebView.postMessage(
      JSON.stringify({ askForTracking: true })
    );
  };

  const handleEventFromWebView = async (event: MessageEvent) => {
    const { data } = event;
    if (typeof data !== "string") return;

    const shouldParse =
      data.includes("answeredToNotificationsPermissions") ||
      data.includes("answeredToTrackingPermissions");
    const parsedData = shouldParse ? JSON.parse(data) : {};

    if (parsedData?.answeredToNotificationsPermissions)
      return setNotificationPermissions(true);

    if (parsedData?.answeredToTrackingPermissions)
      return setTrackingPermissions(true);
  };

  useEffect(() => {
    if (!isNative) return;
    window.addEventListener("message", handleEventFromWebView);
    return () => window.removeEventListener("message", handleEventFromWebView);
  }, []);

  return {
    notificationPermissions,
    trackingPermissions,
    requestNotification,
    requestTraking,
    hasAnsweredToNotificationPermissions,
    hasAnsweredToTrackingPermissions,

    hasAllowedNotifications,
    hasAllowedTracking,
  };
};
