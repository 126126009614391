import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateProductPrice, getUnitPrice, useT } from "@kanpla/system";
import { Module, School, Supplier } from "@kanpla/types";
import {
  DisplayAttributes,
  PriceFormatter,
  hidePricesAtom,
  usePriceFormatter,
} from "@kanpla/ui";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { ReactNode } from "react";
import { FlexModule } from "./FlexModule";
import { ProductProps } from "./Product";
import { ProductPhoto } from "./ProductPhoto";
import { ProductPreviewRow } from "./ProductPreviewRow";

interface PreviewProps
  extends Pick<
    ProductProps,
    "product" | "isBig" | "disabled" | "stock" | "previewOnly"
  > {
  className?: string;
  children?: ReactNode;
  view?: "grid" | "table" | "menu";
  schools?: School[];
  isSelected?: boolean;
  showLabels?: boolean;
  onClick?: () => void;
  currentDayTimestamp?: number;
  supplier?: Supplier;
  module: Module;
}

export const ProductPreview = ({
  product,
  previewOnly,
  view,
  currentDayTimestamp = 0,
  supplier,
  module,
  className = "",
  isBig = false,
  disabled = false,
  isSelected = false,
  showLabels = true,
  stock = NaN,
  children = <></>,
  onClick = () => null,
}: PreviewProps) => {
  const hidePrices = useAtomValue(hidePricesAtom);

  const productPrice = calculateProductPrice({
    product,
    module,
  });

  const t = useT();

  // For display more information about the product in the current day of a menu
  const currentDay = product?.dates?.[currentDayTimestamp];

  const handleClick = () => !disabled && onClick();

  const hasDiscount = product?.originalPrice || null;
  // For leftovers products with no discount (already applied to price)
  const discountIsSameAsPrice =
    product?.originalPrice === getUnitPrice(product);
  const showDiscountUI = !discountIsSameAsPrice && hasDiscount;

  const discountAmountFormattedConditional = usePriceFormatter(
    showDiscountUI ? hasDiscount : (productPrice as number)
  );
  const discountAmountFormatted = usePriceFormatter(hasDiscount || 0);

  if (view === "menu")
    return (
      <FlexModule
        product={product}
        onClick={handleClick}
        dateSeconds={currentDayTimestamp}
        supplier={supplier}
      />
    );

  if (view === "table")
    return (
      <ProductPreviewRow
        product={product}
        onClick={onClick}
        disabled={disabled}
        className={className}
      >
        {children}
      </ProductPreviewRow>
    );

  const showStock =
    product?.isLeftover && stock !== Infinity && stock !== 0 && !isNaN(stock);

  const stockBg = () => {
    if (stock < 4) return "bg-danger-main";
    if (stock > 3 && stock < 6) return "bg-yellow-400";
    return "bg-primary-main";
  };

  const productNameBase = currentDay?.menu?.name || product?.name;

  const productName =
    productNameBase?.length > 50
      ? `${productNameBase.substring(0, 50)}...`
      : productNameBase;

  return (
    <div
      onClick={handleClick}
      id={`product_preview_${product?.id}`}
      className={classNames(
        "w-full h-full flex flex-col justify-start relative group",
        className,
        disabled ? "cursor-not-allowed" : previewOnly ? "" : "cursor-pointer"
      )}
    >
      <div
        className="rounded-lg"
        style={{
          boxShadow:
            "0px 4px 84px rgba(0, 0, 0, 0.02), 0px 4px 14px -4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          className={`h-0 w-full overflow-hidden relative rounded-lg bg-gradient-to-bl from-gray-300 to-background-secondary`}
          style={{
            paddingBottom: isBig ? "54%" : "60%",
            WebkitMaskImage: "-webkit-radial-gradient(white, black)",
            backgroundImage: product?.color
              ? `linear-gradient(${product.color} 28.13%, ${product.color} 100%)`
              : `linear-gradient(rgba(0, 0, 0, 0) 28.13%, rgba(0, 0, 0, 0.45) 100%)`,
          }}
        >
          {isSelected && (
            <div className="absolute z-10 top-0 left-0 w-auto h-auto bg-primary-main rounded-br-md px-4 py-2 transition-all ease-in">
              <FontAwesomeIcon icon={faCheck} color="#FFFFFF" />
            </div>
          )}
          <div
            className={`absolute inset-0 transition-all duration-500 ${
              disabled
                ? "grayscale filter cursor-not-allowed opacity-60"
                : previewOnly
                ? ""
                : "cursor-pointer"
            } ${isSelected && "opacity-50"}`}
          >
            <ProductPhoto
              src={product.photo}
              name={productName}
              disabled={disabled}
              supplier={supplier}
            />
          </div>

          {isBig && (
            <div
              className={`absolute inset-0 rounded-lg ${
                disabled
                  ? "cursor-not-allowed"
                  : previewOnly
                  ? ""
                  : "cursor-pointer"
              }`}
              style={{
                backgroundImage:
                  product.color ||
                  `linear-gradient(rgba(0, 0, 0, 0.08) 28.13%, rgba(0, 0, 0, 0.6) 100%)`,
              }}
            />
          )}

          {showLabels && (
            <DisplayAttributes
              mode="tag"
              attributes={product?.labels}
              supplier={supplier}
            />
          )}
          {showStock && !isBig && (
            <div className={`absolute bottom-2 right-2`}>
              <div
                className={`px-2 py-1 ${stockBg()} text-sm rounded-md text-background-primary flex items-center`}
              >
                {t("{value} left", { value: stock })}
              </div>
            </div>
          )}
        </div>
      </div>
      {isBig ? (
        <div className="absolute py-4 px-5 bottom-0 left-0">
          {showStock && (
            <div className="w-auto flex mb-1">
              <div
                className={`px-2 py-1 ${stockBg()} text-sm rounded-md text-background-primary`}
              >
                {t("{value} left", { value: stock })}
              </div>
            </div>
          )}
          <h3 className="text-white text-lg pt-2 leading-tight font-medium">
            {productName}
          </h3>
          {hidePrices ? null : (
            <div className="flex items-baseline">
              <p
                className={`text-white ${
                  showDiscountUI ? "line-through" : ""
                } text-sm md:text-base font-semibold pt-1`}
              >
                {discountAmountFormattedConditional}
              </p>
              {showDiscountUI && (
                <p className="text-text-primary ml-2 font-semibold text-xs sm:text-sm pt-1">
                  {product.pricePrefix}
                  <PriceFormatter price={productPrice} />
                  {product.priceSuffix || ""}
                </p>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <h3 className="text-text-primary body-main pt-2 ">{productName}</h3>
          {hidePrices ? null : (
            <div className="flex items-baseline">
              <p
                className={`${
                  showDiscountUI
                    ? "text-text-disabled line-through"
                    : "text-primary-main"
                } text-sm pt-1`}
              >
                {discountAmountFormattedConditional}
                {product?.priceSuffix || ""}
              </p>
              {showDiscountUI && (
                <p className="text-primary-main text-sm font-semibold ml-2">
                  {discountAmountFormatted}
                </p>
              )}
            </div>
          )}
        </div>
      )}

      {children}
    </div>
  );
};
