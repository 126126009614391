import { faEye } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { OrderPersonal, _FrontendModule } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import {
  checkoutItemsAtom,
  kanplaGoReceiptOpenAtom,
  receiptTimeAtom,
} from "../kanplaGo/useKanplaGoListener";

interface Props {
  orderDocument: OrderPersonal;
  module: _FrontendModule;
}

export const OpenTicketButton = ({ orderDocument, module }: Props) => {
  const { orderLines, updatedAtSeconds } = orderDocument;

  const t = useT();
  const { hasKanplaGo } = useContainer(OrderingContext);
  const setReceiptOpen = useSetAtom(kanplaGoReceiptOpenAtom);
  const setReceiptTime = useSetAtom(receiptTimeAtom);
  const setCheckoutItems = useSetAtom(checkoutItemsAtom);

  const shouldShow = hasKanplaGo || module.flow === "registering";
  if (!shouldShow) return null;

  return (
    <Button
      onClick={() => {
        setCheckoutItems(orderLines || []);
        setReceiptOpen(true);
        setReceiptTime(updatedAtSeconds || 0);
      }}
      shape="soft"
      size="small"
      dataCy="btn-receipt-show-last-receipt"
    >
      <FontAwesomeIcon icon={faEye} className="mr-2" />
      {t("Show ticket")}
    </Button>
  );
};
