import {
  faClockRotateLeft,
  faCreditCard,
  faEnvelopeDot,
} from "@fortawesome/pro-solid-svg-icons";
import { CardsManage } from "@kanpla/ordering";
import { useT } from "@kanpla/system";
import { ButtonLine } from "@kanpla/ui";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useContainer } from "unstated-next";
import SuperadminSwitch from "../../SuperadminSwitch";
import { AppContext } from "../../contextProvider";
import Notifications from "../../modals/Notifications";
import History from "../history";
import Language from "./Language";
import Logout from "./Logout";
import Refer from "./Refer";
import Settings from "./Settings";
import TermsAndConditions from "./TermsAndConditions";

const UserInfo = () => {
  const { modules } = useContainer(AppContext);

  const t = useT();
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [manageCardsOpen, setManageCardsOpen] = useState<boolean>(false);
  const [accountHistoryOpen, setAccountHistoryOpen] = useState<boolean>(false);

  const creditModules = !isEmpty(
    (modules || []).filter((m) => {
      if (m.paymentMethod === "billing") return false;
      if (m.type !== "mealplan") return false;

      return true;
    })
  );

  return (
    <div>
      <h2 className="text-sm uppercase font-semibold text-text-secondary mb-2">
        {t("Settings")}
      </h2>

      <Refer />

      <ButtonLine
        label={t("Account history")}
        onClick={() => setAccountHistoryOpen(true)}
        dataCy="history-btn"
        icon={faClockRotateLeft}
      />

      <ButtonLine
        label={t("Mail preferences")}
        onClick={() => setNotificationsOpen(true)}
        icon={faEnvelopeDot}
        dataCy="email-preferences-btn"
      />

      {creditModules && (
        <ButtonLine
          label={t("My cards")}
          onClick={() => setManageCardsOpen(true)}
          icon={faCreditCard}
          dataCy="cards-preferences-btn"
        />
      )}

      <History open={accountHistoryOpen} setOpen={setAccountHistoryOpen} />

      <Language />

      <Settings />

      <TermsAndConditions />

      <SuperadminSwitch />

      <Logout />

      <Notifications open={notificationsOpen} setOpen={setNotificationsOpen} />
      <CardsManage open={manageCardsOpen} setOpen={setManageCardsOpen} />
    </div>
  );
};

export default UserInfo;
