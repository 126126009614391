import { OrderingContext } from "@kanpla/ordering";
import { useT } from "@kanpla/system";
import { useAtomValue } from "jotai";
import { isEmpty, sortBy } from "lodash";
import { FC } from "react";
import { FieldValues } from "react-hook-form";
import { useContainer } from "unstated-next";
import { BasketListTextItem } from "../../lib/BasketList/BasketListTextItem";
import { OrderInfoFormWrapper } from "../../lib/BasketList/OrderInfoFormWrapper";
import { OrderInfoItem } from "../../lib/BasketList/OrderInfoItem";
import { basketAtom } from "../../shared/basket/useBasket";
import { useSetBasket } from "../../shared/basket/useSetBasket";
import Payment from "../basket/elements/Payment";
import PriceOverview from "../basket/elements/PriceOverview";
import { BasketInfoForm } from "../basket/useBasketPurchaseLogic";

interface Props {
  onSubmit: (data: FieldValues) => void;
  basketInfoForm: BasketInfoForm;
}

const OrderPreview: FC<Props> = ({ onSubmit, basketInfoForm }) => {
  const { module, dateSeconds } = useContainer(OrderingContext);
  const basket = useAtomValue(basketAtom);
  const setBasket = useSetBasket();

  const t = useT();

  if (isEmpty(basket))
    return (
      <div className="w-full mt-24">
        {/* <h1 className="heading-main">{t("Registration info")}</h1>
        <p className="text-text-disabled mt-1">
          {t(
            "Nothing is selected yet. Mark one or more of the options above by pressing the checkbox"
          )}
        </p> */}
      </div>
    );

  return (
    <div className="w-full flex flex-col transition-all ease-linear">
      <h1 className="heading-main my-2">{t("Basket")}</h1>
      {sortBy(basket, "name").map((item) => {
        return (
          <div key={item.productId}>
            <BasketListTextItem item={item} dateSeconds={dateSeconds} />
          </div>
        );
      })}
      <div className="mt-2">
        <OrderInfoFormWrapper
          onSubmit={onSubmit}
          basketInfoForm={basketInfoForm}
        >
          <OrderInfoItem
            module={module}
            dateSeconds={dateSeconds}
            basket={basket}
            setBasket={setBasket}
          />
        </OrderInfoFormWrapper>
      </div>
      {/* Price overview */}
      <PriceOverview />
      <div className="mt-2">
        <Payment />
      </div>
    </div>
  );
};

export default OrderPreview;
