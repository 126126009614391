import {
  faFileMagnifyingGlass,
  faPrint,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import { OrdersModal } from "@kanpla/ordering";
import { hasAccessToModule, useT } from "@kanpla/system";
import { _FrontendModule } from "@kanpla/types";
import { ButtonLine } from "@kanpla/ui";
import { FlexBulkHolidaysModal } from "libs/ordering/src/flexBulk/FlexBulkHolidaysModal";
import FlexBulkUsersOverview from "libs/ordering/src/flexBulk/usersOverview";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import MultipleModulePrint from "../flex/MultipleModulePrint";
import SinglePrint from "../flex/MultipleModulePrint/SinglePrint";
import { useHasAdminAccess } from "../settings/useHasAdminAccess";

interface Props {
  availableModules: Array<_FrontendModule>;
  /** If the component is rendered from the user settings */
  fromUserSettings?: boolean;
}

const AdminOverview = ({
  availableModules,
  fromUserSettings = false,
}: Props) => {
  const t = useT();

  const { modules, school, child, week, module } = useContainer(AppContext);

  // Offering
  const hasAdminAccess = useHasAdminAccess();

  const [openOrdersHistory, setOpenOrdersHistory] = useState(false);

  const [access, setAccess] = useState<{
    hasBulk: boolean;
    hasIndividual: boolean;
  }>({
    hasBulk: false,
    hasIndividual: false,
  });

  const flexModules = (modules || []).filter((m) => m.type === "flex");

  const shouldHideButtons =
    module.type === "flex" &&
    module.flow !== "menuPreview" &&
    module.shouldHideMenu;

  const flexModulesToPrint = flexModules.filter(
    (m) => !m?.shouldHideMenu && m.type === "flex"
  );

  const moduleTrigger = sortBy(availableModules, "name")
    ?.filter((m) => m.type === "flex")
    ?.map((m) => m.id)
    .join("-");

  useEffect(() => {
    if (!child || !school) return;

    let hasBulk = false,
      hasIndividual = false;

    availableModules.map((m) => {
      const { bulk, individual } = hasAccessToModule({
        module: m,
        child,
        school,
      });

      if (bulk) hasBulk = true;
      if (individual) hasIndividual = true;

      return false;
    });

    setAccess({
      hasBulk,
      hasIndividual,
    });
  }, [moduleTrigger, school?.id, child?.id]);

  if (!access?.hasBulk && !access?.hasIndividual) return null;
  if (!week) return null;

  return (
    <div>
      <h2 className="text-sm uppercase font-semibold text-text-secondary mb-2">
        {hasAdminAccess ? t("Admin overview") : t("Lunch ordering")}
      </h2>

      {hasAdminAccess ? (
        <ButtonLine
          onClick={() => setOpenOrdersHistory(true)}
          label={t("Orders overview")}
          icon={faUsers}
        />
      ) : null}

      {flexModulesToPrint?.length > 1 && !shouldHideButtons && (
        <MultipleModulePrint flexModulesToPrint={flexModulesToPrint} />
      )}

      {flexModulesToPrint?.length === 1 ? (
        <>
          <SinglePrint moduleId={flexModulesToPrint[0].id}>
            <ButtonLine
              label={t("Print this week's menu")}
              icon={faPrint}
              inBetween
            />
          </SinglePrint>
          <SinglePrint moduleId={flexModulesToPrint[0].id} isDigital>
            <ButtonLine
              label={t("View this week's menu")}
              icon={faFileMagnifyingGlass}
              inBetween
            />
          </SinglePrint>
        </>
      ) : null}
      <FlexBulkHolidaysModal fromUserSettings={fromUserSettings} />
      <FlexBulkUsersOverview />
      <OrdersModal
        open={openOrdersHistory}
        setOpen={setOpenOrdersHistory}
        viewEmployeeOrders
      />
    </div>
  );
};

export default AdminOverview;
