import {
  TIMEZONE_DEFAULT,
  Timestamp,
  isDatePastDeadline,
} from "@kanpla/system";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { deadlineInfoAtom } from "../../lib/hooks/useDeadlineInfo";
import { CategoryProps } from "./Category";
import ProductItem from "./ProductItem";

type ProductsListProps = Pick<
  CategoryProps,
  "products" | "orderLines" | "dateSeconds" | "onChange"
>;
const CategoryProductList = ({
  products,
  orderLines,
  dateSeconds,
  onChange,
}: ProductsListProps) => {
  const { deadlineExcludingWeekends, deadlineWeekRelative } =
    useAtomValue(deadlineInfoAtom);

  const { partner } = useContainer(OrderingContext);

  return (
    <>
      {products.map((product, index) => {
        const hasSoftDeadline = isDatePastDeadline({
          date: new Timestamp(dateSeconds, 0),
          deadline: product.individualDeadline,
          deadlineWeekRelative,
          deadlineExcludingWeekends,
          timezone: partner?.timezone ?? TIMEZONE_DEFAULT,
        });

        return (
          <ProductItem
            key={product.id}
            product={product}
            value={orderLines?.find(
              (item) =>
                item.productLineId === product.productLineId ||
                item.productId === product.id
            )}
            disabled={hasSoftDeadline}
            dateSeconds={dateSeconds}
            onChange={onChange}
            isLast={index === products.length - 1}
          />
        );
      })}
    </>
  );
};

export default CategoryProductList;
