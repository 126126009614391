import { faCameraSlash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Supplier, _FrontendSupplier } from "@kanpla/types";
import { Image } from "@kanpla/ui";
import classNames from "classnames";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";

type Ratio = "1:1" | "16:10";
type Fit = "crop";
type NoPhotoIconSize = "1x" | "2x";
type Size = { w: number; h: number };

type ProductPhotoProps = {
  src?: string;
  name: string;
  disabled?: boolean;
  size?: Size;
  ratio?: Ratio;
  fit?: Fit;
  noPhotoIconSize?: NoPhotoIconSize;
  supplier?: Supplier | _FrontendSupplier | null;
};

export const ProductPhoto = ({
  src,
  name,
  disabled,
  supplier,
  size = { w: 400, h: 400 },
  ratio = "16:10",
  fit = "crop",
  noPhotoIconSize = "2x",
}: ProductPhotoProps) => {
  const defaultProductImage =
    supplier?.custom?.images?.defaultProductImage || "";

  if (!src && !defaultProductImage)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <FontAwesomeIcon icon={faCameraSlash} size={noPhotoIconSize} />
      </div>
    );

  return (
    <Image
      src={src || defaultProductImage}
      size={{ ...size, ar: ratio, fit }}
      alt={name}
      className={classNames(
        "w-full transform object-cover object-center rounded-md inset-0 h-full transition-transform",
        !disabled && "group-hover:scale-105"
      )}
      style={{ transition: "transform 0.2s ease" }}
      dataCy="product-img"
    />
  );
};
