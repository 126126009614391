import { faCircleInfo } from "@fortawesome/pro-duotone-svg-icons";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findHoliday, useT } from "@kanpla/system";
import { Tooltip } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import moment from "moment";
import { getRanges } from "../../lib/flex/getRanges";
import { periodsAtom } from "./StandardHolidays";

interface Props {
  dateSeconds: string;
  isHolidayFromAdmin?: boolean;
}

const HolidayFeedback = ({
  dateSeconds,
  isHolidayFromAdmin = false,
}: Props) => {
  const t = useT();

  const periods = useAtomValue(periodsAtom);
  const periodsRanges = getRanges(periods);

  const { isHoliday, period } = findHoliday({ periodsRanges, dateSeconds });

  const getHolidayPeriod = () => {
    const getDateInfos = ({
      seconds,
      format,
    }: {
      seconds: number;
      format: string;
    }) => {
      return moment.unix(seconds).format(format);
    };

    const isSameMonth =
      getDateInfos({ seconds: period?.fromSeconds || 0, format: "MMMM" }) ===
      getDateInfos({ seconds: period?.toSeconds || 0, format: "MMMM" });

    if (isSameMonth) {
      return `${getDateInfos({
        seconds: period?.fromSeconds || 0,
        format: "DD",
      })} - ${getDateInfos({
        seconds: period?.toSeconds || 0,
        format: "DD",
      })} ${getDateInfos({
        seconds: period?.fromSeconds || 0,
        format: "MMMM",
      })}`;
    }

    return `${getDateInfos({
      seconds: period?.fromSeconds || 0,
      format: "DD",
    })} ${getDateInfos({
      seconds: period?.fromSeconds || 0,
      format: "MMMM",
    })} - ${getDateInfos({
      seconds: period?.toSeconds || 0,
      format: "DD",
    })} ${getDateInfos({
      seconds: period?.toSeconds || 0,
      format: "MMMM",
    })} `;
  };
  const holidayPeriod = getHolidayPeriod();

  if (isHoliday) {
    return (
      <div className="flex gap-x-3 items-baseline">
        <h3 className="h500 !text-warning-main">
          {t("Holiday period: {value}", { value: holidayPeriod })}
        </h3>
        <Tooltip
          content={t(
            `A holiday period is registered. Changes can be made under 'Edit holiday'.`
          )}
        >
          <FontAwesomeIcon icon={faCircleInfo} className="cursor-pointer" />
        </Tooltip>
      </div>
    );
  }

  if (isHolidayFromAdmin) {
    return (
      <Tooltip
        content={t("The kitchen is closed on this day.")}
        trigger="click"
      >
        <FontAwesomeIcon
          icon={faWarning}
          className="ml-2 text-warning-main cursor-pointer text-lg"
        />
      </Tooltip>
    );
  }

  return null;
};

export default HolidayFeedback;
