import { createOrderLine } from "@kanpla/system";
import { LoadOfferReturn, OrderLine, _FrontendModule } from "@kanpla/types";

type AddRequiredProductParams = {
  orderLines: OrderLine[];
  module: _FrontendModule;
  offer: LoadOfferReturn;
};
/**
 * Function that adds the required product to the basket if it's not in there yet
 * @returns {OrderLine[]} Returns the required product or []
 */
export const addRequiredProduct = ({
  orderLines,
  module,
  offer,
}: AddRequiredProductParams): OrderLine[] => {
  const { requiredProduct: requiredProductPlugin } = module?.plugins || {};
  const requiredProductId = requiredProductPlugin?.productId;

  if (!requiredProductId) return orderLines;

  const requiredProduct = offer.items.find(
    (item) => item.productId === requiredProductId
  );
  if (!requiredProduct) return orderLines;

  const isAlreadyInBasket = orderLines.some(
    (item) => item.productId === requiredProductId
  );
  if (isAlreadyInBasket) return orderLines;

  if (
    !checkProductLayer(
      orderLines,
      requiredProductPlugin?.requiredOrder?.layers?.[0]?.productIds
    )
  ) {
    return orderLines.filter((item) => item.productId !== requiredProductId);
  }

  const newRequiredProductItem = createOrderLine({
    amount: 1,
    product: requiredProduct,
  });

  return [...orderLines, newRequiredProductItem];
};

// Check if product is part of the blocks
function checkProductLayer(orderLines: OrderLine[], layerProducts?: string[]) {
  // If no product in required order, apply to all
  if (!layerProducts?.length) return true;

  return layerProducts?.some((productId: string) => {
    const isProductInBasket = orderLines?.find(
      (item) => item.productId === productId
    );

    return isProductInBasket;
  });
}
