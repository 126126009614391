import { Button } from "@kanpla/ui";
import { Dot } from "pure-react-carousel";
import { ReactNode } from "react";

interface Props {
  /** Title of the screen */
  title: string;
  /** Subtitle of the screen */
  subtitle: string;
  /** The label displayed on the primary button */
  buttonLabel: string;
  /** An illustration explaining the content of the screen */
  illustration: ReactNode;
  /** Callback fired after click on the main button */
  onContinue: () => void;
  buttonLoading?: boolean;
}

const ScreenWrapper = ({
  title,
  subtitle,
  buttonLabel,
  illustration,
  onContinue,
  buttonLoading = false,
}: Props): JSX.Element => {
  return (
    <div className="w-full h-full bg-background-primary">
      <div className="w-full flex justify-center items-center px-4 pt-4 pb-24 ml-1">
        {illustration}
      </div>
      <div className="w-full h-full border-t border-secondary-dark bg-background-secondary px-4 py-8 flex flex-col justify-center content-between ">
        <div>
          <h1 className="text-2xl font-semibold text-center">{title}</h1>
          <p className="text-text-secondary text-center mt-2 text-sm min-h-[80px]">
            {subtitle}
          </p>
        </div>
        <div className="h-full w-full mt-8">
          <Button
            type="primary"
            className="w-full font-medium text-lg !py-3"
            size="large"
            onClick={onContinue}
            loading={buttonLoading}
          >
            {buttonLabel}
          </Button>
          <div className="w-full flex justify-center mt-6">
            <Dot
              slide={0}
              className="disabled:bg-primary-main bg-text-disabled mx-1 rounded-full h-2 w-2 transition-all ease-in-out"
            />
            <Dot
              slide={1}
              className="disabled:bg-primary-main bg-text-disabled mx-1 rounded-full h-2 w-2 transition-all ease-in-out"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenWrapper;
