import { t } from "@kanpla/system";
import { StampCardRule, StampCard as StampCardType } from "@kanpla/types";
import classNames from "classnames";
import { orderBy } from "lodash";
import moment from "moment-timezone";
import React from "react";
import { Button } from "../../button/Button";
import { Stamp } from "./Stamp";

export type PreviewStampcard = {
  isPreview: true;
  onRewardClick?: never;
};

export type RealStampcard = {
  isPreview?: never;
  onRewardClick: (stampcard: {
    structure: StampCardRule;
    data: StampCardType | null;
  }) => void;
};

type StampCardProps = {
  structure: StampCardRule;
  data: StampCardType | null;
} & (PreviewStampcard | RealStampcard);

export const StampCard = ({
  data,
  structure,
  isPreview,
  onRewardClick,
}: StampCardProps) => {
  const shouldDisplayCTA = structure.cta?.label && structure.cta?.url;

  const stamps = data?.stamps || [];

  // sorted by date of validity
  const validStamps = orderBy(stamps, "validFromSeconds", "asc").filter(
    (stamp) => !stamp.expiredAtSeconds
  );

  const stampsToDisplay = validStamps.slice(
    0,
    structure.reward?.numberOfStamps
  );

  const offset = moment().tz("Europe/Copenhagen").utcOffset();
  const nowSeconds = moment().utc().add(offset, "minutes").unix();

  const shouldDisplayReward =
    stampsToDisplay.every((s) => s.validFromSeconds < nowSeconds) &&
    stampsToDisplay.length >= structure.reward?.numberOfStamps;

  return (
    <div
      className={classNames(
        "w-full px-4 py-2 rounded-xl bg-primary-main font-medium",
        isPreview && "pointer-events-none"
      )}
    >
      {/** Header */}
      <div className="flex justify-between gap-4">
        {/** Title */}
        <p className="text-base text-primary-contrast">{structure.title}</p>

        {/** CTA */}
        {shouldDisplayCTA && (
          <Button
            size="small"
            className="flex-1 h-fit max-w-fit line-clamp-2 min-w-min"
            href={structure.cta.url}
          >
            <p className="text-primary-main">{structure.cta.label}</p>
          </Button>
        )}
      </div>

      {/** Stamps container */}
      <div className="grid grid-cols-8 gap-2 mt-4 mb-2">
        {Array(structure.reward?.numberOfStamps || 0)
          .fill(null)
          .map((_, i) => (
            <div>
              <Stamp
                numberToDisplay={i + 1}
                stamp={stampsToDisplay[i]}
                now={nowSeconds}
                isGift={i === structure.reward.numberOfStamps - 1}
              />
            </div>
          ))}
      </div>

      {/** Reward */}
      {shouldDisplayReward && (
        <>
          {/** Divider */}
          <div className="border-b border-primary-contrast opacity-50 my-4" />

          {/** Reward */}
          <div className="flex justify-between gap-4 items-center">
            <div className="flex gap-2 flex-1">
              <div className="w-9">
                <Stamp
                  numberToDisplay={0}
                  stamp={undefined}
                  now={0}
                  isGift={true}
                />
              </div>

              <p className="text-primary-contrast self-center whitespace-nowrap text-sm text-ellipsis">
                {t("Reward unlocked")}
              </p>
            </div>

            <Button
              size="small"
              type="white"
              className="flex-1 h-fit max-w-fit line-clamp-2 min-w-min"
              onClick={() => !isPreview && onRewardClick({ structure, data })}
            >
              <p className="text-primary-main">{t("Get the reward!")}</p>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
