import { useT } from "@kanpla/system";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useContainer } from "unstated-next";
import { NativePermissionsContext } from "..";
import TrackingIllustration from "../illustrations/TrackingIllustration";
import ScreenWrapper from "./ScreenWrapper";

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const TrackingScreen = ({ setOpen }: Props): JSX.Element => {
  const t = useT();

  const { trackingPermissions, requestTraking } = useContainer(
    NativePermissionsContext
  );

  useEffect(() => {
    if (!trackingPermissions) return;

    setOpen(false);
  }, [trackingPermissions]);

  return (
    <ScreenWrapper
      title={t("Allow tracking")}
      subtitle={t(
        "We track your app usage and orders to tailor meal suggestions to your preferences. For example, if you often choose vegetarian dishes, we'll recommend similar options."
      )}
      buttonLabel={t("Continue")}
      illustration={<TrackingIllustration />}
      onContinue={() => requestTraking()}
    />
  );
};

export default TrackingScreen;
