import { LoadFlexReturn } from "@kanpla/services";
import { useMemo } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";

export const useFlexOrders = () => {
  const { orders, week } = useContainer(OrderingContext);

  const weekOrders: LoadFlexReturn["weekOrders"] = useMemo(
    () =>
      week.reduce((acc, day, index) => {
        const daySeconds = day.seconds;

        const orderForDay = orders.find(
          (order) => order.dateSeconds === daySeconds
        );

        return {
          ...acc,
          [daySeconds]: orderForDay ? orderForDay.orderLines : null, //: defaultOrder || [],
        };
      }, {} as LoadFlexReturn["weekOrders"]),
    [week, orders]
  );

  return {
    weekOrders,
  };
};
