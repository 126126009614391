import { OrderingContext } from "@kanpla/ordering";
import {
  calculateOrderTotalByModule,
  createOrderLine,
  getErrorMessage,
  mergeOrders,
} from "@kanpla/system";
import { CombinedOfferItem, CustomOrderContent } from "@kanpla/types";
import { useAtomValue, useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { useAllowance } from "../shared/allowance/useAllowance";
import { basketAtom, openBasketAtom } from "../shared/basket/useBasket";
import { useSetBasket } from "../shared/basket/useSetBasket";

type Props = {
  product: CombinedOfferItem;
};

const useOnPurchase = ({ product }: Props) => {
  const { module, activePlugins, school, orderInfo } =
    useContainer(OrderingContext);
  const basket = useAtomValue(basketAtom);
  const setOpenBasket = useSetAtom(openBasketAtom);
  const setBasket = useSetBasket();

  const { updateFromProduct: updateAllowance } = useAllowance();

  const openBasketIfShould = () => {
    const hasKanplaGo = activePlugins?.["kanplaGo"];
    const hasPayPerOrder = activePlugins?.["payPerOrder"];
    const activePaymentPlugin = hasKanplaGo
      ? module?.plugins?.kanplaGo
      : hasPayPerOrder
      ? module?.plugins?.payPerOrder
      : undefined;

    const shouldOpenBasket =
      (module?.type === "mealplan" &&
        !hasKanplaGo &&
        !hasPayPerOrder &&
        ["school", "schoolSupplier", "highSchool"].includes(
          school?.type || ""
        ) &&
        calculateOrderTotalByModule(basket, module) === 0) ||
      activePaymentPlugin?.showBasketPopup;

    if (shouldOpenBasket) setOpenBasket(true);
  };

  const onPurchase = async (
    prod: CombinedOfferItem,
    data: CustomOrderContent
  ) => {
    try {
      const newProductItem = createOrderLine({
        amount: data.productAmount || 0,
        product,
        options: data.optionChoices,
      });

      /** special case for advanced variants */
      const newOrder = mergeOrders([basket, [newProductItem]]);

      // Handle Allowance here (update usage)
      updateAllowance({
        product,
        amount: data.productAmount || 0,
        options: data.optionChoices,
      });

      openBasketIfShould();
      setBasket({
        orderLines: newOrder,
        info: orderInfo,
      });
    } catch (e) {
      throw new Error(getErrorMessage(e));
    }
  };

  const orderButtonDisabled = false;

  return { onPurchase, orderButtonDisabled };
};

export default useOnPurchase;
