import { useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useContainer } from "unstated-next";

import {
  calculateProductPrice,
  getErrorMessage,
  stringifyOrderLine,
  useT,
} from "@kanpla/system";
import { CustomOrderContent } from "@kanpla/types";
import {
  DrawerOrModal,
  InputAmount,
  hidePricesAtom,
  message,
  usePriceFormatter,
} from "@kanpla/ui";

import { OrderingContext } from "../../context";
import { useProcessAllergens } from "../../lib/allergens/waitAllergensWarning";
import { useAllowance } from "../allowance/useAllowance";
import { useAllowanceProduct } from "../allowance/useAllowanceProduct";
import { useMinimumAmount } from "../minimumAmount/useMinimumAmount";
import { ProductProps } from "./Product";
import { ProductDetailsContent } from "./ProductDetailsContent";

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return { width };
};

interface Props extends ProductProps {
  setOpen: (nextState: boolean) => void;
  open: boolean;
  orderButtonDisabled?: boolean;
  currentDayTimestamp?: number;
}

export const ProductDetails = (props: Props) => {
  const {
    hideAmount,
    maxAmount,
    disabled,
    orderButtonDisabled,
    purchaseLabel,
    product,
    currentDayTimestamp,
    tooltip,
    hidePriceInLabel,
    open,
    setOpen,
    maxDisabled,
  } = props;
  const { module, dateSeconds } = useContainer(OrderingContext);
  const t = useT();

  const [data, setData] = useState<CustomOrderContent>(Object);

  const { width } = useViewport();
  const hidePrices = useAtomValue(hidePricesAtom);

  // General allowance (scoped to the module)
  const { getMaxAllowanceForProduct } = useAllowance();
  const maxAllowance = getMaxAllowanceForProduct(product);

  // Local allowance (scoped to the current product)
  const { update: updateLocalAllowance, isAllowanceOverForProduct } =
    useAllowanceProduct({
      product,
      open,
    });

  const { waitAllergensWarning } = useProcessAllergens({
    product,
    dateSeconds,
  });

  const minimumAmount = useMinimumAmount([], stringifyOrderLine({}), product);

  const onPurchase = async () => {
    /** Display warning to the user about eventual allergens */
    const response = await waitAllergensWarning();
    if (!response) return response;

    // BS-974: If changing variants, it sends 0 as productAMount, which breaks the listing and orders
    if (!data.productAmount) data.productAmount = 1;
    await props.onPurchase(props.product, data);

    return true;
  };

  const amount =
    data?.productAmount || props.initialAmount || minimumAmount || 1;

  useEffect(() => {
    if (isEmpty(data?.optionChoices)) return;

    // Update local allowance with option choices
    updateLocalAllowance(amount, data.optionChoices);
  }, [data?.optionChoices]);

  const updateAmount = (
    newAmount: number,
    newOptions?: Props["initialOptions"]
  ) => {
    if (newOptions) {
      setData((data) => ({
        ...data,
        optionChoices: newOptions,
      }));
    }

    updateLocalAllowance(newAmount, data.optionChoices);

    if (newAmount === data?.productAmount) return;
    setData((data) => ({
      ...data,
      productAmount: newAmount,
    }));
  };

  useEffect(() => {
    if (!props?.initialAmount) return;
    updateAmount(props.initialAmount, props?.initialOptions);
  }, [props?.initialAmount, props?.initialOptions]);

  useEffect(() => {
    updateAmount(amount);
  }, [amount]);

  const priceToDisplay = calculateProductPrice({
    product,
    module,
    options: data.optionChoices || {},
  });

  const totalPrice = product.periodPrice
    ? product.periodPrice
    : amount * priceToDisplay;
  const totalPriceFormatted = usePriceFormatter(totalPrice);

  const actions = [
    !hideAmount ? (
      <InputAmount
        amount={amount}
        setAmount={(newAmount) => updateAmount(newAmount)}
        minAmount={minimumAmount || 1}
        maxAmount={maxAllowance || maxAmount}
        maxDisabled={maxDisabled}
      />
    ) : (
      <></>
    ),
    {
      label: purchaseLabel ? (
        <span>
          {purchaseLabel}{" "}
          {!hidePriceInLabel && !hidePrices && (
            <span className="hidden md:inline">{totalPriceFormatted}</span>
          )}
        </span>
      ) : (
        `Order ${totalPriceFormatted}`
      ),
      onClick: async () => {
        try {
          const response = await onPurchase();
          if (!response) return;

          setOpen(false);
          setData({});
        } catch (e) {
          const errorMessage = getErrorMessage(e);
          if (errorMessage.includes(t("Not enough credit"))) return;
          message.error(errorMessage);
        }
      },
      type: "primary",
      disabled: orderButtonDisabled || isAllowanceOverForProduct,
      id: `product_order_${product?.id}`,
      key: `product_order_${product?.id}`,
      dataCy: "add-basket",
      tooltip,
    },
  ];

  if (!product) return null;

  const isMobile = width < 768;

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      noPadding={true}
      actions={disabled ? [] : (actions as any)}
      floatingActions
      stopPropagation={false}
    >
      {isMobile ? (
        <div
          id="product-detail-swipeable"
          className="overflow-y-auto mb-4 overflow-x-hidden flex-1 -mt-10"
          style={{ borderRadius: "24px 24px 0 0" }}
        >
          <ProductDetailsContent
            {...props}
            currentDayTimestamp={currentDayTimestamp}
            setData={setData}
            data={data}
          />
          <div
            className={`fixed bottom-0 pb-8 w-full bg-gradient-to-b from-transparent to-main-500`}
            style={{
              paddingBottom: `calc(var(--safe-inset-bottom))`,
            }}
          />
        </div>
      ) : (
        <div className="w-full h-full ">
          <div className="mb-4">
            <ProductDetailsContent
              {...props}
              currentDayTimestamp={currentDayTimestamp}
              setData={setData}
              data={data}
            />
          </div>
        </div>
      )}
    </DrawerOrModal>
  );
};
