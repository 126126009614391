import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter, tx } from "@kanpla/system";
import { HistoryItem } from "apps/frontend/pages/api/internal/load/paymentHistory";
import moment from "moment";

interface Props {
  item: HistoryItem;
}

const Item = ({ item }: Props) => {
  const locale = tx.getCurrentLocale() || "da";

  return (
    <div
      className={`flex mb-1.5 items-center text-text-primary border border-transparent transition rounded-lg p-1.5 px-2 -mx-2 select-none `}
    >
      <div className="bg-background-secondary rounded-lg w-12 h-12 flex items-center justify-center">
        <FontAwesomeIcon icon={item.icon} className="text-xl" />
      </div>
      <div className="pl-3">
        <div>{item.title}</div>
        <div className="text-xs text-text-secondary">
          {moment.unix(item.dateSeconds).format("l")}
        </div>
      </div>
      <div className="text-right ml-auto">
        <div className="text-text-primary">
          {priceFormatter(item.paymentAmount, item.currency, locale)}
        </div>
        <div className="text-xs text-text-secondary">
          {item.paymentDescription}
        </div>
      </div>
    </div>
  );
};

export default Item;
