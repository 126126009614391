import { T } from "@kanpla/system";
import { Tooltip } from "@kanpla/ui";
import classnames from "classnames";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { isStandardOrderingAtom } from "../../../Navigation";

interface Props {
  value?: number;
  disabled?: boolean;
  placeholder?: number;
  onChange: (newValue: number) => void;
  isValueEdited?: boolean;
  /** If there are variants and this is the default choice, only show total */
  showTotal?: boolean;
  hasOrderForTheDay?: boolean;
}

const DailyInput = (props: Props) => {
  const {
    value = "",
    placeholder,
    onChange,
    disabled = false,
    isValueEdited,
    showTotal,
    hasOrderForTheDay,
  } = props;
  const isStandardOrdering = useAtomValue(isStandardOrderingAtom);
  const [focusing, setFocusing] = useState(false);
  const [localValue, setLocalValue] = useState("");

  useEffect(() => setLocalValue(`${value}`), [focusing]);

  if (showTotal)
    return (
      <div className="w-full text-center text-xl font-medium">{value}</div>
    );

  // Customize styles based on whether this is the standard ordering
  const valueTextStyles = isStandardOrdering
    ? "enabled:text-info-dark"
    : "enabled:text-text-primary";
  const backdropStyles = isStandardOrdering
    ? "bg-info-backdrop"
    : "bg-primary-backdrop";

  // Placeholder logic:
  // - if there is a value, show it as the placeholder when focused
  //    - don't show placeholder if there's an order for the day
  //    - don't show placeholder if it's past ordering time
  // - if there is no value, show the standard order as the placeholder
  const showsStandardOrderPlaceholder =
    !isStandardOrdering &&
    !focusing &&
    !value &&
    placeholder !== 0 &&
    !hasOrderForTheDay &&
    !disabled;
  const finalPlaceholder = showsStandardOrderPlaceholder ? placeholder : value;
  const isPlaceholderBlue = isStandardOrdering || showsStandardOrderPlaceholder;
  const placeholderStyles = isPlaceholderBlue
    ? "enabled:placeholder:text-info-dark"
    : "enabled:placeholder:text-text-primary";

  return (
    <Tooltip
      content={
        <div className="w-64">
          <T _str="This is your standard order, which will be ordered automatically unless you make a manual change" />
        </div>
      }
      side="bottom"
      trigger="hover"
      // disabled={true}
      disabled={!showsStandardOrderPlaceholder}
    >
      <input
        pattern="[0-9]*"
        inputMode="numeric"
        type="number"
        value={focusing ? localValue : value}
        disabled={disabled}
        placeholder={`${finalPlaceholder || 0}`}
        onChange={(e) => {
          const newValue = e.target.value;
          if (parseInt(newValue) < 0) return;
          setLocalValue(newValue);
          onChange(parseInt(newValue));
        }}
        className={classnames(
          "shadow-md",
          "disabled:text-text-disabled",
          "w-16 text-center text-xl font-medium",
          "border border-divider-main focus:border-primary-main rounded-lg",
          isValueEdited ? backdropStyles : "bg-background-primary",
          valueTextStyles,
          placeholderStyles,
          disabled && "border-none shadow-none"
        )}
        onFocus={() => setFocusing(true)}
        onBlur={() => setFocusing(false)}
      />
    </Tooltip>
  );
};

export default DailyInput;
