import { useT } from "@kanpla/system";
import { _FrontendModule } from "@kanpla/types";
import { Form, Input, message } from "@kanpla/ui";

interface Props {
  module: _FrontendModule;
  basketKey: string;
}

export const CoHostsInput = ({ module, basketKey }: Props) => {
  const t = useT();

  const billedReceiptsPlugin = module?.plugins?.billedReceipts;

  if (!billedReceiptsPlugin?.active) return null;
  if (!billedReceiptsPlugin.enableCoHost) return null;

  return (
    <Form.Item
      name={`${basketKey}.cohost`}
      label={t("Order's co-hosts")}
      description={t(
        "Enter email addresses of the co-hosts of this order. They will receive order confirmation and updates."
      )}
    >
      <Input.Tags className="mt-1" placeholder={t("Order's co-hosts")} />
    </Form.Item>
  );
};
