import {
  TIMEZONE_DEFAULT,
  Timestamp,
  getDayIndexFromSeconds,
  isDatePastDeadline,
  offerHasMultipleCategories,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  OrderLine,
  OrderLines,
  StandardOrderLine,
} from "@kanpla/types";
import { useAtomValue } from "jotai";
import { entries, isEmpty, values } from "lodash";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";
import { useFlexStandardOrders } from "../../../lib/flex/useFlexStandardOrders";
import { deadlineInfoAtom } from "../../../lib/hooks/useDeadlineInfo";
import Category from "../Category";
import NoLunchButton from "../NoLunchButton";
import ProductItem from "../ProductItem";
import Header from "./Header";

interface ProductListProps {
  isStandard: boolean;
  offer: Record<string, CombinedOfferItem[]>;
  orderLines: OrderLine[] | StandardOrderLine[] | null;
  dateSeconds: number;
  disabled: boolean;
  onProductSelected: (newOrder: OrderLine | OrderLines) => void;
}

const ProductsList = ({
  isStandard,
  offer: offerItems,
  orderLines,
  dateSeconds,
  disabled = false,
  onProductSelected,
}: ProductListProps) => {
  const { deadlineWeekRelative, deadlineExcludingWeekends } =
    useAtomValue(deadlineInfoAtom);

  const { mobile, partner, module } = useContainer(OrderingContext);
  const hideNoLunchButton = module?.plugins?.noLunchFlexHidden?.active;

  const { standardOrderLines } = useFlexStandardOrders();
  const dayIndex = getDayIndexFromSeconds(dateSeconds);

  const hasMultipleCategories = offerHasMultipleCategories(offerItems);

  const orderLinesWithStandards =
    orderLines === null ? standardOrderLines?.[dayIndex] || [] : orderLines;

  if (!hasMultipleCategories) {
    return (
      <Header disabled={disabled} noStyles={isStandard || mobile}>
        {(values(offerItems)?.[0] || []).map((product, index) => {
          const hasIndividualDeadline = isDatePastDeadline({
            date: new Timestamp(dateSeconds, 0),
            deadline: product?.individualDeadline,
            deadlineWeekRelative,
            deadlineExcludingWeekends,
            timezone: partner?.timezone ?? TIMEZONE_DEFAULT,
          });

          return (
            <ProductItem
              key={product.productLineId}
              product={product}
              useStandard={isStandard || orderLines === null}
              value={(orderLinesWithStandards || []).find(
                (item) =>
                  item.productLineId === product.productLineId ||
                  item.productId === product.id
              )}
              disabled={hasIndividualDeadline}
              dateSeconds={dateSeconds}
              onChange={onProductSelected}
              isLast={index === values(offerItems)?.[0].length - 1}
            />
          );
        })}
        {!hideNoLunchButton && (
          <NoLunchButton
            onChange={(newOrderLines) => {
              onProductSelected(newOrderLines);
            }}
            dateSeconds={dateSeconds}
          />
        )}
      </Header>
    );
  }

  return (
    <Header disabled={disabled} noStyles={isStandard || mobile}>
      {entries(offerItems || {})
        .filter(([_, products]) => !isEmpty(products))
        .map(([category, products], index) => (
          <Category
            key={`${category}-${index}`}
            category={category}
            products={products}
            orderLines={orderLinesWithStandards}
            dateSeconds={dateSeconds}
            onChange={onProductSelected}
          />
        ))}
      {!hideNoLunchButton && (
        <NoLunchButton
          isFlexStandard={isStandard}
          onChange={(newOrder) => onProductSelected(newOrder)}
          dateSeconds={dateSeconds}
        />
      )}
    </Header>
  );
};

export default ProductsList;
