import { createOrderLine, mergeOrders } from "@kanpla/system";
import { CustomProduct } from "@kanpla/types";
import { useAtomValue, useSetAtom } from "jotai";
import { Dispatch, SetStateAction } from "react";
import slug from "slug";
import {
  basketAtom,
  openBasketAtom,
} from "./../../../../../shared/basket/useBasket";
import { useSetBasket } from "./../../../../../shared/basket/useSetBasket";
import { currentBasketItemAtom } from "./../../../../../shared/product/ProductPreviewRow";
import {
  CustomOrderFormData,
  DEFAULT_CUSTOM_PRODUCT,
  DEFAULT_PRODUCT_CATEGORY,
  DEFAULT_PRODUCT_NAME,
} from "./CustomOrderModal";

type UseAddCustomProductProps = {
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const useAddCustomProduct = ({ setOpen }: UseAddCustomProductProps) => {
  const setBasket = useSetBasket();
  const basket = useAtomValue(basketAtom);
  const basketValue = useAtomValue(currentBasketItemAtom);
  const setOpenBasket = useSetAtom(openBasketAtom);

  const submit = (
    data: CustomOrderFormData,
    customProducts: CustomProduct[]
  ) => {
    const result = Object.entries(data || {}).reduce(
      (acc: CustomProduct[], [formKey, value]) => {
        // get id and propName
        // formKey has this format "propName_id"
        const id = formKey.split("_")[1];
        const propName = formKey.split("_")[0] as keyof CustomProduct;

        const existingCustomProduct: CustomProduct = acc.find(
          (customProduct) => customProduct.productId === id
        ) || { ...DEFAULT_CUSTOM_PRODUCT, productId: id };

        return [
          ...acc.filter((obj) => obj.productId !== id),
          { ...existingCustomProduct, [propName]: value },
        ];
      },
      []
    );

    // 1. Change the Ids using this format "custom-[name]-[category]-[cost]"
    const newOrderLines = result.map(
      ({
        name: customProductName,
        category: customProductCategory,
        productId,
        unitPrice,
        amount,
      }) => {
        const name = customProductName || DEFAULT_PRODUCT_NAME;
        const category = customProductCategory || DEFAULT_PRODUCT_CATEGORY;
        const targetProduct = customProducts.find(
          (product) => product.productId === productId
        );

        const newCustomProduct = {
          category,
          name,
          unitPrice: unitPrice ?? 0,
          amount: amount ?? 0,
          vatRate: targetProduct?.vatRate ?? 0.25,
          productId: `custom-${slug(name)}-${slug(category)}-${unitPrice ?? 0}`,
        };

        return createOrderLine({
          amount: newCustomProduct.amount,
          product: {
            ...newCustomProduct,
            productLineId: newCustomProduct.productId,
            id: newCustomProduct.productId,
          },
          options: {},
        });
      }
    );

    setBasket({
      orderLines: mergeOrders([basket, newOrderLines]),
      info: basketValue?.info,
    });

    setOpenBasket(true);

    setOpen(false);
  };

  return submit;
};
