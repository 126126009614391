import { useSubscriptionPeriods } from "@kanpla/ordering";
import {
  SubscriptionOrder,
  SubscriptionPeriod,
  _FrontendModule,
} from "@kanpla/types";
import { ModuleLoadingWrapper } from "@kanpla/ui";
import { default as React, useEffect } from "react";
import { useContainer } from "unstated-next";
import Add from "../../components/subscription/add";
import Overview from "../../components/subscription/overview";
import useSubscriptions from "../../lib/useSubscriptions";
import { AppContext } from "../contextProvider";

interface ContextType {
  periods: {
    [type: string]: Array<SubscriptionPeriod>;
  };
  subscriptions: Array<SubscriptionOrder>;
  moduleId: string;
  module: _FrontendModule;
}

export const SubscriptionContext = React.createContext<Partial<ContextType>>(
  {}
);

const Subscription = () => {
  const { module, setTimeNavigation, moduleId, innerAppLoading } =
    useContainer(AppContext);

  const periods = useSubscriptionPeriods(moduleId);
  const subscriptions = useSubscriptions();

  useEffect(() => {
    setTimeNavigation("none");
  }, [moduleId]);

  return (
    <SubscriptionContext.Provider
      value={{
        periods,
        subscriptions,
        moduleId,
        module,
      }}
    >
      <ModuleLoadingWrapper loading={innerAppLoading}>
        <div className="wrapper pt-12">
          {/* Back to mealplan */}
          {!!subscriptions?.length && <Overview />}
          <Add />
        </div>
      </ModuleLoadingWrapper>
    </SubscriptionContext.Provider>
  );
};

export default Subscription;
