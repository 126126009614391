import { Operator, OrderLines, RequiredOrder } from "@kanpla/types";

export const validateRequiredOrder = (
  orderLines: OrderLines,
  validationDocument?: RequiredOrder
) => {
  if (!validationDocument?.layers?.length) return true;

  const operator: Operator = validationDocument?.operator || "AND";

  // Check that every condition is met
  if (operator === "AND") {
    // Map through layers
    const andResults = validationDocument?.layers?.map((layer) => {
      return orderLines.some((orderLine) => {
        const { productId, amount } = orderLine;
        // Is correct amount as well as is any of product IDs
        return amount >= layer.amount && layer.productIds?.includes(productId);
      });
    });

    return andResults.every((res) => res);
  }

  // TODO: Handle OR operator
  if (operator === "OR") throw new Error("Not able to handle OR operator yet");

  return false;
};
