import { useT } from "@kanpla/system";
import { Header } from "@kanpla/ui";
import Products from "./Products";

const Index = () => {
  const t = useT();

  return (
    <>
      <Header title={t("Add subscription")} />
      <Products />
    </>
  );
};

export default Index;
