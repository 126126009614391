import { faCheck, faExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAvailabilityProductStandard,
  getProductName,
  getWeekDays,
  useT,
} from "@kanpla/system";
import { OrderLines, StandardOrderLine } from "@kanpla/types";
import classNames from "classnames";
import { useSetAtom } from "jotai";
import React from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { productsListDateSecondsAtom } from "../shared/ProductsListModal";

interface Props {
  orderLines: OrderLines | StandardOrderLine[];
  dateSeconds: number;
  dayIndex: number;
}

const DayProductItem: React.FC<Props> = ({
  dateSeconds,
  orderLines,
  dayIndex,
}) => {
  const t = useT();

  const { module, items } = useContainer(OrderingContext);

  const setProductListDateSeconds = useSetAtom(productsListDateSecondsAtom);

  // Find the orderLine for current standard day
  const orderLine = orderLines?.[0] || {};
  const product = items.find(
    (i) =>
      i.productLineId === orderLine.productLineId ||
      i.productId === orderLine.productId
  );

  // Get the availability based on the day of the week and product days prop
  const { isAvailable } = getAvailabilityProductStandard({
    product,
    dateSeconds: dateSeconds.toString(),
  });

  const productInfos = getProductName({
    product,
    date: dateSeconds.toString(),
    noLunch: t("No lunch"),
  });

  const includeWeekend = module?.plugins?.sevenDayWeek?.active;
  const days = getWeekDays(includeWeekend);

  const dayLabel = days[dayIndex];

  return (
    <div
      onClick={() => setProductListDateSeconds(dateSeconds)}
      className="flex px-5 py-6 gap-x-4 gap-y-4 items-center rounded-lg transition-all cursor-pointer hover:shadow-lg"
    >
      <div
        style={{
          minWidth: "32px",
        }}
        className={classNames(
          "w-8 aspect-square rounded-full self-center flex items-center justify-center",
          {
            "bg-secondary-main": !product,
            "bg-info-main": product && isAvailable,
            "bg-warning-main": !isAvailable,
          }
        )}
      >
        {product && (
          <FontAwesomeIcon
            icon={isAvailable ? faCheck : faExclamation}
            color="white"
            className="text-lg"
          />
        )}
      </div>
      <div>
        <p className="text-secondary-main">
          {t("Every")} {dayLabel}
        </p>
        {productInfos && <p className="text-lg">{productInfos.name}</p>}
      </div>
    </div>
  );
};

export default DayProductItem;
