import { getAllergensIntersection, isLastItem, tx, useT } from "@kanpla/system";
import { Child, CombinedOfferItem, MenuItem, Week } from "@kanpla/types";
import { Alert } from "@kanpla/ui";
import React from "react";
import { attributesData } from "../productAttributes/attributesData";

interface Props {
  product?: CombinedOfferItem;
  dayIndex?: number;
  className?: string;
  child?: Child | null;
  week?: Week;
  menu?: MenuItem | null;
}

export const AllergensWarning = ({
  product,
  dayIndex,
  child,
  week,
  menu = null,
  className = "",
}: Props) => {
  const t = useT();
  const locale = (tx.getCurrentLocale()?.substring(0, 2) || "da") as
    | "da"
    | "no"
    | "en";

  const dateSeconds = week?.[dayIndex as number]?.seconds;
  const menuItem = menu ? menu : product?.dates?.[dateSeconds as number]?.menu;
  const allergens = getAllergensIntersection({ child, product, menuItem });

  const allergensValues = Object.keys(allergens).map(
    (key) =>
      attributesData
        .find((attribute) => attribute.key === key)
        ?.names[locale].toLowerCase() || ""
  );

  if (!allergensValues.length) return null;

  return (
    <Alert
      message={
        <div>
          <p>
            {t("Contains")}{" "}
            {allergensValues.map((allergen, index) => {
              const isLast = isLastItem({ array: allergensValues, index });
              const isBeforeLast = allergensValues.length - 2 === index;

              const allergenName = <b>{allergen}</b>;
              const allergenDisplay =
                allergensValues.length > 1 ? (
                  isLast ? (
                    <span>
                      {t(" and ")}
                      {allergenName}
                    </span>
                  ) : (
                    <span>
                      {allergenName}
                      <span>{isBeforeLast ? "" : ", "}</span>
                    </span>
                  )
                ) : (
                  allergenName
                );

              return <span key={index}>{allergenDisplay}</span>;
            })}
          </p>
        </div>
      }
      className={className}
      type="warning"
      wrapContent
    />
  );
};
