import React from "react";

const diamondsConfigs = [
  { size: 250, delay: 0 },
  { size: 200, delay: 0.25 },
  { size: 150, delay: 0.5 },
  { size: 100, delay: 0.75 },
];

export const SecurityAnimation = () => {
  return (
    <div className="absolute inset-0 overflow-hidden opacity-30 top-0 left-0 pointer-events-none">
      {diamondsConfigs.map((config, index) => (
        <Diamond key={index} size={config.size} delay={config.delay} />
      ))}
      <style>
        {`
      @keyframes rotate {
        0% {
          transform: rotate(45deg) rotate3d(1, 1, 0, 0deg);
        }
        100% {
          transform: rotate(45deg) rotate3d(1, 1, 0, 360deg);
        }
      }

      .diamond {
        animation: rotate 5s infinite linear;
      }
     `}
      </style>
    </div>
  );
};

interface DiamondProps {
  size: number;
  delay: number;
}

const Diamond = ({ size, delay }: DiamondProps) => (
  <div
    className="absolute border-solid transform rotate-45 border-primary-contrast diamond"
    style={{
      borderWidth: "1px",
      width: `${size}px`,
      height: `${size}px`,
      left: "50%",
      top: "50%",
      transition: "border-color 1s ease, background-color 1s ease",
      animationDelay: `-${delay}s`,
      marginLeft: `-${size / 2}px`,
      marginTop: `-${size / 2}px`,
    }}
  />
);
