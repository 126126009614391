import { useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { useContainer } from "unstated-next";

import { Actions, Alert } from "@kanpla/ui";

import { OrderingContext, initialAllowanceUsagesAtom } from "../../context";
import useBasketPurchaseLogic from "../basket/useBasketPurchaseLogic";

import { useGetAllowanceMessage } from "../../shared/allowance/useGetAllowanceMessage";
import ActionsWrapper from "./ActionsWrapper";
import Card from "./Card";
import Empty from "./Empty";
import OrderPreview from "./OrderPreview";

interface Props {
  hideActions?: boolean;
}

const Content = (props: Props) => {
  const { hideActions = false } = props;

  const { items, dateSeconds } = useContainer(OrderingContext);
  const {
    actions: [actions, className, noPadding],
    onSubmit,
    basketInfoForm,
  } = useBasketPurchaseLogic();

  const initialAllowances = useAtomValue(initialAllowanceUsagesAtom);
  const { messages } = useGetAllowanceMessage({
    allowanceUsages: initialAllowances,
    filterOutWithProduct: true,
  });

  if (isEmpty(items)) return <Empty />;

  return (
    <>
      <div className="px-3 md:px-0">
        <div className="flex flex-col gap-y-2 mb-6">
          {messages.map((message) => (
            <Alert type="info" message={message} />
          ))}
        </div>
        {items
          .filter((item) => item.dates?.[dateSeconds]?.available)
          .map((item) => (
            <Card key={item.id} ticket={item} />
          ))}
        <OrderPreview basketInfoForm={basketInfoForm} onSubmit={onSubmit} />
      </div>
      {!hideActions && (
        <ActionsWrapper actionsClassName={className}>
          <Actions actions={actions} noPadding={noPadding} />
        </ActionsWrapper>
      )}
    </>
  );
};

export default Content;
