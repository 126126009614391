import { isNative, UseAuth, usePermissions } from "@kanpla/system";
import { Modal } from "@kanpla/ui";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import { useEffect, useState } from "react";
import { createContainer, useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import NotificationsScreen from "./partials/Notifications.screen";
import TrackingScreen from "./partials/Tracking.screen";

const ContextState = () => {
  const {
    requestNotification,
    requestTraking,
    hasAnsweredToNotificationPermissions,
    hasAnsweredToTrackingPermissions,
    trackingPermissions,
    notificationPermissions,

    hasAllowedNotifications,
    hasAllowedTracking,
  } = usePermissions();

  return {
    // Request a permissions modal from the native wrapper, to ask notifications permissions
    requestNotification,
    // Request a permissions modal from the native wrapper, to ask tracking permissions
    requestTraking,
    // If the user has already been asked to accept notifications
    hasAnsweredToNotificationPermissions,
    // If the user has already been asked to accept tracking
    hasAnsweredToTrackingPermissions,
    // Fired as soon as the user answers to tracking permissions after the first request
    trackingPermissions,
    // Fired as soon as the user answers to notifications permissions after the first request
    notificationPermissions,
    // If the user has allowed notifications
    hasAllowedNotifications,
    // If the user has allowed tracking
    hasAllowedTracking,
  };
};

export const NativePermissionsContext = createContainer(ContextState);

const PermissionsModal = () => {
  const auth = UseAuth();

  const { userId, appLoading } = useContainer(AppContext);
  const {
    hasAnsweredToNotificationPermissions: answerNotifications,
    hasAnsweredToTrackingPermissions: answerTracking,
  } = useContainer(NativePermissionsContext);

  const [open, setOpen] = useState<boolean>(true);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const totalSlides = 2;

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  if (!isNative || appLoading || !auth?.user || !userId || auth?.loading)
    return null;

  if (answerNotifications && answerTracking) return null;

  return (
    <CarouselProvider
      naturalSlideHeight={1}
      naturalSlideWidth={1}
      totalSlides={totalSlides}
      lockOnWindowScroll
      touchEnabled={false}
      dragEnabled={false}
      className="w-full h-full pb-0 select-none overflow-x-hidden"
      isIntrinsicHeight
      currentSlide={currentSlide}
    >
      <Modal
        open={open}
        setOpen={setOpen}
        className="w-full max-w-xs !shadow-2xl !rounded-xl overflow-x-hidden"
        closableOutside={false}
        noPadding
      >
        <Slider
          classNameTrayWrap="h-full w-full flex-1"
          classNameTray="flex h-full w-full transition select-none"
          className="h-full w-full"
        >
          <Slide
            index={0}
            innerClassName="h-full w-full p-0 m-0"
            className="w-full h-full"
          >
            <NotificationsScreen setCurrentSlide={setCurrentSlide} />
          </Slide>

          <Slide
            index={totalSlides - 1}
            innerClassName="h-full w-full p-0 m-0"
            className="w-full h-full"
          >
            <TrackingScreen setOpen={setOpen} />
          </Slide>
        </Slider>
      </Modal>
    </CarouselProvider>
  );
};

const Index = () => (
  <NativePermissionsContext.Provider>
    <PermissionsModal />
  </NativePermissionsContext.Provider>
);

export default Index;
