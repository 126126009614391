import { OrderingContext } from "@kanpla/ordering";
import { useT } from "@kanpla/system";
import { Actions, ActionType, DrawerOrModal } from "@kanpla/ui";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { ReactNode } from "react";
import { useContainer } from "unstated-next";
import {
  basketEditModeAtom,
  handleBasketOpeningAtom,
  openBasketAtom,
} from "../../shared/basket/useBasket";

interface Props {
  children: ReactNode;
  actions: ActionType[];
  actionsClassName?: string;
  noPaddingOnActions?: boolean;
}

export const BasketModalWrapper = ({
  children,
  actions,
  actionsClassName,
  noPaddingOnActions = false,
}: Props) => {
  const t = useT();
  const openBasket = useAtomValue(openBasketAtom);

  const handleBasketOpening = useSetAtom(handleBasketOpeningAtom);
  const editMode = useAtom(basketEditModeAtom);

  const { mobile, module, fromAdmin, isOrderInvoiced } =
    useContainer(OrderingContext);

  /** For meeting modules, don't wrap desktop basket in a modal */
  const shouldNotWrapInModal = module?.flow === "meeting" && !mobile;

  const shouldHideActions = editMode && fromAdmin && isOrderInvoiced;

  if (shouldNotWrapInModal)
    return (
      <>
        {children}
        {!shouldHideActions && <Actions actions={actions} />}
      </>
    );

  return (
    <DrawerOrModal
      open={openBasket}
      setOpen={handleBasketOpening}
      title={
        mobile ? null : editMode ? t("Edit the order") : t("Complete the order")
      }
      zIndex={60}
      noPadding={mobile}
      actions={shouldHideActions ? undefined : actions}
      floatingActions={mobile}
      stopPropagation={false}
      actionsClassName={actionsClassName}
      noPaddingOnActions={noPaddingOnActions}
    >
      {children}
    </DrawerOrModal>
  );
};
