import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import {
  checkChildLimit,
  createOrderLine,
  getOrderLine,
  stringifyOrderLine,
  updateOrderLine,
  useT,
} from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import {
  Button,
  DisplayAttributes,
  DrawerOrModal,
  Image,
  InputAmount,
  createHapticFeedback,
} from "@kanpla/ui";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext, initialAllowanceUsagesAtom } from "../../context";
import { dateInfoAtom } from "../../lib/hooks/useDateInfo";
import { useGetAllowanceMessage } from "../../shared/allowance/useGetAllowanceMessage";
import { useSingleAllowance } from "../../shared/allowance/useSingleAllowance";
import { basketAtom } from "../../shared/basket/useBasket";
import { useSetBasket } from "../../shared/basket/useSetBasket";
import { useMinimumAmount } from "../../shared/minimumAmount/useMinimumAmount";
import AllowanceOverInfo from "../AllowanceOverInfo";
import DeadlineInfo from "../DeadlineInfo";
interface Props {
  ticket: CombinedOfferItem;
}

const Card = (props: Props) => {
  const { ticket } = props;
  const { name, description, photo } = ticket;
  const {
    supplier,
    activePlugins,
    dateSeconds,
    module,
    child,
    childOrders: orders,
  } = useContainer(OrderingContext);

  const basket = useAtomValue(basketAtom);
  const { isPastTodayIndividualDeadline, isPastDeadline } =
    useAtomValue(dateInfoAtom);

  const setBasket = useSetBasket();

  const t = useT();

  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const hasAllergens =
    (!isEmpty(ticket?.allergens) &&
      Object.values(ticket?.allergens || {})?.filter((a) => a)?.length > 0) ||
    !isEmpty(ticket?.labels) ||
    !isEmpty(ticket?.pictograms);

  // Allowances
  const { shouldDisableProduct } = useSingleAllowance();
  const disableForAllowance = shouldDisableProduct(ticket);

  const initialAllowances = useAtomValue(initialAllowanceUsagesAtom);
  const { messageForProduct } = useGetAllowanceMessage({
    product: ticket,
    allowanceUsages: initialAllowances,
  });

  const getMaxAmount = () => {
    let stock = ticket.dates?.[dateSeconds]?.stock ?? Infinity;

    const isSignupOfferProduct =
      activePlugins["signupOffer"] &&
      module?.plugins?.signupOffer?.productId === ticket?.productId;
    const signupOfferExpired =
      isSignupOfferProduct && child?.takenSignupOffer === true;
    if (signupOfferExpired) return null;
    if (isSignupOfferProduct && stock > 1) stock = 1;

    //Get the quantity the user can order for this product
    const maxFromLimitPerChild = checkChildLimit({ orders, product: ticket });
    const reachedLimit = maxFromLimitPerChild <= 0;
    const limit = reachedLimit ? 0 : maxFromLimitPerChild;

    const productLimit =
      typeof limit === "number" && limit !== Infinity ? limit : stock;

    return isSignupOfferProduct ? 1 : productLimit;
  };
  const maxAmount = getMaxAmount();

  const minimumAmount = useMinimumAmount(
    basket,
    stringifyOrderLine({}),
    ticket
  );

  const newProductItem = createOrderLine({
    amount: minimumAmount || 1,
    product: ticket,
    options: {},
  });

  const basketItem = getOrderLine({
    orderLines: basket,
    targetLine: newProductItem,
  });
  const selected = (basketItem?.amount || 0) > 0;

  const isBeforeFlexibleStartDate =
    typeof ticket.individualOnset === "number" &&
    !isPastTodayIndividualDeadline(ticket?.individualOnset || 0);

  const isPastIndividualDeadline = isPastTodayIndividualDeadline(
    ticket.individualDeadline
  );

  const isPastBothDeadlines = isPastDeadline || isPastIndividualDeadline;

  const shouldDisableProductForAllowance = !selected && disableForAllowance;

  const disabled =
    maxAmount === 0 ||
    isPastBothDeadlines ||
    isBeforeFlexibleStartDate ||
    shouldDisableProductForAllowance;

  return (
    <>
      <div
        className={classNames(
          "w-full px-4 py-6 flex flex-col bg-slate-400 bg-cover bg-no-repeat bg-center rounded-lg shadow-lg hover:shadow-xl mb-4 h-fit relative transition-shadow border",
          disabled ? "cursor-not-allowed opacity-70" : "cursor-pointer"
        )}
      >
        <div className="absolute overflow-hidden rounded-md inset-0 z-0 pointer-events-none">
          <div className="bg-slate-900 inset-0 absolute z-10 opacity-40" />
          {photo && (
            <Image
              src={photo}
              size={{ w: 400 }}
              alt={name}
              className={classNames(
                "h-full object-cover object-center transform scale-110",
                disabled && "grayscale filter cursor-not-allowed opacity-60"
              )}
              blur
            />
          )}
        </div>
        <div className="relative z-1">
          <div className="flex justify-between items-center">
            <h1 className="text-xl font-medium text-white">{name}</h1>
            {selected ? (
              <InputAmount
                amount={basketItem?.amount ?? 1}
                setAmount={(amount: number) => {
                  const newBasket = updateOrderLine({
                    orderLines: basket,
                    targetLine: newProductItem,
                    newData: { amount },
                    removeOrderLine: amount === 0,
                  });
                  setBasket({ orderLines: newBasket });
                }}
                minAmount={minimumAmount || 0}
                maxAmount={ticket?.["canOrderOnlyOne"] ? 1 : maxAmount ?? 1}
                size="small"
                hapticCallback={() =>
                  createHapticFeedback({
                    type: "impact",
                    impactFeedback: "light",
                  })
                }
                stopPropagation
                design="white"
                disabled={disabled}
                maxDisabled={disableForAllowance}
              />
            ) : (
              <Button
                dataCy="btn-registering-check"
                className="w-fit px-2 text-primary-contrast border border-white"
                onClick={() => {
                  createHapticFeedback({ type: "impact" });

                  const newBasket = [...basket, newProductItem];
                  setBasket({ orderLines: newBasket });
                }}
                disabled={disabled}
              >
                {t("Choose")}
              </Button>
            )}
          </div>
          {(description || hasAllergens) && (
            <Button
              onClick={() => setOpenDescription(true)}
              dataCy="btn-registering-allergens"
              className="mt-4 w-fit"
              icon={faCircleQuestion}
              disabled={disabled}
            >
              {t("Product info")}
            </Button>
          )}
        </div>
        {disableForAllowance || messageForProduct ? (
          <div className="absolute -ml-6 flex flex-col items-start -mt-10">
            {disableForAllowance && !selected && <AllowanceOverInfo />}
            {!disableForAllowance && messageForProduct && (
              <AllowanceOverInfo type="info" message={messageForProduct} />
            )}
          </div>
        ) : (
          <DeadlineInfo
            individualDeadline={ticket?.individualDeadline}
            individualOnset={ticket?.individualOnset}
            showChildrenAnyway
            hideNextDay
            className="!mt-0 -left-2 -top-2 absolute"
            outOfStock={maxAmount === 0}
          />
        )}
      </div>
      <DrawerOrModal
        title={name}
        open={openDescription}
        closableOutside={true}
        showCloseButton
        setOpen={setOpenDescription}
      >
        {description && (
          <>
            <h2 className="heading-main capitalize">{t("beskrivelse")}</h2>
            <p className="mt-1">{description}</p>
          </>
        )}
        {hasAllergens && (
          <>
            <h2 className="heading-main mt-2">{t("Allergens")}</h2>
            <DisplayAttributes
              attributes={{
                ...ticket?.labels,
                ...ticket?.allergens,
                ...ticket?.pictograms,
              }}
              mode="all"
              supplier={supplier}
              colorClassName="text-white"
            />
          </>
        )}
      </DrawerOrModal>
    </>
  );
};

export default Card;
