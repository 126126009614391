import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { hasAccessToModule, useT } from "@kanpla/system";
import { Module, _FrontendModule } from "@kanpla/types";
import { Button, Checkbox, Form, Popper } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { isEmpty, isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useContainer } from "unstated-next";
import { ExportContext, isModuleBulkAtom } from "..";
import { OrderingContext } from "../../context";

type ModulesOptions = {
  [moduleId: Module["id"]]: {
    label: string;
    selected: boolean;
    isBulk?: boolean;
  };
};

const SelectModule = () => {
  const { modules, child, school } = useContainer(OrderingContext);
  const { availableModuleIds, moduleIds, setModuleIds } =
    useContainer(ExportContext);

  const t = useT();

  const [open, setOpen] = useState<boolean>(false);
  const setShowBulk = useSetAtom(isModuleBulkAtom);

  const modulesOptions = useMemo(
    () =>
      availableModuleIds.reduce((acc: ModulesOptions, moduleId) => {
        const module = modules?.find((m) => m.id === moduleId);

        if (!module || !child || !school) return acc;

        const { bulk, individual } = hasAccessToModule({
          child,
          school,
          module,
        });

        const isModuleSelected = moduleIds.some((mId) => {
          if (module.type !== "flex") return mId === module.id;
          return mId === `${module.id}-${bulk ? "admin" : "individual"}`;
        });

        if (module.type !== "flex")
          return {
            ...acc,
            [module.id]: {
              label: getModuleLabel(module),
              selected: isModuleSelected,
            },
          };

        if (bulk && individual)
          return {
            ...acc,
            [`${module.id}-individual`]: {
              label: getModuleLabel(module, false),
              selected: isModuleSelected,
              isBulk: false,
            },
            [`${module.id}-admin`]: {
              label: getModuleLabel(module, true),
              selected: isModuleSelected,
              isBulk: true,
            },
          };

        return {
          ...acc,
          [bulk ? `${module.id}-admin` : `${module.id}-individual`]: {
            label: getModuleLabel(module, bulk),
            selected: isModuleSelected,
            isBulk: bulk,
          },
        };
      }, {}),
    [moduleIds, availableModuleIds]
  );

  const formMethods = useForm();

  const {
    watch,
    reset,
    formState: { defaultValues },
  } = formMethods;
  const formValues = watch();

  useEffect(() => {
    const firstKey = Object.keys(modulesOptions)[0];
    setModuleIds([firstKey]);
  }, []);

  useEffect(() => {
    if (!isEmpty(defaultValues) || isEmpty(modulesOptions)) return;

    const firstKey = Object.keys(modulesOptions)[0];
    reset({ [firstKey]: true });
  }, [defaultValues, modulesOptions]);

  useEffect(() => {
    if (
      isEmpty(formValues) ||
      Object.values(formValues).every((value) => value === undefined)
    )
      return;

    const selectedModuleIds = Object.entries(formValues).reduce(
      (acc: string[], [moduleId, selected]) => {
        if (!selected) return acc;

        return [...acc, moduleId];
      },
      []
    );

    if (isEqual(selectedModuleIds, moduleIds)) return;

    const withBulkAccess = selectedModuleIds.some(
      (moduleId) => modulesOptions[moduleId]?.isBulk
    );

    setModuleIds(selectedModuleIds);
    setShowBulk(withBulkAccess);
  }, [formValues]);

  const activeModule =
    moduleIds.length === 1 ? modulesOptions[moduleIds[0]]?.label : null;

  const moduleName = activeModule
    ? activeModule
    : t("{amount} modules", {
        amount: isEqual(availableModuleIds, moduleIds)
          ? t("All")
          : moduleIds.length,
      });

  if (availableModuleIds?.length === 1) return null;

  return (
    <Popper
      open={open}
      setOpen={setOpen}
      fitActionElement
      margin={2}
      placement="bottom-start"
      actionElement={
        <Button
          icon={faFilter}
          dataCy="btn-choose-module-order-history"
          title={moduleName}
        >
          <span className="truncate max-w-[100px]">{moduleName}</span>
        </Button>
      }
    >
      <Form methods={formMethods}>
        <div className="flex flex-col gap-y-2">
          {Object.entries(modulesOptions).map(([moduleId, option]) => {
            return (
              <Form.Item
                name={moduleId}
                controlled
                controlledProps={{ valueName: "checked" }}
              >
                <Checkbox>{option.label}</Checkbox>
              </Form.Item>
            );
          })}
        </div>
      </Form>
    </Popper>
  );
};

const getModuleLabel = (module: _FrontendModule, isBulk = false) => {
  const baseLabel = module?.displayName || module.name;
  if (module.type !== "flex") return baseLabel;

  const adminLabel = module?.adminDisplayName || `${baseLabel} - Admin`;

  return isBulk ? adminLabel : baseLabel;
};

export default SelectModule;
