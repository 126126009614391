import { CombinedOfferItem, Module, _FrontendModule } from "@kanpla/types";

interface OfferHiddenReturnType {
  /** In this property already considered the productLine.isAlwaysVisible */
  isHidden: boolean;
  isAlwaysVisible: boolean;
}

export const isOfferHidden = (
  offer: CombinedOfferItem,
  dateSeconds: number,
  module: Module | _FrontendModule
): OfferHiddenReturnType => {
  const productId = offer?.productId;
  const menuItem = offer.dates?.[dateSeconds]?.menu;

  /** The item with content `X` should always be hidden */
  const isHiddenWithX = menuItem?.name === "X" || menuItem?.name === "x";
  const isAlwaysVisible = module?.productOptions?.[productId]?.default ?? false;

  /**
   * Even with productLine.isAlwaysVisible it should be
   * hidden if the menuItem content is `X`
   */
  const isHidden = isHiddenWithX || (!isAlwaysVisible && !menuItem);

  return { isHidden, isAlwaysVisible };
};
