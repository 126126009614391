import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCountdown, useT } from "@kanpla/system";
import { useAtomValue } from "jotai";
import moment from "moment";
import { deadlineInfoAtom } from "../../../lib/hooks/useDeadlineInfo";

export const DeadlineTimer = () => {
  const { deadline } = useAtomValue(deadlineInfoAtom);
  const t = useT();
  // Util to change the localization of moment.js

  const todaySeconds = moment().utc().utcOffset(2).startOf("day").unix();
  const eventTime = moment.unix(todaySeconds + deadline).unix();

  const { hours, minutes, seconds } = useCountdown({ eventTime, interval: 1 });
  const hasEnded = hours === 0 && minutes === 0 && seconds === 0;

  if (!deadline) return null;

  const customBackround = () => {
    if (hours === 0 && minutes < 59) return "bg-danger-main";
    if (hours > 0 && hours < 2) return "bg-yellow-400";
    return "bg-primary-main";
  };

  return (
    <div
      className={`w-full ${customBackround()} px-4 py-2 flex justify-center items-center text-background-primary font-semibold`}
    >
      <div className="flex items-center">
        <FontAwesomeIcon icon={faClock} color="#ffffff" />
        {!hasEnded && (
          <p className="ml-2">
            {t("Sale ends in {hours}h {minutes}m", {
              hours,
              minutes: minutes < 10 ? `0${minutes}` : minutes,
            })}
          </p>
        )}
        {hasEnded && <p className="ml-2">{t("The sale is over")}</p>}
      </div>
    </div>
  );
};

export default DeadlineTimer;
