import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { selectedDayIndexAtom } from "../../..";
import { OrderingContext } from "../../../../context";
import { VariantsTotalEmpty } from "./VariantsTotalEmpty";
import { VariantsTotalItem } from "./VariantsTotalItem";

type TotalProps = {
  setExpanded: (expanded: boolean) => void;
  productId: string;
};

export const VariantsTotal = ({ setExpanded, productId }: TotalProps) => {
  const { week, holidayDates } = useContainer(OrderingContext);
  const selectedDayIndex = useAtomValue(selectedDayIndexAtom);

  return (
    <div onClick={() => setExpanded(true)} className="flex md:flex-1">
      {week.map((seconds, dayIndex) => {
        const hasHoliday = Boolean(holidayDates?.[seconds.seconds]);

        if (hasHoliday)
          return (
            <VariantsTotalEmpty
              dayIndex={dayIndex}
              selectedDayIndex={selectedDayIndex}
            />
          );

        return (
          <VariantsTotalItem
            dayIndex={dayIndex}
            productId={productId}
            selectedDayIndex={selectedDayIndex}
          />
        );
      })}
    </div>
  );
};
