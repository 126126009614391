import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { OrderingContext } from "@kanpla/ordering";
import { T, t } from "@kanpla/system";
import { CustomProduct } from "@kanpla/types";
import { Button, DrawerOrModal, Form } from "@kanpla/ui";
import { default as classNames } from "classnames";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { uuid } from "short-uuid";
import { useContainer } from "unstated-next";
import { CustomOrderForm } from "./CustomOrderForm";
import { useAddCustomProduct } from "./useAddCustomProduct";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export type CustomOrderFormData = {
  [key: string]: string | number | undefined;
};

const titles = [t("Amount"), t("Name"), t("Category"), t("Price")];
export const DEFAULT_PRODUCT_NAME: string = t("Diverse");
export const DEFAULT_PRODUCT_CATEGORY: string = t("Custom product");
export const DEFAULT_CUSTOM_PRODUCT: CustomProduct = {
  productId: uuid(),
  name: "",
  amount: 0,
  unitPrice: 0,
  vatRate: 0.25,
  category: DEFAULT_PRODUCT_CATEGORY,
};

const defaultValues = {
  [`category_${DEFAULT_CUSTOM_PRODUCT.productId}`]: DEFAULT_PRODUCT_CATEGORY,
  [`amount_${DEFAULT_CUSTOM_PRODUCT.productId}`]: 0,
  [`unitPrice_${DEFAULT_CUSTOM_PRODUCT.productId}`]: 0,
};

export const CustomOrderModal = ({ open, setOpen }: Props) => {
  const { fromAdmin, module, partner, items } = useContainer(OrderingContext);

  const [customProducts, setCustomProducts] = useState<CustomProduct[]>([
    {
      ...DEFAULT_CUSTOM_PRODUCT,
      vatRate: module?.vatRate ?? partner?.vatRate ?? 0.25,
    },
  ]);

  const formMethods = useForm<CustomOrderFormData>({ defaultValues });

  const submit = useAddCustomProduct({
    setOpen,
  });

  if (!fromAdmin) return null;

  const onAddHandler = () => {
    const productId = uuid();
    setCustomProducts((prev) => [
      ...prev,
      {
        productId,
        name: "",
        amount: 0,
        unitPrice: 0,
        vatRate: module?.vatRate ?? partner?.vatRate ?? 0.25,
        category: DEFAULT_PRODUCT_CATEGORY,
      },
    ]);

    formMethods.setValue(`category_${productId}`, DEFAULT_PRODUCT_CATEGORY);
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("New Custom Order")}
      className="!max-w-5xl"
      stopPropagation={false}
      actions={[
        {
          label: t("Confirm"),
          form: "custom-order-form",
          onClick: () => {
            submit(formMethods.getValues(), customProducts);
            formMethods.reset();
            setCustomProducts([]);
            onAddHandler();
          },
        },
      ]}
    >
      <Form id="custom-order-form" className="mt-5" methods={formMethods}>
        <div className="grid grid-cols-5 gap-5">
          {customProducts.length > 0 &&
            titles.map((title, index) => (
              <p
                className={classNames(index === 3 && "col-span-2")}
                key={title}
              >
                {title}
              </p>
            ))}
          {customProducts.map((customProduct) => (
            <CustomOrderForm
              customProduct={customProduct}
              setCustomProducts={setCustomProducts}
              categories={[
                ...items.map((item) => item.category ?? ""),
                ...customProducts.map((product) => product.category),
                t("Dish of the day"),
              ]}
            />
          ))}
        </div>
      </Form>

      <Button type="primary" shape="plain" icon={faPlus} onClick={onAddHandler}>
        <T _str="Add product" />
      </Button>
    </DrawerOrModal>
  );
};
