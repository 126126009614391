import { getDayIndexFromSeconds } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { last } from "lodash";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useFlexStandardOrders } from "./useFlexStandardOrders";

interface Options {
  product?: CombinedOfferItem;
  dateSeconds?: number;
}

export const useFindFlexStandard = ({ product, dateSeconds }: Options) => {
  const { orders } = useContainer(OrderingContext);
  const { standardOrderLines } = useFlexStandardOrders();

  if (!product || !dateSeconds)
    return {
      isStandard: false,
      hasStandardOrder: false,
      hasStandardTemplate: false,
    };

  const dayIndex = getDayIndexFromSeconds(dateSeconds);

  const orderForDay = orders.find((order) => order.dateSeconds === dateSeconds);

  const userOrderExists = last(orderForDay?.changes)?.changedBy === "user";

  /** The standard order document has been created by the server */
  const hasStandardOrder =
    last(orderForDay?.changes)?.changedBy === "server" &&
    orderForDay?.orderLines?.[0]?.productLineId === product.productLineId;

  /** The standard order is not created yet, but the user has it selected */
  const hasStandardTemplate =
    !userOrderExists &&
    standardOrderLines?.[dayIndex]?.[0]?.productId === product.productId;

  /** The product is standard */
  const isStandard = hasStandardOrder || hasStandardTemplate;

  return {
    hasStandardOrder,
    hasStandardTemplate,
    isStandard,
  };
};
