import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { Dispatch, SetStateAction } from "react";
import { useFlexStandardOrders } from "../../lib/flex/useFlexStandardOrders";
import { periodsAtom } from "./StandardHolidays";

const StandardHolidaysButton = ({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const t = useT();

  const { holidays } = useFlexStandardOrders();

  const periods = useAtomValue(periodsAtom);

  const secondsAreNull = () => {
    const holidaysNull =
      holidays.some(
        (holiday) => !holiday?.fromSeconds && !holiday?.toSeconds
      ) || !holidays.length;

    const periodsNull = periods.some(
      (period) => !period?.fromSeconds && !period?.toSeconds
    );

    return { holidaysNull, periodsNull };
  };

  const hasHolidays =
    !secondsAreNull().holidaysNull || !secondsAreNull().periodsNull;

  const text = hasHolidays ? t("Edit holidays") : t("Set holidays");

  return (
    <div className="pt-3">
      <Button onClick={() => setOpen(true)}>
        <FontAwesomeIcon icon={faPen} className="mr-2" />
        {text}
      </Button>
    </div>
  );
};

export default StandardHolidaysButton;
