/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getAllergensIntersection } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import {
  hideAllergensWarningAtom,
  openAllergensWarningAtom,
  promiseInfoAllergensWarningAtom,
  propsAllergensWarningAtom,
} from "@kanpla/ui";
import { useAtomValue, useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";

export const useProcessAllergens = ({
  product,
  dateSeconds,
}: {
  product: CombinedOfferItem;
  dateSeconds: number;
}) => {
  const hide = useAtomValue(hideAllergensWarningAtom);
  const setOpen = useSetAtom(openAllergensWarningAtom);
  const setPromiseInfo = useSetAtom(promiseInfoAllergensWarningAtom);
  const setProps = useSetAtom(propsAllergensWarningAtom);

  const { child, dayIndex, week, supplier } = useContainer(OrderingContext);

  const menuItem = product?.dates?.[dateSeconds as number]?.menu;
  const allergens = getAllergensIntersection({ child, menuItem, product });
  const hasAllergens = Boolean(Object.keys(allergens).length);
  const shouldHideAllergens = supplier?.screens?.shouldHideAllergens === true;

  const waitAllergensWarning = async (): Promise<boolean> => {
    if (!hasAllergens || hide || shouldHideAllergens)
      return Promise.resolve(true);

    setProps({
      child,
      dayIndex,
      week,
      product,
      // @ts-ignore
      supplier,
    });

    return new Promise((resolve, reject) => {
      setOpen(true);
      setPromiseInfo({ resolve, reject });
    });
  };

  return {
    waitAllergensWarning,
  };
};
