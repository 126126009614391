import { DayIndex, FlexOption } from "@kanpla/types";
import { Radio } from "@kanpla/ui";
import classNames from "classnames";
import { useSetAtom } from "jotai";
import { useGetDisabledByDeadline } from "../../softDeadline/useDisabledByDeadline";
import { globalVariantChoicesAtom } from "./globalVariantChoicesAtom";
import { useGetGlobalVariantForDay } from "./useGetGlobalVariantForDay";

interface Props {
  dayIndex: DayIndex;
  option: FlexOption;
  className?: string;
  dateSeconds: number;
}

/** The radio-select of a day of GlobalVariant. */
export const GlobalVariantDay = ({
  dayIndex,
  option,
  className,
  dateSeconds,
}: Props) => {
  const getDisabledByDeadline = useGetDisabledByDeadline();

  const setGlobalVariantChoices = useSetAtom(globalVariantChoicesAtom);

  const getGlobalVariantForDay = useGetGlobalVariantForDay();
  const activeChoice = getGlobalVariantForDay(dayIndex);
  const { disabled } = getDisabledByDeadline(dayIndex);

  return (
    <div
      className={classNames(
        "text-xs font-medium text-center items-center justify-center flex w-16 md:w-auto flex-1",
        className
      )}
    >
      <div className="w-8">
        <Radio.Group
          onChange={(e) => {
            setGlobalVariantChoices((prev) => ({
              ...prev,
              [dayIndex]: e.target.value,
            }));
          }}
          name={`global-variant-${dateSeconds}-${dayIndex}`}
          value={activeChoice?.id}
          disabled={disabled}
          display="vertical"
          options={
            option?.choices?.map((choice) => ({
              label: "",
              value: choice.id,
            })) || []
          }
        />
      </div>
    </div>
  );
};
