import { FlexOption } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";

export interface GlobalVariantsData {
  isActive: boolean;
  globalVariantId: string;
  option: FlexOption;
}

export const useGlobalVariants = (): GlobalVariantsData => {
  const { module, mealOptions } = useContainer(OrderingContext);

  const globalVariantId =
    module?.plugins?.globalVariant?.productOptionIds?.[0] || "";

  const option = mealOptions?.find(
    (o) => o.id === globalVariantId
  ) as FlexOption;

  const isActive =
    Boolean(module?.plugins?.globalVariant?.active) &&
    typeof globalVariantId === "string" &&
    Boolean(option?.choices?.length);

  return {
    isActive,
    option,
    globalVariantId,
  };
};
