import { useT } from "@kanpla/system";
import { PaymentMethod } from "@kanpla/types";
import { Alert, usePriceFormatter } from "@kanpla/ui";
import { useContainer } from "unstated-next";

import { OrderingContext } from "../../context";

interface Props {
  hideBalance?: boolean;
  paymentMethod: PaymentMethod;
}

const PaidFromAlert = ({ hideBalance, paymentMethod }: Props) => {
  const {
    module,
    balance,
    activePlugins,
    offer: { discounts },
  } = useContainer(OrderingContext);
  const t = useT();

  const balanceFormatted = usePriceFormatter(balance);

  const hasBillingDiscount = discounts.some(
    (d) => d.type === "discount-hybridBilling"
  );
  const withHybridBilling = activePlugins["hybridBilling"];

  const hideAlert = withHybridBilling && hasBillingDiscount;

  if (hideAlert) return null;

  return (
    <Alert
      type="info"
      message={
        (paymentMethod || module?.paymentMethod) === "billing"
          ? t("The order is invoiced to your company")
          : hideBalance
          ? t("Paid from balance")
          : t("Paid from balance ({value} left)", {
              value: balanceFormatted,
            })
      }
    />
  );
};

export default PaidFromAlert;
