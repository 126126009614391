import { OrderingContext } from "@kanpla/ordering";
import classnames from "classnames";
import { useContainer } from "unstated-next";
import { getItemCategories } from "../../lib/flexBulk/getItemCategories";
import { MinimumOrderAlert } from "../minimumOrder/MinimumOrderAlert";
import { SoftDeadlineAlert } from "../softDeadline/SoftDeadlineAlert";
import { CustomDeliveryNote } from "./CustomDeliveryNote";
import { WeekDateDisplay } from "./WeekDateDisplay";
import { WeekTotals } from "./WeekTotals";
import { GlobalVariant } from "./globalVariant";
import { Product } from "./product/Product";

export const firstColumnStyles = "w-fit md:w-5/12 md:flex-0";

export const Week = () => {
  const { school, items } = useContainer(OrderingContext);
  const { itemsWithCategory } = getItemCategories({ items });
  const { flexBulkMinimum } = school?.contract || {};

  /**
   * If the menu is empty or the ordering is not standard but the standard modal is open,
   * display the loaders.
   */
  const displayLoaders = !itemsWithCategory.length;

  return (
    <>
      <MinimumOrderAlert />
      <SoftDeadlineAlert />
      <div className={`mb-16 ${!flexBulkMinimum ? "mt-12" : ""}`}>
        {/* Dates */}
        <WeekDateDisplay />
        {/* Totals */}
        <WeekTotals />
        {displayLoaders ? (
          <>
            <div className="loader h-16 rounded-lg mb-2" />
            <div className="loader h-16 rounded-lg mb-2" />
            <div className="loader h-16 rounded-lg mb-2" />
          </>
        ) : (
          <>
            <GlobalVariant />
            {itemsWithCategory.map((item, index) => (
              <Product
                item={item}
                lastItemClassnames={classnames(
                  !index && "rounded-t-lg border-t",
                  itemsWithCategory.length - 1 === index && "rounded-b-lg"
                )}
                key={item.productLineId}
              />
            ))}
            <CustomDeliveryNote />
          </>
        )}
      </div>
    </>
  );
};
