import { db, getChildDisplayName, getErrorMessage, useT } from "@kanpla/system";
import { Form, Input, Modal, message } from "@kanpla/ui";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

type FormData = {
  name: string;
};

const EmptyNamePopup = () => {
  const { child } = useContainer(AppContext);

  const t = useT();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!child) return;
    if (child.displayName && child.name && child.displayName !== " ") return;
    setOpen(true);
  }, [child?.name, child?.displayName]);

  const submit = async ({ name }: FormData) => {
    setLoading(true);

    try {
      if (!name) throw new Error(t("Fill out the name"));
      await db
        .collection("children")
        .doc(child?.id)
        .update({
          name,
          displayName: getChildDisplayName(name),
        });
      message.success(t("Information about your user is saved"));
      setLoading(false);
      setOpen(false);
    } catch (e) {
      console.error(e);
      message.error(getErrorMessage(e));
      setLoading(false);
    }
  };

  if (!child || isEmpty(child)) return null;

  return (
    <Modal
      open={open}
      setOpen={() => {
        message.info(t("Please add information about your user"));
      }}
      title={t("Missing user name")}
      subtitle={t(
        "It looks like your user on {schoolName} is missing a name. Please add your name.",
        {
          schoolName: child.school_name,
        }
      )}
      actions={[
        {
          label: t("Save"),
          type: "primary",
          onClick: () => null,
          htmlType: "submit",
          form: "enter-child-name-form",
          loading,
        },
      ]}
    >
      <Form<FormData> id="enter-child-name-form" onSubmit={submit}>
        <Form.Item name="name" rules={{ required: t("Please enter the name") }}>
          <Input placeholder={t("Enter the name")} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EmptyNamePopup;
