import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";

import { callInternalApi } from "@kanpla/system";

import {
  CrowdTrackerAverageParams,
  CrowdTrackerAverageResponse,
} from "apps/frontend/pages/api/internal/load/crowd-tracker-average";
import type {
  CrowdTrackerDayParams,
  CrowdTrackerDayResponse,
} from "apps/frontend/pages/api/internal/load/crowd-tracker-day";

const POLL_INTERVAL_MS = 1000 * 60 * 2;

export function useCrowdTrackerDay({
  moduleId,
  schoolId,
  timezone,
}: Partial<CrowdTrackerDayParams>) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["crowd-tracker-day", schoolId, moduleId, timezone],
    queryFn: () => {
      if (!moduleId || !schoolId) {
        throw new Error("Missing moduleId or schoolId");
      }

      return callInternalApi<CrowdTrackerDayParams, CrowdTrackerDayResponse>(
        "load/crowd-tracker-day",
        { moduleId, schoolId, timezone, dateSeconds: moment().unix() }
      );
    },
    enabled: !!moduleId && !!schoolId,
    // poll the api if the current time is between TIME_INTERVAL
    refetchInterval: (res) => {
      if (isCurrentTimeInInterval(res?.currentTime)) {
        return POLL_INTERVAL_MS;
      }

      return false;
    },
    /**
     * only refetch on focus if:
     * - the current time is between TIME_INTERVAL
     * - there is average data
     */
    refetchOnWindowFocus: (query) => {
      const averageQuery = queryClient.getQueryState([
        "crowd-tracker-average",
        schoolId,
        moduleId,
      ]);

      if (!averageQuery || averageQuery.status !== "success") {
        return false;
      }

      return isCurrentTimeInInterval(query.state.data?.currentTime);
    },
  });
}

export function useCrowdTrackerAverage({
  moduleId,
  schoolId,
  timezone,
}: Partial<CrowdTrackerAverageParams>) {
  return useQuery({
    queryKey: ["crowd-tracker-average", schoolId, moduleId, timezone],
    queryFn: () => {
      if (!moduleId || !schoolId) {
        throw new Error("Missing moduleId or schoolId");
      }

      return callInternalApi<
        CrowdTrackerAverageParams,
        CrowdTrackerAverageResponse
      >("load/crowd-tracker-average", {
        moduleId,
        schoolId,
        timezone,
        dateSeconds: moment().unix(),
      });
    },
    enabled: !!moduleId && !!schoolId,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

const TIME_INTERVAL = [11, 14];
function isCurrentTimeInInterval(currentTime?: number) {
  if (!currentTime) {
    return false;
  }

  return currentTime > TIME_INTERVAL[0] && currentTime < TIME_INTERVAL[1];
}
