import { CombinedOfferItem, FlexOption } from "@kanpla/types";

interface Props {
  /** Target product */
  product: CombinedOfferItem;
  /** mealOptions from frontend AppContext */
  options?: FlexOption[];
}

export const getAvailableOptions = ({ product, options }: Props) => {
  const productOptionIds = product.options || [];
  const availableOptions =
    options?.filter(
      (o) => productOptionIds.includes(o.id) && !o.extension?.includes("hidden")
    ) || [];
  return availableOptions;
};
