import { db, fb, T, useT } from "@kanpla/system";
import { DrawerOrModal, Form, Input, message } from "@kanpla/ui";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
} from "firebase/auth";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

type FormData = {
  email: string;
  emailConfirm: string;
  password: string;
};

const ChangeEmail = ({ open, setOpen }) => {
  const t = useT();
  const { auth } = useContainer(AppContext);
  const [loading, setLoading] = useState(false);

  const verifyEmail = fb.functions("europe-west1").httpsCallable("verifyEmail");

  const updateEmailInUserDoc = async (email: string) => {
    const userDoc = db.collection("users").doc(auth.user.uid);
    return await userDoc.update({ email: email });
  };

  const onFinish = async (data: FormData) => {
    setLoading(true);
    const { email, password } = data;

    try {
      const credential = EmailAuthProvider.credential(
        auth.user.email,
        password
      );

      await reauthenticateWithCredential(auth.user, credential);
      await updateEmail(auth.user, email);
      await verifyEmail({ email });
      await updateEmailInUserDoc(email);

      message.success(t("Your email was changed"));
      setOpen(false);
    } catch (err) {
      if (err?.code === "auth/wrong-password") {
        message.error(t("Your old password is incorrect"));
        return;
      }
      if (err?.code === "auth/email-already-in-use") {
        message.error(t("This email address is already in use"));
        return;
      }
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("Change e-mail")}
      subtitle={
        <T
          _str="Your current email adress is {email}. Would you like to change it?"
          email={<b>{auth?.user?.email}</b>}
        />
      }
      actions={[
        {
          label: t("Change e-mail"),
          onClick: () => null,
          className: "primary",
          loading,
          dataCy: "change-email-button",
          form: "change-email-form",
          htmlType: "submit",
        },
      ]}
    >
      <Form<FormData>
        id="change-email-form"
        onSubmit={onFinish}
        autoComplete="off"
      >
        <Form.Item
          id="email"
          name="email"
          rules={{
            required: t("Please enter the {value}", {
              value: "email",
            }),
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
              message: t("Invalid {value}", {
                value: "email",
              }),
            },
            validate: (value) =>
              value !== auth.user.email ||
              t("You are already using this email"),
          }}
        >
          <Input.Email label={t("New e-mail")} autoComplete="off" required />
        </Form.Item>

        <Form.Item
          id="emailConfirm"
          name="emailConfirm"
          rules={{
            required: t("Please enter the {value}", { value: "email" }),
            validate: (value, formValues) =>
              (value && formValues["email"] === value) ||
              t("New emails must be the same"),
          }}
        >
          <Input.Email label={t("Confirm email")} autoComplete="off" required />
        </Form.Item>

        <Form.Item
          id="password"
          name="password"
          rules={{
            required: t("Please enter the {value}", {
              value: "password",
            }),
          }}
        >
          <Input.Password autoComplete="off" required />
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default ChangeEmail;
