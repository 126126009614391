import {
  CombinedOfferItem,
  FlexOption,
  MenuItemVariants,
  OrderLine,
  Product,
} from "@kanpla/types";
import { useState } from "react";
import Option from "./Option";

interface InternalRewrittenProduct {
  /** Color of the product, default is some type of gray */
  color?: Product["color"];
  name?: Product["name"];
  id?: Product["id"];
  price?: Product["unitPrice"];
  vatRate: CombinedOfferItem["vatRate"];
}

interface Props {
  /** Info about the product */
  product?: InternalRewrittenProduct;
  /** Available variants */
  options?: FlexOption[];
  value: OrderLine["options"];
  onChange: (newValue: OrderLine["options"]) => void;
  variantNames?: MenuItemVariants;
  editing?: boolean;
}

export const ProductOptions = ({
  product,
  options = [],
  value,
  onChange,
  variantNames,
}: Props) => {
  const [isErrored, setIsErrored] = useState<string[]>([]);

  const processError = (moduleId: string, add: boolean) => {
    if (!add) {
      const newArray = isErrored.filter((e) => e !== moduleId);
      return setIsErrored(newArray);
    }

    if (add) {
      const newArray = isErrored.filter((e) => e !== moduleId);
      return setIsErrored([...newArray, moduleId]);
    }
  };

  const isFirst = (index: number) => {
    return index === 0;
  };

  return (
    <>
      {options
        .filter((o) => !o.extension?.includes("hidden"))
        .map((option: FlexOption, index) => (
          <Option
            key={option.id}
            option={option}
            color={product?.color}
            setError={(id, add) => processError(id, add)}
            orderChoices={value}
            variantNames={variantNames}
            onChange={onChange}
            isFirst={isFirst(index)}
            vatRate={product?.vatRate}
          />
        ))}
    </>
  );
};
