import {
  calculateAmountOfOrderItems,
  calculateOrderTotalByOrder,
  downloadBlob,
  priceFormatter,
  tx,
  useT,
} from "@kanpla/system";
import { activeCurrencyAtom } from "@kanpla/ui";
import axios from "axios";
import { useAtomValue } from "jotai";
import { groupBy, round, sum } from "lodash";
import moment from "moment";
import { useState } from "react";
import slug from "slug";
import { useContainer } from "unstated-next";
import { ExportContext } from "..";
import { OrderingContext } from "../../context";
import { showIndividualUsersAtom } from "../Content";

const usePrintAndDownload = () => {
  const t = useT();
  const { school } = useContainer(OrderingContext);
  const { items, totalAmount, totalPrice, timeRange } =
    useContainer(ExportContext);

  const [printLoading, setPrintLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);

  const showIndividualUsers = useAtomValue(showIndividualUsersAtom);

  const defaultStartDate = moment()
    .utc()
    .subtract(3, "months")
    .startOf("day")
    .unix();
  const defaultEndDate = moment().utc().add(3, "months").startOf("day").unix();

  const formatStartDate = moment
    .unix(timeRange?.fromSeconds || defaultStartDate)
    .format("DD-MM-YYYY");

  const formatEndDate = moment
    .unix(timeRange?.toSeconds ? timeRange?.toSeconds - 86400 : defaultEndDate)
    .format("DD-MM-YYYY");

  const activeCurrency = useAtomValue(activeCurrencyAtom);
  const currentLocale = tx.getCurrentLocale();

  const printAndDownload = async (type: string) => {
    const fileName = `${t("employees")}-${t("bookings")}-${slug(
      school?.name || ""
    )}-${formatStartDate}-${formatEndDate}`;

    let formatOrders: string[][];

    if (showIndividualUsers) {
      formatOrders = Object.entries(items).map(([key, order]) => {
        return [
          `${order?.childDisplayName}`,
          `${calculateAmountOfOrderItems(order.orderLines)}`,
          `${round(
            calculateOrderTotalByOrder(order.orderLines, order) / 100,
            2
          )}`,
        ];
      });
    } else {
      const groupedOrders = groupBy(items, "childName");
      formatOrders = Object.entries(groupedOrders).map(
        ([childName, orders]) => {
          return [
            `${childName}`,
            `${sum(
              orders.map((o) => calculateAmountOfOrderItems(o.orderLines))
            )}`,
            `${round(
              sum(
                orders.map(
                  (o) => calculateOrderTotalByOrder(o.orderLines, o) / 100
                )
              ),
              2
            )}`,
          ];
        }
      );
    }

    try {
      if (type === "pdf") {
        setPrintLoading(true);

        const buildPdf = {
          content: [
            {
              text: `\n\n${t("Orders overview")}`,
              style: "header",
            },
            {
              text: `${t(
                "Orders for {schoolName} in the period {start} - {end}",
                {
                  schoolName: school?.name,
                  start: formatStartDate,
                  end: formatEndDate,
                }
              )}\n\n`,
            },
            "\n\n",
            {
              style: "table",
              table: {
                widths: [300, "auto", "auto"],
                headerRows: 1,
                body: [
                  [
                    {
                      text: t("Name"),
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: t("Total orders"),
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: t("Total price"),
                      style: "tableHeader",
                      alignment: "center",
                    },
                  ],
                  ...formatOrders,
                  [
                    {
                      text: "\nTotal",
                      style: "tableFooter",
                    },
                    {
                      text: `\n${totalAmount}`,
                      style: "tableFooter",
                    },
                    {
                      text: `\n${priceFormatter(
                        totalPrice || 0,
                        activeCurrency,
                        currentLocale
                      )}`,
                      style: "tableFooter",
                    },
                  ],
                ],
              },
            },
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            table: {
              margin: [0, 5, 0, 15],
            },
            tableHeader: {
              bold: true,
              fontSize: 13,
              color: "black",
            },
            tableFooter: {
              bold: true,
              fontSize: 16,
              color: "black",
            },
          },
        };

        const res = await axios({
          method: "POST",
          url: "https://europe-west1-kanpla-87be3.cloudfunctions.net/createPdf",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: buildPdf,
        });

        const file: Blob = res.data;

        downloadBlob(file, `${fileName}.pdf`);
      } else {
        setExcelLoading(true);

        const formatOrders = Object.entries(items).map(([key, order]) => {
          return [
            `${order.childDisplayName}`,
            calculateAmountOfOrderItems(order.orderLines),
            round(calculateOrderTotalByOrder(order.orderLines, order) / 100, 2),
          ];
        });

        const res = await axios({
          method: "POST",
          url: "https://europe-west1-kanpla-87be3.cloudfunctions.net/createExcel",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: {
            docTitle: t("Orders report"),
            content: [
              [
                t("Periods: {start} - {end}", {
                  start: formatStartDate,
                  end: formatEndDate,
                }),
                t("Total orders"),
                t("Total price"),
              ],
              ...formatOrders,
              ["Total", totalAmount, totalPrice],
            ],
          },
        });

        const data =
          typeof res.data === "object"
            ? res.data
            : Buffer.from(res.data, "utf-8");

        const file: Blob = new Blob([data], {
          type: "application/octet-stream",
        });

        downloadBlob(file, `${fileName}.xlsx`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setPrintLoading(false);
      setExcelLoading(false);
    }
  };

  return { printAndDownload, printLoading, excelLoading };
};

export default usePrintAndDownload;
