import { T, getErrorMessage, t } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { Button, message, useConfirmation } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import moment from "moment";
import { useContainer } from "unstated-next";
import {
  accessCodeModalOpenAtom,
  childIdToEditAtom,
  orderModalOpenAtom,
} from ".";
import { OrderingContext } from "../../context";
import { useChildren } from "./useChildren";

interface Props {
  child: Child;
}

export const RowItem = ({ child }: Props) => {
  const { id: childId, name: childName } = child;

  const { userId, module, school } = useContainer(OrderingContext);
  const confirm = useConfirmation();

  const setChildIdToEdit = useSetAtom(childIdToEditAtom);
  const setIsOrderModalOpen = useSetAtom(orderModalOpenAtom);
  const setIsCodesModalOpen = useSetAtom(accessCodeModalOpenAtom);

  const { submitChild, hasAccessToIndividualFlex } = useChildren();

  const deleteChild = async () => {
    try {
      await confirm({
        title: t("You are about to delete a user"),
        children: (
          <p>
            <T
              _str="Are you sure you want to delete {name}?"
              name={childName}
            />
          </p>
        ),
        okButtonType: "danger",
        okButtonText: t("Delete {name}", { name: childName }),
      });

      await submitChild({
        ...child,
        deleted: true,
        deletedAt: moment().unix(),
        deletedBy: userId,
      });

      message.success(t("User successfully deleted"));
    } catch (e) {
      const err = getErrorMessage(e);
      message.error(err);
    }
  };

  return (
    <tr key={childId} className="h-10">
      {/* Child Name */}
      <td className="text-left">{childName}</td>

      {/* Edit Order */}
      <td>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            // Check if the user has access to the individual ordering module
            const hasAccess = hasAccessToIndividualFlex({
              childId,
              module,
              school,
            });

            if (!hasAccess)
              message.error(
                t(
                  "The user doesn't have access to the individual ordering module"
                )
              );

            setChildIdToEdit(childId);
            setIsOrderModalOpen(true);
          }}
        >
          {t("Edit")}
        </Button>
      </td>

      {/* Edit Codes */}
      <td>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            setChildIdToEdit(childId);
            setIsCodesModalOpen(true);
          }}
        >
          {t("Edit")}
        </Button>
      </td>

      {/* Soft Delete */}
      <td>
        <Button size="small" type="danger" onClick={deleteChild}>
          {t("Delete")}
        </Button>
      </td>
    </tr>
  );
};
