import { faPen, faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { useBasketPreventClick } from "@kanpla/ordering";
import {
  getErrorMessage,
  getModulesByChildSchoolId,
  sortModules,
  useT,
} from "@kanpla/system";
import { Child } from "@kanpla/types";
import { Button, message } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { Dispatch, SetStateAction, useState } from "react";
import { useContainer } from "unstated-next";
import { isMenuOpenAtom } from ".";
import { AppContext } from "../contextProvider";
import EditKid from "../modals/EditKid";

interface EditProps {
  child: Child;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  childActive: boolean;
  child: Child;
  EditPopup?: (data: EditProps) => JSX.Element;
}

const ChildActions = ({
  childActive = false,
  child,
  EditPopup = EditKid,
}: Props) => {
  const t = useT();
  const { setChild } = useContainer(AppContext);
  const [open, setOpen] = useState(false);
  const setIsMenuOpen = useSetAtom(isMenuOpenAtom);

  return (
    <div className="flex gap-x-2">
      {childActive && (
        <>
          <Button
            type="primary"
            icon={faPen}
            onClick={() => setOpen(true)}
            dataCy="btn-settings-child-edit"
            size="small"
          >
            {t("Edit")}
          </Button>
          <EditPopup open={open} setOpen={setOpen} child={child} />
        </>
      )}

      {!childActive && (
        <Button
          icon={faRepeat}
          dataCy="switch-user-btn"
          size="small"
          onClick={async () => {
            await setChild(child);
            setIsMenuOpen(false);
          }}
        >
          {t("Switch")}
        </Button>
      )}
    </div>
  );
};

export default ChildActions;
