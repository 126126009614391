/* eslint-disable react-hooks/rules-of-hooks */
import { FlexStandard, Module } from "@kanpla/types";
import { useFetch } from "../api/useFetch";

export interface LoadOrderStandardIndividualProps {
  schoolId: string;
  moduleId?: string;
  childId: string;
  _reloader?: number;
  /** When this function is being called from admin user overview */
  fromAdmin?: boolean;
}

export interface LoadOrderStandardIndividualReturn {
  standardOrderLines: FlexStandard["standardOrderLines"];
  holidays: FlexStandard["holidays"];
  moduleId: Module["id"];
}

type Props = LoadOrderStandardIndividualProps;

export const getFlexStandard = (props: Props) => {
  const { schoolId, moduleId, childId, _reloader, fromAdmin } = props;

  const {
    data,
    loading: standardLoading,
    setData: setIndividualStandard,
  } = useFetch<
    LoadOrderStandardIndividualProps,
    LoadOrderStandardIndividualReturn
  >(
    "load/orderStandardsIndividual",
    {
      schoolId,
      moduleId,
      childId,
      _reloader,
    },
    {
      refreshInterval: 60000 * (fromAdmin ? 8 : 3),
      // Add throttling to focus to avoid reading too many standards
      focusThrottleInterval: fromAdmin ? 30000 : 10000,
    }
  );
  const standardOrderLines = data?.standardOrderLines || {};
  const holidays = data?.holidays || [];

  return {
    standardOrderLines,
    holidays,
    moduleId: data?.moduleId,
    standardLoading,
    setIndividualStandard,
  };
};
