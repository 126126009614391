import { DayIndex, OrderLine } from "@kanpla/types";
import { stringifyChangeInADay } from "../../../../data/applyChangesToOrders";
import { WeeklyOrders } from "../../../../data/atomWeeklyOrders";

interface Props {
  productId: string;
  dayIndex: DayIndex;
  options: OrderLine["options"];
  orders: WeeklyOrders;
}

export const valueFromOrders = ({
  productId,
  dayIndex,
  options,
  orders,
}: Props) => {
  if (!orders) return undefined;
  const orderForTheDay = orders[dayIndex];

  const targetOrderLine = orderForTheDay?.find(
    (orderLine) =>
      stringifyChangeInADay(orderLine) ===
      stringifyChangeInADay({ productId, options })
  );

  const amount = targetOrderLine?.amount;

  if (orderForTheDay) return amount ?? 0;

  return undefined;
};
