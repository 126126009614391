import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import { t } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { ButtonLine } from "@kanpla/ui";
import { atom, useAtom } from "jotai";
import { useState } from "react";
import { AccessCodesModal } from "./AccessCodesModal";
import { IndividualFlexModal } from "./IndividualFlexModal";
import { Modal } from "./Modal";

export const orderModalOpenAtom = atom<boolean>(false);

export const accessCodeModalOpenAtom = atom<boolean>(false);

export const childIdToEditAtom = atom<Child["id"] | null>(null);

const FlexBulksUserOverview = () => {
  const [open, setOpen] = useState(false);

  const [isOrderModalOpen, setIsOrderModalOpen] = useAtom(orderModalOpenAtom);
  const [isCodesModalOpen, setIsCodesModalOpen] = useAtom(
    accessCodeModalOpenAtom
  );

  return (
    <>
      <ButtonLine
        onClick={() => setOpen(true)}
        label={t("User overview")}
        icon={faUsers}
      />
      <Modal open={open} setOpen={setOpen} />
      <IndividualFlexModal
        open={isOrderModalOpen}
        setOpen={setIsOrderModalOpen}
      />
      <AccessCodesModal open={isCodesModalOpen} setOpen={setIsCodesModalOpen} />
    </>
  );
};

export default FlexBulksUserOverview;
