import { TriggerPagePrint, dayIndexesArr, useT } from "@kanpla/system";
import { CombinedOfferItem, MenuItem, Supplier, Week } from "@kanpla/types";
import { Portal } from "@mui/material";
import { Modal } from "antd";
import classNames from "classnames";
import { groupBy } from "lodash";
import moment from "moment";
import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import tinycolor from "tinycolor2";
import { DisplayAttributes } from "..";

interface WrapperProps {
  children: ReactNode;
  isDigital?: boolean;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  title?: string;
}

export const PrintWrapper = ({
  children,
  isDigital,
  open,
  setOpen = () => null,
  title,
}: WrapperProps) => {
  if (isDigital)
    return (
      <Modal
        title={title}
        open={open}
        onCancel={() => setOpen(false)}
        width="auto"
        style={{ minWidth: 500 }}
        className="max-w-5xl"
        footer={null}
      >
        {children}
      </Modal>
    );

  if (typeof document === `undefined`) return null;

  return (
    <Portal node={document.body}>
      <div className="print">{children}</div>
    </Portal>
  );
};

interface Props {
  menus: Array<CombinedOfferItem>;
  week: Week;
  children: ReactElement<any>;
  isDigital?: boolean;
  supplier?: Supplier;
}

export const Print = ({
  menus,
  week,
  children,
  supplier,
  isDigital = false,
}: Props) => {
  const t = useT();

  const [openModal, setOpenModal] = useState(false);
  const [toBePrinted, setToBePrinted] = useState(false);

  const sortedMenus = groupBy(menus, "productId");
  const weekSecondsArray = Object.values(week || {}).map((day) => day.seconds);

  const headerArr = useMemo(() => {
    return week.map((dayTimestamp) =>
      moment(dayTimestamp.toDate()).format("ddd D/M")
    );
  }, [week]);

  return (
    <div>
      {React.cloneElement(children, {
        onClick: isDigital
          ? () => setOpenModal(true)
          : () => {
              setToBePrinted(true);
              setTimeout(() => TriggerPagePrint(), 300);
              setTimeout(() => setToBePrinted(false), 2000);
            },
      })}
      <PrintWrapper
        isDigital={isDigital}
        open={openModal}
        setOpen={setOpenModal}
        title={t("Menu for the week {week}", {
          week: moment.unix(week[0]?.seconds).format("W"),
        })}
      >
        <div className="rounded-t-lg overflow-hidden">
          <table
            className={classNames(
              "table w-full whitespace-normal table-fixed",
              !toBePrinted && "print:hidden"
            )}
          >
            <thead>
              <tr>
                {headerArr.map((dayFormatted) => (
                  <th
                    key={dayFormatted}
                    className="whitespace-nowrap w-1/5 px-4 py-2 print:!bg-white print:text-black"
                    style={{ minWidth: "200px" }}
                  >
                    {dayFormatted}
                  </th>
                ))}
              </tr>
            </thead>

            {Object.entries(sortedMenus || {}).map(([productId, lineMenus]) => {
              const productInfo = lineMenus?.[0];

              const hasMenus = lineMenus.some(
                (menu) =>
                  Object.entries(menu?.dates || {}).filter(
                    ([dateSeconds, dateMenu]) => {
                      if (!dateMenu.available) return false;

                      if (!dateMenu.menu) return false;

                      if (!weekSecondsArray.includes(parseInt(dateSeconds)))
                        return false;

                      return true;
                    }
                  )?.length
              );

              const productName = productInfo?.name || "";

              if (!hasMenus) return null;

              const productColor = productInfo?.color || "";

              const productTextColor = tinycolor(
                productColor || "#fff"
              ).isLight()
                ? "#000000ee"
                : "#ffffffee";

              return (
                <tbody
                  style={{ pageBreakInside: "avoid" }}
                  key={`menu-${productId}`}
                  className="border !bg-white"
                >
                  <tr className="separator">
                    <th
                      colSpan={100}
                      style={{
                        backgroundColor: productColor,
                        color: productTextColor,
                        borderBottom: "1px solid black",
                      }}
                    >
                      <div className="p-0.5">{productName}</div>
                    </th>
                  </tr>
                  <tr key={productId} className="divide-x separator">
                    {dayIndexesArr.map((dayIndex) => {
                      const daySeconds = Number(week?.[dayIndex]?.seconds);
                      const menu = lineMenus.find(
                        (menu) => menu.dates?.[daySeconds]?.available
                      )?.dates?.[daySeconds];

                      if (!menu?.available) return <td></td>;
                      return (
                        <Item
                          key={dayIndex}
                          item={menu?.menu}
                          product={productInfo}
                          supplier={supplier}
                        />
                      );
                    })}
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </PrintWrapper>
    </div>
  );
};

type ItemProps = {
  item: MenuItem;
  product: CombinedOfferItem;
  supplier?: Supplier;
};

const Item = ({ item, product, supplier }: ItemProps) => {
  const attributes = {
    ...product?.labels,
    ...product?.allergens,
    ...product?.pictograms,
    ...item?.labels,
    ...item?.allergens,
    ...item?.pictograms,
  };

  return (
    <td
      className="w-0 relative h-full p-1 text-black"
      style={{ verticalAlign: "top" }}
    >
      <p className="whitespace-normal px-1 text-sm text-black">
        <span className="font-semibold">{item?.name || item?.description}</span>
      </p>
      {item?.name && item?.description && (
        <p className="whitespace-pre-wrap text-xs p-0.5 px-1 text-black">
          {item?.description}
        </p>
      )}
      <DisplayAttributes
        attributes={attributes}
        supplier={supplier}
        size="small"
        mode="all"
        hideHeader
        noBgHeader={true}
        neutral
      />
    </td>
  );
};

export default Print;
