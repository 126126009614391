/* eslint-disable react-hooks/rules-of-hooks */
import {
  capitalizeFirstLetter,
  dayLabels,
  isOfferHidden,
  useT,
} from "@kanpla/system";
import { CombinedOfferItem, DayIndex, Timestamp } from "@kanpla/types";
import classnames from "classnames";
import { Dictionary, get, isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import DayDetailCategory from "./DayDetailCategory";
import { MenuPreviewContext } from "./MenuPreviewContext";
import { useCategoriesDictionary } from "./useCategories";

interface Props {
  date: Timestamp;
  dayIndex: DayIndex;
}

const Day = ({ date, dayIndex }: Props) => {
  const t = useT();

  const { highlightedItems } = useContainer(MenuPreviewContext);
  const {
    dayIndex: selectedDayIndex,
    innerAppLoading,
    schoolId,
    week,
    offer,
    module,
    supplier,
    setDate,
  } = useContainer(AppContext);

  const isHoliday = useMemo(
    () => offer?.holidayDates?.[date?.seconds],
    [date?.seconds, offer?.holidayDates]
  );
  const daySeconds = useMemo(() => week[dayIndex]?.seconds, [dayIndex, week]);

  const categories: Dictionary<CombinedOfferItem[]> = useCategoriesDictionary(
    dayIndex,
    true
  );
  const menuIsEmpty = useMemo(
    () =>
      !Object.values(categories || {})
        .flat()
        .filter((item) => {
          const menuNotAvailable = isEmpty(
            get(item, ["dates", daySeconds, "menu"])
          );
          const dayDisabled = get(item, ["days", dayIndex, "disabled"]);
          const schoolDayDisabled = get(item, [
            "schools",
            schoolId,
            "days",
            dayIndex,
            "disabled",
          ]);
          const { isHidden } = isOfferHidden(item, daySeconds, module);

          return (
            !dayDisabled && !schoolDayDisabled && !menuNotAvailable && !isHidden
          );
        })?.length,
    [categories, dayIndex, schoolId, daySeconds]
  );

  const isCurrentSelected = dayIndex === selectedDayIndex;
  const isToday = useMemo(
    () => moment(date.toDate()).isSame(moment(), "days"),
    [date]
  );
  const dayName = moment.unix(date.seconds).format("DD/MM");

  const handleClick = useCallback(() => {
    setDate({ dayIndex });
  }, [dayIndex, setDate]);

  useEffect(() => {
    if (!isToday || innerAppLoading) return;

    setDate({ dayIndex });
  }, [innerAppLoading]);

  const categoryIcon = supplier?.custom?.categories;

  return (
    <div
      className={classnames({
        "px-4 md:p-6 border-b md:border md:border-r-0 border-divider-main md:border-transparent md:cursor-pointer hover:md:border-divider-main transition md:rounded-l-lg md:block":
          true,
        "md:bg-primary-main md:text-white": isCurrentSelected,
        hidden: !isCurrentSelected,
      })}
      onClick={handleClick}
    >
      {menuIsEmpty && !isHoliday && (
        <p className="text-text-contrast opacity-80 py-1 text-center">
          {t("The menu is not live yet")}
        </p>
      )}
      {isHoliday && (
        <p className="text-text-contrast opacity-40 pt-1">
          {t("The kitchen is closed on this day.")}
        </p>
      )}
      {!menuIsEmpty && (
        <>
          <div className="hidden md:block leading-tight">
            <p className="font-semibold text-lg">
              {t(dayLabels.capitalized[dayIndex])} {dayName}
            </p>
            {highlightedItems.map((item) => {
              if (!daySeconds) return null;

              const dayMenu = item?.dates?.[daySeconds]?.menu;

              const menuName = dayMenu?.name;
              const productName = item?.name;
              const categoryName = item?.category;

              return (
                <>
                  <p className="mt-2 opacity-80 text-sm">
                    {capitalizeFirstLetter(categoryName)}
                  </p>
                  <p className="mt-2 font-medium text-sm max-w-[25ch]">
                    {menuName || productName}
                  </p>
                </>
              );
            })}
          </div>
          <div className="md:hidden">
            {Object.entries(categories).map(([categoryName, categoryMenus]) => {
              const menuIcon = categoryIcon?.find(
                (c) => c.name === categoryName
              )?.src;

              return (
                <DayDetailCategory
                  overrideDayIndex={dayIndex}
                  key={categoryName}
                  categoryName={categoryName}
                  items={categoryMenus}
                  menuIcon={menuIcon}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Day;
