import { faTicket } from "@fortawesome/pro-solid-svg-icons";
import { OrderingContext } from "@kanpla/ordering";
import { calculateAmountOfOrderItems, useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { basketResetAtom } from "../../shared/basket/useBasket";
import Receipt from "../Receipt";
import RegisteringModal from "./RegisteringModal";

const Registering = () => {
  const { orderLines, module } = useContainer(OrderingContext);
  const basketReset = useSetAtom(basketResetAtom);

  const t = useT();

  const [showReceipt, setShowReceipt] = useState(false);

  const hasOrder = calculateAmountOfOrderItems(orderLines);

  useEffect(() => {
    if (!hasOrder) return;

    setShowReceipt(true);
  }, [hasOrder]);

  useEffect(() => {
    basketReset();
    return () => basketReset();
  }, []);

  if (showReceipt)
    return (
      <div className="px-3 md:px-0 md:max-w-md rounded-lg md:mx-auto mt-4">
        {module?.hasMultipleOrdersPerDay ? (
          <div className="md:p-4 pb-4 flex flex-col">
            <Button
              onClick={() => setShowReceipt(false)}
              dataCy="btn-registering-add-ticket"
              type="primary"
              icon={faTicket}
              size="large"
              className="mx-auto"
            >
              {t("Register another ticket")}
            </Button>
          </div>
        ) : (
          <p className="text-text-disabled mt-4 text-center p-4">
            {t(
              "You can't add any more tickets for today. Come back tomorrow to find them again"
            )}{" "}
            😊
          </p>
        )}
        <div className="md:px-14 px-2">
          <Receipt />
        </div>
      </div>
    );

  return <RegisteringModal setShowReceipt={setShowReceipt} />;
};

export default Registering;
