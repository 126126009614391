import { getDayIndexFromSeconds } from "@kanpla/system";
import { DayIndex } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useSubmitOrder } from "../../lib/submit/useSubmitOrder";
import { useFinalOrders } from "../data/useFinalOrders";
import { useLoadWeeklyOrders } from "../data/useLoadWeeklyOrders";

export const useSubmitWeeklyOrders = () => {
  const submitOrders = useSubmitOrder();
  const finalOrders = useFinalOrders();
  const { week, schoolId, moduleId, groupName } = useContainer(OrderingContext);
  const { setData } = useLoadWeeklyOrders();

  const submitWeeklyOrders = async () => {
    // 1. Transform orders into order documents

    const orders = Object.entries(finalOrders).map(([dayIndex, orderLines]) => {
      const dateSeconds = week[parseInt(dayIndex) as DayIndex]
        ?.seconds as number;

      const finalOrder = {
        dateSeconds,
        orderLines,
        schoolId,
        moduleId,
        paymentMethod: "billing",
        groupName,
        info: {},
      };

      return finalOrder;
    });

    const newOrders = await submitOrders({
      orders,
    });

    // 2. Mutate the loaded query orders
    const ordersByDayToSave = Object.fromEntries(
      newOrders.map((order) => [
        getDayIndexFromSeconds(order.dateSeconds),
        order,
      ])
    );
    setData(ordersByDayToSave);
  };

  return submitWeeklyOrders;
};
