import { Child, School } from "@kanpla/types";

interface SelectorsHasOnlyChoiceArgs {
  selectors: School["selectors"];
}

export const selectorsHasOnlyChoice = ({
  selectors,
}: SelectorsHasOnlyChoiceArgs): Child["selectors"] | null => {
  if (!selectors) return null;
  if (selectors.length > 1) return null;

  const firstLayer = selectors?.[0];
  const firstLayerOptions = firstLayer?.options || [];
  const availableOptions = firstLayerOptions.filter((o) => !o.hidden);
  const hasMoreOptions = availableOptions.length > 1;

  if (hasMoreOptions) return null;

  const firstLayerName = firstLayer?.name;
  const firstOptionName = availableOptions[0]?.name;
  if (!firstLayerName || !firstOptionName) return null;
  const onlyChoice = {
    [firstLayerName]: firstOptionName,
  };

  return onlyChoice;
};
