import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { priceFormatter, tx, useT } from "@kanpla/system";
import { Button, activeCurrencyAtom } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useContainer } from "unstated-next";
import NewPayment from "../../../../libs/ordering/src/shared/payment/payment/index";
import { AppContext } from "../contextProvider";

interface Props {
  className?: string;
}

const Credit = ({ className = "" }: Props) => {
  const t = useT();

  const { balance = 0, modules } = useContainer(AppContext);
  const activeCurrency = useAtomValue(activeCurrencyAtom);
  const priceFormatted = priceFormatter(
    balance as number,
    activeCurrency,
    tx.getCurrentLocale()
  );

  const [open, setOpen] = useState(false);

  const hasCredit = (modules || []).filter(
    ({ paymentMethod, type, plugins }) => {
      const isCredit = paymentMethod === "credit" && type === "mealplan";
      const kanplaGo = plugins?.kanplaGo;
      const payPerOrder = plugins?.payPerOrder;

      return (
        isCredit &&
        (!kanplaGo?.active || kanplaGo?.showSaldoAnyways) &&
        (!payPerOrder?.active || payPerOrder?.showSaldoAnyways)
      );
    }
  );

  const tankUpCredit = hasCredit?.length;
  const showCredit = (balance || 0) > 0 || tankUpCredit;

  if (!showCredit) return null;

  return (
    <div className={className}>
      <h2 className="text-sm uppercase font-semibold text-text-secondary mb-2">
        {t("Balance")}
      </h2>
      <div className="bg-secondary-main border border-secondary-dark rounded-md px-4 py-8 text-secondary-contrast flex justify-between items-center">
        <p
          className={`text-2xl font-medium z-10 relative`}
          data-cy="balance-value"
        >
          {priceFormatted}
        </p>
        {tankUpCredit ? (
          <Button
            onClick={() => setOpen(true)}
            data-cy="refill-balance-btn"
            icon={faPlus}
          >
            {t("Fill up")}
          </Button>
        ) : null}
      </div>

      <NewPayment open={open} setOpen={setOpen} />
    </div>
  );
};

export default Credit;
