import { callInternalApi, useT } from "@kanpla/system";
import { DrawerOrModal, Spinner } from "@kanpla/ui";
import { useQuery } from "@tanstack/react-query";
import {
  LoadHistoryProps,
  LoadHistoryReturn,
} from "apps/frontend/pages/api/internal/load/paymentHistory";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import Item from "./Item";
import { getIcon } from "./getIcon";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  /** Title of the modal */
  title: string;
  /** Url to fetch api */
  apiUrl: "load/paymentHistory" | "load/subscriptionHistory";
};

const HistoryModal = ({ open, setOpen, title, apiUrl }: Props) => {
  const { userId } = useContainer(AppContext);
  const t = useT();

  const { data, isLoading } = useQuery({
    queryKey: [apiUrl, userId],
    queryFn: () => {
      if (!userId) return;

      return callInternalApi<LoadHistoryProps, LoadHistoryReturn>(apiUrl, {
        userId,
      });
    },
    enabled: open,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 60 * 1, // 1 hour
  });

  const items = data || [];

  const translatedItems =
    items
      .map((item) => ({
        ...item,
        icon: getIcon(item.icon),
        title: t(item.title?.key, item.title?.value),
        paymentDescription: t(item.paymentDescription),
      }))
      .sort((a, b) => b.dateSeconds - a.dateSeconds) || [];

  return (
    <DrawerOrModal open={open} setOpen={setOpen} title={title} actions={[]}>
      {isLoading && (
        <div className="bg-background-secondary text-text-primary rounded p-8 flex items-center justify-center">
          <Spinner size="small" />
        </div>
      )}
      {translatedItems.map((item) => (
        <Item item={item} key={item.id} />
      ))}
    </DrawerOrModal>
  );
};

export default HistoryModal;
