import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { setNativeKeyboardOffset, useT } from "@kanpla/system";
import { Child, School, Scope } from "@kanpla/types";
import { Button, DrawerOrModal, SelectorsInput } from "@kanpla/ui";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { ChooseSchool } from "./newSalesplace/ChooseSchool";
import { ChooseWithCanteenId } from "./newSalesplace/ChooseWithCanteenId";
import { useSubmitChild } from "./newSalesplace/useSubmitChild";

export interface SignupData {
  schoolId?: string;
  selectors?: Child["selectors"];
  scope?: Scope;
  school?: School;
}

const NewSalesplace = () => {
  const t = useT();

  const [signupData, setSignupData] = useState<SignupData | null>({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const targetSchool = signupData?.school;
  const submit = useSubmitChild({ signupData, setOpen, setLoading });

  const areAllSelectorsHidden =
    signupData?.school?.selectors?.every((s) =>
      s?.options?.every((o) => o?.hidden)
    ) || false;

  useEffect(() => {
    setNativeKeyboardOffset(-50);
  });

  const resetData = () => setSignupData(null);

  useEffect(() => {
    resetData();
    if (!open) setNativeKeyboardOffset(-90);
  }, [open]);

  const canCreateSalesplace = Boolean(
    targetSchool?.id &&
      (isEmpty(targetSchool?.selectors) ||
        areAllSelectorsHidden ||
        !isEmpty(signupData?.selectors))
  );

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="mt-2 w-full"
        shape="solid"
        icon={faPlus}
        dataCy="btn-add-another-location"
      >
        {t("Add new location")}
      </Button>
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        stopPropagation={false}
        title={t("Add new location")}
        actions={
          canCreateSalesplace
            ? [
                {
                  label: t("Confirm"),
                  onClick: submit,
                  type: "primary",
                  loading,
                  disabled: !signupData?.school && !signupData?.schoolId,
                },
              ]
            : []
        }
        noOverflow
      >
        <ChooseSchool signupData={signupData} setSignupData={setSignupData} />

        <ChooseWithCanteenId
          signupData={signupData}
          setSignupData={setSignupData}
        />
        <SelectorsInput
          selectors={signupData?.selectors}
          setSelectors={(newSelectors) =>
            setSignupData({ ...signupData, selectors: newSelectors })
          }
          school={signupData?.school}
          isControlled={false}
        />
      </DrawerOrModal>
    </>
  );
};

export default NewSalesplace;
