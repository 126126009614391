import { getErrorMessage, useT } from "@kanpla/system";
import { Child } from "@kanpla/types";
import {
  Button,
  Form,
  Input,
  activeCurrencyAtom,
  message,
  useCurrencySymbol,
} from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { currencyNumbers } from "libs/ordering/src/shared/payment/payment/PaymentAmount";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

type FormData = {
  adhocLimit: string;
};

interface Props {
  child: Child;
}

const AdhocSettings = ({ child }: Props) => {
  const t = useT();

  const { updateCurrentChild } = useContainer(AppContext);

  const activeCurrency = useAtomValue(activeCurrencyAtom);
  const currencySymbol = useCurrencySymbol();

  const activeNumbers = currencyNumbers[activeCurrency || "DKK"];

  const [loading, setLoading] = useState(false);

  const submit = async (data: FormData) => {
    try {
      setLoading(true);
      const { adhocLimit } = data;

      await updateCurrentChild({ adhocLimit: parseInt(adhocLimit) });
      message.success(t("Settings saved"));
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="heading-main mb-2">{t("Payment in the canteen")}</div>
      <Form<FormData>
        onSubmit={submit}
        defaultValues={{
          adhocLimit:
            typeof child?.adhocLimit === "number"
              ? String(child?.adhocLimit)
              : undefined,
        }}
      >
        <Form.Item
          name="adhocLimit"
          rules={{
            pattern: {
              value: /^[0-9][0-9]*$/i,
              message: t(
                "Only numbers are allowed and it must be greater or equal than 0"
              ),
            },
          }}
        >
          <Input.Number
            label={t("Limit for payment in the canteen")}
            placeholder={String(activeNumbers[2])}
            prefix={currencySymbol}
            min={0}
          />
        </Form.Item>
        <p className="text-xs text-text-secondary mb-4 opacity-70">
          {t(
            "Your default limit is {defaultLimit} {currencySymbol}. If you want to deactivate physical payment in the canteen, you must set the limit to 0 {currencySymbol}.",
            {
              currencySymbol,
              defaultLimit: activeNumbers[2],
            }
          )}
        </p>

        <Button
          loading={loading}
          loadingText={t("Please wait...")}
          dataCy="btn-adhoc-settings-set-limit"
          shape="solid"
          htmlType="submit"
        >
          {t("Set limit")}
        </Button>
      </Form>
    </>
  );
};

export default AdhocSettings;
