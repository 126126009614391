import {
  faCreditCard,
  faExchangeAlt,
  faTimes,
} from "@fortawesome/pro-duotone-svg-icons";
import { FieldValue, T, useT } from "@kanpla/system";
import {
  SubscriptionOrder,
  SubscriptionPeriod,
  SubscriptionProduct,
  _FrontendModule,
} from "@kanpla/types";
import {
  Button,
  ReceiptWrapper,
  message,
  useConfirmation,
  usePriceFormatter,
} from "@kanpla/ui";
import classNames from "classnames";
import moment from "moment";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { SubscriptionContext } from "../.";
import ChangeCard from "./ChangeCard";
import ChooseProduct from "./ChooseProduct";

interface Props {
  subscription: SubscriptionOrder;
  module: _FrontendModule;
}

const Item = ({ subscription, module }: Props) => {
  const t = useT();

  const confirm = useConfirmation();

  const { name, autoRenew } = subscription;
  const { hidePrices } = module?.config || {};

  const [changingCardOpen, setChangingCardOpen] = useState(false);

  // Data
  const { periods } = useContext(SubscriptionContext);

  const [subsequentPeriod, setSubsequentPeriod] = useState<
    Partial<SubscriptionPeriod>
  >({});
  const subsequentProducts = subsequentPeriod.products;

  // Find next period
  useEffect(() => {
    const { type, to } = subscription;

    const subsequentPeriods = periods[type]
      ? periods[type]
          .filter((p) => {
            return p.from.seconds > to.seconds;
          })
          .sort((a, b) => a.from.seconds - b.from.seconds)
      : [];

    const nextPeriod = subsequentPeriods ? subsequentPeriods[0] : {};
    if (nextPeriod) setSubsequentPeriod(nextPeriod);
  }, [periods, subscription]);

  // Format data from subscription
  const startDate = subscription.from
    ? moment.unix(subscription.from.seconds).format("DD/MM")
    : "—";
  const to = moment.unix(subscription.to.seconds).format("DD/MM");

  const renewSubscription = useMemo(
    () => (
      <>
        <T str="Would you like to renew your subscription for" />
        <span className="text-green-600">{name}</span>
      </>
    ),
    [name]
  );

  const unsubsribeSubscription = useMemo(
    () => (
      <T
        str="Would you like to cancel your subscription for {name}"
        name={<span className="text-green-600">{name}</span>}
      />
    ),
    [name]
  );

  const toggleAutoRenew = () => {
    confirm({
      title: autoRenew ? unsubsribeSubscription : renewSubscription,
      children: (
        <p className="text-text-primary text-center mb-4">
          {t(
            autoRenew
              ? "Your subscription will remain active until the end of the period (until {to}). Do you still want to cancel your subscription?"
              : "Your subscription will be automatically renewed when the next period begins (after {{to}}). Do you want to start the automatic renewal?",
            { to }
          )}
        </p>
      ),
      cancelButtonText: t("No"),
      okButtonText: t("Yes"),
    }).then(() => {
      subscription.ref.update({
        autoRenew: !autoRenew,
      });

      message.success(
        autoRenew ? t("Subscription terminated") : t("Subscription activated")
      );
    });
  };

  const changeProdukt = async () => {
    const nextStart = subsequentPeriod?.to?.seconds
      ? DateTime.fromSeconds(subsequentPeriod?.to?.seconds).toFormat("D")
      : 0;

    const selectedProduct = (await confirm({
      title: t("Change to?"),
      children: (
        <ChooseProduct
          subsequentProducts={subsequentProducts}
          nextStart={nextStart}
          module={module}
        />
      ),
    })) as SubscriptionProduct;

    if (!selectedProduct?.id) return;

    await subscription.ref.update({
      changingProductId:
        selectedProduct?.id === subscription.productId
          ? FieldValue.delete()
          : selectedProduct.id,
    });

    message.success(t("Subscription product changed"));
  };

  const changingProduct =
    autoRenew &&
    subscription.changingProductId &&
    subsequentProducts &&
    subsequentProducts.find((p) => p.id === subscription.changingProductId);

  const priceFormatted = usePriceFormatter(subscription.resultingPrice * 100);

  // Hide if is over
  const isOver = subscription.to.seconds < moment().unix();

  const bottomActions = isOver
    ? [
        <Button
          size="small"
          disabled
          className="heading-uppercase ml-auto cursor-default mr-2"
          dataCy="btn-subscription-end"
          shape="solid"
        >
          {t("ended")}
        </Button>,
      ]
    : !autoRenew
    ? [
        <Button
          onClick={toggleAutoRenew}
          className="heading-uppercase text-primary-dark"
          shape="plain"
          dataCy="btn-subscription-redraw"
        >
          {t("Renew")}
        </Button>,
      ]
    : [
        !subsequentProducts && (
          <Button
            onClick={changeProdukt}
            className="font-semibold"
            shape="plain"
            dataCy="btn-subscription-change-product"
            icon={faExchangeAlt}
          >
            {t("Change product")}
          </Button>
        ),
        <Button
          onClick={() => setChangingCardOpen(true)}
          className="font-semibold"
          shape="plain"
          dataCy="btn-select-payment-card"
          icon={faCreditCard}
        >
          {t("Change payment card")}
        </Button>,
        <Button
          onClick={toggleAutoRenew}
          className="font-semibold"
          shape="plain"
          dataCy="btn-cancel-subscritpion"
          type="danger"
          icon={faTimes}
        >
          {t("Cancel subscription")}
        </Button>,
      ];

  return (
    <>
      <div className="pt-6 pb-3 flex flex-wrap">
        <SubscriptionInfo title={t("Product name")}>
          {subscription.name}
        </SubscriptionInfo>
        <SubscriptionInfo title={t("Price")}>{priceFormatted}</SubscriptionInfo>
        <SubscriptionInfo title={t("Period")}>
          <>
            {startDate} -{" "}
            <span className={`${!autoRenew && "text-danger-main"}`}>{to}</span>{" "}
            {changingProduct &&
              t("(changed to {value})", { value: changingProduct.name })}
          </>
        </SubscriptionInfo>
        <SubscriptionInfo title={t("Auto-Renew")}>
          <span
            className={classNames({
              uppercase: true,
              "text-danger-main": !subscription.autoRenew,
              "text-primary-main": subscription.autoRenew,
            })}
          >
            {subscription.autoRenew ? t("On") : t("Off")}
          </span>
        </SubscriptionInfo>

        <ChangeCard open={changingCardOpen} setOpen={setChangingCardOpen} />
      </div>

      <ReceiptWrapper.BottomActions bottomActions={bottomActions} />
    </>
  );
};

interface SubscriptionInfoProps {
  children: ReactNode;
  title: string;
}

const SubscriptionInfo = ({ children, title }: SubscriptionInfoProps) => {
  return (
    <div className="w-2/4 p-2">
      <p className="uppercase text-sm font-medium text-secondary-dark">
        {title || "-"}
      </p>
      <p className="font-semibold">{children}</p>
    </div>
  );
};

export default Item;
