import { useProductTour, useT } from "@kanpla/system";
import { ChildView, Popper } from "@kanpla/ui";
import { atom, useAtom } from "jotai";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import Content from "./Content";
import UserIcon from "./UserIcon";

export const isMenuOpenAtom = atom(false);

const Settings = () => {
  const t = useT();
  const { supplier, user, mobile } = useContainer(AppContext);

  const [isMenuOpen, setIsMenuOpen] = useAtom(isMenuOpenAtom);

  const EATY_TOUR = 371155;

  // EATY SETTINGS TOUR
  useProductTour(
    EATY_TOUR,
    user,
    isMenuOpen && supplier?.partnerId === "Trxof1L5MJ0MxEQvHycH"
  );

  if (mobile)
    return (
      <>
        <UserIcon onClick={() => setIsMenuOpen(true)} />

        <ChildView
          open={isMenuOpen}
          setOpen={setIsMenuOpen}
          title={t("My account")}
          backLabel={t("Back")}
        >
          <Content />
        </ChildView>
      </>
    );

  return (
    <Popper
      open={isMenuOpen}
      setOpen={setIsMenuOpen}
      actionElement={<UserIcon />}
      withPadding={false}
      width={410}
      className="px-5 py-4 overflow-y-scroll md:max-h-[calc(100vh-100px)] 2xl:h-fit 2xl:max-h-[700px]"
      lockOnOpen
      zIndex={30}
      margin={2}
      closableIfModalOpen={false}
    >
      <Content />
    </Popper>
  );
};

export default Settings;
