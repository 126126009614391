import { PopupConstructor, Supplier } from "@kanpla/types";
import {
  CarouselContext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import React, { useContext, useEffect, useState } from "react";
import { Screen } from "./Screen";

interface Props {
  screens: PopupConstructor.Fullscreen[];
  supplier: Supplier;
  /** Fired on the last custom screen */
  onLastCustomScreen?: () => void | Promise<void>;
  /** Fired after all the screens, either custom or system */
  onFinish: () => void | Promise<void>;
  /** Displays the carousel in a desktop friendly way */
  desktopView?: boolean;
  /** Loading state for the button on the last custom screen */
  lastButtonLoading?: boolean;
}

export const SignupCarousel = (props: Props) => {
  const {
    screens,
    supplier,
    onFinish,
    onLastCustomScreen = () => null,
    desktopView = false,
    lastButtonLoading = false,
  } = props;
  const carouselContext = useContext(CarouselContext);

  const screensAmount = screens.length;

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (!carouselContext) return;

    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    onChange();
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <CarouselProvider
      totalSlides={screensAmount}
      naturalSlideWidth={1}
      naturalSlideHeight={1}
      className="w-full h-full pb-0 select-none"
      orientation="horizontal"
      lockOnWindowScroll
      touchEnabled={false}
      dragEnabled={false}
      currentSlide={currentSlide}
    >
      <Slider
        classNameTrayWrap="h-full"
        classNameTray="flex h-full transition"
        className="h-full"
        style={{
          height: "100% !important",
        }}
      >
        {screens.map((popup, i) => {
          return (
            <Slide
              innerClassName="h-full p-0 m-0"
              className="h-full"
              key={`slide-${popup.id}-${i}`}
              index={i}
              style={{
                padding: 0,
                height: "100% !important",
              }}
            >
              <Screen
                popup={popup}
                supplier={supplier}
                onLastScreen={onLastCustomScreen}
                onFinish={onFinish}
                lastButtonLoading={lastButtonLoading}
                desktopView={desktopView}
              />
            </Slide>
          );
        })}
      </Slider>
    </CarouselProvider>
  );
};
