import { faCheck, faGift } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "@kanpla/system";
import { Stamp as StampType } from "@kanpla/types";
import { Tooltip } from "@kanpla/ui";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { isMobile } from "react-device-detect";

interface Props {
  numberToDisplay: number;
  stamp: StampType | undefined;
  now: number;
  isGift: boolean;
}

export const Stamp = ({ numberToDisplay, stamp, now, isGift }: Props) => {
  const stillNoValid =
    !!stamp?.validFromSeconds && stamp.validFromSeconds > now;

  return (
    <div
      className={classNames(
        "w-full aspect-square rounded-full border-primary-contrast font-medium border-2 flex items-center justify-center",
        stamp && "bg-primary-contrast text-primary-main",
        stillNoValid && "bg-opacity-50",
        !stamp && "text-primary-contrast",
        isGift && "border-dashed"
      )}
    >
      {/** Empty stamp */}
      {!stamp && (isGift ? <FontAwesomeIcon icon={faGift} /> : numberToDisplay)}

      {/** Filled stamp */}
      {stamp && !stillNoValid && (
        <FontAwesomeIcon icon={isGift ? faGift : faCheck} />
      )}

      {/** Filled stamp but not valid yet */}
      {stamp && stillNoValid && (
        <Tooltip
          trigger={isMobile ? "click" : "hover"}
          content={t("This stamp will be counted from {value}", {
            value: moment
              .utc(stamp.validFromSeconds * 1000)
              .format("D MMM k:mm"),
          })}
        >
          <FontAwesomeIcon icon={isGift ? faGift : faCheck} />
        </Tooltip>
      )}
    </div>
  );
};
