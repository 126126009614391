import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { allowanceUsageAtom, useBasketPreventClick } from "@kanpla/ordering";
import {
  T,
  Timestamp,
  capitalizeFirstLetter,
  dayLabels,
  getTodayTimestamp,
  useT,
  useWindowSize,
} from "@kanpla/system";
import {
  Alert,
  AutoScrollContainer,
  Button,
  Category,
  HorizontalSlider,
} from "@kanpla/ui";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { capitalize, isEmpty } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { meetingCurrentViewAtom } from "../..";
import { OrderingContext } from "../../../../";
import { meetingOverwriteOfferAtom } from "../../../../lib/hooks/useOffer";
import { useGetAllowanceMessage } from "../../../../shared/allowance/useGetAllowanceMessage";
import Product from "../../../Product";
import { useCategories } from "../../../useCategories";
import StepWrapper, { isScrolledDescriptionAtom } from "../../Wrapper";
import { CustomOrderModal } from "./customOrder/CustomOrderModal";

const ProductSelection = () => {
  const t = useT();

  const setCurrentView = useSetAtom(meetingCurrentViewAtom);

  const {
    module,
    items,
    setDate,
    overviewEditingOrder,
    dateSeconds,
    mobile,
    dayIndex,
    fromAdmin,
    partner,
  } = useContainer(OrderingContext);

  const meetingOverwriteOffer = useAtomValue(meetingOverwriteOfferAtom);

  const { isMobileSize } = useWindowSize();
  const confirm = useBasketPreventClick({}) || (async () => true);

  const [open, setOpen] = useState(false);

  // Allowances
  const allowanceUsages = useAtomValue(allowanceUsageAtom);
  const { messages } = useGetAllowanceMessage({
    allowanceUsages,
  });

  const categories = useCategories(
    meetingOverwriteOffer[module?.id]?.items || items
  );

  const categoryItems = Object.entries(categories)
    .filter(([, items]) => items?.length)
    .map(
      ([name]) =>
        capitalize(name) ||
        module?.text?.["noCategory/text"] ||
        t("Dish of the day")
    );

  const itemsTotalCount: number = Object.values(categories).flat().length;
  const isScrolled = useAtomValue(isScrolledDescriptionAtom);

  const showCategoriesSlider =
    categoryItems.length > 1 &&
    (isMobileSize
      ? categoryItems.length >= 4 || itemsTotalCount > 20
      : categoryItems.length >= 6 || itemsTotalCount > 25);

  /**
   * Needed to keep 2 separate variables.
   * - One for sending the changes from AutoScrollContainer to HorizontalSlider
   * - and the second one for HorizontalSlider to AutoScrollContainer.
   */
  const [highlightedCategoryIndex, setHighlightedCategoryIndex] = useState<
    number | null
  >(null);
  const [intoViewCategoryIndex, setIntoViewCategoryIndex] = useState<
    number | null
  >(null);

  const today = getTodayTimestamp({ Timestamp });
  return (
    <>
      <StepWrapper
        pageTitle={t("Select products")}
        subtitle={t("You are ordering for {value}", {
          value: `${t(dayLabels.capitalized[dayIndex])}, ${moment
            .unix(dateSeconds)
            .format("ll")}`,
        })}
        onBack={async () => {
          await confirm();

          setDate(today);
          setCurrentView("overview");
        }}
        hideBackButton={!isEmpty(overviewEditingOrder)}
        backButtonText={t("Cancel")}
      >
        {!!(partner?.proPlugins?.customOrders && fromAdmin) && (
          <Button
            onClick={() => setOpen(true)}
            type="primary"
            shape="soft"
            className="w-full my-5"
            icon={faPlus}
          >
            <T _str="Add custom order" />
          </Button>
        )}
        {!isEmpty(messages) && (
          <div className="flex flex-col gap-y-2 mb-6">
            {messages.map((message) => (
              <Alert type="info" message={message} />
            ))}
          </div>
        )}
        {showCategoriesSlider && (
          <div
            className={classNames(
              "sticky top-0 w-full bg-background-primary box-content -ml-6 pl-6 z-30",
              mobile && isScrolled && "pt-[calc(var(--status-bar-height)+1rem)]"
            )}
          >
            <HorizontalSlider
              items={categoryItems}
              selectedIndex={intoViewCategoryIndex}
              onClick={setHighlightedCategoryIndex}
              classNames="pb-4 pt-2 mt-2 w-full"
            />
          </div>
        )}
        {Object.entries(categories).map(([name, items], index) => {
          if (name === "skjult") return null;

          return (
            <AutoScrollContainer
              className="boundary-element"
              scrollIntoView={highlightedCategoryIndex === index}
              onIntercept={() => setIntoViewCategoryIndex(index)}
              key={name}
            >
              <Category
                name={
                  capitalizeFirstLetter(name) ||
                  module?.text?.["noCategory/text"] ||
                  t("Dish of the day")
                }
              >
                <div className="mt-2 flex flex-col z-0">
                  {items.map((offerProduct) => {
                    return (
                      <Product
                        key={offerProduct.id}
                        product={offerProduct}
                        hidePriceInLabel
                      />
                    );
                  })}
                </div>
              </Category>
            </AutoScrollContainer>
          );
        })}
      </StepWrapper>
      <CustomOrderModal open={open} setOpen={setOpen} />
    </>
  );
};

export default ProductSelection;
