import { getAvailableOptions } from "@kanpla/ui";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../../../context";
import { useIsSpecialOption } from "../../../variants/useIsSpecialOption";

interface Props {
  productId: string;
}

export const useAvailableOptions = ({ productId }: Props) => {
  const { mealOptions, items } = useContainer(OrderingContext);
  const isSpecialOption = useIsSpecialOption();

  // 1. Filter out special options
  const optionsWithoutGlobalVariant = mealOptions?.filter(
    (option) => !isSpecialOption(option)
  );

  // 2. Return available options
  const product = items?.find((p) => p.productId === productId);
  if (!product) return [];
  const availableOptions = getAvailableOptions({
    product: product,
    options: optionsWithoutGlobalVariant,
  });

  return availableOptions;
};
