import { faBell } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import Cards from "./Cards";
import PhoneWireframe from "./PhoneWireframe";

const NotificationIllustration = (): JSX.Element => {
  const { supplier } = useContainer(AppContext);
  const t = useT();

  return (
    <div className="w-fit h-fit relative">
      <PhoneWireframe />
      <div className="absolute top-11 left-16">
        <div className="relative">
          <FontAwesomeIcon
            icon={faBell}
            size="3x"
            className="text-primary-main animate-ring-infite"
          />
          <span className="absolute animate-bounce -top-1 -right-2 rounded-full bg-danger-dark text-white font-medium h-6 w-6 border-2 border-white flex items-center justify-center">
            1
          </span>
        </div>
      </div>
      <div className="absolute bottom-0 -left-11">
        <Cards
          title={t("Message from {who}", { who: t("the canteen") })}
          content={t("View our new menu 👩‍🍳")}
          icon={
            <img
              src={
                supplier?.appIconUrl ||
                "https://firebasestorage.googleapis.com/v0/b/kanpla-87be3.appspot.com/o/icon%2FApp%20Icon.png?alt=media&token=7b491817-5d37-42e6-80f6-aa11495502f4"
              }
              className="w-auto h-auto object-cover overflow-clip rounded-lg border border-primary-contrast"
              alt={supplier?.appName || supplier?.name || "Kanpla icon"}
            />
          }
        />
      </div>
    </div>
  );
};

export default NotificationIllustration;
