import type { CrowdTrackerData } from "@kanpla/types";

export const average: CrowdTrackerData = [
  {
    time: 11,
    count: 10,
  },
  {
    time: 11.25,
    count: 15,
  },
  {
    time: 11.5,
    count: 23,
  },
  {
    time: 11.75,
    count: 30,
  },
  {
    time: 12,
    count: 45,
  },
  {
    time: 12.25,
    count: 56,
  },
  {
    time: 12.5,
    count: 62,
  },
  {
    time: 12.75,
    count: 65,
  },
  {
    time: 13,
    count: 50,
  },
  {
    time: 13.25,
    count: 20,
  },
  {
    time: 13.5,
    count: 15,
  },
];

export const day: CrowdTrackerData = [
  {
    time: 11,
    count: 5,
  },
  {
    time: 11.25,
    count: 7,
  },
  {
    time: 11.5,
    count: 9,
  },
  {
    time: 11.75,
    count: 24,
  },
  {
    time: 12,
    count: 50,
  },
  {
    time: 12.25,
    count: 60,
  },
  {
    time: 12.5,
    count: 70,
  },
  {
    time: 12.75,
    count: 0,
  },
  {
    time: 13,
    count: 0,
  },
  {
    time: 13.25,
    count: 0,
  },
  {
    time: 13.5,
    count: 0,
  },
];

export const currentTime = 12.5;
