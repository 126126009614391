import { useAtomValue } from "jotai";
import { orderChangesAtom } from "./data/changesAtom";
import { globalVariantChoicesAtom } from "./ordering/globalVariant/globalVariantChoicesAtom";

export const useIsFlexBulkSaved = () => {
  const orderChanges = useAtomValue(orderChangesAtom);
  const globalVariantChoices = useAtomValue(globalVariantChoicesAtom);
  const saved =
    orderChanges.length === 0 &&
    !Object.values(globalVariantChoices).some(
      (choice) => typeof choice === "string"
    );

  return saved;
};
