import React from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import AdminOverview from "../flexBulk/AdminOverview";
import { StampCards } from "../stampcards/Stampcards";
import Kids from "./Kids";
import UserInfo from "./UserInfo";
import { useHasAdminAccess } from "./useHasAdminAccess";

const Content = () => {
  const { modules } = useContainer(AppContext);

  const hasAdminAccess = useHasAdminAccess();

  return (
    <div className="grid gap-6 md:pt-2">
      <Kids />

      {hasAdminAccess && (
        <AdminOverview availableModules={modules || []} fromUserSettings />
      )}

      <StampCards direction="column" />

      <UserInfo />
    </div>
  );
};

export default Content;
