import {
  calculateOrderTotalByModule,
  getProductNameForBasket,
  orderToDisplayString,
} from "@kanpla/system";
import { CombinedOfferItem, OrderLine } from "@kanpla/types";
import { Badge, PriceFormatter, TimeInputDisplay } from "@kanpla/ui";
import { FC } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { ProductPhoto } from "../../shared/product/ProductPhoto";

interface BasketListItemTextProps {
  /** A configuration item from the basket */
  item: OrderLine;
  dateSeconds?: number;
  showImage?: boolean;
}

export const BasketListTextItem: FC<BasketListItemTextProps> = ({
  item,
  dateSeconds = 0,
  showImage,
}) => {
  const { module, items, supplier } = useContainer(OrderingContext);

  const priceToDisplay = calculateOrderTotalByModule([item], module);

  const product = items?.find(
    (p: CombinedOfferItem) => p.productId === item?.productId
  );

  const nameToDisplay = getProductNameForBasket({
    product,
    dateSeconds,
    productName: item?.name,
    productId: item.productId,
  });

  return (
    <div>
      <div className="flex justify-between mb-2 gap-4 items-center">
        {showImage ? (
          <div className="flex gap-4 items-center">
            <div className="relative">
              <div className="absolute -top-2 -right-2 z-10">
                <Badge count={item.amount} />
              </div>
              <div className="w-14 h-14 overflow-hidden rounded-lg">
                <ProductPhoto
                  src={product?.photo}
                  name={product?.name || item?.name}
                  supplier={supplier}
                  ratio="1:1"
                  noPhotoIconSize="1x"
                />
              </div>
            </div>
            <div>
              {product?.category && (
                <p className="text-xs text-text-disabled">
                  {product?.category}
                </p>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: orderToDisplayString({
                    orderLines: [{ ...item, name: nameToDisplay }],
                  }),
                }}
              />
            </div>
          </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: orderToDisplayString({
                orderLines: [{ ...item, name: nameToDisplay }],
              }),
            }}
          />
        )}

        <PriceFormatter price={priceToDisplay} />
      </div>
      {Boolean(item.deliveryTime) && (
        <div className="text-sm opacity-70 ml-4 -mt-1 mb-1">
          <TimeInputDisplay timeInput={item.deliveryTime} />
        </div>
      )}
    </div>
  );
};
