import { useT } from "@kanpla/system";
import { SubscriptionPeriod } from "@kanpla/types";
import { ProductSettingsHeader, Select } from "@kanpla/ui";
import { Dispatch, SetStateAction, useEffect } from "react";

interface Props {
  periods: Array<SubscriptionPeriod>;
  selectedName: string;
  setSelectedName: Dispatch<SetStateAction<string>>;
  isChoosingName: boolean;
}

const ChooseName = (props: Props) => {
  const { periods, selectedName, setSelectedName, isChoosingName } = props;

  const t = useT();

  // Choose the first available name by default
  const periodsTrigger = periods.map((p) => p.id).join();
  useEffect(() => {
    const firstPeriod = periods[0];
    const validPeriod = periods.some((p) => p.name === selectedName);
    if (!validPeriod) {
      setSelectedName(firstPeriod ? firstPeriod.name || "" : null);
    }
  }, [periodsTrigger]);

  const availableNames = [...new Set(periods.map((p) => p.name))];

  if (!isChoosingName) return null;

  return (
    <div className="flex flex-col justify-center relative z-10">
      <div className="px-2">
        <ProductSettingsHeader title={t("1. Ordering period")} />
      </div>

      <div className="mx-auto my-8">
        <Select
          value={selectedName}
          onChange={(newValue: string) => setSelectedName(newValue)}
          options={availableNames.map((name) => ({ label: name, value: name }))}
        />
      </div>
    </div>
  );
};

export default ChooseName;
