import { useT } from "@kanpla/system";
import classNames from "classnames";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";

const Header = () => {
  const t = useT();
  const { module, hasOrdered } = useContainer(OrderingContext);

  return (
    <div
      className={classNames("text-center mb-8", hasOrdered ? "mt-4" : "mt-12")}
    >
      <div className="title-secondary text-text-secondary">
        {module?.text?.["description/title"] || t("It's lunchtime!")}
      </div>
      <div className="text-sm text-text-secondary">
        {module?.text?.["description/text"] ||
          t("Swipe, and show your lunch ticket in the canteen")}
      </div>
    </div>
  );
};

export default Header;
