import { stringifyOrderLine } from "@kanpla/system";
import { CombinedOfferItem, OrderLines } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { sumBy } from "lodash";
import { isOrderingFromBackendAtom } from "../../context";

export const useMinimumAmount = (
  basket: OrderLines,
  currentLineString: string,
  product?: CombinedOfferItem | null
) => {
  const isOrderingFromBackend = useAtomValue(isOrderingFromBackendAtom);
  if (isOrderingFromBackend) return undefined;

  if (!product) return undefined;
  if (typeof product.minimumAmount !== "number") return undefined;

  // Filter out minimum based on other items in the basket
  const sharedItems = basket.filter((item) => {
    if (item.productId !== product?.id) return false;

    return stringifyOrderLine(item) !== currentLineString;
  });
  const sharedAmount = sumBy(sharedItems, (item) => item.amount);

  return product.minimumAmount - sharedAmount;
};
