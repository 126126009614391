import { _FrontendModule } from "@kanpla/types";

export const isWithCredit = (module: _FrontendModule) => {
  if (!module) return false;

  const hasKanplaGo = module.plugins?.kanplaGo?.active;
  const hasPayPerOrder = module.plugins?.payPerOrder?.active;
  const hasKanplaGoShowSaldoAnyways =
    module.plugins?.kanplaGo?.showSaldoAnyways;
  const hasShowSaldoAnyways = module.plugins?.payPerOrder?.showSaldoAnyways;

  return !!(
    module.type === "mealplan" &&
    module.paymentMethod === "credit" &&
    (!hasKanplaGo || (hasKanplaGo && hasKanplaGoShowSaldoAnyways)) &&
    (!hasPayPerOrder || (hasPayPerOrder && hasShowSaldoAnyways))
  );
};
