import {
  calculateAmountOfOrderItems,
  dayLabels,
  getDayIndexFromSeconds,
  sortProducts,
  stringifyOrder,
  stringifyOrderLine,
  useT,
} from "@kanpla/system";
import { OrderLines } from "@kanpla/types";
import { Divider } from "antd";
import { useAtomValue } from "jotai";
import { groupBy, pickBy, sortBy } from "lodash";
import moment from "moment";
import { FC, useEffect } from "react";
import { FieldValues } from "react-hook-form";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { BasketInfoForm } from "../../mealplan2/basket/useBasketPurchaseLogic";
import {
  BasketContainer,
  basketContainerAtom,
} from "../../shared/basket/useBasket";
import { useSetBasket } from "../../shared/basket/useSetBasket";
import { BasketEditButton } from "./BasketEditButton";
import BasketEmpty from "./BasketEmpty";
import { BasketListItem } from "./BasketListItem";
import { OrderInfoFormWrapper } from "./OrderInfoFormWrapper";
import { OrderInfoItem } from "./OrderInfoItem";

interface Props {
  onSubmit: (data: FieldValues) => void;
  basketInfoForm: BasketInfoForm;
}

export const BasketList: FC<Props> = ({ onSubmit, basketInfoForm }) => {
  const basketContainer = useAtomValue(basketContainerAtom);
  const { modules, items } = useContainer(OrderingContext);
  const t = useT();

  const validBaskets: BasketContainer = pickBy(basketContainer, (order) => {
    const numberOfItems = calculateAmountOfOrderItems(order?.orderLines || []);
    const isValid = numberOfItems > 0;
    return isValid;
  });

  const groupedByDateSeconds = groupBy(
    Object.values(validBaskets),
    (order) => order.dateSeconds
  );

  const setBasket = useSetBasket();

  /** Sets the new value of the form. */
  useEffect(() => {
    basketInfoForm.reset(validBaskets);
  }, []);

  return (
    <OrderInfoFormWrapper basketInfoForm={basketInfoForm} onSubmit={onSubmit}>
      <div className="w-full">
        <div className="flex justify-between items-center">
          <h1 className="h500 my-2">{t("Basket")}</h1>
          <BasketEditButton />
        </div>
        <BasketEmpty />

        {/* Cross Basket */}
        {Object.entries(groupedByDateSeconds).map(
          ([dateSeconds, ordersForDateSeconds], i) => {
            const groupedByModule = groupBy(
              ordersForDateSeconds,
              (order) => order.moduleId
            );

            const dateFormatted = moment
              .unix(parseInt(dateSeconds))
              .format("DD/MM");

            const dayIndex = getDayIndexFromSeconds(parseInt(dateSeconds));

            return (
              <div key={dateSeconds}>
                <div className="font-medium font-lg my-2 text-primary-main">
                  {t(dayLabels.capitalized[dayIndex])} {dateFormatted}
                </div>
                {Object.entries(groupedByModule).map(([moduleId, orders]) => {
                  const order = orders[0]; // Only allowing one order per module per day
                  const { orderLines } = order;
                  const targetModule = modules?.find((m) => m.id === moduleId);

                  const showModuleName =
                    Object.keys(groupedByModule).length > 1;

                  const setOrderLines = (orderLines: OrderLines) =>
                    setBasket({
                      orderLines,
                      moduleId,
                      dateSeconds: parseInt(dateSeconds),
                      info: order.info,
                      id: order.id,
                    });

                  const sortedOrderLines = sortProducts({
                    items: sortBy(orderLines, (line) =>
                      stringifyOrderLine(line)
                    ).filter((o) => o.amount),
                    productLines: items,
                  });

                  if (!targetModule) return null;

                  return (
                    <div key={moduleId}>
                      {showModuleName && (
                        <div className="my-2 font-medium uppercase text-text-secondary">
                          {targetModule?.name}
                        </div>
                      )}
                      {sortedOrderLines.map((orderLine) => (
                        <div key={stringifyOrder([orderLine])}>
                          <BasketListItem
                            item={orderLine}
                            basket={orderLines}
                            setBasket={setOrderLines}
                            dateSeconds={parseInt(dateSeconds)}
                          />
                        </div>
                      ))}
                      <OrderInfoItem
                        module={targetModule}
                        dateSeconds={parseInt(dateSeconds)}
                        basket={orderLines}
                        setBasket={setOrderLines}
                      />
                    </div>
                  );
                })}
                {Object.keys(groupedByDateSeconds).length !== i + 1 && (
                  <Divider />
                )}
              </div>
            );
          }
        )}
      </div>
    </OrderInfoFormWrapper>
  );
};
