import { DayIndex, Holiday } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";

export const useHolidays = () => {
  const { week, holidayDates } = useContainer(OrderingContext);

  const getHolidays = (isBoolean = false) =>
    week.map((_, index) => {
      const dayIndex = index as DayIndex;
      const dateSeconds = week[dayIndex]?.seconds as number;
      const holidayOrNull = holidayDates?.[dateSeconds];
      return isBoolean ? !!holidayOrNull : holidayOrNull;
    });

  const daysHolidays = getHolidays(true) as boolean[];
  const actualHolidays = getHolidays(false);

  const hasActiveModuleSwitchHoliday =
    actualHolidays.every((h) => h) &&
    actualHolidays.some((h) => (h as Holiday)?._proPluginModuleSwitch);

  return { daysHolidays, actualHolidays, hasActiveModuleSwitchHoliday };
};
