import { faFilePen } from "@fortawesome/pro-solid-svg-icons";
import { OrderingContext } from "@kanpla/ordering";
import { T } from "@kanpla/system";
import { DeliveryNote } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { isStandardOrderingAtom } from "../../Navigation";
import { DeliveryNoteModal } from "./DeliveryNoteModal";
import { useLoadDeliveryNotes } from "./useLoadDeliveryNotes";

const daysArr: number[] = Array(5).fill(0);

export const CustomDeliveryNote = () => {
  const isStandardOrdering = useAtomValue(isStandardOrderingAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dayIndex, setDayIndex] = useState(0);
  const { week, module } = useContainer(OrderingContext);
  const dateSeconds = week[dayIndex]?.seconds;
  const { dataDateMap } = useLoadDeliveryNotes();

  if (isStandardOrdering) return null;

  if (!module?.plugins?.customDeliveryNote?.active) return null;

  const deliveryNote = {
    ...dataDateMap.get(dateSeconds),
    dateSeconds,
  } as DeliveryNote;

  return (
    <div className="mt-2 p-4 bg-background-secondary rounded-lg flex items-center border-l-4 border-transparent">
      <div className="w-5/12 font-bold">
        <T _str="Note for delivery:" />
      </div>
      <div className="w-7/12 flex justify-around">
        {daysArr.map((_, index) => (
          <div className="w-16">
            <Button
              className="w-full bg-transparent text-secondary-dark"
              icon={faFilePen}
              type="white"
              size="small"
              onClick={() => {
                setDayIndex(index);
                setIsModalOpen(true);
              }}
            />
          </div>
        ))}
      </div>
      <DeliveryNoteModal
        deliveryNote={deliveryNote}
        dateSeconds={dateSeconds}
        open={isModalOpen}
        setOpen={setIsModalOpen}
      />
    </div>
  );
};
