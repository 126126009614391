import { Child, _FrontendModule } from "@kanpla/types";

type Props = {
  child: Child;
  module: _FrontendModule;
};

export const isDefaultReferenceValid = ({ child, module }: Props) => {
  const minimumDefaultLength = module?.plugins?.invoiceReference?.required
    ? 1
    : 0;
  const minLength =
    module?.plugins?.invoiceReference?.minLength || minimumDefaultLength;
  const referenceValid = (child?.defaultReference?.length || 0) >= minLength;
  return referenceValid;
};
