import { LoadFlexBulkProps, LoadFlexBulkReturn } from "@kanpla/services";
import { useFetch } from "@kanpla/system";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { mapValues } from "lodash";
import { useEffect } from "react";
import { groupNameAtom, moduleIdAtom, schoolIdAtom } from "../../context";
import { dateInfoAtom } from "../../lib/hooks/useDateInfo";
import { currentWeekDocumentsAtom } from "../softDeadline/currentWeekDocumentsAtom";
import { emptyWeeklyOrders, useSetLoadedOrders } from "./loadedOrdersAtom";

export const loadingWeeklyDocumentsAtom = atom(false);

export const useLoadWeeklyOrders = () => {
  const moduleId = useAtomValue(moduleIdAtom) as string;
  const groupName = useAtomValue(groupNameAtom) as string;
  const schoolId = useAtomValue(schoolIdAtom) as string;
  const { weekSeconds } = useAtomValue(dateInfoAtom);
  const setCurrentWeekDocuments = useSetAtom(currentWeekDocumentsAtom);
  const setLoading = useSetAtom(loadingWeeklyDocumentsAtom);

  const setLoadedOrders = useSetLoadedOrders();

  useEffect(() => {
    setLoadedOrders(emptyWeeklyOrders, `weekly-${weekSeconds}`);
  }, [weekSeconds, moduleId, groupName, schoolId]);

  const { data, setData, loading } = useFetch<
    LoadFlexBulkProps,
    LoadFlexBulkReturn
  >(
    "load/getFlexBulkWeeklyOrders",
    { weekSeconds, moduleId, groupName, schoolId },
    {
      fallbackData: emptyWeeklyOrders,
    }
  );

  useEffect(() => {
    if (!data) return;

    setCurrentWeekDocuments(data);

    const orderLines = mapValues(data, (order) => order?.orderLines || []);
    setLoadedOrders(orderLines, `weekly-${weekSeconds}`);
  }, [data]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return { setData };
};
