import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { useBasketPreventClick } from "@kanpla/ordering";
import { filterModulesForChild, hasAccessToModule, useT } from "@kanpla/system";
import { Module, _FrontendModule } from "@kanpla/types";
import {
  BottomNavigation,
  Popper,
  Tab,
  TabIcon,
  createHapticFeedback,
  fontAwesomeCollection,
} from "@kanpla/ui";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import { useContainer } from "unstated-next";
import { moduleDefaultIcons } from "./anonymous/AnonymousTabs";
import { AppContext } from "./contextProvider";

const Tabs = () => {
  const { allModules, school, child, module, mobile, appLoading, isBulk } =
    useContainer(AppContext);

  // Control the state of the "More" tab
  const [open, setOpen] = useState(false);

  // Filter available modules
  const modules: _FrontendModule[] = filterModulesForChild({
    modules: allModules || [],
    school,
    child,
  });

  const {
    tabs = [],
    info = [],
  }: {
    tabs: JSX.Element[];
    info: {
      label: string;
      iconKey: [IconPrefix, keyof typeof fontAwesomeCollection.fad];
      moduleId: Module["id"];
      active: boolean;
    }[];
  } = modules.reduce(
    (acc, m) => {
      const hide = mobile
        ? m?.displayOptions?.hideMobileTab
        : m?.displayOptions?.hideDesktopTab;

      if (hide) return acc;

      if (m.type === "flex") {
        const hasAccess = hasAccessToModule({ module: m, school, child });

        if (hasAccess.individual) {
          acc.info.push({
            label: m?.displayName || m.name,
            iconKey: m?.displayIcon || moduleDefaultIcons[m.type],
            moduleId: m.id,
            active: module.id === m.id && !isBulk,
          });
          acc.tabs.push(
            <TabLink
              key={`${m.id}-individual`}
              label={`${m?.displayName || m.name}`}
              m={m}
            />
          );
        }
        if (hasAccess.bulk) {
          acc.info.push({
            label: m?.adminDisplayName || `${m?.displayName || m.name} - Admin`,
            iconKey:
              m?.adminDisplayName ||
              m?.displayIcon ||
              moduleDefaultIcons[m.type],
            moduleId: m.id,
            active: module.id === m.id && isBulk,
            isBulk: true,
          });
          acc.tabs.push(
            <TabLink
              key={`${m.id}-admin`}
              isAdminModule
              label={
                m.adminDisplayName || `${m?.displayName || m.name} - Admin`
              }
              m={m}
            />
          );
        }
      } else {
        acc.info.push({
          label: m?.displayName || m.name,
          iconKey: m?.displayIcon || moduleDefaultIcons[m.type],
          moduleId: m.id,
          active: module.id === m.id,
        });
        acc.tabs.push(
          <TabLink key={m.id} label={m?.displayName || m.name} m={m} />
        );
      }

      return acc;
    },
    { tabs: [], info: [] }
  );

  const filteredTabs = mobile ? tabs.slice(0, tabs.length <= 5 ? 5 : 4) : tabs;

  const modulesInContextMenu = tabs.length > 5 ? info.slice(4) : [];

  if (isEmpty(module)) return null;

  if (filteredTabs.length <= 1) return null;

  if (mobile)
    return (
      <BottomNavigation loading={appLoading}>
        {filteredTabs.map((tab) => tab)}
        {Boolean(modulesInContextMenu.length) && (
          <TabMenu
            modules={modulesInContextMenu}
            open={open}
            setOpen={setOpen}
          />
        )}
      </BottomNavigation>
    );

  if (appLoading)
    return (
      <div className="flex ml-4">
        <div className="loader h-9 w-24 mx-3 rounded" />
        <div className="loader h-9 w-24 mx-3 rounded" />
        <div className="loader h-9 w-24 mx-3 rounded" />
      </div>
    );

  return (
    <div className="overflow-x-auto mx-4 rounded-lg lg:mx-0">
      <div className="hidden md:flex pb-1 pt-1 text-center text-text-primary flex-none relative z-10 lg:justify-end">
        {filteredTabs.map((tab: JSX.Element) => tab)}
      </div>
    </div>
  );
};

export default Tabs;

interface LinkProps {
  label: string;
  m: _FrontendModule;
  isAdminModule?: boolean;
}

export const TabLink = ({ label, isAdminModule = false, m }: LinkProps) => {
  const { module, isBulk, setModuleId, setIsBulk, mobile } =
    useContainer(AppContext);

  const isTargetModule = m.id === module.id;

  const active =
    module.type === "flex"
      ? isAdminModule
        ? isTargetModule && isBulk
        : isTargetModule && !isBulk
      : isTargetModule;

  const confirm = useBasketPreventClick({
    disabled: false,
  });

  const handleOnClick = async () => {
    // Await confirmation from `BasketPreventClick`
    if (
      m.paymentMethod !== module.paymentMethod ||
      m.type !== module.type ||
      (m?.flow && module?.flow && m?.flow !== module?.flow)
    ) {
      await confirm?.(m);
    }

    setModuleId(m.id);
    setIsBulk(isAdminModule);
  };

  const adminIcon = isAdminModule ? m?.adminDisplayIcon : null;

  const iconKey = adminIcon || m?.displayIcon || moduleDefaultIcons[m.type];

  return (
    <Tab
      label={label}
      active={!!active}
      onClick={handleOnClick}
      iconKey={iconKey}
      moduleId={m.id}
      isMobile={mobile}
    />
  );
};

interface TabMenuProps {
  modules: Array<{
    moduleId: string;
    iconKey: string | [IconPrefix, IconName];
    label: string;
    active: boolean;
    isBulk: boolean;
  }>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const TabMenu = ({ modules, open, setOpen }: TabMenuProps) => {
  const { setModuleId, setIsBulk } = useContainer(AppContext);

  const t = useT();

  return (
    <Popper
      open={open}
      setOpen={setOpen}
      lockOnOpen
      flex
      placement="top-start"
      fitActionElement={false}
      withPadding={false}
      rounded="xl"
      shadow="2xl"
      className="mr-2 !border-0 overflow-y-scroll max-h-80"
      withBackdrop
      actionElement={
        <Tab
          moduleId="extra-modules-menu"
          iconKey={["fad", "circle-ellipsis"]}
          label={t("More")}
          isMobile
          fullWidth
          active={open || modules.some((m) => m.active)}
          className={classNames({
            "z-max !text-primary-light transition-all ease-in-out": open,
          })}
        />
      }
    >
      <div className="flex flex-col">
        <h1 className="text-sm text-center w-full px-3 py-3 bg-background-primary text-text-secondary font-medium">
          {t("Other modules")}
        </h1>
        {modules.map((module, i) => (
          <div
            key={module.moduleId}
            className={classNames(
              "px-3 py-2 flex items-center transition-colors ease-in-out",
              {
                "border-t border-secondary-main": modules.length !== i - 1,
              },
              module.active ? "text-primary-main" : "text-text-secondary"
            )}
            onClick={async () => {
              createHapticFeedback({
                type: "impact",
                impactFeedback: "light",
              });

              setModuleId(module.moduleId);
              setIsBulk(module.isBulk);

              setOpen(false);
            }}
          >
            <div className="w-7 flex justify-center items-center">
              <TabIcon iconKey={module.iconKey} size="1x" />
            </div>
            <h3
              className={classNames("ml-2", {
                "text-primary-main": module.active,
              })}
            >
              {module.label}
            </h3>
          </div>
        ))}
      </div>
    </Popper>
  );
};
