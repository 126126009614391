import { CombinedOfferItem } from "@kanpla/types";

interface Props {
  products: CombinedOfferItem[];
}

export const getAvailabilityProductsInfo = ({ products }: Props) => {
  const areSomePeriods = products.some(
    (product) => product?.fromSeconds || product?.toSeconds
  );

  return {
    areSomePeriods,
  };
};

export default getAvailabilityProductsInfo;
