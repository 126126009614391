import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  T,
  checkChildLimit,
  stringifyOrderLine,
  updateOrderLine,
  useT,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  OrderLine,
  OrderLines,
  Plugins,
} from "@kanpla/types";
import { Button, InputAmount, TimeSelect } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import moment from "moment";
import { FC, useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import {
  basketEditModeAtom,
  isOrderEditingAtom,
} from "../../shared/basket/useBasket";
import { useMinimumAmount } from "../../shared/minimumAmount/useMinimumAmount";
import { dateInfoAtom } from "../hooks/useDateInfo";

export interface BasketListItemProps {
  /** A configuration item from the basket */
  item: OrderLine;
  /** The basket */
  basket: OrderLines;
  /** Setter function for the basket */
  setBasket: (order: OrderLines) => void;
}

export const BasketListItemEditing: FC<BasketListItemProps> = ({
  item,
  basket,
  setBasket,
}) => {
  const basketEditMode = useAtomValue(basketEditModeAtom);
  const isOrderEditing = useAtomValue(isOrderEditingAtom);

  const t = useT();
  const { productId } = item;

  const {
    offer,
    module,
    activePlugins,
    dateSeconds,
    fromAdmin,
    isOrderInvoiced,
    childOrders: orders = [],
  } = useContainer(OrderingContext);
  const { isPastTodayIndividualDeadline } = useAtomValue(dateInfoAtom);
  const items = offer?.items || [];

  const product = items?.find(
    (p: CombinedOfferItem) => p.productId === productId
  );

  const minimumAmount = useMinimumAmount(
    basket,
    stringifyOrderLine(item),
    product
  );

  const isRequiredProduct =
    productId === module?.plugins?.requiredProduct?.productId;

  const isAdhoc = productId.startsWith("ad-hoc-");
  const isCustomProduct = productId.startsWith("custom-");
  const isPastIndividualDeadline = isPastTodayIndividualDeadline(
    product?.individualDeadline
  );
  const isEditable =
    fromAdmin ||
    (basketEditMode &&
      !isAdhoc &&
      !isRequiredProduct &&
      !isPastIndividualDeadline);

  const plugins = module?.plugins || ({} as Plugins.Array);

  // Original amount is used for pay-per-order
  const [originalAmount] = useState(item.amount);

  const isSignupOfferProduct =
    activePlugins["signupOffer"] &&
    plugins?.signupOffer?.productId === productId;

  const isPayPerOrder = activePlugins?.["payPerOrder"];
  const isMeeting = module?.flow === "meeting";

  const productStock = product?.dates?.[dateSeconds]?.stock ?? Infinity;
  let stock = (productStock >= 0 ? productStock : undefined) as number;
  if (isSignupOfferProduct && (stock || 0) > 1) stock = 1;

  // Get the quantity the user can order for this product
  const maxFromLimitPerChild = product
    ? checkChildLimit({ orders, product })
    : 0;

  const reachedLimit = maxFromLimitPerChild <= 0;
  const limit = reachedLimit ? 0 : maxFromLimitPerChild;

  const changeAmount = (amount: number) => {
    const newBasket = updateOrderLine({
      orderLines: basket,
      targetLine: item,
      newData: { amount },
      removeOrderLine: amount === 0,
    });

    setBasket(newBasket as OrderLines);
  };

  const setTime = (newTimeValue: number) => {
    if (newTimeValue && newTimeValue === item.deliveryTime) return;

    const newBasket = updateOrderLine({
      orderLines: basket,
      targetLine: item,
      newData: {
        deliveryTime: newTimeValue,
      },
    });

    setBasket(newBasket as OrderLines);
  };

  if (!isEditable) return null;

  // Adjust for if there's other limits
  const getMaxAmount = () => {
    if (isCustomProduct) return Infinity;

    if (stock <= 0) return originalAmount || 0;

    if (isSignupOfferProduct && stock > 1) return 1;

    if (isPayPerOrder && !isMeeting) return originalAmount;

    const isInBasket = basket.some((p) => p.productId === productId);
    const isOrdered = orders.some((o) =>
      (o.orderLines || []).some((ol) => ol.productId === productId)
    );

    if (isSignupOfferProduct && (isInBasket || isOrdered)) return 0;

    if (!product) return item.amount;

    if (isOrderEditing) {
      const amountOrdered = orders.reduce((acc, o) => {
        const orderLine = o.orderLines.find((ol) => ol.productId === productId);
        return acc + (orderLine?.amount || 0);
      }, 0);

      return stock + amountOrdered;
    }

    if (!product.limitPerChild) return stock;

    // Otherwise return the lowest number
    return Math.min(stock || Infinity, limit);
  };

  const maxAmountForUser = getMaxAmount();

  // Admins can increase the amount of a product only if the payment method is billing or
  // if the order has NOT already been billed
  const maxAmountForAdmin =
    module.paymentMethod === "credit" || isOrderInvoiced
      ? originalAmount
      : stock;

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center flex-1">
        <InputAmount
          amount={item.amount}
          setAmount={(value: number) => changeAmount(value)}
          minAmount={minimumAmount || 0}
          maxAmount={fromAdmin ? maxAmountForAdmin : maxAmountForUser}
          size="small"
          className="mr-3"
          disabled={
            (!product && !fromAdmin && !isCustomProduct) || isRequiredProduct
          }
        />
        {activePlugins["timeInput"] &&
          (module.plugins?.timeInput?.individualDeliveryTime ?? true) && (
            <TimeSelect
              interval={plugins.timeInput?.interval}
              endAt={plugins.timeInput?.endAt}
              startAt={plugins.timeInput?.startAt}
              /** Sometimes the deliveryTime is undefined */
              value={item.deliveryTime || plugins.timeInput?.startAt}
              onChange={setTime}
              defaultText={t("Default delivery time")}
              takeaway={
                moment().isSame(moment.unix(dateSeconds), "day") &&
                plugins?.timeInput?.takeaway
              }
            />
          )}
      </div>
      {isRequiredProduct ? null : product || fromAdmin || isCustomProduct ? (
        <Button
          dataCy="basket-list-item-delete"
          className="ml-3"
          size="medium"
          type="danger"
          onClick={() => changeAmount(0)}
          disabled={!product && !fromAdmin && !isCustomProduct}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      ) : (
        <p className="text-warning-dark text-xs text-right">
          <T _str="This product is no longer available. Contact your kitchen to adjust your order." />
        </p>
      )}
    </div>
  );
};
