import { OrderingContext } from "@kanpla/ordering";
import { checkChildLimit, getUnitPrice, T, useT } from "@kanpla/system";
import { CombinedOfferItem, Plugins } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { StringParam, useQueryParam } from "next-query-params";
import { useContainer } from "unstated-next";
import { dateInfoAtom } from "../lib/hooks/useDateInfo";
import { useNextAvailableDate } from "../lib/hooks/useNextAvailableDate";
import { useAllowance } from "../shared/allowance/useAllowance";
import { basketAtom } from "../shared/basket/useBasket";
import NavigateToNextAvailableDay from "../shared/navbar/NavigateToNextAvailableDay";
import { Product as ProductTemplate } from "../shared/product/Product";
import AllowanceOverInfo from "./AllowanceOverInfo";
import DeadlineInfo from "./DeadlineInfo";
import useOnPurchase from "./useOnPurchase";

interface Props {
  product: CombinedOfferItem;
  commonDeadline?: number | boolean;
  pastDeadline?: boolean;
  /** Shows also products after deadline */
  showChildrenAnyway?: boolean;
  hidePriceInLabel?: boolean;
  maxDisabled?: boolean;
}

const Product = (props: Props) => {
  const {
    product,
    commonDeadline = true,
    pastDeadline = false,
    showChildrenAnyway,
    hidePriceInLabel,
    maxDisabled = false,
  } = props;

  const t = useT();

  const {
    defaultReference,
    child,
    activePlugins,
    module,
    dayIndex,
    week,
    orderInfo,
    orderLines,
    hasRequiredProduct,
    childOrders: orders = [],
    dateSeconds,
    fromAdmin,
    overviewEditingOrder,
  } = useContainer(OrderingContext);

  const basket = useAtomValue(basketAtom);

  const [openProductId, setOpenProductId] = useQueryParam(
    "productId",
    StringParam
  );

  const currentDayTimestamp = week[dayIndex]?.seconds;

  const { onPurchase, orderButtonDisabled } = useOnPurchase({ product });
  const { isPastTodayIndividualDeadline } = useAtomValue(dateInfoAtom);

  const { shouldDisableProduct } = useAllowance();
  const shouldDisableForAllowance = shouldDisableProduct(product);

  // Next available date to order
  const nextAvailableDate = useNextAvailableDate({ product });

  const { productId } = product;

  const plugins = module.plugins || ({} as Plugins.Array);

  const isDish =
    product?.category === "" ||
    product?.category === "Dagens ret" ||
    /**
     * The `isBig` exists and is coming from `useCategories`. I had to add it since we already give them category names there
     */
    product?.isBig ||
    false;

  // Check if there are any other products in the basket and get the amount
  const amountInBasket = Object.values(basket).reduce(
    (acc, productInBasket) =>
      productInBasket.productId === productId
        ? acc + productInBasket?.amount
        : acc,
    0
  );

  const productStock = product.dates?.[dateSeconds]?.stock || 0;
  let stock = (
    productStock >= 0
      ? (product.dates[dateSeconds]?.stock as number) - amountInBasket
      : undefined
  ) as number;

  // Get the quantity the user can order for this product
  const maxFromLimitPerChild =
    checkChildLimit({ orders, product }) - amountInBasket;
  const reachedLimit = maxFromLimitPerChild <= 0;
  const limit = reachedLimit ? 0 : maxFromLimitPerChild;

  /** Signup offer plugin */
  const isSignupOfferProduct =
    activePlugins?.["signupOffer"] &&
    plugins?.signupOffer?.productId === productId;
  const signupOfferExpired =
    isSignupOfferProduct && child?.takenSignupOffer === true;
  if (isSignupOfferProduct) stock = 1;

  // backend disabled editing
  const disabledEditingFromBackend =
    fromAdmin &&
    !isEmpty(overviewEditingOrder) &&
    module.paymentMethod === "credit";

  // Adjust for if there's other limits
  const getMaxAmount = () => {
    if (stock <= 0) return 0;

    if (isSignupOfferProduct && stock > 1) return 1;

    const isInBasket = basket.some((p) => p.productId === productId);
    const isOrdered = orders.some((o) =>
      (o.orderLines || []).some((ol) => ol.productId === productId)
    );

    if (isSignupOfferProduct && (isInBasket || isOrdered)) return 0;

    if (!product.limitPerChild) return stock;

    // Otherwise return the lowest number
    return Math.min(stock || Infinity, limit);
  };

  const maxAmount = getMaxAmount();

  if (!product) return null;
  if (signupOfferExpired) return null;

  // Add base price for (before) taxes
  product.basePrice = getUnitPrice(product);

  const individualOnset = product?.individualOnset;
  const isBeforeFlexibleStartDate =
    typeof individualOnset === "number" &&
    !isPastTodayIndividualDeadline(individualOnset);

  const disabled =
    pastDeadline ||
    reachedLimit ||
    maxAmount === 0 ||
    disabledEditingFromBackend ||
    isBeforeFlexibleStartDate ||
    shouldDisableForAllowance;

  const lessThan10Products = maxAmount && stock <= 10;

  return (
    <ProductTemplate
      disabled={disabled}
      open={openProductId === productId}
      setOpen={(open) => setOpenProductId(open ? productId : undefined)}
      isBig={isDish || product?.isLeftover}
      product={product}
      stock={stock}
      order={orderLines}
      onPurchase={onPurchase}
      purchaseLabel={t("Add to basket")}
      fromAdmin={fromAdmin}
      plugins={plugins}
      hasRequiredProduct={hasRequiredProduct}
      orderInfo={orderInfo}
      hideAmount={false}
      maxAmount={maxAmount}
      maxDisabled={maxDisabled}
      defaultReference={defaultReference}
      orderButtonDisabled={orderButtonDisabled}
      hidePriceInLabel={hidePriceInLabel}
      previewProps={{
        view: module?.flow === "meeting" ? "table" : "grid",
        children: (
          <div className="absolute -mx-2 flex flex-col items-start -mt-2">
            {shouldDisableForAllowance ? (
              <AllowanceOverInfo />
            ) : (
              <>
                {isSignupOfferProduct && (
                  <div className="flex text-xs text-background-primary pb-1 bg-primary-dark font-medium px-2 pt-1 rounded-lg bg-opacity-60">
                    {plugins?.signupOffer?.promoText || t("Sign up offer")}
                  </div>
                )}
                {!isSignupOfferProduct && (
                  <DeadlineInfo
                    individualDeadline={product.individualDeadline}
                    individualOnset={product?.individualOnset}
                    hidden={commonDeadline}
                    showChildrenAnyway={showChildrenAnyway}
                    nextAvailableDate={nextAvailableDate}
                    outOfStock={maxAmount === 0}
                    hideNextDay={product?.isLeftover || false}
                  />
                )}
                {lessThan10Products ? (
                  <div className="text-xs text-background-primary pb-1 font-medium px-2 pt-1 rounded-lg bg-opacity-70 mt-1 bg-warning-dark">
                    <T _str="There are only {quantity} left" quantity={stock} />
                  </div>
                ) : null}
              </>
            )}
          </div>
        ),
      }}
      currentDayTimestamp={currentDayTimestamp}
      DisabledContent={() => <NavigateToNextAvailableDay />}
    />
  );
};

export default Product;
