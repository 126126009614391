/* eslint-disable @typescript-eslint/ban-ts-comment */
import { faEllipsisH, faPen, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@kanpla/system";
import { CustomProduct } from "@kanpla/types";
import {
  Button,
  Form,
  Input,
  InputAmount,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PriceInputWithVat,
  SelectCreatableLegacy,
} from "@kanpla/ui";
import { Dispatch, SetStateAction, useState } from "react";
import { ChangeVatRate } from "./ChangeVatRate";
import { DEFAULT_PRODUCT_NAME } from "./CustomOrderModal";

type CustomOrderFormProps = {
  customProduct: CustomProduct;
  setCustomProducts: Dispatch<SetStateAction<CustomProduct[]>>;
  categories: string[];
};

export const CustomOrderForm = ({
  customProduct: { productId, vatRate },
  setCustomProducts,
  categories,
}: CustomOrderFormProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Form.Item
        name={`amount_${productId}`}
        controlled
        controlledProps={{
          valueName: "amount",
          onChangeName: "setAmount",
        }}
        className="flex items-center"
      >
        {/* @ts-ignore */}
        <InputAmount />
      </Form.Item>
      <Form.Item name={`name_${productId}`} className="flex items-center">
        <Input placeholder={DEFAULT_PRODUCT_NAME} />
      </Form.Item>
      <Form.Item name={`category_${productId}`} className="flex items-center">
        {/* @ts-ignore */}
        <SelectCreatableLegacy defaultOptions={categories} />
      </Form.Item>
      <div className="relative flex items-center gap-5 col-span-2">
        <Form.Item
          name={`unitPrice_${productId}`}
          className="flex items-center"
        >
          <PriceInputWithVat vatRate={vatRate} defaultShowingVat />
        </Form.Item>

        <Popover>
          <PopoverTrigger>
            <FontAwesomeIcon icon={faEllipsisH} />
          </PopoverTrigger>

          <PopoverContent className="z-60">
            <Button
              size="small"
              icon={faPen}
              className="w-full text-left"
              type="white"
              onClick={() => setOpen(true)}
            >
              <T _str="Custom VAT rate" />
            </Button>
            <Button
              onClick={() =>
                setCustomProducts((prev) =>
                  prev.filter((product) => product.productId !== productId)
                )
              }
              size="small"
              icon={faTrash}
              className="w-full text-left"
              type="danger"
              shape="plain"
            >
              <T _str="Delete" />
            </Button>
          </PopoverContent>
        </Popover>
      </div>
      <ChangeVatRate
        open={open}
        setOpen={setOpen}
        setCustomProducts={setCustomProducts}
        productId={productId}
        vatRate={vatRate}
      />
    </>
  );
};
