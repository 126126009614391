import { useInvoiceReference } from "@kanpla/ordering";
import {
  FieldValue,
  callInternalApi,
  db,
  getChildDisplayName,
  getChildGroupName,
  getErrorMessage,
  useT,
  validateSelectors,
} from "@kanpla/system";
import { Child, ChildSelector, School } from "@kanpla/types";
import {
  Button,
  Form,
  Input,
  InvoiceReference,
  SelectorsInput,
  message,
} from "@kanpla/ui";
import {
  IdExistsProps,
  IdExistsReturn,
} from "libs/services/src/lib/_microservices/idExists";
import { useMemo, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import SelectSchool from "./SelectSchool";
import KanplaCard from "./child/KanplaCard";
import { useQuerySchools } from "./child/useQuerySchools";

interface Props {
  submit: (data: Child) => Promise<void>;
  child?: Child;
  edit?: boolean;
  isChildSalesplace?: boolean;
}

type FormData = {
  name: string;
  school: School | null;
  invoiceReference: string;
  kanplaId: string | number;
  selectors: ChildSelector;
};

const ChildForm = ({
  submit,
  edit,
  child,
  isChildSalesplace = false,
}: Props) => {
  const t = useT();

  const {
    children,
    supplier,
    allModules,
    defaultReference,
    userId,
    school: childSchool,
    user,
  } = useContainer(AppContext);

  /** Fetching happens here to not let fetch happen more then once. */
  const {
    data: { schools: allSchools },
    isFetching,
  } = useQuerySchools(children);

  const { minCharacters, defaultRequired, title } = useInvoiceReference();

  const schools: School[] = useMemo(
    () =>
      ((edit ? [childSchool] : allSchools) as School[]).filter((s) => {
        if (child?.schoolId === s?.id) return true;
        return !s.hidden && !s.deleted;
      }),
    [allSchools, child, childSchool, edit]
  );

  const school = useMemo(
    () =>
      schools.find((s) => s.id === child?.schoolId) ||
      (children && children[0]
        ? schools.filter((s) => s.id === child?.schoolId)[0]
        : null),
    [schools, child, children]
  );

  const kanplaId =
    child &&
    child.kanplaId &&
    child.kanplaId >= 100000 &&
    child.kanplaId <= 140000
      ? child.kanplaId
      : "";

  const [haveCard, setHaveCard] = useState(
    child && child.kanplaId
      ? child.kanplaId >= 100000 && child.kanplaId <= 140000
        ? true
        : false
      : true
  );

  const [loading, setLoading] = useState(false);

  const moduleWithReference =
    allModules?.find((module) => {
      if (!child?.schoolId) return false;

      if (!module?.scope?.generatedSchoolIds?.includes(child?.schoolId))
        return false;

      return module?.plugins?.invoiceReference?.active;
    }) || null;

  const hasReferencePlugin = Boolean(moduleWithReference);

  const validate = async (data: FormData) => {
    try {
      setLoading(true);

      const { name, school, selectors, invoiceReference, kanplaId } = data;

      // School ref
      const school_ref = db.collection("schools").doc(school?.id);
      if (!school_ref && !isChildSalesplace) {
        setLoading(false);
        message.error(t("Invalid point of sale"));
        return;
      }

      // Adding/changing card (Check id)
      const newId = !child || child.kanplaId !== kanplaId;
      const changingCard = school?.hasCards && haveCard && newId;

      if (changingCard) {
        const { exists } = await callInternalApi<IdExistsProps, IdExistsReturn>(
          "load/idExists",
          { kanplaId: parseInt(kanplaId.toString()) }
        );

        // If yes, abort
        if (exists) {
          message.error(t("The Kanpla ID is already in use"));
          setLoading(false);
          return;
        }
      }

      // Removing card
      const removingCard =
        school?.hasCards &&
        edit &&
        !haveCard &&
        child?.kanplaId?.toString().length === 6;

      if (!validateSelectors(selectors, school?.selectors || []))
        throw new Error(t("Fill all the fields"));

      // Create short name
      const displayName = getChildDisplayName(name);

      // Create group name
      const groupName = getChildGroupName(selectors);

      const dataChild = {
        userId,
        name,
        selectors,
        ...(!child && {
          school_ref,
          school_name: school?.name,
          schoolId: school?.id,
        }),
        ...(changingCard && { kanplaId: parseInt(kanplaId + "") }),
        ...(removingCard && {
          kanplaId: FieldValue.delete(),
          cardHEX: FieldValue.delete(),
        }),
        // Auto generated fields (for faster data processing)
        displayName,
        groupName,

        // Default reference
        defaultReference: invoiceReference,
      } as Child;

      // Submit
      await submit(dataChild);
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      console.error(errorMessage);
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form<FormData>
      layout="vertical"
      onSubmit={validate}
      defaultValues={{
        invoiceReference: defaultReference,
        name: (child && child.name) || "",
        school: school || {},
        kanplaId,
        selectors: child?.selectors,
      }}
    >
      <Form.Item
        name="name"
        rules={{
          required: t("Please enter the full name"),
        }}
      >
        <Input label={t("Name")} placeholder={t("Enter a full name")} />
      </Form.Item>

      {!isChildSalesplace && (
        <Form.Item
          label={t("Point of sale")}
          name="school"
          controlled
          controlledProps={{
            valueName: "school",
            onChangeName: "setSchool",
          }}
          rules={{ required: t("Please select a salesplace") }}
        >
          {/* @ts-ignore */}
          <SelectSchool
            schools={schools}
            edit={edit}
            company={typeof supplier?.partnerId !== "undefined"}
            loading={isFetching}
          />
        </Form.Item>
      )}

      {!isChildSalesplace && (
        <Form.Item
          name="selectors"
          controlled
          controlledProps={{
            valueName: "selectors",
            onChangeName: "setSelectors",
          }}
          className="mt-1"
        >
          {({ watch }) => (
            // @ts-ignore
            <SelectorsInput
              school={watch("school") || school}
              email={user?.email}
            />
          )}
        </Form.Item>
      )}

      {school?.hasCards && (
        <Form.Item name="kanplaId" controlled>
          {/* @ts-ignore */}
          <KanplaCard haveCard={haveCard} setHaveCard={setHaveCard} />
        </Form.Item>
      )}

      {hasReferencePlugin && (
        <Form.Item
          name="invoiceReference"
          rules={{
            required:
              defaultRequired &&
              t("{fieldName} is required", {
                fieldName: title,
              }),
            minLength:
              minCharacters &&
              t("Write at least {value} characters", {
                value: minCharacters,
              }),
          }}
          controlled
        >
          <InvoiceReference module={moduleWithReference} noExtraMargin />
        </Form.Item>
      )}

      <Button
        type="primary"
        dataCy="btn-childForm-submit"
        shape="solid"
        loading={loading}
        loadingText={t("Please wait...")}
        htmlType="submit"
        size="large"
        className="mt-1"
      >
        {edit ? `${t("Save changes")}` : t("Create user")}
      </Button>
    </Form>
  );
};

export default ChildForm;
