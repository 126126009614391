import { FlexStandard } from "@kanpla/types";

type FindHolidayArgs = {
  periodsRanges: Array<
    NonNullable<FlexStandard["holidays"]>[0] & { ranges: number[] | Date[] }
  >;
  dateSeconds: string;
};

export const findHoliday = ({
  periodsRanges,
  dateSeconds,
}: FindHolidayArgs) => {
  const period = periodsRanges.find((period) => {
    const dateSecondsArePresent = !!(period.ranges as number[]).find(
      (seconds) => seconds === Number(dateSeconds)
    );

    return dateSecondsArePresent;
  });

  return { isHoliday: !!period, period };
};
