import { scroll2Elem } from "@kanpla/system";
import classnames from "classnames";
import React, { ReactNode, useEffect, useRef } from "react";

type SliderItemProps = {
  scrollableElement?: HTMLDivElement;
  selected?: boolean;
  onClick: () => void;
  children: ReactNode;
};

const SliderItem = ({
  children,
  onClick,
  scrollableElement,
  selected = false,
}: SliderItemProps) => {
  const ref = useRef<HTMLDivElement>();

  /**
   * The smooth scrolling shouldn't be handled on time of click event,
   * since the click event can be prevented by the parent component of `HorizontalSlider`.
   * Instead, it should be handled on `selectedIndex` change.
   */
  useEffect(() => {
    if (!selected || !scrollableElement) return;
    const parentWidth = scrollableElement.clientWidth;
    const currentWidth = ref.current?.clientWidth || 0;
    /** The margins of items is not counted here. */
    const offsetX = parentWidth / 2 - currentWidth / 2;

    scroll2Elem(ref.current, { offsetX, scrollableElement });
  }, [selected, scrollableElement]);

  return (
    <div
      ref={ref}
      onClick={onClick}
      className={classnames(
        "w-fit mr-3 whitespace-nowrap px-4 py-2",
        "rounded-full cursor-pointer h-full md:hover:bg-primary-light md:hover:bg-opacity-20 md:hover:text-primary-dark transition font-medium select-none",
        { "bg-primary-light bg-opacity-20 text-primary-dark": selected },
        {
          "text-text-secondary bg-background-secondary bg-opacity-50":
            !selected,
        }
      )}
    >
      {children}
    </div>
  );
};

export default SliderItem;
