import { Metric } from "@kanpla/types";

const mappedMetrics = {
  plate: "info",
  buffet: "success",
  meeting: "warning",
  production: "danger",
};

export const metricToColorClass = (metric: Metric) => mappedMetrics[metric];
