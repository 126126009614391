import { FlexChoice, FlexOption, OrderLine } from "@kanpla/types";
import { cloneDeep } from "lodash";
import { useAvailableOptions } from "../input/helpers/useAvailableOptions";

interface Props {
  productId: string;
}

type Config = OrderLine["options"];

export const useAllConfigs = ({ productId }: Props) => {
  const allAvailableOptions: FlexOption[] = useAvailableOptions({ productId });

  const getAllConfigs = (
    availableOptions: FlexOption[],
    prevConfigs: Config[] = [{}]
  ): Config[] => {
    // No more layers to process
    if (!availableOptions || availableOptions.length === 0) {
      return prevConfigs;
    }

    // Get the choices for the current layer
    const choices: FlexChoice[] = availableOptions[0].choices || [];

    // Initialize the configs for the current layer
    const configs: Config[] = [];

    // For each previous config and each choice, create a new config
    prevConfigs.forEach((prevConfig: Config) => {
      choices.forEach((choice: FlexChoice) => {
        const newConfig: Config = {
          ...prevConfig,
          [choice.id]: {
            amount: 1,
            name: choice.name,
            id: parseInt(choice.id),
          },
        };
        configs.push(newConfig);
      });
    });

    // Recursive call for the remaining layers
    return getAllConfigs(availableOptions.slice(1), configs);
  };

  const res: Config[] = getAllConfigs(cloneDeep(allAvailableOptions), [{}]);
  return res;
};
