import { callInternalApi } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { DefinedUseQueryResult, useQuery } from "@tanstack/react-query";
import {
  LoadSchoolsProps,
  LoadSchoolsResult,
} from "apps/frontend/pages/api/internal/load/schools";
import { uniq } from "lodash";

const cacheBasket = new Map();

/**
 * Loads schools for child creation.
 * Uses cache if already exists.
 **/
export const useQuerySchools = (children: Child[]) => {
  const requiredSchoolIds = uniq(children.map((c) => c.schoolId)).sort();
  const cacheKey = requiredSchoolIds.join("-");
  const cacheItem = cacheBasket.get(cacheKey);

  const result = useQuery({
    queryKey: ["loadChildAvailableSchools"],
    queryFn: () =>
      callInternalApi<LoadSchoolsProps, LoadSchoolsResult>("load/schools", {
        requiredSchoolIds,
      }),
    initialData: { schools: [] },
    refetchOnMount: "always",
    refetchOnWindowFocus: false,
    retry: 1,
    refetchIntervalInBackground: false,
    enabled: !cacheItem?.isFetched,
  });

  if (cacheItem?.isFetched && !cacheItem.isFetching) return cacheItem;

  if (!cacheItem && result.isFetched && !result.isFetching) {
    cacheBasket.set(cacheKey, result);
  }

  return result;
};
