import { DayIndex } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { isStandardOrderingAtom } from "../../../Navigation";
import { Change, orderChangesAtom } from "../../../data/changesAtom";
import { displayOrdersAtom } from "../../../data/loadedOrdersAtom";
import { useGetDisabledByDeadline } from "../../../softDeadline/useDisabledByDeadline";
import DailyInput from "./DailyInput";
import { getIsValueEdited } from "./helpers/getIsValueEdited";
import { useOnChange } from "./helpers/useOnChange";
import { valueFromOrders } from "./helpers/valueFromOrders";
import { usePlaceholder } from "./usePlaceholder";

interface Props {
  productId: Change["productId"];
  dayIndex: DayIndex;
  options: Change["options"];
  /** If there are variants and this is the default choice, only show total */
  showTotal?: boolean;
  disabled?: boolean;
}

export const DailyInputWeeklyOrder = ({
  productId,
  dayIndex,
  options,
  showTotal,
  disabled: disabledProps,
}: Props) => {
  const weekOrders = useAtomValue(displayOrdersAtom);
  const orderChanges = useAtomValue(orderChangesAtom);
  const isStandardOrdering = useAtomValue(isStandardOrderingAtom);

  const onChange = useOnChange();

  const getDisabledByDeadline = useGetDisabledByDeadline();
  const { disabled: disabledDeadline } = getDisabledByDeadline(dayIndex);
  const disabled = isStandardOrdering
    ? false
    : disabledProps || disabledDeadline;

  const value = valueFromOrders({
    productId,
    dayIndex,
    options,
    orders: weekOrders,
  });
  const isValueEdited = getIsValueEdited({
    productId,
    dayIndex,
    options,
    changes: orderChanges,
  });

  const placeholder = usePlaceholder({ productId, dayIndex, options });

  return (
    <DailyInput
      value={value}
      hasOrderForTheDay={Boolean(weekOrders[dayIndex])}
      placeholder={placeholder}
      isValueEdited={isValueEdited}
      disabled={disabled}
      onChange={(amount: number) =>
        onChange({ productId, dayIndex, amount, options })
      }
      showTotal={showTotal}
    />
  );
};
