import { t } from "@kanpla/system";
import { Module, School } from "@kanpla/types";
import { DrawerOrModal } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { childIdToEditAtom } from ".";
import { OrderingContext } from "../../context";
import Flex from "../../flex";
import { useChildren } from "./useChildren";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const IndividualFlexModal = ({ open, setOpen }: Props) => {
  const childId = useAtomValue(childIdToEditAtom);

  const initialState = useContainer(OrderingContext);

  const { hasAccessToIndividualFlex, children } = useChildren();

  const child = children.find((c) => c.id === childId);

  const hasAccess = hasAccessToIndividualFlex({
    childId,
    module: initialState.module as Module,
    school: initialState.school as unknown as School,
  });

  if (!childId || !hasAccess || !child) return null;

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      maxWidth="w-full 2xl:w-3/4"
      title={t("Edit orders and standard order")}
      subtitle={t(
        "Standard order and personal holidays are at the bottom of the page"
      )}
    >
      {/* Override context */}
      <OrderingContext.Provider
        initialState={{
          ...initialState,
          childId,
          isBulk: false,
          child,
          userId: child.userId,
        }}
      >
        <Flex />
      </OrderingContext.Provider>
    </DrawerOrModal>
  );
};
