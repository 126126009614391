import { OrderingContext } from "@kanpla/ordering";
import { getTodaySeconds } from "@kanpla/system";
import { useEffect } from "react";
import { useContainer } from "unstated-next";

export const useKanplaGo = () => {
  const {
    setTimeNavigation,
    hasKanplaGo,
    moduleId,
    dateSeconds,
    setDate,
    module,
  } = useContainer(OrderingContext);

  // Show or hide day switch on kanpla Go
  useEffect(() => {
    setTimeNavigation(hasKanplaGo ? "none" : "todaySwitch");
  }, [hasKanplaGo, moduleId]);

  // Correct time on Kanpla Go
  const shouldAlwaysOrderForToday =
    hasKanplaGo || module?.flow === "registering";
  useEffect(() => {
    const todaySeconds = getTodaySeconds();
    const alreadySet = todaySeconds === dateSeconds;
    if (shouldAlwaysOrderForToday && !alreadySet)
      setDate({ dateSeconds: todaySeconds });
  }, [shouldAlwaysOrderForToday, dateSeconds]);
};
