import classNames from "classnames";
import { useContainer } from "unstated-next";

import { useT } from "@kanpla/system";
import { OrderDiscount } from "@kanpla/types";
import { usePriceFormatterFunction } from "@kanpla/ui";

import { useAtomValue } from "jotai";
import { OrderingContext, showDiscountPriceAtom } from "../../context";

interface DiscountBreakdownProps {
  orderDiscounts: OrderDiscount[];
}

export const DiscountBreakdown = ({
  orderDiscounts,
}: DiscountBreakdownProps) => {
  const showDiscountPrice = useAtomValue(showDiscountPriceAtom);

  const {
    offer: { discounts },
  } = useContainer(OrderingContext);

  const t = useT();
  const priceFormatter = usePriceFormatterFunction();

  if (!showDiscountPrice) return null;

  return (
    <div className={classNames("flex w-full mb-2 pt-2 md:pt-4 flex-col")}>
      <h2 className="heading-main w-full text-left mb-2">
        {t("Price Reductions")}
      </h2>

      {orderDiscounts.map((discount) => {
        const { discountName, unitDiscountedAmount, items } = discount;

        const targetOfferDiscount = discounts.find(
          (d) => d.id === discount.discountId
        );

        return (
          <>
            <h3 className="text-lg">
              {discountName}{" "}
              {t(`({amount}% off)`, {
                amount: targetOfferDiscount?.discount.amount,
              })}
            </h3>
            {items.map((item) => {
              const { name, amount, unitDiscountedPrice, vatRate } = item;

              return (
                <div className="flex justify-between items-center body-secondary mb-1">
                  <p className="text-text-secondary">
                    {amount}x {name}
                  </p>
                  <p>
                    -{" "}
                    {priceFormatter(
                      (unitDiscountedPrice + unitDiscountedPrice * vatRate) *
                        amount
                    )}
                  </p>
                </div>
              );
            })}
            <div className="flex justify-between items-center mb-1">
              <p className="text-text-secondary">{t("Total discount")}</p>
              <p>{priceFormatter(unitDiscountedAmount)}</p>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default DiscountBreakdown;
