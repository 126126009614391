import { getDefaultDateSeconds } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { useAtomValue } from "jotai";
import { findKey } from "lodash";
import moment from "moment";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { deadlineInfoAtom } from "./useDeadlineInfo";

interface NextAvailableDateArgs {
  product: CombinedOfferItem;
}

export const useNextAvailableDate = ({ product }: NextAvailableDateArgs) => {
  const { dateSeconds } = useContainer(OrderingContext);

  const {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    includesWeekend,
    timezone,
  } = useAtomValue(deadlineInfoAtom);

  const productDeadline =
    typeof product.individualDeadline === "number"
      ? product.individualDeadline
      : deadline;

  const productDefaultDateSeconds = getDefaultDateSeconds({
    deadline: productDeadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    includesWeekend,
    timezone,
  });

  // Check if the product is available for "nextAvailableDate"
  const isAvailableForNextDate =
    product?.dates?.[productDefaultDateSeconds]?.available;

  const nextDate = Number(
    findKey(
      product?.dates || {},
      (data, key) => data.available && +key > dateSeconds
    )
  );

  const nextAvailableDate =
    !isAvailableForNextDate && nextDate
      ? moment.utc(nextDate * 1000)
      : moment.utc(productDefaultDateSeconds * 1000);

  return nextAvailableDate;
};
