import { useT } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { Dictionary, get, groupBy, isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { MenuPreviewContext } from "./MenuPreviewContext";

export const useCategoriesDictionary = (
  propsDayIndex?: number,
  includeDefaultProducts = false
): Dictionary<CombinedOfferItem[]> => {
  const { items } = useContainer(MenuPreviewContext);

  const t = useT();

  const {
    dayIndex: contextDayIndex,
    schoolId,
    week,
    module,
  } = useContainer(AppContext);

  const dayIndex = propsDayIndex ?? contextDayIndex;

  const [categories, setCategories] = useState<
    Dictionary<Array<CombinedOfferItem>>
  >({});

  const category: string =
    module?.text?.["noCategory/text"] || t("Dish of the day");

  const filterAvailableItems = useCallback(
    (item: CombinedOfferItem) => {
      const dailyMenu = get(item, ["dates", week?.[dayIndex]?.seconds, "menu"]);
      const menuAvailable = !isEmpty(dailyMenu);

      const isDefault = includeDefaultProducts
        ? module?.productOptions?.[item?.productId]?.default
        : false;

      if (isDefault) return true;

      if (!schoolId) return false;

      const hasInfo = dailyMenu?.name !== "" || dailyMenu?.description !== "";

      const dayDisabled = get(item, ["days", dayIndex, "disabled"]);
      const schoolDayDisabled = get(item, [
        "schools",
        schoolId,
        "days",
        dayIndex,
        "disabled",
      ]);

      return (
        !dayDisabled &&
        !schoolDayDisabled &&
        menuAvailable &&
        (isDefault || hasInfo)
      );
    },
    [week, dayIndex, includeDefaultProducts, module?.productOptions, schoolId]
  );

  useEffect(() => {
    const availableMenus = (items as CombinedOfferItem[])
      .filter((i: CombinedOfferItem) => filterAvailableItems(i))
      .map((i) => ({ ...i, category: i?.category || category }));

    const groupedCategories = groupBy(
      availableMenus,
      (product) => product.category
    );
    setCategories(groupedCategories);
  }, [items, filterAvailableItems, category]);

  return categories;
};
