import { faCartShopping } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderingContext } from "@kanpla/ordering";
import { useT } from "@kanpla/system";
import { DrawerOrModal, ModuleLoadingWrapper } from "@kanpla/ui";
import { useAtom, useSetAtom } from "jotai";
import { isTablet } from "react-device-detect";
import { useContainer } from "unstated-next";
import CanteenClosed from "../shared/CanteenClosed";
import {
  openBasketAtom,
  shouldNotifyUserAfterRefillAtom,
} from "../shared/basket/useBasket";
import ModuleDescription from "../shared/info/ModuleDescription";
import NavbarSecondary from "../shared/navbar/NavbarSecondary";
import Products from "./Products";
import Receipt from "./Receipt";
import Basket from "./basket/index";
import { useCategories } from "./useCategories";

export interface SelectedCategoryData {
  index: number;
  trusted?: boolean;
}
const MealplanMealplan = () => {
  const { innerAppLoading, mobile } = useContainer(OrderingContext);
  const setOpenBasket = useSetAtom(openBasketAtom);
  const { activeHoliday, module, hasKanplaGo, week, dayIndex } =
    useContainer(OrderingContext);
  const [shouldNotifyUserAfterRefill, setShouldNotifyUserAfterRefill] = useAtom(
    shouldNotifyUserAfterRefillAtom
  );

  const t = useT();

  const categories = useCategories();

  const confirmButtonText = `${t("Go to")} ${t("Basket").toLowerCase()}`;

  const timeNavigation = mobile || isTablet ? "weekly" : "daily";

  return (
    <>
      <DrawerOrModal
        open={shouldNotifyUserAfterRefill}
        setOpen={setShouldNotifyUserAfterRefill}
        actions={[
          {
            type: "primary",
            onClick: () => {
              setShouldNotifyUserAfterRefill(false);
              setOpenBasket(true);
            },
            label: (
              <>
                <FontAwesomeIcon icon={faCartShopping} className="mr-2" />
                {confirmButtonText}
              </>
            ),
          },
        ]}
      >
        <div className="flex justify-center flex-col text-center">
          <FontAwesomeIcon icon={faCartShopping} className="text-6xl" />
          <h2 className="font-semibold text-xl mt-5">
            {t("You are almost there!")}
          </h2>
          <p className="mt-1 text-xs text-text-secondary">
            {t("The charge has been added to your credit!")}
          </p>
          <p className="font-semibold mt-10 text-sm">
            {t("Remember to confirm your order to complete your purchase")}
          </p>
        </div>
      </DrawerOrModal>

      <NavbarSecondary
        timeNavigation={hasKanplaGo ? "none" : timeNavigation}
        categories={categories}
        dayRowVisibleOnTablet={true}
      />

      {activeHoliday ? (
        <CanteenClosed
          holidayDesc={activeHoliday.design}
          disableJumpLink={hasKanplaGo}
        />
      ) : (
        <div className="wrapper mt-2 lg:mt-8">
          <Receipt />
          <div className="md:pt-1">
            <ModuleDescription align="left" module={module} />
          </div>
          <ModuleLoadingWrapper loading={innerAppLoading}>
            <Products key={week[dayIndex]?.seconds} />
          </ModuleLoadingWrapper>
          <Basket />
        </div>
      )}
    </>
  );
};

export default MealplanMealplan;
