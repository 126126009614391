import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

interface ButtonLineProps {
  onClick?: () => void;
  label: string;
  className?: string;
  icon?: IconProp | null;
  id?: string;
  disabled?: boolean;
  danger?: boolean;
  dataCy?: string;
  standalone?: boolean;
  inBetween?: boolean;
}

export const ButtonLine = ({
  onClick = () => null,
  label,
  className = "",
  icon = null,
  id,
  disabled = false,
  dataCy = "",
  danger,
  standalone = false,
  inBetween = false,
}: ButtonLineProps) => (
  <button
    disabled={disabled}
    id={id}
    className={classNames(
      "py-4 block w-full text-left transition duration-200 bg-background-primary",
      standalone
        ? "border-b border-divider-light rounded-t-md"
        : inBetween
        ? "border-b border-divider-light rounded-none"
        : "first-of-type:rounded-t-md last:rounded-b-md border-b border-divider-light last:border-none",
      disabled
        ? "disabled:cursor-not-allowed text-text-disabled"
        : danger
        ? "hover:bg-danger-light hover:bg-opacity-10 active:bg-opacity-20 text-danger-main"
        : "hover:bg-background-secondary hover:bg-opacity-80 active:bg-opacity-100 text-text-primary",
      className
    )}
    onClick={onClick}
    data-cy={dataCy}
  >
    {icon && (
      <FontAwesomeIcon
        icon={icon}
        className={classNames(
          "mr-4 ml-1 w-6",
          danger ? "text-danger-main" : "text-text-secondary"
        )}
      />
    )}
    {label}
  </button>
);
