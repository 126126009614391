import { OrderInfo, OrderLines } from "@kanpla/types";
import { useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { savingOrderAtom } from "../../flex";
import { useSubmitOrder } from "../submit/useSubmitOrder";

export const useFlexSubmitOrder = () => {
  const submitOrder = useSubmitOrder();

  const { schoolId } = useContainer(OrderingContext);
  const setSaving = useSetAtom(savingOrderAtom);

  const submit = async (
    newOrderLines: OrderLines,
    info: OrderInfo,
    dateSeconds: string,
    needToSetSaving = true
  ) => {
    if (!schoolId) return;

    try {
      if (needToSetSaving) setSaving(true);

      await submitOrder({
        orders: [
          {
            orderLines: newOrderLines,
            info,
            dateSeconds: Number(dateSeconds),
          },
        ],
      });
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      if (needToSetSaving) setSaving(false);
    }
  };

  return { submit };
};
