import { callInternalApi, useT } from "@kanpla/system";
import { message } from "@kanpla/ui";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { useFinalOrders } from "../data/useFinalOrders";
import { useLoadStandardOrders } from "../data/useLoadStandardOrders";

export const useSubmitStandardOrders = () => {
  const { moduleId, schoolId, childId, groupName } =
    useContainer(OrderingContext);

  const t = useT();

  const standardOrders = useFinalOrders();
  const { setData } = useLoadStandardOrders();

  const saveStandard = async () => {
    // 1. Submit the data
    await callInternalApi("submit/flexBulkStandard", {
      standardOrderLines: standardOrders,
      groupName,
      schoolId,
      moduleId,
      childId,
    });

    // 2. Optimistically update the data
    setData(standardOrders);

    // 3. Success message
    message.success(t("Standards updated."));
  };

  return saveStandard;
};
