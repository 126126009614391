import { T, getErrorMessage, useT } from "@kanpla/system";
import { Button, ButtonSave, message } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { isStandardOrderingAtom } from "../Navigation";
import { useHolidays } from "../holidays/useHolidays";
import { useIsDisabledForMinimumOrder } from "../minimumOrder/useIsDisabledForMinimumOrder";
import { useIsDisabledForSoftDeadline } from "../softDeadline/useIsDisabledForSoftDeadline";
import { useIsFlexBulkSaved } from "../useIsFlexBulkSaved";
import { useTitle } from "../useTitle";
import { useResetChanges } from "./useResetChanges";
import { useSubmitStandardOrders } from "./useSubmitStandardOrders";
import { useSubmitWeeklyOrders } from "./useSubmitWeeklyOrders";

export const SaveButton = () => {
  const t = useT();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const saved = useIsFlexBulkSaved();
  const { hasActiveModuleSwitchHoliday } = useHolidays();
  const submitWeeklyOrders = useSubmitWeeklyOrders();
  const submitStandardOrders = useSubmitStandardOrders();
  const isStandardOrdering = useAtomValue(isStandardOrderingAtom);
  const title = useTitle();
  const resetChanges = useResetChanges();

  const isDisabledForMinimumOrder = useIsDisabledForMinimumOrder();
  const isisDisabledForMinimumOrder = useIsDisabledForSoftDeadline();
  const disabled = isDisabledForMinimumOrder || isisDisabledForMinimumOrder;

  if (hasActiveModuleSwitchHoliday || saved) return null;

  const submitToServer = isStandardOrdering
    ? submitStandardOrders
    : submitWeeklyOrders;

  const onSubmit = async () => {
    try {
      setIsSaving(true);
      await submitToServer();
      resetChanges();
    } catch (err) {
      message.error(getErrorMessage(err) || t("Couldn't save this order"));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div
      className="fixed z-50 bg-background-primary pt-16 w-full left-0 border-b border-divider-main"
      style={{ top: "calc(var(--status-bar-height, 0px) - 4rem)" }}
    >
      <div className="w-full flex justify-between max-w-5xl mx-auto py-3 md:py-2 items-center px-4 md:px-8 lg:px-10">
        <div>
          <div className="body-secondary text-text-secondary">
            <T _str="Editing" />
          </div>
          <div className="heading-main">{title}</div>
        </div>
        <div className="flex items-center">
          <Button
            shape="soft"
            className="mr-2"
            onClick={() => {
              resetChanges();
            }}
          >
            <T _str="Cancel" />
          </Button>
          <ButtonSave
            saved={saved}
            onClick={onSubmit}
            disabled={disabled}
            loading={isSaving}
            dataCy="flex-bulk-save-button"
          />
        </div>
      </div>
    </div>
  );
};
