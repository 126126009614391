import { faPen } from "@fortawesome/pro-solid-svg-icons";
import {
  Timestamp,
  getErrorMessage,
  useFetch,
  useSubmit,
  useT,
} from "@kanpla/system";
import {
  Holiday,
  HolidayPeriod,
  Timestamp as TimestampType,
} from "@kanpla/types";
import { ButtonLine, message } from "@kanpla/ui";
import { GetHolidaysProps } from "apps/frontend/pages/api/internal/load/holidays";
import { SubmitHolidaysProps } from "apps/frontend/pages/api/internal/submit/holidays";
import { atom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import { DEFAULT_PERIODS } from "../lib";
import { HolidaysModal } from "../lib/flex/HolidaysModal";

interface FlexBulkHolidaysModalProps {
  fromUserSettings?: boolean;
}

export const holidayModuleIdAtom = atom<string | null>(null);

export const FlexBulkHolidaysModal = ({
  fromUserSettings = false,
}: FlexBulkHolidaysModalProps) => {
  const t = useT();

  const [open, setOpen] = useState(false);
  const [periods, setPeriods] = useState<HolidayPeriod[]>(DEFAULT_PERIODS);

  const [loading, setLoading] = useState<boolean>(false);

  const holidayModuleId = useAtomValue(holidayModuleIdAtom);

  const {
    moduleId,
    schoolId,
    userId,
    partnerId,
    offer: { holidayDates },
    childId,
    child,
  } = useContainer(OrderingContext);

  const {
    data,
    setData,
    loading: holidaysLoading,
  } = useFetch<GetHolidaysProps, Holiday[]>("load/holidays", {
    moduleId: holidayModuleId || moduleId,
    schoolId,
    partnerId: partnerId as string,
    groupName: child?.groupName,
  });

  const { submit } = useSubmit<SubmitHolidaysProps, HolidayPeriod[]>({
    path: "submit/holidays",
    requestConstructor: (periods: HolidayPeriod[]) => {
      const holidays = periods
        .filter((p) => Boolean(p.fromSeconds && p.toSeconds))
        .map(({ fromSeconds, toSeconds, ...restHoliday }) => {
          return {
            ...restHoliday,
            from: new Timestamp(fromSeconds || 0, 0),
            to: new Timestamp(toSeconds || 0, 0),
            design: {
              title: t("Holidays"),
              text: t("Your workplace has closed today due to the holiday."),
            },
          };
        });

      return {
        holidays,
        moduleId: holidayModuleId || moduleId,
        partnerId,
        schoolId,
        userId,
        childId,
      } as SubmitHolidaysProps;
    },
    responseDestructor: (newHolidays) => setData(newHolidays),
  });

  useEffect(() => {
    setPeriods(
      (data || [])?.map((holiday) => ({
        ...holiday,
        fromSeconds:
          holiday?.fromSeconds || (holiday?.from as TimestampType)?.seconds,
        toSeconds:
          holiday?.toSeconds || (holiday?.to as TimestampType)?.seconds,
      }))
    );
  }, [data]);

  const confirmHandler = async (periodsValue: HolidayPeriod[]) => {
    try {
      message.loading({
        messageId: "submit-holiday-loading",
        content: t("Saving holidays..."),
      });

      setLoading(true);
      await submit(periodsValue);
      setPeriods(periodsValue);

      message.success(t("Holidays saved"));

      setOpen(false);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      message.dismiss("submit-holiday-loading");
      setLoading(false);
    }
  };

  return (
    <>
      <ButtonLine
        onClick={() => setOpen(true)}
        label={t("Edit closing days")}
        icon={faPen}
      />
      <HolidaysModal
        open={open}
        setOpen={setOpen}
        onConfirm={confirmHandler}
        periods={periods}
        holidayDates={holidayDates}
        isAdmin
        fromUserSettings={fromUserSettings}
        holidaysLoading={holidaysLoading}
        savingLoading={loading}
      />
    </>
  );
};
