import { t } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { Allergens, DrawerOrModal, message } from "@kanpla/ui";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const ChooseAllergensModal = () => {
  const {
    child: contextChild,
    supplier,
    updateCurrentChild,
    hasSkippedAllergens,
    setHasSkippedAllergens,
  } = useContainer(AppContext);

  const child = (contextChild || {}) as Child;

  const [open, setOpen] = useState(false);
  const [allergens, setAllergens] = useState<Child["allergens"]>({});

  const { hasChosenAllergens, allergens: childAllergens } = child;

  const emptySelection =
    childAllergens !== undefined && isEmpty(childAllergens);
  const shouldHideAllergens = supplier?.screens?.shouldHideAllergens === true;

  useEffect(() => {
    if (
      hasSkippedAllergens ||
      isEmpty(child) ||
      emptySelection ||
      shouldHideAllergens
    )
      return;

    message.dismiss();
    setOpen(!hasChosenAllergens);
  }, [child, hasSkippedAllergens]);

  const submit = async () => {
    try {
      await updateCurrentChild({
        id: child.id,
        allergens,
        hasChosenAllergens: true,
      });
      message.success(t("Allergens information saved"));
    } catch (e) {
      console.error("Error saving allergens", e);
      message.error(t("Something went wrong"));
    } finally {
      setOpen(false);
      setAllergens({});
    }
  };

  const close = () => {
    setOpen(false);
    setHasSkippedAllergens(true);
  };

  if (hasChosenAllergens || emptySelection) return null;

  return (
    <DrawerOrModal
      title={t("Choose Allergens")}
      subtitle={t(
        "Tell us what you're allergic to, and we will do our best to help you avoid them"
      )}
      open={open}
      setOpen={setOpen}
      actions={[
        { label: t("Confirm allergens"), onClick: submit, type: "primary" },
        { label: t("Skip for now"), onClick: close, type: "secondary" },
      ]}
    >
      <Allergens
        supplier={supplier}
        child={child}
        value={allergens}
        onChange={setAllergens}
        fromSignup
      />
    </DrawerOrModal>
  );
};

export default ChooseAllergensModal;
