import { callInternalApi, useT } from "@kanpla/system";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { NativePermissionsContext } from "..";
import { AppContext } from "../../contextProvider";
import NotificationIllustration from "../illustrations/NotificationIllustration";
import ScreenWrapper from "./ScreenWrapper";

interface Props {
  setCurrentSlide: Dispatch<SetStateAction<number>>;
}

const NotificationsScreen = ({ setCurrentSlide }: Props): JSX.Element => {
  const t = useT();

  const { childId, schoolId, partnerId } = useContainer(AppContext);
  const {
    notificationPermissions,
    hasAllowedNotifications,
    requestNotification,
  } = useContainer(NativePermissionsContext);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!notificationPermissions) return;

    (async () => {
      try {
        await callInternalApi("popups-registerNotificationEvent", {
          childId,
          schoolId,
          partnerId,
          hasAllowedNotifications,
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();

    setCurrentSlide((prevState) => prevState + 1);
  }, [notificationPermissions]);

  return (
    <ScreenWrapper
      title={t("Allow push notifications")}
      subtitle={t(
        "Allow notifications from your canteen and be the first to receive new offers"
      )}
      buttonLabel={t("Continue")}
      illustration={<NotificationIllustration />}
      onContinue={() => requestNotification()}
      buttonLoading={loading}
    />
  );
};

export default NotificationsScreen;
