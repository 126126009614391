import { atom, useAtomValue } from "jotai";
import { translationsAvailableAtom } from "../contextProvider";
import { useGetTranslatorUsage } from "./useGetTranslatorUsage";

export const showMenuTranslatedAtom = atom<boolean>(false);

export const useTranslations = () => {
  const translationsAvailable = useAtomValue(translationsAvailableAtom);

  const {
    data: { isAvailable: usageAvailable },
    isLoading,
  } = useGetTranslatorUsage();

  if (isLoading) return { active: false };
  return { active: translationsAvailable && usageAvailable };
};
