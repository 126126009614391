import {
  SubmitMultipleOrdersProps,
  SubmitMultipleOrdersReturn,
} from "@kanpla/services";
import { callInternalApi, useT } from "@kanpla/system";
import { message } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { IncomingOrder } from "../../../../../libs/services/src/lib/ordering/sendMultipleOrders";
import { OrderingContext } from "../../context";
import { receiptOrderAtom } from "../../orders/order/Receipt";

export type SubmitOrderPartial = Pick<
  IncomingOrder,
  "orderLines" | "info" | "dateSeconds" | "id"
>;

interface EnhancedSubmitProps {
  orders: SubmitOrderPartial[];
  noReceipt?: boolean;
}

export const useSubmitOrder = () => {
  const {
    childId,
    userId,
    schoolId,
    moduleId,
    module,
    isBulk,
    child,
    childOrders,
    setLoadedOrders,
    disableRefund,
  } = useContainer(OrderingContext);

  const t = useT();

  const setReceipt = useSetAtom(receiptOrderAtom);

  const submit = async ({
    orders: rawOrders,
    noReceipt = false,
  }: EnhancedSubmitProps) => {
    const personalProps = isBulk ? {} : { childId, userId };

    const orders = rawOrders.map((order: SubmitOrderPartial) => {
      const fullOrder: IncomingOrder = {
        ...order,
        schoolId,
        groupName: child?.groupName || "",
        moduleId,
        isAdmin: !!isBulk,
        ...personalProps,
      };
      return fullOrder;
    });
    const { orders: newOrders = [] } = await callInternalApi<
      SubmitMultipleOrdersProps,
      SubmitMultipleOrdersReturn
    >("ordering/submitMultipleOrders", {
      orders,
      disableRefund,
    });

    message.success(t("New orders saved"), {
      messageId: "success-new-orders-saved",
    });

    const otherOrders = childOrders.filter(
      (oldOrder) => !newOrders.some((newOrder) => newOrder.id === oldOrder.id)
    );
    setLoadedOrders({
      orders: [...otherOrders, ...newOrders],
    });
    if (!noReceipt && module.flow === "meeting") {
      const firstOrder = newOrders?.[0] || null;
      setReceipt({ order: firstOrder, from: "meeting" });
    }

    return newOrders;
  };

  return submit;
};
