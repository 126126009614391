import { OrderingContext } from "@kanpla/ordering";
import { hasAccessToModule, useT } from "@kanpla/system";
import { usePriceFormatter } from "@kanpla/ui";
import classnames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import { useContainer } from "unstated-next";
import {
  basketContainerTotalItemsAtom,
  basketContainerTotalPriceAtom,
  handleBasketOpeningAtom,
} from "../../shared/basket/useBasket";

const BasketPreview = () => {
  const handleBasketOpening = useSetAtom(handleBasketOpeningAtom);
  const { modules, child, school, mobile } = useContainer(OrderingContext);
  const totalProducts = useAtomValue(basketContainerTotalItemsAtom);
  const basketContainerTotalPrice = useAtomValue(basketContainerTotalPriceAtom);

  const t = useT();

  const tabs = useMemo(
    () =>
      modules?.reduce((acc, m) => {
        const hide = m?.displayOptions?.hideMobileTab;

        if (hide) return acc;

        if (m.type === "flex") {
          const hasAccess = hasAccessToModule({ module: m, school, child });

          if (hasAccess.individual) acc.push(m.name);
          if (hasAccess.bulk) acc.push(m.name);
        } else {
          acc.push(m.name);
        }

        return acc;
      }, []),
    [modules?.length, child?.id]
  );

  const totalPriceFormatted = usePriceFormatter(basketContainerTotalPrice);

  if (totalProducts === 0) return null;
  if (!mobile) return null;

  return (
    <div
      className={classnames({
        "inset-x-2 mx-auto max-w-sm cursor-pointer fixed px-4 py-3 z-20 flex justify-between items-center rounded-md shadow-md bg-primary-main text-primary-contrast hover:opacity-80 transition-opacity ease-in-out":
          true,
        "bottom-24": tabs.length > 1,
      })}
      style={{
        bottom: tabs.length <= 1 && "calc(1rem + env(safe-area-inset-bottom))",
      }}
      onClick={() => handleBasketOpening(true)}
    >
      <span>
        {totalProducts === 1
          ? t("{totalProducts} product", { totalProducts })
          : t("{totalProducts} products", { totalProducts })}
      </span>
      <span className="font-semibold">{totalPriceFormatted}</span>
    </div>
  );
};

export default BasketPreview;
