import classNames from "classnames";

interface HeaderProps {
  noStyles: boolean;
  disabled: boolean;
  children: React.ReactNode;
}

const Header = ({ children, noStyles, disabled }: HeaderProps) => (
  <div
    className={
      noStyles
        ? ""
        : classNames(
            "grid grid-cols-3 gap-6",
            disabled && "opacity-30 pointer-events-none"
          )
    }
  >
    {children}
  </div>
);

export default Header;
