import { SubscriptionProduct } from "@kanpla/types";

/**
 * A function to calculate total price of a subscription product per period
 * @param product Product from subscription period settings
 * @param numberOfDays How many days are we delivering for?
 * @returns A total price in currency
 */
export const calculateSubscriptionPrice = (
  product: SubscriptionProduct,
  numberOfDays: number,
  onlyOne?: "price" | "subsidies"
) => {
  const {
    overwriteTotalPrice = 0,
    price = 0,
    subsidies = 0,
    overwriteTotalSubsidies = 0,
    priceType = "daily",
  } = product || {};

  const hasPeriodBasedPrice = priceType === "period";

  /** Calculate price per period */
  if (hasPeriodBasedPrice) {
    if (onlyOne === "price") return overwriteTotalPrice;
    if (onlyOne === "subsidies") return overwriteTotalSubsidies;

    const periodBasedPrice =
      overwriteTotalPrice - (overwriteTotalSubsidies || 0);
    return periodBasedPrice;
  }

  /** Calculate price based on delivery days */

  if (onlyOne === "price") return price * numberOfDays;
  if (onlyOne === "subsidies") return subsidies * numberOfDays;

  const dayBasedPrice = (price - subsidies) * numberOfDays;
  return dayBasedPrice;
};
