import { FlexBulkStandard } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";

export type WeekOrders = FlexBulkStandard["standardOrderLines"];

export const useSoftDeadlineMaxAmount = () => {
  const { school } = useContainer(OrderingContext);

  const softDeadlineMaxAmount =
    (school?.contract?.softDeadlineMaxAmount as number) || 0;

  return softDeadlineMaxAmount;
};
