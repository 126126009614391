import { T } from "@kanpla/system";
import { useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { deadlineInfoAtom } from "../lib/hooks/useDeadlineInfo";
import JumpLink from "./JumpLink";

interface Props {
  holidayDesc?: {
    title: string;
    text: string;
    image?: string;
  };
  /** Disables jumping link, e.g. in case of Kanpla Go */
  disableJumpLink?: boolean;
}

const CanteenClosed = ({ holidayDesc, disableJumpLink }: Props) => {
  const { defaultDateSeconds } = useAtomValue(deadlineInfoAtom);

  return (
    <section className="max-w-screen-xl mx-auto pt-20 md:pt-32 lg:pt-48 pb-12 text-center wrapper">
      {isEmpty(holidayDesc) && (
        <h2 className="title-secondary font-semibold text-xl md:text-2xl lg:text-3xl mb-2 text-text-secondary">
          <T _str={"The canteen is closed this day"} />
        </h2>
      )}
      {holidayDesc && !isEmpty(holidayDesc) && holidayDesc?.title && (
        <>
          <h2 className="title-secondary font-semibold text-xl md:text-2xl lg:text-3xl mb-2 text-text-secondary">
            {holidayDesc?.title || (
              <T _str={"The canteen is closed this day"} />
            )}
          </h2>
          {holidayDesc?.text && (
            <p className="text-text-secondary max-w-prose mb-4 mx-auto">
              {holidayDesc?.text}
            </p>
          )}
        </>
      )}

      {!disableJumpLink &&
        (defaultDateSeconds ? (
          <>
            <T _str={"If you're hungry, you can order for "} />
            <JumpLink />!
          </>
        ) : (
          <span>
            <T _str={"There are no future mealplans yet."} />
          </span>
        ))}
    </section>
  );
};

export default CanteenClosed;
