import { getAvailableAccessCodes, getErrorMessage, t } from "@kanpla/system";
import { Module, School } from "@kanpla/types";
import { DrawerOrModal, Select, message } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { getChildCodes } from "libs/system/src/modules/hasAccessToModule";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { childIdToEditAtom } from ".";
import { OrderingContext } from "../../context";
import { useChildren } from "./useChildren";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const AccessCodesModal = ({ open, setOpen }: Props) => {
  const childId = useAtomValue(childIdToEditAtom);
  const { modules, school } = useContainer(OrderingContext);
  const { children, submitChild } = useChildren();

  const child = children?.find((c) => c.id === childId);
  const childCodes = child
    ? getChildCodes(child, school as unknown as School)
    : [];
  const [selectedCodes, setSelectedCodes] = useState<string[]>(childCodes);
  const availableCodes = getAvailableAccessCodes(
    modules as Module[],
    school as unknown as School
  );

  const [loading, setLoading] = useState(false);

  if (!child) return null;

  const saveCodes = async () => {
    setLoading(true);

    try {
      await submitChild({
        ...child,
        codes: selectedCodes,
      });

      message.success(t("Codes changed"));
      setLoading(false);
      setOpen(false);
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      message.error(errorMessage || t("Failed to save passwords"));
      setLoading(false);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("Edit access codes for {name}", { name: child.name })}
      stopPropagation={false}
      actions={[
        {
          label: t("Save"),
          onClick: () => saveCodes(),
          loading: loading,
        },
      ]}
    >
      <Select
        defaultValue={childCodes.map((c) => ({ label: c, value: c }))}
        options={availableCodes.map((c) => ({ label: c, value: c }))}
        isMulti
        onChange={(newValue: string[]) => setSelectedCodes(newValue)}
      />
    </DrawerOrModal>
  );
};
