import { OrderingContext } from "@kanpla/ordering";
import { ReactNode } from "react";
import { useContainer } from "unstated-next";
import PastDateWrapper from "../shared/PastDateWrapper";
import SubscriptionBanner from "../shared/legacy/SubscriptionBanner";
import NoMealplanWrapper from "./NoMealplanWrapper";
import Category from "./ProductCategory";
import ProductSuggestion from "./ProductSuggestion";
import { useCategories } from "./useCategories";

const Products = () => {
  // Util to change the localization of moment.js

  const { child, school } = useContainer(OrderingContext);
  const { module } = useContainer(OrderingContext);

  const categories = useCategories();

  // Temp (milk banner on top for Steffen's schools)
  const schoolId = child?.schoolId;
  const bannerOnTop =
    schoolId === "o9Yp7yGoJijZESdsIBhO" || schoolId === "0YPMr00awHyXZdobICI3";

  return (
    <PastDateWrapper>
      <NoMealplanWrapper>
        <>
          {school && (
            <>
              {Object.entries(categories).map(([name, items], index) => (
                <Category
                  noMarginTop={index === 0}
                  key={name}
                  name={name}
                  items={items}
                  showChildrenAnyway
                  isHidden={items.length === 0}
                  index={index}
                />
              ))}
            </>
          )}

          {/* Loading */}
          {!school && (
            <div>
              <div className="h-6 w-32 mt-3 lg:mt-0 loader rounded mb-2" />
              <LoaderCategory>
                <div className="w-full h-48 md:h-56 loader rounded-lg" />
              </LoaderCategory>
              <LoaderCategory>
                <div className="w-full h-24 loader rounded-lg" />
                <div className="w-full h-24 loader rounded-lg" />
                <div className="w-full h-24 loader rounded-lg" />
              </LoaderCategory>
              <LoaderCategory>
                <div className="w-full h-24 loader rounded-lg" />
                <div className="w-full h-24 loader rounded-lg" />
                <div className="w-full h-24 loader rounded-lg" />
              </LoaderCategory>
            </div>
          )}

          {!bannerOnTop && <SubscriptionBanner noBorder={false} />}
          {module.plugins?.userSuggestions?.active && <ProductSuggestion />}
        </>
      </NoMealplanWrapper>
    </PastDateWrapper>
  );
};

export default Products;

const LoaderCategory = ({ children }: { children: ReactNode }) => (
  <div className="pb-6 pt-1">
    <div className="h-6 w-32 mt-3 lg:mt-0 loader rounded mb-3" />
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
      {children}
    </div>
  </div>
);
