import { useSetAtom } from "jotai";
import { orderChangesAtom } from "../data/changesAtom";
import { globalVariantChoicesAtom } from "../ordering/globalVariant/globalVariantChoicesAtom";

export const useResetChanges = () => {
  const setOrderChanges = useSetAtom(orderChangesAtom);
  const setGlobalVariantChoices = useSetAtom(globalVariantChoicesAtom);

  const resetChanges = () => {
    setOrderChanges([]);
    setGlobalVariantChoices({});
  };
  return resetChanges;
};
