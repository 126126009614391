import { useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import { useAtom, useAtomValue } from "jotai";
import { FC } from "react";
import { useAllowance } from "../../shared/allowance/useAllowance";
import {
  basketAtom,
  basketEditModeAtom,
  isOrderEditingAtom,
} from "../../shared/basket/useBasket";

export const BasketEditButton: FC = () => {
  const t = useT();
  const [basketEditMode, setBasketEditMode] = useAtom(basketEditModeAtom);
  const [isOrderEditing, setIsOrderEditing] = useAtom(isOrderEditingAtom);
  const basket = useAtomValue(basketAtom);

  const { updateFromBasket } = useAllowance();

  return (
    <Button
      size="small"
      onClick={() => {
        setBasketEditMode((prevState) => {
          if (prevState) {
            updateFromBasket(basket);
          }

          return !prevState;
        });

        // Reset the state of the order editing
        if (isOrderEditing) {
          setIsOrderEditing(false);
        }
      }}
    >
      {basketEditMode ? t("Done") : t("Edit")}
    </Button>
  );
};
