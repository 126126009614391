import {
  faMoneyBillWave,
  faReceipt,
  faRotateRight,
} from "@fortawesome/pro-regular-svg-icons";
import { useT } from "@kanpla/system";
import { ButtonLine, DrawerOrModal } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { Dispatch, SetStateAction, useState } from "react";
import { useContainer } from "unstated-next";
import { accountHistoryOpenAtom } from "../../Wrapper";
import { AppContext } from "../../contextProvider";
import ExportTransactions from "./ExportTransactions";
import HistoryModal from "./HistoryModal";

interface HistoryProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const History = ({ open, setOpen }: HistoryProps) => {
  const { modules } = useContainer(AppContext);
  const setHistoryOpen = useSetAtom(accountHistoryOpenAtom);

  const [subscriptionsOpen, setSubscriptionsOpen] = useState(false);
  const [paymentsOpen, setPaymentsOpen] = useState(false);

  const t = useT();

  const hasSubscription = (modules || []).some(
    (m) => m.type === "subscription"
  );

  return (
    <>
      <DrawerOrModal setOpen={setOpen} open={open} title={t("Account History")}>
        <ButtonLine
          label={t("Orders")}
          onClick={() => setHistoryOpen(true)}
          dataCy="history-orders"
          icon={faReceipt}
        />
        <ButtonLine
          label={t("Payments")}
          onClick={() => setPaymentsOpen(true)}
          dataCy="history-payments"
          icon={faMoneyBillWave}
        />
        {hasSubscription && (
          <ButtonLine
            label={t("Subscriptions")}
            onClick={() => setSubscriptionsOpen(true)}
            dataCy="history-subscription"
            icon={faRotateRight}
          />
        )}
        <ExportTransactions />
      </DrawerOrModal>

      {/* Modals */}
      <HistoryModal
        open={paymentsOpen}
        setOpen={setPaymentsOpen}
        title={t("Payment history")}
        apiUrl="load/paymentHistory"
      />
      <HistoryModal
        open={subscriptionsOpen}
        setOpen={setSubscriptionsOpen}
        title={t("Subscription history")}
        apiUrl="load/subscriptionHistory"
      />
    </>
  );
};

export default History;
