import { T, deadlineDisplay } from "@kanpla/system";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import moment from "moment";
import { useEffect, useState } from "react";
import { receiptTimeAtom } from "../useKanplaGoListener";
import { SecurityAnimation } from "./SecurityAnimation";

interface Props {
  onBack: () => void;
  childName: string;
  schoolName: string;
  disableCountdown?: boolean;
}

const Header = (props: Props) => {
  const { childName, schoolName, disableCountdown = false } = props;
  const receiptTime = useAtomValue(receiptTimeAtom);

  const [elapsedTime, setElapsedTime] = useState(moment.duration(0));

  useEffect(() => {
    if (disableCountdown) return;
    const startTime = moment.unix(receiptTime);

    const intervalId = setInterval(() => {
      const now = moment();
      const duration = moment.duration(now.diff(startTime));
      setElapsedTime(duration);
    }, 100);

    return () => clearInterval(intervalId);
  }, [receiptTime]);

  /** The function won't be used if the the countdown is disabled */
  const displayTime = () => {
    const hours = Math.floor(elapsedTime.asHours());
    const minutes = elapsedTime.minutes().toString().padStart(2, "0");
    const seconds = elapsedTime.seconds().toString().padStart(2, "0");
    const milliseconds = elapsedTime
      .milliseconds()
      .toString()
      .padStart(3, "0")
      .slice(0, 2);

    const hoursString = hours > 0 ? `${hours}:` : "";

    return `${hoursString}${minutes}:${seconds}:${milliseconds}`;
  };

  const backgroundColor =
    elapsedTime.asMinutes() < 2
      ? `bg-success-main`
      : elapsedTime.asMinutes() > 5
      ? `bg-danger-main`
      : `bg-warning-main`;

  const receiptTimeContent = () => {
    if (!disableCountdown) return null;

    const timeMoment = moment.unix(receiptTime);
    const date = timeMoment.format("L");
    const time = timeMoment.format("LT");

    return (
      <h2 className="flex flex-col items-center justify-center">
        <span className="text-4xl font-semibold">{time}</span>
        <span className="text-base">{date}</span>
      </h2>
    );
  };

  return (
    <div
      className={classNames(
        "flex justify-center items-center z-20 p-10 ease-in-out -mt-10 md:-mt-0 relative transition-all",
        disableCountdown ? "bg-success-main" : backgroundColor
      )}
    >
      <SecurityAnimation />
      <div className="flex flex-col items-center text-primary-contrast pt-4">
        {disableCountdown ? (
          <div>
            <p className="text-base mb-2 opacity-75">
              <T _str="Order confirmed" />
            </p>
            {receiptTimeContent()}
          </div>
        ) : (
          <>
            <h1 className="text-5xl font-semibold px-4 py-1 flex flex-col items-center justify-center">
              {displayTime()}
            </h1>
            <p className="-mt-1 text-xl">
              {childName.trim()}, {schoolName}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
