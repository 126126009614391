import { useT } from "@kanpla/system";
import { Child, _FrontendSupplier } from "@kanpla/types";
import { Allergens, DrawerOrModal } from "@kanpla/ui";
import { Dispatch, SetStateAction } from "react";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  child: Child;
  supplier: _FrontendSupplier | null;
}

const EditAllergens = ({ open, setOpen, child, supplier }: Props) => {
  const t = useT();

  if (!child) return null;

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("Allergens")}
      subtitle={t(
        "The allergens are only descriptive, and the canteen may choose to not include them"
      )}
    >
      <Allergens
        onSave={() => null}
        child={child}
        supplier={supplier}
        withBorder={false}
      />
    </DrawerOrModal>
  );
};

export default EditAllergens;
