import { WithId } from "@kanpla/types";
import { useMemo } from "react";

/// FUNCTION OVERLOADING [newMap]

/** Returns a map where keys are the ids. */
export function newMap<T extends WithId>(entries: T[]): Map<string, T>;
/** Returns a map where keys are specified. */
export function newMap<
  T extends WithId = WithId,
  K extends keyof T = keyof T,
  R extends T[K] = T[K]
>(entries: T[], key: K): Map<R, T>;

/**
 * Returns new map of specified properties key-values of given entries.
 *
 * Example: ```
    type T1 = WithId<{ name: string; num: number }>;

    const entriesList: T1[] = [
      { id: "1", name: "name1", num: 1 },
      { id: "2", name: "name2", num: 2 },
      { id: "3", name: "name3", num: 3 },
      { id: "4", name: "name4", num: 4 },
    ];

    const m0 = newMap(entriesList, "num");  // Map<T1["num"], T1> <->  Map<number, T1>
    const m1 = newMap(entriesList, "id");   // Map<T1["id"], T1> <->  Map<string, T1>
    const m2 = newMap(entriesList);         // Map<T1["id"], T1> <->  Map<string, T1>

    const um0 = useMap(entriesList, "num"); // Map<T1["num"], T1> <->  Map<number, T1>
    const um1 = useMap(entriesList, "id");  // Map<T1["id"], T1> <->  Map<string, T1>
    const um2 = useMap(entriesList);        // Map<T1["id"], T1> <->  Map<string, T1>
 ```
 **/
export function newMap<
  T extends WithId = WithId,
  K extends keyof T = keyof T,
  R extends T[K] = T[K]
>(entries: T[], key: K = "id" as K): Map<R, T> {
  return new Map(entries?.map((e) => [e?.[key], e]));
}

/// FUNCTION OVERLOADING [useMap]

/** Returns a map where keys are the ids. */
export function useMap<T extends WithId>(entries: T[]): Map<string, T>;
/** Returns a map where keys are specified. */
export function useMap<
  T extends WithId = WithId,
  K extends keyof T = keyof T,
  R extends T[K] = T[K]
>(entries: T[], key: K): Map<R, T>;

/** Returns memorized newMap @see newMap */
export function useMap<
  T extends WithId = WithId,
  K extends keyof T = keyof T,
  R extends T[K] = T[K]
>(entries: T[], key: K = "id" as K): Map<R, T> {
  return useMemo(() => newMap(entries, key), [entries, key]);
}
