import { faEmptySet, faLoader } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronRight, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getMidnightSeconds, useFetch, useT } from "@kanpla/system";
import { Button, Calendar, DrawerOrModal } from "@kanpla/ui";
import classNames from "classnames";
import { getDay } from "date-fns";
import { useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { uuid } from "short-uuid";
import { useContainer } from "unstated-next";
import { meetingCurrentViewAtom, meetingEditingOrderAtom } from "..";
import {
  LoadNextThreeOrdersProps,
  LoadNextThreeOrdersReturn,
} from "../../../../../../apps/frontend/pages/api/internal/load/nextThreeOrders";
import { OrderingContext } from "../../../context";
import { deadlineInfoAtom } from "../../../lib/hooks/useDeadlineInfo";
import { OrdersModal } from "../../../orders/OrdersModal";
import OrderCard from "../../../orders/order/OrderCard";
import ModuleDescription from "../../../shared/info/ModuleDescription";

const Overview = () => {
  const t = useT();

  const { isDateSecondsPastDeadline, defaultDateSeconds } =
    useAtomValue(deadlineInfoAtom);
  const setCurrentView = useSetAtom(meetingCurrentViewAtom);
  const setEditingOrder = useSetAtom(meetingEditingOrderAtom);

  const {
    childId,
    dateSeconds,
    moduleId,
    module,
    setDate,
    holidayDates,
    fromAdmin,
  } = useContainer(OrderingContext);

  const [newOrderOpen, setNewOrderOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);

  const isDisabledDate = (date: Date) => {
    const updatedDate = moment(date);
    updatedDate.set("hour", selectedDate.get("hour"));

    const unixSeconds = moment.utc(updatedDate).unix();
    const isPastDeadline = isDateSecondsPastDeadline(unixSeconds);
    if (isPastDeadline && !fromAdmin) return true;

    const isWeekend = getDay(date) === 0 || getDay(date) === 6;
    if (isWeekend && !module?.plugins?.sevenDayWeek?.active) return true;

    const isHoliday = holidayDates?.[unixSeconds];
    if (isHoliday) return true;

    return false;
  };

  const [currentDate, setCurrentDate] = useState<Date | undefined>();

  const selectedDate = moment.unix(dateSeconds);

  useEffect(() => {
    if (!defaultDateSeconds) {
      setCurrentDate(moment.unix(getMidnightSeconds(moment().unix())).toDate());
      return;
    }

    setCurrentDate(moment.unix(defaultDateSeconds).toDate());
  }, [defaultDateSeconds]);

  useEffect(() => {
    if (!currentDate) return;

    const currentMoment = moment(currentDate);
    currentMoment.set("hour", selectedDate.get("hour"));

    const newDateSeconds = getMidnightSeconds(currentMoment.unix());
    setDate({ dateSeconds: newDateSeconds });
  }, [currentDate]);

  const [_reloader, setReloader] = useState("");
  const reload = () => setReloader(uuid());

  const { data, loading } = useFetch<
    LoadNextThreeOrdersProps,
    LoadNextThreeOrdersReturn
  >(
    "load/nextThreeOrders",
    { childId, moduleId, _reloader },
    { refreshInterval: 100000, revalidateOnFocus: false }
  );
  const nextThreeOrders = data?.orders || [];
  const nextThreeOrdersLoading =
    loading && (!nextThreeOrders || isEmpty(nextThreeOrders));

  useEffect(() => {
    reload();
  }, []);

  return (
    <>
      <div className="w-full h-full flex justify-center items-center flex-col pt-10 p-4 md:p-14 md:pt-14">
        <div className="flex flex-col justify-center">
          <ModuleDescription align="center" module={module} />

          <div className="mt-2 md:mt-4 mx-auto">
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setNewOrderOpen(true);
              }}
              shape="solid"
              dataCy="new-order-btn"
              icon={faPlus}
            >
              {t("New order")}
            </Button>
            <DrawerOrModal
              title={t("New order")}
              subtitle={t("What day should you book for?")}
              open={newOrderOpen}
              setOpen={setNewOrderOpen}
              actions={[
                {
                  label: t("Continue"),
                  onClick: () => {
                    setDate({ momentDate: selectedDate });
                    setCurrentView("ordering");
                    setEditingOrder(null);
                  },
                  type: "primary",
                  dataCy: "action-new-order",
                },
              ]}
            >
              <div className="pt-3 -mx-2 flex justify-center">
                <Calendar
                  selected={currentDate}
                  onSelect={setCurrentDate}
                  className="rounded-md border border-secondary-light"
                  disabled={(matcher) => isDisabledDate(matcher)}
                />
              </div>
            </DrawerOrModal>
          </div>
        </div>
        <div className="w-full mt-20 md:mt-32 max-w-6xl mx-auto">
          <h1 className="title-secondary text-text-secondary">
            {t("My orders")}
          </h1>
          <div className="flex flex-col w-full">
            <div className="mt-2 flex flex-col md:flex-row w-full">
              {nextThreeOrdersLoading && (
                <div className="w-full py-16 text-text-secondary text-lg text-center shadow-inner flex flex-col items-center rounded-lg bg-background-secondary">
                  <FontAwesomeIcon
                    icon={faLoader}
                    size="4x"
                    className="fa-spin"
                  />
                  <p className="m-6 font-semibold">{t("Loading orders")}</p>
                </div>
              )}
              {!nextThreeOrdersLoading && !!nextThreeOrders?.length && (
                <div className="flex flex-col w-full">
                  {nextThreeOrders.map((orderDocument, index) => (
                    <OrderCard
                      key={index}
                      order={orderDocument}
                      reloadOrders={reload}
                      meetingView
                    />
                  ))}
                </div>
              )}
              {!nextThreeOrdersLoading && nextThreeOrders.length === 0 && (
                <div
                  className={classNames(
                    "flex flex-col items-center rounded-lg bg-background-secondary",
                    "w-full py-16 text-text-secondary text-lg text-center shadow-inner"
                  )}
                >
                  <FontAwesomeIcon icon={faEmptySet} size="4x" />
                  <p className="m-6 font-semibold">
                    {t("There are no orders yet")}
                  </p>
                </div>
              )}
            </div>
            <div className="w-full mt-10 flex justify-center">
              <Button
                onClick={() => setOpenHistory(true)}
                icon={faChevronRight}
                shape="solid"
                className="w-fit"
                dataCy="btn-meeting-overview-see-all"
              >
                {t("See all")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <OrdersModal
        open={openHistory}
        setOpen={setOpenHistory}
        limitedModuleIds={[moduleId]}
        meetingView
      />
    </>
  );
};

export default Overview;
