import { faCoin } from "@fortawesome/pro-duotone-svg-icons";
import { useT } from "@kanpla/system";
import { Button, message } from "@kanpla/ui";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import { openPaymentModalAtom } from "../../../../libs/ordering/src/mealplan2/kanplaGo/PaidFromCredit";
import PaymentModal from "../../../../libs/ordering/src/shared/payment/payment";
import { AppContext } from "../contextProvider";

const NegativeBalancePopup = () => {
  const [open, setOpen] = useAtom(openPaymentModalAtom);

  const t = useT();

  const { balance } = useContainer(AppContext);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const messageId = "negativeBalance";

      if (!balance || balance >= 0) {
        message.dismiss(messageId);
        return;
      }

      message.error(
        <div className="flex flex-col">
          <p>
            {t(
              "You have a negative balance, please contact Kanpla or top up your credit."
            )}
          </p>
          <Button
            onClick={() => setOpen(true)}
            type="danger"
            shape="soft"
            size="small"
            className="mt-2 ml-auto w-full border-danger-main"
            icon={faCoin}
            dataCy="btn-negative-balance-refuel"
          >
            {t("Recharge here")}
          </Button>
        </div>,
        {
          messageId,
          autoClose: false,
        }
      );
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [balance]);

  return <PaymentModal open={open} setOpen={setOpen} />;
};

export default NegativeBalancePopup;
