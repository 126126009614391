import { FlexOption } from "@kanpla/types";
import { useGlobalVariants } from "../globalVariant/useGlobalVariants";

export const useIsSpecialOption = () => {
  const { isActive, globalVariantId } = useGlobalVariants();

  const isSpecialOption = (option: FlexOption) => {
    const isGlobalVariant = isActive && option.id === globalVariantId;
    const isHiddenOption = option.extension?.includes("hidden");
    return isGlobalVariant || isHiddenOption;
  };

  return isSpecialOption;
};
