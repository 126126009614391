import React, { ReactNode } from "react";

interface Props {
  title: string;
  content: string;
  icon: ReactNode;
}

const Cards: React.FC<Props> = (props): JSX.Element => {
  const { title, content, icon } = props;

  return (
    <div className="relative">
      <div className="absolute w-64 h-14 rounded-md bg-background-primary border border-secondary-dark flex items-center p-3 z-30 shadow-md">
        <div className="w-auto h-full flex items-center">
          <div className="w-8 h-8">{icon}</div>
        </div>
        <div className="w-full ml-3 flex flex-col justify-center">
          <h1 className="text-xs text-text-secondary">{title}</h1>
          <p>{content}</p>
        </div>
      </div>
      <div className="absolute left-2 w-60 h-14 z-20 mt-2 bg-background-primary shadow-md rounded-md border border-secondary-dark" />
      <div className="absolute left-6 w-52 h-14 z-10 mt-4 bg-background-primary shadow-md rounded-md border border-secondary-dark" />
    </div>
  );
};

export default Cards;
