import { ReactNode, useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";

type VariantsWrapperProps = {
  expanded: boolean;
  children: ReactNode;
};

const VariantsWrapper = ({ children, expanded }: VariantsWrapperProps) => {
  const [animationProps, setAnimationProps] = useSpring(
    () => ({ height: "0px" }),
    []
  );

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current)
      setAnimationProps({
        height: `${expanded ? ref.current.offsetHeight : 0}px`,
      });
  }, [expanded, ref?.current?.offsetHeight]);

  return (
    <animated.div style={animationProps}>
      <div ref={ref}>{children}</div>
    </animated.div>
  );
};

export default VariantsWrapper;
