import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import {
  T,
  Timestamp,
  addWeek,
  dayLabels,
  deadlineDisplay,
  getWeekArray,
  subtractWeek,
  t,
  timestampToMoment,
} from "@kanpla/system";
import { DayIndex } from "@kanpla/types";
import {
  Button,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
  WeekDayButtons,
} from "@kanpla/ui";
import classNames from "classnames";
import { atom, useAtom, useAtomValue } from "jotai";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { selectedDayIndexAtom } from ".";
import { OrderingContext } from "../context";
import { dateInfoAtom } from "../lib/hooks/useDateInfo";
import { deadlineInfoAtom } from "../lib/hooks/useDeadlineInfo";
import { useIsFlexBulkSaved } from "./useIsFlexBulkSaved";
import { useTitle } from "./useTitle";

export const isStandardOrderingAtom = atom(false);

export const flexBulkFocusAtom = atom((get) => {
  const isStandardOrdering = get(isStandardOrderingAtom);
  const { weekSeconds } = get(dateInfoAtom);

  if (isStandardOrdering) return "standard";
  return `weekly-${weekSeconds}`;
});

export const Navigation = () => {
  const { week, mobile, dateSeconds, setDate } = useContainer(OrderingContext);
  const { deadlineFormatted } = useAtomValue(dateInfoAtom);
  const { deadline, deadlineExcludingWeekends, deadlineWeekRelative } =
    useAtomValue(deadlineInfoAtom);
  const [selectedDayIndex, setSelectedDayIndex] = useAtom(selectedDayIndexAtom);

  const saved = useIsFlexBulkSaved();

  const deadlineToDisplay = mobile
    ? deadlineFormatted
    : deadlineDisplay({
        deadline,
        deadlineExcludingWeekends,
        deadlineWeekRelative,
        t,
      });

  const [isStandardOrdering, setIsStandardOrdering] = useAtom(
    isStandardOrderingAtom
  );
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const titleStyles = "title-main text-center";
  const subtitleStyles =
    "text-center text-text-secondary pt-2 max-w-lg mx-auto text-balance";

  const title = useTitle();

  const startDate = timestampToMoment(week?.[0]);
  const endDate = timestampToMoment(week?.[week?.length - 1]);

  const sameMonth = startDate?.format("M") === endDate?.format("M");

  const startDateFormatted = startDate?.format(sameMonth ? "D" : "DD/M");
  const endDateFormatted = endDate?.format("D/M");

  return (
    <>
      <div className="pt-12">
        <div className="flex justify-center">
          <Button
            type="secondary"
            shape={!isStandardOrdering ? "solid" : "plain"}
            onClick={() => setIsStandardOrdering(false)}
            disabled={!saved}
          >
            <T _str="Weekly orders" />
          </Button>
          <Button
            type="info"
            shape={isStandardOrdering ? "solid" : "plain"}
            onClick={() => setIsStandardOrdering(true)}
            disabled={!saved}
          >
            <T _str="Standard order" />
          </Button>
        </div>

        <div className="pt-8">
          {!isStandardOrdering && (
            <div>
              <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                <div className="flex justify-center items-center">
                  <Button
                    type="primary"
                    shape="plain"
                    size="large"
                    className="mr-2"
                    icon={faChevronLeft}
                    onClick={() => setDate({ week: subtractWeek(week) })}
                  />

                  <PopoverTrigger>
                    <h1 className={classNames(titleStyles)}>{title}</h1>
                  </PopoverTrigger>

                  <Button
                    type="primary"
                    shape="plain"
                    size="large"
                    className="ml-2"
                    icon={faChevronRight}
                    onClick={() => setDate({ week: addWeek(week) })}
                  />
                </div>
                <PopoverContent className="w-fit flex justify-center items-center">
                  <Calendar.WeekPicker
                    value={moment.utc(week[0].seconds * 1000)}
                    onChange={(newValue) => {
                      const weekStart = newValue[0] as Moment;
                      const newSeconds =
                        weekStart.utc().startOf("week").valueOf() / 1000;
                      const newWeek = getWeekArray(newSeconds, Timestamp);

                      setDate({ week: newWeek });
                      setPopoverOpen(false);
                    }}
                  />
                </PopoverContent>
              </Popover>
              {!isStandardOrdering && (
                <p className={subtitleStyles}>
                  <T
                    _str="You're viewing orders for {startDateFormatted}-{endDateFormatted}."
                    startDateFormatted={startDateFormatted}
                    endDateFormatted={endDateFormatted}
                  />
                  <br />
                  <T
                    _str="Order until {deadlineFormatted}"
                    deadlineFormatted={deadlineToDisplay}
                  />
                </p>
              )}
            </div>
          )}
          {isStandardOrdering && (
            <div>
              <h1 className={classNames(titleStyles, "!text-info-dark")}>
                {title}
              </h1>

              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore (TS doesn't know textWrap) */}
              <p className={subtitleStyles} style={{ textWrap: "balance" }}>
                <T _str="Your standard order creates orders automatically, unless you make a manual change." />
              </p>
            </div>
          )}
        </div>
      </div>

      {mobile && (
        <div className="pt-3">
          <WeekDayButtons
            week={week}
            selectedDayIndex={selectedDayIndex}
            onDayClick={(newIndex: DayIndex) => {
              setSelectedDayIndex(newIndex);
              setDate({
                dateSeconds: week?.[newIndex]?.seconds || dateSeconds,
              });
            }}
            visibleForTablet={false}
            disablePastDateUi={isStandardOrdering}
            contentMapper={(_, dayIndex) => {
              if (!isStandardOrdering) return "";

              return (
                <>
                  <span className="text-xs opacity-75 mt-1 break-word text-center">
                    <T _str="every" />
                  </span>
                  <span className="font-semibold mb-2">
                    {t(dayLabels.capitalized[dayIndex]).slice(0, 3)}
                  </span>
                </>
              );
            }}
          />
        </div>
      )}
    </>
  );
};
