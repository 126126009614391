import { DayIndex, OrderLine } from "@kanpla/types";
import { atom } from "jotai";

export type Change = {
  productId: OrderLine["productId"];
  amount: OrderLine["amount"];
  options: OrderLine["options"];
  dayIndex: DayIndex;
};

export type Changes = Change[];

export const orderChangesAtom = atom<Changes>([]);
