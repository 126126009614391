import { useT } from "@kanpla/system";
import { ColumnTableList } from "@kanpla/ui";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { ExportContext } from "..";
import { OrderingContext } from "../../context";
import ExportOrdersModal from "./ExportOrdersModal";

export const ExportOrders = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const t = useT();

  const { modules } = useContainer(OrderingContext);
  const { moduleIds } = useContainer(ExportContext);

  const defaultColumns = useMemo(() => {
    const cols = [
      {
        title: t("Date"),
        path: "dateSeconds",
      },
      {
        title: t("Order name"),
        path: "orderInfo_name",
      },
      {
        title: t("Total price"),
        path: "priceTotalIncludingTax",
      },
      {
        title: t("VAT"),
        path: "taxTotal",
      },
    ];

    return cols;
  }, []);

  const allCols = (modules || []).reduce((acc, module) => {
    return {
      ...acc,
      [`export-${module?.id}-columns`]: defaultColumns,
    };
  }, {});

  const [allColumns, setAllColumns] = useLocalstorageState<{
    [key: string]: ColumnTableList;
  }>("export-all-columns", allCols);

  //Just take the columns of the first module, the columns are the same for all of them
  const selectedColumns = allColumns?.[`export-${moduleIds[0]}-columns`];

  return (
    <ExportOrdersModal
      open={open}
      setOpen={setOpen}
      setAllColumns={setAllColumns}
      selectedColumns={selectedColumns}
      allColumns={allColumns}
      modalTitle={t("Export orders")}
    />
  );
};

export default ExportOrders;
