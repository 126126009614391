import { faPenToSquare, faStore } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  calculateOrderTotalByModule,
  configToDisplayString,
  getProductNameForBasket,
  useT,
} from "@kanpla/system";
import { CombinedOfferItem, Plugins } from "@kanpla/types";
import { Badge, PriceFormatter, TimeInputDisplay, Tooltip } from "@kanpla/ui";
import classnames from "classnames";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { basketEditModeAtom } from "../../shared/basket/useBasket";
import { ProductPhoto } from "../../shared/product/ProductPhoto";
import { dateInfoAtom } from "../hooks/useDateInfo";
import {
  BasketListItemEditing,
  BasketListItemProps,
} from "./BasketListItemEditing";

interface Props extends BasketListItemProps {
  /** Is the item the last one (avoid extra lines on the last element) */
  isLast?: boolean;
  /** Displays the amount of the product as a string, useful when the picture is hidden */
  displayStringAmount?: boolean;
  dateSeconds?: number;
}

export const BasketListItem: FC<Props> = ({
  item,
  isLast,
  displayStringAmount = false,
  basket,
  setBasket,
}) => {
  const { name, photo, productId } = item;
  const basketEditMode = useAtomValue(basketEditModeAtom);

  const t = useT();

  const { offer, module, activePlugins, dateSeconds, fromAdmin, supplier } =
    useContainer(OrderingContext);
  const { isPastTodayIndividualDeadline } = useAtomValue(dateInfoAtom);
  const items = offer?.items || [];

  const product: CombinedOfferItem = items?.find(
    (p: CombinedOfferItem) => p.productId === productId
  );

  const isRequiredProduct =
    productId === module?.plugins?.requiredProduct?.productId;

  const shouldHideRequiredProduct =
    module?.plugins?.requiredProduct?.hideProduct ?? false;

  const isAdhoc = productId.startsWith("ad-hoc-");
  const isCustomProduct = productId.startsWith("custom-");
  const isPastIndividualDeadline = isPastTodayIndividualDeadline(
    product?.individualDeadline
  );
  const isEditable =
    fromAdmin ||
    (basketEditMode &&
      !isAdhoc &&
      !isRequiredProduct &&
      !isPastIndividualDeadline);

  const plugins = module?.plugins || ({} as Plugins.Array);

  const {
    photo: photoFromOffer,
    name: productName,
    category,
  } = product || ({} as CombinedOfferItem);

  const nameToDisplay = getProductNameForBasket({
    product,
    dateSeconds,
    productName: productName || name,
    productId: item.productId,
  });

  const photoToDisplay = photo || photoFromOffer;
  const priceToDisplay = calculateOrderTotalByModule([item], module);

  const configText = configToDisplayString(item);

  const isSignupOfferProduct =
    activePlugins["signupOffer"] &&
    plugins?.signupOffer?.productId === productId;

  let stock = product?.dates?.[dateSeconds]?.stock;
  if (isSignupOfferProduct && (stock || 0) > 1) stock = 1;

  return (
    <div
      className={classnames({
        "mb-3": basketEditMode && !isLast,
        hidden: isRequiredProduct && shouldHideRequiredProduct,
      })}
    >
      <div className="text-text-primary">
        <div className={`flex justify-between items-center py-2 w-full h-full`}>
          <div className="flex items-center flex-shrink overflow-hidden pt-3">
            <Badge count={item.amount} hidden={isEditable}>
              <div
                className="h-16 w-16 rounded-lg bg-background-secondary flex-shrink-0 relative overflow-hidden border border-divider-main"
                style={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <ProductPhoto
                  src={photoToDisplay}
                  name={nameToDisplay}
                  supplier={supplier}
                  size={{ w: 64, h: 64 }}
                  ratio="1:1"
                  noPhotoIconSize="1x"
                />
              </div>
            </Badge>
            <div
              className="text-text-secondary md:w-auto overflow-hidden ml-2 text-left"
              data-cy="product-order-name"
            >
              <p className="text-text-disabled text-sm">{category}</p>
              <p
                className="font-medium truncate whitespace-normal"
                title={nameToDisplay}
              >
                {nameToDisplay}
                {isAdhoc && (
                  <Tooltip content={t("Bought in the canteen")} side="right">
                    <span className="rounded inline-block bg-background-secondary p-1 px-2 ml-3 text-xs text-primary-dark">
                      <FontAwesomeIcon icon={faStore} />
                    </span>
                  </Tooltip>
                )}
                {isCustomProduct && (
                  <Tooltip content={t("This is a custom product")} side="right">
                    <span className="rounded inline-block bg-background-secondary p-1 px-2 ml-3 text-xs text-text-secondary">
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </span>
                  </Tooltip>
                )}
              </p>
              {item.options && (
                <>
                  {configText && (
                    <p className="text-sm mt-1 text-text-secondary">
                      {configText}
                    </p>
                  )}
                  {item?.deliveryTime &&
                    !(activePlugins["timeInput"] && !basketEditMode) && (
                      <div className="mt-1 text-sm text-text-secondary">
                        <TimeInputDisplay timeInput={item.deliveryTime} />
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
          <div className="h-full whitespace-nowrap flex items-center pt-3 md:p-0">
            <div className="text-primary-dark flex items-center py-1 text-right">
              {displayStringAmount && (
                <p className="text-left text-text-primary font-bold text-sm mr-2">
                  {item.amount}x
                </p>
              )}
              <p className="text-right">
                <PriceFormatter price={priceToDisplay} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <BasketListItemEditing
        item={item}
        basket={basket}
        setBasket={setBasket}
      />
    </div>
  );
};
