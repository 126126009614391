import { DayIndex, OrderLines } from "@kanpla/types";
import { atom, useSetAtom } from "jotai";
import { flexBulkFocusAtom } from "../Navigation";
import { useFilterOutSpecialVariants } from "../ordering/variants/useFilterOutSpecialVariants";
import { applyChangesToOrders } from "./applyChangesToOrders";
import { orderChangesAtom } from "./changesAtom";

export type WeeklyOrders = {
  [dayIndex in DayIndex]: OrderLines;
};

export const emptyWeeklyOrders: WeeklyOrders = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
};

export const allLoadedOrdersAtom = atom<{
  [key: string]: WeeklyOrders;
}>({});

export const allLoadedOrdersAtomWithGlobalVariantOptions = atom<{
  [key: string]: WeeklyOrders;
}>({});

export const useSetLoadedOrders = () => {
  const setLoadedOrders = useSetAtom(allLoadedOrdersAtom);
  const setAllLoadedOrdersWithGlobalVariantOptions = useSetAtom(
    allLoadedOrdersAtomWithGlobalVariantOptions
  );

  const filterOutSpecialVariants = useFilterOutSpecialVariants();

  const setFunction = (data: WeeklyOrders, key: string) => {
    // For the global variant, we want to keep the global variant to load default value
    setAllLoadedOrdersWithGlobalVariantOptions((prev) => ({
      ...prev,
      [key]: data,
    }));

    // For most of the app, we want to filter out the global variant
    const cleanData = filterOutSpecialVariants(data);
    setLoadedOrders((prev) => ({
      ...prev,
      [key]: cleanData,
    }));
  };

  return setFunction;
};

export const loadedOrdersAtom = atom<WeeklyOrders>((get) => {
  const allLoadedOrders = get(allLoadedOrdersAtom);
  const flexBulkFocus = get(flexBulkFocusAtom);
  return allLoadedOrders[flexBulkFocus] || emptyWeeklyOrders;
});

// 3. Compute the display state of weekly orders by combining loaded data and changes
export const displayOrdersAtom = atom((get) => {
  const loadedOrders = get(loadedOrdersAtom);
  const changes = get(orderChangesAtom);
  const displayOrders = applyChangesToOrders({ loadedOrders, changes });
  return displayOrders;
});
