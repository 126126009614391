import { useT } from "@kanpla/system";
import { Button, usePriceFormatter } from "@kanpla/ui";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import NewPayment from "../payment/payment";

const BalanceDisplay = () => {
  const t = useT();
  const { balance, innerAppLoading } = useContainer(OrderingContext);
  const [open, setOpen] = useState(false);

  const balanceFormatted = usePriceFormatter(balance);

  if (innerAppLoading)
    return (
      <div className="flex flex-col items-end w-12 mr-1 ml-3">
        <div className="loader w-8 h-3 rounded-sm" />
        <div className="mt-2 loader w-12 h-4 rounded-sm" />
      </div>
    );

  return (
    <>
      <Button
        className="ml-1 !w-auto"
        title={t("Deposit money")}
        aria-label={t("Deposit money")}
        id="cy-open-credit-modal"
        onClick={() => setOpen(true)}
        dataCy="btn-balance-display"
        size="small"
        type="primary"
        shape="soft"
      >
        <span className="font-semibold" style={{ fontSize: 13 }}>
          {balanceFormatted}
        </span>
      </Button>

      <NewPayment open={open} setOpen={setOpen} />
    </>
  );
};

export default BalanceDisplay;
