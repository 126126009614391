import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction } from "react";

interface Props {
  closeable?: boolean;
  setClosedBanners?: Dispatch<SetStateAction<string[]>>;
  index?: string;
}

const CloseableButton = ({ closeable, setClosedBanners, index }: Props) => {
  return closeable ? (
    <button
      onClick={() => setClosedBanners((b = []) => [...b, index])}
      className="absolute right-3 top-3 z-40 w-8 h-8 rounded-full bg-transparent hover:bg-background-secondary text-background-primary hover:text-text-primary transition-colors"
    >
      <FontAwesomeIcon icon={faTimes} />
    </button>
  ) : null;
};

export default CloseableButton;
