import { callInternalApi, useMap } from "@kanpla/system";
import { DeliveryNote, Module, School } from "@kanpla/types";
import { useQuery } from "@tanstack/react-query";
import { LoadDeliveryNotesProps } from "libs/services/src/lib/_handlers/load/customDeliveryNotes";

type Args = {
  moduleId?: Module["id"];
  schoolId?: School["id"];
  dateSecondsArray?: number[];
  enabled?: boolean;
};

export const useQueryDeliveryNotes = ({
  moduleId,
  schoolId,
  dateSecondsArray,
  enabled,
}: Args) => {
  const isValid = Boolean(dateSecondsArray?.length && schoolId && moduleId);

  const result = useQuery({
    queryKey: ["loadDeliveryNotes", { schoolId, moduleId, dateSecondsArray }],
    queryFn: () =>
      callInternalApi<Partial<LoadDeliveryNotesProps>, DeliveryNote[]>(
        "load/customDeliveryNotes",
        { schoolId, moduleId, dateSecondsArray }
      ),
    enabled: enabled ?? isValid,
  });

  const data = result.data || [];
  const dataDateMap = useMap(data, "dateSeconds");

  return { dataDateMap, data };
};
