import * as SwitchPrimitives from "@radix-ui/react-switch";
import classNames from "classnames";
import * as React from "react";

interface SwitchProps {
  label?: string;
  labelLeft?: boolean;
  dataCy?: string;
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & SwitchProps
>(({ className, label, labelLeft, dataCy, ...props }, ref) => (
  <div className="flex items-center w-fit">
    <label
      className={classNames(
        "form-label",
        { "order-last ml-2": !labelLeft },
        { "mr-2": labelLeft }
      )}
    >
      {label}
    </label>
    <SwitchPrimitives.Root
      className={classNames(
        "peer inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary-main data-[state=unchecked]:bg-secondary-backdrop",
        className
      )}
      {...props}
      data-cy={dataCy}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={classNames(
          "pointer-events-none block h-5 w-5 rounded-full data-[state=checked]:bg-primary-contrast data-[state=unchecked]:bg-secondary-dark shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0"
        )}
      />
    </SwitchPrimitives.Root>
  </div>
));

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
