import { faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { useT } from "@kanpla/system";
import { Button, DrawerOrModal } from "@kanpla/ui";
import { useState } from "react";
import AddChild from "../forms/addChild";

const NewKid = () => {
  const t = useT();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        shape="solid"
        className="w-full my-3"
        onClick={() => setOpen(true)}
        dataCy="btn-add-new-kid"
        icon={faUserPlus}
      >
        {t("Add another user")}
      </Button>
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        title={t("Add user")}
        stopPropagation={false}
        noOverflow
      >
        <AddChild callback={() => setOpen(false)} />
      </DrawerOrModal>
    </>
  );
};

export default NewKid;
