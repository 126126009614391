import { useLocationHostname, useT } from "@kanpla/system";
import dynamic from "next/dynamic";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import NewKid from "../modals/newKid";
import KidLoader from "./KidLoader";
import Credit from "./credit";
import SalesplaceKid from "./salesplace/SalesplaceKid";

const Kid = dynamic(() => import("./kid"), {
  ssr: false,
  loading: () => <KidLoader />,
});

const Kids = () => {
  const t = useT();

  const {
    children,
    isChildSalesplace,
    appLoading,
    childId: activeChildId,
  } = useContainer(AppContext);

  /** HARDCODED! */
  const domainsWithSingleUser = [
    "serwiz.kanpla.dk",
    "gastrokantiner.kanpla.dk",
    "zoffmannjensen.kanpla.dk",
  ];

  const hostname = useLocationHostname({});
  const singleUserApp = hostname
    ? domainsWithSingleUser.includes(hostname)
    : false;

  if (appLoading)
    return (
      <div>
        <h2 className="heading-main pb-2 border-b">{t("User")}</h2>
        <KidLoader />
        <div className="opacity-60">
          <KidLoader />
        </div>
        <div className="opacity-40">
          <KidLoader />
        </div>
      </div>
    );

  const activeChild = children?.find((child) => child.id === activeChildId);
  const otherChildren = children?.filter((child) => child.id !== activeChildId);

  return (
    <div className="max-w-full overflow-hidden">
      {!isChildSalesplace ? (
        <div>
          <h2 className="text-sm uppercase font-semibold text-text-secondary mb-2">
            {t("Active user")}
          </h2>
          {!children?.length && (
            <p className="my-4 text-center text-text-disabled">
              {t("You have no user on this account.")}
            </p>
          )}
          {activeChild && (
            <div className="flex flex-col gap-y-3 w-full">
              <Kid key={activeChild.id} child={activeChild} />
            </div>
          )}
          {otherChildren && (
            <div className="mt-5">
              <h2 className="text-sm uppercase font-semibold text-text-secondary mb-2">
                {t("Other users")}
              </h2>
              <div className="flex flex-col gap-y-3 w-full">
                {otherChildren.map((child) => (
                  <Kid key={child.id} child={child} />
                ))}
              </div>
            </div>
          )}
          {!singleUserApp && <NewKid />}
        </div>
      ) : (
        <SalesplaceKid />
      )}
      <Credit className="mt-4" />
    </div>
  );
};

export default Kids;
