import { faLanguage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { callInternalApi, useT } from "@kanpla/system";
import { CombinedOffer } from "@kanpla/types";
import { Button, message } from "@kanpla/ui";
import {
  TranslateMenusArgs,
  TranslateMenusReturns,
} from "apps/frontend/pages/api/internal/translate/menus";
import { atom, useAtom } from "jotai";
import moment from "moment";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { MenuPreviewContext } from "./MenuPreviewContext";
import { showMenuTranslatedAtom, useTranslations } from "./useTranslations";

export const translatedOfferAtom = atom<CombinedOffer | null>(null);

const TranslateMenu = () => {
  const { userDeviceLanguage, week, schoolId } = useContainer(AppContext);
  const { items } = useContainer(MenuPreviewContext);

  const [loading, setLoading] = useState<boolean>(false);

  const { active: translationsAvailable } = useTranslations();

  const [showMenuTranslated, setShowMenuTranslated] = useAtom(
    showMenuTranslatedAtom
  );

  const t = useT();

  const startOfWeek = moment().utc().startOf("isoWeek").unix();
  const endOfNextWeek = moment().utc().add(1, "weeks").endOf("isoWeek").unix();

  const shouldAllowTranslation = moment(week[0].seconds).isBetween(
    startOfWeek,
    endOfNextWeek,
    undefined,
    "[]"
  );

  const [translatedOffer, setTranslatedOffer] = useAtom(translatedOfferAtom);

  const getTranslatedOffer = async () => {
    try {
      setLoading(true);

      const { translatedOffer } = await callInternalApi<
        TranslateMenusArgs,
        TranslateMenusReturns
      >("translate/menus", { language: userDeviceLanguage, offer: items });

      setTranslatedOffer(translatedOffer);
    } catch (err) {
      console.error(err);
      message.error(t("Translations are not available at the moment."));
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchLanguage = async () => {
    if (!translatedOffer) {
      await getTranslatedOffer();
    }

    setShowMenuTranslated((prev) => !prev);
  };

  useEffect(() => {
    if (!translatedOffer) return;

    setTranslatedOffer(null);
    setShowMenuTranslated(false);
  }, [schoolId]);

  if (!(translationsAvailable && shouldAllowTranslation)) return null;

  return (
    <div className="w-full flex justify-center flex-col md:flex-row text-center items-center pb-6 text-sm gap-2 relative z-20">
      <div className="bg-info-backdrop text-info-main rounded-md px-2 py-0.5 text-sm flex items-center justify-center gap-x-2">
        <FontAwesomeIcon icon={faLanguage} />
        BETA
      </div>
      <p>{t("This menu is available for translation.")}</p>
      <Button
        loading={loading}
        shape="plain"
        className="font-semibold underline !mx-0 !px-0"
        size="small"
        onClick={handleSwitchLanguage}
      >
        {showMenuTranslated
          ? t("Show original")
          : t(`Translate ({lang})`, { lang: userDeviceLanguage.toUpperCase() })}
      </Button>
    </div>
  );
};

export default TranslateMenu;
