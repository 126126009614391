import { OrderingContext } from "@kanpla/ordering";
import { LoadSingleOfferProps, LoadSingleOfferResult } from "@kanpla/services";
import {
  getDayIndexFromSeconds,
  getDaysInRange,
  getMidnightSeconds,
  tx,
  useFetch,
  useT,
} from "@kanpla/system";
import { OrderPersonal } from "@kanpla/types";
import { message } from "@kanpla/ui";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";
import { useContainer } from "unstated-next";
import { deadlineInfoAtom } from "../../lib/hooks/useDeadlineInfo";
import { meetingOverwriteOfferAtom } from "../../lib/hooks/useOffer";
import { basketResetAtom } from "../../shared/basket/useBasket";
import { useSetBasket } from "../../shared/basket/useSetBasket";
import Ordering from "./views/Ordering";
import Overview from "./views/Overview";

interface Props {
  moduleId?: string;
}

export interface Views {
  [key: string]: {
    content: ReactNode;
  };
}

export const meetingCurrentViewAtom = atom<"overview" | "ordering">("overview");
export const meetingEditingOrderAtom = atom<OrderPersonal | null>(null);

const MealplanMeeting = (props: Props) => {
  const t = useT();

  const {
    setTimeNavigation,
    setDate,
    dayIndex,
    week,
    moduleId,
    schoolId,
    childId,
    module,
    dateSeconds,
    overviewEditingOrder,
    fromAdmin,
  } = useContainer(OrderingContext);

  const setMeetingOverwriteOffer = useSetAtom(meetingOverwriteOfferAtom);
  const { deadline } = useAtomValue(deadlineInfoAtom);
  const basketReset = useSetAtom(basketResetAtom);
  const setBasket = useSetBasket();

  const [currentView, setCurrentView] = useAtom(meetingCurrentViewAtom);
  const calendarDate = moment.unix(dateSeconds);

  // Ignore all other orders for the day (except the one editing, in case)
  const [editingOrder, setEditingOrder] = useAtom(meetingEditingOrderAtom);

  const { data: meetingOffer } = useFetch<
    LoadSingleOfferProps,
    LoadSingleOfferResult
  >(
    "offers/loadSingleOffer",
    {
      moduleId,
      schoolId,
      childId,
      fromAdmin,
      dateSeconds: (currentView === "ordering"
        ? [dateSeconds]
        : (getDaysInRange(
            moment(calendarDate).startOf("month").startOf("day"),
            moment(calendarDate).endOf("month").add(2, "days").startOf("day"),
            "unix"
          ) as Array<number>)
      ).map((seconds) => getMidnightSeconds(seconds)),
    },
    { refreshInterval: 60000, revalidateOnFocus: false }
  );

  const meetingOfferTrigger = JSON.stringify(meetingOffer);
  useEffect(() => {
    setMeetingOverwriteOffer({
      [moduleId]: meetingOffer?.offer,
    });
  }, [meetingOfferTrigger]);

  useEffect(() => {
    if (!isEmpty(overviewEditingOrder)) {
      setCurrentView("ordering");
      setEditingOrder(overviewEditingOrder as OrderPersonal);
    }
  }, []);

  const router = useRouter();

  const meetingDateQuery = router.query?.["meetingDate"];

  /** Automatic ordering via a link */
  const includeWeekend = module?.plugins?.sevenDayWeek?.active;
  useEffect(() => {
    if (!meetingDateQuery) return;

    // Ignore if date is unix because of the new way date gets registered in the URL
    if (
      moment.unix(parseInt(meetingDateQuery as string)).isValid() &&
      meetingDateQuery.length > 8
    )
      return;

    const momentDate = moment.parseZone(
      `${meetingDateQuery}`,
      "DDMMYYYY",
      tx.getCurrentLocale()
    );

    const currentlySelectedDate = week?.[dayIndex]?.seconds;

    if (!currentlySelectedDate) return;

    if (moment.unix(currentlySelectedDate).isSame(momentDate, "day")) return;

    if (!momentDate.isValid()) {
      message.warning(t("Invalid date in the URL"));
      return;
    }

    const dayDeadline = momentDate.clone().startOf("day").add(deadline, "s");
    // After deadline
    if (dayDeadline.isBefore(moment())) {
      message.warning(t("It's too late to order for this date"));
      return;
    }

    setDate({ momentDate });
    const dateSeconds = momentDate.unix();

    // Disallow weekend
    const localDayIndex = parseInt(
      getDayIndexFromSeconds(dateSeconds).toFixed(0)
    );
    if (!includeWeekend && (localDayIndex === 5 || localDayIndex === 6)) {
      message.warning(t("You can not order on the weekend"));
      return;
    }

    setCurrentView("ordering");
    setEditingOrder(null);
  }, [router.isReady, week, dayIndex, meetingDateQuery]);

  useEffect(() => {
    if (editingOrder) {
      setBasket(editingOrder);
    } else {
      basketReset();
    }
  }, [editingOrder?.id, currentView]);

  useEffect(() => {
    setTimeNavigation("none");
  }, [currentView, setTimeNavigation]);

  useEffect(() => {
    setTimeNavigation("none");
  }, []);

  if (currentView === "ordering") return <Ordering />;

  return <Overview />;
};

export default MealplanMeeting;
