import {
  AllergenName,
  Child,
  CombinedOfferItem,
  MenuItem,
  ProductIconItem,
} from "@kanpla/types";

interface Props {
  child?: Child | null;
  product?: CombinedOfferItem;
  menuItem?: MenuItem | null;
}

/**
 * This function returns the intersection of the allergens of the child and the product.
 */
export const getAllergensIntersection = ({
  child,
  product,
  menuItem,
}: Props) => {
  const cAllergens = child?.allergens || {};
  const pAllergens = product?.allergens || {};
  const mAllergens = menuItem?.allergens || {};

  /** Concatenation between the menu product and the product allergens */
  const allAllergens = { ...pAllergens, ...mAllergens };

  const activeAllergens = (allergens: ProductIconItem<AllergenName>) =>
    Object.entries(allergens).filter(([_, isActive]) => isActive);

  const cActiveAllergens = activeAllergens(cAllergens);
  const pActiveAllergens = Object.fromEntries(activeAllergens(allAllergens));

  const allergens = Object.fromEntries(
    cActiveAllergens.filter(([key, _]) => pActiveAllergens[key])
  );

  return allergens;
};
