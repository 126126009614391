import { ExportOrdersResponseItem, Module, Partner } from "@kanpla/types";
import { groupBy } from "lodash";

interface Props {
  modules: Module[];
  partner?: Partner;
  dictionaries?: ExportOrdersResponseItem;
  productPerLine?: boolean;
  t: any;
}

export const defineOptions = ({
  modules,
  partner = null,
  dictionaries = null,
  productPerLine,
  t = (string) => string,
}: Props) => {
  const moduleIds = (modules || []).map((module) => module?.id);
  const emptyExportOrderInstance: ExportOrdersResponseItem = {
    moduleName: t("Name of the module"),
    modulePaymentMethod: t("Payment method"),
    salesplaceName: t("Salesplace name"),
    name: t("Full name"),
    displayName: t("Username"),
    groupName: t("User's division"),
    childId: t("User's id"),
    email: t("User's email"),
    dateSeconds: t("Order date"),
    billedDateSeconds: t("Billed date"),
    createdDateSeconds: t("Created date"),
    order: t("Ordered products"),
    orderId: t("Order ID"),
    priceTotalIncludingTax: t("Total price including VAT"),
    priceTotalExcludingTax: t("Total price excluding VAT"),
    taxTotal: t("Total VAT"),
    priceIndividualIncludingTax: t("Price per item including VAT"),
    priceIndividualExcludingTax: t("Price per item excluding VAT"),
    taxIndividual: t("VAT per item"),
    productsTotal: t("Number of products"),
    orderInfo_name: t("Name of the order"),
    deliveryTime: t("Delivery time"),
    invoiceReference: t("Invoice reference"),
    category: t("Category"),
    internalCanteenId: t("Internal canteen Id"),
  };

  const setCustomOptions = () => {
    const activeModulesWithTextInputs = modules.filter(
      (m) =>
        moduleIds.includes(m.id) &&
        m?.plugins?.textInput?.active &&
        m?.plugins?.textInput?.fields?.length
    );
    const textInputs = activeModulesWithTextInputs
      .map((module) => module?.plugins?.textInput?.fields)
      .flat();

    const groupedInputs = groupBy(textInputs, "title");

    const options = Object.entries(groupedInputs).map(([title, inputs]) => ({
      path: `custom_${inputs.map((input) => input.key).join("|")}`,
      title,
      group: "Brugerdefinerede felter",
    }));

    return options;
  };

  const setDefinedOptions = () => {
    const options = Object.entries(emptyExportOrderInstance)
      .filter(
        ([path]) =>
          productPerLine ||
          ![
            "priceIndividualIncludingTax",
            "priceIndividualExcludingTax",
            "taxIndividual",
          ].includes(path)
      )
      .map(([path, title]) => ({
        path,
        title,
        group: "Definerede felter",
      }));

    if (partner?.proPlugins?.customProductId)
      options.push({
        path: "customId",
        title: "Brugerdefinerede produkt ID'er",
        group: "Definerede felter",
      });

    return options;
  };

  const customOptions = setCustomOptions();
  const definedOptions = setDefinedOptions();

  return {
    customOptions: customOptions || [],
    definedOptions: definedOptions || [],
  };
};
