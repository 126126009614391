import { capitalizeFirstLetter } from "@kanpla/system";
import classnames from "classnames";
import React, { ReactNode } from "react";

interface Props {
  name?: string | ReactNode;
  noMargin?: boolean;
  noMarginTop?: boolean;
  children: ReactNode;
  /** Pixels from top */
  top?: number | string;
}

export const Category = ({
  name = "—",
  children,
  noMargin = false,
  top,
  noMarginTop,
}: Props) => {
  return (
    <div className={classnames({ "mt-8": !noMarginTop })}>
      <div
        className={classnames(
          "heading-main md:text-xl relative flex items-center z-10",
          !noMargin && "px-2",
          top === undefined && "-top-3 mt-3"
        )}
        style={{ top }}
      >
        <span className="min-w-full block p-0 px-4 rounded-br-lg -ml-7 pl-8 box-content z-10">
          {typeof name === "string" ? capitalizeFirstLetter(name) : name}
        </span>
      </div>

      <div className="z-10">{children}</div>
    </div>
  );
};
