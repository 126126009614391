import { faUserPen } from "@fortawesome/pro-solid-svg-icons";
import { useT } from "@kanpla/system";
import { School, _FrontendModule } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import React, { useMemo, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import EditKid from "../../modals/EditKid";
import { EditKidButtons } from "../EditKid/EditKidButtons";

const UserInfoBase: React.FC = () => {
  const { child, user, supplier, allModules, school } =
    useContainer(AppContext);

  const t = useT();
  const [open, setOpen] = useState(false);

  const initials = useMemo(
    () =>
      child?.name
        ?.split(" ")
        .map((word) => word[0])
        .filter((ch) => /([A-Za-z])/.test(ch))
        .join("")
        .slice(0, 2),
    [child?.name]
  );

  if (!child) return null;

  return (
    <>
      <div>
        <div className="p-4 pr-0 rounded-md flex items-center justify-between bg-secondary-light text-secondary-contrast">
          <div className="inline-flex items-center">
            <div className="rounded-full mr-3 h-10 w-10 flex items-center justify-center p-2 bg-secondary-main border border-secondary-dark">
              {initials}
            </div>
            <div className="flex flex-col" style={{ maxWidth: 180 }}>
              <span className="text-lg font-medium truncate">
                {child.displayName || child.name}
              </span>
              <span className="text-xs">{user?.email}</span>
            </div>
          </div>
          <Button
            icon={faUserPen}
            shape="plain"
            onClick={() => setOpen(true)}
            dataCy="btn-salesplace-open-edit-kid"
            className="text-secondary-contrast"
          >
            {t("Edit")}
          </Button>
        </div>
      </div>
      <EditKidButtons
        child={child}
        supplier={supplier}
        allModules={(allModules || []) as _FrontendModule[]}
        school={school as unknown as School}
      />
      <EditKid open={open} setOpen={setOpen} child={child} isChildSalesplace />
    </>
  );
};

const UserInfo = React.memo(UserInfoBase);
export default UserInfo;
