import {
  getErrorMessage,
  selectorsHasOnlyChoice,
  useT,
  validateSelectors,
} from "@kanpla/system";
import { ChildSelector, School, Selector } from "@kanpla/types";
import {
  Button,
  DrawerOrModal,
  Form,
  SelectorsInput,
  message,
} from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { isMenuOpenAtom } from "../settings";

type FormData = {
  selectors: ChildSelector;
};

const AddClassInfo = () => {
  const t = useT();
  const { child, school, updateCurrentChild } = useContainer(AppContext);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const setIsMenuOpen = useSetAtom(isMenuOpenAtom);

  const selectorText = useMemo(() => {
    const s: Partial<School> | null = school;
    const sels = s?.selectors || [];
    const allSels = sels?.filter(
      (c, i) => sels.length <= 1 || i !== sels.length - 1
    );
    const lastSel = sels?.length > 1 && sels[sels.length - 1];

    return `${allSels?.map((s: Selector) => s.name.toLowerCase()).join(", ")}${
      lastSel ? ` og ${lastSel.name.toLowerCase()}` : ""
    }`;
  }, [school]);

  const trigger = (child?.userId || "") + (child?.id || "") + school?.id;

  const setOnlyChoiceSelector = async (onlyChoice: ChildSelector) => {
    await updateCurrentChild({
      selectors: onlyChoice,
      approveSelectors: null,
    });
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (!child || !school) return;
    const noSelectors = !school.selectors || school.selectors.length === 0;
    const notRightSchool = child.schoolId !== school?.id;
    if (noSelectors || notRightSchool) return;

    const noNew =
      isEmpty(child.selectors || {}) ||
      !validateSelectors(child.selectors, school.selectors);

    if (noNew) {
      // If there's only one choice to choose from, automatically assign it
      const onlyChoice = selectorsHasOnlyChoice({
        selectors: school?.selectors,
      });
      if (onlyChoice) {
        setOnlyChoiceSelector(onlyChoice);
      } else {
        handleOpen();
      }
    }
    if (child.approveSelectors) {
      handleOpen();
    }
  }, [trigger]);

  if (!child) return null;

  const submit = async (data: FormData) => {
    try {
      setLoading(true);

      const { selectors } = data;

      const validSelector = validateSelectors(selectors, school?.selectors);
      if (!validSelector) throw new Error(t("Fill all the fields"));

      await updateCurrentChild({
        selectors,
        approveSelectors: null,
      });

      message.success(t("Information about your user is saved"));
      setOpen(false);
    } catch (e) {
      console.error(e);
      message.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      closableOutside={false}
      stopPropagation={false}
      setOpen={() => {
        message.info(t("Please add information about your user"));
      }}
      title={
        child.approveSelectors
          ? t("Welcome back!")
          : t("Hi {value}", { value: child.name })
      }
      noOverflow
    >
      {child.approveSelectors === "selector_change" && (
        <p className="text-center">
          {t(
            "Hello {childName}. Welcome back from holidays! ☀️ Please enter {selectorText}.",
            { childName: child.name, selectorText }
          )}
        </p>
      )}

      {child.approveSelectors === "school_change" && (
        <p className="text-center">
          {t(
            "Hi {childName}. Your kitchen has moved you to {schoolName}. 📚🧑‍🍳 Please enter {selectorText}.",
            {
              childName: child.name,
              schoolName: child.school_name,
              selectorText,
            }
          )}
        </p>
      )}

      {!child.approveSelectors && (
        <p className="text-center">
          {t("Please enter {value}.", { value: selectorText })}
        </p>
      )}
      <Form<FormData>
        className="my-8 m-auto mb-0"
        onSubmit={submit}
        defaultValues={{ selectors: child?.selectors }}
      >
        <Form.Item
          name="selectors"
          controlled
          controlledProps={{
            valueName: "selectors",
            onChangeName: "setSelectors",
          }}
          rules={{ required: t("Please choose a selector") }}
        >
          {/* @ts-ignore */}
          <SelectorsInput school={school} />
        </Form.Item>
        <Button
          htmlType="submit"
          loading={loading}
          shape="solid"
          type="primary"
          loadingText={t("Please wait...")}
          dataCy="btn-submit-confirm"
        >
          {t("Confirm")}
        </Button>
      </Form>
    </DrawerOrModal>
  );
};

export default AddClassInfo;
