import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getErrorMessage, removeUndefinedKeys, useT } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { Button, ChildView, message, sectionClasses } from "@kanpla/ui";
import { Dispatch, SetStateAction, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import ChildForm from "../forms/childForm";
import AdhocSettings from "./AdhocSettings";
import DeleteKid from "./deleteKid";

interface Props {
  child: Child;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isChildSalesplace?: boolean;
}

const EditKid = ({
  child,
  open,
  setOpen,
  isChildSalesplace = false,
}: Props) => {
  const t = useT();

  return (
    <ChildView open={open} setOpen={setOpen} title={t("Edit user")}>
      <Content
        child={child}
        open={open}
        isChildSalesplace={isChildSalesplace}
      />
    </ChildView>
  );
};

const Content = ({
  child,
  open,
  isChildSalesplace = false,
}: {
  child: Child;
  open: boolean;
  isChildSalesplace: boolean;
}) => {
  const t = useT();

  const { school, updateCurrentChild } = useContainer(AppContext);

  const [deleteOpen, setDeleteOpen] = useState(false);

  const extendedClasses = `${sectionClasses} mt-6`;

  const submit = async (data: Partial<Child>) => {
    try {
      removeUndefinedKeys(data);
      await updateCurrentChild(data);
      message.success(t("Information about your user is saved"));
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      message.error(errorMessage);
    }
  };

  if (!open) return null;

  return (
    <>
      <div className="pb-4 flex items-center">
        <FontAwesomeIcon
          icon={faUserCircle}
          size="3x"
          className="text-text-primary mr-3"
        />
        <div>
          <h3 className="title-secondary leading-tight">{child.name}</h3>
          <p className="text-text-secondary text-sm">
            {!isChildSalesplace && child ? (
              <>
                {child.school_name}
                {child?.groupName ? `, ${child.groupName}` : ``}
              </>
            ) : null}
          </p>
        </div>
      </div>
      <div className={extendedClasses}>
        <h3 className="heading-main mb-2">Information</h3>
        <div className="">
          <ChildForm
            submit={submit}
            child={child}
            isChildSalesplace={isChildSalesplace}
            edit
          />
        </div>
      </div>

      {school?.hasCards && (
        <div className={extendedClasses}>
          <AdhocSettings child={child} />
        </div>
      )}

      <div className={extendedClasses}>
        <h3 className="heading-main mb-2">{t("Other options")}</h3>
        <Button
          className="mt-1"
          onClick={() => setDeleteOpen(true)}
          size="small"
          type="danger"
          shape="solid"
          dataCy="btn-delete-kid"
        >
          {t("Delete {value}", { value: child.name })}
        </Button>
      </div>
      <DeleteKid open={deleteOpen} setOpen={setDeleteOpen} child={child} />
    </>
  );
};

export default EditKid;
