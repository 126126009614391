import { OrderingContext } from "@kanpla/ordering";
import { configToDisplayString, stringifyOrder } from "@kanpla/system";
import { CombinedOfferItem, OrderLine, OrderLines } from "@kanpla/types";
import classNames from "classnames";
import { useContainer } from "unstated-next";
import { ProductPhoto } from "../../../shared/product/ProductPhoto";

interface Props {
  orderLine: OrderLine;
  /** How should we render the items */
  big?: boolean;
}

interface ReceiptItemProps {
  items: OrderLines;
}

export const SingleReceiptItem = ({ orderLine, big }: Props) => {
  const { items, supplier } = useContainer(OrderingContext);

  const product =
    items?.find((p) => p.productId === orderLine.productId) ||
    ({} as CombinedOfferItem);

  const { photo, name } = product;

  if (big)
    return (
      <div className="w-full flex flex-col items-center relative mt-4">
        <div
          className={classNames("w-full relative h-52", {
            "bg-primary-light rounded-lg": !photo,
          })}
        >
          <ProductPhoto src={photo} name={name} supplier={supplier} />
        </div>
        <h1 className="title-secondary mt-2 text-center text-text-primary">
          {name}
        </h1>
        <p className="text-text-secondary whitespace-nowrap">
          {configToDisplayString(orderLine, false)}
        </p>
        <div className="bg-primary-main -top-4 -left-4 rounded-full flex items-center justify-center absolute text-xl text-primary-contrast font-semibold w-12 h-12 p-2 border-2 border-background-primary">
          {orderLine.amount}x
        </div>
      </div>
    );

  return (
    <div className="w-full flex items-center relative mt-4 gap-4">
      <div
        className={classNames("w-16 relative h-16", {
          "bg-primary-light rounded-lg": !photo,
        })}
      >
        <ProductPhoto src={photo} name={name} supplier={supplier} />
      </div>
      <div>
        <h2 className="text-lg text-center text-text-primary">{name}</h2>
        <p className="text-text-secondary whitespace-nowrap">
          {configToDisplayString(orderLine, false)}
        </p>
      </div>
      <div className="bg-primary-main -top-2 -left-3 rounded-full flex items-center justify-center absolute text-base text-primary-contrast font-semibold w-8 h-8 p-1.5 border border-background-primary">
        {orderLine.amount}x
      </div>
    </div>
  );
};

const ReceiptItems = (props: ReceiptItemProps) => {
  const { items } = props;

  const filteredItems = items.filter((item) => item.amount);

  return (
    <>
      {filteredItems.map((orderLine) => (
        <SingleReceiptItem
          key={stringifyOrder([orderLine])}
          orderLine={orderLine}
          big={filteredItems.length < 3}
        />
      ))}
    </>
  );
};

export default ReceiptItems;
