import { dayLabels, entriesTotalDayAmount, useT } from "@kanpla/system";
import { DayIndex } from "@kanpla/types";
import { FoodWasteInsightsData } from "libs/system/src/foodwaste/entriesToGraphData";
import { max, values } from "lodash";
import React from "react";
import Bar from "./Bar";

interface ChartProps {
  weekData: FoodWasteInsightsData;
}

const Chart = ({ weekData }: ChartProps) => {
  const t = useT();

  const weekDataKeys = Object.keys(weekData);
  const hasWeekendData =
    weekDataKeys.includes("5") || weekDataKeys.includes("6");

  const highestDayAmount = max(
    values(weekData).map((entry) => entriesTotalDayAmount(entry))
  ) as number;

  return (
    <div className="flex flex-1 justify-between gap-x-4">
      {dayLabels.capitalized.map((day, dayIndex) => {
        if (!hasWeekendData && (dayIndex === 5 || dayIndex === 6)) return null;

        const dayData = weekData[dayIndex as DayIndex];
        const dayLabel = t(day);

        return (
          <div className="flex flex-col items-center justify-end gap-y-2 w-full">
            {dayData && (
              <Bar data={dayData} absoluteWeekMax={highestDayAmount} />
            )}
            <h3>{dayLabel.slice(0, 3)}</h3>
          </div>
        );
      })}
    </div>
  );
};

export default Chart;
