import { faMoneySimpleFromBracket } from "@fortawesome/pro-regular-svg-icons";
import { T, useRequest, useT } from "@kanpla/system";
import {
  Alert,
  ButtonLine,
  DrawerOrModal,
  Form,
  Input,
  TermsOfTrade,
  activeCurrencyAtom,
  message,
} from "@kanpla/ui";
import { RequestPayoutProps } from "apps/frontend/pages/api/internal/submit/requestPayout";
import { useAtomValue } from "jotai";
import { round } from "lodash";
import React, { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext, paymentGatewayIdAtom } from "../../contextProvider";

interface FormData {
  payoutAmount: number;
}

const RequestPayout = () => {
  const t = useT();
  const [open, setOpen] = useState(false);
  const activeCurrency = useAtomValue(activeCurrencyAtom);
  const { balance, userId } = useContainer(AppContext);
  const { isSubmitting, request } = useRequest<RequestPayoutProps, boolean>(
    "submit/requestPayout"
  );
  const paymentGatewayId = useAtomValue(paymentGatewayIdAtom);

  const requestPayout = async ({ payoutAmount }: FormData) => {
    try {
      await request({ userId, payoutAmount, currency: activeCurrency });

      message.success(
        t("Your request is registered, you will hear from us soon.")
      );
      return null;
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
      console.error(e);
    }
  };

  // Don't show button if there's no balance
  if (!balance) return null;

  const availableBalance = round(balance / 100, 2);

  return (
    <>
      <ButtonLine
        label={t("Request payout")}
        icon={faMoneySimpleFromBracket}
        onClick={() => setOpen(true)}
      />
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        title={t("Request payout")}
        actions={[
          {
            onClick: () => null,
            label: t("Request payout"),
            loading: isSubmitting,
            form: "request-payout-form",
            htmlType: "submit",
          },
        ]}
      >
        <Form<FormData>
          id="request-payout-form"
          className="mt-6"
          onSubmit={requestPayout}
          defaultValues={{
            payoutAmount: availableBalance,
          }}
        >
          <Form.Item
            name="payoutAmount"
            rules={{
              required: t("Requested amount for payout"),
            }}
          >
            <Input.Number
              placeholder={String(availableBalance)}
              max={availableBalance}
              required
            />
          </Form.Item>
        </Form>
        <Alert
          className="text-sm mt-2"
          type="warning"
          message={
            <T
              _str="As per our {terms}, there is a fee applied on each payout. You will receive the fee breakdown together with your payout confirmation over email."
              terms={<TermsOfTrade paymentGatewayId={paymentGatewayId} />}
            />
          }
        />
        <Alert
          className="text-sm mt-2"
          type="info"
          message={
            <T _str="The requested amount minus the fee will be paid out within 3 - 5 business days on your account. In case there is an issue with your payout, our customer service will reach out to you over the email." />
          }
        />
      </DrawerOrModal>
    </>
  );
};

export default RequestPayout;
