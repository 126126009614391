import { isEmpty } from "lodash";

import { AllowanceUsage, CombinedOfferItem, Currency } from "@kanpla/types";

import { t } from "../transifex.config";
import { priceFormatter } from "../utils/priceFormatter";

import { getAllowancesForProduct } from "./getAllowancesForProduct";

interface GetAllowanceLeftArgs {
  allowanceUsages: AllowanceUsage[];
  product?: CombinedOfferItem;
  currency: Currency;
  locale: string;
  filterOutWithProduct?: boolean;
  showExclusiveVat: boolean;
  vatRate?: number;
}

export const getAllowanceLeftMessage = ({
  allowanceUsages,
  product,
  locale,
  currency,
  showExclusiveVat,
  vatRate,
  filterOutWithProduct = false,
}: GetAllowanceLeftArgs) => {
  const productId = product?.id;

  const targetAllowances = productId
    ? getAllowancesForProduct({
        allowanceUsages,
        productId,
      })
    : allowanceUsages;

  if (isEmpty(targetAllowances)) return { messages: [] };

  // Filter out specific allowances that are not generic for Registering module (will handle specific ones on the product level)
  const filteredAllowances = targetAllowances.filter((allowance) => {
    if (!filterOutWithProduct) return true;

    const hasGenericAllowance =
      isEmpty(allowance.numberOfProducts?.productIds) &&
      isEmpty(allowance.unitPrice?.productIds);

    if (hasGenericAllowance) return true;

    return false;
  });

  const messages = filteredAllowances.map<string>((allowance) => {
    const { numberOfProducts, unitPrice } = allowance;

    if (numberOfProducts) {
      return t(`You have {amount} products left on your allowance`, {
        amount: numberOfProducts.amount,
      });
    }

    if (unitPrice) {
      const totalPrice = showExclusiveVat
        ? unitPrice.amount
        : unitPrice.amount * (1 + (vatRate || 0.25));

      return t(`You have {amount} left on your allowance`, {
        amount: priceFormatter(
          totalPrice >= 0 ? totalPrice : 0,
          currency,
          locale
        ),
      });
    }

    return "";
  });

  const messageForProductCall = () => {
    if (!product) return null;

    const withProductOnly = targetAllowances.filter((allowance) => {
      const hasUnitPriceProducts = allowance.unitPrice?.productIds?.includes(
        product.id
      );

      const hasNumberOfProducts =
        allowance.numberOfProducts?.productIds?.includes(product.id);

      return hasNumberOfProducts || hasUnitPriceProducts;
    });

    if (isEmpty(withProductOnly)) return null;

    const messages = withProductOnly.map<string>((allowance) => {
      const { numberOfProducts, unitPrice } = allowance;

      if (numberOfProducts) {
        return t(`Amount {amount} left on your allowance for this product`, {
          amount: numberOfProducts.amount,
        });
      }

      if (unitPrice) {
        const totalPrice = showExclusiveVat
          ? unitPrice.amount
          : unitPrice.amount * (1 + (vatRate || 0.25));

        return t(`{amount} left on your allowance for this product`, {
          amount: priceFormatter(
            totalPrice >= 0 ? totalPrice : 0,
            currency,
            locale
          ),
        });
      }

      return [];
    });

    return messages[0];
  };

  const messageForProduct = messageForProductCall();

  return { messages: messages.filter((message) => message), messageForProduct };
};
