export const getProportionalHeightPadding = (
  width: number,
  place?: "top" | "bottom" | "center" | "height",
  double?: boolean
) => {
  if (place === "top") {
    if (width < 450) return !double ? "pb-6" : "pb-12";
    if (width < 600) return !double ? "pb-12" : "pb-24";
    if (width < 800) return !double ? "pb-18" : "pb-36";
    return !double ? "pb-24" : "pb-48";
  }
  if (place === "bottom") {
    if (width < 450) return !double ? "pt-6" : "pt-12";
    if (width < 600) return !double ? "pt-12" : "pt-24";
    if (width < 800) return !double ? "pt-18" : "pt-36";
    return !double ? "pt-24" : "pt-48";
  }
  if (place === "height") {
    if (width < 450) return !double ? "h-20" : "h-40";
    if (width < 600) return !double ? "h-24" : "h-48";
    if (width < 800) return !double ? "h-28" : "h-56";
    return !double ? "h-28" : "h-56";
  }
  if (width < 450) return !double ? "py-6" : "py-12";
  if (width < 600) return !double ? "py-12" : "py-24";
  if (width < 800) return !double ? "py-18" : "py-36";
  return !double ? "py-24" : "py-48";
};

export const getProportionalFontSize = (
  width: number,
  currentSize:
    | "text-xs"
    | "text-sm"
    | "text-base"
    | "text-lg"
    | "text-xl"
    | "text-2xl"
    | "text-3xl"
    | "text-4xl"
    | "text-5xl"
    | "text-6xl"
    | "text-7xl"
    | "text-8xl"
    | "text-9xl" = "text-base"
) => {
  const sizes = [
    "text-xs",
    "text-sm",
    "text-base",
    "text-lg",
    "text-xl",
    "text-2xl",
    "text-3xl",
    "text-4xl",
    "text-5xl",
    "text-6xl",
    "text-7xl",
    "text-8xl",
    "text-9xl",
  ];

  const index = sizes.findIndex((s) => s === currentSize);

  if (width < 550) return currentSize;
  if (width < 850) return sizes[index + 1];
  return sizes[index + 2];
};
