import { useT } from "@kanpla/system";
import { Button, DrawerOrModal } from "@kanpla/ui";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { Helpcenter } from "../Helpcenter";

const SupportButtons = () => {
  const t = useT();

  const [contactOpen, setContactOpen] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);

  const { school, module } = useContainer(AppContext);
  const { contact } = school || {};

  const hideContactKitchenButton = module?.config?.hideContactKitchen ?? false;

  const { email, tel } = contact || {};
  const hasContact = email || tel;

  return (
    <div className="flex flex-col md:flex-row mt-8 items-center">
      {/* Contact Kitchen */}
      {!hideContactKitchenButton && hasContact && (
        <>
          <Button
            type="primary"
            className="mx-1 mb-2"
            onClick={() => setContactOpen(true)}
            dataCy="contact-the-kitchen"
            shape="solid"
          >
            {t("Contact the kitchen")}
          </Button>
          <DrawerOrModal
            open={contactOpen}
            setOpen={setContactOpen}
            title={t("Contact the kitchen")}
            subtitle={t(
              "You can contact the kitchen with questions about food, allergens, delivery, pick-up or refunds."
            )}
            showCloseButton={true}
          >
            <div className="text-text-primary text-center">
              {email && (
                <div className="mt-4">
                  <p className="h200">E-mail</p>
                  <a className="font-medium" href={`mailto:${email}`}>
                    {email}
                  </a>
                </div>
              )}
              {tel && (
                <div className="mt-4">
                  <p className="h200">{t("Telephone")}</p>
                  <a className="font-medium" href={`tel:${tel}`}>
                    {tel}
                  </a>
                </div>
              )}
            </div>
          </DrawerOrModal>
        </>
      )}

      {/* Kanpla: Technical Questions */}
      <Button
        className="mx-1 mb-2"
        onClick={() => setSupportOpen(true)}
        dataCy="technical issues"
        shape="solid"
      >
        {t("Technical issues")}
      </Button>
      <DrawerOrModal
        open={supportOpen}
        setOpen={setSupportOpen}
        className="overflow-hidden"
        title={t("Technical issues?")}
        showCloseButton
      >
        <Helpcenter />
      </DrawerOrModal>
    </div>
  );
};

export default SupportButtons;
