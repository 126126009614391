import { hasAccessToModule, isUrlRelative } from "@kanpla/system";
import { backLinkAtom } from "apps/frontend/components/Navbar";
import { AppContext } from "apps/frontend/components/contextProvider";
import {
  isOpeningFeedbackModalAtom,
  useFeedback,
} from "apps/frontend/components/feedback/useFeedback";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { BlockProps } from "../../utils";
import { FlexMenuContent } from "../FlexMenu";
import LockedBlock from "../LockedBlock";
import CloseableButton from "./CloseableButton";
import BannerFive from "./Five";
import BannerFour from "./Four";
import BannerOne from "./One";
import BannerSix from "./Six";
import BannerThree from "./Three";
import BannerTwo from "./Two";

const BannerInner = ({ singleBlock }: BlockProps) => {
  const {
    type,
    title = "",
    topTitle,
    subTitle,
    imageUrl = "",
    link,
    linkText,
    moduleId,
    productId,
    hideBorder,
    overlayOpacity = 70,
  } = singleBlock?.props || {};

  const doc = new DOMParser().parseFromString(title, "text/html");
  const escapedTitle = doc.documentElement.textContent || "";

  if (type === 1)
    return (
      <BannerOne
        overlayOpacity={overlayOpacity}
        title={escapedTitle}
        imageUrl={imageUrl}
      />
    );

  if (type === 2)
    return (
      <BannerTwo
        title={escapedTitle}
        imageUrl={imageUrl}
        link={link}
        linkText={linkText}
        overlayOpacity={overlayOpacity}
      />
    );

  if (type === 3)
    return (
      <BannerThree
        title={escapedTitle}
        subTitle={subTitle}
        imageUrl={imageUrl}
        link={link}
        linkText={linkText}
        overlayOpacity={overlayOpacity}
      />
    );

  if (type === 4)
    return (
      <BannerFour
        title={escapedTitle}
        subTitle={subTitle}
        imageUrl={imageUrl}
        topTitle={topTitle}
        overlayOpacity={overlayOpacity}
      />
    );

  if (type === 5)
    return (
      <BannerFive
        title={escapedTitle}
        subTitle={subTitle}
        imageUrl={imageUrl}
      />
    );

  if (type === 6)
    return (
      <BannerSix
        title={escapedTitle}
        subTitle={subTitle}
        imageUrl={imageUrl}
        link={link}
        linkText={linkText}
        overlayOpacity={overlayOpacity}
      />
    );

  if (type === "flexMenu")
    return (
      <FlexMenuContent
        productId={productId}
        moduleId={moduleId}
        link={link}
        hideBorder={hideBorder}
      />
    );

  return null;
};

const Banner = ({ singleBlock, index }: BlockProps) => {
  const { moduleId, setModuleId, setIsBulk, school, child } =
    useContainer(AppContext);
  const setBackLink = useSetAtom(backLinkAtom);
  const isOpeningFeedbackModal = useAtomValue(isOpeningFeedbackModalAtom);
  const { schoolId, modules } = useContainer(AppContext);

  const [closedBanners, setClosedBanners] = useLocalstorageState<Array<string>>(
    "homescreen-banners-closed"
  );

  const { openSurveyModal } = useFeedback();

  const closeableButtonProps = { setClosedBanners, index };

  if (!singleBlock || !singleBlock.props) return null;

  const { link, closeable, prefix } = singleBlock.props;

  if (closeable && closedBanners?.includes(index)) return null;

  if (!link || link === "")
    return (
      <div className="relative z-0 h-full">
        <CloseableButton closeable={closeable} {...closeableButtonProps} />
        <BannerInner singleBlock={singleBlock} />
      </div>
    );

  if (link && prefix === "feedback") {
    return (
      <div
        className={classNames(
          "cursor-pointer relative z-0 h-full",
          isOpeningFeedbackModal && "pointer-events-none"
        )}
        onClick={() => openSurveyModal(link)}
      >
        <CloseableButton closeable={closeable} {...closeableButtonProps} />
        <BannerInner singleBlock={singleBlock} />
      </div>
    );
  }

  if (link && link !== "") {
    /** Outer links */
    if (!isUrlRelative(link))
      return (
        <div className="relative z-0 h-full">
          <CloseableButton closeable={closeable} {...closeableButtonProps} />
          <a
            className="cursor-pointer text-current relative no-underline focus:no-underline h-full block"
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            <BannerInner singleBlock={singleBlock} />
          </a>
        </div>
      );

    const hasModuleId = link.includes("/m/");

    const getModuleId = () => {
      const newLink = link.split("/") as Array<string>;
      const mIndex = newLink.findIndex((i) => i === "m");
      return newLink[mIndex + 1];
    };

    const linkModule = modules?.find((m) => m.id === getModuleId());

    const redirectToModule = () => {
      const newModuleId = linkModule?.id;
      if (!newModuleId) return;
      setModuleId(newModuleId);
      const { bulk } = hasAccessToModule({
        child,
        school,
        module: linkModule,
      });

      setIsBulk(!!bulk);
      setBackLink({ fromModuleId: newModuleId, toModuleId: moduleId });
    };

    /** Inner links */
    if (
      !hasModuleId ||
      linkModule?.scope?.generatedSchoolIds?.includes(schoolId)
    ) {
      return (
        <div className="relative z-0 h-full">
          <CloseableButton closeable={closeable} {...closeableButtonProps} />
          <p
            onClick={redirectToModule}
            className="cursor-pointer text-current relative h-full block"
          >
            <BannerInner singleBlock={singleBlock} />
          </p>
        </div>
      );
    }
  }

  /** The user has no access to the module that the block is redirecting to */
  return (
    <div className="relative z-0 h-full rounded-lg overflow-hidden">
      <BannerInner singleBlock={singleBlock} />
      <LockedBlock />
    </div>
  );
};

export default Banner;
