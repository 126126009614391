import { MobileBasket } from "./MobileBasket";
import BasketStep from "./steps/Basket";
import ProductSelection from "./steps/ProductSelection";

const Ordering = () => {
  return (
    <div className="w-full h-full pb-8 md:pb-0 md:min-h-screen flex border-b">
      <div className="w-full md:w-1/2 lg:w-2/3 mx-auto max-w-4xl p-4 md:p-6 lg:p-16">
        <ProductSelection />
      </div>
      <div className="md:w-1/2 lg:w-2/5 xl:w-1/3 border rounded-lg shadow-sm mt-6 mb-8 hidden md:block p-2 md:p-4 h-fit">
        <BasketStep openModalWithEdit />
      </div>
      <MobileBasket />
    </div>
  );
};

export default Ordering;
