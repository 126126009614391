import { faLeaf } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalizeFirstLetter, useT } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { AutoScrollContainer, Category } from "@kanpla/ui";
import { useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import { dateInfoAtom } from "../lib/hooks/useDateInfo";
import { deadlineInfoAtom } from "../lib/hooks/useDeadlineInfo";
import {
  clickedCategoryIndexAtom,
  deadlineInfoHeightAtom,
  inViewCategoryIndexAtom,
} from "../shared/navbar/NavbarSecondary";
import Deadline from "./Deadline";
import Product from "./Product";

interface Props {
  name: string;
  items: Array<CombinedOfferItem>;
  index: number;
  showChildrenAnyway?: boolean;
  isHidden?: boolean;
  noMarginTop?: boolean;
}

const ProductCategory = ({
  name,
  index,
  items = [],
  showChildrenAnyway = false,
  isHidden = false,
  noMarginTop,
}: Props) => {
  const t = useT();

  const {
    hasRequiredProduct,
    module,
    mobile,
    activePlugins,
    child,
    dateSeconds,
  } = useContainer(OrderingContext);

  const { isPastDeadline, isPastTodayIndividualDeadline } =
    useAtomValue(dateInfoAtom);
  const { deadline } = useAtomValue(deadlineInfoAtom);
  const clickedCategoryIndex = useAtomValue(clickedCategoryIndexAtom);
  const setInViewCategoryIndex = useSetAtom(inViewCategoryIndexAtom);
  const deadlineInfoHeight = useAtomValue(deadlineInfoHeightAtom);

  const allPastDeadline = isPastDeadline;

  const filteredItems = items.filter((item) => {
    if (!item.dates[dateSeconds]?.available) return false;
    // Hide if it's a required product
    const hiddenRequiredProduct =
      hasRequiredProduct &&
      module?.plugins?.requiredProduct?.productId === item.productId;

    return !hiddenRequiredProduct;
  });

  const hideCategory = useMemo(() => {
    const signupOffer = activePlugins?.["signupOffer"];
    const signupOfferProductId = module?.plugins?.signupOffer?.productId;

    if (!signupOffer) return false;

    const empty = !(filteredItems || []).filter(
      (item) => item.productId !== signupOfferProductId
    )?.length;

    if (empty && child?.takenSignupOffer) return true;

    return false;
  }, [child?.takenSignupOffer]);

  if (!filteredItems.length) return null;

  // Seeing if there is a common deadline
  const commonDeadline: number | boolean = (() => {
    const productDeadlines = filteredItems.map((item) =>
      typeof item.individualDeadline === `number`
        ? item.individualDeadline
        : deadline
    );

    const uniqueProductDeadlines: any = [...new Set(productDeadlines)];
    return uniqueProductDeadlines.length === 1
      ? uniqueProductDeadlines[0]
      : false;
  })();

  const displayName =
    capitalizeFirstLetter(name) ||
    module?.text?.["noCategory/text"] ||
    t("Dish of the day");

  if (hideCategory) return null;

  return (
    <AutoScrollContainer
      className="boundary-element -mx-1"
      scrollIntoView={index === clickedCategoryIndex}
      onIntercept={() => setInViewCategoryIndex(index)}
      topOffsetPx={mobile ? 250 + deadlineInfoHeight : 180}
    >
      <Category
        name={
          <>
            {displayName || ""}
            {displayName === "Leftovers" ? (
              <FontAwesomeIcon
                icon={faLeaf}
                color="#298f54"
                className="ml-2"
                size="sm"
              />
            ) : (
              ""
            )}
            {typeof commonDeadline === "number" &&
              commonDeadline !== deadline && (
                <Deadline
                  individualDeadline={commonDeadline}
                  className="ml-3 mr-auto inline-block"
                />
              )}
          </>
        }
        top={0}
        noMarginTop={noMarginTop}
      >
        {filteredItems.map((item) => {
          const isPastDeadline = isPastTodayIndividualDeadline(
            item?.individualDeadline
          );

          return (
            <Product
              product={{
                ...item,
              }}
              key={item.productLineId}
              commonDeadline={commonDeadline}
              pastDeadline={
                !item.dates?.[dateSeconds]?.available ||
                (isHidden && !showChildrenAnyway) ||
                (allPastDeadline && !item.individualDeadline) ||
                isPastDeadline
              }
              showChildrenAnyway
            />
          );
        })}
      </Category>
    </AutoScrollContainer>
  );
};

export default ProductCategory;
