import { calculateAmountOfOrderItems, getCssVariable, t } from "@kanpla/system";
import { OrderPersonal } from "@kanpla/types";
import { ReceiptWrapper } from "@kanpla/ui";
import classNames from "classnames";
import moment from "moment";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import OrderItem from "./OrderItem";

const setReceiptConfig = (
  order: OrderPersonal,
  ordersAmount: number,
  index: number,
  createdAtSeconds: number
) => {
  const date = moment.unix(createdAtSeconds);

  return {
    title:
      ordersAmount > 1
        ? order?.info?.name ||
          t("Order nr. {index} from {time}", {
            index: `#${index}`,
            time: date.format(
              date.isSame(moment(), "day") ? "HH:mm" : "L HH:mm"
            ),
          })
        : t("My order"),
    status: { text: t("Order confirmed") },
  };
};

const Receipt = () => {
  const { mobile, hasOrdered, orders, module } = useContainer(OrderingContext);

  const filteredOrders = orders.filter((o) =>
    calculateAmountOfOrderItems(o.orderLines)
  );
  const amountOfOrders = filteredOrders.length;

  if (!hasOrdered) return null;

  const customColor = getCssVariable("--palette-receipt-primary") || "#ffffff";

  const hasRegistering = module?.flow === "registering";

  return (
    <div
      className={classNames(
        "flex flex-col pb-6 md:p-0",
        !hasRegistering && "md:float-right"
      )}
    >
      {filteredOrders?.map((order, index) => {
        // In this overview, the order is always only personal
        const typedOrder = order as OrderPersonal;

        return (
          <ReceiptWrapper
            fullWidth
            svgColor={customColor}
            mobile={mobile}
            ordersAmount={amountOfOrders || 0}
            orderDocument={typedOrder}
            orderNumber={index + 1}
            receiptConfig={setReceiptConfig(
              typedOrder,
              amountOfOrders || 0,
              index + 1,
              order.createdAtSeconds || 0
            )}
            className={classNames(
              "pt-0 md:pt-8",
              hasRegistering && "md:min-w-full"
            )}
            initialOpen={orders.length - 1 === index}
            center={hasRegistering}
          >
            <OrderItem orderDocument={typedOrder} />
          </ReceiptWrapper>
        );
      })}
    </div>
  );
};

export default Receipt;
