import { RequestReceiptProps } from "@kanpla/services";
import { T, getErrorMessage, useRequest, useT } from "@kanpla/system";
import React, { useState } from "react";
import { Button } from "../button/Button";
import { DrawerOrModal } from "../elements/BottomDrawer/DrawerOrModal";
import { Form } from "../inputs/generic/Form";
import { Input } from "../inputs/generic/Input";
import { message } from "../inputs/generic/Message/Message";

interface Props {
  type: "payment" | "order";
  id: string;
  emails?: Array<string>;
}

interface FormProps {
  emails: Array<string>;
}

export const RequestReceipt = ({
  type,
  id,
  emails: defaultEmails = [],
}: Props) => {
  const [open, setOpen] = useState(false);
  const t = useT();

  const { request: requestReceipt, isSubmitting } = useRequest<
    RequestReceiptProps,
    boolean
  >("user/request-receipt");

  if (!id) return null;

  const onRequest = async (data: FormProps) => {
    try {
      await requestReceipt({ type, id, emails: data.emails });

      message.success(t("Your receipts should soon be in your inbox!"));
      setOpen(false);
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      console.error(e);
      message.error(errorMessage);
    }
  };

  const formId = `request-receipt-${type}-${id}`;

  return (
    <div>
      <DrawerOrModal
        title={<T _str="Request receipt" />}
        open={open}
        setOpen={setOpen}
        actions={[
          {
            label: "Request receipt",
            type: "primary",
            onClick: () => null,
            htmlType: "submit",
            form: formId,
            loading: isSubmitting,
          },
        ]}
        stopPropagation={false}
      >
        <Form<FormProps>
          defaultValues={{ emails: defaultEmails }}
          id={formId}
          onSubmit={onRequest}
        >
          <Form.Item
            label={<T _str="Emails to send receipt to" />}
            extra={
              <T _str="We will send the confirmation to all these emails" />
            }
            name="emails"
            rules={{
              required: t("Enter at least one email"),
            }}
          >
            <Input.Tags placeholder={t("Add emails...")} />
          </Form.Item>
        </Form>
      </DrawerOrModal>
      <Button size="small" shape="soft" onClick={() => setOpen(true)}>
        <T _str="Request receipt" />
      </Button>
    </div>
  );
};
