import { FoodWasteInsightsData } from "@kanpla/system";

export const trackingInsightsMockData: FoodWasteInsightsData = {
  0: [
    { metricKey: "plate", amount: 10 },
    { metricKey: "buffet", amount: 4 },
    { metricKey: "meeting", amount: 8 },
    { metricKey: "production", amount: 22 },
  ],
  1: [
    { metricKey: "plate", amount: 4 },
    { metricKey: "buffet", amount: 6 },
    { metricKey: "meeting", amount: 15 },
  ],
  2: [
    { metricKey: "plate", amount: 31 },
    { metricKey: "buffet", amount: 2.6 },
    { metricKey: "meeting", amount: 0.5 },
    { metricKey: "production", amount: 40 },
  ],
  3: [
    { metricKey: "plate", amount: 1.7 },
    { metricKey: "buffet", amount: 19 },
    { metricKey: "meeting", amount: 5.3 },
    { metricKey: "production", amount: 11 },
  ],
  4: [
    { metricKey: "plate", amount: 10 },
    { metricKey: "buffet", amount: 20 },
    { metricKey: "meeting", amount: 30 },
    { metricKey: "production", amount: 40 },
  ],
};
