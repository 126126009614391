import { useT } from "@kanpla/system";
import { Space } from "@kanpla/ui";

const AnonymousBasket = () => {
  const t = useT();

  return (
    <Space className="w-full relative" direction="vertical">
      <div className="h-full flex justify-center items-center py-64 px-8 text-center">
        <p className="font-semibold text-lg text-primary-main">
          {t("Log in or sign up to complete your purchase")}
        </p>
      </div>
    </Space>
  );
};

export default AnonymousBasket;
