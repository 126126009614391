import { shouldHaveStandardOrdering } from "@kanpla/system";
import { DayIndex, School, _FrontendModule } from "@kanpla/types";
import { ModuleLoadingWrapper } from "@kanpla/ui";
import classNames from "classnames";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { FC, useEffect } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import { dateInfoAtom } from "../lib/hooks/useDateInfo";
import CanteenClosed from "../shared/CanteenClosed";
import { Navigation } from "./Navigation";
import { useLoadStandardOrders } from "./data/useLoadStandardOrders";
import { useLoadWeeklyOrders } from "./data/useLoadWeeklyOrders";
import { useHolidays } from "./holidays/useHolidays";
import { Week } from "./ordering/Week";
import { useSoftDeadlineMaxAmount } from "./softDeadline/useSoftDeadlineMaxAmount";
import { SaveButton } from "./submit/SaveButton";
import { useResetChanges } from "./submit/useResetChanges";

interface OverviewComponentProps {
  availableModules: Array<_FrontendModule>;
}
export interface ExtendingComponentsProps {
  Overview?: FC<OverviewComponentProps>;
}

/** Selected day */
export const selectedDayIndexAtom = atom<DayIndex>(0);

export const FlexBulk = (props: ExtendingComponentsProps) => {
  const { Overview } = props;

  const { innerAppLoading, school, module, setTimeNavigation, fromAdmin } =
    useContainer(OrderingContext);
  const { weekSeconds } = useAtomValue(dateInfoAtom);
  const { actualHolidays, hasActiveModuleSwitchHoliday } = useHolidays();

  const hasStandardOrdering = shouldHaveStandardOrdering({
    targetModule: module,
    school: school as unknown as School,
  });
  const resetChanges = useResetChanges();

  const setSelectedDayIndex = useSetAtom(selectedDayIndexAtom);

  useSoftDeadlineMaxAmount();

  // Time navigation
  useEffect(() => {
    setSelectedDayIndex(0);
    setTimeNavigation("calendarWeekly");
  }, []);

  // Reset weekly changes when week changes
  useEffect(() => {
    resetChanges();
  }, [weekSeconds]);

  useLoadStandardOrders();
  useLoadWeeklyOrders();

  return (
    <>
      <SaveButton />

      <div className={classNames("wrapper", fromAdmin && "w-full")}>
        <Navigation />

        {/* Removed module description since it doesn't fit the design and doesn't seem to be needed here */}
        {/* <ModuleDescription align="left" module={module} /> */}

        <ModuleLoadingWrapper loading={innerAppLoading}>
          {hasActiveModuleSwitchHoliday ? (
            <CanteenClosed
              disableJumpLink
              holidayDesc={
                typeof actualHolidays[0] !== `boolean`
                  ? actualHolidays[0]?.design
                  : undefined
              }
            />
          ) : (
            <>
              <Week />
              {hasStandardOrdering && (
                <div className="lg:grid grid-cols-2 gap-4 px-3 lg:px-0">
                  {/* Sidenav overview for prints and exports */}
                  <div className="mb-4">
                    {typeof Overview !== "undefined" ? (
                      <Overview availableModules={[module]} />
                    ) : null}
                  </div>
                </div>
              )}
            </>
          )}
        </ModuleLoadingWrapper>
      </div>
    </>
  );
};
