import React from "react";

import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import classNames from "classnames";

type HeaderProps = {
  liveCount: number;
  avgCount: number;
  isPreview: boolean;
};

export const Header = ({ liveCount, avgCount, isPreview }: HeaderProps) => {
  const t = useT();

  return (
    <div className="flex justify-between">
      <div className="flex flex-col gap-1">
        <div className="flex gap-2 items-center">
          <span className="text-4xl leading-none font-bold ">{liveCount}</span>

          <FontAwesomeIcon className="h-6" icon={faUser} />
        </div>

        {isPreview ? (
          <Placeholder />
        ) : (
          <span className="text-xs">{t("Right now")}</span>
        )}
      </div>

      <div className={classNames("flex flex-col", { "gap-2 w-20": isPreview })}>
        {isPreview ? (
          <Placeholder />
        ) : (
          <span className="text-sm">
            {liveCount > avgCount ? t("More busy") : t("Less busy")}
          </span>
        )}

        {isPreview ? (
          <Placeholder className="!w-2/3 self-end" />
        ) : (
          <span className="text-sm">{t("than usual")}</span>
        )}
      </div>
    </div>
  );
};

const Placeholder = ({ className }: { className?: string }) => (
  <div className={classNames("rounded-md w-full h-2 loader", className)} />
);

export const Wrapper = React.forwardRef<
  HTMLDivElement,
  JSX.IntrinsicElements["div"]
>((props, ref) => {
  return (
    <div
      className="flex h-full justify-center max-h-[300px] min-h-[250px] w-full max-w-md flex-col rounded-md bg-background-secondary text-text-primary p-4"
      ref={ref}
      {...props}
    />
  );
});
