/* eslint-disable react-hooks/rules-of-hooks */
import {
  faPlateUtensils,
  faShopSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newMap, useT } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { Dictionary } from "lodash";
import { FC } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import DayDetailCategory from "./DayDetailCategory";
import { useCategoriesDictionary } from "./useCategories";

/** Draws all categories of the day. */
const DayDetail: FC = () => {
  const t = useT();
  const { dateSeconds, offer, dayIndex, supplier } = useContainer(AppContext);
  const categories: Dictionary<CombinedOfferItem[]> = useCategoriesDictionary(
    dayIndex,
    true
  );
  const isHoliday = offer?.holidayDates?.[dateSeconds];

  const menuIsEmpty = !Object.values(categories || {}).length;

  const categoryIconsSet = newMap(
    supplier?.custom?.categories || [],
    "category"
  );

  return (
    <div
      className="z-10 shadow-xl px-16 py-8 justify-between border rounded-lg w-full h-full bg-main-100 flex-column overflow-hidden bg-background-primary"
      style={{ flex: 3 }}
    >
      {menuIsEmpty && (
        <div className="flex items-center justify-center p-8 flex-col h-full text-text-contrast opacity-80 ">
          <FontAwesomeIcon
            icon={isHoliday ? faShopSlash : faPlateUtensils}
            className="mb-4 text-7xl"
          />
          <p>
            {isHoliday
              ? t("The kitchen is closed on this day.")
              : t("The menu is not live yet")}
          </p>
        </div>
      )}
      {!menuIsEmpty &&
        Object.entries(categories || {}).map(
          ([categoryName, categoryMenus]) => {
            const trimmedCategoryName = categoryName?.trim() || "";
            const menuIcon = categoryIconsSet.get(
              trimmedCategoryName === t("Dish of the day")
                ? ""
                : trimmedCategoryName
            )?.src;

            return (
              <DayDetailCategory
                key={categoryName}
                categoryName={categoryName}
                items={categoryMenus}
                menuIcon={menuIcon}
              />
            );
          }
        )}
    </div>
  );
};

export default DayDetail;
