import { Divider, Space } from "@kanpla/ui";
import { useRef } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { BasketList } from "../../lib/BasketList/BasketList";
import AnonymousBasket from "../../shared/anonymous/AnonymousBasket";
import BasketPreview from "../kanplaGo/BasketPreview";
import { BasketModalWrapper } from "./BasketModalWrapper";
import Payment from "./elements/Payment";
import PriceOverview from "./elements/PriceOverview";
import { TakeFirstBanner } from "./TakeFirstBanner";
import useBasketPurchaseLogic from "./useBasketPurchaseLogic";

const Basket = () => {
  const { userId } = useContainer(OrderingContext);
  const {
    actions: [actions, actionsClassName, noPadding],
    onSubmit,
    basketInfoForm,
  } = useBasketPurchaseLogic();

  // Refs
  const drawerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="grid max-h-full grid-rows-2">
      <BasketPreview />
      <BasketModalWrapper
        actions={actions}
        actionsClassName={actionsClassName}
        noPaddingOnActions={noPadding}
      >
        {userId ? (
          <div
            className="row-start-1 row-end-3 px-6 sm:px-4 lg:px-0 md:pb-4 text-text-primary select-none"
            ref={drawerRef}
          >
            <Space
              direction="vertical"
              align="start"
              size="middle"
              className="w-full"
            >
              <TakeFirstBanner />
              <BasketList basketInfoForm={basketInfoForm} onSubmit={onSubmit} />

              <Divider />

              {/* Price overview */}
              <PriceOverview />

              {/* Payment */}
              <Payment />
            </Space>
          </div>
        ) : (
          <AnonymousBasket />
        )}
      </BasketModalWrapper>
    </div>
  );
};
export default Basket;
