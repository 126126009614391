import { DayIndex, OrderLine } from "@kanpla/types";
import { Changes } from "../../../../data/changesAtom";
import { stringifyChangeInAWeek } from "./useOnChange";

interface Props {
  productId: string;
  dayIndex: DayIndex;
  options: OrderLine["options"];
  changes: Changes;
}

export const getIsValueEdited = ({
  productId,
  dayIndex,
  options,
  changes,
}: Props) => {
  const changesIncludeValue = changes.find(
    (ch) =>
      stringifyChangeInAWeek(ch) ===
      stringifyChangeInAWeek({ productId, dayIndex, options })
  );

  return !!changesIncludeValue;
};
