import { Homescreen } from "@kanpla/types";
import React from "react";
import Button from "../blocks/Button";
import Subtitle from "./Subtitle";
import Title from "./Title";

interface Props {
  title?: string;
  subtitle?: string;
  buttonProps?: Homescreen.ContentInner;
}

const SectionHeader = ({ title, buttonProps, subtitle }: Props) => {
  if (!title && !subtitle) return null;

  return (
    <header>
      <div className="flex justify-between items-baseline mt-4">
        <Title title={title} />
        {buttonProps && (
          <div>
            <Button singleBlock={buttonProps} />
          </div>
        )}
      </div>
      <Subtitle subtitle={subtitle} />
    </header>
  );
};

export default SectionHeader;
