import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { T, useT } from "@kanpla/system";
import { ButtonLine, DrawerOrModal } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { CustomLegalLinkButton } from "libs/ui/src/legal/CustomLegalLinkButton";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext, partnerDataSubjectAtom } from "../../contextProvider";

const TermsAndConditions = () => {
  const t = useT();

  const partnerDataSubject = useAtomValue(partnerDataSubjectAtom);

  const { paymentGatewayId, supplier } = useContainer(AppContext);

  const [termsOpen, setTermsOpen] = useState(false);

  const name = partnerDataSubject?.name || supplier?.name || "Kanpla ApS";

  return (
    <>
      <ButtonLine
        label={t("Terms and Conditions")}
        onClick={() => setTermsOpen(true)}
        icon={faInfoCircle}
        dataCy="language-preferences-btn"
      />
      <DrawerOrModal
        open={termsOpen}
        setOpen={setTermsOpen}
        title={t("Terms and Conditions")}
      >
        <div className="text-center mb-8">
          {!!name && <h3 className="font-semibold text-lg">{name}</h3>}
          {!!partnerDataSubject?.address && (
            <p>{partnerDataSubject?.address}</p>
          )}
          {!!partnerDataSubject?.vatNr && (
            <p>
              <T _str="VAT no.:" /> {partnerDataSubject?.vatNr}
            </p>
          )}
        </div>
        <CustomLegalLinkButton
          fileName="privacy_policy"
          defaultLink="https://kanpla.io/privacy-policy"
          paymentGatewayId={paymentGatewayId ?? undefined}
          className="block"
        >
          <ButtonLine label={t("Privacy Policy")} className="px-4" />
        </CustomLegalLinkButton>
        <CustomLegalLinkButton
          fileName="terms_of_trade"
          defaultLink="https://kanpla.io/terms-of-trade"
          paymentGatewayId={paymentGatewayId ?? undefined}
          className="block"
        >
          <ButtonLine label={t("Terms of Trade")} className="px-4" />
        </CustomLegalLinkButton>
        {/* <ButtonLine label={t("Complaints and Returns")} disabled /> */}
      </DrawerOrModal>
    </>
  );
};

export default TermsAndConditions;
