import { faReceipt } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { t } from "@kanpla/system";
import { OrderPersonal } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { receiptOrderAtom } from "../../orders/order/Receipt";

interface Props {
  orderDocument: OrderPersonal;
}

export const OpenReceiptButton = ({ orderDocument }: Props) => {
  const { fromAdmin } = useContainer(OrderingContext);
  const setReceipt = useSetAtom(receiptOrderAtom);

  if (fromAdmin) return null;

  return (
    <Button
      onClick={() => {
        setReceipt({
          order: orderDocument,
          from: "history",
        });
      }}
      shape="soft"
      size="small"
      dataCy="btn-receipt-show-history"
      title={t("Open receipt")}
    >
      <FontAwesomeIcon icon={faReceipt} />
    </Button>
  );
};
