import { faCertificate, faGift } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { callInternalApi, getErrorMessage, useT } from "@kanpla/system";
import { StampCard, StampCardRule } from "@kanpla/types";
import { Button, message, Modal } from "@kanpla/ui";
import {
  UseStampcardProps,
  UseStampcardReturn,
} from "apps/frontend/pages/api/internal/stampcards/useStampcard";
import classNames from "classnames";
import { atom, useAtom } from "jotai";
import { cloneDeep } from "lodash";
import router from "next/router";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { useStampCards } from "./useStampCards";

export const stampcardModalAtom = atom<{
  isOpen: boolean;
  stampcard: { structure: StampCardRule; data: StampCard } | null;
}>({ isOpen: false, stampcard: null });

export const StampcardModal = () => {
  const { mobile, partnerId, schoolId } = useContainer(AppContext);
  const [stampcardModal, setStampcardModal] = useAtom(stampcardModalAtom);
  const t = useT();

  const { stampcards, setStampcards } = useStampCards();

  const [loading, setLoading] = useState(false);

  const moduleId = stampcardModal.stampcard?.structure.reward.moduleId;
  const productId =
    stampcardModal.stampcard?.structure.reward.discount.productId;

  const linkToShop =
    !!moduleId || !!productId
      ? `/app/m/${moduleId}/s/${schoolId}?productId=${productId}`
      : "";

  const submit = async () => {
    if (!partnerId || !stampcardModal.stampcard) return;

    try {
      setLoading(true);

      const { stampcard: stampcardUsed, discount: discountCreated } =
        await callInternalApi<UseStampcardProps, UseStampcardReturn>(
          "stampcards/useStampcard",
          { stampcard: stampcardModal.stampcard }
        );

      discountCreated &&
        message.success(t("New discount created"), {
          messageId: "success-new-discount-created",
        });

      const deepCopy = cloneDeep(stampcards);
      const stampcardIndex = deepCopy.findIndex(
        (s) => s.structure.id === stampcardUsed.structure.id
      );

      deepCopy[stampcardIndex] = stampcardUsed;
      stampcardIndex !== -1 && setStampcards({ stampcards: deepCopy });

      setStampcardModal({ isOpen: false, stampcard: null });
      discountCreated && router.push(linkToShop);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      message.error({ content: errorMessage, key: "error-update-stampcard" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className="h-96 w-96"
      zMax
      open={stampcardModal.isOpen}
      setOpen={() => setStampcardModal({ isOpen: false, stampcard: null })}
    >
      <FontAwesomeIcon icon={faCertificate} />
      <div
        className={classNames(
          "inset-0 transition text-primary-contrast duration-500 z-max transform",
          mobile ? "fixed" : "absolute"
        )}
      >
        <div className="flex flex-col items-center justify-center bg-primary-main p-8 h-full">
          <FontAwesomeIcon
            icon={faGift}
            className="text-primary-contrast h-24 aspect-square"
          />
          <h3 className="title-secondary mb-10 mt-10 text-center">
            {stampcardModal.stampcard?.structure?.title || ""}
          </h3>
          <Button type="secondary" onClick={submit} loading={loading}>
            {t("Activate")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
