import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createOrderLine } from "@kanpla/system";
import {
  CombinedOfferItem,
  FlexOption,
  OrderLine,
  StandardOrderLine,
  Supplier,
} from "@kanpla/types";
import { ProductItemInfo, hidePricesAtom } from "@kanpla/ui";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext, mealOptionsAtom } from "../../context";
import { useProcessAllergens } from "../../lib/allergens/waitAllergensWarning";
import { useFindFlexStandard } from "../../lib/flex/useFindFlexStandard";
import { VariantsModal } from "../../shared/productOptions/VariantsModal";

interface ProductItemProps {
  product: CombinedOfferItem;
  value?: OrderLine | StandardOrderLine;
  onChange: (newOrder: OrderLine) => void;
  disabled?: boolean;
  dateSeconds: number;
  /** Only display standard order if there's no order document */
  useStandard?: boolean;
  isLast?: boolean;
}

const ProductItem = ({
  product,
  value,
  onChange,
  disabled = false,
  dateSeconds,
  useStandard,
  isLast = false,
}: ProductItemProps) => {
  const mealOptions = useAtomValue(mealOptionsAtom);
  const hidePrices = useAtomValue(hidePricesAtom);
  const { supplier, mobile } = useContainer(OrderingContext);

  const { hasStandardTemplate, isStandard: hasStandard } = useFindFlexStandard({
    product,
    dateSeconds,
  });

  const isSelected = !!value || (hasStandardTemplate && useStandard);
  const isStandard = hasStandard && useStandard;

  // Handle variants
  const hasOptions = !isEmpty(product?.options);
  const [openVariants, setOpenVariants] = useState<boolean>(false);

  const { waitAllergensWarning } = useProcessAllergens({
    product,
    dateSeconds,
  });

  const handleOnClick = async () => {
    const forward = await waitAllergensWarning();
    if (!forward) return;

    const skipVariants = skipVariantsCheck({
      product,
      dateSeconds,
      mealOptions,
    });

    if (hasOptions && !skipVariants) {
      return setOpenVariants(true);
    }

    onChange(createOrderLine({ amount: 1, product, options: {} }));
  };

  return (
    <>
      <div
        onClick={handleOnClick}
        className={classNames(
          "md:pr-5 pl-1 md:pl-7 md:py-4 mb-3 rounded transition-all md:bg-background-primary flex justify-between gap-x-8 cursor-pointer relative overflow-hidden",
          {
            "opacity-30 pointer-events-none": disabled,
            "md:border-2 md:border-info-main": isSelected && isStandard,
            "md:border-2 md:border-primary-main": isSelected && !isStandard,
            "md:border hover:border-divider-main": !isSelected,
          },
          {
            "border-b border-divider-main": !isLast && mobile,
          }
        )}
      >
        {!mobile && (
          <div
            className={classNames("w-2 h-full absolute inset-y-0 left-0", {
              "bg-info-main": isSelected && isStandard,
              "bg-primary-main": isSelected && !isStandard,
              "bg-secondary-main": !isSelected,
            })}
            style={{ backgroundColor: product?.color }}
          />
        )}

        <ProductItemInfo
          product={isStandard ? { ...product, dates: {} } : product}
          hidePrices={hidePrices}
          dateSeconds={dateSeconds}
          supplier={supplier as Supplier}
          fromStandard={isStandard}
        />

        {isSelected ? (
          <div
            className={classNames(
              "w-8 aspect-square rounded-md md:rounded-full self-start mt-2 flex items-center justify-center",
              isStandard ? "bg-info-main" : "bg-primary-main"
            )}
          >
            <FontAwesomeIcon icon={faCheck} color="white" className="text-lg" />
          </div>
        ) : (
          <div className="w-8 aspect-square rounded-md md:rounded-full border border-divider-main md:border-none md:bg-secondary-main self-start mt-2" />
        )}
      </div>

      {hasOptions && (
        <VariantsModal
          mealOptions={mealOptions}
          open={openVariants}
          setOpen={setOpenVariants}
          product={product}
          value={value?.["options"] || {}}
          productAvailability={product.dates[dateSeconds]}
          onChange={(newOptions) => {
            onChange(
              createOrderLine({ amount: 1, product, options: newOptions })
            );
          }}
        />
      )}
    </>
  );
};

export default ProductItem;

type SkipVariantsCheckProps = {
  product: CombinedOfferItem;
  dateSeconds: number;
  mealOptions: FlexOption[];
};
function skipVariantsCheck({
  dateSeconds,
  product,
  mealOptions,
}: SkipVariantsCheckProps) {
  const offerProduct = product.dates[dateSeconds];

  if (!offerProduct) return false;

  const options = mealOptions.filter(
    (option) =>
      offerProduct.menu?.options?.includes(option?.id) ||
      product.options?.includes(option?.id)
  );

  return options.every((o) => o.extension?.includes("hidden"));
}
