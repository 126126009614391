import React, { ReactElement } from "react";

const WrapContent: React.FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  return (
    <div className="w-full h-[250px] md:h-full flex justify-center items-center">
      <div className="bg-background-secondary py-12 px-4 rounded-lg text-center text-text-secondary w-full h-full">
        <div className="flex flex-col h-full items-center justify-center w-full">
          {children}
        </div>
      </div>
    </div>
  );
};

export default WrapContent;
