import { callInternalApi, t } from "@kanpla/system";
import { FlexStandard, HolidayPeriod } from "@kanpla/types";
import { message } from "@kanpla/ui";
import { atom, useAtom } from "jotai";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { DEFAULT_PERIODS } from "../../lib";
import { HolidaysModal } from "../../lib/flex/HolidaysModal";
import { getRanges } from "../../lib/flex/getRanges";
import { useFlexStandardOrders } from "../../lib/flex/useFlexStandardOrders";

export const periodsAtom =
  atom<NonNullable<FlexStandard["holidays"]>>(DEFAULT_PERIODS);

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const StandardHolidays = ({ open, setOpen }: Props) => {
  const { holidayDates, schoolId, moduleId, userId, childId } =
    useContainer(OrderingContext);

  const { holidays } = useFlexStandardOrders();

  const [periods, setPeriods] = useAtom(periodsAtom);

  useEffect(() => {
    if (!holidays?.length) return;
    setPeriods(holidays);
  }, [holidays]);

  const confirmHandler = async (
    periodsValue: HolidayPeriod[]
  ): Promise<void> => {
    try {
      message.loading(t("Saving holidays"), {
        messageId: "loading-holidays-saving",
      });

      const range = [
        ...new Set(
          getRanges(periodsValue)
            .map((arr) => arr.ranges)
            .flat()
        ),
      ];

      await callInternalApi("submit/submitFlexHolidays", {
        holidays: periodsValue,
        range,
        schoolId,
        moduleId,
        userId,
        childId,
      });

      setPeriods(periodsValue);

      message.dismiss("loading-holidays-saving");

      message.success(t("Holidays saved"), {
        messageId: "success-holidays-saving",
      });
    } catch (error) {
      message.error(t("Changes could not be saved."), {
        messageId: "error-holidays-saving",
      });
    } finally {
      setOpen(false);
    }
  };

  return (
    <HolidaysModal
      open={open}
      setOpen={setOpen}
      periods={periods}
      holidayDates={holidayDates}
      onConfirm={confirmHandler}
    />
  );
};

export default StandardHolidays;
