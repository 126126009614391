import { Order, OrderChange, OrderLines, Timestamp } from "@kanpla/types";
import { getOrderLine } from "../orders/orderLines/getOrderLine";
import { getCETOffset } from "../time/getCETOffset";

interface Props {
  order?: Order | undefined;
  date: Timestamp;
  deadline: number;
  softDeadlineMaxAmount: number;
  localOrder: OrderLines;
}

export interface GetLastFlexOrderChangeReturn {
  availableChanges?: number;
  rules: {
    max?: number;
    min?: number;
  };
  orderedAmount?: number;
  lastOrder?: OrderLines;
}

export const getLastFlexOrderChange = (
  props: Props
): GetLastFlexOrderChangeReturn | undefined => {
  const {
    order,
    date,
    deadline,
    softDeadlineMaxAmount,
    localOrder = [],
  } = props;

  try {
    const changes = order?.changes || [];

    const CETOffset = getCETOffset();

    const deadlineSeconds: number = date.seconds + deadline - CETOffset;

    const lastChange = changes.reduce((acc: OrderChange, curr: OrderChange) => {
      const currChangeSeconds = curr?.timestamp?.seconds;
      const accChangeSeconds = acc?.timestamp?.seconds;

      // ignore changes past deadline
      if (currChangeSeconds > deadlineSeconds) return acc;

      // ignore earlier changes
      if (currChangeSeconds < accChangeSeconds) return acc;

      return curr;
    }, null);

    const lastOrder = lastChange?.orderLines || [];

    if (!lastChange && !localOrder)
      return { availableChanges: softDeadlineMaxAmount, lastOrder, rules: {} };

    const changesMade: number = localOrder.reduce((acc, newOrderLine) => {
      const oldOrderLine = getOrderLine({
        orderLines: lastOrder,
        targetLine: newOrderLine,
      });
      const productDiff = Math.abs(
        (oldOrderLine?.amount || 0) - newOrderLine.amount
      );

      return acc + productDiff;
    }, 0);

    const availableChanges: number = softDeadlineMaxAmount - changesMade;

    return { availableChanges, lastOrder, rules: {} };
  } catch (err) {
    console.error(err);
  }
};
