import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateProductPrice } from "@kanpla/system";
import { CombinedOfferItem, FlexOption } from "@kanpla/types";
import { ChoiceButton, usePriceFormatter } from "@kanpla/ui";
import { T } from "@transifex/react";
import { Space } from "antd";
import { animated, config, useSpring } from "react-spring";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";

interface Props {
  title: string;
  subtitle?: string;
  price: number;
  color: string;
  max?: number;
  amount?: number;
  setAmountChosen?: (amount: number) => void;
  leftPerOption?: number;
  setChoice: (
    name: string,
    id: string,
    amount: number,
    price: number,
    removeOtherChoices?: boolean
  ) => void;
  id: string;
  option: FlexOption;
  disableMinus?: boolean;
  vatRate: CombinedOfferItem["vatRate"];
}

const Choice = ({
  title,
  subtitle,
  price,
  color,
  max,
  amount = 0,
  leftPerOption,
  setChoice,
  id,
  option,
  disableMinus,
  vatRate,
}: Props) => {
  const [animationProps, setAnimationProps] = useSpring(() => ({
    transform: `scale(1)`,
  }));
  const { module } = useContainer(OrderingContext);

  const triggerAnimation = () => {
    setAnimationProps({
      transform: `scale(1.1)`,
      config: config.wobbly,
    });
    setAnimationProps({
      transform: `scale(1)`,
      delay: 600,
      config: { mass: 2, tension: 170, friction: 20 },
    });
  };

  const processAmountChange = (
    type: "plus" | "minus" | "clear",
    removeOtherChoices?: true
  ) => {
    if (disableMinus && type !== "plus") {
      return;
    }

    if (type === "plus") {
      triggerAnimation();
    }
    const results = {
      plus: amount + 1,
      minus: amount - 1,
      clear: 0,
    };
    const newAmount = results[type];
    setChoice(title, id, newAmount, price, removeOtherChoices || false);
  };

  const processCheckbox = () => {
    if (isRadio && amount === 0) {
      processAmountChange("plus", true);
      return;
    }

    if (amount === 0) {
      processAmountChange("plus");
      return;
    }
    // Empty
    processAmountChange("clear");
  };

  const disabledNotEnough =
    typeof leftPerOption === "number" && leftPerOption < 1;

  const isRadio = option.maximum === 1;

  const RadioContent = () =>
    amount === 1 ? (
      <FontAwesomeIcon icon={faCheck} className="text-main-500" />
    ) : (
      <></>
    );

  const CheckboxContent = () => <>{amount > 0 ? amount : ""}</>;

  const isDisabled = amount === 0 && disabledNotEnough && !isRadio;

  const priceWithVat = calculateProductPrice({
    product: {
      unitPrice: price,
      vatRate,
      // Other fields for typescript to be happy :)
      id: "",
      productId: "",
      productLineId: "",
      dates: [],
      name: "",
      fromSeconds: 0,
      toSeconds: 0,
      originalPrice: price,
    },
    module,
  });
  const priceFormatted = usePriceFormatter(priceWithVat);

  return (
    <li
      className={`items-center flex -mx-4 px-4 rounded transition ${
        isDisabled
          ? "pointer-events-none opacity-50"
          : "hover:bg-background-secondary"
      }`}
    >
      <label className="py-3 flex items-start flex-shrink min-w-0 cursor-pointer w-full">
        <animated.button
          type="button"
          onClick={processCheckbox}
          className={`h-7 w-7 -my-1 -mb-2 rounded-full border-2 flex justify-center items-center flex-shrink-0 text font-semibold border-primary-main ${
            amount > 0
              ? "bg-primary-main text-primary-contrast"
              : "bg-background-primary text-text-primary"
          }`}
          style={{
            borderColor: color,
            backgroundColor: amount > 0 ? color : "var(--main-color-500)",
            ...animationProps,
          }}
        >
          {isRadio ? <RadioContent /> : <CheckboxContent />}
        </animated.button>
        <p className="mx-3 min-w-0 flex flex-col">
          {subtitle && (
            <span className="text-xs" style={{ color: color }}>
              {title}
            </span>
          )}

          <span className="leading-tight text-text-secondary">
            <span>{subtitle || title}</span>
            {max && (
              <span className="ml-2 text-xs text-text-secondary">
                <T _str="max {amount} pcs." amount={max} />
              </span>
            )}
          </span>
        </p>
      </label>

      <Space className="ml-auto ">
        {price > 0 ? (
          <p className="text-sm text-text-secondary flex-shrink-0 whitespace-nowrap">
            + {priceFormatted}
          </p>
        ) : null}

        {!isRadio && amount > 0 && (
          <ChoiceButton
            color={color}
            plusProps={{ disabled: amount === max || disabledNotEnough }}
            processAmountChange={processAmountChange}
            minusProps={{
              disabled: disableMinus,
            }}
          />
        )}
      </Space>
    </li>
  );
};

export default Choice;
