import { OrderingContext } from "@kanpla/ordering";
import { AllergensWarningModal } from "@kanpla/ui";
import React from "react";
import { useContainer } from "unstated-next";
import { Flex } from "./flex";
import { FlexBulk } from "./flexBulk";
import MealplanMealplan from "./mealplan2/MealplanMealplan";
import MealplanMeeting from "./mealplan2/meeting";
import Registering from "./mealplan2/registering";

export interface SelectedCategoryData {
  index: number;
  trusted?: boolean;
}

interface Props {
  Overview?: () => React.ReactElement | null;
}

export const Module = ({ Overview }: Props) => {
  const { module, isBulk } = useContainer(OrderingContext);

  if (module?.type === "flex") {
    return isBulk ? <FlexBulk Overview={Overview} /> : <Flex />;
  }

  if (module?.type === "mealplan") {
    const meetingUI = module?.flow === "meeting";
    const registeringUI = module?.flow === "registering";

    return (
      <>
        {meetingUI ? (
          <MealplanMeeting />
        ) : registeringUI ? (
          <Registering />
        ) : (
          <MealplanMealplan />
        )}
        <AllergensWarningModal />
      </>
    );
  }

  return null;
};
