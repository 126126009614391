import { cloneDeep } from "lodash";
import moment from "moment";

interface Props {
  deadline: number;
  deadlineWeekRelative: boolean;
  deadlineExcludingWeekends: boolean;
  t: (p: any) => any;
}

export const deadlineDisplay = ({
  deadline,
  deadlineWeekRelative,
  deadlineExcludingWeekends,
  t,
}: Props) => {
  const translation = {
    "same-day": t("same day"),
    "day-before": t("day before"),
    "days-before": t("days before"),
    "week-before": t("week before"),
    "time-prefix": "",
    monday: t("Monday"),
    tuesday: t("Tuesday"),
    wednesday: t("Wednesday"),
    thursday: t("Thursday"),
    friday: t("Friday"),
    saturday: t("Saturday"),
    sunday: t("Sunday"),
  };

  const daysBefore = Math.floor((deadline || 0) / 86400);
  const timeModulus = (deadline || 0) % 86400;

  let time = timeModulus;

  if (timeModulus < 0) {
    const date = moment().startOf("day").add(deadline, "seconds");
    const midnight = cloneDeep(date).startOf("day");

    time = date.unix() - midnight.unix();
  }

  const getDayRelativeDisplay = () => {
    if (daysBefore === 0) return translation["same-day"];
    if (daysBefore === -1) return translation["day-before"];
    return `${-daysBefore} ${translation["days-before"]}`;
  };

  const getWeekRelativeDisplay = () => {
    const dayNames = {
      6: translation.sunday,
      5: translation.saturday,
      4: translation.friday,
      3: translation.thursday,
      2: translation.wednesday,
      1: translation.tuesday,
      0: translation.monday,
    };

    const dayIndex = daysBefore + 7;
    return `${dayNames[dayIndex]} ${translation["week-before"]}`;
  };

  const displayDay = deadlineWeekRelative
    ? getWeekRelativeDisplay()
    : getDayRelativeDisplay();
  const displayTime = moment.utc(time * 1000 || 0).format("HH:mm");

  return `${translation["time-prefix"]}${displayTime}, ${displayDay}`;
};
