import { faSignOutAlt } from "@fortawesome/pro-solid-svg-icons";
import { useT } from "@kanpla/system";
import { ButtonLine, message } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { useRouter } from "next/router";
import { useContainer } from "unstated-next";
import { isMenuOpenAtom } from "..";
import { AppContext } from "../../contextProvider";

const Logout = () => {
  const { setChild, auth } = useContainer(AppContext);
  const router = useRouter();
  const t = useT();
  const setIsMenuOpen = useSetAtom(isMenuOpenAtom);

  return (
    <ButtonLine
      id="action-logOut"
      label={t("Log out")}
      onClick={async () => {
        try {
          setChild(null);
          setIsMenuOpen(false);
          await auth.signOut();
          router.push("/login");
        } catch (e) {
          console.error(e);
          message.error(e?.message || t("There were issues logging out"));
        }
      }}
      icon={faSignOutAlt}
    />
  );
};

export default Logout;
