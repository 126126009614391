import { faCartShopping } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";

const Empty = () => {
  const t = useT();

  return (
    <div className="w-full h-full flex justify-center items-center flex-col pt-4">
      <FontAwesomeIcon
        icon={faCartShopping}
        size="2x"
        className="text-primary-main mb-4"
      />
      <h1 className="text-primary-main text-xl">
        {t("No available products")}
      </h1>
      <p className="mt-2 text-center">
        {t("There are no available products in this module")}
      </p>
    </div>
  );
};

export default Empty;
