import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import {
  faDownload,
  faFileExcel,
  faFilePdf,
  faTable,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { Button, Popper } from "@kanpla/ui";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useContainer } from "unstated-next";
import { ExportContext } from "..";
import ExportOrders from "../export/ExportOrders";
import usePrintAndDownload from "../export/usePrintAndDownload";

const ExportMenu = () => {
  const t = useT();

  const [open, setOpen] = useState<boolean>(false);
  const [openExport, setOpenExport] = useState<boolean>(false);

  const { meetingView, mobile, items, viewEmployeeOrders } =
    useContainer(ExportContext);

  const { printAndDownload, printLoading, excelLoading } =
    usePrintAndDownload();

  const adminView = viewEmployeeOrders || meetingView;
  const disabled = !items || isEmpty(items);

  if (!adminView || mobile) return null;

  return (
    <>
      <Popper
        open={open}
        setOpen={setOpen}
        fitActionElement
        margin={2}
        placement="bottom-start"
        withPadding={false}
        className="p-2"
        actionElement={
          <Button type="primary" icon={faDownload} dataCy="btn-export-menu">
            {t("Export")}
          </Button>
        }
      >
        <>
          <div
            className={classNames(
              "flex items-center hover:bg-secondary-light hover:text-secondary-contrast transition-all ease-in-out my-1 rounded-md px-3 py-1",
              disabled
                ? "opacity-50 select-none cursor-not-allowed"
                : "cursor-pointer"
            )}
            onClick={async () => {
              if (disabled) return;
              await printAndDownload("pdf");
            }}
          >
            <FontAwesomeIcon
              icon={!disabled && printLoading ? faSpinnerThird : faFilePdf}
              className={classNames({ "animate-spin": printLoading })}
            />
            <span className="ml-2">{t("Export pdf")}</span>
          </div>
          <div
            className={classNames(
              "flex items-center hover:bg-secondary-light hover:text-secondary-contrast transition-all ease-in-out my-1 rounded-md px-3 py-1",
              disabled
                ? "opacity-50 select-none cursor-not-allowed"
                : "cursor-pointer"
            )}
            onClick={async () => {
              if (disabled) return;
              await printAndDownload("xlsx");
            }}
          >
            <FontAwesomeIcon
              icon={!disabled && excelLoading ? faSpinnerThird : faFileExcel}
              className={classNames({ "animate-spin": excelLoading })}
            />
            <span className="ml-2">{t("Export xlsx")}</span>
          </div>
          <div
            className={classNames(
              "flex items-center hover:bg-secondary-light hover:text-secondary-contrast transition-all ease-in-out my-1 rounded-md px-3 py-1",
              disabled
                ? "opacity-50 select-none cursor-not-allowed"
                : "cursor-pointer"
            )}
            onClick={() => {
              if (disabled) return;
              setOpenExport(true);
              setOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faTable} />
            <span className="ml-2">{t("Customize export")}</span>
          </div>
        </>
      </Popper>
      <ExportOrders open={openExport} setOpen={setOpenExport} />
    </>
  );
};

export default ExportMenu;
