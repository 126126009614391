import { faClock, faCookieBite } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { message } from "@kanpla/ui";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import moment, { Moment } from "moment";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import { dateInfoAtom } from "../lib/hooks/useDateInfo";
import { useDeadlineFormatter } from "../lib/hooks/useDeadlineFormatter";
import { deadlineInfoAtom } from "../lib/hooks/useDeadlineInfo";
import Deadline from "./Deadline";

interface Props {
  individualDeadline: number | undefined | false;
  individualOnset?: number | undefined | false;
  hidden?: boolean | number;
  nextAvailableDate?: Moment | null;
  showChildrenAnyway?: boolean;
  outOfStock?: boolean;
  hideNextDay?: boolean;
  /** Use it mainly for placing the info, if necessary */
  className?: string;
  /** Display the banner on the right, for longer messages. Defaults to `true` */
  displayRight?: boolean;
  /** Show a toast when the dateSeconds changes after user interaction */
  displayMessage?: boolean;
}

const DeadlineInfo = (props: Props) => {
  const {
    individualDeadline,
    individualOnset = null,
    hidden,
    nextAvailableDate = null,
    showChildrenAnyway = false,
    outOfStock = false,
    hideNextDay = false,
    className = "",
    displayRight = true,
    displayMessage = false,
  } = props;
  const t = useT();

  const { isPastDeadline, isPastTodayIndividualDeadline } =
    useAtomValue(dateInfoAtom);

  const { deadline, deadlineExcludingWeekends, deadlineWeekRelative } =
    useAtomValue(deadlineInfoAtom);

  const { dateSeconds, setDate, fromAdmin, hasKanplaGo } =
    useContainer(OrderingContext);

  const onsetDisplay = useDeadlineFormatter({
    dateSeconds,
    deadline: individualOnset || 0,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  const hasIndividualDeadline = typeof individualDeadline === "number";
  const hasIndividualOnset = typeof individualOnset === "number";

  const hasRestrictedAvailability = hasIndividualDeadline || hasIndividualOnset;

  const isPastIndividualDeadline = isPastTodayIndividualDeadline(
    individualDeadline || deadline
  );

  const isBeforeFlexibleStartDate =
    typeof individualOnset === "number" &&
    !isPastTodayIndividualDeadline(individualOnset);

  const isSameAsMainDeadline = individualDeadline === deadline;

  const isPastBothDeadlines = isPastDeadline || isPastIndividualDeadline;

  if ((isPastBothDeadlines || isBeforeFlexibleStartDate) && !showChildrenAnyway)
    return null;

  if (fromAdmin) return null;

  if (
    hasRestrictedAvailability &&
    (isPastBothDeadlines || isBeforeFlexibleStartDate) &&
    !hideNextDay
  ) {
    if (!nextAvailableDate) return null;

    const tomorrow = moment().add(1, "day");
    const isTomorrow = tomorrow
      .startOf("day")
      .isSame(nextAvailableDate.startOf("day"));

    const warningText: string = isPastIndividualDeadline
      ? hasKanplaGo
        ? t("Too late to order for this day.")
        : t("Too late to order for this day. Click here to order for {value}", {
            value: isTomorrow
              ? t("tomorrow")
              : nextAvailableDate.format("dddd [d]. D/M"),
          })
      : isBeforeFlexibleStartDate
      ? t("Too early to order for this day. Come back on {value}", {
          value: onsetDisplay,
        })
      : null;

    return (
      <div
        className={classNames(
          "z-10",
          displayRight ? "flex flex-col items-start w-full" : "w-fit",
          className
        )}
      >
        <Deadline
          individualDeadline={individualDeadline}
          individualOnset={individualOnset}
          isBeforeFlexibleStartDate={isBeforeFlexibleStartDate}
          className={classNames("ml-auto mb-1 animate-appear transition-all", {
            "hover:bg-primary-main cursor-pointer": !isBeforeFlexibleStartDate,
          })}
          message={warningText}
          onClick={() => {
            !isBeforeFlexibleStartDate &&
              setDate({ dateSeconds: nextAvailableDate.unix() });

            displayMessage &&
              message.info(
                t("You are now ordering for {value}", {
                  value: nextAvailableDate.format("dddd [d]. D/M"),
                })
              );
          }}
        />
      </div>
    );
  }

  const shouldShowMessage =
    hasRestrictedAvailability && !hidden && !isSameAsMainDeadline;

  if (
    shouldShowMessage ||
    outOfStock ||
    isPastBothDeadlines ||
    isBeforeFlexibleStartDate
  )
    return (
      <div className={classNames("flex flex-col items-start", className)}>
        {shouldShowMessage && (
          <Deadline
            individualDeadline={individualDeadline}
            individualOnset={individualOnset}
            isBeforeFlexibleStartDate={isBeforeFlexibleStartDate}
            className="ml-auto mb-1 animate-appear"
          />
        )}
        {outOfStock && (
          <div
            className={`text-xs animate-appear text-background-primary pb-1 bg-danger-main font-medium px-2 pt-1 rounded-lg bg-opacity-80`}
            style={{
              backdropFilter: "blur(3px)",
              WebkitBackdropFilter: "blur(3px)",
            }}
          >
            <FontAwesomeIcon icon={faCookieBite} className="mr-1" />
            {t("This item is sold out!")}
          </div>
        )}
        {!fromAdmin && isPastBothDeadlines && (
          <div
            className={`text-xs animate-appear text-background-primary pb-1 bg-warning-main font-medium px-2 pt-1 rounded-lg bg-opacity-80`}
            style={{
              backdropFilter: "blur(3px)",
              WebkitBackdropFilter: "blur(3px)",
            }}
          >
            <FontAwesomeIcon icon={faClock} className="mr-1" />
            {t("It's too late to order")}
          </div>
        )}
      </div>
    );

  return null;
};

export default DeadlineInfo;
