import { tx, useAvailableLanguages, useT } from "@kanpla/system";
import { Language } from "@kanpla/types";
import { DrawerOrModal, Form, Radio, message } from "@kanpla/ui";
import { Dispatch, SetStateAction, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

type FormData = {
  newLanguage: string;
};

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ChangeLanguage = ({ open, setOpen }: Props) => {
  const t = useT();
  const { updateLanguage } = useContainer(AppContext);
  const [loading, setLoading] = useState(false);
  const availableLanguages = useAvailableLanguages();

  const onFinish = async (data: FormData) => {
    try {
      setLoading(true);

      const { newLanguage } = data;

      await updateLanguage(newLanguage as Language);
      message.success(t("Language changed"));

      setOpen(false);
    } catch (e) {
      message.error(t("Language could not be changed"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("Change language")}
      subtitle={t("Change the application language based on your preferences")}
      actions={[
        {
          label: t("Change language"),
          onClick: () => null,
          className: "primary",
          loading,
          htmlType: "submit",
          form: "change-language-form",
        },
      ]}
    >
      <Form<FormData>
        id="change-language-form"
        onSubmit={onFinish}
        defaultValues={{ newLanguage: tx.getCurrentLocale() }}
      >
        <Form.Item name="newLanguage">
          {/* @ts-ignore */}
          <Radio.Group
            options={availableLanguages.map((language) => ({
              label: language.localized_name,
              value: language.code,
              name: "choose-language",
            }))}
            display="vertical"
          />
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default ChangeLanguage;
