import { faRadar, faWavePulse } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cards from "./Cards";
import PhoneWireframe from "./PhoneWireframe";

const PermissionsIllustration = (): JSX.Element => {
  return (
    <div className="w-fit h-fit relative">
      <PhoneWireframe />
      <div className="absolute top-11 left-16">
        <div className="relative">
          <FontAwesomeIcon
            icon={faRadar}
            size="3x"
            className="text-primary-main"
          />
          <div className="absolute top-4 left-0 h-3.5 w-3.5">
            <span className="relative flex w-full h-full">
              <span className="rounded-full inline-flex bg-danger-dark font-medium h-3.5 w-3.5 border-2 border-white" />
              <span className="absolute inline-flex w-full h-full rounded-full animate-ping opacity-75 bg-danger-dark"></span>
            </span>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 -left-11">
        <Cards
          title="New activity"
          content="+1 new purchase 🎉"
          icon={
            <FontAwesomeIcon
              icon={faWavePulse}
              className="text-primary-main w-auto h-auto mt-1"
            />
          }
        />
      </div>
    </div>
  );
};

export default PermissionsIllustration;
